import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeleteMessageVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeleteMessageInput;
}>;


export type DeleteMessage = { __typename: 'Mutation', deleteMessage: { __typename: 'DeleteMessagePayload', id: string } };


export const DeleteMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessage, DeleteMessageVariables>;

/**
 * __useDeleteMessage__
 *
 * To run a mutation, you first call `useDeleteMessage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessage, { data, loading, error }] = useDeleteMessage({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMessage(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMessage, DeleteMessageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteMessage, DeleteMessageVariables>(DeleteMessageDocument, options);
      }
export type DeleteMessageHookResult = ReturnType<typeof useDeleteMessage>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessage>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessage, DeleteMessageVariables>;