import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useUpdateCompanyIdentities as useUpdateCompanyIdentitiesMutation } from '../queries/generated/UpdateCompanyIdentities';

interface UseUpdateCompanyIdentitiesParams {
  id: string;
}

interface UseUpdateCompanyIdentitiesResult {
  loading: boolean;
  error?: ApolloError;
  onUpdateCompanyIdentities: (email: string) => void;
}

export const useUpdateCompanyIdentities = ({
  id,
}: UseUpdateCompanyIdentitiesParams): UseUpdateCompanyIdentitiesResult => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Данные компании изменены');
  };

  const [updateCompanyIdentities, { error, loading }] =
    useUpdateCompanyIdentitiesMutation({
      onCompleted: handleCompleted,
    });

  const onUpdateCompanyIdentities = (email: string) => {
    updateCompanyIdentities({
      variables: {
        input: {
          id,
          email,
        },
      },
    });
  };

  return {
    onUpdateCompanyIdentities,
    loading,
    error,
  };
};
