import { FC } from 'react';
import { ToggleBlock } from 'commonComponents/ToggleBlock';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { useFormikContext } from 'formik';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { CurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';
import { useToggleBlockHandlers } from '../../hooks/useToggleBlockHandlers';
import { CreatePromoCodeValues } from '../../../../types';

export const BonusToggleBlock: FC = () => {
  const { values } = useFormikContext<CreatePromoCodeValues>();
  const { onBonusBlockToggleHandler } = useToggleBlockHandlers();

  return (
    <FormRow isWide>
      <ToggleBlock
        name={FieldName.ConnectionWithBonus}
        label={mapCreatePromoCodeFieldToTitle[FieldName.ConnectionWithBonus]}
        isOpen={values[FieldName.ConnectionWithBonus]}
        onToggle={(isChecked) => {
          onBonusBlockToggleHandler(isChecked);
        }}
      >
        <FormRow>
          <Input.Number
            name={FieldName.BonusAmount}
            label={mapCreatePromoCodeFieldToTitle[FieldName.BonusAmount]}
            placeholder="Введите значение"
            customMaxLength={15}
            decimalScale={1}
          />
          <CurrencyDropdown
            name={FieldName.BonusCurrency}
            label={mapCreatePromoCodeFieldToTitle[FieldName.BonusCurrency]}
          />
        </FormRow>

        <FormRow>
          <Input.Number
            name={FieldName.BonusWager}
            label={mapCreatePromoCodeFieldToTitle[FieldName.BonusWager]}
            placeholder="Введите значение"
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={2}
          />
          <Input.Number
            name={FieldName.BonusCashout}
            label={mapCreatePromoCodeFieldToTitle[FieldName.BonusCashout]}
            placeholder="Введите значение"
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={3}
          />
        </FormRow>

        <FormRow>
          <Input.Number
            name={FieldName.BonusMinBalance}
            label={mapCreatePromoCodeFieldToTitle[FieldName.BonusMinBalance]}
            placeholder="Введите значение"
            customMaxLength={15}
            decimalScale={1}
          />
        </FormRow>
      </ToggleBlock>
    </FormRow>
  );
};
