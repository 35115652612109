import { FC, MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import styles from './RepeatButton.module.scss';

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const RepeatButton: FC<Props> = ({ className, onClick }) => (
  <button
    data-testid="repeatButton"
    type="button"
    className={classNames(className, styles.container)}
    onClick={onClick}
  >
    <FontAwesomeIcon className={styles.icon} icon={faArrowRotateRight} />
  </button>
);
