import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext, useEffect } from 'react';
import { CompanyApplicationModalSteps } from '../../../const';

interface UseCompanyApplicationModalParams {
  companyApplicationId: string;
}

export const useCompanyApplicationModalSteps = ({
  companyApplicationId,
}: UseCompanyApplicationModalParams) => {
  const { state, dispatch } = useContext(ModalContext);

  useEffect(() => {
    if (state.step === CompanyApplicationModalSteps.CreateReferralLink) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание медиа элемента',
          subTitle: `Заявка компании #${companyApplicationId}`,
        },
      });
    } else if (state.step === CompanyApplicationModalSteps.UpdateReferralLink) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Редактирование медиа элемента',
          subTitle: `Заявка компании #${companyApplicationId}`,
        },
      });
    } else if (state.step === CompanyApplicationModalSteps.BaseStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${companyApplicationId}`,
          subTitle: 'Заявка компании',
        },
      });
    } else if (state.step === CompanyApplicationModalSteps.DeclineStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Отклонение заявки',
          subTitle: `Заявка компании #${companyApplicationId}`,
        },
      });
    }
  }, [dispatch, companyApplicationId, state.step]);
};
