import { SET_EDITABLE_AFFILIATE_DATA } from '../actionTypes';

export interface EditableAffiliate {
  name: string;
  note?: string;
  affiliateId: string;
}

interface Payload {
  editableAffiliate: EditableAffiliate;
}

export interface SetEditableAffiliate {
  type: typeof SET_EDITABLE_AFFILIATE_DATA;
  payload: Payload;
}

export const setEditableAffiliateAction = (
  payload: Payload
): SetEditableAffiliate => ({
  type: SET_EDITABLE_AFFILIATE_DATA,
  payload,
});
