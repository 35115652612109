import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { ListAllOperations } from 'generatedGraphql';
import { toast } from 'react-toastify';
import { useDeleteQualifiedReferralConfirmationSetting as useDeleteQualifiedReferralConfirmationSettingMutation } from '../queries/generated/DeleteQualifiedReferralConfirmationSetting';

export const useDeleteQualifiedReferralConfirmationSetting = (
  confirmationSettingId: string
) => {
  const { dispatch } = useContext(ModalContext);

  const [deleteQualifiedReferralConfirmationSetting, { loading }] =
    useDeleteQualifiedReferralConfirmationSettingMutation({
      variables: {
        input: {
          id: confirmationSettingId,
        },
      },
      refetchQueries: [
        ListAllOperations.Query.GetQualifiedReferralConfirmationSettings,
      ],
    });

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Настройка подтверждения не удалена',
      text: error.message,
      toastId: 'delete-qualified-referral-confirmation-setting-error',
    });

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleComplete = () => {
    toast.success('Настройка подтверждения удалена');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleDeleteConfirmationSetting = () => {
    deleteQualifiedReferralConfirmationSetting({
      onError: handleError,
      onCompleted: handleComplete,
    });
  };

  return {
    handleDeleteConfirmationSetting,
    loading,
  };
};
