import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { nbsp } from 'src/const';
import { AddAffiliatesForm } from '../components/AddAffiliatesForm';

interface UseAddAffiliatesModalParams {
  companyId: string;
  companyName?: string;
}

interface UseAddAffiliatesModalResult {
  openAddAffiliatesModal: () => void;
}

export const useAddAffiliatesModal = ({
  companyId,
  companyName,
}: UseAddAffiliatesModalParams): UseAddAffiliatesModalResult => {
  const { dispatch } = useContext(ModalContext);

  const openAddAffiliatesModal = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: `Добавление партнера к${nbsp}компании`,
        subTitle: `${companyName} #${companyId}`,
        content: <AddAffiliatesForm companyId={companyId} />,
      },
    });
  }, [companyId, companyName, dispatch]);

  return {
    openAddAffiliatesModal,
  };
};
