import { FC } from 'react';
import Input from 'ui/Input';
import { Sum } from 'generatedGraphql';
import { Dropdown } from 'ui/Dropdown';
import Button, { ButtonTheme } from 'ui/Button';
import { Form, Formik } from 'formik';
import { playerPaymentNameMap } from 'src/const';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { Currency, PaymentProcessor } from 'commonTypes';
import Loader from 'ui/Loader';
import { LoaderTheme } from 'ui/Loader/Loader';
import { Tooltip } from 'ui/Tooltip';
import { ApolloError } from '@apollo/client';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { ActorInfo } from 'commonComponents/CreatePayment/types';
import { CurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { CreatePaymentValues, validate } from './validation';
import { useAddPayment } from './hooks/useAddPayment';
import { FormWarningLimit } from './components/FormWarningLimit';
import styles from './CreatePayment.module.scss';
import { useConvertPaymentCurrency } from './hooks/useConvertPaymentCurrency';
import { useGetRestrictionAndLimits } from './hooks/useGetRestrictionAndLimits';
import FormWarningRestriction, {
  FORM_WARNING_RESTRICTION_CHECKBOX_NAME,
} from './components/FormWarningRestriction';
import { isSubmitPayoutDisabled } from './helpers';

interface Props {
  withdrawalId: string;
  remainingSum?: Sum;
  handleCreatePayment: (arg: CreatePaymentValues) => Promise<void>;
  createPaymentLoading: boolean;
  createPaymentError?: ApolloError;
  actorInfo?: ActorInfo;
}

export const CreatePayment: FC<Props> = ({
  withdrawalId,
  remainingSum,
  handleCreatePayment,
  createPaymentLoading,
  createPaymentError,
  actorInfo,
}) => {
  const {
    providerOptions,
    paymentProcessorsLoading,
    paymentProcessorsError,
    paymentMethodName,
    paymentMethodAccount,
    paymentMethodMaskedAccount,
    paymentRemainingAmount,
    paymentAmount,
    setPaymentAmount,
    setPaymentRemainingAmount,
    paymentCurrency,
    setPaymentCurrency,
    paymentProvider,
    setPaymentProvider,
    isCryptoPaymentMethod,
  } = useAddPayment({ withdrawalId, remainingSum });

  const {
    convertCurrency,
    convertLoading,
    convertError,
    convertRemainingAmount,
    convertRemainingAmountLoading,
    convertRemainingAmountError,
    hasConvertCurrencyPrivilege,
  } = useConvertPaymentCurrency(setPaymentAmount, setPaymentRemainingAmount);

  const {
    bypassRestrictionIfApproved,
    comparisonResult,
    restrictionAndLimitsLoading,
  } = useGetRestrictionAndLimits(
    actorInfo,
    paymentAmount,
    paymentCurrency,
    isCryptoPaymentMethod
  );

  if (!remainingSum || !paymentCurrency || !paymentRemainingAmount) {
    return null;
  }

  const convertCurrencyHandler = (currency: Currency) => {
    if (!paymentAmount) {
      return;
    }

    convertCurrency({
      variables: {
        from: {
          amount: paymentAmount,
          currency: paymentCurrency,
        },
        to: currency,
      },
    });

    convertRemainingAmount({
      variables: {
        from: {
          amount: remainingSum.amount,
          currency: paymentCurrency,
        },
        to: currency,
      },
    });
  };

  const initialValues: CreatePaymentValues = {
    actor: actorInfo ? `#${actorInfo.id} ${actorInfo.name}` : '',
    sum: paymentAmount,
    currency: paymentCurrency,
    paymentMethodName: paymentMethodName
      ? playerPaymentNameMap[paymentMethodName] || paymentMethodName
      : '',
    paymentMethodAccount:
      paymentMethodAccount || paymentMethodMaskedAccount || '',
    bypassRestrictionIfApproved,
    provider: paymentProvider,
    approved: bypassRestrictionIfApproved ? false : undefined,
  };

  const error =
    createPaymentError || convertError || convertRemainingAmountError;
  const isCurrencyDropdownDisabled =
    convertLoading ||
    convertRemainingAmountLoading ||
    !hasConvertCurrencyPrivilege;

  return (
    <Formik
      initialValues={initialValues}
      validate={(obj) => validate(obj, paymentRemainingAmount, paymentAmount)}
      onSubmit={handleCreatePayment}
      enableReinitialize
      validateOnChange={false}
    >
      {({ getFieldProps, values }) => {
        const isSubmitDisabled = isSubmitPayoutDisabled(
          getFieldProps(FORM_WARNING_RESTRICTION_CHECKBOX_NAME).value
        );

        return (
          <Form data-testid="form">
            {error && <Error error={error} />}
            {bypassRestrictionIfApproved && <FormWarningRestriction />}
            {comparisonResult?.shouldShowOverForm && (
              <FormWarningLimit isCryptoPaymentMethod={isCryptoPaymentMethod} />
            )}

            <FormRowsWrapper>
              <FormRow isWide>
                <Input name="actor" disabled label="Игрок" />
              </FormRow>
              <FormRow>
                <Input name="paymentMethodName" label="Метод" disabled />
                <Input
                  name="paymentMethodAccount"
                  label="Номер счёта"
                  disabled
                />
              </FormRow>
              <FormRow>
                <Dropdown
                  label="Провайдер"
                  options={providerOptions}
                  loading={paymentProcessorsLoading}
                  loadingError={paymentProcessorsError}
                  name="provider"
                  onChange={(provider) => {
                    setPaymentProvider(provider as PaymentProcessor);
                  }}
                />
              </FormRow>
              <FormRow>
                <Input.Number
                  label="Сумма"
                  name="sum"
                  disabled={convertLoading}
                  onBlur={() => {
                    if (values.sum) {
                      setPaymentAmount(values.sum);
                    }
                  }}
                  errorTooltipPlacement="left"
                />
                <CurrencyDropdown
                  name="currency"
                  label="Валюта"
                  disabled={isCurrencyDropdownDisabled}
                  onChange={(currency) => {
                    convertCurrencyHandler(currency as Currency);
                    setPaymentCurrency(currency as Currency);
                  }}
                />
              </FormRow>
            </FormRowsWrapper>

            {!comparisonResult?.shouldShowOverForm &&
              comparisonResult?.delta && (
                <FormWarningLimit
                  comparisonResult={
                    restrictionAndLimitsLoading ? (
                      <Loader
                        theme={LoaderTheme.Dark}
                        className={styles.comparisonResultLoader}
                      />
                    ) : (
                      formatSumWithCurrency(
                        comparisonResult?.delta,
                        paymentCurrency
                      )
                    )
                  }
                  isCryptoPaymentMethod={isCryptoPaymentMethod}
                />
              )}

            <Tooltip
              tooltipContent={
                isSubmitDisabled ? 'Необходимо подтвердить платеж' : undefined
              }
              placement="right"
            >
              <Button
                theme={ButtonTheme.Success}
                type="submit"
                isLoading={createPaymentLoading}
                disabled={isSubmitDisabled}
              >
                Создать
              </Button>
            </Tooltip>
          </Form>
        );
      }}
    </Formik>
  );
};
