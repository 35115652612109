import { FC } from 'react';
import { PromoCodeFreespinAction } from 'generatedGraphql';
import { KeyValueRow } from 'ui/KeyValueRow';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { nbsp } from 'src/const';
import { PromoCodeActionBlockContainer } from '../PromoCodeActionBlockContainer';

interface Props {
  freespinAction: PromoCodeFreespinAction;
}

export const FreespinActionBlock: FC<Props> = ({ freespinAction }) => (
  <PromoCodeActionBlockContainer title={`Связь с${nbsp}фриспинами`}>
    <>
      <KeyValueRow title="Слот" value={freespinAction.game.name} />
      <KeyValueRow title="Количество" value={freespinAction.count} />
      <KeyValueRow title="Валюта" value={freespinAction.currency} />
      <KeyValueRow title="Вейджер" value={freespinAction.bonusWager} />
      <KeyValueRow
        title="Минимальный баланс"
        value={
          freespinAction.minAccountBalanceAmount &&
          formatSumWithCurrency(
            freespinAction.minAccountBalanceAmount.amount,
            freespinAction.minAccountBalanceAmount.currency
          )
        }
      />
    </>
  </PromoCodeActionBlockContainer>
);
