import { FC, ReactElement } from 'react';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { PartnersSearchInput } from '../PartnersSearchInput/PartnersSearchInput';
import { PartnerPageContent } from '../PartnerPageContent/PartnerPageContent';

interface Props {
  children: ReactElement;
  partnerId: string;
  shouldProvideBlock?: boolean;
}

export const PartnerPageContainer: FC<Props> = ({
  children,
  partnerId,
  shouldProvideBlock = true,
}) => (
  <SearchContextProvider
    shouldUpdateUrl={false}
    pageType={PageTypeStorage.PartnerPage}
  >
    <>
      <PartnersSearchInput />
      <PartnerPageContent
        partnerId={partnerId}
        shouldProvideBlock={shouldProvideBlock}
      >
        {children}
      </PartnerPageContent>
    </>
  </SearchContextProvider>
);
