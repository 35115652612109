import { SET_INITIAL_DATA } from '../actionTypes';

interface Payload {
  blockId: string;
  isPermanentOpened: boolean;
}

export interface SetInitialData {
  type: typeof SET_INITIAL_DATA;
  payload: Payload;
}

export const setInitialDataAction = (payload: Payload): SetInitialData => ({
  type: SET_INITIAL_DATA,
  payload,
});
