import { FileFormat, InputFile } from 'ui/InputFile';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useAddPlayerNameToSidebarSubTitle } from 'src/utils/hooks/useAddPlayerNameToSidebarSubTitle';
import Button, { ButtonTheme } from 'ui/Button';
import { Tooltip } from 'ui/Tooltip';
import styles from './CreateDocumentsForm.module.scss';
import { HandleDelete, HandleUpdateNote, LocalFile } from './types';
import { useUploadFileToServer } from './hooks/useUploadFileToServer';
import { DocumentToUpload } from './components/DocumentToUpload';
import { LoadingFileStatus } from './const';
import { useLocalFiles } from './hooks/useLocalFiles';
import { useCreateDocuments } from './hooks/useCreateDocuments';

interface Props {
  playerId: string;
  refetchDocuments: () => void;
}

export const CreateDocumentsForm: FC<Props> = ({
  playerId,
  refetchDocuments,
}) => {
  const abortControllers = useRef(new Map<string, () => void>());

  useAddPlayerNameToSidebarSubTitle(playerId);

  const { createDocuments, createDocumentsLoading } = useCreateDocuments({
    refetchDocuments,
  });

  const { localFiles, addLocalFiles, removeLocalFile, updateLocalFilesItem } =
    useLocalFiles();

  const { uploadFileToServer, uploadFileLoading } = useUploadFileToServer({
    updateLocalFilesItem,
    abortControllersMap: abortControllers.current,
  });

  const isButtonDisabled = useMemo(() => {
    const isLocalFilesContainsError = localFiles.find(
      (localFile) => localFile.status === LoadingFileStatus.Error
    );

    return !localFiles.length || Boolean(isLocalFilesContainsError);
  }, [localFiles]);

  const handleUploadFiles = async (files: FileList) => {
    const filesToUpload: Array<LocalFile> =
      [...files].map((file) => ({
        file,
        status: LoadingFileStatus.Loading,
        uploadedBites: 0,
        id: String(Math.random() * 10),
      })) || [];

    if (!filesToUpload.length) {
      return;
    }

    addLocalFiles(filesToUpload);
    filesToUpload.forEach(uploadFileToServer);
  };

  const handleDeleteClick: HandleDelete = useCallback(
    (id, status) => {
      removeLocalFile(id, status, abortControllers.current.get(id));
    },
    [removeLocalFile]
  );

  const handleUploadButtonClick = async () => {
    const dataToUpload = localFiles.map(({ id, note }) => ({
      fileId: id,
      note,
    }));

    await createDocuments({
      variables: { input: { playerId, items: dataToUpload } },
    });
  };

  const handleUpdateNote: HandleUpdateNote = useCallback(
    (id, value) => {
      updateLocalFilesItem(id, { note: value });
    },
    [updateLocalFilesItem]
  );

  return (
    <>
      <InputFile
        validFormats={[FileFormat.JPEG]}
        onChange={handleUploadFiles}
        className={styles.inputFile}
      />

      {localFiles.length !== 0 && (
        <div className={styles.filesContainer}>
          {localFiles.map((document) => (
            <DocumentToUpload
              key={document.id}
              document={document}
              createDocumentsLoading={createDocumentsLoading}
              uploadFileToServer={uploadFileToServer}
              onDelete={handleDeleteClick}
              onUpdateNote={handleUpdateNote}
            />
          ))}
        </div>
      )}

      <Tooltip
        tooltipContent={
          localFiles.length ? undefined : 'Необходимо загрузить файл'
        }
      >
        <Button
          data-testid="uploadDocumentsButton"
          theme={ButtonTheme.Process}
          isLoading={createDocumentsLoading}
          disabled={isButtonDisabled || uploadFileLoading}
          onClick={handleUploadButtonClick}
        >
          Загрузить
        </Button>
      </Tooltip>
    </>
  );
};
