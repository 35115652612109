import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { Confirmation } from 'ui/Confirmation';
import { UpdateNameValues, validate } from './validation';
import { useUpdateCompanyName } from './hooks/useUpdateCompanyName';

interface Props {
  id: string;
  name: string;
}

export const UpdateCompanyName: FC<Props> = ({ id, name }) => {
  const { loading, onUpdateCompanyName, error } = useUpdateCompanyName({
    id,
  });

  const [initialValues] = useState({
    clientName: name,
    confirmation: '',
  });

  const submitHandler = (values: UpdateNameValues) => {
    onUpdateCompanyName(values.clientName);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name="clientName"
                label="Название"
                maxLength={50}
                data-testid="clientNameField"
              />
            </FormRow>
          </FormRowsWrapper>
          <Confirmation
            label="Чтобы изменить имя напишите «ИЗМЕНИТЬ»"
            loading={loading}
            buttonText="Изменить"
            isValid={isValid}
            dirty={dirty}
          />
        </Form>
      )}
    </Formik>
  );
};
