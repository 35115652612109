import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { Sum } from 'generatedGraphql';
import { WithdrawalModalSteps } from '../../../../../../../types';
import {
  setDataAction,
  WithdrawalItemContext,
} from '../../../../../../../store';

interface OpenAcceptPaymentFormParams {
  paymentId: string;
  paymentPaidSum?: Sum | null;
}

interface UseOpenAcceptPaymentFormResult {
  openAcceptPaymentForm: (obj: OpenAcceptPaymentFormParams) => void;
}

export const useOpenAcceptPaymentForm = (): UseOpenAcceptPaymentFormResult => {
  const { dispatch } = useContext(ModalContext);
  const { dispatch: withdrawalItemDispatch } = useContext(
    WithdrawalItemContext
  );

  const openAcceptPaymentForm = useCallback(
    ({ paymentId, paymentPaidSum }: OpenAcceptPaymentFormParams) => {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: WithdrawalModalSteps.AcceptPayment,
          previousStep: WithdrawalModalSteps.BaseStep,
        },
      });

      withdrawalItemDispatch(
        setDataAction({
          paymentPaidSum,
          paymentId,
        })
      );
    },
    [dispatch, withdrawalItemDispatch]
  );

  return {
    openAcceptPaymentForm,
  };
};
