import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ConfirmCompanyAffiliateApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ConfirmCompanyAffiliateApplicationInput;
}>;


export type ConfirmCompanyAffiliateApplication = { __typename: 'Mutation', confirmCompanyAffiliateApplication: { __typename: 'ConfirmCompanyAffiliateApplicationPayload', companyAffiliateApplication: { __typename: 'CompanyAffiliateApplication', id: string, status: SchemaTypes.CompanyAffiliateApplicationStatus, items: Array<{ __typename: 'CompanyAffiliateApplicationItem', approved: boolean, id: string }>, statistic: { __typename: 'CompanyAffiliateApplicationStatistic', approved: number, total: number } } } };


export const ConfirmCompanyAffiliateApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ConfirmCompanyAffiliateApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConfirmCompanyAffiliateApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmCompanyAffiliateApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyAffiliateApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approved"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"statistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approved"}},{"kind":"Field","name":{"kind":"Name","value":"total"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ConfirmCompanyAffiliateApplicationMutationFn = Apollo.MutationFunction<ConfirmCompanyAffiliateApplication, ConfirmCompanyAffiliateApplicationVariables>;

/**
 * __useConfirmCompanyAffiliateApplication__
 *
 * To run a mutation, you first call `useConfirmCompanyAffiliateApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCompanyAffiliateApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCompanyAffiliateApplication, { data, loading, error }] = useConfirmCompanyAffiliateApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmCompanyAffiliateApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmCompanyAffiliateApplication, ConfirmCompanyAffiliateApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ConfirmCompanyAffiliateApplication, ConfirmCompanyAffiliateApplicationVariables>(ConfirmCompanyAffiliateApplicationDocument, options);
      }
export type ConfirmCompanyAffiliateApplicationHookResult = ReturnType<typeof useConfirmCompanyAffiliateApplication>;
export type ConfirmCompanyAffiliateApplicationMutationResult = Apollo.MutationResult<ConfirmCompanyAffiliateApplication>;
export type ConfirmCompanyAffiliateApplicationMutationOptions = Apollo.BaseMutationOptions<ConfirmCompanyAffiliateApplication, ConfirmCompanyAffiliateApplicationVariables>;