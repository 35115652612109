import { FC, useMemo } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useGetOutgoingMoneyTransfersColumns } from './components/OutgoingMoneyTransfersTable/hooks/useGetOutgoingMoneyTransfersColumns';
import { OutgoingMoneyTransfersTable } from './components/OutgoingMoneyTransfersTable';

interface Props {
  id: string;
}

export const OutgoingMoneyTransfersBlock: FC<Props> = withBlockProvider(
  ({ id: companyId }) => {
    const { columns } = useGetOutgoingMoneyTransfersColumns(false);

    const routeToCustomPage = useMemo(
      () =>
        generatePath(Route.COMPANY_OUTGOING_MONEY_TRANSFERS, { id: companyId }),
      [companyId]
    );

    return (
      <OutgoingMoneyTransfersTable
        companyId={companyId}
        columns={columns}
        routeToCustomPage={routeToCustomPage}
        isFullScreenTable={false}
      />
    );
  }
);
