import { RestrictionTypeName } from 'commonTypes';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useCancelBadBankRestriction } from './queries/generated/CancelBadBankRestriction';
import { useCancelChargeBackRestriction } from './queries/generated/CancelChargeBackRestriction';
import { useCancelWithdrawalRestriction } from './queries/generated/CancelWithdrawalRestriction';
import { useCancelReceiveGiftRestriction } from './queries/generated/CancelReceiveGiftRestriction';
import { GetLastRestrictionsDocument } from '../LastRestrictions/queries/generated/GetLastRestrictions';

interface Value {
  cancelRestriction?: () => void;
  loading?: boolean;
}

export const useRestrictionModal = (
  type: RestrictionTypeName,
  playerId: string
): Value => {
  const { dispatch } = useContext(ModalContext);

  let cancelRestriction;

  const [
    cancelReceiveGiftRestriction,
    {
      data: cancelReceiveGiftRestrictionData,
      loading: cancelReceiveGiftRestrictionLoading,
    },
  ] = useCancelReceiveGiftRestriction({
    variables: {
      input: {
        playerId,
      },
    },

    refetchQueries: [
      { query: GetLastRestrictionsDocument, variables: { playerId } },
    ],
  });

  const [
    cancelBadBankRestriction,
    {
      data: cancelBadBankRestrictionData,
      loading: cancelBadBankRestrictionLoading,
    },
  ] = useCancelBadBankRestriction({
    variables: {
      input: {
        playerId,
      },
    },
    refetchQueries: [
      { query: GetLastRestrictionsDocument, variables: { playerId } },
    ],
  });

  const [
    cancelChargeBackRestriction,
    {
      data: cancelChargeBackRestrictionData,
      loading: cancelChargeBackRestrictionLoading,
    },
  ] = useCancelChargeBackRestriction({
    variables: {
      input: {
        playerId,
      },
    },
    refetchQueries: [
      { query: GetLastRestrictionsDocument, variables: { playerId } },
    ],
  });

  const [
    cancelWithdrawalRestriction,
    {
      data: cancelWithdrawalRestrictionData,
      loading: cancelWithdrawalRestrictionLoading,
    },
  ] = useCancelWithdrawalRestriction({
    variables: {
      input: {
        playerId,
      },
    },
    refetchQueries: [
      { query: GetLastRestrictionsDocument, variables: { playerId } },
    ],
  });

  switch (type) {
    case RestrictionTypeName.BadBank:
      cancelRestriction = cancelBadBankRestriction;
      break;
    case RestrictionTypeName.ChargeBack:
      cancelRestriction = cancelChargeBackRestriction;
      break;
    case RestrictionTypeName.ReceiveGift:
      cancelRestriction = cancelReceiveGiftRestriction;
      break;
    case RestrictionTypeName.Withdrawal:
      cancelRestriction = cancelWithdrawalRestriction;
      break;
    default:
      break;
  }

  useEffect(() => {
    if (
      cancelWithdrawalRestrictionData ||
      cancelReceiveGiftRestrictionData ||
      cancelChargeBackRestrictionData ||
      cancelBadBankRestrictionData
    ) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Ограничение отменено');
    }
  }, [
    cancelBadBankRestrictionData,
    cancelChargeBackRestrictionData,
    cancelReceiveGiftRestrictionData,
    cancelWithdrawalRestrictionData,
    dispatch,
  ]);

  return {
    cancelRestriction,
    loading:
      cancelWithdrawalRestrictionLoading ||
      cancelReceiveGiftRestrictionLoading ||
      cancelChargeBackRestrictionLoading ||
      cancelBadBankRestrictionLoading,
  };
};
