import { AccountEventTypeName } from 'commonTypes';
import { SumFlowDirection } from 'generatedGraphql';
import styles from './SumTitle.module.scss';
import { AccountEventData } from '../../../../queries/fragments/generated/AccountEventData';

export const getEventBonusBalance = (event: AccountEventData) => {
  switch (event.__typename) {
    case AccountEventTypeName.BonusAccountEvent:
      return event.bonusEventBonusBalance;
    case AccountEventTypeName.GameAccountEvent:
      return event.gameEventBonusBalance;
    default:
      return null;
  }
};

export const mapDirectionToClassName: Record<SumFlowDirection, string> = {
  [SumFlowDirection.Credit]: styles.creditBalance,
  [SumFlowDirection.Debit]: styles.debitBalance,
};
