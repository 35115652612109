import { UserPrivilege } from 'generatedUserPrivilege';
import { CompanyAffiliateApplicationStatus } from 'generatedGraphql';
import { useAuth } from 'commonComponents/Auth';

interface UseGetButtonsVisibilityReturn {
  hasSuspendButton: boolean;
  hasAssignButton: boolean;
  hasConfirmButton: boolean;
}

export const useGetButtonsVisibility = (
  companyAffiliateApplicationStatus: CompanyAffiliateApplicationStatus,
  isEditable: boolean
): UseGetButtonsVisibilityReturn => {
  const auth = useAuth();

  const isAllowedToUpdateApplication = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const hasAssignButton =
    isAllowedToUpdateApplication([
      UserPrivilege.AssignCompanyAffiliateApplication,
    ]) &&
    companyAffiliateApplicationStatus ===
      CompanyAffiliateApplicationStatus.Pending;

  const hasSuspendButton =
    isAllowedToUpdateApplication([
      UserPrivilege.SuspendCompanyAffiliateApplication,
    ]) &&
    isEditable &&
    companyAffiliateApplicationStatus ===
      CompanyAffiliateApplicationStatus.InProgress;

  const hasConfirmButton =
    isAllowedToUpdateApplication([
      UserPrivilege.ConfirmCompanyAffiliateApplication,
    ]) &&
    isEditable &&
    companyAffiliateApplicationStatus ===
      CompanyAffiliateApplicationStatus.InProgress;

  return { hasAssignButton, hasSuspendButton, hasConfirmButton };
};
