import errorToast from 'ui/Toast/ErrorToast';
import { ListAllOperations } from 'generatedGraphql';
import { toast } from 'react-toastify';
import { RewardTariffType } from 'commonTypes';
import { useAcceptAllRewards as useAcceptAllRewardsMutation } from '../queries/generated/AcceptAllRewards';

export const useAcceptAllRewards = (rewardTariffType: RewardTariffType) => {
  const [acceptAllRewards, { loading }] = useAcceptAllRewardsMutation({
    variables: {
      input: {
        tariffType: rewardTariffType,
      },
    },
  });

  const acceptAllRewardsAction = () => {
    acceptAllRewards({
      refetchQueries: [ListAllOperations.Query.GetRewards],
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'acceptAllRewardsErrorToast',
        });
      },
      onCompleted: () => {
        toast.success('Вознаграждения подтверждены');
      },
    });
  };

  return {
    acceptAllRewardsAction,
    loading,
  };
};
