import { ForwardRefExoticComponent } from 'react';
import { InputNumber } from 'ui/Input/InputNumber/InputNumber';
import { Input as InternalInput, InputProps } from './Input/Input';
import { InputSearch } from './InputSearch/InputSearch';
import { InputSum } from './InputSum/InputSum';
import { Textarea } from './Textarea/Textarea';

type CompoundedComponent = ForwardRefExoticComponent<InputProps> & {
  Textarea: typeof Textarea;
  Search: typeof InputSearch;
  Sum: typeof InputSum;
  Number: typeof InputNumber;
};

const Input = InternalInput as CompoundedComponent;

Input.Textarea = Textarea;
Input.Search = InputSearch;
Input.Sum = InputSum;
Input.Number = InputNumber;

export { InputSize } from './const';
export default Input;
