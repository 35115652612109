import { RewardTariffType } from 'commonTypes';
import { CreateMediaItemReferralLinkValues } from '../../../types';
import { useCreateReferralLink } from './useCreateReferralLink';

export const useCreateMediaItemReferralLink = () => {
  const {
    createMediaItemError,
    createMediaItemData,
    createMediaItemLoading,
    createPartnerMediaItem,
    createCompanyMediaItem,
    createCompanyAffiliateMediaItem,
  } = useCreateReferralLink();

  const createMediaItemReferralLink = ({
    name,
    partnerId,
    companyId,
    companyAffiliateId,
    code,
    cpaRewardTariffId,
    fallbackTariffId,
    revShareRewardTariffId,
    typeRewardTariff,
    mediaCampaignId,
  }: CreateMediaItemReferralLinkValues) => {
    const hasCpaTariff =
      !!cpaRewardTariffId && typeRewardTariff === RewardTariffType.Cpa;
    const hasRevShareTariff =
      !!revShareRewardTariffId &&
      typeRewardTariff === RewardTariffType.RevShare;

    const referralLinkMediaItemInput = {
      name,
      code,
      mediaItemRewardTariff: {
        ...(hasCpaTariff
          ? {
              cpaRewardTariff: {
                id: cpaRewardTariffId,
                fallbackRewardTariffId: fallbackTariffId,
              },
            }
          : null),

        ...(hasRevShareTariff
          ? {
              revShareRewardTariff: {
                id: revShareRewardTariffId,
              },
            }
          : null),
      },
      mediaCampaignId,
    };

    if (companyId && !companyAffiliateId) {
      createCompanyMediaItem({
        variables: {
          input: {
            referralLinkMediaItem: referralLinkMediaItemInput,
            companyId,
          },
        },
      });
    }

    if (companyAffiliateId) {
      createCompanyAffiliateMediaItem({
        variables: {
          input: {
            referralLinkMediaItem: referralLinkMediaItemInput,
            companyAffiliateId,
          },
        },
      });
    }

    if (partnerId) {
      createPartnerMediaItem({
        variables: {
          input: {
            referralLinkMediaItem: referralLinkMediaItemInput,
            partnerId,
          },
        },
      });
    }
  };

  return {
    createMediaItemReferralLink,
    createReferralLinkMediaItemError: createMediaItemError,
    createReferralLinkMediaItemDate: createMediaItemData,
    createReferralLinkMediaItemLoading: createMediaItemLoading,
  };
};
