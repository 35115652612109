import { AuthStorage, authStorageKeys } from './authStorage';
import { BlocksStateStorage } from './blocksStateStorage';
import { SearchListStorage } from './searchListStorage';

interface StorageType {
  searchListStorage: SearchListStorage;
  authStorage: AuthStorage;
  blocksStateStorage: BlocksStateStorage;
  clear: () => void;
}

export const Storage: StorageType = {
  searchListStorage: new SearchListStorage(),
  authStorage: new AuthStorage(),
  blocksStateStorage: new BlocksStateStorage(),

  clear() {
    this.authStorage.clearLoggedInKey();
    this.searchListStorage.clearAllSearch();
    this.blocksStateStorage.clearOpenedBlocks();
  },
};

export { authStorageKeys };
