import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { ChargeBackRestrictionData } from './ChargeBackRestrictionData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateChargeBackRestrictionVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateChargeBackRestrictionInput;
}>;


export type CreateChargeBackRestriction = { __typename: 'Mutation', createChargeBackRestriction: { __typename: 'CreateChargeBackRestrictionPayload', chargeBackRestriction: { __typename: 'ChargeBackRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } } };


export const CreateChargeBackRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateChargeBackRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateChargeBackRestrictionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createChargeBackRestriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargeBackRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChargeBackRestrictionData"}}]}}]}}]}},...ChargeBackRestrictionData.definitions]} as unknown as DocumentNode;
export type CreateChargeBackRestrictionMutationFn = Apollo.MutationFunction<CreateChargeBackRestriction, CreateChargeBackRestrictionVariables>;

/**
 * __useCreateChargeBackRestriction__
 *
 * To run a mutation, you first call `useCreateChargeBackRestriction` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargeBackRestriction` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargeBackRestriction, { data, loading, error }] = useCreateChargeBackRestriction({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargeBackRestriction(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChargeBackRestriction, CreateChargeBackRestrictionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateChargeBackRestriction, CreateChargeBackRestrictionVariables>(CreateChargeBackRestrictionDocument, options);
      }
export type CreateChargeBackRestrictionHookResult = ReturnType<typeof useCreateChargeBackRestriction>;
export type CreateChargeBackRestrictionMutationResult = Apollo.MutationResult<CreateChargeBackRestriction>;
export type CreateChargeBackRestrictionMutationOptions = Apollo.BaseMutationOptions<CreateChargeBackRestriction, CreateChargeBackRestrictionVariables>;