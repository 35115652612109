import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AcceptAllRewardsVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AcceptAllRewardsInput;
}>;


export type AcceptAllRewards = { __typename: 'Mutation', acceptAllRewards: { __typename: 'AcceptAllRewardsPayload', void?: any | null } };


export const AcceptAllRewardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptAllRewards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptAllRewardsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptAllRewards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type AcceptAllRewardsMutationFn = Apollo.MutationFunction<AcceptAllRewards, AcceptAllRewardsVariables>;

/**
 * __useAcceptAllRewards__
 *
 * To run a mutation, you first call `useAcceptAllRewards` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAllRewards` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAllRewards, { data, loading, error }] = useAcceptAllRewards({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptAllRewards(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptAllRewards, AcceptAllRewardsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptAllRewards, AcceptAllRewardsVariables>(AcceptAllRewardsDocument, options);
      }
export type AcceptAllRewardsHookResult = ReturnType<typeof useAcceptAllRewards>;
export type AcceptAllRewardsMutationResult = Apollo.MutationResult<AcceptAllRewards>;
export type AcceptAllRewardsMutationOptions = Apollo.BaseMutationOptions<AcceptAllRewards, AcceptAllRewardsVariables>;