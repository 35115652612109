import { FormikHelpers } from 'formik';
import { useCallback, useContext, useMemo } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { initialValues } from '../../../../../../../../RewardTariffs/components/CreateRewardTariff/const';
import {
  AffiliateInApproveProcessStatus,
  CompanyAffiliateApplicationContext,
  setAffiliatesInProgressAction,
  setEditableAffiliateAction,
  EditableAffiliate,
} from '../../../../../store';
import { CompanyAffiliateApplicationInitialValues } from '../types';
import { CompanyAffiliateApplicationModalSteps } from '../../../../../const';

export const useGetProcessAffiliateHandlers = () => {
  const { state, dispatch } = useContext(CompanyAffiliateApplicationContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const affiliatesInProgressState = useMemo(
    () => ({ ...state.affiliatesInProgress }),
    [state.affiliatesInProgress]
  );

  const approveAffiliate = (
    values: CompanyAffiliateApplicationInitialValues,
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue']
  ) => {
    const affiliateIds = Object.keys(values);

    affiliateIds.forEach((id) => {
      if (values[id] === true) {
        affiliatesInProgressState[id] =
          AffiliateInApproveProcessStatus.Approved;

        dispatch(
          setAffiliatesInProgressAction({
            affiliatesInProgress: affiliatesInProgressState,
          })
        );

        setFieldValue(id, null);
      }
    });
  };

  const declineAffiliate = (
    values: CompanyAffiliateApplicationInitialValues,
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue']
  ) => {
    const affiliateIds = Object.keys(values);

    affiliateIds.forEach((id) => {
      if (values[id] === true) {
        affiliatesInProgressState[id] =
          AffiliateInApproveProcessStatus.Declined;

        dispatch(
          setAffiliatesInProgressAction({
            affiliatesInProgress: affiliatesInProgressState,
          })
        );

        setFieldValue(id, null);
      }
    });
  };

  const resetAffiliateStatus = (
    id: string,
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue']
  ) => {
    affiliatesInProgressState[id] = AffiliateInApproveProcessStatus.InProcess;

    dispatch(
      setAffiliatesInProgressAction({
        affiliatesInProgress: affiliatesInProgressState,
      })
    );

    setFieldValue(id, false);
  };

  const changeAvailableAffiliateCheckboxes = (
    values: CompanyAffiliateApplicationInitialValues,
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue']
  ) => {
    const affiliateIds = Object.keys(values);
    const newValues: CompanyAffiliateApplicationInitialValues = {};

    const hasSomeUnChecked = Object.values(values).includes(false);

    affiliateIds.forEach((id) => {
      if (values[id] !== null) {
        if (hasSomeUnChecked) {
          newValues[id] = true;
          setFieldValue(id, true);
        } else {
          newValues[id] = false;
          setFieldValue(id, false);
        }
      }
    });
  };

  const goToEditPartnerCompanyAffiliatesApplicationStep = useCallback(
    ({ name, note, affiliateId }: EditableAffiliate) => {
      dispatch(
        setEditableAffiliateAction({
          editableAffiliate: {
            name,
            note,
            affiliateId,
          },
        })
      );

      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: CompanyAffiliateApplicationModalSteps.EditPartnerCompanyAffiliate,
        },
      });
    },
    [dispatch, modalDispatch]
  );

  return {
    resetAffiliateStatus,
    declineAffiliate,
    approveAffiliate,
    changeAvailableAffiliateCheckboxes,
    goToEditPartnerCompanyAffiliatesApplicationStep,
  };
};
