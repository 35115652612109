import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ResetPartnerTwoFactorVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ResetPartnerTwoFactorInput;
}>;


export type ResetPartnerTwoFactor = { __typename: 'Mutation', resetPartnerTwoFactor: { __typename: 'ResetPartnerTwoFactorPayload', partner: { __typename: 'Partner', id: string, twoFactorEnabled: boolean } } };


export const ResetPartnerTwoFactorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPartnerTwoFactor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPartnerTwoFactorInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPartnerTwoFactor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"twoFactorEnabled"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ResetPartnerTwoFactorMutationFn = Apollo.MutationFunction<ResetPartnerTwoFactor, ResetPartnerTwoFactorVariables>;

/**
 * __useResetPartnerTwoFactor__
 *
 * To run a mutation, you first call `useResetPartnerTwoFactor` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPartnerTwoFactor` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPartnerTwoFactor, { data, loading, error }] = useResetPartnerTwoFactor({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPartnerTwoFactor(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPartnerTwoFactor, ResetPartnerTwoFactorVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPartnerTwoFactor, ResetPartnerTwoFactorVariables>(ResetPartnerTwoFactorDocument, options);
      }
export type ResetPartnerTwoFactorHookResult = ReturnType<typeof useResetPartnerTwoFactor>;
export type ResetPartnerTwoFactorMutationResult = Apollo.MutationResult<ResetPartnerTwoFactor>;
export type ResetPartnerTwoFactorMutationOptions = Apollo.BaseMutationOptions<ResetPartnerTwoFactor, ResetPartnerTwoFactorVariables>;