import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { CompanyBasicData } from '../../../../../queries/generated/CompanyBasicData';
import { useOpenResetCompanyTwoFactorModal } from './useOpenResetCompanyTwoFactorModal';

interface UseCompanyMarkersParams {
  company: CompanyBasicData;
}

export const useCompanyMarkers = ({ company }: UseCompanyMarkersParams) => {
  const auth = useAuth();
  const { twoFactorEnabled } = company;

  const isMarkerDisabled = (privileges: Array<UserPrivilege>) =>
    !auth.privileges.isSomeGranted({
      privileges,
    });

  const { openResetCompanyTwoFactorModal } = useOpenResetCompanyTwoFactorModal({
    company,
  });

  return [
    {
      id: 'twoFactorEnable',
      content: twoFactorEnabled && '2FA включена',
      isHidden: !twoFactorEnabled,
      onClick: openResetCompanyTwoFactorModal,
      isDisabled: isMarkerDisabled([UserPrivilege.ResetCompanyTwoFactor]),
    },
    {
      id: 'twoFactorDisable',
      content: !twoFactorEnabled && '2FA отключена',
      isHidden: twoFactorEnabled,
      onClick: openResetCompanyTwoFactorModal,
      isDisabled: isMarkerDisabled([UserPrivilege.ResetCompanyTwoFactor]),
    },
  ];
};
