import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PartnerOutgoingMoneyTransferData } from '../../../OutgoingMoneyTransfersTable/queries/generated/PartnerOutgoingMoneyTransferData';
import { PartnerOutgoingMoneyTransferBasicData } from '../../../OutgoingMoneyTransfersTable/queries/generated/PartnerOutgoingMoneyTransferBasicData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerOutgoingMoneyTransferVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  outgoingMoneyTransferId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerOutgoingMoneyTransfer = { __typename: 'Query', partner: { __typename: 'Partner', id: string, outgoingMoneyTransfer: { __typename: 'AffiliateOutgoingMoneyTransfer', createdAt: string, id: string, player: { __typename: 'Player', email?: string | null, id: string, moneyTransferAccount?: string | null, name: string, phone?: string | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const GetPartnerOutgoingMoneyTransferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerOutgoingMoneyTransfer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"outgoingMoneyTransferId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"outgoingMoneyTransfer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"outgoingMoneyTransferId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PartnerOutgoingMoneyTransferData"}}]}}]}}]}},...PartnerOutgoingMoneyTransferData.definitions,...PartnerOutgoingMoneyTransferBasicData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPartnerOutgoingMoneyTransfer__
 *
 * To run a query within a React component, call `useGetPartnerOutgoingMoneyTransfer` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerOutgoingMoneyTransfer` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerOutgoingMoneyTransfer({
 *   variables: {
 *      id: // value for 'id'
 *      outgoingMoneyTransferId: // value for 'outgoingMoneyTransferId'
 *   },
 * });
 */
export function useGetPartnerOutgoingMoneyTransfer(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables> & ({ variables: GetPartnerOutgoingMoneyTransferVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables>(GetPartnerOutgoingMoneyTransferDocument, options);
      }
export function useGetPartnerOutgoingMoneyTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables>(GetPartnerOutgoingMoneyTransferDocument, options);
        }
export function useGetPartnerOutgoingMoneyTransferSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables>(GetPartnerOutgoingMoneyTransferDocument, options);
        }
export type GetPartnerOutgoingMoneyTransferHookResult = ReturnType<typeof useGetPartnerOutgoingMoneyTransfer>;
export type GetPartnerOutgoingMoneyTransferLazyQueryHookResult = ReturnType<typeof useGetPartnerOutgoingMoneyTransferLazyQuery>;
export type GetPartnerOutgoingMoneyTransferSuspenseQueryHookResult = ReturnType<typeof useGetPartnerOutgoingMoneyTransferSuspenseQuery>;
export type GetPartnerOutgoingMoneyTransferQueryResult = Apollo.QueryResult<GetPartnerOutgoingMoneyTransfer, GetPartnerOutgoingMoneyTransferVariables>;