import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { useContext, useEffect, useState } from 'react';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { useGetPartnerApplication } from '../queries/generated/GetPartnerApplication';
import { PartnerApplicationModalSteps } from '../../../const';

interface UsePartnerApplicationModalParams {
  partnerApplicationId: string;
}

export const usePartnerApplicationModal = ({
  partnerApplicationId,
}: UsePartnerApplicationModalParams) => {
  const { state, dispatch } = useContext(ModalContext);

  const [referralLinkData, setReferralLinkData] =
    useState<null | ReferralLinkData>(null);

  const { data, loading, error } = useGetPartnerApplication({
    variables: {
      id: partnerApplicationId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'partner-application-error' },
    hasData: !!data,
  });

  useEffect(() => {
    if (state.step === PartnerApplicationModalSteps.DeclineStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Отклонение заявки',
          subTitle: `Заявка партнёра #${partnerApplicationId}`,
        },
      });
    }

    if (state.step === PartnerApplicationModalSteps.CreateReferralLink) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание медиа элемента',
          subTitle: `Заявка партнёра #${partnerApplicationId}`,
        },
      });
    }

    if (state.step === PartnerApplicationModalSteps.UpdateReferralLink) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Редактирование медиа элемента',
          subTitle: `Заявка партнёра #${partnerApplicationId}`,
        },
      });
    }

    if (state.step === PartnerApplicationModalSteps.BaseStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${partnerApplicationId}`,
          subTitle: 'Заявка партнёра',
        },
      });
    }
  }, [dispatch, partnerApplicationId, state.step]);

  return {
    partnerApplication: data?.partnerApplication,
    setReferralLinkData,
    referralLinkData,
  };
};
