import { FC } from 'react';
import { paymentProcessorMap, paymentStatusMap } from 'src/const';
import { PaymentStatus } from 'generatedGraphql';
import { CopyModalRow } from 'ui/CopyModalRow';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DataCardWithDropdown } from 'commonComponents/DataCardWithDropdown';
import { BoldSumWithWarningIconRowByCompare } from 'commonComponents/DataCard';
import { WithdrawalListPaymentData } from '../../queries/generated/WithdrawalListPaymentData';
import { getStatusIcon } from './helpers';
import { ClosedDropdownLeftSubTitle } from './components/ClosedDropdownLeftSubTitle';
import { UpdatePaymentButtons } from './components/UpdatePaymentButtons';

interface Props {
  payment: WithdrawalListPaymentData;
}

export const WithdrawalPayment: FC<Props> = ({ payment }) => {
  const {
    paidSum,
    externalPaymentId,
    createdAt,
    updatedAt,
    sum,
    processor,
    id,
    error,
    status,
  } = payment;

  const getStatusText = (paymentStatus: PaymentStatus) => {
    if (paymentStatus === PaymentStatus.Failed && error) {
      return `Ошибка: ${error.code}`;
    }

    return paymentStatusMap[paymentStatus];
  };

  const isCompleteStatus = status === PaymentStatus.Complete;

  return (
    <DataCardWithDropdown
      header={{
        leftTitle: paymentProcessorMap[processor] || processor,
        rightTitle: formatSumWithCurrency(sum.amount, sum.currency),
        leftSubTitle: (
          <CopyModalRow
            isRightIconPosition
            value={`#${id}`}
            copyValue={id}
            toastText="ID скопирован"
          />
        ),
        closedDropdownLeftSubTitle: (
          <ClosedDropdownLeftSubTitle status={status} updatedAt={updatedAt} />
        ),
      }}
      withBorder
      statusConfig={{
        status: getStatusText(status),
        text: error?.message,
        iconConfig: getStatusIcon(status),
      }}
      rows={[
        paidSum &&
          !isCompleteStatus && {
            title: 'Фактическая сумма',
            value: (
              <BoldSumWithWarningIconRowByCompare
                comparedSum={sum}
                renderedSum={paidSum}
              />
            ),
          },
        externalPaymentId && {
          title: 'Внешний ID',
          value: (
            <CopyModalRow
              value={externalPaymentId}
              toastText="Внешний ID скопирован"
            />
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <UpdatePaymentButtons
          paymentId={id}
          paymentStatus={status}
          paymentPaidSum={sum}
        />
      }
    />
  );
};
