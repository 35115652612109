import { DeclinePartnerApplicationInput } from 'generatedGraphql';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useDeclinePartnerApplication } from '../queries/generated/DeclinePartnerApplication';
import { PartnerApplicationModalSteps } from '../../../const';

export const useDeclinePartnerApplicationData = () => {
  const [declinePartnerApplication, { data, error, loading }] =
    useDeclinePartnerApplication();

  const { state, dispatch } = useContext(ModalContext);

  const declinePartnerApplicationHandler = async ({
    reason,
    id,
  }: DeclinePartnerApplicationInput) => {
    await declinePartnerApplication({
      variables: {
        input: {
          id,
          reason,
        },
      },
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (state.step === PartnerApplicationModalSteps.DeclineStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: PartnerApplicationModalSteps.BaseStep,
        },
      });

      toast.success('Заявка отклонена');
    }
  }, [data, dispatch, state.step]);

  return {
    declinePartnerApplicationHandler,
    error,
    loading,
  };
};
