import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { NoteData } from '../../../../queries/generated/NoteData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdatePlayerNoteVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdatePlayerNoteInput;
}>;


export type UpdatePlayerNote = { __typename: 'Mutation', updatePlayerNote: { __typename: 'UpdatePlayerNotePayload', player: { __typename: 'Player', id: string, note?: { __typename: 'Note', text: string } | null } } };


export const UpdatePlayerNoteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePlayerNote"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePlayerNoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePlayerNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NoteData"}}]}}]}}]}}]}},...NoteData.definitions]} as unknown as DocumentNode;
export type UpdatePlayerNoteMutationFn = Apollo.MutationFunction<UpdatePlayerNote, UpdatePlayerNoteVariables>;

/**
 * __useUpdatePlayerNote__
 *
 * To run a mutation, you first call `useUpdatePlayerNote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerNote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerNote, { data, loading, error }] = useUpdatePlayerNote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlayerNote(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlayerNote, UpdatePlayerNoteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePlayerNote, UpdatePlayerNoteVariables>(UpdatePlayerNoteDocument, options);
      }
export type UpdatePlayerNoteHookResult = ReturnType<typeof useUpdatePlayerNote>;
export type UpdatePlayerNoteMutationResult = Apollo.MutationResult<UpdatePlayerNote>;
export type UpdatePlayerNoteMutationOptions = Apollo.BaseMutationOptions<UpdatePlayerNote, UpdatePlayerNoteVariables>;