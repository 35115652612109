import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { CompanyAdditionalData } from '../../../../queries/generated/CompanyAdditionalData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCompanyIdentitiesVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateCompanyIdentitiesInput;
}>;


export type UpdateCompanyIdentities = { __typename: 'Mutation', updateCompanyIdentities: { __typename: 'UpdateCompanyIdentitiesPayload', company: { __typename: 'Company', createdAt: string, email: string, id: string, name: string, trafficSource?: string | null, website?: string | null, contactForCommunication: { __typename: 'ContactForCommunication', type: CommonType.ContactForCommunicationType, value: string }, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus, player: { __typename: 'Player', email?: string | null, id: string, name: string, phone?: string | null } } | null } } };


export const UpdateCompanyIdentitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompanyIdentities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateCompanyIdentitiesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCompanyIdentities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyAdditionalData"}}]}}]}}]}},...CompanyAdditionalData.definitions]} as unknown as DocumentNode;
export type UpdateCompanyIdentitiesMutationFn = Apollo.MutationFunction<UpdateCompanyIdentities, UpdateCompanyIdentitiesVariables>;

/**
 * __useUpdateCompanyIdentities__
 *
 * To run a mutation, you first call `useUpdateCompanyIdentities` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyIdentities` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyIdentities, { data, loading, error }] = useUpdateCompanyIdentities({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyIdentities(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyIdentities, UpdateCompanyIdentitiesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCompanyIdentities, UpdateCompanyIdentitiesVariables>(UpdateCompanyIdentitiesDocument, options);
      }
export type UpdateCompanyIdentitiesHookResult = ReturnType<typeof useUpdateCompanyIdentities>;
export type UpdateCompanyIdentitiesMutationResult = Apollo.MutationResult<UpdateCompanyIdentities>;
export type UpdateCompanyIdentitiesMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyIdentities, UpdateCompanyIdentitiesVariables>;