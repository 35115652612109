import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyReferralLinkMediaItemNameVariables = SchemaTypes.Exact<{
  companyId: SchemaTypes.Scalars['ID']['input'];
  referralLinkMediaItemId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyReferralLinkMediaItemName = { __typename: 'Query', company: { __typename: 'Company', id: string, referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string } } };


export const GetCompanyReferralLinkMediaItemNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyReferralLinkMediaItemName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCompanyReferralLinkMediaItemName__
 *
 * To run a query within a React component, call `useGetCompanyReferralLinkMediaItemName` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyReferralLinkMediaItemName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyReferralLinkMediaItemName({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      referralLinkMediaItemId: // value for 'referralLinkMediaItemId'
 *   },
 * });
 */
export function useGetCompanyReferralLinkMediaItemName(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables> & ({ variables: GetCompanyReferralLinkMediaItemNameVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables>(GetCompanyReferralLinkMediaItemNameDocument, options);
      }
export function useGetCompanyReferralLinkMediaItemNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables>(GetCompanyReferralLinkMediaItemNameDocument, options);
        }
export function useGetCompanyReferralLinkMediaItemNameSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables>(GetCompanyReferralLinkMediaItemNameDocument, options);
        }
export type GetCompanyReferralLinkMediaItemNameHookResult = ReturnType<typeof useGetCompanyReferralLinkMediaItemName>;
export type GetCompanyReferralLinkMediaItemNameLazyQueryHookResult = ReturnType<typeof useGetCompanyReferralLinkMediaItemNameLazyQuery>;
export type GetCompanyReferralLinkMediaItemNameSuspenseQueryHookResult = ReturnType<typeof useGetCompanyReferralLinkMediaItemNameSuspenseQuery>;
export type GetCompanyReferralLinkMediaItemNameQueryResult = Apollo.QueryResult<GetCompanyReferralLinkMediaItemName, GetCompanyReferralLinkMediaItemNameVariables>;