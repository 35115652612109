import { FC } from 'react';
import { useParams } from 'react-router';
import RestrictionsTable from '../../components/RestrictionsBlock/components/RestrictionsTable';
import { useGetRestrictionsHistoryColumns } from '../../components/RestrictionsBlock/hooks/useGetRestrictionsHistoryColumns';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const Restrictions: FC = () => {
  const { columns } = useGetRestrictionsHistoryColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <RestrictionsTable
        playerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PlayerPageContainer>
  );
};
