import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import { MediaItemTypeName } from 'commonTypes';
import { mediaItemsTabs } from './const';

interface Props {
  currentTabId?: MediaItemTypeName;
  setMediaItemsType: (type: MediaItemTypeName) => void;
}

export const MediaItemsTabs: FC<Props> = ({
  currentTabId,
  setMediaItemsType,
}) => {
  const handleTabChange = (id: MediaItemTypeName) => {
    setMediaItemsType(id);
  };

  return (
    <Tabs
      tabs={mediaItemsTabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
