import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { OrderDirection, CompanyPartnerReportOrderBy } from 'generatedGraphql';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetCpaCompanyPartnersReportLazyQuery } from '../queries/generated/GetCpaCompanyPartnersReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

const cellHeight = Heights.defaultCellHeight;

interface UseGetCpaCompanyPartnersReportParams {
  companyId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaCompanyPartnersReport = ({
  companyId,
  isAllTimeCpa,
}: UseGetCpaCompanyPartnersReportParams) => {
  const period = useGetPeriodOfStatistic();

  const { currency } = useGetCurrency();

  const [
    loadCpaCompanyPartnersReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCpaCompanyPartnersReportLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
      currency,
      id: companyId,
      ordering: {
        orderBy: CompanyPartnerReportOrderBy.AllDeposit,
        direction: OrderDirection.Desc,
      },
      period,
      showAllTimeCpa: isAllTimeCpa,
    },
  });

  const cpaCompanyPartnerReport = data?.company.cpaCompanyPartnerReport;
  const edges = cpaCompanyPartnerReport?.edges;
  const total = cpaCompanyPartnerReport?.total;
  const hasTotalRow = !!total;
  const company = cpaCompanyPartnerReport?.company;
  const endCursor = cpaCompanyPartnerReport?.pageInfo.endCursor;
  const hasNextPage = cpaCompanyPartnerReport?.pageInfo.hasNextPage;

  const cpaCompanyPartnersReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    report?.unshift(company);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [company, edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  return {
    loadCpaCompanyPartnersReport,
    data,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    cpaCompanyPartnersReportList,
    company: data?.company,
    hasNextPage,
    hasTotalRow,
  };
};
