import { FC } from 'react';
import { Route } from 'src/router/routes.const';
import { Link } from 'react-router-dom';
import { CpaCompanyPartnersReportRow } from '../../types';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { useGeneratePathWithSavePeriodQueryParams } from '../../../../hooks/useGeneratePathWithSavePeriodQueryParams';

interface Props {
  row: CpaCompanyPartnersReportRow;
}

export const CpaMediaOwnerCell: FC<Props> = ({ row }) => {
  const generatePathWithPeriodQueryParams =
    useGeneratePathWithSavePeriodQueryParams();

  if (row.company) {
    const { id, name, email } = row.company;

    return (
      <MultilineCellWithLinkWithPeriodQueryParams
        id={id}
        title={`Компания ${name}`}
        subTitle={email}
        route={Route.STATISTIC_CPA_COMPANY}
      />
    );
  }

  const {
    id: affiliateId,
    name,
    company: { id: companyId },
  } = row.companyPartner;

  const path = generatePathWithPeriodQueryParams({
    originalPath: Route.STATISTIC_CPA_COMPANY_AFFILIATE,
    params: { companyId, affiliateId },
  });

  return (
    <Link to={path} target="_self" rel="noopener noreferrer">
      {name}
    </Link>
  );
};
