import { Sum } from 'generatedGraphql';
import { formatSumWithCurrency } from './accounting';

interface CashbackFormulaParams {
  paidCashbackSum: Sum;
  upcomingCashback: {
    sum: Sum;
  };
  creditSum: Sum;
  debitSum: Sum;
}

export const getCashbackFormula = ({
  paidCashbackSum,
  upcomingCashback,
  debitSum,
  creditSum,
}: CashbackFormulaParams): string =>
  `(${creditSum.amount} - ${debitSum.amount}) * 10% - ${
    paidCashbackSum.amount
  } = ${formatSumWithCurrency(
    upcomingCashback.sum.amount,
    upcomingCashback.sum.currency
  )}`;
