import { useModalManageData } from 'ui/Modal';
import { useGetRevShareRewardTariff } from '../../../../../queries/generated/GetRevShareRewardTariff';

interface UseRevShareRewardTariffModalParams {
  revShareRewardTariffsId: string;
}

export const useRevShareRewardTariffModal = ({
  revShareRewardTariffsId,
}: UseRevShareRewardTariffModalParams) => {
  const { data, loading, error } = useGetRevShareRewardTariff({
    variables: {
      revShareRewardTariffsId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'rev-share-reward-tariffs-error' },
    hasData: !!data,
  });

  return { revShareRewardTariff: data?.revShareRewardTariff };
};
