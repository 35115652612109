import { FC } from 'react';
import FormRow from 'ui/FormRow';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';

export const CpaForm: FC = () => (
  <>
    <FormRow isWide>
      <CpaTariffDropdown name="cpaTariffId" label="Партнёрский тариф" />
    </FormRow>
    <FormRow isWide>
      <RevShareTariffDropdown name="revShareTariffId" label="Резервный тариф" />
    </FormRow>
  </>
);
