type Primitive = string | boolean | null | number;

type Value =
  | Primitive
  | Array<Primitive | Record<string | number, Primitive>>
  | Record<string | number, Primitive>;
export class LocalStorage {
  private storage: typeof localStorage;

  constructor() {
    this.storage = window.localStorage;
  }

  public getValue = <T = Value>(key: string): T | null => {
    try {
      const item = this.storage.getItem(key);

      if (!item) {
        return null;
      }

      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  };

  public setValue = (key: string, value: Value): void => {
    this.storage.setItem(key, JSON.stringify(value));
  };

  public removeValue = (key: string): void => {
    this.storage.removeItem(key);
  };
}
