import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeletePostbackDisabledNotificationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeletePostbackDisabledNotificationInput;
}>;


export type DeletePostbackDisabledNotification = { __typename: 'Mutation', deletePostbackDisabledNotification: { __typename: 'DeletePostbackDisabledNotificationPayload', void?: any | null } };


export const DeletePostbackDisabledNotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePostbackDisabledNotification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeletePostbackDisabledNotificationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePostbackDisabledNotification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type DeletePostbackDisabledNotificationMutationFn = Apollo.MutationFunction<DeletePostbackDisabledNotification, DeletePostbackDisabledNotificationVariables>;

/**
 * __useDeletePostbackDisabledNotification__
 *
 * To run a mutation, you first call `useDeletePostbackDisabledNotification` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostbackDisabledNotification` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostbackDisabledNotification, { data, loading, error }] = useDeletePostbackDisabledNotification({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePostbackDisabledNotification(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostbackDisabledNotification, DeletePostbackDisabledNotificationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeletePostbackDisabledNotification, DeletePostbackDisabledNotificationVariables>(DeletePostbackDisabledNotificationDocument, options);
      }
export type DeletePostbackDisabledNotificationHookResult = ReturnType<typeof useDeletePostbackDisabledNotification>;
export type DeletePostbackDisabledNotificationMutationResult = Apollo.MutationResult<DeletePostbackDisabledNotification>;
export type DeletePostbackDisabledNotificationMutationOptions = Apollo.BaseMutationOptions<DeletePostbackDisabledNotification, DeletePostbackDisabledNotificationVariables>;