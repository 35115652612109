import { Storage } from '../../storage';

export const openedBlocksKeyName = '@blocksStateStorage/openedBlocks';
export class BlocksStateStorage {
  getOpenedBlocks = (): { [key: string]: boolean } =>
    Storage.local.getValue(openedBlocksKeyName) || {};

  setOpenedBlocks = (item: { [key: string]: boolean }): void => {
    Storage.local.setValue(openedBlocksKeyName, {
      ...this.getOpenedBlocks(),
      ...item,
    });
  };

  clearOpenedBlocks = (): void => {
    Storage.local.removeValue(openedBlocksKeyName);
  };
}
