import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { FreespinData } from '../../../FreespinsTable/queries/generated/FreespinData';
import { FreespinBasicData } from '../../../FreespinsTable/queries/generated/FreespinBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateFreespinVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateFreespinInput;
}>;


export type CreateFreespin = { __typename: 'Mutation', createFreespin: { __typename: 'CreateFreespinPayload', freespin: { __typename: 'Freespin', updatedAt: string, bonusWager?: number | null, count: number, createdAt: string, createdAutomatically: boolean, currency: CommonType.Currency, id: string, status: SchemaTypes.FreespinStatus, game: { __typename: 'Game', id: string, name: string }, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, promoCode?: { __typename: 'PromoCode', id: string, token: string } | null } } };


export const CreateFreespinDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFreespin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateFreespinInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFreespin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"freespin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FreespinData"}}]}}]}}]}},...FreespinData.definitions,...FreespinBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;
export type CreateFreespinMutationFn = Apollo.MutationFunction<CreateFreespin, CreateFreespinVariables>;

/**
 * __useCreateFreespin__
 *
 * To run a mutation, you first call `useCreateFreespin` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreespin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreespin, { data, loading, error }] = useCreateFreespin({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFreespin(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFreespin, CreateFreespinVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateFreespin, CreateFreespinVariables>(CreateFreespinDocument, options);
      }
export type CreateFreespinHookResult = ReturnType<typeof useCreateFreespin>;
export type CreateFreespinMutationResult = Apollo.MutationResult<CreateFreespin>;
export type CreateFreespinMutationOptions = Apollo.BaseMutationOptions<CreateFreespin, CreateFreespinVariables>;