import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PartnerAdditionalData } from './PartnerAdditionalData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerAdditionalDataVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerAdditionalData = { __typename: 'Query', partner: { __typename: 'Partner', createdAt: string, email?: string | null, emailConfirmedAt?: string | null, id: string, lastVisited?: string | null, locale?: CommonType.Locale | null, name: string, trafficSource?: string | null, contactForCommunication: { __typename: 'ContactForCommunication', contact: string, method: string }, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus, player: { __typename: 'Player', email?: string | null, id: string, name: string, phone?: string | null } } | null } };


export const GetPartnerAdditionalDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerAdditionalData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PartnerAdditionalData"}}]}}]}},...PartnerAdditionalData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPartnerAdditionalData__
 *
 * To run a query within a React component, call `useGetPartnerAdditionalData` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerAdditionalData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerAdditionalData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPartnerAdditionalData(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables> & ({ variables: GetPartnerAdditionalDataVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables>(GetPartnerAdditionalDataDocument, options);
      }
export function useGetPartnerAdditionalDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables>(GetPartnerAdditionalDataDocument, options);
        }
export function useGetPartnerAdditionalDataSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables>(GetPartnerAdditionalDataDocument, options);
        }
export type GetPartnerAdditionalDataHookResult = ReturnType<typeof useGetPartnerAdditionalData>;
export type GetPartnerAdditionalDataLazyQueryHookResult = ReturnType<typeof useGetPartnerAdditionalDataLazyQuery>;
export type GetPartnerAdditionalDataSuspenseQueryHookResult = ReturnType<typeof useGetPartnerAdditionalDataSuspenseQuery>;
export type GetPartnerAdditionalDataQueryResult = Apollo.QueryResult<GetPartnerAdditionalData, GetPartnerAdditionalDataVariables>;