import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CancelReceiveGiftRestrictionVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CancelReceiveGiftRestrictionInput;
}>;


export type CancelReceiveGiftRestriction = { __typename: 'Mutation', cancelReceiveGiftRestriction: { __typename: 'CancelReceiveGiftRestrictionPayload', receiveGiftRestriction?: { __typename: 'ReceiveGiftRestriction', canceledAt?: string | null, id: string } | null } };


export const CancelReceiveGiftRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelReceiveGiftRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelReceiveGiftRestrictionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelReceiveGiftRestriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"receiveGiftRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CancelReceiveGiftRestrictionMutationFn = Apollo.MutationFunction<CancelReceiveGiftRestriction, CancelReceiveGiftRestrictionVariables>;

/**
 * __useCancelReceiveGiftRestriction__
 *
 * To run a mutation, you first call `useCancelReceiveGiftRestriction` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReceiveGiftRestriction` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReceiveGiftRestriction, { data, loading, error }] = useCancelReceiveGiftRestriction({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReceiveGiftRestriction(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelReceiveGiftRestriction, CancelReceiveGiftRestrictionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CancelReceiveGiftRestriction, CancelReceiveGiftRestrictionVariables>(CancelReceiveGiftRestrictionDocument, options);
      }
export type CancelReceiveGiftRestrictionHookResult = ReturnType<typeof useCancelReceiveGiftRestriction>;
export type CancelReceiveGiftRestrictionMutationResult = Apollo.MutationResult<CancelReceiveGiftRestriction>;
export type CancelReceiveGiftRestrictionMutationOptions = Apollo.BaseMutationOptions<CancelReceiveGiftRestriction, CancelReceiveGiftRestrictionVariables>;