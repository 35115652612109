import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetWithdrawalPayoutVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  viewPaymentMethodAccount: SchemaTypes.Scalars['Boolean']['input'];
}>;


export type GetWithdrawalPayout = { __typename: 'Query', withdrawal: { __typename: 'Withdrawal', id: string, paymentMethod: { __typename: 'PaymentMethod', account?: string, crypto: boolean, id: string, maskedAccount?: string, name: CommonType.PaymentMethodName } } };


export const GetWithdrawalPayoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetWithdrawalPayout"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viewPaymentMethodAccount"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"withdrawal"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"paymentMethod"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"include"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"if"},"value":{"kind":"Variable","name":{"kind":"Name","value":"viewPaymentMethodAccount"}}}]}]},{"kind":"Field","name":{"kind":"Name","value":"crypto"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"maskedAccount"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"skip"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"if"},"value":{"kind":"Variable","name":{"kind":"Name","value":"viewPaymentMethodAccount"}}}]}]},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetWithdrawalPayout__
 *
 * To run a query within a React component, call `useGetWithdrawalPayout` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawalPayout` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawalPayout({
 *   variables: {
 *      id: // value for 'id'
 *      viewPaymentMethodAccount: // value for 'viewPaymentMethodAccount'
 *   },
 * });
 */
export function useGetWithdrawalPayout(baseOptions: ApolloReactHooks.QueryHookOptions<GetWithdrawalPayout, GetWithdrawalPayoutVariables> & ({ variables: GetWithdrawalPayoutVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetWithdrawalPayout, GetWithdrawalPayoutVariables>(GetWithdrawalPayoutDocument, options);
      }
export function useGetWithdrawalPayoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWithdrawalPayout, GetWithdrawalPayoutVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetWithdrawalPayout, GetWithdrawalPayoutVariables>(GetWithdrawalPayoutDocument, options);
        }
export function useGetWithdrawalPayoutSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetWithdrawalPayout, GetWithdrawalPayoutVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetWithdrawalPayout, GetWithdrawalPayoutVariables>(GetWithdrawalPayoutDocument, options);
        }
export type GetWithdrawalPayoutHookResult = ReturnType<typeof useGetWithdrawalPayout>;
export type GetWithdrawalPayoutLazyQueryHookResult = ReturnType<typeof useGetWithdrawalPayoutLazyQuery>;
export type GetWithdrawalPayoutSuspenseQueryHookResult = ReturnType<typeof useGetWithdrawalPayoutSuspenseQuery>;
export type GetWithdrawalPayoutQueryResult = Apollo.QueryResult<GetWithdrawalPayout, GetWithdrawalPayoutVariables>;