import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { ListAllOperations } from 'generatedGraphql';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { useDeleteAllPostbackDisabledNotifications as useDeleteAllPostbackDisabledNotificationsMutation } from '../queries/generated/DeleteAllPostbackDisabledNotifications';

export const useDeleteAllPostbackDisabledNotifications = () => {
  const { dispatch } = useContext(ModalContext);

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Уведомления о выключенных постбеках не были удалены',
      text: error.message,
      toastId: 'delete-postback-disabled-notification-error',
    });
  };

  const handleComplete = () => {
    toast.success('Уведомления о выключенных постбеках удалены');
    dispatch({ type: ModalActions.Close });
  };

  const [deleteAllPostbackDisabledNotification, { loading }] =
    useDeleteAllPostbackDisabledNotificationsMutation({
      onError: handleError,
      onCompleted: handleComplete,
      refetchQueries: [
        ListAllOperations.Query.GetPostbackDisabledNotifications,
      ],
    });

  return {
    deleteAllPostbackDisabledNotification,
    loading,
  };
};
