import SearchInput, { OnSearchInputSubmit } from 'ui/SearchInput/SearchInput';
import { useNavigate } from 'react-router';
import { Route } from 'src/router/routes.const';
import {
  COMPANIES_SEARCH_INPUT_NAME,
  COMPANIES_SEARCH_PLACEHOLDER,
} from '../../../CompaniesList/const';

export const CompaniesSearchInput = () => {
  const navigate = useNavigate();

  const handleSubmit: OnSearchInputSubmit<{
    [COMPANIES_SEARCH_INPUT_NAME]: string;
  }> = () => {
    navigate(Route.COMPANIES);
  };

  return (
    <SearchInput
      name={COMPANIES_SEARCH_INPUT_NAME}
      placeholder={COMPANIES_SEARCH_PLACEHOLDER}
      onSubmit={handleSubmit}
    />
  );
};
