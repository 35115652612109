import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportReferralLinkMediaItemRevShareReportVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  id: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
}>;


export type GetExportReferralLinkMediaItemRevShareReport = { __typename: 'Query', mediaItem: { __typename: 'ReferralLinkMediaItem', exportRevShareReport: string, id: string } };


export const GetExportReferralLinkMediaItemRevShareReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportReferralLinkMediaItemRevShareReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportRevShareReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currency"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currency"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportReferralLinkMediaItemRevShareReport__
 *
 * To run a query within a React component, call `useGetExportReferralLinkMediaItemRevShareReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportReferralLinkMediaItemRevShareReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportReferralLinkMediaItemRevShareReport({
 *   variables: {
 *      currency: // value for 'currency'
 *      id: // value for 'id'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetExportReferralLinkMediaItemRevShareReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables> & ({ variables: GetExportReferralLinkMediaItemRevShareReportVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables>(GetExportReferralLinkMediaItemRevShareReportDocument, options);
      }
export function useGetExportReferralLinkMediaItemRevShareReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables>(GetExportReferralLinkMediaItemRevShareReportDocument, options);
        }
export function useGetExportReferralLinkMediaItemRevShareReportSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables>(GetExportReferralLinkMediaItemRevShareReportDocument, options);
        }
export type GetExportReferralLinkMediaItemRevShareReportHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemRevShareReport>;
export type GetExportReferralLinkMediaItemRevShareReportLazyQueryHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemRevShareReportLazyQuery>;
export type GetExportReferralLinkMediaItemRevShareReportSuspenseQueryHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemRevShareReportSuspenseQuery>;
export type GetExportReferralLinkMediaItemRevShareReportQueryResult = Apollo.QueryResult<GetExportReferralLinkMediaItemRevShareReport, GetExportReferralLinkMediaItemRevShareReportVariables>;