export enum FileFormat {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export const MAX_SIZE = 5 * 10 ** 6;

export const fileExtensionMap: { [key: string]: string } = {
  [FileFormat.JPEG]: '.jpg',
  [FileFormat.PNG]: '.png',
};
