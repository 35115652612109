import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useParams } from 'react-router';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { OutgoingMoneyTransferItem } from '../../OutgoingMoneyTransferItem';
import { PartnerOutgoingMoneyTransferData } from '../queries/generated/PartnerOutgoingMoneyTransferData';

interface UseOpenOutgoingMoneyTransferModalResult<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useOpenOutgoingMoneyTransferModal = <
  T extends object
>(): UseOpenOutgoingMoneyTransferModalResult<T> => {
  const { dispatch } = useContext(ModalContext);
  const { id: partnerId } = useParams();

  const openModal = useCallback(
    (outgoingMoneyTransfer: T, initiatorId: string) => {
      if (!partnerId) {
        return;
      }

      const { id: outgoingMoneyTransferId } =
        outgoingMoneyTransfer as PartnerOutgoingMoneyTransferData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(outgoingMoneyTransferId),
          subTitle: 'Исходящий перевод',
          content: (
            <OutgoingMoneyTransferItem
              partnerId={partnerId}
              outgoingMoneyTransferId={outgoingMoneyTransferId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, partnerId]
  );

  return {
    openModal,
  };
};
