import { ReactNode } from 'react';
import { nbsp } from 'src/const';

export const getLimitWarningText = (
  comparisonResult?: string | ReactNode,
  isCryptoPaymentMethod?: boolean
) => {
  let title = '';
  let message = '';

  if (comparisonResult) {
    title = isCryptoPaymentMethod
      ? 'Сумма превышает криптовалютный лимит'
      : 'Сумма превышает суточный лимит';
    message = `Сумма платежа на${nbsp}вывод превышает оставшуюся сумму лимита.`;

    return { title, message };
  }

  title = isCryptoPaymentMethod
    ? 'Криптовалютный лимит исчерпан'
    : 'Суточный лимит исчерпан';
  message = isCryptoPaymentMethod
    ? `Допустимый криптовалютный лимит на${nbsp}вывод исчерпан.`
    : `Допустимый суточный лимит на${nbsp}вывод исчерпан.`;

  return { title, message };
};
