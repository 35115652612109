import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
export type LastRestrictionData_BadBankRestriction = { __typename: 'BadBankRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string };

export type LastRestrictionData_ChargeBackRestriction = { __typename: 'ChargeBackRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string };

export type LastRestrictionData_ReceiveGiftRestriction = { __typename: 'ReceiveGiftRestriction', canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string };

export type LastRestrictionData_WithdrawalRestriction = { __typename: 'WithdrawalRestriction', canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string };

export type LastRestrictionData = LastRestrictionData_BadBankRestriction | LastRestrictionData_ChargeBackRestriction | LastRestrictionData_ReceiveGiftRestriction | LastRestrictionData_WithdrawalRestriction;

export const LastRestrictionData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LastRestrictionData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RestrictionInterface"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]} as unknown as DocumentNode;