import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useResetCompanyTwoFactor as useResetCompanyTwoFactorMutation } from '../queries/generated/ResetCompanyTwoFactor';

interface ResetCompanyTwoFactorParams {
  companyId: string;
}

interface ResetCompanyTwoFactorResult {
  loading: boolean;
  resetCompanyTwoFactorHandler: () => void;
  error?: ApolloError;
}

export const useResetCompanyTwoFactor = ({
  companyId,
}: ResetCompanyTwoFactorParams): ResetCompanyTwoFactorResult => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Компания изменена');
  };

  const [resetCompanyTwoFactor, { error, loading }] =
    useResetCompanyTwoFactorMutation({
      onCompleted: handleCompleted,
    });

  const resetCompanyTwoFactorHandler = async () => {
    await resetCompanyTwoFactor({
      variables: {
        input: {
          id: companyId,
        },
      },
    });
  };

  return {
    resetCompanyTwoFactorHandler,
    loading,
    error,
  };
};
