export enum ColumnId {
  Id = 'id',
  CreatedAt = 'createdAt',
  MoneyTransferAccount = 'player.moneyTransferAccount',
  Sum = 'sum',
  Player = 'player',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.MoneyTransferAccount]: 'Номер счёта',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.Player]: 'Получатель',
};

export const blockColumnsIds = [
  ColumnId.MoneyTransferAccount,
  ColumnId.Player,
  ColumnId.Sum,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.MoneyTransferAccount,
  ColumnId.Player,
  ColumnId.Sum,
  ColumnId.CreatedAt,
];
