import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ResetPlayerTwoFactorVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ResetPlayerTwoFactorInput;
}>;


export type ResetPlayerTwoFactor = { __typename: 'Mutation', resetPlayerTwoFactor: { __typename: 'ResetPlayerTwoFactorPayload', player: { __typename: 'Player', id: string, twoFactorEnabled: boolean } } };


export const ResetPlayerTwoFactorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPlayerTwoFactor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPlayerTwoFactorInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPlayerTwoFactor"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"twoFactorEnabled"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ResetPlayerTwoFactorMutationFn = Apollo.MutationFunction<ResetPlayerTwoFactor, ResetPlayerTwoFactorVariables>;

/**
 * __useResetPlayerTwoFactor__
 *
 * To run a mutation, you first call `useResetPlayerTwoFactor` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPlayerTwoFactor` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPlayerTwoFactor, { data, loading, error }] = useResetPlayerTwoFactor({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPlayerTwoFactor(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPlayerTwoFactor, ResetPlayerTwoFactorVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPlayerTwoFactor, ResetPlayerTwoFactorVariables>(ResetPlayerTwoFactorDocument, options);
      }
export type ResetPlayerTwoFactorHookResult = ReturnType<typeof useResetPlayerTwoFactor>;
export type ResetPlayerTwoFactorMutationResult = Apollo.MutationResult<ResetPlayerTwoFactor>;
export type ResetPlayerTwoFactorMutationOptions = Apollo.BaseMutationOptions<ResetPlayerTwoFactor, ResetPlayerTwoFactorVariables>;