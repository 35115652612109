import { Storage } from '../../storage';
import { PageTypeStorage } from '../../const';

const getKeyByType = (type: PageTypeStorage) => `${type}/search`;

export class SearchListStorage {
  setSearchParams = (search: string, type: PageTypeStorage): void => {
    Storage.local.setValue(getKeyByType(type), search);
  };

  getSearchParams = (type: PageTypeStorage): string => {
    const params = Storage.local.getValue<string | null>(getKeyByType(type));

    return params || '';
  };

  clearAllSearch = (): void => {
    Object.values(PageTypeStorage).forEach((item) => {
      Storage.local.removeValue(getKeyByType(item));
    });
  };
}
