import { FC } from 'react';
import styles from './BlockSubHeaderCompanyAffiliateInfo.module.scss';

interface Props {
  companyName: string;
  affiliateName: string;
}

export const BlockSubHeaderCompanyAffiliateInfo: FC<Props> = ({
  companyName,
  affiliateName,
}) => (
  <div className={styles.subHeader}>
    <div>
      <span className={styles.label}>Компания</span>{' '}
      <span className={styles.value}>{companyName}</span>
    </div>
    <div>
      <span className={styles.label}>Партнёр компании</span>{' '}
      <span className={styles.value}>{affiliateName}</span>
    </div>
  </div>
);
