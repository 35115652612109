import { FC, useEffect, useState } from 'react';
import { Dropdown } from 'ui/Dropdown';
import { useGetCompanyAffiliateReferralLinks } from './hooks/useGetCompanyAffiliateReferralLinks';

// Width for dropdown in modal for text overflow
const LABEL_WIDTH = 479;

interface Props {
  name: string;
  label: string;
  companyId: string;
  companyAffiliateId: string;
  labelWidth?: number;
  onChange?: (label: string) => void;
}

export const CompanyAffiliateReferralLinksDropdown: FC<Props> = ({
  name,
  label,
  companyId,
  companyAffiliateId,
  labelWidth = LABEL_WIDTH,
  onChange,
}) => {
  const {
    referralLinksOptions,
    loading,
    error,
    fetchMoreCallback,
    hasNextPage,
  } = useGetCompanyAffiliateReferralLinks({
    companyId,
    companyAffiliateId,
    labelWidth,
  });

  const [optionsState, setOptionsState] = useState(referralLinksOptions);

  const onSearchInList = (e: string) => {
    const filteredOptions = referralLinksOptions?.filter(
      (option) =>
        option.label.props.children.props.value
          .toUpperCase()
          .indexOf(e.toUpperCase()) !== -1
    );

    setOptionsState(filteredOptions);
  };

  useEffect(() => {
    if (referralLinksOptions) {
      setOptionsState(referralLinksOptions);
    }
  }, [referralLinksOptions]);

  /*
   ** Temporary solution for search without query in dropdown
   ** TODO: Rewrite after complete task https://eyeconweb.atlassian.net/browse/APN-1196
   */
  const onChangeHandler = (id: string | Array<string>) => {
    if (onChange) {
      const currentLabel = referralLinksOptions?.find(
        (option) => option.id === id
      );

      onChange?.(currentLabel?.label.props.children.props.value);
    }
  };

  return (
    <Dropdown
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMoreCallback();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={optionsState}
      onSearchInList={onSearchInList}
      onChange={onChangeHandler}
    />
  );
};
