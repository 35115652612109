import { useState } from 'react';
import { Filter } from 'ui/TableFilter/types';

interface UseTableFilterStateResult {
  selectedFilters: Array<Filter>;
  deleteFilterFromState: (id: string) => void;
  setFilterValue: (id: string, value: string) => void;
  removeAllSelectedFilters: () => void;
  setSelectedFilterId: (id: string) => void;
  toggleFilter: (isOpen: boolean, id: string) => void;
}

export const useTableFilterState = (): UseTableFilterStateResult => {
  const [selectedFilters, setSelectedFilters] = useState<Array<Filter>>([]);

  const removeAllSelectedFilters = () => {
    setSelectedFilters([]);
  };

  const setSelectedFilterId = (selectedFilterId: string) => {
    setSelectedFilters((filters) => [
      ...filters,
      {
        selectedFilterId,
        selectedFilterValue: null,
        isOpened: true,
      },
    ]);
  };

  const deleteFilter = (selectedFilterId: string) => {
    setSelectedFilters((items) =>
      items.filter((item) => item.selectedFilterId !== selectedFilterId)
    );
  };

  const setFilterValue = (selectedFilterId: string, filterValue: string) => {
    setSelectedFilters((items) =>
      items.map((elem) => {
        if (elem.selectedFilterId === selectedFilterId) {
          return {
            selectedFilterId,
            selectedFilterValue: filterValue,
            isOpened: false,
          };
        }

        return elem;
      })
    );
  };

  const toggleFilter = (isOpened: boolean, selectedFilterId: string) => {
    setSelectedFilters((items) =>
      items.map((elem) => {
        if (elem.selectedFilterId === selectedFilterId) {
          return {
            selectedFilterId,
            selectedFilterValue: elem.selectedFilterValue,
            isOpened,
          };
        }

        return elem;
      })
    );
  };

  return {
    selectedFilters,
    setFilterValue,
    deleteFilterFromState: deleteFilter,
    setSelectedFilterId,
    removeAllSelectedFilters,
    toggleFilter,
  };
};
