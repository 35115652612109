import { DeclineCompanyApplicationInput } from 'generatedGraphql';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CompanyApplicationModalSteps } from '../../../const';
import { useDeclineCompanyApplication } from '../queries/generated/DeclineCompanyApplication';

export const useDeclineCompanyApplicationData = () => {
  const { state, dispatch } = useContext(ModalContext);

  const handleComplete = () => {
    if (state.step === CompanyApplicationModalSteps.DeclineStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: CompanyApplicationModalSteps.BaseStep,
        },
      });

      toast.success('Заявка отклонена');
    }
  };

  const handleError = () => {
    if (state.step === CompanyApplicationModalSteps.DeclineStep) {
      toast.error('Заявка не отклонена');
    }
  };

  const [declineCompanyApplication, { error, loading }] =
    useDeclineCompanyApplication({
      onCompleted: handleComplete,
      onError: handleError,
    });

  const declineCompanyApplicationHandler = ({
    reason,
    id,
  }: DeclineCompanyApplicationInput) => {
    declineCompanyApplication({
      variables: {
        input: {
          id,
          reason,
        },
      },
    });
  };

  return {
    declineCompanyApplicationHandler,
    error,
    loading,
  };
};
