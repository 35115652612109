import { Route } from 'src/router/routes.const';
import { PlayerListData } from '../../../queries/generated/PlayerListData';

export const getAffiliateInfo = (affiliate: PlayerListData['affiliate']) => {
  const isCompany = affiliate?.__typename === 'Company';
  const name = affiliate?.name;
  const email = isCompany ? affiliate?.email : affiliate?.partnerEmail;
  const route = isCompany
    ? `${Route.COMPANIES}/${affiliate.id}`
    : `${Route.PARTNERS}/${affiliate?.id}`;

  return {
    name,
    email,
    route,
  };
};
