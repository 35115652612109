import { FC, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './RouterLink.module.scss';
import { RouterLinkSize, mapRouterLinkSizeToClass } from './const';

interface Props {
  path: string;
  withIcon?: boolean;
  size?: RouterLinkSize;
  underlined?: boolean;
  target?: '_blank' | '_parent';
  className?: string;
}

export const RouterLink: FC<PropsWithChildren<Props>> = ({
  children,
  path,
  withIcon = true,
  size = RouterLinkSize.Small,
  underlined,
  target = '_blank',
  className,
}) => {
  const linkTextClasses = classnames(
    styles.linkText,
    withIcon && styles.withIcon,
    mapRouterLinkSizeToClass[size],
    underlined && styles.underlined
  );

  return (
    <Link
      to={path}
      target={target}
      rel="noopener noreferrer"
      className={classnames(styles.linkContainer, className)}
    >
      <span className={linkTextClasses}>{children}</span>
      {withIcon && (
        <FontAwesomeIcon
          data-testid="icon"
          icon={faArrowUpRightFromSquare}
          className={classnames(styles.icon, mapRouterLinkSizeToClass[size])}
        />
      )}
    </Link>
  );
};
