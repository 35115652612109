import { useContext } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetExternalMoneyTransfers } from './hooks/useGetExternalMoneyTransfers';
import { useOpenExternalMoneyTransferModal } from './hooks/useOpenExternalMoneyTransferModal';

interface Props<T extends object> {
  id: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const ExternalMoneyTransfersTable = <T extends { __typename: string }>({
  id,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: Props<T>) => {
  const { state } = useContext(BlockContext);
  const { openModal } = useOpenExternalMoneyTransferModal<T>();

  const {
    loadExternalMoneyTransfers,
    externalMoneyTransfersList,
    loading,
    error,
    hasNextPage,
    fetchMoreCallback,
    refetch,
    data,
  } = useGetExternalMoneyTransfers<T>({
    id,
    isFullScreenTable,
  });

  useBlockComponentState({
    loadData: loadExternalMoneyTransfers,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  return (
    <Block
      title="Внешние переводы"
      id="playerExternalMoneyTransfersBlock"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      isEmpty={isListEmpty(externalMoneyTransfersList)}
      emptyText="Внешние переводы отсутствуют"
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(externalMoneyTransfersList) && (
        <BlockTable
          isFullScreenTable={isFullScreenTable}
          data={externalMoneyTransfersList}
          columns={columns}
          routeToCustomPage={routeToCustomPage}
          onClickOnRow={openModal}
        />
      )}
    </Block>
  );
};
