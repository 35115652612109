import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AssignCompanyAffiliateApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AssignCompanyAffiliateApplicationInput;
}>;


export type AssignCompanyAffiliateApplication = { __typename: 'Mutation', assignCompanyAffiliateApplication: { __typename: 'AssignCompanyAffiliateApplicationPayload', companyAffiliateApplication: { __typename: 'CompanyAffiliateApplication', editable: boolean, id: string, status: SchemaTypes.CompanyAffiliateApplicationStatus, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string } | null } } };


export const AssignCompanyAffiliateApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssignCompanyAffiliateApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssignCompanyAffiliateApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignCompanyAffiliateApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyAffiliateApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"editable"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AssignCompanyAffiliateApplicationMutationFn = Apollo.MutationFunction<AssignCompanyAffiliateApplication, AssignCompanyAffiliateApplicationVariables>;

/**
 * __useAssignCompanyAffiliateApplication__
 *
 * To run a mutation, you first call `useAssignCompanyAffiliateApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCompanyAffiliateApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCompanyAffiliateApplication, { data, loading, error }] = useAssignCompanyAffiliateApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCompanyAffiliateApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCompanyAffiliateApplication, AssignCompanyAffiliateApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignCompanyAffiliateApplication, AssignCompanyAffiliateApplicationVariables>(AssignCompanyAffiliateApplicationDocument, options);
      }
export type AssignCompanyAffiliateApplicationHookResult = ReturnType<typeof useAssignCompanyAffiliateApplication>;
export type AssignCompanyAffiliateApplicationMutationResult = Apollo.MutationResult<AssignCompanyAffiliateApplication>;
export type AssignCompanyAffiliateApplicationMutationOptions = Apollo.BaseMutationOptions<AssignCompanyAffiliateApplication, AssignCompanyAffiliateApplicationVariables>;