import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { AcceptPaymentInput } from 'generatedGraphql';
import { ApolloError } from '@apollo/client';
import { useAcceptWithdrawalPayment } from '../queries/generated/AcceptWithdrawalPayment';
import { WithdrawalModalSteps } from '../../../types';

interface UseAcceptPaymentResult {
  acceptPayment: (obj: AcceptPaymentInput) => void;
  error?: ApolloError;
  loading: boolean;
}

export const useAcceptPayment = (): UseAcceptPaymentResult => {
  const { state, dispatch } = useContext(ModalContext);

  const [acceptPaymentMutation, { data, error, loading }] =
    useAcceptWithdrawalPayment();

  useEffect(() => {
    if (!data) {
      return;
    }

    toast.success('Платеж выполнен');

    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: WithdrawalModalSteps.BaseStep,
      },
    });
  }, [data, dispatch, state.step]);

  const acceptPayment = ({ id, amount }: AcceptPaymentInput) => {
    acceptPaymentMutation({
      variables: { input: { id, amount } },
    });
  };

  return { acceptPayment, error, loading };
};
