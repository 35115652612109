import { DeclinePaymentInput, PaymentStatus } from 'generatedGraphql';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { useDeclineWithdrawalPayment as useDeclineWithdrawalPaymentMutation } from '../queries/generated/DeclineWithdrawalPayment';

interface UseDeclineWithdrawalPaymentResult {
  handleDeclinePayment: ({ id }: DeclinePaymentInput) => void;
  error?: ApolloError;
  loading: boolean;
}

export const useDeclineWithdrawalPayment =
  (): UseDeclineWithdrawalPaymentResult => {
    const handleErrorDeclineWithdrawalPayment = (error: ApolloError) => {
      errorToast({
        header: `При${nbsp}отклонении платежа произошла ошибка`,
        text: error.message,
        toastId: 'decline-withdrawal-payment-error',
      });
    };

    const [declinePayment, { error, loading }] =
      useDeclineWithdrawalPaymentMutation({
        onError: handleErrorDeclineWithdrawalPayment,
      });

    const handleDeclinePayment = ({ id }: DeclinePaymentInput) => {
      declinePayment({
        variables: { input: { id } },
        context: { delayed: true },
        optimisticResponse: {
          __typename: 'Mutation',
          declinePayment: {
            __typename: 'DeclinePaymentPayload',
            payment: {
              id,
              status: PaymentStatus.Declined,
              updatedAt: new Date().toISOString(),
              __typename: 'Payment',
            },
          },
        },
      });

      undoActionToast('Платеж отменён');
    };

    return { handleDeclinePayment, error, loading };
  };
