import { FC, useState } from 'react';
import { RewardTariffType } from 'commonTypes';
import { RevShareGeneralReport } from './components/RevShareGeneralReport';
import { CpaGeneralReport } from './components/CpaGeneralReport';

export const Statistic: FC = () => {
  const [tariffType, setTariffType] = useState(RewardTariffType.RevShare);

  if (tariffType === RewardTariffType.RevShare) {
    return <RevShareGeneralReport setTariffType={setTariffType} />;
  }

  return <CpaGeneralReport setTariffType={setTariffType} />;
};
