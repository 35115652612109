import React, { FC, useState } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
  Navigate,
} from 'react-router-dom';
import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import Button, { ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { Tooltip } from 'ui/Tooltip';
import styles from '../Auth.module.scss';
import { useAuth } from '../../Auth';
import { PasswordChangeValues, validatePasswordChange } from '../../validation';
import {
  AuthErrorMessages,
  AuthStatuses,
  PASSWORD_CREATE_ACTION,
} from '../../const';
import { privateDefaultPath, Route } from '../../../../router/routes.const';

const Password: FC = () => {
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const auth = useAuth();
  const { authStatus } = auth.user;

  const navigate = useNavigate();

  const { action } = useParams();

  const location = useLocation();
  const { from } = location.state || { from: { pathname: privateDefaultPath } };

  const passwordChangeInitialValues = {
    password: '',
    passwordRepeat: '',
  };

  const onChange = (values: PasswordChangeValues) => {
    if (!token) {
      return setError(AuthErrorMessages.Common);
    }

    setError('');

    return auth
      .setPassword(values.password, token)
      .then((success) => {
        if (success) {
          navigate(Route.LOGIN);
        } else {
          setError(AuthErrorMessages.Common);
        }

        return success;
      })
      .catch((e) =>
        setError(e.status === 401 ? AuthErrorMessages.PasswordSet401 : e.detail)
      );
  };

  const renderChangePasswordForm = () => (
    <Formik
      initialValues={passwordChangeInitialValues}
      validate={validatePasswordChange}
      onSubmit={onChange}
      validateOnChange={false}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <FormRowsWrapper>
            <FormRow isWide>
              <Input type="password" name="password" label="Придумай пароль" />
            </FormRow>
            <FormRow isWide>
              <Input
                type="password"
                name="passwordRepeat"
                label="Повтори пароль"
              />
            </FormRow>
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty
                ? 'Необходимо корректно заполнить все поля'
                : undefined
            }
            placement="bottom"
            shouldSetWidthFitContent={false}
          >
            <Button
              theme={ButtonTheme.Process}
              className={styles.button}
              type="submit"
              isLoading={isSubmitting}
              disabled={!isValid || !dirty}
            >
              {action === PASSWORD_CREATE_ACTION ? 'Создать' : 'Сменить'}
            </Button>
          </Tooltip>
          {error && <div className={styles.error}>{error}</div>}
        </Form>
      )}
    </Formik>
  );

  if (authStatus === AuthStatuses.Authenticated) {
    return <Navigate to={from} />;
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        {action === PASSWORD_CREATE_ACTION ? 'Создание пароля' : 'Смена пароля'}
      </h1>
      <div className={styles.container}>{renderChangePasswordForm()}</div>
    </div>
  );
};

export default Password;
