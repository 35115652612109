import { DropdownOption } from 'ui/DropdownOptionsList/types';
import { useMemo } from 'react';
import { CreateMediaElementOptionsIds } from '../const';
import { useGetCreateMediaItemPrivileges } from '../../../hooks/useGetCreateMediaItemPrivileges';

export const useGetCreateMediaItemOptions = () => {
  const { canCreateReferralLink, canCreatePromoCode } =
    useGetCreateMediaItemPrivileges();

  const creationMediaItemOptions = useMemo(() => {
    const options: Array<DropdownOption> = [];

    if (canCreateReferralLink) {
      options.push({
        id: CreateMediaElementOptionsIds.ReferralLink,
        label: 'Создать реферальную ссылку',
      });
    }

    if (canCreatePromoCode) {
      options.push({
        id: CreateMediaElementOptionsIds.PromoCode,
        label: 'Создать промокод',
      });
    }

    return options;
  }, [canCreatePromoCode, canCreateReferralLink]);

  return { creationMediaItemOptions };
};
