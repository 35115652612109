import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { QualifiedReferralConfirmationSettingData } from '../../../../queries/generated/QualifiedReferralConfirmationSettingData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetQualifiedReferralConfirmationSettingVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetQualifiedReferralConfirmationSetting = { __typename: 'Query', qualifiedReferralConfirmationSetting: { __typename: 'QualifiedReferralConfirmationSetting', code?: any | null, comment: string, createdAt: string, id: string, type: SchemaTypes.QualifiedReferralConfirmationSettingType, updatedAt: string, affiliate: { __typename: 'Company', email: string, id: string, name: string } | { __typename: 'Partner', id: string, name: string, partnerEmail?: string | null } } };


export const GetQualifiedReferralConfirmationSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetQualifiedReferralConfirmationSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"qualifiedReferralConfirmationSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QualifiedReferralConfirmationSettingData"}}]}}]}},...QualifiedReferralConfirmationSettingData.definitions]} as unknown as DocumentNode;

/**
 * __useGetQualifiedReferralConfirmationSetting__
 *
 * To run a query within a React component, call `useGetQualifiedReferralConfirmationSetting` and pass it any options that fit your needs.
 * When your component renders, `useGetQualifiedReferralConfirmationSetting` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQualifiedReferralConfirmationSetting({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQualifiedReferralConfirmationSetting(baseOptions: ApolloReactHooks.QueryHookOptions<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables> & ({ variables: GetQualifiedReferralConfirmationSettingVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables>(GetQualifiedReferralConfirmationSettingDocument, options);
      }
export function useGetQualifiedReferralConfirmationSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables>(GetQualifiedReferralConfirmationSettingDocument, options);
        }
export function useGetQualifiedReferralConfirmationSettingSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables>(GetQualifiedReferralConfirmationSettingDocument, options);
        }
export type GetQualifiedReferralConfirmationSettingHookResult = ReturnType<typeof useGetQualifiedReferralConfirmationSetting>;
export type GetQualifiedReferralConfirmationSettingLazyQueryHookResult = ReturnType<typeof useGetQualifiedReferralConfirmationSettingLazyQuery>;
export type GetQualifiedReferralConfirmationSettingSuspenseQueryHookResult = ReturnType<typeof useGetQualifiedReferralConfirmationSettingSuspenseQuery>;
export type GetQualifiedReferralConfirmationSettingQueryResult = Apollo.QueryResult<GetQualifiedReferralConfirmationSetting, GetQualifiedReferralConfirmationSettingVariables>;