import { OperationVariables, QueryHookOptions } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { useCallback } from 'react';
import { fetchMoreCustom } from '../helpers';
import {
  FetchMoreCustomParams,
  FetchMoreOptions,
  UseQueryReturn,
} from '../types';
import { usePaginationData } from './usePaginationData';

export const useQuery = <
  TData,
  TVariables extends OperationVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): UseQueryReturn<TData, TVariables> => {
  const {
    fetchMore: originalFetchMore,
    data: originalData,
    ...rest
  } = Apollo.useQuery<TData, TVariables>(query, options);

  const { data, fetchMore } = usePaginationData({
    originalData,
    originalFetchMore,
    options,
  });

  const fetchMoreCallback = useCallback(
    <TFetchData>(
      fetchMoreOptions: FetchMoreOptions<TVariables, TFetchData>,
      fetchMoreParams: FetchMoreCustomParams = { shouldShowErrorToast: true }
    ) => fetchMoreCustom(fetchMoreOptions, fetchMore, fetchMoreParams),
    [fetchMore]
  );

  return {
    ...rest,
    data,
    fetchMore: fetchMoreCallback,
  };
};
