import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { isEqual } from 'lodash-es';
import { Storage, authStorageKeys } from '../../utils/storage';
import { AuthStatuses, AuthStatusesBackend, UserPrivilege } from './const';

import {
  checkTwoFactor,
  generateTwoFactor,
  getRefreshToken,
  getToken,
  logout,
  passwordSet,
  TokenResponseBody,
  TwoFactorResponseBody,
} from './api';

interface User {
  id: string;
  authStatus: (typeof AuthStatuses)[keyof typeof AuthStatuses];
  name: string;
  privileges: Array<UserPrivilege>;
}

interface Token {
  token: string;
  expiredAt: number;
}

interface JwtData {
  exp: number;
  iat: number;
  userId: string;
  identifier: string;
  privileges: Array<UserPrivilege>;
}

const defaultUser = {
  id: '',
  privileges: [],
  name: '',
  authStatus: AuthStatuses.Unathorized,
};

export interface AuthContext {
  user: User;
  accessToken: MutableRefObject<Token>;
  logIn: (identifier: string, password: string) => Promise<void>;
  logOut: () => Promise<boolean>;
  refreshToken: () => Promise<void>;
  createTwoFactor: () => Promise<TwoFactorResponseBody>;
  checkTwoFactorCode: (authCode: string) => Promise<void>;
  setPassword: (password: string, token: string) => Promise<boolean>;

  privileges: {
    /**
     * Accepts privileges and returns true if user has all of them otherwise false
     */
    areEveryGranted: (params: { privileges?: Array<UserPrivilege> }) => boolean;

    /**
     * Accepts privileges and returns true if user has one of them otherwise false
     */
    isSomeGranted: (params: { privileges?: Array<UserPrivilege> }) => boolean;
  };
}

const useProvideAuth = (): AuthContext => {
  const [user, setUser] = useState<User>(defaultUser);
  const accessToken = useRef<Token>({ token: '', expiredAt: 0 });
  const { authStorage } = Storage;

  const updateUserData = (data: TokenResponseBody) => {
    const result = { ...user };
    const { status, token } = data;

    if (token) {
      const decodedTokenData = jwtDecode<JwtData>(token);

      accessToken.current.token = token;
      accessToken.current.expiredAt = decodedTokenData.exp;
      result.name = decodedTokenData.identifier;
      result.id = decodedTokenData.userId;
      result.privileges = decodedTokenData.privileges;
    }

    if (status) {
      result.authStatus =
        AuthStatuses[status as keyof typeof AuthStatusesBackend];
    }

    if (!isEqual(user, result)) {
      setUser(result);
    }
  };

  const logIn = async (identifier: string, password: string) => {
    const data = await getToken(identifier, password);

    if (data) {
      updateUserData(data);
      authStorage.setLoggedInKey(Date.now().toString());
    }
  };

  const logOut = () =>
    logout().finally(() => {
      Storage.clear();
      setUser(defaultUser);
    });

  const refreshToken = async () => {
    const data = await getRefreshToken();

    if (data) {
      updateUserData(data);
    }
  };

  const createTwoFactor = () => generateTwoFactor();

  const checkTwoFactorCode = async (authCode: string) => {
    const data = await checkTwoFactor(authCode);

    if (data) {
      updateUserData(data);
    }
  };

  const setPassword = (password: string, token: string) =>
    passwordSet(password, token);

  const syncLogout = (event: StorageEvent) => {
    if (event.key === authStorageKeys.loggedInKey && !event.newValue) {
      logOut();
    }
  };

  const areEveryGranted = ({
    privileges,
  }: {
    privileges?: Array<UserPrivilege>;
  }): boolean =>
    !privileges || privileges.every((item) => user?.privileges.includes(item));

  const isSomeGranted = ({
    privileges,
  }: {
    privileges?: Array<UserPrivilege>;
  }): boolean =>
    !privileges || privileges.some((item) => user?.privileges.includes(item));

  useEffect(() => {
    // to support logging out from all windows
    window.addEventListener('storage', syncLogout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    accessToken,
    logIn,
    logOut,
    refreshToken,
    createTwoFactor,
    checkTwoFactorCode,
    setPassword,
    privileges: {
      areEveryGranted,
      isSomeGranted,
    },
  };
};

export const authContext = createContext<AuthContext>({
  user: {
    id: '',
    authStatus: AuthStatuses.Unathorized,
    privileges: [],
    name: '',
  },
  accessToken: {
    current: { token: '', expiredAt: 0 },
  } as MutableRefObject<Token>,
  logIn: () => Promise.resolve(),
  logOut: () => Promise.resolve(false),
  refreshToken: () => Promise.resolve(),
  createTwoFactor: () => Promise.resolve({ qr_content: '', secret: '' }),
  checkTwoFactorCode: () => Promise.resolve(),
  setPassword: () => Promise.resolve(false),
  privileges: {
    areEveryGranted: () => false,
    isSomeGranted: () => false,
  },
});

export const AuthProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): AuthContext => useContext(authContext);
