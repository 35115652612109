import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { PromoCodeModalDetails } from 'commonComponents/PromoCodeModalDetails';
import { useGetCompanyAffiliatePromoCodeItem } from './queries/generated/GetCompanyAffiliatePromoCodeItem';

interface Props {
  companyId: string;
  affiliateId: string;
  promoCodeId: string;
}

export const AffiliatePromoCodeItemDetails: FC<Props> = ({
  companyId,
  affiliateId,
  promoCodeId,
}) => {
  const { data, loading, error } = useGetCompanyAffiliatePromoCodeItem({
    variables: {
      companyId,
      affiliateId,
      promoCodeId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-company-affiliate-promo-code-item-error',
    },
    hasData: !!data,
  });

  const promoCodeMediaItem = data?.company.affiliate.promoCodeMediaItem;

  if (!promoCodeMediaItem) {
    return null;
  }

  return <PromoCodeModalDetails promoCodeMediaItem={promoCodeMediaItem} />;
};
