import { FC } from 'react';
import DropdownWithQuery from 'ui/DropdownWithQuery/DropdownWithQuery';
import { useGetPartnersQuery } from './hooks/useGetPartnersQuery';
import { getPartnersOptions } from './helpers';

interface Props {
  name: string;
  label: string;
  onChange?: (value: string | Array<string>) => void;
}

export const PartnersDropdown: FC<Props> = ({ name, label, onChange }) => {
  const {
    loadPartnersWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  } = useGetPartnersQuery();

  const partnersOptions = getPartnersOptions(data?.partners.edges);

  return (
    <DropdownWithQuery
      onChange={onChange}
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={partnersOptions}
      searchMinLength={3}
      loadList={loadPartnersWithThrottle}
    />
  );
};
