import { FC, PropsWithChildren, ReactElement } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Error from 'ui/Error';
import { useGetPartnerData } from '../../queries/generated/GetPartnerData';
import { PartnerHeader } from '../PartnerHeader/PartnerHeader';

interface Props {
  children: ReactElement;
  partnerId: string;
  shouldProvideBlock?: boolean;
}

export const PartnerPageContent: FC<Props> = ({
  children,
  partnerId,
  shouldProvideBlock = true,
}) => {
  let ChildrenWithHOCs: FC<PropsWithChildren<object>> | undefined;

  if (shouldProvideBlock) {
    ChildrenWithHOCs = withBlockProvider(() => children);
  }

  const { data, loading, error } = useGetPartnerData({
    variables: {
      id: partnerId,
    },
    fetchPolicy: 'cache-first',
  });

  const partnerData = data?.partner;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!partnerData) {
    return null;
  }

  return (
    <>
      <PartnerHeader partner={partnerData} />
      {ChildrenWithHOCs ? <ChildrenWithHOCs /> : children}
    </>
  );
};
