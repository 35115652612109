import { SuspectState } from 'generatedGraphql';
import { SuspiciousActionConfig } from '../types';

interface Params {
  suspectState: SuspectState;
  suspectActionConfig: SuspiciousActionConfig;
  unsuspectActionConfig: SuspiciousActionConfig;
  tooltipUnsuspectMessage: string;
  tooltipSuspectMessage: string;
}

interface Result {
  action?: () => void;
  tooltipMessage?: string;
  disabled: boolean;
}

export const useGetSuspectStateButtonProps = ({
  suspectState,
  suspectActionConfig,
  unsuspectActionConfig,
  tooltipUnsuspectMessage,
  tooltipSuspectMessage,
}: Params): Result => {
  const { action: suspectAction, loading: suspectLoading } =
    suspectActionConfig;
  const { action: unsuspectAction, loading: unsuspectLoading } =
    unsuspectActionConfig;

  let action: undefined | (() => void);
  let tooltipMessage: undefined | string;
  let disabled = true;

  if (suspectState === SuspectState.Eligible) {
    action = suspectAction;
    tooltipMessage = tooltipSuspectMessage;
    disabled = suspectLoading;
  } else if (suspectState === SuspectState.Suspicious) {
    action = unsuspectAction;
    tooltipMessage = tooltipUnsuspectMessage;
    disabled = unsuspectLoading;
  }

  return {
    action,
    tooltipMessage,
    disabled,
  };
};
