import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';
import { useGetMediaCampaignsLazyQuery } from '../queries/generated/GetMediaCampaigns';
import { MediaCampaignData } from '../queries/generated/MediaCampaignData';

const cellHeight = Heights.defaultCellHeight;

export const useMediaCampaigns = () => {
  const [loadMediaCampaigns, { data, loading, error, fetchMore, refetch }] =
    useGetMediaCampaignsLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
      },
    });

  const mediaCampaigns: Array<MediaCampaignData> | undefined = useMemo(
    () => data?.mediaCampaigns?.edges.map(({ node }) => node),
    [data?.mediaCampaigns?.edges]
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.mediaCampaigns?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.mediaCampaigns?.pageInfo.endCursor, fetchMore]
  );

  return {
    mediaCampaigns,
    loadMediaCampaigns,
    data,
    loading,
    fetchMoreCallback,
    error,
    refetch,
  };
};
