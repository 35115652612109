import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';
import { useGetCompanyAffiliateApplicationsLazyQuery } from '../queries/generated/GetCompanyAffiliateApplications';

const cellHeight = Heights.defaultCellHeight;

const COUNT_PER_PAGE = 10;

export const useCompanyAffiliateApplicationsData = () => {
  const [
    loadCompanyAffiliateApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCompanyAffiliateApplicationsLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
    },
  });

  useBlockComponentState({
    loadData: loadCompanyAffiliateApplications,
    loading,
    error,
  });

  const companyAffiliateApplicationsList = useMemo(
    () => data?.companyAffiliateApplications?.edges.map(({ node }) => node),
    [data?.companyAffiliateApplications?.edges]
  );

  const endCursor = data?.companyAffiliateApplications?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.companyAffiliateApplications?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchCompanyAffiliateApplicationsList: refetch,
    companyAffiliateApplicationsList,
  };
};
