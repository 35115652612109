import { FC, PropsWithChildren } from 'react';
import createStore from '../../../../../utils/createStore';
import { CompanyAffiliateApplicationState, Props } from './types';
import { reducer } from './reducer';

const initialState: CompanyAffiliateApplicationState = {
  affiliatesInProgress: null,
  editableAffiliate: null,
};

const [
  CompanyAffiliateApplicationContext,
  CompanyAffiliateApplicationProvider,
] = createStore(reducer, initialState);

export {
  CompanyAffiliateApplicationContext,
  CompanyAffiliateApplicationProvider,
};

export const withCompanyAffiliateApplicationProvider = (
  OriginalComponent: FC<PropsWithChildren<Props>>
): ((props: Props) => JSX.Element) => {
  const WrappedComponent = (props: Props) => (
    <CompanyAffiliateApplicationProvider>
      <OriginalComponent {...props} />
    </CompanyAffiliateApplicationProvider>
  );

  WrappedComponent.displayName = `withCompanyAffiliateApplicationProvider(${OriginalComponent.name})`;

  return WrappedComponent;
};
