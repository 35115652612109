import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ApprovePartnerPlayerAccountVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ApprovePartnerPlayerAccountInput;
}>;


export type ApprovePartnerPlayerAccount = { __typename: 'Mutation', approvePartnerPlayerAccount: { __typename: 'ApprovePartnerPlayerAccountPayload', partner: { __typename: 'Partner', id: string, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus } | null } } };


export const ApprovePartnerPlayerAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApprovePartnerPlayerAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApprovePartnerPlayerAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approvePartnerPlayerAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type ApprovePartnerPlayerAccountMutationFn = Apollo.MutationFunction<ApprovePartnerPlayerAccount, ApprovePartnerPlayerAccountVariables>;

/**
 * __useApprovePartnerPlayerAccount__
 *
 * To run a mutation, you first call `useApprovePartnerPlayerAccount` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePartnerPlayerAccount` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePartnerPlayerAccount, { data, loading, error }] = useApprovePartnerPlayerAccount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovePartnerPlayerAccount(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovePartnerPlayerAccount, ApprovePartnerPlayerAccountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ApprovePartnerPlayerAccount, ApprovePartnerPlayerAccountVariables>(ApprovePartnerPlayerAccountDocument, options);
      }
export type ApprovePartnerPlayerAccountHookResult = ReturnType<typeof useApprovePartnerPlayerAccount>;
export type ApprovePartnerPlayerAccountMutationResult = Apollo.MutationResult<ApprovePartnerPlayerAccount>;
export type ApprovePartnerPlayerAccountMutationOptions = Apollo.BaseMutationOptions<ApprovePartnerPlayerAccount, ApprovePartnerPlayerAccountVariables>;