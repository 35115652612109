import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { PromoCodeModalDetails } from 'commonComponents/PromoCodeModalDetails';
import { useGetPartnerPromoCodeMediaItem } from './queries/generated/GetPartnerPromoCodeMediaItem';

interface Props {
  promoCodeId: string;
  partnerId: string;
}
export const PromoCodeMediaItemModal: FC<Props> = ({
  promoCodeId,
  partnerId,
}) => {
  const { data, loading, error } = useGetPartnerPromoCodeMediaItem({
    variables: { promoCodeId, partnerId },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-partner-promo-code-media-item-error' },
    hasData: !!data,
  });

  const promoCodeMediaItem = data?.partner.promoCodeMediaItem;

  if (!promoCodeMediaItem) {
    return null;
  }

  return <PromoCodeModalDetails promoCodeMediaItem={promoCodeMediaItem} />;
};
