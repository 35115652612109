import { FC, useContext, useState } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useModalManageData, ModalContext } from 'ui/Modal';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { DateFormat, formatDate } from '../../../../utils/formatDate';
import { contactForCommunicationTypeMap, nbsp } from '../../../../const';
import {
  getCompanyApplicationStatus,
  getCompanyApplicationStatusIcon,
} from '../../helpers';
import { CompanyApplicationModalSteps } from '../../const';
import { CreateReferralLink } from '../CreateReferralLink';
import { CompanyApplicationFooter } from './components/CompanyApplicationFooter';
import { useGetCompanyApplication } from './queries/generated/GetCompanyApplication';
import { useCompanyApplicationModalSteps } from './hooks/useCompanyApplicationModalSteps';
import { DeclineCompanyApplicationModal } from '../DeclineCompanyApplicationModal';

interface Props {
  companyApplicationId: string;
}

export const CompanyApplicationModal: FC<Props> = ({
  companyApplicationId,
}) => {
  const { state } = useContext(ModalContext);
  const [referralLinkMediaItem, setReferralLinkMediaItem] =
    useState<null | ReferralLinkData>(null);

  useCompanyApplicationModalSteps({ companyApplicationId });

  const { data, loading, error } = useGetCompanyApplication({
    variables: {
      id: companyApplicationId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'company-application-error' },
    hasData: !!data,
  });

  const companyApplication = data?.companyApplication;

  if (!companyApplication) {
    return null;
  }

  const {
    status,
    companyName,
    companyEmail,
    website,
    declineReason,
    admin,
    createdAt,
    updatedAt,
    contactForCommunication,
    trafficSource,
  } = companyApplication;

  const [statusText, , subTitle] = getCompanyApplicationStatus(
    status,
    admin,
    declineReason
  );

  if (state.step === CompanyApplicationModalSteps.DeclineStep) {
    return (
      <DeclineCompanyApplicationModal
        companyName={companyName}
        companyApplicationId={companyApplicationId}
      />
    );
  }

  if (state.step === CompanyApplicationModalSteps.CreateReferralLink) {
    return (
      <CreateReferralLink
        companyName={companyName}
        companyApplicationId={companyApplicationId}
        setReferralLinkMediaItem={setReferralLinkMediaItem}
      />
    );
  }

  if (state.step === CompanyApplicationModalSteps.UpdateReferralLink) {
    return (
      <CreateReferralLink
        companyName={companyName}
        companyApplicationId={companyApplicationId}
        setReferralLinkMediaItem={setReferralLinkMediaItem}
        referralLinkMediaItem={referralLinkMediaItem}
      />
    );
  }

  return (
    <DataCard
      statusConfig={
        status && {
          status: statusText,
          iconConfig: getCompanyApplicationStatusIcon(status),
          text: subTitle,
        }
      }
      rows={[
        {
          title: 'Название компании',
          value: companyName,
        },
        {
          title: 'Почта',
          value: companyEmail,
        },
        {
          title: `Метод${nbsp}для${nbsp}связи`,
          value: contactForCommunicationTypeMap[contactForCommunication.type],
        },
        {
          title: 'Контакт',
          value: contactForCommunication.value,
        },
        {
          title: `Вебсайт`,
          value: website,
        },
        {
          title: `Источник${nbsp}трафика`,
          value: trafficSource,
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <CompanyApplicationFooter
          companyApplicationStatus={status}
          companyApplicationId={companyApplicationId}
          referralLinkData={referralLinkMediaItem}
        />
      }
    />
  );
};
