import { FC } from 'react';
import { SuspectState } from 'generatedGraphql';
import { useGetSuspectStateButtonProps } from './hooks/useGetSuspectStateButtonProps';
import { SuspectStateButton } from './components/SuspectStateButton';
import { SuspiciousActionConfig } from './types';

interface Props {
  suspectState: SuspectState;
  suspectActionConfig: SuspiciousActionConfig;
  unsuspectActionConfig: SuspiciousActionConfig;
  tooltipUnsuspectMessage: string;
  tooltipSuspectMessage: string;
}

export const SuspiciousCell: FC<Props> = ({
  suspectState,
  suspectActionConfig,
  unsuspectActionConfig,
  tooltipSuspectMessage,
  tooltipUnsuspectMessage,
}) => {
  const { action, tooltipMessage, disabled } = useGetSuspectStateButtonProps({
    suspectState,
    suspectActionConfig,
    unsuspectActionConfig,
    tooltipSuspectMessage,
    tooltipUnsuspectMessage,
  });

  return (
    <SuspectStateButton
      suspectState={suspectState}
      action={action}
      tooltipMessage={tooltipMessage}
      disabled={disabled}
    />
  );
};
