import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdatePlayerIdentitiesVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdatePlayerIdentitiesInput;
}>;


export type UpdatePlayerIdentities = { __typename: 'Mutation', updatePlayerIdentities: { __typename: 'UpdatePlayerIdentitiesPayload', player: { __typename: 'Player', email?: string | null, id: string, phone?: string | null } } };


export const UpdatePlayerIdentitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePlayerIdentities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePlayerIdentitiesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePlayerIdentities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdatePlayerIdentitiesMutationFn = Apollo.MutationFunction<UpdatePlayerIdentities, UpdatePlayerIdentitiesVariables>;

/**
 * __useUpdatePlayerIdentities__
 *
 * To run a mutation, you first call `useUpdatePlayerIdentities` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerIdentities` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerIdentities, { data, loading, error }] = useUpdatePlayerIdentities({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlayerIdentities(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlayerIdentities, UpdatePlayerIdentitiesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePlayerIdentities, UpdatePlayerIdentitiesVariables>(UpdatePlayerIdentitiesDocument, options);
      }
export type UpdatePlayerIdentitiesHookResult = ReturnType<typeof useUpdatePlayerIdentities>;
export type UpdatePlayerIdentitiesMutationResult = Apollo.MutationResult<UpdatePlayerIdentities>;
export type UpdatePlayerIdentitiesMutationOptions = Apollo.BaseMutationOptions<UpdatePlayerIdentities, UpdatePlayerIdentitiesVariables>;