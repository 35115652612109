import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AcceptWithdrawalPaymentVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AcceptPaymentInput;
}>;


export type AcceptWithdrawalPayment = { __typename: 'Mutation', acceptPayment: { __typename: 'AcceptPaymentPayload', payment: { __typename: 'Payment', id: string, status: SchemaTypes.PaymentStatus, updatedAt: string } } };


export const AcceptWithdrawalPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptWithdrawalPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AcceptWithdrawalPaymentMutationFn = Apollo.MutationFunction<AcceptWithdrawalPayment, AcceptWithdrawalPaymentVariables>;

/**
 * __useAcceptWithdrawalPayment__
 *
 * To run a mutation, you first call `useAcceptWithdrawalPayment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptWithdrawalPayment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptWithdrawalPayment, { data, loading, error }] = useAcceptWithdrawalPayment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptWithdrawalPayment(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptWithdrawalPayment, AcceptWithdrawalPaymentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptWithdrawalPayment, AcceptWithdrawalPaymentVariables>(AcceptWithdrawalPaymentDocument, options);
      }
export type AcceptWithdrawalPaymentHookResult = ReturnType<typeof useAcceptWithdrawalPayment>;
export type AcceptWithdrawalPaymentMutationResult = Apollo.MutationResult<AcceptWithdrawalPayment>;
export type AcceptWithdrawalPaymentMutationOptions = Apollo.BaseMutationOptions<AcceptWithdrawalPayment, AcceptWithdrawalPaymentVariables>;