import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { UpdatePartnerIdentities } from '../components/UpdatePartnerIdentities';

interface UseEditPartnerIdentitiesParams {
  partnerId: string;
  email?: string | null;
}

export const useEditPartnerIdentities = ({
  partnerId,
  email,
}: UseEditPartnerIdentitiesParams) => {
  const { dispatch } = useContext(ModalContext);

  const onPartnerIdentitiesEdit = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: <UpdatePartnerIdentities id={partnerId} />,
        title: 'Изменение данных входа',
        subTitle: email && `${email} #${partnerId}`,
      },
    });
  }, [dispatch, email, partnerId]);

  return { onPartnerIdentitiesEdit };
};
