import { FC } from 'react';
import { ProgressBar } from 'ui/ProgressBar';
import styles from './ProgressBarWithLimit.module.scss';

interface Props {
  activated: number;
  limit: number;
}

export const ProgressBarWithLimit: FC<Props> = ({ activated, limit }) => (
  <>
    <div className={styles.progress}>{`${activated}/${limit}`}</div>
    <ProgressBar percent={(activated / limit) * 100} />
  </>
);
