import { FC, useContext } from 'react';
import { ModalContext, TabsWrapper } from 'ui/Modal';
import classnames from 'classnames';
import { CreatePayment } from 'src/components/CreatePayment';
import AcceptPaymentForm from '../AcceptPaymentForm';
import { withWithdrawalItemProvider } from '../../WithdrawalItemStore';
import { WithdrawalModalSteps } from '../WithdrawalPayments/types';
import { useWithdrawalSidebar } from './useWithdrawalSidebar';
import styles from './WithdrawalSidebar.module.scss';
import { useCreatePayment } from './useCreatePayment';

interface Props {
  withdrawalId: string;
}

const WithdrawalSidebar: FC<Props> = ({ withdrawalId }) => {
  const {
    tabs,
    remainingSum,
    paymentPaidSum,
    paymentId,
    activeTab,
    actorInfo,
  } = useWithdrawalSidebar({
    withdrawalId,
  });

  const { handleCreatePayment, loading, error } = useCreatePayment({
    withdrawalId,
  });

  let activeTabDefault = 0;

  if (activeTab) {
    activeTabDefault = activeTab;
  }

  const { state } = useContext(ModalContext);

  if (state.step === WithdrawalModalSteps.CreatePayment) {
    return (
      <CreatePayment
        withdrawalId={withdrawalId}
        remainingSum={remainingSum}
        actorInfo={actorInfo}
        handleCreatePayment={handleCreatePayment}
        createPaymentLoading={loading}
        createPaymentError={error}
      />
    );
  }

  if (
    state.step === WithdrawalModalSteps.AcceptPayment &&
    paymentPaidSum &&
    paymentId
  ) {
    return (
      <AcceptPaymentForm
        id={paymentId}
        paidCurrency={paymentPaidSum.currency}
        paidSum={paymentPaidSum.amount}
      />
    );
  }

  return (
    <TabsWrapper
      tabsWrapper={classnames(activeTab === 1 && styles.tabsWrapper)}
      tabs={tabs}
      activeTabDefault={activeTabDefault}
    />
  );
};

export default withWithdrawalItemProvider(WithdrawalSidebar);
