import { useState } from 'react';
import { ContactForCommunicationType } from 'commonTypes';
import { UpdateCompanyPersonalDataValues } from '../types';

interface UseContactValueTooltipResult {
  isContactValueTooltipVisible: boolean;
  showContactValueTooltip: (values: UpdateCompanyPersonalDataValues) => void;
  hideContactValueTooltip: () => void;
}

export const useContactValueTooltip = (): UseContactValueTooltipResult => {
  const [isContactValueTooltipVisible, setContactValueTooltipVisibility] =
    useState(false);

  const showContactValueTooltip = (values: UpdateCompanyPersonalDataValues) => {
    if (values.contactType === ContactForCommunicationType.Other) {
      setContactValueTooltipVisibility(true);
    }
  };

  const hideContactValueTooltip = () => {
    setContactValueTooltipVisibility(false);
  };

  return {
    isContactValueTooltipVisible,
    showContactValueTooltip,
    hideContactValueTooltip,
  };
};
