export enum ColumnId {
  Id = 'id',
  PaymentMethodName = 'paymentMethod.name',
  Account = 'paymentMethod.account',
  Sum = 'sum',
  PaidSum = 'paidSum',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.PaymentMethodName]: 'Метод',
  [ColumnId.Account]: 'Номер счёта',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.PaidSum]: 'Фактическая сумма',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлен',
};

export const blockColumnsIds = [
  ColumnId.PaymentMethodName,
  ColumnId.Account,
  ColumnId.Sum,
  ColumnId.Status,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.PaymentMethodName,
  ColumnId.Account,
  ColumnId.Sum,
  ColumnId.PaidSum,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
