import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ApproveCompanyPlayerAccountVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ApproveCompanyPlayerAccountInput;
}>;


export type ApproveCompanyPlayerAccount = { __typename: 'Mutation', approveCompanyPlayerAccount: { __typename: 'ApproveCompanyPlayerAccountPayload', company: { __typename: 'Company', id: string, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus } | null } } };


export const ApproveCompanyPlayerAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApproveCompanyPlayerAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ApproveCompanyPlayerAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approveCompanyPlayerAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type ApproveCompanyPlayerAccountMutationFn = Apollo.MutationFunction<ApproveCompanyPlayerAccount, ApproveCompanyPlayerAccountVariables>;

/**
 * __useApproveCompanyPlayerAccount__
 *
 * To run a mutation, you first call `useApproveCompanyPlayerAccount` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCompanyPlayerAccount` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCompanyPlayerAccount, { data, loading, error }] = useApproveCompanyPlayerAccount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveCompanyPlayerAccount(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveCompanyPlayerAccount, ApproveCompanyPlayerAccountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ApproveCompanyPlayerAccount, ApproveCompanyPlayerAccountVariables>(ApproveCompanyPlayerAccountDocument, options);
      }
export type ApproveCompanyPlayerAccountHookResult = ReturnType<typeof useApproveCompanyPlayerAccount>;
export type ApproveCompanyPlayerAccountMutationResult = Apollo.MutationResult<ApproveCompanyPlayerAccount>;
export type ApproveCompanyPlayerAccountMutationOptions = Apollo.BaseMutationOptions<ApproveCompanyPlayerAccount, ApproveCompanyPlayerAccountVariables>;