import { genderMap } from 'src/const';
import { ClientDataBlockRowConfirm } from 'src/components/ClientDataBlockRowConfirm/ClientDataBlockRowConfirm';
import { ClientData } from 'commonComponents/ClientDataBlock/types';
import { getDisplayName } from 'src/utils/getDisplayName';
import capitalize from 'src/utils/capitalize';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useAuth, UserPrivilege } from 'src/components/Auth';
import { getDateConfirmed } from '../../../../../tests/utils';
import { GetPlayerAdditionalData } from './queries/generated/GetPlayerAdditionalData';
import { getAddress } from './helpers';
import { AffiliateLink } from './components/AffiliateLink';

export const useGetPlayerDataConfig = (
  data: GetPlayerAdditionalData | undefined,
  onEdit: () => void
): ClientData | null => {
  const auth = useAuth();

  if (!data) return null;

  const {
    player: {
      affiliate,
      affiliateAccount,
      gender,
      lastName,
      firstName,
      middleName,
      birthday,
      phone,
      email,
      createdAt,
      lastVisited,
      country,
      city,
      emailConfirmedAt,
      locale,
      phoneConfirmedAt,
    },
  } = data;

  const displayName = locale && getDisplayName({ value: locale });

  const capitalizedDisplayName = displayName
    ?.split(' ')
    .map(capitalize)
    .join(' ');

  const isEditButtonVisible = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdatePlayerIdentities],
  });

  return [
    {
      title: 'Данные входа',
      isEditButtonVisible,
      onEdit,
      rows: [
        {
          title: 'Почта',
          value: email,
          alignRightValue: email && (
            <ClientDataBlockRowConfirm
              confirmation={getDateConfirmed(
                emailConfirmedAt,
                'Не подтверждена'
              )}
            />
          ),
        },
        {
          title: 'Телефон',
          value: phone,
          alignRightValue: phone && (
            <ClientDataBlockRowConfirm
              confirmation={getDateConfirmed(
                phoneConfirmedAt,
                'Не подтверждена'
              )}
            />
          ),
        },
        {
          title: 'Дата регистрации',
          value: createdAt && formatDate(createdAt, DateFormat.DefaultDateOld),
        },
        {
          title: 'Последний вход',
          value: lastVisited && formatDate(lastVisited, DateFormat.FullDate),
        },
        {
          title: 'Партнёр / Компания',
          value: affiliate && <AffiliateLink affiliate={affiliate} />,
        },
      ],
    },
    {
      title: 'Персональные данные',
      rows: [
        {
          title: 'Язык',
          value: locale && capitalizedDisplayName,
        },
        {
          title: 'Имя',
          value: firstName,
        },
        {
          title: 'Отчество',
          value: middleName,
        },
        {
          title: 'Фамилия',
          value: lastName,
        },
        {
          title: 'Дата рождения',
          value: birthday && formatDate(birthday, DateFormat.DefaultDateOld),
        },
        {
          title: 'Пол',
          value: gender && genderMap[gender],
        },
        {
          title: 'Адрес',
          value: getAddress(country, city),
        },
        affiliateAccount && {
          title: 'Партнёрский аккаунт',
          value: <AffiliateLink affiliate={affiliateAccount} />,
        },
      ],
    },
  ];
};
