import { BonusTypeName } from 'commonTypes';
import { BonusStatus } from 'generatedGraphql';

export enum ColumnId {
  Id = 'id',
  Type = '__typename',
  Sum = 'sum',
  Wager = 'config.winConditions.wager',
  Cashout = 'config.cashout',
  MinBalance = 'config.minAccountBalance',
  BetSum = 'config.winConditions.betSum',
  WinSum = 'winSum',
  Balance = 'balance',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const bonusStatusMap: {
  [key in BonusStatus]: string;
} = {
  [BonusStatus.Active]: 'Активен',
  [BonusStatus.Canceled]: 'Отменён',
  [BonusStatus.AutoCanceled]: 'Отменён автоматически',
  [BonusStatus.Expired]: 'Истёк',
  [BonusStatus.Pending]: 'В\u00a0ожидании',
  [BonusStatus.Won]: 'Отыгран',
  [BonusStatus.Unknown]: 'Unknown',
};

export const bonusNameMap = {
  [BonusTypeName.Cashback]: 'Кэшбэк',
  [BonusTypeName.FirstDeposit]: '100% на\u00a0первый депозит',
  [BonusTypeName.Freespin]: 'Бонус с\u00a0фриспинов',
  [BonusTypeName.Gift]: 'Бонус от\u00a0казино',
  [BonusTypeName.PromoCode]: 'Бонус по\u00a0промокоду',
  [BonusTypeName.PersonalBonus]: 'Персональный бонус',
};

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Type]: 'Тип',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.Wager]: 'Вейджер',
  [ColumnId.Cashout]: 'Кэшаут',
  [ColumnId.MinBalance]: 'Минимальный баланс',
  [ColumnId.BetSum]: 'Сумма ставок',
  [ColumnId.WinSum]: 'Выигрыш',
  [ColumnId.Balance]: 'Бонусный баланс',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const blockColumnsIds = [
  ColumnId.Type,
  ColumnId.Sum,
  ColumnId.Wager,
  ColumnId.Status,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Type,
  ColumnId.Sum,
  ColumnId.Wager,
  ColumnId.Cashout,
  ColumnId.MinBalance,
  ColumnId.BetSum,
  ColumnId.WinSum,
  ColumnId.Balance,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
