export enum ColumnId {
  Player = 'player',
  FirstDeposit = 'firstDeposit',
  Deposit = 'deposit',
  ReferralLink = '__typename',
  ReferralStatus = 'referralStatus',
  Redeposit = 'redeposit',
  Redeposits = 'redeposits',

  Withdrawal = 'withdrawal',
  NGR = 'financialIndicators.ngr',

  Profit = 'financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  Paid = 'affiliateRewardStatistic.paid',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Player]: 'Игрок',
  [ColumnId.FirstDeposit]: 'Первый депозит',
  [ColumnId.Deposit]: 'Депозиты',
  [ColumnId.ReferralLink]: 'CPA тариф',
  [ColumnId.ReferralStatus]: 'Статус',
  [ColumnId.Redeposit]: 'Редепозиты',
  [ColumnId.Redeposits]: 'Количество редепозитов',

  [ColumnId.Withdrawal]: 'Выводы',
  [ColumnId.NGR]: 'NGR',

  [ColumnId.Profit]: 'Доход',
  [ColumnId.Upcoming]: 'Вознаграждение',
  [ColumnId.Paid]: 'Выплачено',
};

export const columnsIds: Array<ColumnId> = [
  ColumnId.Player,
  ColumnId.FirstDeposit,
  ColumnId.Deposit,
  ColumnId.ReferralLink,
  ColumnId.ReferralStatus,
  ColumnId.Redeposit,
  ColumnId.Redeposits,

  ColumnId.Withdrawal,
  ColumnId.NGR,

  ColumnId.Profit,
  ColumnId.Upcoming,
  ColumnId.Paid,
];
