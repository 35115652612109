import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { RevShareRewardTariffData } from '../../../queries/generated/RevShareRewardTariffData';
import { RevShareRewardTariffModal } from '../components/RevShareRewardTariffModal';

interface OpenModalParams {
  cpaRewardTariff: RevShareRewardTariffData;
  initiatorId: string;
}

type UseCpaRewardTariffModalResult = (params: OpenModalParams) => void;

export const useRevShareRewardTariffsModal =
  (): UseCpaRewardTariffModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      async ({ cpaRewardTariff: { id }, initiatorId }: OpenModalParams) => {
        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: `#${id}`,
            subTitle: 'Партнёрский тариф',
            content: <RevShareRewardTariffModal revShareRewardTariffsId={id} />,
            initiatorId,
          },
        });
      },
      [modalDispatch]
    );

    return openModal;
  };
