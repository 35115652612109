import { FC, ReactElement } from 'react';
import { Popup } from 'ui/Popup';
import { ButtonTheme } from 'ui/Button';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';

interface Props {
  message: string;
  action: () => void;
  triggerElement: ReactElement;
  privilege: UserPrivilege;
}

export const QualifiedReferralsHeaderActionPopup: FC<Props> = ({
  message,
  action,
  triggerElement,
  privilege,
}) => (
  <Privilege privileges={[privilege]}>
    <Popup
      content={message}
      actionButtonsConfig={[
        {
          text: 'Подтвердить',
          theme: ButtonTheme.Primary,
          action,
        },
        {
          text: 'Отменить',
          theme: ButtonTheme.Process,
        },
      ]}
      triggerElement={triggerElement}
      isActionButtonsMaxWidth
    />
  </Privilege>
);
