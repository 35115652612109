import * as SchemaTypes from '../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ResetAdminPasswordVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ResetAdminPasswordInput;
}>;


export type ResetAdminPassword = { __typename: 'Mutation', resetAdminPassword: { __typename: 'ResetAdminPasswordPayload', admin: { __typename: 'Admin', id: string } } };


export const ResetAdminPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetAdminPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetAdminPasswordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetAdminPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ResetAdminPasswordMutationFn = Apollo.MutationFunction<ResetAdminPassword, ResetAdminPasswordVariables>;

/**
 * __useResetAdminPassword__
 *
 * To run a mutation, you first call `useResetAdminPassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAdminPassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAdminPassword, { data, loading, error }] = useResetAdminPassword({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetAdminPassword(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetAdminPassword, ResetAdminPasswordVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetAdminPassword, ResetAdminPasswordVariables>(ResetAdminPasswordDocument, options);
      }
export type ResetAdminPasswordHookResult = ReturnType<typeof useResetAdminPassword>;
export type ResetAdminPasswordMutationResult = Apollo.MutationResult<ResetAdminPassword>;
export type ResetAdminPasswordMutationOptions = Apollo.BaseMutationOptions<ResetAdminPassword, ResetAdminPasswordVariables>;