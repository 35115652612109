import { UserPrivilege } from 'generatedUserPrivilege';
import { CompanyApplicationStatus } from 'generatedGraphql';
import { useAuth } from 'commonComponents/Auth';

export const useAllowedButtons = (
  companyApplicationStatus: CompanyApplicationStatus
) => {
  const auth = useAuth();

  const isAllowedToUpdateApplication = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const hasAssignButton =
    isAllowedToUpdateApplication([UserPrivilege.AssignCompanyApplication]) &&
    companyApplicationStatus === CompanyApplicationStatus.Pending;

  const hasSuspendButton =
    isAllowedToUpdateApplication([UserPrivilege.SuspendCompanyApplication]) &&
    companyApplicationStatus === CompanyApplicationStatus.InProgress;

  const hasAcceptButton =
    isAllowedToUpdateApplication([UserPrivilege.AssignCompanyApplication]) &&
    companyApplicationStatus === CompanyApplicationStatus.InProgress;

  const hasDeclineButton =
    isAllowedToUpdateApplication([UserPrivilege.DeclineCompanyApplication]) &&
    companyApplicationStatus === CompanyApplicationStatus.InProgress;

  return {
    hasAssignButton,
    hasSuspendButton,
    hasAcceptButton,
    hasDeclineButton,
  };
};
