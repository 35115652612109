import { FC, ReactElement } from 'react';
import styles from './PromoCodeActionBlockContainer.module.scss';

interface Props {
  title: string;
  children: ReactElement;
}

export const PromoCodeActionBlockContainer: FC<Props> = ({
  title,
  children,
}) => (
  <div className={styles.container}>
    <span className={styles.blockTitle}>{title}</span>
    <div className={styles.content}>{children}</div>
  </div>
);
