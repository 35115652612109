import React, { FC } from 'react';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faWallet } from '@fortawesome/pro-solid-svg-icons';
import styles from './TransferAccount.module.scss';

interface Props {
  moneyTransferAccount: string;
}

const TransferAccount: FC<Props> = ({ moneyTransferAccount }) => {
  const iconConfig = {
    icon: faWallet,
    iconColor: CardIconColor.BLUE_GREY,
  };

  return (
    <div className={styles.container}>
      <CardIcon config={iconConfig} className={styles.cardIcon} />
      <span className={styles.title}>Номер счёта игрока: </span>
      <span>{moneyTransferAccount}</span>
    </div>
  );
};

export default TransferAccount;
