import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CancelChargeBackRestrictionVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CancelChargeBackRestrictionInput;
}>;


export type CancelChargeBackRestriction = { __typename: 'Mutation', cancelChargeBackRestriction: { __typename: 'CancelChargeBackRestrictionPayload', chargeBackRestriction: { __typename: 'ChargeBackRestriction', canceledAt?: string | null, id: string, updatedAt: string } } };


export const CancelChargeBackRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelChargeBackRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelChargeBackRestrictionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelChargeBackRestriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargeBackRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CancelChargeBackRestrictionMutationFn = Apollo.MutationFunction<CancelChargeBackRestriction, CancelChargeBackRestrictionVariables>;

/**
 * __useCancelChargeBackRestriction__
 *
 * To run a mutation, you first call `useCancelChargeBackRestriction` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelChargeBackRestriction` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelChargeBackRestriction, { data, loading, error }] = useCancelChargeBackRestriction({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelChargeBackRestriction(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelChargeBackRestriction, CancelChargeBackRestrictionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CancelChargeBackRestriction, CancelChargeBackRestrictionVariables>(CancelChargeBackRestrictionDocument, options);
      }
export type CancelChargeBackRestrictionHookResult = ReturnType<typeof useCancelChargeBackRestriction>;
export type CancelChargeBackRestrictionMutationResult = Apollo.MutationResult<CancelChargeBackRestriction>;
export type CancelChargeBackRestrictionMutationOptions = Apollo.BaseMutationOptions<CancelChargeBackRestriction, CancelChargeBackRestrictionVariables>;