import { FC, PropsWithChildren } from 'react';
import { ClientDataBlock } from 'commonComponents/ClientDataBlock/ClientDataBlock';
import { withBlockProvider } from 'ui/Block/store';
import { useCompanyData } from './hooks/useCompanyData';

const TITLE_COLUMN_MIN_WIDTH = 175;

interface Props {
  id: string;
}

export const CompanyDataBlock: FC<PropsWithChildren<Props>> = withBlockProvider(
  ({ id }) => {
    const { refetchCompanyData, clientData } = useCompanyData({ id });

    return (
      <ClientDataBlock
        blockId="companyData"
        title="Данные компании"
        onUpdate={refetchCompanyData}
        data={clientData}
        titleColumnMinWidth={TITLE_COLUMN_MIN_WIDTH}
      />
    );
  }
);
