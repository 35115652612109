import { RewardTariffType } from 'commonTypes';
import { FormikErrors } from 'formik';
import { GenerateReferralLinkCode } from 'src/queries/generated/GenerateReferralLinkCode';
import { FieldName } from './const';

export interface CreateCompanyReferralLinkValues {
  [FieldName.Company]: string;
  [FieldName.MediaCampaignId]: string;
  [FieldName.MediaItemName]: string;
  [FieldName.Code]: GenerateReferralLinkCode | null;
  [FieldName.PaymentModel]: RewardTariffType | null;
  [FieldName.CPATariffId]: string;
  [FieldName.FallbackTariffId]: string;
  [FieldName.RevShareTariffId]: string;
}

export const validate = (
  values: CreateCompanyReferralLinkValues
): FormikErrors<CreateCompanyReferralLinkValues> => {
  const errors: FormikErrors<CreateCompanyReferralLinkValues> = {};

  if (!values[FieldName.MediaItemName]) {
    errors.mediaItemName = 'Поле не может быть пустым';
  }

  if (!values[FieldName.MediaCampaignId]) {
    errors.mediaCampaignId = 'Поле не может быть пустым';
  }

  if (!values[FieldName.Code]) {
    errors.code = 'Поле не может быть пустым';
  }

  if (!values[FieldName.PaymentModel]) {
    errors.paymentModel = 'Поле не может быть пустым';
  }

  if (
    values[FieldName.PaymentModel] === RewardTariffType.Cpa &&
    !values[FieldName.CPATariffId]
  ) {
    errors.CPATariffId = 'Поле не может быть пустым';
  }

  if (
    values[FieldName.PaymentModel] === RewardTariffType.Cpa &&
    !values[FieldName.FallbackTariffId]
  ) {
    errors.fallbackTariffId = 'Поле не может быть пустым';
  }

  if (
    values[FieldName.PaymentModel] === RewardTariffType.RevShare &&
    values[FieldName.RevShareTariffId] === ''
  ) {
    errors.revShareTariffId = 'Поле не может быть пустым';
  }

  return errors;
};
