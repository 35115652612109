import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { useGetTournamentRebuy } from '../../queries/generated/GetTournamentRebuy';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';

interface Props {
  tournamentRebuyId: string;
}

export const TournamentRebuyModal: FC<Props> = ({ tournamentRebuyId }) => {
  const { data, loading, error } = useGetTournamentRebuy({
    variables: {
      tournamentRebuyId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-tournament-rebuy-error' },
    hasData: !!data,
  });

  if (!data?.tournamentRebuy) {
    return null;
  }

  const { tournament, createdAt, sum } = data.tournamentRebuy;

  return (
    <DataCard
      rows={[
        {
          title: 'Название турнира',
          value: tournament.name,
        },
        {
          title: 'Сумма',
          value: formatSumWithCurrency(sum.amount, sum.currency),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
    />
  );
};
