import { CardIconColor } from 'ui/Card';
import { MoneyTransferType } from 'commonTypes';
import { faLeftToLine, faRightToLine } from '@fortawesome/pro-solid-svg-icons';
import { CardIconConfig } from 'ui/Card/CardIcon';

export const getMoneyTransferIconConfig = (value: string): CardIconConfig => {
  switch (value) {
    case MoneyTransferType.Incoming:
      return { icon: faRightToLine, iconColor: CardIconColor.GREEN };

    case MoneyTransferType.Outgoing:
      return { icon: faLeftToLine, iconColor: CardIconColor.RED };

    default:
      return { icon: faRightToLine, iconColor: CardIconColor.GREEN };
  }
};
