import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from '../../../../../../../../../const';
import { useGetCompanyAffiliatesForReferralLinkMediaItem } from '../queries/generated/GetCompanyAffiliatesForReferralLinkMediaItem';

const COUNT_PER_PAGE = 10;

export const useGetCompanyAffiliatesQuery = (companyId: string) => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке партнеров компании произошла ошибка`,
      text: error.message,
      toastId: 'get-company-affiliates-for-referral-link-media-item-error',
    });
  };

  const {
    data,
    loading,
    fetchMore: fetchMoreCompanyAffiliates,
    error,
  } = useGetCompanyAffiliatesForReferralLinkMediaItem({
    variables: {
      id: companyId,
      first: COUNT_PER_PAGE,
    },
    onError: handleError,
  });

  const hasNextPage = data?.company.affiliates.pageInfo.hasNextPage;
  const endCursor = data?.company.affiliates?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      fetchMoreCompanyAffiliates({
        variables: {
          id: companyId,
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [companyId, fetchMoreCompanyAffiliates, endCursor]
  );

  return {
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  };
};
