import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { LastBadBankRestrictionData } from './LastBadBankRestrictionData';
import { LastRestrictionData } from './LastRestrictionData';
import { LastChargeBackRestrictionData } from './LastChargeBackRestrictionData';
import { LastReceiveGiftRestrictionData } from './LastReceiveGiftRestrictionData';
import { LastWithdrawalRestrictionData } from './LastWithdrawalRestrictionData';
export type LastRestrictionsData = { __typename: 'LastRestrictions', badBankRestriction?: { __typename: 'BadBankRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } | null, chargeBackRestriction?: { __typename: 'ChargeBackRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } | null, receiveGiftRestriction?: { __typename: 'ReceiveGiftRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string } | null, withdrawalRestriction?: { __typename: 'WithdrawalRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, createdAt: string, id: string, reason: string, updatedAt: string } | null };

export const LastRestrictionsData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LastRestrictionsData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LastRestrictions"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"badBankRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LastBadBankRestrictionData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"chargeBackRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LastChargeBackRestrictionData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"receiveGiftRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LastReceiveGiftRestrictionData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"withdrawalRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LastWithdrawalRestrictionData"}}]}}]}}]} as unknown as DocumentNode;