import React, { FC, useContext, useEffect } from 'react';
import { ListAllOperations } from 'generatedGraphql';
import Button, { ButtonTheme } from 'ui/Button';
import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import { ModalActions, ModalContext } from 'ui/Modal';
import Error from 'ui/Error';
import { toast } from 'react-toastify';
import { SidebarLoader } from 'ui/SidebarLoader';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Tooltip } from 'ui/Tooltip';
import styles from './CreateMessageForm.module.scss';
import { useCreateMessage } from './queries/generated/CreateMessage';
import { useAddPlayerNameToSidebarSubTitle } from '../../../../../../utils/hooks/useAddPlayerNameToSidebarSubTitle';
import { useGetPlayerLocale } from './queries/generated/GetPlayerLocale';
import capitalize from '../../../../../../utils/capitalize';
import { CreateMessageValues, validate } from './validation';
import { getDisplayName } from '../../../../../../utils/getDisplayName';

interface Props {
  playerId: string;
  hasMessagesList: boolean;
  isFullScreenTable: boolean;
}

const CreateMessageForm: FC<Props> = ({
  hasMessagesList,
  playerId,
  isFullScreenTable,
}) => {
  const { dispatch } = useContext(ModalContext);

  useAddPlayerNameToSidebarSubTitle(playerId);

  const {
    data,
    loading: getPlayerLocaleLoading,
    error: getPlayerLocaleError,
  } = useGetPlayerLocale({
    variables: {
      id: playerId,
    },
  });

  const player = data?.player;

  const locale = player?.locale;

  const [
    createMessage,
    {
      data: createResult,
      loading: createMessageLoading,
      error: createMessageError,
    },
  ] = useCreateMessage({
    ...(hasMessagesList
      ? {
          refetchQueries: [
            isFullScreenTable
              ? ListAllOperations.Query.GetPlayerMessages
              : ListAllOperations.Query.GetPlayerMessagesBlock,
          ],
        }
      : null),
  });

  useEffect(() => {
    if (createResult) {
      dispatch({ type: ModalActions.Close });

      toast.success('Сообщение отправлено');
    }
  }, [dispatch, createResult]);

  const initialValues: CreateMessageValues = {
    playerId,
    text: '',
  };

  const onSubmit = async (values: CreateMessageValues) => {
    await createMessage({
      variables: {
        input: {
          playerId: values.playerId,
          text: values.text,
        },
      },
    });
  };

  const localeName = locale && getDisplayName({ value: locale });

  const error = createMessageError || getPlayerLocaleError;

  return (
    <SidebarLoader loading={getPlayerLocaleLoading}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({ isValid, dirty }) => (
          <Form>
            {error && <Error error={error} />}

            <div className={styles.locale}>{`Предпочитаемый язык: ${
              localeName ? capitalize(localeName) : ''
            }`}</div>
            <FormRowsWrapper>
              <Input.Textarea
                placeholder="Введите сообщение"
                name="text"
                label="Сообщение"
              />
            </FormRowsWrapper>

            <Tooltip
              placement="right"
              tooltipContent={
                !isValid || !dirty ? 'Необходимо заполнить поле' : undefined
              }
            >
              <Button
                theme={ButtonTheme.Success}
                type="submit"
                isLoading={createMessageLoading}
                disabled={!isValid || !dirty}
              >
                Отправить
              </Button>
            </Tooltip>
          </Form>
        )}
      </Formik>
    </SidebarLoader>
  );
};

export default CreateMessageForm;
