import { QualifiedReferralStatus, SuspectState } from 'generatedGraphql';
import errorToast from 'ui/Toast/ErrorToast';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { UndoActionToastType } from 'ui/Toast/const';
import { useRejectQualifiedReferral } from '../queries/generated/RejectQualifiedReferral';
import { useApproveQualifiedReferral } from '../queries/generated/ApproveQualifiedReferral';

interface UseReferralActionsParams {
  id: string;
}

export const useReferralActions = ({ id }: UseReferralActionsParams) => {
  const [approveQualifiedReferral, { loading: approveLoading }] =
    useApproveQualifiedReferral({
      variables: {
        input: {
          id,
        },
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'approveQualifiedReferralErrorToast',
        });
      },
    });

  const [rejectQualifiedReferral, { loading: rejectLoading }] =
    useRejectQualifiedReferral({
      variables: {
        input: {
          id,
        },
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'rejectQualifiedReferralErrorToast',
        });
      },
    });

  const approveReferral = () => {
    approveQualifiedReferral({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        approveQualifiedReferral: {
          referral: {
            id,
            status: QualifiedReferralStatus.Approved,
            suspectState: SuspectState.NotEligible,
            updatedAt: new Date().toISOString(),
            __typename: 'QualifiedReferral',
          },
          __typename: 'ApproveQualifiedReferralPayload',
        },
      },
    });

    undoActionToast('Игрок подтверждён');
  };

  const rejectReferral = () => {
    rejectQualifiedReferral({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        rejectQualifiedReferral: {
          referral: {
            id,
            status: QualifiedReferralStatus.Rejected,
            suspectState: SuspectState.NotEligible,
            updatedAt: new Date().toISOString(),
            __typename: 'QualifiedReferral',
          },
          __typename: 'RejectQualifiedReferralPayload',
        },
      },
    });

    undoActionToast('Игрок отклонён', UndoActionToastType.Error);
  };

  return {
    approveReferral,
    rejectReferral,
    approveLoading,
    rejectLoading,
  };
};
