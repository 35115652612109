import { FC, ReactElement } from 'react';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';

interface Props {
  title: string;
  id: string;
  subTitle?: string | ReactElement | null;
  route: Route;
}

export const MultilineCellWithLinkWithPeriodQueryParams: FC<Props> = ({
  title,
  id,
  subTitle,
  route,
}) => {
  const generatePathWithPeriodQueryParams =
    useGeneratePathWithSavePeriodQueryParams();

  const path = generatePathWithPeriodQueryParams({
    originalPath: route,
    params: { id },
  });

  return (
    <MultilineCellWithLink
      title={title}
      subTitle={subTitle}
      route={path}
      hasIcon={false}
    />
  );
};
