import { FC, PropsWithChildren } from 'react';
import { Sum } from 'generatedGraphql';
import createStore from '../../../../utils/createStore';

interface WithdrawalItemState {
  paymentPaidSum?: Sum | null;
  paymentId?: string | null;
}

enum WithdrawalItemActions {
  setData,
}

interface Action {
  type: WithdrawalItemActions.setData;
  payload: {
    paymentPaidSum?: Sum | null;
    paymentId?: string | null;
  };
}

interface Props {
  withdrawalId: string;
  playerId: string;
}

const initialState: WithdrawalItemState = {
  paymentPaidSum: null,
  paymentId: null,
};

const reducer = (currentState: WithdrawalItemState, action: Action) => {
  const newState = { ...currentState };

  switch (action.type) {
    case WithdrawalItemActions.setData:
      newState.paymentPaidSum = action.payload.paymentPaidSum;
      newState.paymentId = action.payload.paymentId;

      return newState;

    default:
      return currentState;
  }
};

const [WithdrawalItemContext, WithdrawalItemProvider] = createStore(
  reducer,
  initialState
);

export { WithdrawalItemContext, WithdrawalItemProvider, WithdrawalItemActions };

export const withWithdrawalItemProvider = (
  OriginalComponent: FC<PropsWithChildren<Props>>
): ((props: Props) => JSX.Element) => {
  const WrappedComponent = (props: Props) => (
    <WithdrawalItemProvider>
      <OriginalComponent {...props} />
    </WithdrawalItemProvider>
  );

  WrappedComponent.displayName = `withWithdrawalItemProvider(${OriginalComponent.name})`;

  return WrappedComponent;
};
