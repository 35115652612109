import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CardIconColor } from 'ui/Card';
import { DepositStatus } from 'generatedGraphql';
import {
  faBan,
  faCircleCheck,
  faExclamationCircle,
  faHourglassEmpty,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';

export const getStatusIcon = (value: string): [IconProp, CardIconColor] => {
  let icon: IconProp;
  let color;

  switch (value) {
    case DepositStatus.Accepted:
      icon = faCircleCheck;
      color = CardIconColor.GREEN;
      break;
    case DepositStatus.InProgress:
      icon = faSpinner;
      color = CardIconColor.BLUE;
      break;
    case DepositStatus.Pending:
      icon = faHourglassEmpty;
      color = CardIconColor.ORANGE;
      break;
    case DepositStatus.Declined:
    case DepositStatus.Expired:
      icon = faBan;
      color = CardIconColor.RED;
      break;
    case DepositStatus.PresumablyDeclined:
      icon = faExclamationCircle;
      color = CardIconColor.RED;
      break;

    default:
      icon = faCircleCheck;
      color = CardIconColor.GREEN;
      break;
  }

  return [icon, color];
};
