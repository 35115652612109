import { useParams } from 'react-router';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';
import { TournamentRebuysBlock } from '../../components/TournamentRebuysBlock';

export const TournamentRebuys = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <TournamentRebuysBlock id={id} shouldReturnToPrevPage isFullScreenTable />
    </PlayerPageContainer>
  );
};
