import styles from './DropdownOptionsList.module.scss';

export enum DropdownOptionsPosition {
  Right = 'right',
  Left = 'left',
}

export const mapDropdownOptionsPositionToClass: {
  [key in DropdownOptionsPosition]: string;
} = {
  [DropdownOptionsPosition.Left]: styles.leftPosition,
  [DropdownOptionsPosition.Right]: styles.rightPosition,
};
