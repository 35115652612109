import { useContext } from 'react';
import { ModalContext, Tabs } from 'ui/Modal';
import { CounterTabTitle } from 'commonComponents/CounterTabTitle';
import styles from '../CompanyAffiliateApplicationModal.module.scss';
import { GetCompanyAffiliateApplication } from '../queries/generated/GetCompanyAffiliateApplication';
import { ApplicationInfoTab } from '../components/ApplicationInfoTab';
import { AffiliatesTab } from '../components/AffiliatesTab';
import { CompanyAffiliateApplicationContext } from '../store';
import { getAffiliateCount } from '../helpers';
import { MAX_AFFILIATE_COUNT_TO_TITLE_TAB } from '../const';

interface UseTabsSidebarParams {
  companyAffiliateApplication?: GetCompanyAffiliateApplication['companyAffiliateApplication'];
}

export const useTabsSidebar = ({
  companyAffiliateApplication,
}: UseTabsSidebarParams) => {
  const { state } = useContext(ModalContext);

  const { state: companyAffiliateApplicationState } = useContext(
    CompanyAffiliateApplicationContext
  );

  if (!companyAffiliateApplication) {
    return null;
  }

  const { items } = companyAffiliateApplication;
  const affiliatesCount = items.length || 0;
  const moreThanMaxCount = affiliatesCount > MAX_AFFILIATE_COUNT_TO_TITLE_TAB;

  const affiliatesInProgress =
    companyAffiliateApplicationState?.affiliatesInProgress;

  const count = getAffiliateCount({
    affiliatesInProgress,
    companyAffiliateApplication,
    moreThanMaxCount,
  });

  const tabs: Array<Tabs> = [
    {
      title: 'Информация',
      content: (
        <ApplicationInfoTab
          companyAffiliateApplication={companyAffiliateApplication}
        />
      ),
    },
    {
      title: (
        <div className={styles.tab}>
          <span>Партнёры</span>
          {!!count && (
            <CounterTabTitle
              withPlus={moreThanMaxCount}
              count={count}
              isActiveTab={state.activeTab === 1}
            />
          )}
        </div>
      ),
      content: (
        <AffiliatesTab
          isEditable={companyAffiliateApplication.editable}
          affiliates={companyAffiliateApplication.items}
          status={companyAffiliateApplication.status}
        />
      ),
    },
  ];

  const { activeTab } = state;

  let activeTabDefault = 0;

  if (activeTab) {
    activeTabDefault = activeTab;
  }

  return {
    tabs,
    activeTab,
    activeTabDefault,
  };
};
