const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const generatePromoCodeToken = (length = 8) => {
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};
