import { FC, useState } from 'react';
import { RewardTariffType } from 'commonTypes';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import {
  GENERAL_REPORT_INPUT_NAME,
  GENERAL_REPORT_SEARCH_PLACEHOLDER,
  GENERAL_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { StatisticTabs } from '../StatisticTabs';
import { StatisticBlockHeaderContent } from '../StatisticBlockHeaderContent';
import { useGetCpaGeneralReport } from './hooks/useGetCpaGeneralReport';
import { CpaGeneralReportTable } from './components/CpaGeneralReportTable';
import { useGetExportCpaGeneralReport } from './hooks/useGetExportCpaGeneralReport';

interface Props {
  setTariffType: (type: RewardTariffType) => void;
}

const Component: FC<Props> = withBlockProvider(({ setTariffType }) => {
  const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
  const { cpaGeneralReportList, refetch, hasTotalRow, loadReport } =
    useGetCpaGeneralReport(isAllTimeCpa);

  const exportReportConfig = useGetExportCpaGeneralReport({
    isAllTimeCpa,
  });

  const listExist = hasList(cpaGeneralReportList);

  return (
    <>
      <SearchInput
        name={GENERAL_REPORT_INPUT_NAME}
        placeholder={GENERAL_REPORT_SEARCH_PLACEHOLDER}
        onSubmit={loadReport}
      />
      <Block
        title={GENERAL_STATISTIC_BLOCK_TITLE}
        id="statisticCpa"
        headerContent={
          <StatisticBlockHeaderContent
            hasList={listExist}
            exportReportConfig={exportReportConfig}
            handleRefetchClick={refetch}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
            rewardTariffType={RewardTariffType.Cpa}
          />
        }
        subHeader={
          <StatisticTabs
            currentTabId={RewardTariffType.Cpa}
            setTariffType={setTariffType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaGeneralReportList)}
        isPermanentOpened
      >
        {listExist && (
          <CpaGeneralReportTable
            report={cpaGeneralReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    </>
  );
});

export const CpaGeneralReport = (props: Props) => (
  <SearchContextProvider pageType={PageTypeStorage.GeneralReport}>
    <Component {...props} />
  </SearchContextProvider>
);
