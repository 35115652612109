import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { PlayerRank } from 'commonTypes';
import { ApolloError } from '@apollo/client';
import { useUpdatePlayerRank as useUpdatePlayerRankMutation } from './queries/generated/UpdatePlayerRank';

interface Args {
  id: string;
}

interface UpdateRankPlayer {
  rank: PlayerRank;
}

interface Return {
  loading: boolean;
  onUpdateRankPlayer: ({ rank }: UpdateRankPlayer) => void;
  error?: ApolloError;
}

export const useUpdatePlayerRank = ({ id }: Args): Return => {
  const { dispatch } = useContext(ModalContext);

  const [
    updatePlayerRank,
    { data: updateResult, error, loading: updateLoading },
  ] = useUpdatePlayerRankMutation();
  const onUpdateRankPlayer = async ({ rank }: UpdateRankPlayer) => {
    await updatePlayerRank({
      variables: { input: { id, rank } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Игрок изменён');
    }
  }, [updateResult, dispatch]);

  return {
    loading: updateLoading,
    onUpdateRankPlayer,
    error,
  };
};
