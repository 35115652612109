import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import NowrapCell from 'ui/Table/components/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { twoEmDash } from 'src/const';
import { getStatusAndIconConfig, restrictionTypeMap } from '../helpers';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';
import { RestrictionsData } from '../queries/generated/RestrictionsData';

const columnHelper = createColumnHelper<RestrictionsData>();

const columnsSource: ColumnsSource<RestrictionsData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Type,
    header: mapColumnIdToName[ColumnId.Type],
    cell: ({ row }) => restrictionTypeMap[row.original.__typename],
  }),
  columnHelper.accessor(ColumnId.Reason, {
    id: ColumnId.Reason,
    header: mapColumnIdToName[ColumnId.Reason],
    cell: ({ row }) => MayBeNullCell(row.original.reason, twoEmDash),
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status, iconConfig } = getStatusAndIconConfig(row.original);

      return MultilineCellWithIcon({
        title: status,
        iconConfig: iconConfig.icon ? iconConfig : undefined,
      });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetRestrictionsHistoryColumns = (
  isFullScreenTable: boolean
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
