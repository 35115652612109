import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCompanyAffiliateApplicationItemVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateCompanyAffiliateApplicationItemInput;
}>;


export type UpdateCompanyAffiliateApplicationItem = { __typename: 'Mutation', updateCompanyAffiliateApplicationItem: { __typename: 'UpdateCompanyAffiliateApplicationItemPayload', item: { __typename: 'CompanyAffiliateApplicationItem', id: string, name: string, note?: { __typename: 'Note', text: string } | null } } };


export const UpdateCompanyAffiliateApplicationItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompanyAffiliateApplicationItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateCompanyAffiliateApplicationItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCompanyAffiliateApplicationItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"item"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateCompanyAffiliateApplicationItemMutationFn = Apollo.MutationFunction<UpdateCompanyAffiliateApplicationItem, UpdateCompanyAffiliateApplicationItemVariables>;

/**
 * __useUpdateCompanyAffiliateApplicationItem__
 *
 * To run a mutation, you first call `useUpdateCompanyAffiliateApplicationItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAffiliateApplicationItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAffiliateApplicationItem, { data, loading, error }] = useUpdateCompanyAffiliateApplicationItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyAffiliateApplicationItem(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyAffiliateApplicationItem, UpdateCompanyAffiliateApplicationItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCompanyAffiliateApplicationItem, UpdateCompanyAffiliateApplicationItemVariables>(UpdateCompanyAffiliateApplicationItemDocument, options);
      }
export type UpdateCompanyAffiliateApplicationItemHookResult = ReturnType<typeof useUpdateCompanyAffiliateApplicationItem>;
export type UpdateCompanyAffiliateApplicationItemMutationResult = Apollo.MutationResult<UpdateCompanyAffiliateApplicationItem>;
export type UpdateCompanyAffiliateApplicationItemMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyAffiliateApplicationItem, UpdateCompanyAffiliateApplicationItemVariables>;