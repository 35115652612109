import { FC, useMemo, PropsWithChildren } from 'react';
import { useAuth, UserPrivilege } from '../Auth';

interface Props {
  privileges: Array<UserPrivilege>;
}

const Privilege: FC<PropsWithChildren<Props>> = ({ children, privileges }) => {
  const auth = useAuth();
  const hasUserPrivilege = useMemo(
    () => auth.privileges.areEveryGranted({ privileges }),
    [auth, privileges]
  );

  if (!hasUserPrivilege) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default Privilege;
