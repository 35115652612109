import { FC } from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
  GroupWithoutIsActive,
  MenuButtonData,
  MenuGroupItemData,
  MenuLinkData,
} from '../../types';
import { MenuItem } from '../MenuItem';
import { SubItem } from './components/SubItem';
import styles from './MenuGroup.module.scss';

interface Props {
  groupTitle: string;
  groupIcon: IconDefinition;
  className?: string;
  subMenuClassName?: string;
  group?: MenuGroupItemData['group'];
  isOpen: boolean;
  isActive: boolean;
  toggleMenuDropdown: (arg: GroupWithoutIsActive) => void;
  openMenuDropdownItem: (id: string) => void;
}

export const MenuGroup: FC<Props> = ({
  groupTitle,
  groupIcon,
  group,
  className,
  subMenuClassName,
  isOpen,
  isActive,
  toggleMenuDropdown,
  openMenuDropdownItem,
}) => {
  if (!group) {
    return null;
  }

  return (
    <div className={className}>
      <MenuItem
        onClick={() => toggleMenuDropdown({ id: groupTitle, isOpen: !isOpen })}
        className={styles.itemGroup}
        icon={groupIcon}
        title={groupTitle}
        isActive={isActive}
        isOpen={isOpen}
        isDropdown
      />
      {isOpen && (
        <div className={classNames(subMenuClassName, styles.list)}>
          {group.map((item) => {
            if ('route' in item) {
              const { route, title } = item as MenuLinkData;

              return (
                <SubItem
                  onClick={() => openMenuDropdownItem(groupTitle)}
                  key={route}
                  route={route}
                  title={title}
                />
              );
            }

            if ('onClick' in item) {
              const { onClick, title } = item as MenuButtonData;

              return (
                <SubItem
                  onClick={() => {
                    onClick();
                    openMenuDropdownItem(groupTitle);
                  }}
                  key={title}
                  title={title}
                />
              );
            }

            return null;
          })}
        </div>
      )}
    </div>
  );
};
