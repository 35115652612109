import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { PartnerApplicationData } from '../../queries/generated/PartnerApplicationData';
import { usePartnerApplicationModal } from '../../hooks/usePartnerApplicationModal';
import { useGetPartnerApplicationsColumns } from './hooks/useGetPartnerApplicationsColumns';

interface Props {
  partnerApplications?: Array<PartnerApplicationData>;
}

export const PartnerApplicationsTable: FC<Props> = ({
  partnerApplications,
}) => {
  const { columns } = useGetPartnerApplicationsColumns();
  const openModal = usePartnerApplicationModal();

  return (
    <BlockTable
      onClickOnRow={(partnerApplication, initiatorId) =>
        openModal({
          partnerApplication,
          initiatorId,
        })
      }
      columns={columns}
      data={partnerApplications}
      isFullScreenTable
    />
  );
};
