import { FormikErrors } from 'formik';

export interface EditPartnerCompanyAffiliatesApplicationValues {
  name: string;
  note: string;
}

export interface EditPartnerCompanyAffiliatesApplicationErrors {
  name?: string;
}

export const validate = (
  values: EditPartnerCompanyAffiliatesApplicationValues
): FormikErrors<EditPartnerCompanyAffiliatesApplicationErrors> => {
  const errors: FormikErrors<EditPartnerCompanyAffiliatesApplicationErrors> =
    {};

  if (!values.name) {
    errors.name = 'Поле не может быть пустым';
  }

  return errors;
};
