import { FormikErrors } from 'formik';
import { AcceptPaymentInput } from 'generatedGraphql';

export const validate = (
  values: AcceptPaymentInput
): FormikErrors<AcceptPaymentInput> => {
  const errors: FormikErrors<AcceptPaymentInput> = {};

  if (!values.amount) {
    errors.amount = 'Введите фактическую сумму';
  }

  return errors;
};
