import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { TransferData } from '../../../../queries/generated/TransferData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetTransferVariables = SchemaTypes.Exact<{
  currentPlayerId: SchemaTypes.Scalars['ID']['input'];
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetTransfer = { __typename: 'Query', moneyTransfer: { __typename: 'MoneyTransfer', createdAt: string, id: string, type: SchemaTypes.MoneyTransferType, counterparty: { __typename: 'Player', email?: string | null, id: string, moneyTransferAccount?: string | null, name: string, phone?: string | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, wager?: { __typename: 'Wager', id: string, status: SchemaTypes.WagerStatus, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, wonSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null } };


export const GetTransferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTransfer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentPlayerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moneyTransfer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currentPlayerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentPlayerId"}}},{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TransferData"}}]}}]}},...TransferData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetTransfer__
 *
 * To run a query within a React component, call `useGetTransfer` and pass it any options that fit your needs.
 * When your component renders, `useGetTransfer` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransfer({
 *   variables: {
 *      currentPlayerId: // value for 'currentPlayerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransfer(baseOptions: ApolloReactHooks.QueryHookOptions<GetTransfer, GetTransferVariables> & ({ variables: GetTransferVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTransfer, GetTransferVariables>(GetTransferDocument, options);
      }
export function useGetTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTransfer, GetTransferVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTransfer, GetTransferVariables>(GetTransferDocument, options);
        }
export function useGetTransferSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetTransfer, GetTransferVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetTransfer, GetTransferVariables>(GetTransferDocument, options);
        }
export type GetTransferHookResult = ReturnType<typeof useGetTransfer>;
export type GetTransferLazyQueryHookResult = ReturnType<typeof useGetTransferLazyQuery>;
export type GetTransferSuspenseQueryHookResult = ReturnType<typeof useGetTransferSuspenseQuery>;
export type GetTransferQueryResult = Apollo.QueryResult<GetTransfer, GetTransferVariables>;