import { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { privateDefaultPath } from './routes.const';
import { useAuth } from '../components/Auth';
import Layout from '../components/Layout';
import { AuthStatuses } from '../components/Auth/const';
import { Storage } from '../utils/storage';
import { AuthWrapper } from './AuthWrapper';
import { useRoutes } from './routes';

const AppRouter: FC = () => {
  const auth = useAuth();
  const { authStatus } = auth.user;
  const [isLoading, setIsLoading] = useState(false);

  const { privateRoutes, publicRoutes } = useRoutes();

  const { authStorage } = Storage;

  useEffect(() => {
    const loggedIn = authStorage.getLoggedInKey();

    if (loggedIn && authStatus !== AuthStatuses.Authenticated) {
      setIsLoading(true);

      // eslint-disable-next-line promise/catch-or-return
      auth
        .refreshToken()
        .catch(() => auth.logOut())
        .finally(() => setIsLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={privateDefaultPath} replace />}
        />
        {publicRoutes.map(({ path, ...rest }) => (
          <Route key={path} path={path} {...rest} />
        ))}
        {privateRoutes.map(
          ({ path, element, privileges, accessType, ...rest }) => (
            <Route
              key={path}
              path={path}
              element={
                <AuthWrapper privileges={privileges} accessType={accessType}>
                  {element}
                </AuthWrapper>
              }
              {...rest}
            />
          )
        )}
      </Routes>
    </Layout>
  );
};

export default AppRouter;
