import { OwnerTypeName } from 'commonTypes';
import { QualifiedReferralConfirmationSettingData } from '../../../queries/generated/QualifiedReferralConfirmationSettingData';

export const getAffiliateNameTitle = (
  affiliate: QualifiedReferralConfirmationSettingData['affiliate']
): string => {
  if (affiliate.__typename === OwnerTypeName.Company) {
    return 'Название компании';
  }

  return 'Имя пользователя';
};
