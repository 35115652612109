import { useState } from 'react';
import { LocalFile } from '../types';
import { LoadingFileStatus } from '../const';
import { useUploadFile } from '../../../queries/generated/UploadFile';

interface Params {
  updateLocalFilesItem: (id: string, dataToUpdate: Partial<LocalFile>) => void;
  abortControllersMap: Map<string, () => void>;
}

export const useUploadFileToServer = ({
  updateLocalFilesItem,
  abortControllersMap,
}: Params) => {
  const [loadingFilesCount, setLoadingFilesCount] = useState<number>(0);

  const increaseLoadingCount = () => setLoadingFilesCount((prev) => prev + 1);
  const decreaseLoadingCount = () => setLoadingFilesCount((prev) => prev - 1);

  // We cannot use the standard Apollo loader because it does not support request abort
  const isLoading = loadingFilesCount > 0;

  const [uploadFile] = useUploadFile();

  const uploadFileToServer = async (item: LocalFile) => {
    try {
      updateLocalFilesItem(item.id, {
        status: LoadingFileStatus.Loading,
      });

      increaseLoadingCount();
      const { errors } = await uploadFile({
        variables: {
          input: {
            file: item.file,
          },
        },
        context: {
          fetchOptions: {
            useUpload: true,
            onProgress: (e: ProgressEvent) => {
              updateLocalFilesItem(item.id, {
                uploadedBites: e.loaded,
              });
            },
            onAbortPossible: (abortHandler: () => undefined) => {
              abortControllersMap.set(item.id, () => {
                decreaseLoadingCount();
                abortHandler();
                abortControllersMap.delete(item.id);
              });
            },
            onComplete: () => {
              abortControllersMap.delete(item.id);
            },
          },
        },
      });

      if (errors?.length) {
        throw new Error('Error while uploading file');
      }

      decreaseLoadingCount();
      updateLocalFilesItem(item.id, {
        status: LoadingFileStatus.Uploaded,
      });
    } catch (error) {
      decreaseLoadingCount();
      updateLocalFilesItem(item.id, {
        status: LoadingFileStatus.Error,
      });
    }
  };

  return { uploadFileToServer, uploadFileLoading: isLoading };
};
