import { FC, useContext } from 'react';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'src/utils/storage';
import { PLAYERS_SEARCH_INPUT, PLAYERS_SEARCH_PLACEHOLDER } from 'src/const';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetPlayers } from './hooks';
import { SearchContextProvider } from '../../contexts/SearchContext';
import { PlayersListTable } from './components/PlayersListTable';

const Component: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const { playersList, handleSearchSubmit, refetchPlayersList } =
    useGetPlayers();

  return (
    <>
      <SearchInput
        name={PLAYERS_SEARCH_INPUT}
        placeholder={PLAYERS_SEARCH_PLACEHOLDER}
        onSubmit={handleSearchSubmit}
      />
      <Block
        title="Игроки"
        id="playersList"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetchPlayersList()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Игроки не найдены"
        isEmpty={isListEmpty(playersList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(playersList) && <PlayersListTable playersList={playersList} />}
      </Block>
    </>
  );
});

export const PlayersList = () => (
  <SearchContextProvider pageType={PageTypeStorage.PlayerPage}>
    <Component />
  </SearchContextProvider>
);
