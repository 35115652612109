import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import { MessageData } from '../components/MessagesTable/queries/generated/MessageData';
import MessageItem from '../components/MessageItem';

interface MessageOpenModelType<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useMessageOpenModal = <T extends object>(
  isFullScreenTable: boolean
): MessageOpenModelType<T> => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    (message: T, initiatorId: string) => {
      const { id } = message as MessageData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Сообщение',
          content: (
            <MessageItem messageId={id} isFullScreenTable={isFullScreenTable} />
          ),
          iconConfig: {
            icon: faEnvelope,
            iconColor: CardIconColor.BLUE_GREY,
          },
          initiatorId,
        },
      });
    },
    [dispatch, isFullScreenTable]
  );

  return {
    openModal,
  };
};
