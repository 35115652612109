import { useCallback, useEffect } from 'react';
import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useGetAccountEventGroupsLazyQuery } from '../queries/generated/GetAccountEventGroups';
import { useGetAccountEventGroupsBlockLazyQuery } from '../queries/generated/GetAccountEventGroupsBlock';

const COUNT_PER_PAGE = 10;

interface UseEventGroupsParams {
  isFullScreenTable: boolean;
  currentAccountId?: string;
}
export const useEventGroups = ({
  currentAccountId,
  isFullScreenTable,
}: UseEventGroupsParams) => {
  const currentUseGetAccountEventGroups = isFullScreenTable
    ? useGetAccountEventGroupsLazyQuery
    : useGetAccountEventGroupsBlockLazyQuery;

  const [loadAccountEvents, { data, loading, error, fetchMore, refetch }] =
    currentUseGetAccountEventGroups();

  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const eventGroupsData = data?.account.eventGroups;

  const evensGroupsFetchMore = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: countPerPage,
            after: eventGroupsData?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [countPerPage, eventGroupsData?.pageInfo.endCursor, fetchMore]
  );

  useEffect(() => {
    if (!currentAccountId) {
      return;
    }

    loadAccountEvents({
      variables: {
        first: countPerPage,
        accountId: currentAccountId,
      },
    });
  }, [countPerPage, currentAccountId, loadAccountEvents]);

  return {
    eventGroupsData,
    eventGroupsLoading: loading,
    eventGroupsError: error,
    eventGroupsRefetch: refetch,
    evensGroupsFetchMore,
  };
};
