import React, { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import {
  GetWithdrawal,
  useButtonsUpdateWithdrawal,
} from './useButtonsUpdateWithdrawal';
import styles from './ButtonsUpdateWithdrawal.module.scss';

interface Props {
  withdrawal: GetWithdrawal;
}

const ButtonsUpdateWithdrawal: FC<Props> = ({ withdrawal }) => {
  const {
    onAssignWithdrawal,
    onDeclineWithdrawal,
    onSuspendWithdrawal,
    isAssignButton,
    isDeclineButton,
    isSuspendButton,
    hasUnfinalizedPayments,
    loadingSuspendWithdrawal,
    loadingAssignWithdrawal,
    loadingDeclineWithdrawal,
  } = useButtonsUpdateWithdrawal(withdrawal);

  return (
    <div className={styles.btnContainer}>
      {hasUnfinalizedPayments && 'Имеются платежи без\u00a0финального статуса'}
      {isAssignButton && (
        <Button
          className={styles.btn}
          onClick={() => onAssignWithdrawal({ id: withdrawal.id })}
          type="submit"
          isLoading={loadingAssignWithdrawal}
          theme={ButtonTheme.Success}
        >
          Взять в&nbsp;работу
        </Button>
      )}
      {isSuspendButton && !hasUnfinalizedPayments && (
        <Button
          className={styles.btn}
          onClick={() => onSuspendWithdrawal({ id: withdrawal.id })}
          type="submit"
          isLoading={loadingSuspendWithdrawal}
          theme={ButtonTheme.Success}
          disabled={loadingDeclineWithdrawal}
        >
          В&nbsp;ожидание
        </Button>
      )}
      {isDeclineButton && !hasUnfinalizedPayments && (
        <Button
          className={styles.btn}
          onClick={() => onDeclineWithdrawal({ id: withdrawal.id })}
          type="submit"
          isLoading={loadingDeclineWithdrawal}
          theme={ButtonTheme.Important}
          disabled={loadingSuspendWithdrawal}
        >
          Отклонить
        </Button>
      )}
    </div>
  );
};

export default ButtonsUpdateWithdrawal;
