import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { CompanyOutgoingMoneyTransferData } from '../../../OutgoingMoneyTransfersTable/queries/generated/CompanyOutgoingMoneyTransferData';
import { CompanyOutgoingMoneyTransferBasicData } from '../../../OutgoingMoneyTransfersTable/queries/generated/CompanyOutgoingMoneyTransferBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyOutgoingMoneyTransferVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  outgoingMoneyTransferId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyOutgoingMoneyTransfer = { __typename: 'Query', company: { __typename: 'Company', id: string, outgoingMoneyTransfer: { __typename: 'AffiliateOutgoingMoneyTransfer', createdAt: string, id: string, player: { __typename: 'Player', email?: string | null, id: string, moneyTransferAccount?: string | null, name: string, phone?: string | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const GetCompanyOutgoingMoneyTransferDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyOutgoingMoneyTransfer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"outgoingMoneyTransferId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"outgoingMoneyTransfer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"outgoingMoneyTransferId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyOutgoingMoneyTransferData"}}]}}]}}]}},...CompanyOutgoingMoneyTransferData.definitions,...CompanyOutgoingMoneyTransferBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetCompanyOutgoingMoneyTransfer__
 *
 * To run a query within a React component, call `useGetCompanyOutgoingMoneyTransfer` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOutgoingMoneyTransfer` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOutgoingMoneyTransfer({
 *   variables: {
 *      id: // value for 'id'
 *      outgoingMoneyTransferId: // value for 'outgoingMoneyTransferId'
 *   },
 * });
 */
export function useGetCompanyOutgoingMoneyTransfer(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables> & ({ variables: GetCompanyOutgoingMoneyTransferVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables>(GetCompanyOutgoingMoneyTransferDocument, options);
      }
export function useGetCompanyOutgoingMoneyTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables>(GetCompanyOutgoingMoneyTransferDocument, options);
        }
export function useGetCompanyOutgoingMoneyTransferSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables>(GetCompanyOutgoingMoneyTransferDocument, options);
        }
export type GetCompanyOutgoingMoneyTransferHookResult = ReturnType<typeof useGetCompanyOutgoingMoneyTransfer>;
export type GetCompanyOutgoingMoneyTransferLazyQueryHookResult = ReturnType<typeof useGetCompanyOutgoingMoneyTransferLazyQuery>;
export type GetCompanyOutgoingMoneyTransferSuspenseQueryHookResult = ReturnType<typeof useGetCompanyOutgoingMoneyTransferSuspenseQuery>;
export type GetCompanyOutgoingMoneyTransferQueryResult = Apollo.QueryResult<GetCompanyOutgoingMoneyTransfer, GetCompanyOutgoingMoneyTransferVariables>;