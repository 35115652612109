import React, { FC, useContext, useEffect, useMemo } from 'react';
import { ListAllOperations } from 'generatedGraphql';
import Button, { ButtonTheme } from 'ui/Button';
import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import { useApolloClient } from '@apollo/client';
import { ModalActions, ModalContext } from 'ui/Modal';
import { Currency } from 'commonTypes';
import Error from 'ui/Error';
import { toast } from 'react-toastify';
import { CheckboxSize } from 'ui/Checkbox';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import FormWarning from 'ui/FormWarning/FormWarning';
import { CurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { useAddPlayerNameToSidebarSubTitle } from 'src/utils/hooks/useAddPlayerNameToSidebarSubTitle';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { PlayerData } from 'src/queries/generated/PlayerData';
import styles from './CreateBonusForm.module.scss';
import { CreateBonusValues, validate } from './validation';
import { useCreateGiftBonus } from './queries/generated/CreateGiftBonus';
import { useGetActiveAccountCurrency } from '../../../FreespinsBlock/components/CreateFreespinForm/queries/generated/GetActiveAccountCurrency';

interface Props {
  playerId: string;
  isApprovalRequired?: boolean;
  formValues?: Partial<CreateBonusValues>;
  isFullScreenTable: boolean;
}

const CreateBonusForm: FC<Props> = ({
  playerId,
  isApprovalRequired,
  formValues,
  isFullScreenTable,
}) => {
  const client = useApolloClient();
  const { dispatch } = useContext(ModalContext);
  const player = client.readFragment({
    id: `Player:${playerId}`,
    fragment: PlayerData,
  });

  useAddPlayerNameToSidebarSubTitle(playerId);

  const { data } = useGetActiveAccountCurrency({
    variables: {
      id: playerId,
    },
  });

  const [createBonus, { data: createResult, loading, error }] =
    useCreateGiftBonus({
      refetchQueries: [
        isFullScreenTable
          ? ListAllOperations.Query.GetBonuses
          : ListAllOperations.Query.GetBonusesBlock,
      ],
    });

  useEffect(() => {
    if (createResult) {
      dispatch({ type: ModalActions.Close });

      toast.success('Бонус начислен');
    }
  }, [dispatch, createResult]);

  const initialValues: CreateBonusValues = useMemo(
    () => ({
      player: player && `#${player.id} ${player.name}`,
      amount: 0,
      currency: data?.player.activeAccountCurrency as Currency,
      approved: false,
      cashout: 10,
      wager: 10,
      ...formValues,
    }),
    [data?.player.activeAccountCurrency, formValues, player]
  );

  const onSubmit = (values: CreateBonusValues) =>
    createBonus({
      variables: {
        input: {
          bonusConfig: {
            currency: values.currency,
            ...(values.cashout ? { cashout: values.cashout } : null),
            ...(values.wager ? { wager: values.wager } : null),
            ...(values.minAccountBalanceAmount
              ? { minAccountBalanceAmount: values.minAccountBalanceAmount }
              : null),
          },
          playerId,
          amount: values.amount,
          ...(isApprovalRequired ? { approved: values.approved } : null),
        },
      },
    });

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      {({ values }) => (
        <Form>
          {error && <Error error={error} />}

          <FormRowsWrapper>
            {isApprovalRequired && (
              <FormWarning
                subTitle="Бонус необходимо согласовать"
                checkbox={{
                  name: 'approved',
                  label: 'Подарок согласован',
                  wrapperClassName: styles.approveCheckboxWrapper,
                  checkboxSize: CheckboxSize.Small,
                }}
              >
                <p>
                  У&nbsp;пользователя есть ограничение на&nbsp;получение
                  бонусов. Согласуйте бонус.
                </p>
              </FormWarning>
            )}

            <FormRow isWide>
              <Input name="player" disabled label="Игрок" />
            </FormRow>

            <FormRow>
              <Input.Number
                name="amount"
                label="Сумма"
                placeholder="Введите значение"
              />
              <CurrencyDropdown name="currency" label="Валюта" />
            </FormRow>
            <FormRow>
              <Input.Number
                invalidChars={INVALID_INTEGER_CHARS}
                name="wager"
                label="Вейджер"
                placeholder="Введите значение"
              />
              <Input.Number
                invalidChars={INVALID_INTEGER_CHARS}
                name="cashout"
                label="Кэшаут"
                placeholder="Введите значение"
              />
            </FormRow>

            <FormRow>
              <Input.Number
                name="minAccountBalanceAmount"
                label="Минимальный баланс"
                placeholder="Введите значение"
              />
            </FormRow>
          </FormRowsWrapper>

          <Button
            theme={ButtonTheme.Success}
            type="submit"
            isLoading={loading}
            disabled={isApprovalRequired && !values.approved}
          >
            Начислить
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBonusForm;
