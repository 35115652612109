import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { UpdateClientIdentitiesPayload } from 'commonComponents/UpdateClientIdentities/types';
import { useGetPlayerAdditionalData } from '../../queries/generated/GetPlayerAdditionalData';
import { useUpdatePlayerIdentities as useUpdatePlayerIdentitiesMutation } from './queries/generated/UpdatePlayerIdentities';

interface Args {
  id: string;
}

interface EditPlayer {
  onUpdatePlayerIdentities: (values: UpdateClientIdentitiesPayload) => void;
  loading: boolean;
  error?: ApolloError;
  email?: string | null;
  phone?: string | null;
}

export const useUpdatePlayerIdentities = ({ id }: Args): EditPlayer => {
  const { dispatch } = useContext(ModalContext);

  const {
    data,
    loading,
    error: getPlayerError,
  } = useGetPlayerAdditionalData({
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  });

  useModalManageData({
    loading,
    errorConfig: {
      error: getPlayerError,
      toastId: 'get-player-additional-data',
    },
    hasData: !!data,
  });

  const [
    updatePlayerIdentities,
    { data: updateResult, error, loading: updateLoading },
  ] = useUpdatePlayerIdentitiesMutation();
  const onUpdatePlayerIdentities = async ({
    email,
    phone,
  }: UpdateClientIdentitiesPayload) => {
    const newEmail = email || null;
    const newPhone = phone || null;

    await updatePlayerIdentities({
      variables: { input: { id, email: newEmail, phone: newPhone } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });
      toast.success('Игрок изменён');
    }
  }, [updateResult, dispatch]);

  return {
    onUpdatePlayerIdentities,
    loading: updateLoading || loading,
    error,
    email: data?.player.email,
    phone: data?.player.phone,
  };
};
