export enum ColumnId {
  Id = 'id',
  EventType = '__typename',
  Sum = 'sum',
  Balance = 'balance',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.EventType]: 'Тип события',
  [ColumnId.Sum]: 'Фактическая сумма события',
  [ColumnId.Balance]: 'Баланс',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const blockColumnsIds = [
  ColumnId.EventType,
  ColumnId.Sum,
  ColumnId.Balance,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.EventType,
  ColumnId.Sum,
  ColumnId.Balance,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
