import { nbsp } from '../../../const';

interface GetUnBanTitleParams {
  date: string | null;
  isProtected: boolean;
}

export const getUnBanTitle = ({ date, isProtected }: GetUnBanTitleParams) => {
  if (date) {
    return `Доступ в${nbsp}аккаунт заблокирован до${nbsp} ${date}`;
  }

  if (isProtected) {
    return `Доступ в${nbsp}аккаунт заблокирован без${nbsp}права на${nbsp}восстановление`;
  }

  return `Доступ в${nbsp}аккаунт заблокирован пока не разблокируют`;
};
