import { ApolloError } from '@apollo/client';
import { SET_ERROR } from '../actionTypes';
import type { ErrorType } from '../types';

interface Payload {
  data: ApolloError | null;
  type: ErrorType | null;
}

export interface SetError {
  type: typeof SET_ERROR;
  payload: Payload;
}

export const setErrorAction = (payload: Payload): SetError => ({
  type: SET_ERROR,
  payload,
});
