import { Storage } from '../../../utils/storage';
import * as types from './actionTypes';

import { Action, State } from './types';

export const reducer = (currentState: State, action: Action): State => {
  switch (action.type) {
    case types.SET_INITIAL_DATA:
      return {
        ...currentState,
        blockId: action.payload.blockId,
        isPermanentOpened: action.payload.isPermanentOpened,
      };

    case types.TOGGLE_BLOCK:
      if (currentState.blockId && !currentState.isPermanentOpened) {
        Storage.blocksStateStorage.setOpenedBlocks({
          [currentState.blockId]: !currentState.isOpened,
        });
      }

      return {
        ...currentState,
        isOpened: !currentState.isOpened,
      };

    case types.SET_LOAD_OLD:
      return {
        ...currentState,
        loadOld: {
          hasOld: action.payload.hasOld,
          onLoadOld: action.payload.onLoadOld,
        },
      };

    case types.SET_LOAD_NEW:
      return {
        ...currentState,
        loadNew: {
          hasNew: action.payload.hasNew,
          onLoadNew: action.payload.onLoadNew,
        },
      };

    case types.SET_REFETCH:
      return {
        ...currentState,
        refetch: action.payload.refetch,
      };

    case types.SET_LOADING:
      return {
        ...currentState,
        isLoading: action.payload.loading,
      };

    case types.SET_ERROR:
      return {
        ...currentState,
        error: {
          data: action.payload.data,
          type: action.payload.type,
        },
      };

    case types.RESET_ERROR:
      return {
        ...currentState,
        error: {
          data: null,
          type: null,
        },
      };

    case types.SET_LOADER_VISIBILITY:
      return {
        ...currentState,
        isLoaderVisible: action.payload.isLoaderVisible,
      };

    default:
      return currentState;
  }
};
