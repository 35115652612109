import { FC } from 'react';
import { useParams } from 'react-router';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { OutgoingMoneyTransfersTable } from '../../components/OutgoingMoneyTransfersBlock/components/OutgoingMoneyTransfersTable';
import { useGetOutgoingMoneyTransfersColumns } from '../../components/OutgoingMoneyTransfersBlock/components/OutgoingMoneyTransfersTable/hooks/useGetOutgoingMoneyTransfersColumns';

export const OutgoingMoneyTransfers: FC = () => {
  const { columns } = useGetOutgoingMoneyTransfersColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <OutgoingMoneyTransfersTable
        companyId={id}
        columns={columns}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </CompanyPageContainer>
  );
};
