import { FC } from 'react';
import { useParams } from 'react-router';
import FreespinsTable from '../../components/FreespinsBlock/components/FreespinsTable';
import { useGetFreespinsColumns } from '../../components/FreespinsBlock/hooks/useGetFreespinsColumns';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const Freespins: FC = () => {
  const { columns } = useGetFreespinsColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <FreespinsTable
        playerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PlayerPageContainer>
  );
};
