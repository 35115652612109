import React, { FC } from 'react';
import ResetClientTwoFactor from 'commonComponents/ResetClientTwoFactor';
import { useResetCompanyTwoFactor } from './hooks/useResetCompanyTwoFactor';

interface Props {
  companyId: string;
  twoFactorEnabled?: boolean;
}

export const ResetCompanyTwoFactor: FC<Props> = ({
  companyId,
  twoFactorEnabled,
}) => {
  const { loading, resetCompanyTwoFactorHandler, error } =
    useResetCompanyTwoFactor({
      companyId,
    });

  return (
    <ResetClientTwoFactor
      onResetClientTwoFactor={resetCompanyTwoFactorHandler}
      twoFactorEnabled={twoFactorEnabled}
      isLoading={loading}
      error={error}
    />
  );
};
