import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import Button, { ButtonTheme } from 'ui/Button';
import { Currency } from 'commonTypes';
import { AcceptPaymentInput } from 'generatedGraphql';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { Tooltip } from 'ui/Tooltip';
import styles from './AcceptPaymentForm.module.scss';
import { validate } from './validation';
import { useOnAcceptPayment } from './useAcceptPayment';

const INPUT_SUM_NAME = 'amount';

interface Props {
  id: string;
  paidSum: number;
  paidCurrency: Currency;
}

const AcceptPaymentForm: FC<Props> = ({ id, paidSum, paidCurrency }) => {
  const { onAcceptPayment, error, loading } = useOnAcceptPayment();
  const initialValues: AcceptPaymentInput = {
    id,
    amount: paidSum,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onAcceptPayment}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty, getFieldProps }) => {
        const isFieldEmpty = !getFieldProps(INPUT_SUM_NAME).value;
        const isSubmitDisabled = !isValid || (isFieldEmpty && !dirty);

        return (
          <Form>
            {error && <Error error={error} />}
            <FormRowsWrapper>
              <div className={styles.inputContainer}>
                <Input.Sum
                  name={INPUT_SUM_NAME}
                  label="Фактическая сумма"
                  currency={paidCurrency}
                />
              </div>
            </FormRowsWrapper>
            <Tooltip
              tooltipContent={
                isSubmitDisabled
                  ? 'Необходимо ввести фактическую сумму'
                  : undefined
              }
              placement="right"
            >
              <Button
                className={styles.submitButton}
                theme={ButtonTheme.Success}
                type="submit"
                isLoading={loading}
                disabled={isSubmitDisabled}
              >
                Выполнить
              </Button>
            </Tooltip>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AcceptPaymentForm;
