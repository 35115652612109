import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { useApprovePartnerPlayerAccount } from '../queries/generated/ApprovePartnerPlayerAccount';
import { useDeclinePartnerPlayerAccount } from '../queries/generated/DeclinePartnerPlayerAccount';

export const useManagePlayerAccount = (partnerId: string) => {
  const { state, dispatch } = useContext(ModalContext);

  const [approvePlayerAccount, { loading: approvePlayerAccountLoading }] =
    useApprovePartnerPlayerAccount({
      variables: {
        input: {
          partnerId,
        },
      },
      onCompleted: () => {
        toast.success('Игровой аккаунт подтверждён');
      },
      onError: (error) => {
        errorToast({
          header: `При${nbsp}подтверждении игрового аккаунта произошла ошибка`,
          text: error.message,
          toastId: 'approve-player-account-error',
        });
      },
    });

  const [
    declinePlayerAccount,
    { loading: declinePlayerAccountLoading, error: declinePlayerAccountError },
  ] = useDeclinePartnerPlayerAccount({
    variables: {
      input: {
        partnerId,
      },
    },
    onCompleted: () => {
      if (state.isOpened) {
        dispatch({
          type: ModalActions.Close,
        });
      }

      toast.error('Игровой аккаунт отклонён');
    },
    onError: (error) => {
      if (!state.isOpened) {
        errorToast({
          header: `При${nbsp}отклонении игрового аккаунта произошла ошибка`,
          text: error.message,
          toastId: 'decline-player-account-error',
        });
      }
    },
  });

  return {
    approvePlayerAccount,
    declinePlayerAccount,
    approvePlayerAccountLoading,
    declinePlayerAccountLoading,
    declinePlayerAccountError,
  };
};
