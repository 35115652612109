import { FormikHelpers } from 'formik';
import { DROPDOWN_SEARCH } from 'ui/Dropdown/components/DropdownList/const';
import { logError } from 'src/utils/logError';
import { FieldName } from '../const';
import { CreateConfirmationSettingValues } from '../types';

interface HandleChangeCompanyDropdownParams {
  setFieldValue: FormikHelpers<CreateConfirmationSettingValues>['setFieldValue'];
  companyId: string | Array<string>;
  checkHasCompanyConfirmationSettings: (companyId: string) => void;
}

export const handleChangeCompanyDropdown = ({
  setFieldValue,
  companyId,
  checkHasCompanyConfirmationSettings,
}: HandleChangeCompanyDropdownParams) => {
  if (typeof companyId === 'string') {
    checkHasCompanyConfirmationSettings(companyId);

    // This is necessary to clear the search field in the dropdown list
    setFieldValue(`${FieldName.companyReferralCode}_${DROPDOWN_SEARCH}`, '');
  } else {
    logError(`companyId should be a string but it's an ${typeof companyId}`);
  }
};
