import { FC } from 'react';
import { ClientDataBlock } from 'commonComponents/ClientDataBlock/ClientDataBlock';
import { withBlockProvider } from 'ui/Block/store';
import { usePartnerData } from './hooks/usePartnerData';

interface Props {
  id: string;
}

export const PartnerDataBlock: FC<Props> = withBlockProvider(({ id }) => {
  const { refetchPartnerData, clientData } = usePartnerData({ id });

  return (
    <ClientDataBlock
      blockId="partnerData"
      title="Данные партнера"
      onUpdate={refetchPartnerData}
      data={clientData}
    />
  );
});
