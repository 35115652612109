import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import {
  BonusBalance,
  BonusBalanceIconSize,
} from 'commonComponents/BonusBalance';
import { localeCurrency } from 'src/utils/localeCurrency';
import { AccountData } from '../../queries/fragments/generated/AccountData';

interface AccountsTabsParams {
  accounts: Array<AccountData>;
  currentTabId?: string;
  setCurrentTabId: (id: string) => void;
}

export const AccountsTabs: FC<AccountsTabsParams> = ({
  accounts,
  currentTabId,
  setCurrentTabId,
}) => {
  const tabs = accounts?.map((account) => {
    const balanceFormatted = localeCurrency({
      amount: account.balance.amount,
      currency: account.balance.currency,
      allOptions: {
        maximumFractionDigits: 2,
      },
    });

    const bonusBalance = account?.bonusBalance;

    return {
      id: account.id,
      name: (
        <div>
          {balanceFormatted}
          {bonusBalance && (
            <>
              {' + '}
              <BonusBalance
                iconSize={BonusBalanceIconSize.Big}
                amount={bonusBalance.amount}
                setDefaultIconColor={false}
              />
            </>
          )}
        </div>
      ),
    };
  });

  const handleTabChange = (id: string) => {
    setCurrentTabId(id);
  };

  if (!tabs?.length) {
    return null;
  }

  return (
    <Tabs
      tabs={tabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
