import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { RewardStatus, SuspectState } from 'generatedGraphql';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { Currency } from 'commonTypes';
import { AcceptRewardValues } from '../types';
import { useAcceptReward as useAcceptRewardMutation } from '../queries/generated/AcceptReward';

export const useAcceptReward = (rewardId: string, currency: Currency) => {
  const [acceptReward, { loading }] = useAcceptRewardMutation();
  const { dispatch } = useContext(ModalContext);

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Вознаграждение не подтверждено',
      text: error.message,
      toastId: 'accept-reward-error',
    });

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleSubmit = ({ amount }: AcceptRewardValues) => {
    acceptReward({
      variables: {
        input: {
          id: rewardId,
          amount,
        },
      },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        acceptReward: {
          __typename: 'AcceptRewardPayload',
          reward: {
            id: rewardId,
            status: RewardStatus.Accepted,
            suspectState: SuspectState.NotEligible,
            paidSum: { amount, currency, __typename: 'Sum' },
            updatedAt: new Date().toISOString(),
            __typename: 'Reward',
          },
        },
      },
      onError: handleError,
    });

    undoActionToast('Вознаграждение подтверждено');
    dispatch({
      type: ModalActions.Close,
    });
  };

  return {
    handleSubmit,
    loading,
  };
};
