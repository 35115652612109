import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { useGetPlayerExternalMoneyTransfer } from './queries/generated/GetPlayerExternalMoneyTransfer';

interface Props {
  externalMoneyTransferId: string;
  playerId: string;
}

export const ExternalMoneyTransferItem: FC<Props> = ({
  externalMoneyTransferId,
  playerId,
}) => {
  const { data, loading, error } = useGetPlayerExternalMoneyTransfer({
    variables: {
      id: playerId,
      externalMoneyTransferId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-player-external-transfer-error',
    },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    createdAt,
    affiliate,
    sum: { amount, currency },
  } = data.player.externalMoneyTransfer;

  const email =
    affiliate.__typename === 'Company'
      ? affiliate.companyEmail
      : affiliate.partnerEmail;

  return (
    <DataCard
      rows={[
        {
          title: 'Сумма',
          value: formatSumWithCurrency(amount, currency),
        },
        {
          title: 'Отправитель',
          value: affiliate.name,
        },
        {
          title: 'Почта отправителя',
          value: email,
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
    />
  );
};
