import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateReferralLinkMediaItemNameVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateReferralLinkMediaItemNameInput;
}>;


export type UpdateReferralLinkMediaItemName = { __typename: 'Mutation', updateReferralLinkMediaItemName: { __typename: 'UpdateReferralLinkMediaItemNamePayload', referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string } } };


export const UpdateReferralLinkMediaItemNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateReferralLinkMediaItemName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateReferralLinkMediaItemNameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateReferralLinkMediaItemName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateReferralLinkMediaItemNameMutationFn = Apollo.MutationFunction<UpdateReferralLinkMediaItemName, UpdateReferralLinkMediaItemNameVariables>;

/**
 * __useUpdateReferralLinkMediaItemName__
 *
 * To run a mutation, you first call `useUpdateReferralLinkMediaItemName` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralLinkMediaItemName` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralLinkMediaItemName, { data, loading, error }] = useUpdateReferralLinkMediaItemName({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralLinkMediaItemName(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferralLinkMediaItemName, UpdateReferralLinkMediaItemNameVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateReferralLinkMediaItemName, UpdateReferralLinkMediaItemNameVariables>(UpdateReferralLinkMediaItemNameDocument, options);
      }
export type UpdateReferralLinkMediaItemNameHookResult = ReturnType<typeof useUpdateReferralLinkMediaItemName>;
export type UpdateReferralLinkMediaItemNameMutationResult = Apollo.MutationResult<UpdateReferralLinkMediaItemName>;
export type UpdateReferralLinkMediaItemNameMutationOptions = Apollo.BaseMutationOptions<UpdateReferralLinkMediaItemName, UpdateReferralLinkMediaItemNameVariables>;