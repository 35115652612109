import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { Popup } from 'ui/Popup';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { FC } from 'react';
import { useDeleteQualifiedReferralConfirmationSetting } from './hooks/useDeleteQualifiedReferralConfirmationSetting';
import styles from './ConfirmationSettingModalButtons.module.scss';
import { useUpdateConfirmationSettingModal } from './hooks/useUpdateConfirmationSettingModal';

interface Props {
  confirmationSettingId: string;
}

export const ConfirmationSettingModalButtons: FC<Props> = ({
  confirmationSettingId,
}) => {
  const { handleDeleteConfirmationSetting, loading: deleteLoading } =
    useDeleteQualifiedReferralConfirmationSetting(confirmationSettingId);

  const { openUpdateConfirmationSettingModal } =
    useUpdateConfirmationSettingModal();

  const actionButtonsConfig = [
    {
      text: 'Подтвердить',
      theme: ButtonTheme.Primary,
      action: handleDeleteConfirmationSetting,
    },
    {
      text: 'Отменить',
      theme: ButtonTheme.Process,
    },
  ];

  return (
    <div className={styles.container}>
      <Privilege
        privileges={[UserPrivilege.UpdateQualifiedReferralConfirmationSetting]}
      >
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          isLoading={deleteLoading}
          onClick={() =>
            openUpdateConfirmationSettingModal(confirmationSettingId)
          }
        >
          Редактировать
        </Button>
      </Privilege>
      <Privilege
        privileges={[UserPrivilege.DeleteQualifiedReferralConfirmationSetting]}
      >
        <Popup
          content="Вы подтверждаете удаление настройки подтверждения?"
          actionButtonsConfig={actionButtonsConfig}
          triggerElement={
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Important}
              isLoading={deleteLoading}
            >
              Удалить
            </Button>
          }
          isActionButtonsMaxWidth
        />
      </Privilege>
    </div>
  );
};
