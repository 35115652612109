import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import styles from './UpdatePostbackDisabledNotificationButtons.module.scss';
import { useEnablePostback } from './hooks/useEnablePostback';
import { useDeletePostbackDisabledNotification } from './hooks/useDeletePostbackDisabledNotification';

interface Props {
  postbackId: string;
  postbackDisabledNotificationId: string;
}

export const UpdatePostbackDisabledNotificationButtons: FC<Props> = ({
  postbackId,
  postbackDisabledNotificationId,
}) => {
  const { enablePostback, loading: enablePostbackLoading } =
    useEnablePostback(postbackId);

  const {
    deletePostbackDisabledNotification,
    loading: deletePostbackDisabledNotificationLoading,
  } = useDeletePostbackDisabledNotification(postbackDisabledNotificationId);

  return (
    <div className={styles.btnContainer}>
      <Button
        isLoading={enablePostbackLoading}
        onClick={() => enablePostback()}
        theme={ButtonTheme.Success}
      >
        Включить
      </Button>
      <Button
        isLoading={deletePostbackDisabledNotificationLoading}
        onClick={() => deletePostbackDisabledNotification()}
        theme={ButtonTheme.Important}
      >
        Удалить
      </Button>
    </div>
  );
};
