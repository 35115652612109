import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportReferralLinkMediaItemCpaReportLazyQuery } from '../queries/generated/GetExportReferralLinkMediaItemCpaReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

interface UseGetExportReferralLinkMediaItemCpaReportParams {
  referralLinkId: string;
  isAllTimeCpa: boolean;
}

export const useGetExportReferralLinkMediaItemCpaReport = ({
  referralLinkId,
  isAllTimeCpa,
}: UseGetExportReferralLinkMediaItemCpaReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportReferralLinkMediaItemCpaReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.mediaItem.exportCpaReport);
      },
      onError: handleErrorExportReport,
    });

  const { currency } = useGetCurrency();

  const exportReportWithVariables = useCallback(() => {
    exportReport({
      variables: {
        id: referralLinkId,
        currency,
        period,
        showAllTimeCpa: isAllTimeCpa,
      },
    });
  }, [currency, exportReport, isAllTimeCpa, period, referralLinkId]);

  return {
    exportReport: exportReportWithVariables,
    exportReportLoading:
      getExportReportLinkLoading || downloadReportFileLoading,
    exportPrivilege: UserPrivilege.ExportReferralLinkMediaItemCpaReport,
  };
};
