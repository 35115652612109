import accounting from 'accounting';
import { Option } from 'ui/Dropdown';
import { Currency } from 'commonTypes';
import { currencySignMap } from 'src/const';
import getEnumKeys from './getEnumKeys';
import { nbsp } from '../const';

export const formatSumAmount = (amount: number, precision = 2): string =>
  accounting.formatNumber(amount, precision, nbsp, ',').replace(/,00$/, '');

export const formatSumWithCurrency = (
  amount: number,
  currency: Currency,
  precision = 2
): string =>
  `${formatSumAmount(amount, precision)}${nbsp}${
    currency ? currencySignMap[currency] || currency : ''
  }`;

export const currencyOptions: Array<Option> = getEnumKeys(Currency).reduce(
  (acc: Array<Option> | [], curr: keyof typeof Currency) => {
    if (Currency[curr] !== Currency.Xxx) {
      return [
        ...acc,
        {
          label: curr.toUpperCase(),
          id: Currency[curr],
        },
      ];
    }

    return acc;
  },
  []
);
