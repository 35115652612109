import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { NoteData } from '../../../../queries/generated/NoteData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdatePartnerNoteVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdatePartnerNoteInput;
}>;


export type UpdatePartnerNote = { __typename: 'Mutation', updatePartnerNote: { __typename: 'UpdatePartnerNotePayload', partner: { __typename: 'Partner', id: string, note?: { __typename: 'Note', text: string } | null } } };


export const UpdatePartnerNoteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePartnerNote"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePartnerNoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePartnerNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NoteData"}}]}}]}}]}}]}},...NoteData.definitions]} as unknown as DocumentNode;
export type UpdatePartnerNoteMutationFn = Apollo.MutationFunction<UpdatePartnerNote, UpdatePartnerNoteVariables>;

/**
 * __useUpdatePartnerNote__
 *
 * To run a mutation, you first call `useUpdatePartnerNote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerNote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerNote, { data, loading, error }] = useUpdatePartnerNote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerNote(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePartnerNote, UpdatePartnerNoteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePartnerNote, UpdatePartnerNoteVariables>(UpdatePartnerNoteDocument, options);
      }
export type UpdatePartnerNoteHookResult = ReturnType<typeof useUpdatePartnerNote>;
export type UpdatePartnerNoteMutationResult = Apollo.MutationResult<UpdatePartnerNote>;
export type UpdatePartnerNoteMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerNote, UpdatePartnerNoteVariables>;