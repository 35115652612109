import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { SumData } from '../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AcceptRewardVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AcceptRewardInput;
}>;


export type AcceptReward = { __typename: 'Mutation', acceptReward: { __typename: 'AcceptRewardPayload', reward: { __typename: 'Reward', id: string, status: SchemaTypes.RewardStatus, suspectState: SchemaTypes.SuspectState, updatedAt: string, paidSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } } };


export const AcceptRewardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptReward"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptRewardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptReward"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reward"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"paidSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"suspectState"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}},...SumData.definitions]} as unknown as DocumentNode;
export type AcceptRewardMutationFn = Apollo.MutationFunction<AcceptReward, AcceptRewardVariables>;

/**
 * __useAcceptReward__
 *
 * To run a mutation, you first call `useAcceptReward` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptReward` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptReward, { data, loading, error }] = useAcceptReward({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptReward(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptReward, AcceptRewardVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptReward, AcceptRewardVariables>(AcceptRewardDocument, options);
      }
export type AcceptRewardHookResult = ReturnType<typeof useAcceptReward>;
export type AcceptRewardMutationResult = Apollo.MutationResult<AcceptReward>;
export type AcceptRewardMutationOptions = Apollo.BaseMutationOptions<AcceptReward, AcceptRewardVariables>;