import { Dropdown } from 'ui/Dropdown';
import { FC, useState } from 'react';
import { currencyOptions } from '../../utils/accounting';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: string | Array<string>) => void;
}

export const CurrencyDropdown: FC<Props> = ({
  name,
  label,
  disabled,
  onChange,
}) => {
  const [optionsState, setOptionsState] = useState(currencyOptions);

  const onSearchInList = (e: string) => {
    const filteredOptions = currencyOptions.filter((option) => {
      if (typeof option.label === 'string') {
        return option.label.includes(e.toUpperCase());
      }

      return undefined;
    });

    setOptionsState(filteredOptions);
  };

  return (
    <Dropdown
      name={name}
      label={label}
      options={optionsState}
      onSearchInList={onSearchInList}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
