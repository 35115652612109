import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { CreateReferralLinkForm } from '../CreateReferralLinkForm';
import { PartnerBasicData } from '../../../../../../queries/generated/PartnerBasicData';

interface UseOpenCreateReferralLinkModalParams {
  partnerId: string;
  isFullScreenTable: boolean;
}

export const useOpenCreateReferralLinkModal = ({
  partnerId,
  isFullScreenTable,
}: UseOpenCreateReferralLinkModalParams) => {
  const client = useApolloClient();
  const { dispatch } = useContext(ModalContext);

  const partner = client.readFragment({
    id: `Partner:${partnerId}`,
    fragment: PartnerBasicData,
  });

  const openCreateReferralLinkModal = () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Создание реферальной ссылки',
        subTitle: `${partner?.name} #${partnerId}`,
        content: (
          <CreateReferralLinkForm
            partner={partner}
            isFullScreenTable={isFullScreenTable}
          />
        ),
      },
    });
  };

  return {
    openCreateReferralLinkModal,
  };
};
