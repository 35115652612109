import {
  faCircleCheck,
  faBan,
  faShieldCheck,
  faShieldXmark,
  faBadgePercent,
  faHourglassEmpty,
  faQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import { ReferralStatus } from 'generatedGraphql';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const getReferralStatusIcon = (
  status: ReferralStatus
): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (status) {
    case ReferralStatus.Paid:
    case ReferralStatus.Approved:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case ReferralStatus.Qualified:
      icon = faShieldCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case ReferralStatus.Hold:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
    case ReferralStatus.Canceled:
      icon = faShieldXmark;
      iconColor = CardIconColor.ORANGE;
      break;
    case ReferralStatus.Unpaid:
    case ReferralStatus.Rejected:
      icon = faBan;
      iconColor = CardIconColor.RED;
      break;
    case ReferralStatus.RevShare:
      icon = faBadgePercent;
      iconColor = CardIconColor.PINK;
      break;
    default:
      icon = faQuestion;
      iconColor = CardIconColor.GREY;
      break;
  }

  return { icon, iconColor };
};
