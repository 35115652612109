import { RouteProps } from 'react-router';
import { UserPrivilege } from '../components/Auth';
import { Route } from './routes.const';

export enum PrivilegeAccessType {
  All,
  Some,
}

export type RouteType = RouteProps & {
  path: (typeof Route)[keyof typeof Route];
  privileges?: Array<UserPrivilege>;
  accessType?: PrivilegeAccessType;
};
