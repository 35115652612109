import styles from './Table.module.scss';

export enum TableCellAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export const mapAlignToStyle: Record<TableCellAlign, string> = {
  [TableCellAlign.Left]: styles.alignLeft,
  [TableCellAlign.Right]: styles.alignRight,
  [TableCellAlign.Center]: styles.alignCenter,
};
