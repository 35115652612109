import { FormikErrors } from 'formik';
import { UpdateConfirmationSettingValues } from './types';

interface UpdatePaymentErrors {
  comment: string;
}

export const validate = (
  values: UpdateConfirmationSettingValues
): FormikErrors<UpdatePaymentErrors> => {
  const errors: FormikErrors<UpdatePaymentErrors> = {};

  if (!values.comment) {
    errors.comment = 'Поле не может быть пустым';
  }

  return errors;
};
