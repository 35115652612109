import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeclinePartnerPlayerAccountVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeclinePartnerPlayerAccountInput;
}>;


export type DeclinePartnerPlayerAccount = { __typename: 'Mutation', declinePartnerPlayerAccount: { __typename: 'DeclinePartnerPlayerAccountPayload', partner: { __typename: 'Partner', id: string, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus } | null } } };


export const DeclinePartnerPlayerAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclinePartnerPlayerAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclinePartnerPlayerAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declinePartnerPlayerAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type DeclinePartnerPlayerAccountMutationFn = Apollo.MutationFunction<DeclinePartnerPlayerAccount, DeclinePartnerPlayerAccountVariables>;

/**
 * __useDeclinePartnerPlayerAccount__
 *
 * To run a mutation, you first call `useDeclinePartnerPlayerAccount` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclinePartnerPlayerAccount` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declinePartnerPlayerAccount, { data, loading, error }] = useDeclinePartnerPlayerAccount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclinePartnerPlayerAccount(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclinePartnerPlayerAccount, DeclinePartnerPlayerAccountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeclinePartnerPlayerAccount, DeclinePartnerPlayerAccountVariables>(DeclinePartnerPlayerAccountDocument, options);
      }
export type DeclinePartnerPlayerAccountHookResult = ReturnType<typeof useDeclinePartnerPlayerAccount>;
export type DeclinePartnerPlayerAccountMutationResult = Apollo.MutationResult<DeclinePartnerPlayerAccount>;
export type DeclinePartnerPlayerAccountMutationOptions = Apollo.BaseMutationOptions<DeclinePartnerPlayerAccount, DeclinePartnerPlayerAccountVariables>;