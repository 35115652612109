import { FC } from 'react';
import { useParams } from 'react-router';
import TransfersTable from '../../components/TransfersBlock/components/TransfersTable';
import { useGetTransfersColumns } from '../../components/TransfersBlock/hooks/useGetTransfersColumns';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const Transfers: FC = () => {
  const { columns } = useGetTransfersColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <TransfersTable
        playerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PlayerPageContainer>
  );
};
