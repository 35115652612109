import { FC, useContext, useEffect } from 'react';
import { ModalActions, ModalContext, Tabs, TabsWrapper } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { AboutReferralLinkMediaItem } from '../AboutReferralLinkMediaItem';
import { EditReferralLinkMediaItem } from '../EditReferralLinkMediaItem';
import { AboutReferralLinkSteps } from '../EditReferralLinkMediaItem/const';

interface Props {
  partnerId: string;
  referralLinkId: string;
}

export const PartnerReferralLinkMediaItem: FC<Props> = ({
  partnerId,
  referralLinkId,
}) => {
  const { state, dispatch } = useContext(ModalContext);

  useEffect(() => {
    if (state.step === AboutReferralLinkSteps.EditReferralLinkStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Редактирование медиа элемента',
          subTitle: addHashTagToId(referralLinkId),
        },
      });
    } else if (state.step === AboutReferralLinkSteps.BaseStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: addHashTagToId(referralLinkId),
          subTitle: 'Медиа элемент',
        },
      });
    }
  }, [dispatch, referralLinkId, state.step]);

  if (state.step === AboutReferralLinkSteps.EditReferralLinkStep) {
    return (
      <EditReferralLinkMediaItem
        partnerId={partnerId}
        referralLinkMediaItemId={referralLinkId}
      />
    );
  }

  const tabs: Array<Tabs> = [
    {
      title: 'О медиа-элементе',
      content: (
        <AboutReferralLinkMediaItem
          partnerId={partnerId}
          referralLinkMediaItemId={referralLinkId}
        />
      ),
    },
  ];

  return <TabsWrapper tabs={tabs} />;
};
