import * as SchemaTypes from '../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { AccountEventGroupBasicData } from './AccountEventGroupBasicData';
import { BalanceChangeData } from './BalanceChangeData';
import { SumData } from '../../../../../../../queries/generated/SumData';
import { AccountEventBasicData } from './AccountEventBasicData';
export type AccountEventGroupData = { __typename: 'AccountEventGroup', updatedAt: string, createdAt: string, eventCount: number, id: string, type: CommonType.AccountEventGroupType, billingSummary: { __typename: 'BalanceChange', delta: { __typename: 'SumFlow', direction?: SchemaTypes.SumFlowDirection | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, from: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, to: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, bonusSummary?: { __typename: 'BalanceChange', delta: { __typename: 'SumFlow', direction?: SchemaTypes.SumFlowDirection | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, from: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, to: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null, events: { __typename: 'AccountEventConnection', edges: Array<{ __typename: 'AccountEventEdge', node: { __typename: 'BonusAccountEvent', id: string, bonusType: CommonType.BonusAccountEventType } | { __typename: 'DepositAccountEvent', id: string, depositType: CommonType.DepositAccountEventType } | { __typename: 'GameAccountEvent', id: string, gameType: CommonType.GameAccountEventType } | { __typename: 'JackpotAccountEvent', id: string, jackpotType: CommonType.JackpotAccountEventType } | { __typename: 'MoneyTransferAccountEvent', id: string, moneyTransferType: CommonType.MoneyTransferAccountEventType } | { __typename: 'TournamentAccountEvent', id: string, tournamentType: CommonType.TournamentAccountEventType } | { __typename: 'WithdrawalAccountEvent', id: string, withdrawalType: CommonType.WithdrawalAccountEventType } }> } };

export const AccountEventGroupData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AccountEventGroupData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AccountEventGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AccountEventGroupBasicData"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]} as unknown as DocumentNode;