import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetRevShareReferralLinksColumns } from './hooks/useGetRevShareReferralLinksColumns';
import { RevShareReferralLinkReportData } from '../../queries/generated/RevShareReferralLinkReportData';

interface Props {
  report?: Array<RevShareReferralLinkReportData>;
  hasTotalRow: boolean;
}

export const RevShareReferralLinkReportTable: FC<Props> = ({
  report,
  hasTotalRow,
}) => {
  const { columns } = useGetRevShareReferralLinksColumns();

  return (
    <BlockTable
      columns={columns}
      data={report}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
