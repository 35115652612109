import { AvailableFilter } from 'ui/TableFilter';
import { DepositFilterMember } from 'commonTypes';
import { FilterType } from 'ui/TableFilter/const';
import { paymentProviderMap, playerPaymentNameMap } from '../../../../../const';
import { getStatusesList } from './getStatusesList';
import { getDropdownListByMap } from '../../../../../utils/getDropdownListByMap';

interface GetDepositFiltersReturn {
  availableDepositFilters: Array<AvailableFilter<DepositFilterMember>>;
  allDepositFilters: Array<AvailableFilter<DepositFilterMember>>;
}

const depositFilters: Array<AvailableFilter<DepositFilterMember>> = [
  {
    title: 'Поиск ID',
    filterId: DepositFilterMember.DepositId,
    type: FilterType.Input,
  },
  {
    title: 'Статус',
    filterId: DepositFilterMember.Status,
    type: FilterType.List,
    list: getStatusesList,
  },

  {
    title: 'Метод',
    filterId: DepositFilterMember.MethodName,
    type: FilterType.List,
    list: getDropdownListByMap(playerPaymentNameMap),
  },

  {
    title: 'Провайдер',
    filterId: DepositFilterMember.Provider,
    type: FilterType.List,
    list: getDropdownListByMap(paymentProviderMap),
  },

  {
    title: 'Номер счета',
    filterId: DepositFilterMember.AccountNumber,
    type: FilterType.Input,
  },
];

export const getDepositFilters = (
  availableFilters: Array<DepositFilterMember>
): GetDepositFiltersReturn => {
  const allDepositFilters: Array<AvailableFilter<DepositFilterMember>> =
    depositFilters;

  const availableDepositFilters = depositFilters.filter(
    (item) => availableFilters.indexOf(item.filterId) !== -1
  );

  return { availableDepositFilters, allDepositFilters };
};
