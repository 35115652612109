import { FC, useContext } from 'react';
import { HeaderActionButtonsWrapper } from 'ui/Block/BlockTable/components/HeaderActionButtonsWrapper';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { ApolloQueryResult } from '@apollo/client';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAllReferralsActions } from '../../hooks/useAllReferralsActions';
import { QualifiedReferralsHeaderActionPopup } from '../QualifiedReferralsHeaderActionPopup';

interface Props {
  refetch: () => Promise<ApolloQueryResult<unknown>>;
}

export const QualifiedReferralsHeaderActions: FC<Props> = ({ refetch }) => {
  const { state } = useContext(BlockContext);

  const {
    approveAllReferrals,
    rejectAllReferrals,
    approveAllLoading,
    rejectAllLoading,
  } = useAllReferralsActions();

  return (
    <HeaderActionButtonsWrapper>
      <QualifiedReferralsHeaderActionPopup
        message="Вы подтверждаете качество всех игроков?"
        action={approveAllReferrals}
        triggerElement={
          <ActionButton
            actionType={ButtonType.CheckDouble}
            disabled={state.isLoading || approveAllLoading}
            dataTestId="approveAllReferrals"
          />
        }
        privilege={UserPrivilege.ApproveAllQualifiedReferrals}
      />
      <QualifiedReferralsHeaderActionPopup
        message="Вы отклоняете качество всех игроков?"
        action={rejectAllReferrals}
        triggerElement={
          <ActionButton
            actionType={ButtonType.Cancel}
            disabled={state.isLoading || rejectAllLoading}
            dataTestId="rejectAllReferrals"
          />
        }
        privilege={UserPrivilege.RejectAllQualifiedReferrals}
      />
      <ActionButton
        actionType={ButtonType.Update}
        disabled={state.isLoading}
        onClick={() => refetch()}
      />
    </HeaderActionButtonsWrapper>
  );
};
