import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { UpdatedDepositData } from './UpdatedDepositData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type RevertDepositToPresumablyDeclinedVariables = SchemaTypes.Exact<{
  input: SchemaTypes.RevertDepositToPresumablyDeclinedInput;
}>;


export type RevertDepositToPresumablyDeclined = { __typename: 'Mutation', revertDepositToPresumablyDeclined: { __typename: 'RevertDepositToPresumablyDeclinedPayload', deposit: { __typename: 'Deposit', id: string, status: SchemaTypes.DepositStatus, paidSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } } };


export const RevertDepositToPresumablyDeclinedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RevertDepositToPresumablyDeclined"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RevertDepositToPresumablyDeclinedInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revertDepositToPresumablyDeclined"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deposit"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UpdatedDepositData"}}]}}]}}]}},...UpdatedDepositData.definitions]} as unknown as DocumentNode;
export type RevertDepositToPresumablyDeclinedMutationFn = Apollo.MutationFunction<RevertDepositToPresumablyDeclined, RevertDepositToPresumablyDeclinedVariables>;

/**
 * __useRevertDepositToPresumablyDeclined__
 *
 * To run a mutation, you first call `useRevertDepositToPresumablyDeclined` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertDepositToPresumablyDeclined` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertDepositToPresumablyDeclined, { data, loading, error }] = useRevertDepositToPresumablyDeclined({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertDepositToPresumablyDeclined(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertDepositToPresumablyDeclined, RevertDepositToPresumablyDeclinedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RevertDepositToPresumablyDeclined, RevertDepositToPresumablyDeclinedVariables>(RevertDepositToPresumablyDeclinedDocument, options);
      }
export type RevertDepositToPresumablyDeclinedHookResult = ReturnType<typeof useRevertDepositToPresumablyDeclined>;
export type RevertDepositToPresumablyDeclinedMutationResult = Apollo.MutationResult<RevertDepositToPresumablyDeclined>;
export type RevertDepositToPresumablyDeclinedMutationOptions = Apollo.BaseMutationOptions<RevertDepositToPresumablyDeclined, RevertDepositToPresumablyDeclinedVariables>;