export enum ColumnId {
  Id = 'id',
  Actor = 'actor',
  Account = 'account',
  PaymentMethod = 'paymentMethod',
  AccountName = 'paymentMethod.account',
  Bank = 'paymentMethod.bank',
  Sum = 'sum',
  PaidSum = 'paidSum',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Actor]: 'Пользователь',
  [ColumnId.Account]: 'Счёт',
  [ColumnId.PaymentMethod]: 'Метод',
  [ColumnId.AccountName]: 'Номер счёта',
  [ColumnId.Bank]: 'Банк',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.PaidSum]: 'Факт. сумма',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлен',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Actor,
  ColumnId.Account,
  ColumnId.PaymentMethod,
  ColumnId.AccountName,
  ColumnId.Bank,
  ColumnId.Sum,
  ColumnId.PaidSum,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
