import { ChangeEvent, FC, PropsWithChildren, useId } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import styles from './RadioButton.module.scss';

export interface RadioProps {
  name: string;
  value: string;
  disabled?: boolean;
  onChange?: () => void;
}

export const RadioButton: FC<PropsWithChildren<RadioProps>> = ({
  name,
  disabled,
  value,
  onChange,
  children,
}) => {
  const id = useId();
  const [field, , setters] = useField(name);

  const isCheckedByValue = value === field.value;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange();
    }

    setters.setValue(event.currentTarget.value);
  };

  return (
    <label
      htmlFor={id}
      className={classnames(styles.label, disabled && styles.disabledLabel)}
    >
      <input
        type="radio"
        name={name}
        id={id}
        className={styles.radio}
        disabled={disabled}
        value={value}
        checked={isCheckedByValue}
        onChange={handleChange}
      />
      <span
        className={classnames(
          styles.pseudoRadio,
          disabled && styles.disabledPseudoRadio
        )}
      />
      {children && <span className={styles.labelText}>{children}</span>}
    </label>
  );
};
