import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { GetCompanyAffiliateApplication } from '../../queries/generated/GetCompanyAffiliateApplication';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { contactForCommunicationTypeMap, nbsp } from '../../../../../../const';
import { getCompanyAffiliateApplicationStatus } from '../../../../helpers';
import { UpdateCompanyAffiliateApplicationButtons } from '../UpdateCompanyAffiliateApplicationButtons';
import { useGetButtonsVisibility } from '../UpdateCompanyAffiliateApplicationButtons/hooks/useGetButtonsVisibility';

interface Props {
  companyAffiliateApplication: GetCompanyAffiliateApplication['companyAffiliateApplication'];
}

export const ApplicationInfoTab: FC<Props> = ({
  companyAffiliateApplication,
}) => {
  const { id, status, company, createdAt, updatedAt, admin, statistic } =
    companyAffiliateApplication;

  const [title, iconConfig] = getCompanyAffiliateApplicationStatus(
    status,
    admin,
    statistic
  );

  const { name, contactForCommunication, email } = company;

  const { hasConfirmButton, hasSuspendButton, hasAssignButton } =
    useGetButtonsVisibility(status, companyAffiliateApplication.editable);

  return (
    <DataCard
      statusConfig={
        status && {
          status: title,
          iconConfig,
        }
      }
      rows={[
        {
          title: 'Название компании',
          value: name,
        },
        {
          title: 'Почта',
          value: email,
        },
        {
          title: `Контакт для${nbsp}связи`,
          value: contactForCommunicationTypeMap[contactForCommunication.type],
        },
        {
          title: 'Номер/Имя пользователя',
          value: contactForCommunication.value,
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.DefaultDateOld),
        },
        {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.DefaultDateOld),
        },
      ]}
      customFooter={
        <UpdateCompanyAffiliateApplicationButtons
          companyAffiliateApplicationId={id}
          hasConfirmButton={hasConfirmButton}
          hasAssignButton={hasAssignButton}
          hasSuspendButton={hasSuspendButton}
        />
      }
    />
  );
};
