import { FC } from 'react';
import { SidebarLoader } from 'ui/SidebarLoader';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useModalManageData } from 'ui/Modal';
import { playerPaymentNameMap, withdrawalStatusMap } from 'src/const';
import { WithdrawalStatus } from 'generatedGraphql';
import { DataCard } from 'commonComponents/DataCard';
import { getWithdrawalStatusIconConfig } from 'src/helpers';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useAuth } from 'src/components/Auth';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { useGetWithdrawal } from './queries/generated/GetWithdrawal';
import ButtonsUpdateWithdrawal from '../ButtonsUpdateWithdrawal/ButtonsUpdateWithdrawal';

interface Props {
  withdrawalId: string;
}

const WithdrawalItem: FC<Props> = ({ withdrawalId }) => {
  const { privileges } = useAuth();

  const { data, loading, error } = useGetWithdrawal({
    variables: {
      id: withdrawalId,
      viewPaymentMethodAccount: privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-withdrawal-error' },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    createdAt,
    paymentMethod: { account, name, maskedAccount },
    sum,
    paidSum,
    admin,
    status,
    updatedAt,
  } = data.withdrawal;

  const getStatusText = () => {
    if (status === WithdrawalStatus.InProgress && admin) {
      return `${withdrawalStatusMap[status]}: ${admin.firstName} ${admin.lastName}`;
    }

    return withdrawalStatusMap[status];
  };

  return (
    <SidebarLoader loading={loading}>
      <DataCard
        statusConfig={{
          status: getStatusText(),
          iconConfig: getWithdrawalStatusIconConfig(status),
        }}
        rows={[
          {
            title: 'Метод',
            value: playerPaymentNameMap[name] || name,
          },
          {
            title: 'Номер счета',
            value: account || maskedAccount,
          },
          {
            title: 'Сумма',
            value: formatSumWithCurrency(sum.amount, sum.currency),
          },
          paidSum && {
            title: 'Фактическая сумма',
            value: formatSumWithCurrency(paidSum.amount, paidSum.currency),
          },
          {
            title: 'Создан',
            value: formatDate(createdAt, DateFormat.FullDate),
          },
          updatedAt && {
            title: 'Обновлен',
            value: formatDate(updatedAt, DateFormat.FullDate),
          },
        ]}
        customFooter={<ButtonsUpdateWithdrawal withdrawal={data.withdrawal} />}
      />
    </SidebarLoader>
  );
};

export default WithdrawalItem;
