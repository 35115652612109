import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import styles from './MessageBlockWithButton.module.scss';

interface ActionButtonConfig {
  buttonText: string;
  onButtonClick: () => void;
}

interface Props {
  message?: string;
  actionButtonConfig?: ActionButtonConfig;
}

export const MessageBlockWithButton: FC<Props> = ({
  message,
  actionButtonConfig,
}) => (
  <div className={classnames(styles.container, !message && styles.noMessage)}>
    {message && (
      <span className={styles.message} data-testid="message">
        {message}
      </span>
    )}
    {actionButtonConfig && (
      <Button
        onClick={actionButtonConfig.onButtonClick}
        theme={ButtonTheme.Primary}
        size={ButtonSize.Large}
      >
        {actionButtonConfig.buttonText}
      </Button>
    )}
  </div>
);
