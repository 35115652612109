import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';

export const useGetPrivilegeToShowMediaItems = () => {
  const auth = useAuth();

  const hasPrivilegeForViewCompanyAffiliateReferralLinks =
    auth.privileges.areEveryGranted({
      privileges: [UserPrivilege.ViewCompanyAffiliateReferralLinkMediaItem],
    });

  const hasPrivilegeForViewCompanyAffiliatePromoCodes =
    auth.privileges.areEveryGranted({
      privileges: [UserPrivilege.ViewCompanyAffiliatePromoCodeMediaItem],
    });

  return {
    hasPrivilegeForViewCompanyAffiliateReferralLinks,
    hasPrivilegeForViewCompanyAffiliatePromoCodes,
  };
};
