import { ApolloError } from '@apollo/client';
import { useGetPostbackDisabledNotificationsBlock } from '../queries/generated/GetPostbackDisabledNotificationsBlock';

interface UseHasPostbackDisabledNotificationsResult {
  loading: boolean;
  error?: ApolloError;
  hasPostbackDisabledNotifications: boolean;
  refetch: () => void;
}

const COUNT_PER_PAGE = 1;

export const useHasPostbackDisabledNotifications =
  (): UseHasPostbackDisabledNotificationsResult => {
    const { data, loading, error, refetch } =
      useGetPostbackDisabledNotificationsBlock({
        variables: {
          first: COUNT_PER_PAGE,
        },
      });

    const hasPostbackDisabledNotifications =
      !!data?.postbackDisabledNotifications.edges.length;

    return {
      loading,
      error,
      hasPostbackDisabledNotifications,
      refetch,
    };
  };
