import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { RevShareCompanyAffiliateReportData } from '../../queries/generated/RevShareCompanyAffiliateReportData';
import { useGetRevShareCompanyAffiliateReportColumns } from './hooks/useGetRevShareCompanyAffiliateReportColumns';

interface Props {
  revShareCompanyAffiliateReport?: Array<RevShareCompanyAffiliateReportData>;
  hasTotalRow: boolean;
}

export const RevShareCompanyAffiliateReportTable: FC<Props> = ({
  revShareCompanyAffiliateReport,
  hasTotalRow,
}) => {
  const { columns } = useGetRevShareCompanyAffiliateReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={revShareCompanyAffiliateReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
