import { FormikErrors } from 'formik';
import { MediaItemTypeName, RewardTariffType } from 'commonTypes';

export interface CreateReferralLinkInput {
  id: string;
  referralLinkName: string;
  companyName?: string;
  mediaCampaignId: string;
  typeMediaItem: MediaItemTypeName;
  referralLinkCode: string;
  type: RewardTariffType | null;
  revShareTariffId: string;
  cpaTariffId: string;
}

export const validate = (
  values: CreateReferralLinkInput
): FormikErrors<CreateReferralLinkInput> => {
  const errors: FormikErrors<CreateReferralLinkInput> = {};

  if (!values.type) {
    errors.type = 'Укажите тип';
  }

  if (!values.referralLinkName) {
    errors.referralLinkName = 'Введите название';
  }

  if (values.type === RewardTariffType.Cpa && !values.revShareTariffId) {
    errors.revShareTariffId = 'Укажите резервный тариф';
  }

  if (values.type === RewardTariffType.Cpa && !values.cpaTariffId) {
    errors.cpaTariffId = 'Укажите CPA тариф';
  }

  if (values.type === RewardTariffType.RevShare && !values.revShareTariffId) {
    errors.revShareTariffId = 'Укажите RevShare тариф';
  }

  return errors;
};
