import { IconDefinition, faRotate } from '@fortawesome/pro-solid-svg-icons';
import { IconType } from './const';

const checkIsIconType = (icon: IconType | IconDefinition): icon is IconType =>
  Object.values(IconType).includes(icon as IconType);

const iconTypeToIconMap: Record<IconType, IconDefinition> = {
  [IconType.Update]: faRotate,
};

export const getIcon = (icon: IconType | IconDefinition): IconDefinition =>
  checkIsIconType(icon) ? iconTypeToIconMap[icon] : icon;
