import { FC, ReactElement } from 'react';
import classnames from 'classnames';
import { Falsy } from 'commonTypes';
import styles from './KeyValueRow.module.scss';

export interface KeyValueRowProps {
  title: string;
  emptyText?: string;
  value?: string | number | ReactElement | Falsy;
  isBoldValue?: boolean;
  className?: string;
}

export const KeyValueRow: FC<KeyValueRowProps> = ({
  title,
  value,
  emptyText,
  isBoldValue,
  className,
}) => (
  <div className={classnames(styles.container, className)}>
    <div className={styles.title}>{title}</div>
    {value ? (
      <div className={classnames(styles.value, isBoldValue && styles.bold)}>
        {value}
      </div>
    ) : (
      <div className={styles.noValue}>{emptyText || 'Нет данных'}</div>
    )}
  </div>
);
