import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeleteAllPostbackDisabledNotificationsVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type DeleteAllPostbackDisabledNotifications = { __typename: 'Mutation', deleteAllPostbackDisabledNotifications: { __typename: 'DeleteAllPostbackDisabledNotificationsPayload', void?: any | null } };


export const DeleteAllPostbackDisabledNotificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAllPostbackDisabledNotifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAllPostbackDisabledNotifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type DeleteAllPostbackDisabledNotificationsMutationFn = Apollo.MutationFunction<DeleteAllPostbackDisabledNotifications, DeleteAllPostbackDisabledNotificationsVariables>;

/**
 * __useDeleteAllPostbackDisabledNotifications__
 *
 * To run a mutation, you first call `useDeleteAllPostbackDisabledNotifications` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllPostbackDisabledNotifications` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllPostbackDisabledNotifications, { data, loading, error }] = useDeleteAllPostbackDisabledNotifications({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllPostbackDisabledNotifications(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAllPostbackDisabledNotifications, DeleteAllPostbackDisabledNotificationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAllPostbackDisabledNotifications, DeleteAllPostbackDisabledNotificationsVariables>(DeleteAllPostbackDisabledNotificationsDocument, options);
      }
export type DeleteAllPostbackDisabledNotificationsHookResult = ReturnType<typeof useDeleteAllPostbackDisabledNotifications>;
export type DeleteAllPostbackDisabledNotificationsMutationResult = Apollo.MutationResult<DeleteAllPostbackDisabledNotifications>;
export type DeleteAllPostbackDisabledNotificationsMutationOptions = Apollo.BaseMutationOptions<DeleteAllPostbackDisabledNotifications, DeleteAllPostbackDisabledNotificationsVariables>;