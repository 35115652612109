import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { SumData } from '../../../../../../queries/generated/SumData';
import { BonusBasicData } from './BonusBasicData';
import { BonusCashbackData } from './BonusCashbackData';
import { BonusFreespinData } from './BonusFreespinData';
import { BonusGiftData } from './BonusGiftData';
import { BonusPromoCodeData } from './BonusPromoCodeData';
export type BonusData_CashbackBonus = { __typename: 'CashbackBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, rewardPeriod: any, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusData_FirstDepositBonus = { __typename: 'FirstDepositBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusData_FreespinBonus = { __typename: 'FreespinBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, freespin?: { __typename: 'Freespin', id: string } | null };

export type BonusData_GiftBonus = { __typename: 'GiftBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, cancellable: boolean, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusData_PersonalBonus = { __typename: 'PersonalBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusData_PromoCodeBonus = { __typename: 'PromoCodeBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, promoCode: { __typename: 'PromoCode', id: string, token: string } };

export type BonusData = BonusData_CashbackBonus | BonusData_FirstDepositBonus | BonusData_FreespinBonus | BonusData_GiftBonus | BonusData_PersonalBonus | BonusData_PromoCodeBonus;

export const BonusData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BonusData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BonusInterface"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"balance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BonusBasicData"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BonusCashbackData"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BonusFreespinData"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BonusGiftData"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BonusPromoCodeData"}},{"kind":"Field","name":{"kind":"Name","value":"config"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cashout"}},{"kind":"Field","name":{"kind":"Name","value":"minAccountBalance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"winConditions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"betSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"requiredBetSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"wager"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"winSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}}]}}]} as unknown as DocumentNode;