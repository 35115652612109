import {
  PropsWithChildren,
  Reducer,
  Context,
  useReducer,
  createContext,
  Dispatch,
} from 'react';

const createStore = <StateType, ActionType>(
  reducer: Reducer<StateType, ActionType>,
  initialState: StateType
): readonly [
  Context<{ state: StateType; dispatch: Dispatch<ActionType> }>,
  (props: PropsWithChildren<unknown>) => JSX.Element
] => {
  const defaultDispatch: Dispatch<ActionType> = () => initialState;

  const context = createContext({
    state: initialState,
    dispatch: defaultDispatch,
  });

  const Provider = (props: PropsWithChildren<unknown>) => {
    const [state, dispatch] = useReducer<Reducer<StateType, ActionType>>(
      reducer,
      initialState
    );

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    return <context.Provider value={{ state, dispatch }} {...props} />;
  };

  return [context, Provider] as const;
};

export default createStore;
