import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { ReactElement } from 'react';
import {
  depositStatusMap,
  paymentProviderMap,
  playerPaymentNameMap,
  twoEmDash,
} from 'src/const';
import { CardIconConfig } from 'ui/Card/CardIcon';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { DepositStatus, Sum } from 'generatedGraphql';
import { BoldSumCellByCompare } from 'ui/Table/components/BoldSumCellByCompare';
import ProgressBarWager from 'src/components/ProgressBarWager/ProgressBarWager';
import { ProgressBarWagerType } from 'src/components/ProgressBarWager/const';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import { DepositData } from '../queries/generated/DepositData';
import { getStatusIcon } from '../helpers';
import {
  blockColumnsIds,
  ColumnId,
  mapColumnIdToName,
  pageColumnsIds,
} from '../const';

interface GetSumData {
  sum: Sum;
  paidSum?: Sum | null;
}

interface GetSumDataReturn {
  title: string;
  iconConfig: undefined;
  subTitle?: string;
}

const getStatusData = (status: DepositStatus): [string, CardIconConfig] => {
  const formattedStatus = depositStatusMap[status];
  const [icon, color] = getStatusIcon(status);
  const iconConf: CardIconConfig = { icon, iconColor: color };

  return [formattedStatus, iconConf];
};

const getDate = (createdAt: string): ReactElement => (
  <NowrapCell>{formatDate(createdAt, DateFormat.FullDate)}</NowrapCell>
);

const getSumData = ({ sum, paidSum }: GetSumData): GetSumDataReturn => {
  const sumAmount = sum?.amount;
  const sumCurrency = sum?.currency;

  const paidSumAmount = paidSum?.amount;
  const paidSumCurrency = paidSum?.currency;

  const title = formatSumWithCurrency(sumAmount, sumCurrency);

  let subTitle;

  if (paidSumAmount && paidSumCurrency && sumAmount !== paidSumAmount) {
    subTitle = formatSumWithCurrency(paidSumAmount, paidSumCurrency);
  }

  return { title, iconConfig: undefined, subTitle };
};

const columnHelper = createColumnHelper<DepositData>();

const getColumnsSource = (
  isFullScreenTable: boolean
): ColumnsSource<DepositData> => [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
  }),
  columnHelper.accessor(ColumnId.Provider, {
    id: ColumnId.Provider,
    header: mapColumnIdToName[ColumnId.Provider],
    cell: ({ row }) => {
      const { paymentProvider } = row.original;

      if (paymentProvider) {
        return (
          <div>{paymentProviderMap[paymentProvider] || paymentProvider}</div>
        );
      }

      return null;
    },
  }),
  columnHelper.accessor(ColumnId.PaymentMethodName, {
    id: ColumnId.PaymentMethodName,
    header: mapColumnIdToName[ColumnId.PaymentMethodName],
    cell: ({ row }) => {
      const { paymentMethodName } = row.original;

      return (
        <div>
          {playerPaymentNameMap[paymentMethodName] || paymentMethodName}
        </div>
      );
    },
  }),
  columnHelper.accessor(ColumnId.PaymentMethodAccount, {
    id: ColumnId.PaymentMethodAccount,
    header: mapColumnIdToName[ColumnId.PaymentMethodAccount],
    cell: ({ row }) => {
      const { paymentMethod } = row.original;

      return MayBeNullCell(
        paymentMethod?.account || paymentMethod?.maskedAccount,
        isFullScreenTable ? twoEmDash : 'Нет данных'
      );
    },
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { sum, paidSum } = row.original;

      if (sum.amount !== paidSum?.amount && !isFullScreenTable) {
        return MultilineCellWithIcon({
          ...getSumData({ sum, paidSum }),
          isAlignRight: true,
        });
      }

      return formatSumWithCurrency(sum.amount, sum.currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Wager, {
    id: ColumnId.Wager,
    header: mapColumnIdToName[ColumnId.Wager],
    cell: ({ row }) => {
      const { wager } = row.original;

      return MayBeNullCell(
        wager && (
          <ProgressBarWager wager={wager} type={ProgressBarWagerType.Short} />
        ),
        twoEmDash
      );
    },
  }),
  columnHelper.accessor(ColumnId.PaidSum, {
    id: ColumnId.PaidSum,
    header: mapColumnIdToName[ColumnId.PaidSum],
    cell: ({ row }) => {
      const { sum, paidSum } = row.original;

      return <BoldSumCellByCompare comparedSum={sum} renderedSum={paidSum} />;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const [title, iconConfig] = getStatusData(row.original.status);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => getDate(row.original.createdAt),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => {
      const { updatedAt } = row.original;

      if (updatedAt) {
        return getDate(updatedAt);
      }

      return null;
    },
  }),
];

export const useGetDepositsColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    getColumnsSource(isFullScreenTable)
  );

  return {
    columns,
  };
};
