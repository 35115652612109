import { useCallback, useEffect } from 'react';
import errorToast from 'ui/Toast/ErrorToast';
import { getRevShareTariffsOptions } from './helpers';
import { useGetRevShareTariffs } from './queries/generated/GetRevShareTariffs';
import { nbsp } from '../../const';

const COUNT_PER_PAGE = 10;

export const useRevShareTariffDropdown = () => {
  const {
    data: revShareTariffs,
    loading,
    error,
    fetchMore,
  } = useGetRevShareTariffs({
    variables: {
      first: COUNT_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  const revShareTariffsOptions = getRevShareTariffsOptions(
    revShareTariffs?.revShareRewardTariffs.edges
  );

  const hasNextPage =
    revShareTariffs?.revShareRewardTariffs.pageInfo.hasNextPage;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore({
        variables: {
          first: COUNT_PER_PAGE,
          after: revShareTariffs?.revShareRewardTariffs?.pageInfo.endCursor,
        },
      }),
    [revShareTariffs?.revShareRewardTariffs?.pageInfo.endCursor, fetchMore]
  );

  useEffect(() => {
    if (error) {
      errorToast({
        header: `Ошибка${nbsp}при${nbsp}загрузке RevShare тарифов`,
        text: error.message,
        toastId: 'get-rev-share-tariffs-error',
      });
    }
  }, [error]);

  return {
    revShareTariffsOptions,
    loading,
    revShareTariffs,
    error,
    fetchMoreCallback,
    hasNextPage,
  };
};
