import { TabFieldInterface } from 'ui/Tabs';
import { MediaItemTypeName } from 'commonTypes';

export const mediaItemsTabs: Array<TabFieldInterface<MediaItemTypeName>> = [
  {
    id: MediaItemTypeName.ReferralLink,
    name: <span>Ссылки</span>,
  },
  {
    id: MediaItemTypeName.PromoCode,
    name: <span>Промокоды</span>,
  },
];
