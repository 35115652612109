import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type EnableAllPostbacksVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type EnableAllPostbacks = { __typename: 'Mutation', enableAllPostbacks: { __typename: 'EnableAllPostbacksPayload', void?: any | null } };


export const EnableAllPostbacksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EnableAllPostbacks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enableAllPostbacks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type EnableAllPostbacksMutationFn = Apollo.MutationFunction<EnableAllPostbacks, EnableAllPostbacksVariables>;

/**
 * __useEnableAllPostbacks__
 *
 * To run a mutation, you first call `useEnableAllPostbacks` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAllPostbacks` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAllPostbacks, { data, loading, error }] = useEnableAllPostbacks({
 *   variables: {
 *   },
 * });
 */
export function useEnableAllPostbacks(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableAllPostbacks, EnableAllPostbacksVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EnableAllPostbacks, EnableAllPostbacksVariables>(EnableAllPostbacksDocument, options);
      }
export type EnableAllPostbacksHookResult = ReturnType<typeof useEnableAllPostbacks>;
export type EnableAllPostbacksMutationResult = Apollo.MutationResult<EnableAllPostbacks>;
export type EnableAllPostbacksMutationOptions = Apollo.BaseMutationOptions<EnableAllPostbacks, EnableAllPostbacksVariables>;