import { AvailableFilterListItem } from 'ui/TableFilter/types';

interface GetDropdownListByMapReturn extends AvailableFilterListItem {
  label: string;
}

export const getDropdownListByMap = (map: {
  [key in string]: string;
}): Array<GetDropdownListByMapReturn> =>
  Object.entries(map).map(([key, value]) => ({
    label: value,
    textForSearch: value,
    id: key,
  }));
