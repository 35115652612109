import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateMediaCampaignVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateMediaCampaignInput;
}>;


export type UpdateMediaCampaign = { __typename: 'Mutation', updateMediaCampaign: { __typename: 'UpdateMediaCampaignPayload', mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string } } };


export const UpdateMediaCampaignDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateMediaCampaign"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateMediaCampaignInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateMediaCampaign"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateMediaCampaignMutationFn = Apollo.MutationFunction<UpdateMediaCampaign, UpdateMediaCampaignVariables>;

/**
 * __useUpdateMediaCampaign__
 *
 * To run a mutation, you first call `useUpdateMediaCampaign` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaCampaign` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaCampaign, { data, loading, error }] = useUpdateMediaCampaign({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaCampaign(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMediaCampaign, UpdateMediaCampaignVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMediaCampaign, UpdateMediaCampaignVariables>(UpdateMediaCampaignDocument, options);
      }
export type UpdateMediaCampaignHookResult = ReturnType<typeof useUpdateMediaCampaign>;
export type UpdateMediaCampaignMutationResult = Apollo.MutationResult<UpdateMediaCampaign>;
export type UpdateMediaCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateMediaCampaign, UpdateMediaCampaignVariables>;