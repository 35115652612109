import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useCompanyAffiliateApplicationsData } from './hooks/useCompanyAffiliateApplicationsData';
import { CompanyAffiliateApplicationsTable } from './components/CompanyAffiliateApplicationsTable';

export const CompanyAffiliateApplications: FC = withBlockProvider(() => {
  const {
    refetchCompanyAffiliateApplicationsList,
    companyAffiliateApplicationsList,
  } = useCompanyAffiliateApplicationsData();
  const { state } = useContext(BlockContext);

  return (
    <Block
      title="Заявки партнёров компании"
      id="сompanyAffiliateApplications"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={false}
          disabled={state.isLoading}
          onClick={() => refetchCompanyAffiliateApplicationsList()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Заявки партнеров компании не найдены"
      isEmpty={isListEmpty(companyAffiliateApplicationsList)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(companyAffiliateApplicationsList) && (
        <CompanyAffiliateApplicationsTable
          companyAffiliateApplications={companyAffiliateApplicationsList}
        />
      )}
    </Block>
  );
});
