import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Dropdown } from 'ui/Dropdown';
import FormRow from 'ui/FormRow';
import { QualifiedReferralConfirmationSettingType } from 'generatedGraphql';
import Input from 'ui/Input';
import Error from 'ui/Error';
import { Tooltip } from 'ui/Tooltip';
import { FieldName } from './const';
import { useUpdateQualifiedReferralConfirmationSetting } from './hooks/useUpdateQualifiedReferralConfirmationSetting';
import { validate } from './validation';
import { getConfirmationSettingTypeOptions } from '../../helpers';
import { UpdateConfirmationSettingValues } from './types';

interface UpdateQualifiedReferralConfirmationSettingParams {
  confirmationSettingId: string;
  comment: string;
  settingType: QualifiedReferralConfirmationSettingType;
}

export const UpdateQualifiedReferralConfirmationSetting: FC<
  UpdateQualifiedReferralConfirmationSettingParams
> = ({ confirmationSettingId, comment, settingType }) => {
  const { updateQualifiedReferralConfirmationSetting, loading, error } =
    useUpdateQualifiedReferralConfirmationSetting(confirmationSettingId);

  const initialValues: UpdateConfirmationSettingValues = {
    [FieldName.settingType]: settingType,
    [FieldName.comment]: comment,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateQualifiedReferralConfirmationSetting}
      validateOnChange={false}
      validate={validate}
    >
      {({ dirty, isValid }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Dropdown
                name={FieldName.settingType}
                label="Тип настройки"
                options={getConfirmationSettingTypeOptions()}
              />
            </FormRow>
            <FormRow isWide>
              <Input.Textarea
                name={FieldName.comment}
                label="Комментарий"
                placeholder="Введите комментарий"
                maxLength={500}
              />
            </FormRow>
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Необходимо заполнить поле' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              disabled={!dirty || !isValid}
              isLoading={loading}
            >
              Сохранить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
