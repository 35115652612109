import { FC, useContext, useState } from 'react';
import { MediaItemTypeName } from 'commonTypes';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { MediaItemsTabs } from 'commonComponents/MediaItemsTabs';
import { isListEmpty } from 'ui/Block/BlockTable/utils';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useGetMediaItemsData } from './hooks/useGetMediaItemsData';
import styles from './PartnerMediaItemsBlock.module.scss';
import { useOpenCreateReferralLinkModal } from './components/ReferralLinkMediaItemTable/useOpenCreateReferralLinkModal';
import { MediaItemTable } from './components/MediaItemTable';

interface Props {
  id: string;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
}

export const PartnerMediaItemsBlock: FC<Props> = withBlockProvider(
  ({ id, isFullScreenTable, shouldReturnToPrevPage }) => {
    const { state } = useContext(BlockContext);

    const [mediaItemsType, setMediaItemsType] = useState(
      MediaItemTypeName.ReferralLink
    );

    const { openCreateReferralLinkModal } = useOpenCreateReferralLinkModal({
      partnerId: id,
      isFullScreenTable,
    });

    const {
      mediaItems,
      refetch,
      loadPartnerMediaItem,
      loading,
      error,
      fetchMore,
      hasNextPage,
      mediaItemsData,
      columns,
    } = useGetMediaItemsData({
      mediaItemsType,
      partnerId: id,
      isFullScreenTable,
    });

    useBlockComponentState({
      loadData: loadPartnerMediaItem,
      loading,
      error,
    });

    useBlockTableComponentState({
      data: mediaItemsData,
      fetchMoreCallback: fetchMore,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable,
    });

    return (
      <Block
        title="Медиа элементы"
        id="partnerMediaItems"
        headerContent={
          <div className={styles.headerContent}>
            <Privilege
              privileges={[UserPrivilege.CreatePartnerReferralLinkMediaItem]}
            >
              <ActionButton
                actionType={ButtonType.Add}
                onClick={openCreateReferralLinkModal}
              />
            </Privilege>
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetch()}
            />
          </div>
        }
        subHeader={
          <MediaItemsTabs
            currentTabId={mediaItemsType}
            setMediaItemsType={setMediaItemsType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Медиа элементы не найдены"
        isEmpty={isListEmpty(mediaItems)}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
      >
        <MediaItemTable
          isFullScreenTable={isFullScreenTable}
          mediaItemsType={mediaItemsType}
          mediaItems={mediaItems}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columns={columns as any}
          partnerId={id}
        />
      </Block>
    );
  }
);
