import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CreateRewardTariff } from '../index';

export const useCreateRewardTariffModal = () => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(async () => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        title: `Создание партнёрского тарифа`,
        content: <CreateRewardTariff />,
      },
    });
  }, [modalDispatch]);

  return openModal;
};
