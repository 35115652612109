import { FC, ReactElement } from 'react';
import { Tooltip } from 'ui/Tooltip';
import { TooltipTheme } from 'ui/Tooltip/types';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { CardIcon } from 'ui/Card/CardIcon';
import styles from './ReviewResultCellWithTooltip.module.scss';

interface Props {
  title: string;
  children?: ReactElement | string;
}

export const ReviewResultCellWithTooltip: FC<Props> = ({ title, children }) => (
  <div className={styles.container}>
    <span className={styles.title}>{title}</span>
    <Tooltip
      tooltipTheme={TooltipTheme.Dark}
      tooltipContent={children}
      clickable
    >
      <CardIcon
        config={{ icon: faQuestionCircle }}
        className={styles.questionIcon}
        data-testid="questionIconWithTooltip"
      />
    </Tooltip>
  </div>
);
