import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import {
  GetPostbackDisabledNotifications,
  useGetPostbackDisabledNotifications as useGetPostbackDisabledNotificationsQuery,
} from '../queries/generated/GetPostbackDisabledNotifications';

interface UseGetPostbackDisabledNotificationsResult {
  loadMore: () => void;
  error?: ApolloError;
  loading: boolean;
  postbackDisabledNotifications?: GetPostbackDisabledNotifications['postbackDisabledNotifications'];
}

const COUNT_PER_PAGE = 100;

export const useGetPostbackDisabledNotifications =
  (): UseGetPostbackDisabledNotificationsResult => {
    const { data, loading, error, fetchMore } =
      useGetPostbackDisabledNotificationsQuery({
        variables: {
          first: COUNT_PER_PAGE,
        },
      });

    const postbackDisabledNotifications = data?.postbackDisabledNotifications;

    const loadMore = useCallback(() => {
      if (!postbackDisabledNotifications?.pageInfo.hasNextPage) {
        return;
      }

      fetchMore({
        variables: {
          after: postbackDisabledNotifications.pageInfo.endCursor,
          first: COUNT_PER_PAGE,
        },
      });
    }, [postbackDisabledNotifications, fetchMore]);

    return {
      postbackDisabledNotifications,
      loading,
      error,
      loadMore,
    };
  };
