import React, { FC, SyntheticEvent } from 'react';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import Lightbox from 'react-image-lightbox-rotation';
import 'react-image-lightbox-rotation/style.css';
import { Link } from 'react-router-dom';
import LoadMoreButton from 'ui/LoadMoreButton';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import styles from './DocumentsList.module.scss';
import '../../lightbox.scss';
import DocumentThumbItem from '../DocumentThumbItem';
import { useDocuments } from '../../hooks/useDocuments';
import DocumentSidebar from '../DocumentSidebar/DocumentSidebar';
import { useLightboxControls } from '../../hooks/useLightboxControls';
import DownloadDocumentButton from '../DownloadDocumentButton';

interface Props {
  playerId: string;
  isFullScreenBlock: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const DocumentsList: FC<Props> = ({
  playerId,
  isFullScreenBlock,
  shouldReturnToPrevPage,
  routeToCustomPage,
}) => {
  const {
    state,
    documents,
    deleteDocument,
    refetchDocuments,
    openCreateDocumentsModal,
    isEmptyList,
    isDeleteLoading,
    loading,
    isEndOfList,
    documentsImages,
  } = useDocuments({ playerId, isFullScreenBlock });

  const {
    documentIndex,
    currentDocument,
    nextDocument,
    prevDocument,
    handleClick,
    handleMoveNext,
    handleMovePrev,
    reactModalStyle,
    onLightboxOpen,
    onLightboxClose,
  } = useLightboxControls(documentsImages);

  const handleAddClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    openCreateDocumentsModal();
  };

  return (
    <Block
      title="Документы"
      id="playerDocuments"
      headerContent={
        <div className={styles.headerContent}>
          <ActionButton actionType={ButtonType.Add} onClick={handleAddClick} />
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={refetchDocuments}
          />
        </div>
      }
      isEmpty={isEmptyList}
      emptyText="Игрок не загружал документы"
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {!!documents?.length && (
        <div
          className={`${styles.wrapper} ${
            isFullScreenBlock
              ? styles.documentsPageGrid
              : styles.documentsBlockGrid
          }`}
        >
          <div className={styles.documentsList}>
            {documents.map(({ node }, index) => (
              <DocumentThumbItem
                key={node.id}
                documentIndex={index}
                note={node.note}
                createdAt={node.createdAt}
                toggleLightbox={handleClick}
                thumbnailImageUrl={node.thumbnailImageUrl}
              />
            ))}
          </div>
          {!!currentDocument && (
            <Lightbox
              mainSrc={currentDocument.imageUrl}
              mainSrcThumbnail={currentDocument.imageUrl}
              nextSrc={nextDocument.imageUrl}
              nextSrcThumbnail={nextDocument.imageUrl}
              prevSrc={prevDocument.imageUrl}
              prevSrcThumbnail={prevDocument.imageUrl}
              onCloseRequest={onLightboxClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
              rotate={0}
              wrapperClassName="customLightboxWrapper"
              reactModalStyle={reactModalStyle}
              animationDuration={100}
              onAfterOpen={onLightboxOpen}
              clickOutsideToClose={false}
              toolbarButtons={[
                <DownloadDocumentButton imgUrl={currentDocument.imageUrl} />,
              ]}
              imageCaption={
                <DocumentSidebar
                  documentId={documents[documentIndex].node.id}
                  createdAt={documents[documentIndex].node.createdAt}
                  note={documents[documentIndex].node.note}
                  onDelete={deleteDocument}
                  isDeleteLoading={isDeleteLoading}
                />
              }
            />
          )}
          {routeToCustomPage && (
            <Link to={routeToCustomPage} key={routeToCustomPage}>
              <LoadMoreButton
                className={styles.showAllButton}
                label="Показать все"
              />
            </Link>
          )}
          {isFullScreenBlock && loading && (
            <LoadMoreStatus
              isLoading={state.isLoading}
              error={state.error.type}
            />
          )}
          {isEndOfList && (
            <LoadMoreStatus
              isEndOfList
              messages={{
                complete: 'Вы достигли конца списка',
              }}
            />
          )}
        </div>
      )}
    </Block>
  );
};

export default DocumentsList;
