import React, { FC } from 'react';
import { RestrictionTypeName } from 'commonTypes';
import styles from './LastRestrictions.module.scss';
import LastRestrictionItem from '../LastRestrictionItem/LastRestrictionItem';
import { LastRestrictionsData } from './queries/generated/LastRestrictionsData';

interface Props {
  lastRestrictions?: LastRestrictionsData;
}

const LastRestrictions: FC<Props> = ({ lastRestrictions }) => {
  if (!lastRestrictions) {
    return null;
  }

  const {
    receiveGiftRestriction,
    withdrawalRestriction,
    chargeBackRestriction,
    badBankRestriction,
  } = lastRestrictions;

  return (
    <div className={styles.container}>
      <LastRestrictionItem
        type={RestrictionTypeName.ReceiveGift}
        restriction={receiveGiftRestriction}
      />
      <LastRestrictionItem
        type={RestrictionTypeName.Withdrawal}
        restriction={withdrawalRestriction}
      />
      <LastRestrictionItem
        type={RestrictionTypeName.ChargeBack}
        restriction={chargeBackRestriction}
      />
      <LastRestrictionItem
        type={RestrictionTypeName.BadBank}
        restriction={badBankRestriction}
      />
    </div>
  );
};

export default LastRestrictions;
