import { FC } from 'react';
import { Sum } from 'generatedGraphql';
import { formatSumAmount } from 'src/utils/accounting';
import styles from './CpaTariffCell.module.scss';

interface Props {
  reward: Sum;
  baseLine: Sum;
}

export const CpaTariffCell: FC<Props> = ({ reward, baseLine }) => (
  <div className={styles.container}>
    <div className={styles.titles}>
      <span>Вознаграждение</span>
      <span>Бейслайн</span>
    </div>
    <div className={styles.values}>
      <span>{formatSumAmount(reward.amount)}</span>
      <span>{formatSumAmount(baseLine.amount)}</span>
    </div>
  </div>
);
