import { FC } from 'react';
import { formatSumAmount } from 'src/utils/accounting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import { SumFlowDirection } from 'generatedGraphql';
import { addSignToString } from 'src/utils/addSignToString';
import classNames from 'classnames';
import styles from './BonusBalance.module.scss';

export enum BonusBalanceIconSize {
  Small = 'small',
  Big = 'big',
}

const mapIconSizeToStyle: Record<BonusBalanceIconSize, string> = {
  [BonusBalanceIconSize.Small]: styles.smallSizeIcon,
  [BonusBalanceIconSize.Big]: styles.bigSizeIcon,
};

interface Props {
  amount: number | string;
  iconSize?: BonusBalanceIconSize;
  direction?: SumFlowDirection | null;
  setDefaultIconColor?: boolean;
}

export const BonusBalance: FC<Props> = ({
  amount,
  direction,
  iconSize = BonusBalanceIconSize.Small,
  setDefaultIconColor = true,
}) => {
  const sum =
    typeof amount === 'number'
      ? addSignToString(formatSumAmount(amount), direction)
      : amount;

  return (
    <div
      data-testid="bonusBalance"
      className={classNames(
        styles.container,
        iconSize === BonusBalanceIconSize.Big && styles.withBigGap
      )}
    >
      {sum}
      <FontAwesomeIcon
        className={classNames(
          mapIconSizeToStyle[iconSize],
          setDefaultIconColor && styles.iconWithDefaultColor
        )}
        icon={faCoins}
      />
    </div>
  );
};
