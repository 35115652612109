import {
  AffiliateInApproveProcessStatus,
  AffiliatesInProgress,
} from '../../../store';

export const getAcceptedAffiliate = (
  affiliatesInProgress: AffiliatesInProgress | null
) => {
  const approvedAffiliates = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key in affiliatesInProgress) {
    if (
      affiliatesInProgress[key] === AffiliateInApproveProcessStatus.Approved
    ) {
      approvedAffiliates.push(key);
    }
  }

  return approvedAffiliates;
};
