import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { CpaCompanyReportData } from '../../queries/generated/CpaCompanyReportData';
import { useGetCpaCompanyReportColumns } from './hooks/useGetCpaCompanyReportColumns';

interface Props {
  cpaCompanyReport?: Array<CpaCompanyReportData>;
  hasTotalRow: boolean;
}

export const CpaCompanyReportTable: FC<Props> = ({
  cpaCompanyReport,
  hasTotalRow,
}) => {
  const { columns } = useGetCpaCompanyReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={cpaCompanyReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
