import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type SuspendPartnerApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.SuspendPartnerApplicationInput;
}>;


export type SuspendPartnerApplication = { __typename: 'Mutation', suspendPartnerApplication: { __typename: 'SuspendPartnerApplicationPayload', partnerApplication: { __typename: 'PartnerApplication', id: string, status: SchemaTypes.PartnerApplicationStatus } } };


export const SuspendPartnerApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SuspendPartnerApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuspendPartnerApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suspendPartnerApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partnerApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SuspendPartnerApplicationMutationFn = Apollo.MutationFunction<SuspendPartnerApplication, SuspendPartnerApplicationVariables>;

/**
 * __useSuspendPartnerApplication__
 *
 * To run a mutation, you first call `useSuspendPartnerApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendPartnerApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendPartnerApplication, { data, loading, error }] = useSuspendPartnerApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuspendPartnerApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<SuspendPartnerApplication, SuspendPartnerApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SuspendPartnerApplication, SuspendPartnerApplicationVariables>(SuspendPartnerApplicationDocument, options);
      }
export type SuspendPartnerApplicationHookResult = ReturnType<typeof useSuspendPartnerApplication>;
export type SuspendPartnerApplicationMutationResult = Apollo.MutationResult<SuspendPartnerApplication>;
export type SuspendPartnerApplicationMutationOptions = Apollo.BaseMutationOptions<SuspendPartnerApplication, SuspendPartnerApplicationVariables>;