import { useSearchParams } from 'react-router-dom';
import { DatePickerHandleProps } from 'ui/DatePicker';
import { Dispatch, SetStateAction } from 'react';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { StatisticDatePeriod } from 'src/const';

export const useHandleSubmit = (
  setIsDatePickerOpened: Dispatch<SetStateAction<boolean>>
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = (dates: DatePickerHandleProps) => {
    const newParams = new URLSearchParams(searchParams);

    if (Array.isArray(dates)) {
      const dateFrom = dates[0];
      const dateTo = dates[1];
      const periodFrom =
        dateFrom && formatDate(dateFrom, DateFormat.DefaultDateWithDash);
      const periodTo =
        dateTo && formatDate(dateTo, DateFormat.DefaultDateWithDash);

      newParams.set(StatisticDatePeriod.To, periodTo || '');
      newParams.set(StatisticDatePeriod.From, periodFrom || '');

      setSearchParams(newParams);
      setIsDatePickerOpened(false);
    }
  };

  return handleSubmit;
};
