import { formatSumAmount } from 'src/utils/accounting';
import { formatTableHeaderCellWithCurrency } from 'src/pages/Statistic/helpers';

import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Currency } from 'commonTypes';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { CpaCompanyPartnersReportRow } from '../../../types';
import { CpaMediaOwnerCell } from '../../CpaMediaOwnerCell';
import { useGetCurrency } from '../../../../../hooks/useGetCurrency';

const columnHelper = createColumnHelper<CpaCompanyPartnersReportRow>();

const buildColumnsSource = (
  currency: Currency
): ColumnsSource<CpaCompanyPartnersReportRow> => [
  columnHelper.accessor(ColumnId.MediaOwner, {
    id: ColumnId.MediaOwner,
    header: mapColumnIdToName[ColumnId.MediaOwner],
    cell: ({ row }) => {
      if (!row.original.companyPartner && !row.original.company) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      return <CpaMediaOwnerCell row={row.original} />;
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Hits, {
    id: ColumnId.Hits,
    header: mapColumnIdToName[ColumnId.Hits],
    cell: ({ row }) => {
      const { hits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hits);
    },
  }),
  columnHelper.accessor(ColumnId.Hosts, {
    id: ColumnId.Hosts,
    header: mapColumnIdToName[ColumnId.Hosts],
    cell: ({ row }) => {
      const { hosts } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hosts);
    },
  }),
  columnHelper.accessor(ColumnId.Registrations, {
    id: ColumnId.Registrations,
    header: mapColumnIdToName[ColumnId.Registrations],
    cell: ({ row }) => {
      const { registrations } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(registrations);
    },
  }),
  columnHelper.accessor(ColumnId.FirstDeposits, {
    id: ColumnId.FirstDeposits,
    header: mapColumnIdToName[ColumnId.FirstDeposits],
    cell: ({ row }) => {
      const { firstDeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(firstDeposits);
    },
  }),
  columnHelper.accessor(ColumnId.HostToRegConversion, {
    id: ColumnId.HostToRegConversion,
    header: mapColumnIdToName[ColumnId.HostToRegConversion],
    cell: ({ row }) => {
      const { hostToRegConversion } = row.original.marketingStatistic;

      return addPercentToValue(hostToRegConversion);
    },
  }),
  columnHelper.accessor(ColumnId.RegToDepConversion, {
    id: ColumnId.RegToDepConversion,
    header: mapColumnIdToName[ColumnId.RegToDepConversion],
    cell: ({ row }) => {
      const { regToDepConversion } = row.original.marketingStatistic;

      return addPercentToValue(regToDepConversion);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { allDeposit } = row.original.playerStatistic;

      return formatSumAmount(allDeposit.amount);
    },
  }),
  columnHelper.accessor(ColumnId.AverageDeposit, {
    id: ColumnId.AverageDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AverageDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.averageDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllRedeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allRedeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) => {
      const { redeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(redeposits);
    },
  }),
  columnHelper.accessor(ColumnId.NewDeposit, {
    id: ColumnId.NewDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newDeposit;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Withdrawal],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewWithdrawal, {
    id: ColumnId.NewWithdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewWithdrawal],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewProfit, {
    id: ColumnId.NewProfit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewProfit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newProfit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Hold, {
    id: ColumnId.Hold,
    header: mapColumnIdToName[ColumnId.Hold],
    cell: ({ row }) => {
      const { hold } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(hold);
    },
  }),
  columnHelper.accessor(ColumnId.Qualified, {
    id: ColumnId.Qualified,
    header: mapColumnIdToName[ColumnId.Qualified],
    cell: ({ row }) => {
      const { qualified } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(qualified);
    },
  }),
  columnHelper.accessor(ColumnId.NotQualified, {
    id: ColumnId.NotQualified,
    header: mapColumnIdToName[ColumnId.NotQualified],
    cell: ({ row }) => {
      const { canceled } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(canceled);
    },
  }),
  columnHelper.accessor(ColumnId.Approved, {
    id: ColumnId.Approved,
    header: mapColumnIdToName[ColumnId.Approved],
    cell: ({ row }) => {
      const { approved } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(approved);
    },
  }),
  columnHelper.accessor(ColumnId.Rejected, {
    id: ColumnId.Rejected,
    header: mapColumnIdToName[ColumnId.Rejected],
    cell: ({ row }) => {
      const { rejected } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(rejected);
    },
  }),
  columnHelper.accessor(ColumnId.Paid, {
    id: ColumnId.Paid,
    header: mapColumnIdToName[ColumnId.Paid],
    cell: ({ row }) => {
      const { paid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(paid);
    },
  }),
  columnHelper.accessor(ColumnId.Unpaid, {
    id: ColumnId.Unpaid,
    header: mapColumnIdToName[ColumnId.Unpaid],
    cell: ({ row }) => {
      const { unpaid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(unpaid);
    },
  }),
  columnHelper.accessor(ColumnId.RevShare, {
    id: ColumnId.RevShare,
    header: mapColumnIdToName[ColumnId.RevShare],
    cell: ({ row }) => {
      const { revShare } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(revShare);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NGR],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.financialIndicators.ngr;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Profit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Upcoming],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateRewardStatistic.upcoming;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.RewardPaid, {
    id: ColumnId.RewardPaid,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.RewardPaid],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateRewardStatistic.paid;

      return formatSumAmount(amount);
    },
  }),
];

export const useGetCpaCompanyPartnersReportColumns = () => {
  const { currency } = useGetCurrency();
  const columnsSource = buildColumnsSource(currency);
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
