import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { DeclinePlayerAccount } from 'commonComponents/DeclinePlayerAccount';
import { useManagePlayerAccount } from './useManagePlayerAccount';
import { GetCompanyAdditionalData } from '../queries/generated/GetCompanyAdditionalData';
import { addHashTagToId } from '../../../../../utils/addHashTagToId';

interface UseEditPlayerAccountModalParams {
  companyId: string;
  company?: GetCompanyAdditionalData['company'];
}

export const useEditPlayerAccountModal = ({
  companyId,
  company,
}: UseEditPlayerAccountModalParams) => {
  const { dispatch } = useContext(ModalContext);

  const {
    declinePlayerAccount,
    declinePlayerAccountLoading,
    declinePlayerAccountError,
  } = useManagePlayerAccount(companyId);

  const onPlayerAccountEdit = useCallback(() => {
    if (!company?.playerAccount) {
      return;
    }

    const { playerAccount } = company;
    const {
      player: { name, email, phone, id },
    } = playerAccount;

    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <DeclinePlayerAccount
            onDeclinePlayerAccount={declinePlayerAccount}
            isLoading={declinePlayerAccountLoading}
            playerName={name}
            playerContactInfo={email || phone}
            playerId={id}
            error={declinePlayerAccountError}
          />
        ),
        title: 'Игровой аккаунт',
        subTitle: `${company.name} ${addHashTagToId(companyId)}`,
      },
    });
  }, [
    company,
    dispatch,
    declinePlayerAccount,
    declinePlayerAccountLoading,
    declinePlayerAccountError,
    companyId,
  ]);

  return { onPlayerAccountEdit };
};
