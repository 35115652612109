export const copyToClipboard = (text: string | number): boolean => {
  if (window.navigator.clipboard) {
    try {
      window.navigator.clipboard.writeText(String(text));

      return true;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const textarea = document.createElement('textarea');

  textarea.textContent = String(text);
  document.body.appendChild(textarea);
  textarea.select();
  try {
    return document.execCommand('copy'); // Security exception may be thrown by some browsers.
  } catch (e) {
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};
