import { SyntheticEvent, useCallback, useContext } from 'react';
import Block from 'ui/Block';
import { BlockContext } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import styles from './RestrictionsTable.module.scss';
import LastRestrictions from '../LastRestrictions/LastRestrictions';
import { useGetRestrictionsByTableSize } from '../../hooks/useGetRestrictionsByTableSize';
import { useRestrictionOpenModal } from '../../hooks/useRestrictionOpenModal';
import CreateRestrictionForm from '../CreateRestrictionForm';
import { RestrictionsData } from '../../queries/generated/RestrictionsData';
import { RestrictionsBasicData } from '../../queries/generated/RestrictionsBasicData';

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const countPerPage = 10;

const RestrictionsTable = <T extends RestrictionsData | RestrictionsBasicData>({
  playerId,
  columns,
  routeToCustomPage,
  isFullScreenTable,
  shouldReturnToPrevPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const { openRestrictionItem } = useRestrictionOpenModal<T>();

  const { loadRestrictions, data, loading, error, fetchMore, refetch } =
    useGetRestrictionsByTableSize(playerId, isFullScreenTable);
  const restrictions = data?.player.restrictions;
  const lastRestrictions = data?.player.lastRestrictions;

  const restrictionsList =
    restrictions &&
    (restrictions.edges.map(({ node }) => node) as unknown as Array<T>);

  const hasNextPage = !!restrictions?.pageInfo.hasNextPage;

  useBlockComponentState({
    loadData: loadRestrictions,
    loading,
    error,
  });

  const refetchRestrictions = () => {
    refetch?.();
  };

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: restrictions?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [fetchMore, playerId, restrictions?.pageInfo.endCursor]
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  const openModal = (restriction: T, initiatorId: string) => {
    openRestrictionItem({ restriction, initiatorId });
  };

  const { dispatch: dispatchModal } = useContext(ModalContext);

  const addRestriction = (e: SyntheticEvent) => {
    e.stopPropagation();

    dispatchModal({
      type: ModalActions.Open,
      payload: {
        content: (
          <CreateRestrictionForm
            isFullScreenTable={isFullScreenTable}
            playerId={playerId}
          />
        ),
        title: 'Установка ограничения',
      },
    });
  };

  return (
    <Block
      title="Ограничения"
      id="playerRestrictions"
      headerContent={
        <div className={styles.headerContent}>
          <ActionButton actionType={ButtonType.Add} onClick={addRestriction} />
          <ActionButton
            actionType={ButtonType.Update}
            onClick={refetchRestrictions}
            hidden={!state.isOpened}
            disabled={state.isLoading}
          />
        </div>
      }
      shrinkLoaderWrapper={false}
      isEmpty={isListEmpty(restrictionsList)}
      emptyText="Игроку не устанавливались ограничения"
      shouldReturnToPrevPage={shouldReturnToPrevPage}
      extendedHeaderClassname={styles.extendedHeader}
      extendedHeader={
        state.isOpened && (
          <LastRestrictions lastRestrictions={lastRestrictions} />
        )
      }
    >
      {hasList(restrictionsList) && (
        <BlockTable
          onClickOnRow={openModal}
          columns={columns}
          data={restrictionsList}
          routeToCustomPage={routeToCustomPage}
          isFullScreenTable={isFullScreenTable}
        />
      )}
    </Block>
  );
};

export default RestrictionsTable;
