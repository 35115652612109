import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { useCreateDocuments as useCreateDocumentsMutation } from '../../../queries/generated/CreateDocuments';

interface UseCreateDocumentsParams {
  refetchDocuments: () => void;
}

export const useCreateDocuments = ({
  refetchDocuments,
}: UseCreateDocumentsParams) => {
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [createDocuments, { loading: createDocumentsLoading, error, data }] =
    useCreateDocumentsMutation();

  useEffect(() => {
    dispatchModal({
      type: ModalActions.DisableClose,
      payload: {
        isCloseDisabled: createDocumentsLoading,
      },
    });
  }, [createDocumentsLoading, dispatchModal]);

  useEffect(() => {
    if (data) {
      refetchDocuments();
      dispatchModal({
        type: ModalActions.Close,
      });

      toast.success('Документы загружены');
    }
  }, [data, dispatchModal, refetchDocuments]);

  useEffect(() => {
    if (error) {
      errorToast({
        header: 'Ошибка при\u00a0создании документа',
        text: error.message || 'Обратитесь к\u00a0администратору',
        toastId: 'createDocumentsError',
      });
    }
  }, [error]);

  return { createDocuments, createDocumentsLoading };
};
