import { FormikErrors } from 'formik';

export interface CreateMessageValues {
  playerId: string;
  text: string;
}

export interface CreateMessageErrors {
  text: string;
}

export const validate = (
  values: CreateMessageValues
): FormikErrors<CreateMessageErrors> => {
  const errors: FormikErrors<CreateMessageValues> = {};

  if (!values.text) {
    errors.text = 'Необходимо ввести сообщение';
  }

  return errors;
};
