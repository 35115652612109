import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetActiveAccountCurrencyVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetActiveAccountCurrency = { __typename: 'Query', player: { __typename: 'Player', activeAccountCurrency: CommonType.Currency, id: string } };


export const GetActiveAccountCurrencyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetActiveAccountCurrency"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeAccountCurrency"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetActiveAccountCurrency__
 *
 * To run a query within a React component, call `useGetActiveAccountCurrency` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAccountCurrency` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAccountCurrency({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActiveAccountCurrency(baseOptions: ApolloReactHooks.QueryHookOptions<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables> & ({ variables: GetActiveAccountCurrencyVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables>(GetActiveAccountCurrencyDocument, options);
      }
export function useGetActiveAccountCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables>(GetActiveAccountCurrencyDocument, options);
        }
export function useGetActiveAccountCurrencySuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables>(GetActiveAccountCurrencyDocument, options);
        }
export type GetActiveAccountCurrencyHookResult = ReturnType<typeof useGetActiveAccountCurrency>;
export type GetActiveAccountCurrencyLazyQueryHookResult = ReturnType<typeof useGetActiveAccountCurrencyLazyQuery>;
export type GetActiveAccountCurrencySuspenseQueryHookResult = ReturnType<typeof useGetActiveAccountCurrencySuspenseQuery>;
export type GetActiveAccountCurrencyQueryResult = Apollo.QueryResult<GetActiveAccountCurrency, GetActiveAccountCurrencyVariables>;