import { FC, useContext, useEffect } from 'react';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { ToggleBlock } from 'commonComponents/ToggleBlock';
import { Dropdown } from 'ui/Dropdown';
import { Tooltip } from 'ui/Tooltip';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useFormikContext } from 'formik';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { nbsp } from 'src/const';
import { ModalContext } from 'ui/Modal';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { suppressSymbols, transformValueToUppercase } from 'ui/Input/utils';
import {
  CreatePromoCodeModalSteps,
  FieldName,
  mapCreatePromoCodeFieldToTitle,
} from '../../const';
import { useGetReferralLinkMediaItemByUrl } from './hooks/useGetReferralLinkMediaItemByUrl';
import { useToggleBlockHandler } from './hooks/useToggleBlockHandler';
import {
  promoCodeUsageTypeOptions,
  promoCodeUsageTypeOptionsDisabledLink,
} from './const';
import { CreatePromoCodeValues } from '../../types';
import { useGoToNextStep } from './hooks/useGoToNextStep';
import { useValidatePromoCodeToken } from './hooks/useValidatePromoCodeToken';

export const CreatePromoCodeFormBaseStep: FC = () => {
  const { isValid, values, dirty, validateForm } =
    useFormikContext<CreatePromoCodeValues>();

  const { handleToggleBlockSwitch } = useToggleBlockHandler();
  const { getReferralLinkByUrl, getReferralLinkByUrlLoading } =
    useGetReferralLinkMediaItemByUrl();
  const { validatePromoCodeToken, validatePromoCodeTokenLoading } =
    useValidatePromoCodeToken();
  const { goToConnectionWithFreespinsAndBonusStep } = useGoToNextStep();

  const { state } = useContext(ModalContext);

  const shouldValidateForResetErrorsFromSecondStep =
    state.previousStep ===
      CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus &&
    (values[FieldName.ConnectionWithFreespins] ||
      values[FieldName.ConnectionWithBonus]);

  useEffect(() => {
    if (shouldValidateForResetErrorsFromSecondStep) {
      validateForm();
    }
  }, [
    shouldValidateForResetErrorsFromSecondStep,
    state.previousStep,
    validateForm,
  ]);

  return (
    <>
      <FormRowsWrapper>
        <FormRow isWide>
          <Input.Number
            name={FieldName.ActivationLimit}
            label={mapCreatePromoCodeFieldToTitle[FieldName.ActivationLimit]}
            placeholder={`Введите количество от${nbsp}1 до${nbsp}9999`}
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={4}
          />
        </FormRow>
        <FormRow isWide>
          <Input
            name={FieldName.Token}
            label={mapCreatePromoCodeFieldToTitle[FieldName.Token]}
            onInput={transformValueToUppercase}
            onKeyDown={suppressSymbols}
            maxLength={36}
            onBlur={validatePromoCodeToken}
            disabled={validatePromoCodeTokenLoading}
          />
        </FormRow>
        <FormRow isWide>
          <ToggleBlock
            name={FieldName.ConnectionWithReferralLink}
            label={
              mapCreatePromoCodeFieldToTitle[
                FieldName.ConnectionWithReferralLink
              ]
            }
            onToggle={handleToggleBlockSwitch}
            isOpen={values[FieldName.ConnectionWithReferralLink]}
          >
            <Input
              name={FieldName.ReferralLink}
              label={mapCreatePromoCodeFieldToTitle[FieldName.ReferralLink]}
              placeholder="Введите реферальную ссылку"
              onBlur={getReferralLinkByUrl}
            />
          </ToggleBlock>
        </FormRow>
        <FormRow isWide>
          <MediaCampaignDropdown
            name={FieldName.MediaCampaignId}
            label={mapCreatePromoCodeFieldToTitle[FieldName.MediaCampaignId]}
            disabled={values[FieldName.ConnectionWithReferralLink]}
          />
        </FormRow>
        <FormRow isWide>
          <Dropdown
            name={FieldName.Usage}
            label={mapCreatePromoCodeFieldToTitle[FieldName.Usage]}
            options={
              values[FieldName.ConnectionWithReferralLink]
                ? promoCodeUsageTypeOptions
                : promoCodeUsageTypeOptionsDisabledLink
            }
          />
        </FormRow>
      </FormRowsWrapper>
      <Tooltip
        tooltipContent={!isValid || !dirty ? 'Заполните все поля' : undefined}
        placement="right"
      >
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          disabled={!dirty}
          isLoading={getReferralLinkByUrlLoading}
          onClick={goToConnectionWithFreespinsAndBonusStep}
        >
          Далее
        </Button>
      </Tooltip>
    </>
  );
};
