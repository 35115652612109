import { FC, useContext, useEffect } from 'react';
import {
  depositStatusMap,
  paymentProviderMap,
  playerPaymentNameMap,
} from 'src/const';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { faArrowAltToBottom } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import ProgressBarWager from 'src/components/ProgressBarWager/ProgressBarWager';
import { ProgressBarWagerType } from 'src/components/ProgressBarWager/const';
import {
  DataCard,
  BoldSumWithWarningIconRowByCompare,
} from 'commonComponents/DataCard';
import { useAuth, UserPrivilege } from 'src/components/Auth';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { getStatusIcon } from '../../helpers';
import styles from './DepositItem.module.scss';
import ButtonsUpdateDeposit from './components/ButtonsUpdateDeposit';
import { useGetDeposit } from './queries/generated/GetDeposit';
import ConfirmDepositForm from './components/ConfirmDepositForm';
import { useDepositItem } from './useDepositItem';
import { DepositModalSteps } from './components/ButtonsUpdateDeposit/useButtonsUpdateDeposit';

export interface DepositProps {
  id: string;
}

const DepositItem: FC<DepositProps> = ({ id }) => {
  const auth = useAuth();
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { getSum } = useDepositItem();

  useEffect(() => {
    if (state.step === DepositModalSteps.ConfirmationStep) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Подтверждение депозита',
          subTitle: `#${id}`,
        },
      });
    } else {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          iconConfig: {
            icon: faArrowAltToBottom,
            iconColor: CardIconColor.GREEN,
          },
          title: `#${id}`,
          subTitle: 'Депозит',
        },
      });
    }
  }, [id, modalDispatch, state.step]);

  const { data, loading, error } = useGetDeposit({
    variables: {
      id,
      viewPaymentMethodAccount: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-deposit-error' },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const { deposit } = data;

  const {
    status,
    paymentMethodName,
    sum,
    createdAt,
    paymentMethod,
    paidSum,
    updatedAt,
    paymentProvider,
    wager,
  } = deposit;

  if (state.step === DepositModalSteps.ConfirmationStep) {
    return (
      <ConfirmDepositForm
        id={id}
        paidSum={sum.amount}
        paidCurrency={sum.currency}
      />
    );
  }

  const [icon, color] = getStatusIcon(status);

  return (
    <DataCard
      statusConfig={{
        status: depositStatusMap[status],
        iconConfig: {
          icon,
          iconColor: color,
        },
      }}
      customSubHeader={
        <ProgressBarWager
          wager={wager}
          type={ProgressBarWagerType.Full}
          className={styles.wager}
        />
      }
      rows={[
        {
          title: 'Провайдер',
          value: paymentProviderMap[paymentProvider] || paymentProvider,
        },
        {
          title: 'Метод',
          value: playerPaymentNameMap[paymentMethodName] || paymentMethodName,
        },
        {
          title: 'Номер счета',
          value: paymentMethod?.account || paymentMethod?.maskedAccount,
        },
        {
          title: 'Сумма',
          value: getSum({ amount: sum.amount, currency: sum.currency }),
        },
        paidSum && {
          title: 'Фактическая сумма',
          value: (
            <BoldSumWithWarningIconRowByCompare
              comparedSum={sum}
              renderedSum={paidSum}
            />
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <ButtonsUpdateDeposit depositId={id} depositStatus={status} />
      }
    />
  );
};

export default DepositItem;
