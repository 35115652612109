import { FC } from 'react';
import { RewardTariffType } from 'commonTypes';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'src/utils/storage';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import {
  GENERAL_REPORT_INPUT_NAME,
  GENERAL_REPORT_SEARCH_PLACEHOLDER,
  GENERAL_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { StatisticTabs } from '../StatisticTabs';
import { StatisticBlockHeaderContent } from '../StatisticBlockHeaderContent';
import { useGetRevShareGeneralReport } from './hooks/useGetRevShareGeneralReport';
import { RevShareGeneralReportTable } from './components/RevShareGeneralReportTable';
import { useGetExportRevShareGeneralReport } from './hooks/useGetExportRevShareGeneralReport';

interface Props {
  setTariffType: (type: RewardTariffType) => void;
}

export const Component: FC<Props> = withBlockProvider(({ setTariffType }) => {
  const { revShareGeneralReportList, refetch, hasTotalRow, loadReport } =
    useGetRevShareGeneralReport();

  const exportReportConfig = useGetExportRevShareGeneralReport();

  const listExist = hasList(revShareGeneralReportList);

  return (
    <>
      <SearchInput
        name={GENERAL_REPORT_INPUT_NAME}
        placeholder={GENERAL_REPORT_SEARCH_PLACEHOLDER}
        onSubmit={loadReport}
      />
      <Block
        title={GENERAL_STATISTIC_BLOCK_TITLE}
        id="statisticRevShare"
        headerContent={
          <StatisticBlockHeaderContent
            hasList={listExist}
            exportReportConfig={exportReportConfig}
            handleRefetchClick={refetch}
          />
        }
        subHeader={
          <StatisticTabs
            currentTabId={RewardTariffType.RevShare}
            setTariffType={setTariffType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(revShareGeneralReportList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {listExist && (
          <RevShareGeneralReportTable
            report={revShareGeneralReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    </>
  );
});

export const RevShareGeneralReport = (props: Props) => (
  <SearchContextProvider pageType={PageTypeStorage.GeneralReport}>
    <Component {...props} />
  </SearchContextProvider>
);
