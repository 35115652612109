import { FormikErrors } from 'formik';
import { CreatePromoCodeModalSteps, FieldName } from './const';
import { CreatePromoCodeValues } from './types';

export const validate = (
  values: CreatePromoCodeValues,
  formStep?: CreatePromoCodeModalSteps | null
): FormikErrors<CreatePromoCodeValues> => {
  const errors: FormikErrors<CreatePromoCodeValues> = {};

  if (!values[FieldName.Token]) {
    errors.token = 'Поле не может быть пустым';
  }

  if (!values[FieldName.Usage]) {
    errors.usage = 'Поле не может быть пустым';
  }

  if (values[FieldName.ConnectionWithReferralLink]) {
    if (!values[FieldName.ReferralLink]) {
      errors.referralLink = 'Поле не может быть пустым';
    } else if (values[FieldName.ReferralLinkId] === undefined) {
      errors.referralLink = 'Реферальная ссылка не существует';
    }
  } else if (!values[FieldName.MediaCampaignId]) {
    errors.mediaCampaignId = 'Поле не может быть пустым';
  }

  if (formStep === CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus) {
    if (values[FieldName.ConnectionWithFreespins]) {
      if (!values[FieldName.FreespinCount]) {
        errors.freespinCount = 'Поле не может быть пустым';
      }
    }

    if (values[FieldName.ConnectionWithBonus]) {
      if (!values[FieldName.BonusAmount]) {
        errors.bonusAmount = 'Поле не может быть пустым';
      }
    }
  }

  if (values[FieldName.InvalidToken]) {
    errors.token = 'Токен уже существует';
  }

  return errors;
};
