import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import {
  UpdateCompanyNote,
  useUpdateCompanyNote,
} from '../queries/generated/UpdateCompanyNote';

interface UseCompanyEditNoteResult {
  updateCompanyNoteHandler: (value: string) => void;
  updateCompanyNoteLoading: boolean;
  updateCompanyNoteResult?: UpdateCompanyNote | null;
}

interface UseCompanyEditNoteParams {
  id: string;
}

export const useCompanyEditNote = ({
  id,
}: UseCompanyEditNoteParams): UseCompanyEditNoteResult => {
  const onErrorHandler = (error: ApolloError) => {
    errorToast({
      header: error.name,
      text: error.message,
      toastId: 'updateCompanyNameErrorToast',
    });
  };

  const [
    updateCompanyNote,
    { data: updateCompanyNoteResult, loading: updateCompanyNoteLoading },
  ] = useUpdateCompanyNote({
    onError: onErrorHandler,
  });

  const updateCompanyNoteHandler = (updatedText: string) => {
    updateCompanyNote({
      variables: {
        input: {
          id,
          text: updatedText,
        },
      },
    });
  };

  return {
    updateCompanyNoteHandler,
    updateCompanyNoteResult,
    updateCompanyNoteLoading,
  };
};
