import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AssignPartnerApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AssignPartnerApplicationInput;
}>;


export type AssignPartnerApplication = { __typename: 'Mutation', assignPartnerApplication: { __typename: 'AssignPartnerApplicationPayload', partnerApplication: { __typename: 'PartnerApplication', id: string, status: SchemaTypes.PartnerApplicationStatus, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string } | null } } };


export const AssignPartnerApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssignPartnerApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssignPartnerApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignPartnerApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partnerApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AssignPartnerApplicationMutationFn = Apollo.MutationFunction<AssignPartnerApplication, AssignPartnerApplicationVariables>;

/**
 * __useAssignPartnerApplication__
 *
 * To run a mutation, you first call `useAssignPartnerApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPartnerApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPartnerApplication, { data, loading, error }] = useAssignPartnerApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignPartnerApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignPartnerApplication, AssignPartnerApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignPartnerApplication, AssignPartnerApplicationVariables>(AssignPartnerApplicationDocument, options);
      }
export type AssignPartnerApplicationHookResult = ReturnType<typeof useAssignPartnerApplication>;
export type AssignPartnerApplicationMutationResult = Apollo.MutationResult<AssignPartnerApplication>;
export type AssignPartnerApplicationMutationOptions = Apollo.BaseMutationOptions<AssignPartnerApplication, AssignPartnerApplicationVariables>;