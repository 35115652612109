import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { CompanyAdditionalData } from './CompanyAdditionalData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyAdditionalDataVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyAdditionalData = { __typename: 'Query', company: { __typename: 'Company', createdAt: string, email: string, id: string, name: string, trafficSource?: string | null, website?: string | null, contactForCommunication: { __typename: 'ContactForCommunication', type: CommonType.ContactForCommunicationType, value: string }, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus, player: { __typename: 'Player', email?: string | null, id: string, name: string, phone?: string | null } } | null } };


export const GetCompanyAdditionalDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyAdditionalData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CompanyAdditionalData"}}]}}]}},...CompanyAdditionalData.definitions]} as unknown as DocumentNode;

/**
 * __useGetCompanyAdditionalData__
 *
 * To run a query within a React component, call `useGetCompanyAdditionalData` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAdditionalData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAdditionalData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyAdditionalData(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables> & ({ variables: GetCompanyAdditionalDataVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables>(GetCompanyAdditionalDataDocument, options);
      }
export function useGetCompanyAdditionalDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables>(GetCompanyAdditionalDataDocument, options);
        }
export function useGetCompanyAdditionalDataSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables>(GetCompanyAdditionalDataDocument, options);
        }
export type GetCompanyAdditionalDataHookResult = ReturnType<typeof useGetCompanyAdditionalData>;
export type GetCompanyAdditionalDataLazyQueryHookResult = ReturnType<typeof useGetCompanyAdditionalDataLazyQuery>;
export type GetCompanyAdditionalDataSuspenseQueryHookResult = ReturnType<typeof useGetCompanyAdditionalDataSuspenseQuery>;
export type GetCompanyAdditionalDataQueryResult = Apollo.QueryResult<GetCompanyAdditionalData, GetCompanyAdditionalDataVariables>;