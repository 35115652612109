import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { Route as RouteEnum } from 'src/router/routes.const';
import { generatePath } from 'react-router';
import { useGetDepositsColumns } from './hooks/useGetDepositsColumns';
import DepositsTable from './DepositsTable/DepositsTable';

interface Props {
  id: string;
}

export const DepositsBlock: FC<Props> = withBlockProvider(({ id }) => {
  const { columns } = useGetDepositsColumns(false);

  return (
    <DepositsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.PLAYER_DEPOSITS, { id })}
      isFullScreenTable={false}
    />
  );
});
