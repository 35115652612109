import { CreateRewardTariffValues } from '../../types';

export const initialValues: CreateRewardTariffValues = {
  type: null,
  title: '',
  percent: null,
  baseline: 0,
  reward: 0,
  countries: [],
};
