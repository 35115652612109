import { useSearchParams } from 'react-router-dom';
import { StatisticDatePeriod } from 'src/const';
import { useEffect, useMemo } from 'react';
import { statisticPeriod } from '../const';

interface UseGetPeriodOfStatisticResult {
  from: string;
  to: string;
}

export const useGetPeriodOfStatistic = (): UseGetPeriodOfStatisticResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = useMemo(
    () => new URLSearchParams(searchParams),
    [searchParams]
  );

  const periodTo =
    searchParams.get(StatisticDatePeriod.To) || statisticPeriod.currentDay;
  const periodFrom =
    searchParams.get(StatisticDatePeriod.From) ||
    statisticPeriod.firstDayOfCurrentMonth;

  useEffect(() => {
    if (!searchParams.has(StatisticDatePeriod.From)) {
      newParams.set(StatisticDatePeriod.From, periodFrom);
      setSearchParams(newParams);
    }

    if (!searchParams.has(StatisticDatePeriod.To)) {
      newParams.set(StatisticDatePeriod.To, periodTo);
      setSearchParams(newParams);
    }
  }, [periodFrom, periodTo, newParams, setSearchParams, searchParams]);

  return {
    from: periodFrom,
    to: periodTo,
  };
};
