import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { faArrowAltToTop } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import { WithdrawalListData } from '../queries/generated/WithdrawalListData';
import { WithdrawalSidebar } from '../components/WithdrawalSidebar';

interface OpenModalParams {
  withdrawal: WithdrawalListData;
  initiatorId: string;
}

interface OpenModelType {
  openModal: (args: OpenModalParams) => void;
}

export const useOpenModal = (): OpenModelType => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    ({ withdrawal, initiatorId }: OpenModalParams) => {
      const { id } = withdrawal;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Вывод',
          content: <WithdrawalSidebar withdrawalId={id} />,
          iconConfig: {
            icon: faArrowAltToTop,
            iconColor: CardIconColor.RED,
          },
          initiatorId,
        },
      });
    },
    [dispatch]
  );

  return {
    openModal,
  };
};
