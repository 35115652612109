import { useMemo } from 'react';
import {
  faArrowAltToTop,
  faChartPieSimpleCircleDollar,
  faCoins,
  faPhotoFilmMusic,
  faPollPeople,
  faSignOutAlt,
  faSitemap,
  faUserCrown,
  faUserGroup,
  faUsers,
  faChartSimple,
} from '@fortawesome/pro-solid-svg-icons';
import { useAuth, UserPrivilege } from 'commonComponents/Auth';
import { Route } from '../../../router/routes.const';
import { MenuGroupItemData, MenuItemData } from '../../Menu/types';

interface PrivilegeBasedLinks {
  links: Array<MenuItemData | MenuGroupItemData>;
}

export const usePrivilegeBasedLinks = (): PrivilegeBasedLinks => {
  const auth = useAuth();

  const logOut = async (): Promise<void> => {
    try {
      auth.logOut();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const links = useMemo(() => {
    const linkList = [
      auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPlayer],
      }) && {
        title: 'Игроки',
        route: Route.PLAYERS,
        icon: faUsers,
      },

      auth.privileges.isSomeGranted({
        privileges: [
          UserPrivilege.ViewPartner,
          UserPrivilege.ViewPartnerApplication,
        ],
      }) && {
        title: 'Партнеры',
        group: [
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewPartner],
          }) && {
            title: 'Список партнеров',
            route: Route.PARTNERS,
          },
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewPartnerApplication],
          }) && {
            title: 'Заявки партнеров',
            route: Route.PARTNER_APPLICATIONS,
          },
        ].filter(Boolean),
        icon: faUserGroup,
      },

      auth.privileges.isSomeGranted({
        privileges: [
          UserPrivilege.ViewCompany,
          UserPrivilege.ViewCompanyApplication,
          UserPrivilege.ViewCompanyAffiliateApplication,
        ],
      }) && {
        title: 'Компании',
        group: [
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewCompany],
          }) && {
            title: 'Список компаний',
            route: Route.COMPANIES,
          },
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewCompanyAffiliateApplication],
          }) && {
            title: 'Заявки партнеров компании ',
            route: Route.COMPANY_AFFILIATE_APPLICATIONS,
          },
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewCompanyApplication],
          }) && {
            title: 'Заявки компаний',
            route: Route.COMPANY_APPLICATIONS,
          },
        ].filter(Boolean),
        icon: faSitemap,
      },

      auth.privileges.areEveryGranted({
        privileges: [
          UserPrivilege.ViewCpaRewardTariff,
          UserPrivilege.ViewRevShareRewardTariff,
        ],
      }) && {
        title: 'Тарифы',
        route: Route.REWARD_TARIFFS,
        icon: faChartPieSimpleCircleDollar,
      },

      auth.privileges.isSomeGranted({
        privileges: [
          UserPrivilege.ViewMediaItem,
          UserPrivilege.ViewMediaCampaign,
        ],
      }) && {
        title: 'Медиа',
        group: [
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewMediaCampaign],
          }) && {
            title: 'Медиа кампании',
            route: Route.MEDIA_CAMPAIGNS,
          },
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewMediaItem],
          }) && {
            title: 'Медиа элементы',
            route: Route.MEDIA_ITEMS,
          },
        ].filter(Boolean),
        icon: faPhotoFilmMusic,
      },

      auth.privileges.isSomeGranted({
        privileges: [
          UserPrivilege.ViewQualifiedReferral,
          UserPrivilege.ViewQualifiedReferralConfirmationSetting,
        ],
      }) && {
        title: 'CPA',
        group: [
          auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewQualifiedReferral],
          }) && {
            title: 'Квалифицированные игроки',
            route: Route.QUALIFIED_REFERRALS,
            icon: faPollPeople,
          },
          auth.privileges.areEveryGranted({
            privileges: [
              UserPrivilege.ViewQualifiedReferralConfirmationSetting,
            ],
          }) && {
            title: 'Настройки подтверждения',
            route: Route.QUALIFIED_REFERRAL_CONFIRMATION_SETTINGS,
          },
        ].filter(Boolean),
        icon: faPollPeople,
      },

      auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewReward],
      }) && {
        title: 'Вознаграждения',
        route: Route.REWARDS,
        icon: faCoins,
      },

      auth.privileges.areEveryGranted({
        privileges: [
          UserPrivilege.ViewCpaGeneralReport,
          UserPrivilege.ViewRevShareGeneralReport,
        ],
      }) && {
        title: 'Статистика',
        route: Route.STATISTIC,
        icon: faChartSimple,
      },

      auth.privileges.isSomeGranted({
        privileges: [
          UserPrivilege.ViewAdminWithdrawal,
          UserPrivilege.ViewWithdrawal,
        ],
      }) && {
        title: 'Выводы',
        route: Route.WITHDRAWAL,
        icon: faArrowAltToTop,
      },

      auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewAdmin],
      }) && {
        title: 'Администраторы',
        route: Route.ADMINS,
        icon: faUserCrown,
        isBottom: true,
      },

      {
        title: 'Выход',
        onClick: logOut,
        icon: faSignOutAlt,
        isBottom: true,
      },
    ].filter(Boolean) as Array<MenuItemData | MenuGroupItemData>;

    return linkList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.privileges, auth.user]);

  return {
    links,
  };
};
