export const isSubmitPayoutDisabled = (
  isRestrictionCheckboxChecked?: boolean,
  isLimitCheckboxChecked?: boolean
): boolean => {
  if (
    isRestrictionCheckboxChecked === undefined &&
    isLimitCheckboxChecked === undefined
  ) {
    return false;
  }

  if (isRestrictionCheckboxChecked === undefined) {
    return !isLimitCheckboxChecked;
  }

  if (isLimitCheckboxChecked === undefined) {
    return !isRestrictionCheckboxChecked;
  }

  if (isRestrictionCheckboxChecked && isLimitCheckboxChecked) {
    return false;
  }

  return true;
};
