import { useGetPromoCodeMediaItemByTokenLazyQuery } from 'src/queries/generated/GetPromoCodeMediaItemByToken';
import { FocusEvent } from 'react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { FieldName } from '../../../const';
import { CreateAffiliatePromoCodeValues } from '../../../types';

interface useGetPromoCodeMediaItemByTokenResult {
  validatePromoCodeToken: (event: FocusEvent<HTMLInputElement>) => void;
  validatePromoCodeTokenLoading?: boolean;
}

export const useValidatePromoCodeToken =
  (): useGetPromoCodeMediaItemByTokenResult => {
    const { values, validateForm, setFieldValue } =
      useFormikContext<CreateAffiliatePromoCodeValues>();

    const [getPromoCodeMediaItemByTokenQuery, { loading }] =
      useGetPromoCodeMediaItemByTokenLazyQuery();

    const validatePromoCodeToken = (event: FocusEvent<HTMLInputElement>) => {
      const token = event.target.value;

      if (token === '') {
        setFieldValue(FieldName.InvalidToken, false, true);

        return;
      }

      validateForm(values);

      getPromoCodeMediaItemByTokenQuery({
        variables: {
          token,
        },
        onCompleted: () => {
          setFieldValue(FieldName.InvalidToken, true, true);

          toast.error('Токен уже существует');
        },
        onError: () => {
          setFieldValue(FieldName.InvalidToken, false, true);
        },
      });
    };

    return {
      validatePromoCodeToken,
      validatePromoCodeTokenLoading: loading,
    };
  };
