import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { DatePicker, DatePickerHandleProps } from 'ui/DatePicker';
import Input from 'ui/Input';
import { FormFieldWrapper, FormFieldWrapperProps } from 'ui/FormFieldWrapper';
import classnames from 'classnames';
import { useField } from 'formik';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { ReactDatePickerProps } from 'react-datepicker';
import moment from 'moment';
import { DateFormat, formatDate } from '../../utils/formatDate';
import styles from './DatePickerWithInput.module.scss';

interface Props extends FormFieldWrapperProps, ReactDatePickerProps {
  onChange: (date: DatePickerHandleProps) => void;
  disabled?: boolean;
  placeholder?: string;
  name: string;
  isOpen?: boolean;
  openDateInitial?: Date;
}

const dateFormat = DateFormat.DefaultDate;

export const DatePickerWithInput: FC<Props> = ({
  onChange,
  name,
  label,
  disabled,
  wrapperClassName,
  openDateInitial,
  isOpen = false,
  placeholder = `Введите дату формата ${dateFormat}`,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const [startDate, setStartDate] = useState(field.value || null);

  const [isOpenState, setIsOpenState] = useState(isOpen);
  const [inputValue, setInputValue] = useState(
    startDate && formatDate(startDate, dateFormat)
  );

  const handleSubmit = (date: DatePickerHandleProps) => {
    setStartDate(date);
    onChange(date);
    if (date) {
      setIsOpenState(false);
    }
  };

  const handleChange = (date: DatePickerHandleProps) => {
    setStartDate(date);
  };

  useEffect(() => {
    setInputValue(startDate && formatDate(startDate, dateFormat, false));
  }, [startDate]);

  useEffect(() => {
    if (field.value === null) {
      setStartDate(field.value);
    }
  }, [field.value]);

  const handleChangeInput = (value: string) => {
    setInputValue(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      const formattedDate = moment(inputValue, dateFormat).toDate();

      if (!Number.isNaN(formattedDate.getDate())) {
        setStartDate(formattedDate);
      }
    }
  };

  const handleClickOutside = useClickOutside(() => {
    setIsOpenState(false);
    setStartDate(field.value);
  });

  return (
    <FormFieldWrapper
      name={name}
      label={label}
      wrapperClassName={classnames(wrapperClassName, styles.datePickerWrapper)}
    >
      <ClickOutside callback={handleClickOutside}>
        <Input
          onClick={() => setIsOpenState(true)}
          name="datePickerInput"
          disabled={disabled}
          onKeyDown={handleKeyDown}
          value={inputValue || ''}
          placeholder={placeholder}
          onChange={handleChangeInput}
          inputClassName={classnames(
            touched && styles.pickerTouched,
            touched && error && styles.pickerError
          )}
        />
        {isOpenState && (
          <DatePicker
            {...rest}
            openDateInitial={openDateInitial}
            className={styles.dropdown}
            onChange={handleChange}
            startDateInitial={startDate}
            onSubmit={handleSubmit}
          />
        )}
      </ClickOutside>
    </FormFieldWrapper>
  );
};
