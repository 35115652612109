import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext, useEffect } from 'react';
import { useCpaTariffDropdown } from 'commonComponents/CpaTariffDropdown/useCpaTariffDropdown';
import { useRevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown/useRevShareTariffDropdown';
import { MediaItemTypeName } from 'commonTypes';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { CreateReferralLinkInput } from '../validation';
import { PartnerApplicationModalSteps } from '../../../const';
import {
  getTariffType,
  getRevShareTariffPercent,
  getCpaTariffTitle,
} from '../helpers';
import { useGetGenerateReferralLinkCodeLazyQuery } from '../../../../../queries/generated/GetGenerateReferralLinkCode';

interface UseCreateReferralLinkParams {
  partnerId: string;
  partnerApplicationId: string;
  partnerName: string;
  setReferralLinkData: (value: ReferralLinkData) => void;
  referralLinkData?: ReferralLinkData | null;
}

export const useCreateReferralLink = ({
  partnerId,
  partnerName,
  partnerApplicationId,
  setReferralLinkData,
  referralLinkData,
}: UseCreateReferralLinkParams) => {
  const { dispatch } = useContext(ModalContext);
  const { cpaTariffs } = useCpaTariffDropdown();
  const { revShareTariffs } = useRevShareTariffDropdown();

  const [
    generateReferralLink,
    {
      data: referralLink,
      loading: referralLinkLoading,
      error: referralLinkError,
    },
  ] = useGetGenerateReferralLinkCodeLazyQuery();

  useEffect(() => {
    if (!referralLinkData?.code) {
      generateReferralLink();
    }
  }, [generateReferralLink, referralLinkData?.code]);

  const partnerInputValue = `#${partnerId} ${partnerName}`;
  const referralLinkCodeInitial =
    referralLinkData?.code || referralLink?.generateReferralLinkCode || '';

  const initialValues = {
    id: partnerApplicationId,
    partnerName: partnerInputValue,
    referralLinkCode: referralLinkCodeInitial,
    typeOfPaymentModel: getTariffType(referralLinkData),
    revShareTariffId: referralLinkData?.revShareTariff.id || '',
    cpaTariffId: referralLinkData?.cpaTariff?.id || '',
    typeMediaItem: MediaItemTypeName.ReferralLink,
    referralLinkName: referralLinkData?.referralLinkName || '',
    mediaCampaignId: referralLinkData?.mediaCampaignId || '',
  };

  const onSubmitForm = ({
    revShareTariffId,
    cpaTariffId,
    referralLinkCode,
    referralLinkName,
    mediaCampaignId,
  }: CreateReferralLinkInput) => {
    const revShareTariffPercent = getRevShareTariffPercent(
      revShareTariffId,
      revShareTariffs?.revShareRewardTariffs?.edges
    );
    const cpaTariffTitle = getCpaTariffTitle(
      cpaTariffId,
      cpaTariffs?.cpaRewardTariffs?.edges
    );

    setReferralLinkData({
      code: referralLinkCode,
      referralLinkName,
      mediaCampaignId,
      revShareTariff: {
        id: revShareTariffId,
        title: revShareTariffPercent
          ? `RevShare ${revShareTariffPercent}%`
          : '',
      },
      cpaTariff: cpaTariffId
        ? {
            id: cpaTariffId,
            title: cpaTariffTitle || '',
          }
        : null,
    });

    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: PartnerApplicationModalSteps.BaseStep,
      },
    });
  };

  const buttonText = referralLinkData !== undefined ? 'Сохранить' : 'Создать';
  const errorText =
    referralLinkData !== undefined
      ? 'Значения не были изменены'
      : 'Заполните все поля';

  return {
    initialValues,
    onSubmitForm,
    error: referralLinkError,
    loading: referralLinkLoading,
    buttonText,
    errorText,
  };
};
