import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetAvailableWithdrawalFiltersVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  usedFilters: Array<SchemaTypes.Scalars['WithdrawalFilterMember']['input']> | SchemaTypes.Scalars['WithdrawalFilterMember']['input'];
}>;


export type GetAvailableWithdrawalFilters = { __typename: 'Query', player: { __typename: 'Player', availableWithdrawalFilters: Array<CommonType.WithdrawalFilterMember>, id: string } };


export const GetAvailableWithdrawalFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAvailableWithdrawalFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"usedFilters"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WithdrawalFilterMember"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"availableWithdrawalFilters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"usedFilters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"usedFilters"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAvailableWithdrawalFilters__
 *
 * To run a query within a React component, call `useGetAvailableWithdrawalFilters` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableWithdrawalFilters` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableWithdrawalFilters({
 *   variables: {
 *      id: // value for 'id'
 *      usedFilters: // value for 'usedFilters'
 *   },
 * });
 */
export function useGetAvailableWithdrawalFilters(baseOptions: ApolloReactHooks.QueryHookOptions<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables> & ({ variables: GetAvailableWithdrawalFiltersVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables>(GetAvailableWithdrawalFiltersDocument, options);
      }
export function useGetAvailableWithdrawalFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables>(GetAvailableWithdrawalFiltersDocument, options);
        }
export function useGetAvailableWithdrawalFiltersSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables>(GetAvailableWithdrawalFiltersDocument, options);
        }
export type GetAvailableWithdrawalFiltersHookResult = ReturnType<typeof useGetAvailableWithdrawalFilters>;
export type GetAvailableWithdrawalFiltersLazyQueryHookResult = ReturnType<typeof useGetAvailableWithdrawalFiltersLazyQuery>;
export type GetAvailableWithdrawalFiltersSuspenseQueryHookResult = ReturnType<typeof useGetAvailableWithdrawalFiltersSuspenseQuery>;
export type GetAvailableWithdrawalFiltersQueryResult = Apollo.QueryResult<GetAvailableWithdrawalFilters, GetAvailableWithdrawalFiltersVariables>;