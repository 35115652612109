import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import DepositItem from '../components/DepositItem';

interface PlayerData {
  openDepositItem: (id: string, initiatorId: string) => void;
}

export const useDepositOpenModal = (): PlayerData => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openDepositItem = useCallback(
    async (id: string, initiatorId: string) => {
      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          content: <DepositItem id={id} />,
          initiatorId,
        },
      });
    },
    [modalDispatch]
  );

  return {
    openDepositItem,
  };
};
