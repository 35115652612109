import { FC, useEffect } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useFormikContext } from 'formik';
import { TextOverflow } from 'ui/TextOverflow';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';
import { promoCodeUsageTypeMap } from '../../../../../../../../../../const';
import { CreatePromoCodeValues } from '../../../../types';
import { useGetMediaCampaignIfReferralLinkUndefined } from './hooks/useGetMediaCampaignIfReferralLinkUndefined';
import styles from './DataCardMain.module.scss';

export const DataCardMain: FC = () => {
  const { values } = useFormikContext<CreatePromoCodeValues>();

  const { getMediaCampaignIfReferralLinkUndefined } =
    useGetMediaCampaignIfReferralLinkUndefined();

  useEffect(() => {
    if (
      !values[FieldName.ReferralLink] &&
      !values[FieldName.MediaCampaignName] &&
      values[FieldName.MediaCampaignId]
    ) {
      getMediaCampaignIfReferralLinkUndefined(
        values[FieldName.MediaCampaignId]
      );
    }
  }, [getMediaCampaignIfReferralLinkUndefined, values]);

  return (
    <DataCard
      containerClassName={styles.container}
      rows={[
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.ActivationLimit],
          value: values[FieldName.ActivationLimit],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.Token],
          value: values[FieldName.Token],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.MediaCampaignName],
          value: values[FieldName.MediaCampaignName],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.MediaCampaignUrl],
          value: values[FieldName.MediaCampaignUrl],
        },
        values[FieldName.Usage] && {
          title: mapCreatePromoCodeFieldToTitle[FieldName.Usage],
          value: promoCodeUsageTypeMap[values[FieldName.Usage]],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.ReferralLink],
          value: values[FieldName.ReferralLink] && (
            <div className={styles.referralLink}>
              <TextOverflow text={values[FieldName.ReferralLink]} />
            </div>
          ),
        },
      ]}
    />
  );
};
