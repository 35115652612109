import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { Checkbox } from 'ui/Checkbox';
import { RewardTariffType } from 'commonTypes';
import { Formik } from 'formik';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import styles from './StatisticBlockHeaderContent.module.scss';
import { StatisticDatePeriod } from './components/StatisticDatePeriod';

const SHOW_ALL_CPA_FIELD = 'showAllCpa';

interface Props {
  handleRefetchClick: () => void;
  rewardTariffType?: RewardTariffType;
  showAllCpa?: (value: boolean) => void;
  exportReportConfig: {
    exportReportLoading: boolean;
    exportReport: () => void;
    exportPrivilege: UserPrivilege;
  };
  hasList: boolean;
}
export const StatisticBlockHeaderContent = ({
  handleRefetchClick,
  showAllCpa,
  rewardTariffType,
  exportReportConfig,
  hasList,
}: Props) => {
  const { state } = useContext(BlockContext);
  const { exportReport, exportReportLoading, exportPrivilege } =
    exportReportConfig;

  return (
    <div className={styles.headerContent}>
      <StatisticDatePeriod />
      {rewardTariffType === RewardTariffType.Cpa && (
        <Formik
          initialValues={{ [SHOW_ALL_CPA_FIELD]: false }}
          onSubmit={() => undefined}
        >
          <Checkbox
            isDisabled={state.isLoading || exportReportLoading}
            label="Показать все CPA"
            name={SHOW_ALL_CPA_FIELD}
            onChange={(value) => {
              showAllCpa?.(value);
            }}
          />
        </Formik>
      )}
      <Privilege privileges={[exportPrivilege]}>
        <ActionButton
          actionType={ButtonType.Archive}
          loading={exportReportLoading}
          disabled={!hasList || state.isLoading}
          onClick={exportReport}
          dataTestId="export-statistic-report-btn"
        />
      </Privilege>
      <ActionButton
        actionType={ButtonType.Update}
        disabled={state.isLoading || exportReportLoading}
        onClick={() => handleRefetchClick()}
        dataTestId="refetch-statistic-report-btn"
      />
    </div>
  );
};
