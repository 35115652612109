import { RewardTariffType } from 'commonTypes';
import { nbsp, rewardTariffMap } from 'src/const';

export const REV_SHARE_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}партнёру компании ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const REV_SHARE_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER =
  'Поиск реферального кода';
export const REV_SHARE_COMPANY_AFFILIATE_REPORT_INPUT_NAME =
  'revShareCompanyAffiliateReportSearch';
