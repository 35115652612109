import { useCpaTariffDropdown } from 'commonComponents/CpaTariffDropdown/useCpaTariffDropdown';
import { useRevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown/useRevShareTariffDropdown';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { CreateReferralLinkInput } from '../validation';
import { getRevShareTariffPercent, getCpaTariffTitle } from '../helpers';

interface UseSetReferralLinkParams {
  setReferralLinkMediaItem: (value: ReferralLinkData) => void;
}

export const useSetReferralLink = ({
  setReferralLinkMediaItem,
}: UseSetReferralLinkParams) => {
  const { cpaTariffs } = useCpaTariffDropdown();
  const { revShareTariffs } = useRevShareTariffDropdown();

  const setReferralLink = ({
    revShareTariffId,
    cpaTariffId,
    referralLinkCode,
    referralLinkName,
    mediaCampaignId,
  }: CreateReferralLinkInput) => {
    const revShareTariffPercent = getRevShareTariffPercent(
      revShareTariffId,
      revShareTariffs?.revShareRewardTariffs?.edges
    );
    const cpaTariffTitle = getCpaTariffTitle(
      cpaTariffId,
      cpaTariffs?.cpaRewardTariffs?.edges
    );

    setReferralLinkMediaItem({
      code: referralLinkCode,
      referralLinkName,
      mediaCampaignId,
      revShareTariff: {
        id: revShareTariffId,
        title: revShareTariffPercent
          ? `RevShare ${revShareTariffPercent}%`
          : '',
      },
      cpaTariff: cpaTariffId
        ? {
            id: cpaTariffId,
            title: cpaTariffTitle || '',
          }
        : null,
    });
  };

  return { setReferralLink };
};
