interface Props {
  value: string;
  type?: Intl.DisplayNamesType;
  locales?: Array<string>;
}

export const getDisplayName = ({
  value,
  type = 'language',
  locales = ['ru'],
}: Props) => {
  const name = new Intl.DisplayNames(locales, { type });

  return name.of(value);
};
