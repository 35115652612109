import { FC } from 'react';
import styles from './Error.module.scss';

interface Props {
  header: string;
  text?: string;
}

const ErrorContent: FC<Props> = ({ header, text }) => (
  <>
    <div className={styles.header}>{header}</div>
    {text && <div className={styles.text}>{text}</div>}
  </>
);

export default ErrorContent;
