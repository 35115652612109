import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ApproveAllQualifiedReferralsVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type ApproveAllQualifiedReferrals = { __typename: 'Mutation', approveAllQualifiedReferrals: { __typename: 'ApproveAllQualifiedReferralsPayload', void?: any | null } };


export const ApproveAllQualifiedReferralsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApproveAllQualifiedReferrals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approveAllQualifiedReferrals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type ApproveAllQualifiedReferralsMutationFn = Apollo.MutationFunction<ApproveAllQualifiedReferrals, ApproveAllQualifiedReferralsVariables>;

/**
 * __useApproveAllQualifiedReferrals__
 *
 * To run a mutation, you first call `useApproveAllQualifiedReferrals` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAllQualifiedReferrals` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAllQualifiedReferrals, { data, loading, error }] = useApproveAllQualifiedReferrals({
 *   variables: {
 *   },
 * });
 */
export function useApproveAllQualifiedReferrals(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveAllQualifiedReferrals, ApproveAllQualifiedReferralsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ApproveAllQualifiedReferrals, ApproveAllQualifiedReferralsVariables>(ApproveAllQualifiedReferralsDocument, options);
      }
export type ApproveAllQualifiedReferralsHookResult = ReturnType<typeof useApproveAllQualifiedReferrals>;
export type ApproveAllQualifiedReferralsMutationResult = Apollo.MutationResult<ApproveAllQualifiedReferrals>;
export type ApproveAllQualifiedReferralsMutationOptions = Apollo.BaseMutationOptions<ApproveAllQualifiedReferrals, ApproveAllQualifiedReferralsVariables>;