import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetPartnerOutgoingMoneyTransfersLazyQuery } from '../queries/generated/GetPartnerOutgoingMoneyTransfers';
import { useGetPartnerOutgoingMoneyTransfersBlockLazyQuery } from '../queries/generated/GetPartnerOutgoingMoneyTransfersBlock';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetOutgoingMoneyTransfersByTableSize = (
  partnerId: string,
  isFullScreenTable: boolean
) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPartnerOutgoingMoneyTransfers = isFullScreenTable
    ? useGetPartnerOutgoingMoneyTransfersLazyQuery
    : useGetPartnerOutgoingMoneyTransfersBlockLazyQuery;

  const [
    loadOutgoingMoneyTransfers,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetPartnerOutgoingMoneyTransfers({
    variables: {
      id: partnerId,
      first: countPerPageByTableSize,
    },
  });

  return {
    loadOutgoingMoneyTransfers,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
