import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetCompanyMediaCampaignsBlockLazyQuery } from '../../../queries/generated/GetCompanyMediaCampaignsBlock';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface UseGetCompanyMediaCampaignsParams {
  companyId: string;
  isFullScreenTable?: boolean;
}

export const useGetCompanyMediaCampaigns = <T>({
  companyId,
  isFullScreenTable,
}: UseGetCompanyMediaCampaignsParams) => {
  const [
    loadCompanyMediaCampaignsList,
    { data, error, loading, fetchMore, refetch },
  ] = useGetCompanyMediaCampaignsBlockLazyQuery({
    variables: {
      id: companyId,
      first: isFullScreenTable
        ? getCountByWindowHeight(cellHeight)
        : COUNT_PER_PAGE,
    },
  });

  const companyMediaCampaigns = data?.company.mediaCampaigns;
  const endCursor = companyMediaCampaigns?.pageInfo.endCursor;
  const hasNextPage = companyMediaCampaigns?.pageInfo.hasNextPage;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  const companyMediaCampaignsList = useMemo(
    () =>
      companyMediaCampaigns?.edges.map(
        ({ node }) => node
      ) as unknown as Array<T>,
    [companyMediaCampaigns?.edges]
  );

  return {
    loadCompanyMediaCampaignsList,
    data,
    loading,
    error,
    hasNextPage,
    refetch,
    fetchMoreCallback,
    companyMediaCampaignsList,
  };
};
