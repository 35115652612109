import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { TournamentRebuysData } from './TournamentRebuysData';
import { SumData } from '../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetTournamentRebuyVariables = SchemaTypes.Exact<{
  tournamentRebuyId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetTournamentRebuy = { __typename: 'Query', tournamentRebuy: { __typename: 'TournamentRebuy', createdAt: string, id: string, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, tournament: { __typename: 'CashTournament', id: string, name: string } | { __typename: 'FreespinTournament', id: string, name: string } | { __typename: 'XTournament', id: string, name: string } } };


export const GetTournamentRebuyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetTournamentRebuy"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tournamentRebuyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tournamentRebuy"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tournamentRebuyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"TournamentRebuysData"}}]}}]}},...TournamentRebuysData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetTournamentRebuy__
 *
 * To run a query within a React component, call `useGetTournamentRebuy` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentRebuy` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentRebuy({
 *   variables: {
 *      tournamentRebuyId: // value for 'tournamentRebuyId'
 *   },
 * });
 */
export function useGetTournamentRebuy(baseOptions: ApolloReactHooks.QueryHookOptions<GetTournamentRebuy, GetTournamentRebuyVariables> & ({ variables: GetTournamentRebuyVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTournamentRebuy, GetTournamentRebuyVariables>(GetTournamentRebuyDocument, options);
      }
export function useGetTournamentRebuyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTournamentRebuy, GetTournamentRebuyVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTournamentRebuy, GetTournamentRebuyVariables>(GetTournamentRebuyDocument, options);
        }
export function useGetTournamentRebuySuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetTournamentRebuy, GetTournamentRebuyVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetTournamentRebuy, GetTournamentRebuyVariables>(GetTournamentRebuyDocument, options);
        }
export type GetTournamentRebuyHookResult = ReturnType<typeof useGetTournamentRebuy>;
export type GetTournamentRebuyLazyQueryHookResult = ReturnType<typeof useGetTournamentRebuyLazyQuery>;
export type GetTournamentRebuySuspenseQueryHookResult = ReturnType<typeof useGetTournamentRebuySuspenseQuery>;
export type GetTournamentRebuyQueryResult = Apollo.QueryResult<GetTournamentRebuy, GetTournamentRebuyVariables>;