import { CompanyAffiliateApplicationStatus } from 'generatedGraphql';
import { GetCompanyAffiliateApplication } from '../queries/generated/GetCompanyAffiliateApplication';
import {
  AffiliateInApproveProcessStatus,
  AffiliatesInProgress,
} from '../store';
import { MAX_AFFILIATE_COUNT_TO_TITLE_TAB } from '../const';

interface GetAffiliateCountParams {
  companyAffiliateApplication: GetCompanyAffiliateApplication['companyAffiliateApplication'];
  affiliatesInProgress: AffiliatesInProgress | null;
  moreThanMaxCount: boolean;
}

export const getAffiliateCount = ({
  companyAffiliateApplication,
  affiliatesInProgress,
  moreThanMaxCount,
}: GetAffiliateCountParams) => {
  const { items } = companyAffiliateApplication;
  const affiliatesCount = items.length || 0;

  if (
    companyAffiliateApplication.status ===
    CompanyAffiliateApplicationStatus.Pending
  ) {
    return moreThanMaxCount
      ? MAX_AFFILIATE_COUNT_TO_TITLE_TAB
      : affiliatesCount;
  }

  if (
    companyAffiliateApplication.status ===
    CompanyAffiliateApplicationStatus.InProgress
  ) {
    return affiliatesInProgress
      ? Object.values(affiliatesInProgress).filter(
          (item) => item === AffiliateInApproveProcessStatus.InProcess
        )?.length
      : null;
  }

  return null;
};
