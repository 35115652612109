import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import styles from './SidebarLoader.module.scss';

interface Props {
  loading: boolean | null;
}

export const SidebarLoader: FC<PropsWithChildren<Props>> = ({
  loading,
  children,
}) => {
  if (loading === null) {
    return <div>{children}</div>;
  }

  return (
    <div
      className={classnames(styles.root, loading && styles.loadingRootState)}
    >
      {children}
      {loading && (
        <>
          <div className={styles.loadingBackground} />
          <div className={styles.loadingContainer}>
            <FontAwesomeIcon
              className={styles.loader}
              icon={faSpinnerThird}
              spin
            />
          </div>
        </>
      )}
    </div>
  );
};
