import { nbsp } from 'src/const';

export enum ColumnId {
  Affiliate = 'affiliate',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Affiliate]: 'Партнёр / Компания',
  [ColumnId.Hits]: 'Хиты',
  [ColumnId.Hosts]: 'Хосты',
  [ColumnId.Registrations]: 'Регистрации',
  [ColumnId.FirstDeposits]: 'Первые депозиты',
  [ColumnId.HostToRegConversion]: 'Host2reg',
  [ColumnId.RegToDepConversion]: 'Reg2dep',
  [ColumnId.AllDeposit]: 'Все депозиты',
  [ColumnId.AverageDeposit]: 'Средний депозит',
  [ColumnId.AllRedeposit]: 'Все редепозиты',
  [ColumnId.Redeposits]: 'Количество редепозитов',
  [ColumnId.NewDeposit]: 'Новые депозиты',
  [ColumnId.IncomingMoneyTransfer]: 'Входящие переводы',
  [ColumnId.Withdrawal]: 'Выводы',
  [ColumnId.NewWithdrawal]: 'Новые выводы',
  [ColumnId.NewProfit]: 'Новый доход',
  [ColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [ColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [ColumnId.AdminFee]: 'Admin fee',
  [ColumnId.Bonus]: 'Бонусы',
  [ColumnId.TournamentPrize]: 'Призовые',
  [ColumnId.Jackpot]: 'Джекпоты',
  [ColumnId.NGR]: 'NGR',
  [ColumnId.Profit]: 'Доход',
  [ColumnId.Upcoming]: 'Вознаграждение',
  [ColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  ColumnId.Affiliate,
  ColumnId.Hits,
  ColumnId.Hosts,
  ColumnId.Registrations,
  ColumnId.FirstDeposits,
  ColumnId.HostToRegConversion,
  ColumnId.RegToDepConversion,
  ColumnId.AllDeposit,
  ColumnId.AverageDeposit,
  ColumnId.AllRedeposit,
  ColumnId.Redeposits,
  ColumnId.NewDeposit,
  ColumnId.IncomingMoneyTransfer,
  ColumnId.Withdrawal,
  ColumnId.NewWithdrawal,
  ColumnId.NewProfit,
  ColumnId.DepositFee,
  ColumnId.WithdrawalFee,
  ColumnId.AdminFee,
  ColumnId.Bonus,
  ColumnId.TournamentPrize,
  ColumnId.Jackpot,
  ColumnId.NGR,
  ColumnId.Profit,
  ColumnId.Upcoming,
  ColumnId.RewardPaid,
];
