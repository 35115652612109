import { FC } from 'react';
import classnames from 'classnames';
import styles from './CounterTabTitle.module.scss';

interface Props {
  withPlus: boolean;
  isActiveTab: boolean;
  count?: number;
}

export const CounterTabTitle: FC<Props> = ({
  count,
  withPlus,
  isActiveTab,
}) => {
  if (!count) {
    return null;
  }

  return (
    <div className={classnames(styles.counter, isActiveTab && styles.active)}>
      <span>{count}</span>
      {withPlus && <span className={styles.plus}>+</span>}
    </div>
  );
};
