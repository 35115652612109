export enum PageTypeStorage {
  AdminPage = '@adminPageStorage',
  PlayerPage = '@playerPageStorage',
  RevShareCompanyReport = '@revShareCompanyReportPageStorage',
  CpaCompanyReport = '@cpaCompanyReportPageStorage',
  RevShareCompanyAffiliateReport = '@revShareCompanyAffiliateReportPageStorage',
  CpaCompanyAffiliateReport = '@cpaCompanyAffiliateReportPageStorage',
  RevShareReferralLinkReport = '@revShareReferralLinkReportPageStorage',
  CPAReferralLinkReport = '@cpaReferralLinkReportPageStorage',
  CPAPartnerReport = '@cpaPartnerReportPageStorage',
  RevSharePartnerReport = '@revShareReportPageStorage',
  PartnerPage = '@partnerPageStorage',
  CompanyPage = '@companyPageStorage',
  WithdrawalPage = '@withdrawalPageStorage',
  GeneralReport = '@generalReportPageStorage',
}
