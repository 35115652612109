import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { CreateSingInRestriction } from 'src/components/Ban/types';
import { SignInRestrictionType } from 'commonComponents/Ban/consts';
import { GetPlayerDataDocument } from '../../../../queries/generated/GetPlayerData';
import { useCreateSingInRestriction } from './queries/generated/CreateSingInRestriction';
import { useDeletePlayer } from './queries/generated/DeletePlayer';
import { useCancelSignInRestriction } from './queries/generated/CancelSignInRestriction';
import { useUndeletePlayer } from './queries/generated/UndeletePlayer';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';

interface Args {
  id: string;
}

interface DeletePlayer {
  reason: string;
}

interface Return {
  loading: boolean;
  onCreateSingInRestriction: ({
    expiredAt,
    expired,
    reason,
    signInRestrictionType,
  }: CreateSingInRestriction) => void;
  onDeletePlayer: ({ reason }: DeletePlayer) => void;
  onCancelSignInRestriction: () => void;
  onUnDeleted: () => void;
  error?: ApolloError;
}

export const useChangePlayerState = ({ id }: Args): Return => {
  const { dispatch } = useContext(ModalContext);

  const [
    createSingInRestriction,
    {
      data: createSingInRestrictionResult,
      loading: createSingInRestrictionLoading,
      error: createSingInRestrictionError,
    },
  ] = useCreateSingInRestriction({
    refetchQueries: [{ query: GetPlayerDataDocument, variables: { id } }],
  });
  const onCreateSingInRestriction = async ({
    expiredAt,
    reason,
    signInRestrictionType,
  }: CreateSingInRestriction) => {
    const formattedDate = expiredAt
      ? formatDate(expiredAt, DateFormat.FullDateOld)
      : expiredAt;

    await createSingInRestriction({
      variables: {
        input: {
          expiredAt:
            signInRestrictionType === SignInRestrictionType.Protected
              ? null
              : formattedDate,
          reason,
          protected: signInRestrictionType === SignInRestrictionType.Protected,
          playerId: id,
        },
      },
    });
  };

  useEffect(() => {
    if (createSingInRestrictionResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Игрок заблокирован');
    }
  }, [createSingInRestrictionResult, dispatch]);

  const [
    deletePlayer,
    {
      data: deletePlayerResult,
      loading: deletePlayerLoading,
      error: deletePlayerError,
    },
  ] = useDeletePlayer();
  const onDeletePlayer = async ({ reason }: DeletePlayer) => {
    await deletePlayer({ variables: { input: { id, reason } } });
  };

  useEffect(() => {
    if (deletePlayerResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Игрок удалён');
    }
  }, [deletePlayerResult, dispatch]);

  const [
    cancelSignInRestriction,
    {
      data: cancelSignInRestrictionResult,
      loading: cancelSignInRestrictionLoading,
      error: cancelSignInRestrictionError,
    },
  ] = useCancelSignInRestriction({
    refetchQueries: [{ query: GetPlayerDataDocument, variables: { id } }],
  });
  const onCancelSignInRestriction = async () => {
    await cancelSignInRestriction({ variables: { input: { playerId: id } } });
  };

  useEffect(() => {
    if (cancelSignInRestrictionResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Игрок разблокирован');
    }
  }, [cancelSignInRestrictionResult, dispatch]);

  const [
    undeletePlayer,
    {
      data: undeletePlayerResult,
      loading: undeletePlayerLoading,
      error: undeletePlayerError,
    },
  ] = useUndeletePlayer();
  const onUnDeleted = async () => {
    await undeletePlayer({ variables: { input: { id } } });
  };

  useEffect(() => {
    if (undeletePlayerResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Удаление игрока отменено');
    }
  }, [undeletePlayerResult, dispatch]);

  return {
    loading:
      createSingInRestrictionLoading ||
      deletePlayerLoading ||
      cancelSignInRestrictionLoading ||
      undeletePlayerLoading,
    onCreateSingInRestriction,
    onDeletePlayer,
    onCancelSignInRestriction,
    onUnDeleted,
    error:
      createSingInRestrictionError ||
      deletePlayerError ||
      cancelSignInRestrictionError ||
      undeletePlayerError,
  };
};
