import { useFormikContext } from 'formik';
import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { FieldName } from '../../../const';
import { CreateAffiliatePromoCodeValues } from '../../../types';

interface useToggleBlockHandlersResult {
  onFreespinBlockToggleHandler: (isBlockOpen: boolean) => void;
  onBonusBlockToggleHandler: (isBlockOpen: boolean) => void;
}

export const useToggleBlockHandlers = (): useToggleBlockHandlersResult => {
  const { setFieldValue, values, setTouched, validateForm, initialValues } =
    useFormikContext<CreateAffiliatePromoCodeValues>();

  const onFreespinBlockToggleHandler = (isBlockOpen: boolean) => {
    setFieldValue(FieldName.ConnectionWithFreespins, isBlockOpen)
      .then(() => validateForm())
      .then(() => {
        if (!isBlockOpen) {
          // Reset values if block is closed
          setFieldValue(
            FieldName.FreespinGameId,
            initialValues[FieldName.FreespinGameId]
          );
          setFieldValue(
            FieldName.FreespinCurrency,
            initialValues[FieldName.FreespinCurrency]
          );
          setFieldValue(
            FieldName.FreespinCount,
            initialValues[FieldName.FreespinCount]
          );
          setFieldValue(
            FieldName.FreespinWager,
            initialValues[FieldName.FreespinWager]
          );
          setFieldValue(
            FieldName.FreespinMinBalance,
            initialValues[FieldName.FreespinMinBalance]
          );

          // Reset touched fields for clear validation process
          setTouched({
            [FieldName.FreespinCount]: false,
            // Save touched for show error state if bonus block still open
            ...(values[FieldName.ConnectionWithBonus]
              ? {
                  [FieldName.BonusAmount]: true,
                }
              : null),
          });
        }

        return validateForm();
      })
      .catch((error) => {
        errorToast({
          header: `Связь с${nbsp}фриспинами`,
          text: error,
          toastId: 'link-with-freespins',
        });
      });
  };

  const onBonusBlockToggleHandler = (isBlockOpen: boolean) => {
    setFieldValue(FieldName.ConnectionWithBonus, isBlockOpen)
      .then(() => validateForm())
      .then(() => {
        if (!isBlockOpen) {
          // Reset values if block is closed
          setFieldValue(
            FieldName.BonusAmount,
            initialValues[FieldName.BonusAmount]
          );
          setFieldValue(
            FieldName.BonusCurrency,
            initialValues[FieldName.BonusCurrency]
          );
          setFieldValue(
            FieldName.BonusWager,
            initialValues[FieldName.BonusWager]
          );
          setFieldValue(
            FieldName.BonusCashout,
            initialValues[FieldName.BonusCashout]
          );
          setFieldValue(
            FieldName.BonusMinBalance,
            initialValues[FieldName.BonusMinBalance]
          );

          // Reset touched fields for clear validation process
          setTouched({
            [FieldName.BonusAmount]: false,
            // Save touched for show error state if freespin block still open
            ...(values[FieldName.ConnectionWithFreespins]
              ? {
                  [FieldName.FreespinCount]: true,
                }
              : null),
          });
        }

        return validateForm();
      })
      .catch((error) => {
        errorToast({
          header: `Связь с${nbsp}бонусом`,
          text: error,
          toastId: 'link-with-bonus',
        });
      });
  };

  return {
    onFreespinBlockToggleHandler,
    onBonusBlockToggleHandler,
  };
};
