import { FormikErrors } from 'formik';
import { AcceptPaymentInput, Sum } from 'generatedGraphql';

export const validate = (
  values: AcceptPaymentInput,
  paymentPaidSum: Sum
): FormikErrors<AcceptPaymentInput> => {
  const errors: FormikErrors<AcceptPaymentInput> = {};

  if (!values.amount) {
    errors.amount = 'Введите фактическую сумму';
  }

  if (paymentPaidSum.amount < values.amount) {
    errors.amount = 'Не может быть больше суммы платежа';
  }

  return errors;
};
