import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { MultilineCellWithLink } from 'src/ui/Table/components/MultilineCellWithLink';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { Route } from 'src/router/routes.const';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { twoEmDash } from 'src/const';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { QualifiedReferralReviewResult } from '../components/QualifiedReferralReviewResult';
import { QualifiedReferralData } from '../../../queries/generated/QualifiedReferralData';
import { QualifiedReferralActionButtons } from '../components/QualifiedReferralActionButtons';
import { SuspiciousCellWrapper } from '../components/SuspiciousCellWrapper';
import { getAffiliateInfo, getQualifiedReferralStatus } from '../helpers';

const columnHelper = createColumnHelper<QualifiedReferralData>();

const columnsSource: ColumnsSource<QualifiedReferralData> = [
  columnHelper.accessor(ColumnId.SuspectState, {
    id: ColumnId.SuspectState,
    header: mapColumnIdToName[ColumnId.SuspectState],
    cell: ({ row }) => {
      const { id, suspectState } = row.original;

      return <SuspiciousCellWrapper id={id} suspectState={suspectState} />;
    },
  }),
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      const { id, name, email, phone } = row.original.player;

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          route={`${Route.PLAYERS}/${id}`}
          subTitle={email || phone}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      const { email, name, route } = getAffiliateInfo(row.original.affiliate);

      return mayBeNullCell(
        email && (
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />
        ),
        twoEmDash
      );
    },
  }),
  columnHelper.accessor(ColumnId.ReferralCode, {
    id: ColumnId.ReferralCode,
    header: mapColumnIdToName[ColumnId.ReferralCode],
  }),
  columnHelper.accessor(ColumnId.CPATariff, {
    id: ColumnId.CPATariff,
    header: mapColumnIdToName[ColumnId.CPATariff],
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const [iconConfig, statusText] = getQualifiedReferralStatus(
        row.original.status
      );

      return (
        <MultilineCellWithIcon iconConfig={iconConfig} title={statusText} />
      );
    },
  }),
  columnHelper.accessor(ColumnId.ReviewResult, {
    id: ColumnId.ReviewResult,
    header: mapColumnIdToName[ColumnId.ReviewResult],
    cell: ({ row }) => (
      <QualifiedReferralReviewResult reviewResult={row.original.review} />
    ),
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.Action, {
    id: ColumnId.Action,
    header: mapColumnIdToName[ColumnId.Action],
    cell: ({ row }) => (
      <QualifiedReferralActionButtons qualifiedReferral={row.original} />
    ),
  }),
];

export const useGetQualifiedReferralsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
