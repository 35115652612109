import { MediaItemTypeName } from 'commonTypes';
import { ColumnDef } from '@tanstack/react-table';
import { Route as RouteEnum } from 'src/router/routes.const';
import { generatePath } from 'react-router';
import { useMemo } from 'react';
import { PromoCodeMediaItemTable } from '../PromoCodeMediaItemTable';
import { ReferralLinkMediaItemTable } from '../ReferralLinkMediaItemTable';

interface Props<T extends object> {
  mediaItemsType: MediaItemTypeName;
  partnerId: string;
  isFullScreenTable: boolean;
  mediaItems?: Array<T>;
  columns: Array<ColumnDef<T>>;
}

export const MediaItemTable = <T extends { __typename: string }>({
  partnerId,
  mediaItemsType,
  isFullScreenTable,
  mediaItems,
  columns,
}: Props<T>) => {
  const routeToCustomPage = useMemo(
    () =>
      !isFullScreenTable
        ? generatePath(RouteEnum.PARTNER_MEDIA_ITEMS, { id: partnerId })
        : undefined,
    [partnerId, isFullScreenTable]
  );

  if (mediaItemsType === MediaItemTypeName.PromoCode) {
    return (
      <PromoCodeMediaItemTable
        partnerId={partnerId}
        routeToCustomPage={routeToCustomPage}
        isFullScreenTable={isFullScreenTable}
        promoCodeMediaItems={mediaItems}
        columns={columns}
      />
    );
  }

  return (
    <ReferralLinkMediaItemTable
      partnerId={partnerId}
      routeToCustomPage={routeToCustomPage}
      isFullScreenTable={isFullScreenTable}
      referralLinkMediaItems={mediaItems}
      columns={columns}
    />
  );
};
