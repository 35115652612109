import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import errorToast from 'ui/Toast/ErrorToast';
import { useGetReferralLinkMediaItemByUrlLazyQuery } from 'src/queries/generated/GetReferralLinkMediaItemByUrl';
import { CreateAffiliatePromoCodeValues } from '../../../types';
import { FieldName } from '../../../const';

export const useGetMediaCampaignByReferralLinkUrl = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateAffiliatePromoCodeValues>();

  const [getReferralLinkByUrl] = useGetReferralLinkMediaItemByUrlLazyQuery();

  useEffect(() => {
    if (
      values[FieldName.ReferralLinkUrl] &&
      !values[FieldName.MediaCampaignName] &&
      !values[FieldName.MediaCampaignUrl]
    ) {
      getReferralLinkByUrl({
        variables: {
          url: values[FieldName.ReferralLinkUrl],
        },
        onCompleted: (data) => {
          setFieldValue(
            FieldName.MediaCampaignName,
            data.referralLinkMediaItemByUrl.mediaCampaign.name
          );
          setFieldValue(
            FieldName.MediaCampaignUrl,
            data.referralLinkMediaItemByUrl.mediaCampaign.url
          );
        },
        onError: (error) => {
          errorToast({
            header: 'Реферальная ссылка не существует',
            text: error.message,
            toastId: 'get-referral-link-media-item-by-url',
          });
        },
      });
    }
  }, [getReferralLinkByUrl, setFieldValue, values]);
};
