import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ApolloError } from '@apollo/client';
import { useAuth } from 'commonComponents/Auth';
import { UserPrivilege } from 'generatedUserPrivilege';
import { CreatePaymentValues } from 'commonComponents/CreatePayment/validation';
import { logError } from 'src/utils/logError';
import { GetWithdrawalItemDocument } from '../../WithdrawalItem/queries/generated/GetWithdrawalItem';
import { useCreateWithdaralListPayment } from '../queries/generated/CreateWithdaralListPayment';
import { WithdrawalModalSteps } from '../../../types';

interface UseCreatePaymentResult {
  handleCreatePayment: (arg: CreatePaymentValues) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface UseCreatePaymentParams {
  withdrawalId: string;
}

export const useCreatePayment = ({
  withdrawalId,
}: UseCreatePaymentParams): UseCreatePaymentResult => {
  const auth = useAuth();

  const [createPayment, { data: paymentResult, loading, error }] =
    useCreateWithdaralListPayment({
      refetchQueries: [
        {
          query: GetWithdrawalItemDocument,
          variables: {
            id: withdrawalId,
            viewPaymentMethodAccount: auth.privileges.areEveryGranted({
              privileges: [UserPrivilege.ViewPaymentmethodAccount],
            }),
          },
        },
      ],
    });

  const { dispatch } = useContext(ModalContext);

  useEffect(() => {
    if (paymentResult) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: WithdrawalModalSteps.BaseStep,
        },
      });
    }
  }, [paymentResult, dispatch]);

  const handleCreatePayment = async (values: CreatePaymentValues) => {
    const { provider, bypassRestrictionIfApproved, sum, currency } = values;

    if (!provider) {
      logError('Provider is undefined');

      return;
    }

    await createPayment({
      variables: {
        input: {
          withdrawalId,
          paymentProcessor: provider,
          sum: {
            amount: Number(sum),
            currency,
          },
          bypassRestrictionIfApproved,
        },
      },
    });
  };

  return { handleCreatePayment, loading, error };
};
