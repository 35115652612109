export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonTheme {
  Process = 'process',
  Success = 'success',
  Important = 'important',
  Warning = 'warning',
  Primary = 'primary',
}

export enum LinkButtonTheme {
  Important = 'important',
  Grey = 'grey',
}

export enum IconType {
  Update = 'update',
}
