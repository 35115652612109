import { FC, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFlagCheckered,
  faSpinnerThird,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import Button from 'ui/Button/Button';
import { IconType } from 'ui/Button';
import { ErrorType } from 'ui/Block/store/types';
import styles from './LoadMoreStatus.module.scss';

interface Messages {
  inProgress?: string;
  complete?: string;
  error?: string;
}

interface Props {
  error?: ErrorType | null;
  isLoading?: boolean;
  isEndOfList?: boolean;
  messages?: Messages;
  className?: string;
  fetchMore?: () => void;
}

export const LoadMoreStatus: FC<Props> = ({
  error,
  isEndOfList,
  isLoading,
  messages,
  className,
  fetchMore,
}) => {
  let nodeToRender: ReactElement | null = null;

  if (error && error !== ErrorType.FetchMore) {
    return null;
  }

  if (error === ErrorType.FetchMore) {
    nodeToRender = (
      <>
        <div className={styles.errorMessageContainer}>
          <FontAwesomeIcon
            className={styles.errorIcon}
            icon={faTriangleExclamation}
          />
          {messages?.error || 'Не удалось загрузить'}
        </div>
        {fetchMore && (
          <Button
            className={styles.updateButton}
            linkStyled
            underlined
            icon={IconType.Update}
            onClick={() => {
              fetchMore?.();
            }}
          >
            Обновить
          </Button>
        )}
      </>
    );
  } else if (isLoading) {
    nodeToRender = (
      <>
        <FontAwesomeIcon
          spin
          className={styles.loader}
          icon={faSpinnerThird}
          data-testid="loadMoreIcon"
        />
        {messages?.inProgress || 'Загружаем информацию'}
      </>
    );
  } else if (isEndOfList) {
    nodeToRender = (
      <>
        <FontAwesomeIcon className={styles.loader} icon={faFlagCheckered} />
        {messages?.complete || 'Вы достигли конца таблицы'}
      </>
    );
  }

  return (
    <div
      className={classnames(
        styles.status,
        className,
        error === ErrorType.FetchMore && styles.error
      )}
    >
      {nodeToRender}
    </div>
  );
};
