import { FormikErrors } from 'formik';

export interface ResetName {
  confirmation: string;
}

export interface ResetNameErrors {
  confirmation: string;
}
export const validate = (values: ResetName): FormikErrors<ResetNameErrors> => {
  const errors: FormikErrors<ResetNameErrors> = {};

  if (values.confirmation.toLowerCase() !== 'сбросить') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
