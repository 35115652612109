export enum ColumnId {
  Id = 'id',
  Company = 'company',
  ContactForCommunication = 'company.contactForCommunication',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Company]: 'Компания',
  [ColumnId.ContactForCommunication]: 'Контактная информация',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Company,
  ColumnId.ContactForCommunication,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
