import { ApolloError } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal/ModalStore';
import errorToast from 'ui/Toast/ErrorToast';

interface Props {
  loading: boolean;
  errorConfig: { error?: ApolloError; toastId: string };
  hasData: boolean;
}

export const useModalManageData = ({
  loading,
  errorConfig: { error, toastId },
  hasData,
}: Props): void => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  useEffect(() => {
    modalDispatch({
      type: ModalActions.SetIsLoading,
      payload: { isLoading: loading },
    });
  }, [loading, modalDispatch]);

  useEffect(() => {
    if (!hasData) {
      modalDispatch({
        type: ModalActions.SetError,
        payload: { error },
      });
    }
  }, [error, hasData, modalDispatch]);

  if (error && hasData) {
    errorToast({
      header: error.name,
      text: error.message,
      toastId,
    });
  }
};
