import { useParams } from 'react-router';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';
import { SignInRestrictionHistoryBlock } from '../../components/SignInRestrictionHistoryBlock';

export const SignInRestrictionHistory = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <SignInRestrictionHistoryBlock
        id={id}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PlayerPageContainer>
  );
};
