import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useGetRevSharePartnerReportColumns } from './hooks/useGetRevSharePartnerReportColumns';
import { RevSharePartnerReportData } from '../../queries/generated/RevSharePartnerReportData';

interface Props {
  revSharePartnerReport?: Array<RevSharePartnerReportData>;
  hasTotalRow: boolean;
}

export const RevSharePartnerReportTable: FC<Props> = ({
  revSharePartnerReport,
  hasTotalRow,
}) => {
  const { columns } = useGetRevSharePartnerReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={revSharePartnerReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
