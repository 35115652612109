import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import classnames from 'classnames';
import { getCodeFromGraphQLError } from 'src/utils/getCodeFromGraphQLError';
import ErrorContent from './ErrorContent';

import styles from './Error.module.scss';

interface Props {
  error?: ApolloError | string | null;
  className?: string;
}

const getErrorMessage = (error: Props['error']) => {
  if (error instanceof ApolloError && error.graphQLErrors.length) {
    return error.graphQLErrors.map((graphQLError, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={styles.errorsWrapper}>
        <ErrorContent
          header={getCodeFromGraphQLError(graphQLError)}
          text={graphQLError.message}
        />
      </div>
    ));
  }

  if (error instanceof ApolloError && error.message) {
    return <ErrorContent header={error.message} />;
  }

  return <ErrorContent header={error as string} />;
};

const Error: FC<Props> = ({ error, className }) => {
  if (!error) {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrapper, className)}
      data-testid="errorBlock"
    >
      {getErrorMessage(error)}
    </div>
  );
};

export default Error;
