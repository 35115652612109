import { FC, SyntheticEvent, useContext } from 'react';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import Privilege from 'src/components/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { BlockContext } from 'ui/Block/store';
import styles from '../../FreespinsBlock.module.scss';

interface Props {
  refetchFreespins: () => void;
  onCreateFreespin: (e: SyntheticEvent) => void;
}

const FreespinsBlockHeaderContent: FC<Props> = ({
  refetchFreespins,
  onCreateFreespin,
}) => {
  const { state } = useContext(BlockContext);

  return (
    <div className={styles.headerContent}>
      <ActionButton
        actionType={ButtonType.Update}
        hidden={!state.isOpened}
        disabled={state.isLoading}
        onClick={refetchFreespins}
      />
      <Privilege
        // You need both privileges to create freespin though backend guarantees to provide all of them.
        privileges={[UserPrivilege.ViewGame, UserPrivilege.CreateFreespin]}
      >
        <ActionButton actionType={ButtonType.Add} onClick={onCreateFreespin} />
      </Privilege>
    </div>
  );
};

export default FreespinsBlockHeaderContent;
