import { FC, useEffect } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useFormikContext } from 'formik';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Tooltip } from 'ui/Tooltip';
import { FieldName } from '../../const';
import { CreateAffiliatePromoCodeValues } from '../../types';
import { useGoToCreatePromoCodeSteps } from './hooks/useGoToCreatePromoCodeSteps';
import { FreespinToggleBlock } from './components/FreespinToggleBlock';
import { BonusToggleBlock } from './components/BonusToggleBlock';
import styles from './CreateAffiliatePromoCodeFormSecondStep.module.scss';

export const CreateAffiliatePromoCodeFormSecondStep: FC = () => {
  const { isValid, values, validateForm } =
    useFormikContext<CreateAffiliatePromoCodeValues>();

  // Call validation if ToggleBlocks is open (again return from base step) to disable confirm button
  useEffect(() => {
    if (
      values[FieldName.ConnectionWithFreespins] ||
      values[FieldName.ConnectionWithBonus]
    ) {
      validateForm();
    }
  }, [validateForm, values]);

  const { goToFirstStep, goToConfirmStep } = useGoToCreatePromoCodeSteps();

  return (
    <>
      <FormRowsWrapper>
        <FreespinToggleBlock />
        <BonusToggleBlock />
      </FormRowsWrapper>
      <div className={styles.actionButtons}>
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          onClick={() => goToFirstStep()}
        >
          Назад
        </Button>
        <Tooltip
          tooltipContent={!isValid ? 'Заполните все поля' : undefined}
          placement="right"
        >
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            onClick={() => goToConfirmStep()}
          >
            Далее
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
