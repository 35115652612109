import { FC } from 'react';
import classnames from 'classnames';
import styles from './CircleProgressBar.module.scss';

interface Props {
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  className?: string;
}

export const CircleProgressBar: FC<Props> = ({
  percentage,
  radius = 16,
  strokeWidth = 3,
  className,
}) => {
  const normalizedPercentage = Math.min(Math.max(percentage, 0), 100);
  const normalizedRadius = radius - strokeWidth * 0.5;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset =
    circumference - (normalizedPercentage / 100) * circumference;

  return (
    <svg
      key={radius}
      data-testid="progress-bar"
      height={radius * 2}
      width={radius * 2}
      className={classnames(className, styles.container)}
    >
      <circle
        className={styles.background}
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        data-testid="circle"
        className={styles.progressBar}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        strokeLinecap="round"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};
