import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useFormikContext } from 'formik';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';
import { CreateAffiliatePromoCodeValues } from '../../../../types';
import styles from './DataCardFreespinLink.module.scss';

export const DataCardFreespinLink: FC = () => {
  const { values } = useFormikContext<CreateAffiliatePromoCodeValues>();

  return (
    <DataCard
      customSubHeader={
        <span className={styles.blockTitle}>Связь с&nbsp;фриспинами</span>
      }
      containerClassName={styles.container}
      rows={[
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.FreespinGameId],
          value: `${values[FieldName.FreespinGameName]} #${
            values[FieldName.FreespinGameId]
          }`,
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.FreespinCount],
          value: `${values[FieldName.FreespinCount]} FS`,
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.FreespinCurrency],
          value: values[FieldName.FreespinCurrency],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.FreespinWager],
          value: values[FieldName.FreespinWager],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.FreespinMinBalance],
          value: values[FieldName.FreespinMinBalance]
            ? formatSumWithCurrency(
                values[FieldName.FreespinMinBalance],
                values[FieldName.FreespinCurrency]
              )
            : null,
        },
      ]}
    />
  );
};
