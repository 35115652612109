import { FC } from 'react';
import { useParams } from 'react-router';
import DocumentsList from '../../components/DocumentsBlock/components/DocumentsList';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const Documents: FC = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <DocumentsList playerId={id} isFullScreenBlock shouldReturnToPrevPage />
    </PlayerPageContainer>
  );
};
