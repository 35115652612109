import { FC, useContext, useEffect } from 'react';
import { ListAllOperations } from 'generatedGraphql';
import { Formik, Form } from 'formik';
import Button, { ButtonTheme } from 'ui/Button';
import { ModalActions, ModalContext } from 'ui/Modal';
import Error from 'ui/Error';
import { toast } from 'react-toastify';
import { CreteAdminValues, validate } from './validation';
import AdminForm from '../AdminCard/AdminForm';
import {
  PASSWORD_CREATE_ACTION,
  PASSWORD_SET_URI,
} from '../../../../components/Auth/const';
import { useCreateAdmin } from './queries/generated/CreateAdmin';

interface Props {
  hasAdminsList: boolean;
}

const CreateAdminForm: FC<Props> = ({ hasAdminsList }) => {
  const { dispatch } = useContext(ModalContext);

  const initialValues: CreteAdminValues = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
  };

  const [createAdmin, { data: createResult, loading, error }] = useCreateAdmin({
    ...(hasAdminsList
      ? { refetchQueries: [ListAllOperations.Query.GetAdmins] }
      : null),
  });

  useEffect(() => {
    if (createResult) {
      dispatch({ type: ModalActions.Close });

      toast.success('Администратор добавлен');
    }
  }, [dispatch, createResult]);

  const onSubmit = async (values: CreteAdminValues) => {
    await createAdmin({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roles: values.roles,
          passwordSetUri: `${PASSWORD_SET_URI}/${PASSWORD_CREATE_ACTION}`,
        },
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      <Form>
        {error && <Error error={error} />}

        <AdminForm />

        <Button theme={ButtonTheme.Process} type="submit" isLoading={loading}>
          Добавить
        </Button>
      </Form>
    </Formik>
  );
};

export default CreateAdminForm;
