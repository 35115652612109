import { useAuth, UserPrivilege } from 'commonComponents/Auth';

export const useGetCreateMediaItemPrivileges = () => {
  const auth = useAuth();

  const canCreateReferralLink = auth.privileges.isSomeGranted({
    privileges: [
      UserPrivilege.CreateCompanyReferralLinkMediaItem,
      UserPrivilege.CreatePartnerReferralLinkMediaItem,
    ],
  });

  const canCreatePromoCode = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.CreatePromoCodeMediaItem],
  });

  const canCreatePartnerReferralLink = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.CreateCompanyReferralLinkMediaItem],
  });

  const canCreateCompanyReferralLink = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.CreatePartnerReferralLinkMediaItem],
  });

  const canCreateCompanyAffiliateReferralLink = auth.privileges.areEveryGranted(
    {
      privileges: [UserPrivilege.CreateCompanyAffiliateReferralLinkMediaItem],
    }
  );

  return {
    canCreateReferralLink,
    canCreatePromoCode,
    canCreatePartnerReferralLink,
    canCreateCompanyReferralLink,
    canCreateCompanyAffiliateReferralLink,
  };
};
