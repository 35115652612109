import { FC } from 'react';
import { useParams } from 'react-router';
import DepositsTable from '../../components/DepositsBlock/DepositsTable/DepositsTable';
import { useGetDepositsColumns } from '../../components/DepositsBlock/hooks/useGetDepositsColumns';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const Deposits: FC = () => {
  const { columns } = useGetDepositsColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <DepositsTable
        playerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PlayerPageContainer>
  );
};
