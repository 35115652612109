import { ApolloError } from '@apollo/client';
import {
  AssignWithdrawalInput,
  DeclineWithdrawalInput,
  SuspendWithdrawalInput,
  WithdrawalStatus,
} from 'generatedGraphql';
import { UserPrivilege } from 'generatedUserPrivilege';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { useAuth } from 'commonComponents/Auth';
import { useAssignWithdrawalInList } from '../queries/generated/AssignWithdrawalInList';
import { useDeclineWithdrawalInList } from '../queries/generated/DeclineWithdrawalInList';
import { useSuspendWithdrawalInList } from '../queries/generated/SuspendWithdrawalInList';
import { WithdrawalItemData } from '../../WithdrawalItem/queries/generated/WithdrawalItemData';

interface WithdrawalUpdateActionsResult {
  error?: ApolloError;
  loadingSuspendWithdrawal: boolean;
  loadingAssignWithdrawal: boolean;
  loadingDeclineWithdrawal: boolean;
  assignWithdrawalHandler: (obj: AssignWithdrawalInput) => void;
  declineWithdrawalHandler: (obj: DeclineWithdrawalInput) => void;
  suspendWithdrawalHandler: (obj: SuspendWithdrawalInput) => void;
  isAssignButton?: boolean;
  isDeclineButton?: boolean;
  isSuspendButton?: boolean;
  hasUnfinalizedPayments: boolean;
}

export const useWithdrawalUpdateActions = (
  withdrawal: WithdrawalItemData
): WithdrawalUpdateActionsResult => {
  const [
    assignWithdrawal,
    { error: errorAssignWithdrawal, loading: loadingAssignWithdrawal },
  ] = useAssignWithdrawalInList();

  const [
    declineWithdrawal,
    { error: errorDeclineWithdrawal, loading: loadingDeclineWithdrawal },
  ] = useDeclineWithdrawalInList();

  const [
    suspendWithdrawal,
    { error: errorSuspendWithdrawal, loading: loadingSuspendWithdrawal },
  ] = useSuspendWithdrawalInList();

  const assignWithdrawalHandler = async ({ id }: AssignWithdrawalInput) => {
    await assignWithdrawal({
      variables: { input: { id } },
    });
  };

  const declineWithdrawalHandler = ({ id }: DeclineWithdrawalInput) => {
    const withdrawalData = { ...withdrawal };

    withdrawalData.status = WithdrawalStatus.Declined;
    declineWithdrawal({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        declineWithdrawal: {
          __typename: 'DeclineWithdrawalPayload',
          withdrawal: withdrawalData,
        },
      },
    });

    undoActionToast('Вывод отклонен');
  };

  const suspendWithdrawalHandler = async ({ id }: SuspendWithdrawalInput) => {
    await suspendWithdrawal({
      variables: { input: { id } },
    });
  };

  const { hasUnfinalizedPayments } = withdrawal;
  const auth = useAuth();

  const userId = auth.user.id;

  const withdrawalAdminId = withdrawal.admin?.id;
  const isCreatorWithdrawal = userId === withdrawalAdminId;

  const isAllowedToUpdateWithdrawal = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const isAssignButton =
    isAllowedToUpdateWithdrawal([UserPrivilege.AssignWithdrawal]) &&
    withdrawal.status === WithdrawalStatus.Pending;

  const isDeclineButton =
    isCreatorWithdrawal &&
    !hasUnfinalizedPayments &&
    isAllowedToUpdateWithdrawal([UserPrivilege.DeclineWithdrawal]) &&
    withdrawal.status === WithdrawalStatus.InProgress;

  const isSuspendButton =
    isCreatorWithdrawal &&
    !hasUnfinalizedPayments &&
    isAllowedToUpdateWithdrawal([UserPrivilege.SuspendWithdrawal]) &&
    withdrawal.status === WithdrawalStatus.InProgress;

  return {
    assignWithdrawalHandler,
    declineWithdrawalHandler,
    suspendWithdrawalHandler,
    isAssignButton,
    isDeclineButton,
    isSuspendButton,
    hasUnfinalizedPayments,
    loadingSuspendWithdrawal,
    loadingAssignWithdrawal,
    loadingDeclineWithdrawal,
    error:
      errorSuspendWithdrawal || errorAssignWithdrawal || errorDeclineWithdrawal,
  };
};
