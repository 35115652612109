import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useParams } from 'react-router';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { OutgoingMoneyTransferItem } from '../../OutgoingMoneyTransferItem';
import { CompanyOutgoingMoneyTransferData } from '../queries/generated/CompanyOutgoingMoneyTransferData';

interface UseOpenOutgoingMoneyTransferModalResult<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useOpenOutgoingMoneyTransferModal = <
  T extends object
>(): UseOpenOutgoingMoneyTransferModalResult<T> => {
  const { dispatch } = useContext(ModalContext);
  const { id: companyId } = useParams();

  const openModal = useCallback(
    (outgoingMoneyTransfer: T, initiatorId: string) => {
      if (!companyId) {
        return;
      }

      const { id } = outgoingMoneyTransfer as CompanyOutgoingMoneyTransferData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(id),
          subTitle: 'Исходящий перевод',
          content: (
            <OutgoingMoneyTransferItem
              outgoingMoneyTransferId={id}
              companyId={companyId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, companyId]
  );

  return {
    openModal,
  };
};
