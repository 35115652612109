import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { UpdateCompanyName } from '../components/UpdateCompanyName';

interface UseCompanyEditNameModalParams {
  id: string;
  name: string;
}

interface UseCompanyEditNameModalResult {
  openCompanyEditNameModal: () => void;
}

export const useCompanyEditNameModal = ({
  id,
  name,
}: UseCompanyEditNameModalParams): UseCompanyEditNameModalResult => {
  const { dispatch } = useContext(ModalContext);

  const openCompanyEditNameModal = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Изменение названия компании',
        subTitle: `${name} #${id}`,
        content: <UpdateCompanyName id={id} name={name} />,
      },
    });
  }, [dispatch, id, name]);

  return {
    openCompanyEditNameModal,
  };
};
