export const SECONDS_BEFORE_EXPIRATION = 60;

export { UserPrivilege } from 'generatedUserPrivilege';

export enum AuthStatusesLocal {
  Unathorized = 'Unathorized',
}

export enum AuthStatusesBackend {
  Authenticated = 'Authenticated',
  TwoFactorGenerationRequired = 'TwoFactorGenerationRequired',
  TwoFactorAuthenticationRequired = 'TwoFactorAuthenticationRequired',
}

export const AuthStatuses = {
  ...AuthStatusesLocal,
  ...AuthStatusesBackend,
};

export enum AuthErrorMessages {
  LogIn401 = 'Введена неверная почта или пароль',
  TwoFactor401 = 'Введен неверный код аутентификации',
  PasswordSet401 = 'Срок действия текущей сессии истек',
  Common = 'Произошла ошибка. Повторите действие еще раз',
}

export const PASSWORD_SET_URI = `${window.location.origin}/password`;
export const PASSWORD_CREATE_ACTION = 'create';
export const PASSWORD_CHANGE_ACTION = 'change';

export const AUTH_CODE_LENGTH = 6;
