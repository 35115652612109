import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeclinePartnerApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeclinePartnerApplicationInput;
}>;


export type DeclinePartnerApplication = { __typename: 'Mutation', declinePartnerApplication: { __typename: 'DeclinePartnerApplicationPayload', partnerApplication: { __typename: 'PartnerApplication', declineReason?: string | null, id: string, status: SchemaTypes.PartnerApplicationStatus } } };


export const DeclinePartnerApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclinePartnerApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclinePartnerApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declinePartnerApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partnerApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineReason"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type DeclinePartnerApplicationMutationFn = Apollo.MutationFunction<DeclinePartnerApplication, DeclinePartnerApplicationVariables>;

/**
 * __useDeclinePartnerApplication__
 *
 * To run a mutation, you first call `useDeclinePartnerApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclinePartnerApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declinePartnerApplication, { data, loading, error }] = useDeclinePartnerApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclinePartnerApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclinePartnerApplication, DeclinePartnerApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeclinePartnerApplication, DeclinePartnerApplicationVariables>(DeclinePartnerApplicationDocument, options);
      }
export type DeclinePartnerApplicationHookResult = ReturnType<typeof useDeclinePartnerApplication>;
export type DeclinePartnerApplicationMutationResult = Apollo.MutationResult<DeclinePartnerApplication>;
export type DeclinePartnerApplicationMutationOptions = Apollo.BaseMutationOptions<DeclinePartnerApplication, DeclinePartnerApplicationVariables>;