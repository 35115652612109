import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaItemPromoCodeData } from '../../../../../../../../queries/generated/MediaItemPromoCodeData';
import { MediaItemPromoCodeBasicData } from '../../../../../../../../queries/generated/MediaItemPromoCodeBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerPromoCodeMediaItemVariables = SchemaTypes.Exact<{
  partnerId: SchemaTypes.Scalars['ID']['input'];
  promoCodeId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerPromoCodeMediaItem = { __typename: 'Query', partner: { __typename: 'Partner', id: string, promoCodeMediaItem: { __typename: 'PromoCodeMediaItem', createdAt: string, id: string, updatedAt: string, usageType: SchemaTypes.PromoCodeUsageType, promoCode: { __typename: 'PromoCode', id: string, token: string, bonusAction?: { __typename: 'PromoCodeBonusAction', cashout?: number | null, wager?: number | null, amount: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, minAccountBalanceAmount?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } | null, freespinAction?: { __typename: 'PromoCodeFreespinAction', bonusWager?: number | null, count: number, currency: CommonType.Currency, game: { __typename: 'Game', id: string, name: string }, minAccountBalanceAmount?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } | null, activation?: { __typename: 'PromoCodeActivation', activated: number, limit: number } | null }, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, referralLink?: { __typename: 'ReferralLinkMediaItem', code: any, id: string, referralLink: string } | null } } };


export const GetPartnerPromoCodeMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerPromoCodeMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"promoCodeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"promoCodeMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"promoCodeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaItemPromoCodeData"}}]}}]}}]}},...MediaItemPromoCodeData.definitions,...MediaItemPromoCodeBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPartnerPromoCodeMediaItem__
 *
 * To run a query within a React component, call `useGetPartnerPromoCodeMediaItem` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerPromoCodeMediaItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerPromoCodeMediaItem({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      promoCodeId: // value for 'promoCodeId'
 *   },
 * });
 */
export function useGetPartnerPromoCodeMediaItem(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables> & ({ variables: GetPartnerPromoCodeMediaItemVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables>(GetPartnerPromoCodeMediaItemDocument, options);
      }
export function useGetPartnerPromoCodeMediaItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables>(GetPartnerPromoCodeMediaItemDocument, options);
        }
export function useGetPartnerPromoCodeMediaItemSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables>(GetPartnerPromoCodeMediaItemDocument, options);
        }
export type GetPartnerPromoCodeMediaItemHookResult = ReturnType<typeof useGetPartnerPromoCodeMediaItem>;
export type GetPartnerPromoCodeMediaItemLazyQueryHookResult = ReturnType<typeof useGetPartnerPromoCodeMediaItemLazyQuery>;
export type GetPartnerPromoCodeMediaItemSuspenseQueryHookResult = ReturnType<typeof useGetPartnerPromoCodeMediaItemSuspenseQuery>;
export type GetPartnerPromoCodeMediaItemQueryResult = Apollo.QueryResult<GetPartnerPromoCodeMediaItem, GetPartnerPromoCodeMediaItemVariables>;