import { useGetAdminsLazyQuery } from '../queries/generated/GetAdmins';

const useGetAdmins = () => {
  const [loadAdmins, { data, loading, error, fetchMore }] =
    useGetAdminsLazyQuery();

  const admins = data?.admins;

  const endCursor = admins?.pageInfo.endCursor;

  return {
    endCursor,
    loadAdmins,
    admins,
    loading,
    error,
    fetchMore,
  };
};

export default useGetAdmins;
