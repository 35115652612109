import { FC } from 'react';
import { CircleProgressBar } from 'ui/CircleProgressBar/CircleProgressBar';
import { RepeatButton } from 'ui/RepeatButton';
import { LoadingFileStatus } from '../../const';
import styles from './DocumentStatusIcon.module.scss';

interface Props {
  status: LoadingFileStatus;
  uploadedPercent?: number;
  onRepeatButtonClick: () => void;
}

export const DocumentStatusIcon: FC<Props> = ({
  status,
  uploadedPercent,
  onRepeatButtonClick,
}) => {
  if (uploadedPercent && status === LoadingFileStatus.Loading) {
    return (
      <CircleProgressBar
        className={styles.statusIcon}
        percentage={uploadedPercent}
      />
    );
  }

  if (status === LoadingFileStatus.Error) {
    return (
      <RepeatButton
        className={styles.statusIcon}
        onClick={onRepeatButtonClick}
      />
    );
  }

  return null;
};
