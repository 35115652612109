import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { QualifiedReferralData } from '../../queries/generated/QualifiedReferralData';
import { useGetQualifiedReferralsColumns } from './hooks/useGetQualifiedReferralsColumns';

interface Props {
  qualifiedReferralsList?: Array<QualifiedReferralData>;
}

export const QualifiedReferralsTable: FC<Props> = ({
  qualifiedReferralsList,
}) => {
  const { columns } = useGetQualifiedReferralsColumns();

  return (
    <BlockTable
      columns={columns}
      data={qualifiedReferralsList}
      isFullScreenTable
    />
  );
};
