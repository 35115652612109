import { FC, useContext, useEffect, useState } from 'react';
import Error from 'ui/Error';
import { ModalItemsList } from 'ui/Modal/components/ModalItemsList';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useGetPostbackDisabledNotifications } from './hooks/useGetPostbackDisabledNotifications';
import { PostbackDisabledNotificationsListModalSteps } from './const';
import { DisabledPostbackReferralLink } from '../DisabledPostbackReferralLink';
import { PostbackDisabledNotification } from './components/PostbackDisabledNotification';
import { UpdateAllPostbackDisabledNotificationsButtons } from './components/UpdateAllPostbackDisabledNotificationsButtons';

export const PostbackDisabledNotificationsListModal: FC = () => {
  const { state, dispatch } = useContext(ModalContext);

  const { loadMore, error, loading, postbackDisabledNotifications } =
    useGetPostbackDisabledNotifications();

  const [referralLinkId, setReferralLinkId] = useState<string | null>(null);

  useEffect(() => {
    if (
      state.step === PostbackDisabledNotificationsListModalSteps.BaseStep &&
      referralLinkId
    ) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Отключённые постбэки',
          subTitle: undefined,
        },
      });
    }
  }, [dispatch, referralLinkId, state.step]);

  if (
    state.step === PostbackDisabledNotificationsListModalSteps.ReferralLink &&
    referralLinkId
  ) {
    return <DisabledPostbackReferralLink referralLinkId={referralLinkId} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <ModalItemsList
      topBlock={<UpdateAllPostbackDisabledNotificationsButtons />}
      itemsListContent={postbackDisabledNotifications?.edges.map(({ node }) => (
        <PostbackDisabledNotification
          key={node.postback.id}
          postbackDisabledNotificationsId={node.id}
          setReferralLinkId={setReferralLinkId}
          disabledPostback={node.postback}
        />
      ))}
      loading={loading}
      paginationConfig={{
        loadMore,
        hasNextPage: !!postbackDisabledNotifications?.pageInfo.hasNextPage,
      }}
    />
  );
};
