import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CreateQualifiedReferralConfirmationSetting } from '../components/CreateQualifiedReferralConfirmationSetting';

interface UseCreateQualifiedReferralConfirmationSettingModalResult {
  openCreateQualifiedReferralConfirmationSettingModal: () => void;
}

export const useCreateQualifiedReferralConfirmationSettingModal =
  (): UseCreateQualifiedReferralConfirmationSettingModalResult => {
    const { dispatch } = useContext(ModalContext);

    const openCreateQualifiedReferralConfirmationSettingModal =
      useCallback(() => {
        dispatch({
          type: ModalActions.Open,
          payload: {
            title: `Создание настройки подтверждения`,
            content: <CreateQualifiedReferralConfirmationSetting />,
          },
        });
      }, [dispatch]);

    return {
      openCreateQualifiedReferralConfirmationSettingModal,
    };
  };
