import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { contactForCommunicationTypeMap } from 'src/const';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyListData } from '../../../queries/generated/CompanyListData';
import { mapColumnIdToName, ColumnId, columnsIds } from '../const';

const columnHelper = createColumnHelper<CompanyListData>();

const columnsSource: ColumnsSource<CompanyListData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Name, {
    id: ColumnId.Name,
    header: mapColumnIdToName[ColumnId.Name],
  }),
  columnHelper.accessor(ColumnId.Email, {
    id: ColumnId.Email,
    header: mapColumnIdToName[ColumnId.Email],
  }),
  columnHelper.accessor(ColumnId.ContactForCommunication, {
    id: ColumnId.ContactForCommunication,
    header: mapColumnIdToName[ColumnId.ContactForCommunication],
    cell: ({ row }) => {
      const { contactForCommunication } = row.original;

      return (
        <MultilineCellWithIcon
          title={contactForCommunication.value}
          subTitle={
            contactForCommunicationTypeMap[contactForCommunication.type]
          }
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Note, {
    id: ColumnId.Note,
    header: mapColumnIdToName[ColumnId.Note],
  }),
];

export const useGetCompaniesColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
