import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { CompanyApplicationData } from '../../queries/generated/CompanyApplicationData';
import { useCompanyApplicationModal } from '../../hooks/useCompanyApplicationModal';
import { useGetCompanyApplicationsColumns } from './hooks/useGetCompanyApplicationsColumns';

interface Props {
  companyApplications?: Array<CompanyApplicationData>;
}

export const CompanyApplicationsTable: FC<Props> = ({
  companyApplications,
}) => {
  const { columns } = useGetCompanyApplicationsColumns();
  const openModal = useCompanyApplicationModal();

  return (
    <BlockTable
      onClickOnRow={(companyApplication, initiatorId) =>
        openModal({
          companyApplication,
          initiatorId,
        })
      }
      columns={columns}
      data={companyApplications}
      isFullScreenTable
    />
  );
};
