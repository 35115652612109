import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import SearchInput from 'ui/SearchInput';
import { useCompaniesList } from './hooks/useCompaniesList';
import { CompaniesListTable } from './components/CompaniesListTable';
import {
  COMPANIES_SEARCH_INPUT_NAME,
  COMPANIES_SEARCH_PLACEHOLDER,
} from './const';
import { PageTypeStorage } from '../../utils/storage';
import { SearchContextProvider } from '../../contexts/SearchContext';

const Component: FC = withBlockProvider(() => {
  const { companiesList, loadCompanies, refetchCompanies } = useCompaniesList();
  const { state } = useContext(BlockContext);

  return (
    <>
      <SearchInput
        name={COMPANIES_SEARCH_INPUT_NAME}
        placeholder={COMPANIES_SEARCH_PLACEHOLDER}
        onSubmit={loadCompanies}
      />
      <Block
        title="Компании"
        id="companyList"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetchCompanies()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Компании не найдены"
        isEmpty={isListEmpty(companiesList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(companiesList) && (
          <CompaniesListTable companiesList={companiesList} />
        )}
      </Block>
    </>
  );
});

export const CompaniesList = () => (
  <SearchContextProvider pageType={PageTypeStorage.CompanyPage}>
    <Component />
  </SearchContextProvider>
);
