import { Currency } from 'commonTypes';

interface localeCurrencyParams {
  amount: number;
  currency: Currency;
  locale?: Intl.LocalesArgument;
  allOptions?: Intl.NumberFormatOptions;
}

export const localeCurrency = ({
  amount,
  currency,
  locale = 'ru',
  allOptions,
}: localeCurrencyParams): string =>
  amount.toLocaleString(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...allOptions,
  });
