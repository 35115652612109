import { WithdrawalListData } from '../queries/generated/WithdrawalListData';

export const getActorTypeByTypeName = (actor: WithdrawalListData['actor']) => {
  switch (actor.__typename) {
    case 'Company':
      return 'Компания';
    case 'Partner':
      return 'Партнёр';
    case 'Player':
      return 'Игрок';

    default:
      return 'Unknown';
  }
};
