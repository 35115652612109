import { TabFieldInterface } from 'ui/Tabs';
import { RewardTariffType } from 'commonTypes';
import { rewardTariffMap } from 'src/const';

export const statisticTabs: Array<TabFieldInterface<RewardTariffType>> = [
  {
    id: RewardTariffType.RevShare,
    name: rewardTariffMap[RewardTariffType.RevShare],
  },
  {
    id: RewardTariffType.Cpa,
    name: rewardTariffMap[RewardTariffType.Cpa],
  },
];
