import { SET_REFETCH } from '../actionTypes';

interface Payload {
  refetch: () => void;
}

export interface SetRefetch {
  type: typeof SET_REFETCH;
  payload: Payload;
}

export const setRefetchAction = (payload: Payload): SetRefetch => ({
  type: SET_REFETCH,
  payload,
});
