import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { RevShareRewardTariffData } from '../../../../queries/generated/RevShareRewardTariffData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateRevShareRewardTariffVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateRevShareRewardTariffInput;
}>;


export type CreateRevShareRewardTariff = { __typename: 'Mutation', createRevShareRewardTariff: { __typename: 'CreateRevShareRewardTariffPayload', revShareRewardTariff: { __typename: 'RevShareRewardTariff', createdAt: string, id: string, percent: any } } };


export const CreateRevShareRewardTariffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateRevShareRewardTariff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateRevShareRewardTariffInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRevShareRewardTariff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revShareRewardTariff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RevShareRewardTariffData"}}]}}]}}]}},...RevShareRewardTariffData.definitions]} as unknown as DocumentNode;
export type CreateRevShareRewardTariffMutationFn = Apollo.MutationFunction<CreateRevShareRewardTariff, CreateRevShareRewardTariffVariables>;

/**
 * __useCreateRevShareRewardTariff__
 *
 * To run a mutation, you first call `useCreateRevShareRewardTariff` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevShareRewardTariff` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevShareRewardTariff, { data, loading, error }] = useCreateRevShareRewardTariff({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRevShareRewardTariff(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRevShareRewardTariff, CreateRevShareRewardTariffVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateRevShareRewardTariff, CreateRevShareRewardTariffVariables>(CreateRevShareRewardTariffDocument, options);
      }
export type CreateRevShareRewardTariffHookResult = ReturnType<typeof useCreateRevShareRewardTariff>;
export type CreateRevShareRewardTariffMutationResult = Apollo.MutationResult<CreateRevShareRewardTariff>;
export type CreateRevShareRewardTariffMutationOptions = Apollo.BaseMutationOptions<CreateRevShareRewardTariff, CreateRevShareRewardTariffVariables>;