import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type RejectQualifiedReferralVariables = SchemaTypes.Exact<{
  input: SchemaTypes.RejectQualifiedReferralInput;
}>;


export type RejectQualifiedReferral = { __typename: 'Mutation', rejectQualifiedReferral: { __typename: 'RejectQualifiedReferralPayload', referral: { __typename: 'QualifiedReferral', id: string, status: SchemaTypes.QualifiedReferralStatus, suspectState: SchemaTypes.SuspectState, updatedAt: string } } };


export const RejectQualifiedReferralDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RejectQualifiedReferral"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RejectQualifiedReferralInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rejectQualifiedReferral"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referral"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"suspectState"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type RejectQualifiedReferralMutationFn = Apollo.MutationFunction<RejectQualifiedReferral, RejectQualifiedReferralVariables>;

/**
 * __useRejectQualifiedReferral__
 *
 * To run a mutation, you first call `useRejectQualifiedReferral` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectQualifiedReferral` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectQualifiedReferral, { data, loading, error }] = useRejectQualifiedReferral({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectQualifiedReferral(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectQualifiedReferral, RejectQualifiedReferralVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RejectQualifiedReferral, RejectQualifiedReferralVariables>(RejectQualifiedReferralDocument, options);
      }
export type RejectQualifiedReferralHookResult = ReturnType<typeof useRejectQualifiedReferral>;
export type RejectQualifiedReferralMutationResult = Apollo.MutationResult<RejectQualifiedReferral>;
export type RejectQualifiedReferralMutationOptions = Apollo.BaseMutationOptions<RejectQualifiedReferral, RejectQualifiedReferralVariables>;