export const getRangeYesterday = () => {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  const startRange = date;
  const endRange = date;

  return { startRange, endRange };
};
