import { MediaItemTypeName, RewardTariffType } from 'commonTypes';

export const rewardTypeOptions = [
  {
    label: 'RevShare',
    id: RewardTariffType.RevShare,
  },
  {
    label: 'CPA',
    id: RewardTariffType.Cpa,
  },
];

export const mediaItemTypeOptions = [
  {
    label: 'Ссылка',
    id: MediaItemTypeName.ReferralLink,
  },
];
