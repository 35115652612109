import { throttle } from 'lodash-es';
import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { useModalManageData } from 'ui/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFlagCheckered,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import { SidebarLoader } from 'ui/SidebarLoader';
import { PX_BEFORE_END_OF_SCREEN } from 'ui/Block/const';
import styles from './WithdrawalPayments.module.scss';
import WithdrawalPayment from './components/WithdrawalPayment';
import { useGetWithdrawalPayments } from './queries/generated/GetWithdrawalPayments';
import WithdrawalPaymentsHeader from './components/WithdrawalPaymentsHeader/WithdrawalPaymentsHeader';

interface Props {
  withdrawalId: string;
}

const paymentHeight = 252;

export const paymentsCountPerPage = 10;

const WithdrawalPayments: FC<Props> = ({ withdrawalId }) => {
  const { data, error, loading, fetchMore } = useGetWithdrawalPayments({
    variables: { id: withdrawalId, first: paymentsCountPerPage },
  });

  const remainingSum = data?.withdrawal.remainingSum;
  const withdrawalAdminId = data?.withdrawal.admin?.id;
  const payments = data?.withdrawal.payments;

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        id: withdrawalId,
        first: paymentsCountPerPage,
        after: payments?.pageInfo.endCursor,
      },
    });
  }, [fetchMore, payments?.pageInfo.endCursor, withdrawalId]);

  const hasNextPage = data?.withdrawal.payments.pageInfo.hasNextPage;

  const [prevScrollable, setPrevScrollable] = useState(0);

  const handleScroll = useCallback(() => {
    const tabsContent = document.getElementById('tabsContent');
    const scrollable = tabsContent ? tabsContent.offsetHeight : 0;
    const documentHeight = tabsContent
      ? tabsContent?.getBoundingClientRect().bottom
      : 0;
    const heightViewport = window.innerHeight;

    if (prevScrollable > scrollable || prevScrollable) {
      setPrevScrollable(0);
    }

    const isLoad =
      hasNextPage && documentHeight - heightViewport <= PX_BEFORE_END_OF_SCREEN;

    const isSomething =
      prevScrollable === 0 ||
      (prevScrollable !== 0 && scrollable - prevScrollable >= paymentHeight);

    if (isLoad && isSomething) {
      if (!loading) {
        setPrevScrollable(scrollable);
        loadMore();
      }
    }
  }, [prevScrollable, hasNextPage, loadMore, loading]);

  const handleScrollThrottle = throttle(handleScroll, 100);

  useLayoutEffect(() => {
    const tabsWrapper = document.getElementById('tabsWrapper');

    tabsWrapper?.addEventListener('scroll', handleScrollThrottle);

    return () => {
      tabsWrapper?.removeEventListener('scroll', handleScrollThrottle);
    };
  }, [loadMore, handleScrollThrottle]);

  useModalManageData({
    loading: false,
    errorConfig: { error, toastId: 'get-withdrawal-payments-error' },
    hasData: !!data,
  });

  const listIsEmpty = !payments?.edges.length;

  const isEndOfTheList = !hasNextPage && !loading && !listIsEmpty;

  return (
    <SidebarLoader loading={loading}>
      <WithdrawalPaymentsHeader
        listIsEmpty={listIsEmpty}
        remainingAmount={remainingSum?.amount}
        withdrawalAdminId={withdrawalAdminId}
      />
      {!!payments?.edges.length && (
        <>
          {payments.edges.map(({ node }) => (
            <WithdrawalPayment payment={node} key={node.id} />
          ))}

          {loading && (
            <div className={styles.loaderContainer}>
              <FontAwesomeIcon
                className={styles.loader}
                icon={faSpinnerThird}
                spin
              />
              Загружаем информацию
            </div>
          )}
          {isEndOfTheList && (
            <div className={styles.loaderContainer}>
              <FontAwesomeIcon
                className={styles.loader}
                icon={faFlagCheckered}
              />
              Вы достигли конца списка
            </div>
          )}
        </>
      )}
    </SidebarLoader>
  );
};

export default WithdrawalPayments;
