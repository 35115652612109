import { FC, ReactElement } from 'react';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from 'ui/Tooltip';
import styles from './TooltipQuestionMark.module.scss';

interface Props {
  tooltipContent: string | ReactElement;
}

export const TooltipQuestionMark: FC<Props> = ({ tooltipContent }) => (
  <Tooltip
    tooltipContent={tooltipContent}
    clickable
    tooltipContentClassName={styles.toolTipContent}
  >
    <div className={styles.questionIcon} data-testid="tooltipQuestionMark">
      <FontAwesomeIcon width={13} height={13} icon={faCircleQuestion} />
    </div>
  </Tooltip>
);
