import { FC } from 'react';
import { useParams } from 'react-router';
import { ClientPageLayout } from 'commonComponents/ClientPageLayout/ClientPageLayout';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { PartnerDataBlock } from './components/PartnerDataBlock';
import { PartnerPageContainer } from './components/PartnerPageContainer/PartnerPageContainer';
import { PartnerPageProps } from './types';
import { PartnerMediaCampaignsBlock } from './components/PartnerMediaCampaignsBlock';
import { PartnerMediaItemsBlock } from './components/PartnerMediaItemsBlock';
import { OutgoingMoneyTransfersBlock } from './components/OutgoingMoneyTransfersBlock';

const Component: FC<PartnerPageProps> = ({ partnerId }) => (
  <ClientPageLayout>
    <div>
      <PartnerDataBlock id={partnerId} />
      <Privilege privileges={[UserPrivilege.ViewPartnerMediaCampaign]}>
        <PartnerMediaCampaignsBlock id={partnerId} />
      </Privilege>
      <Privilege privileges={[UserPrivilege.ViewPartnerOutgoingMoneyTransfer]}>
        <OutgoingMoneyTransfersBlock id={partnerId} />
      </Privilege>
    </div>
    <div>
      <Privilege privileges={[UserPrivilege.ViewPartnerReferralLinkMediaItem]}>
        <PartnerMediaItemsBlock id={partnerId} isFullScreenTable={false} />
      </Privilege>
    </div>
  </ClientPageLayout>
);

export const Partner = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer shouldProvideBlock={false} partnerId={id}>
      <Component partnerId={id} />
    </PartnerPageContainer>
  );
};
