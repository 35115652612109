import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import {
  GetCompanyAffiliateReferralLinksBlock,
  useGetCompanyAffiliateReferralLinksBlock,
} from '../queries/generated/GetCompanyAffiliateReferralLinksBlock';

const COUNT_PER_PAGE = 10;

interface UseGetAffiliateReferralLinkParams {
  companyId: string;
  companyAffiliateId: string;
}

interface UseGetAffiliateReferralLinkResult {
  referralLinks?:
    | GetCompanyAffiliateReferralLinksBlock['company']['affiliate']['referralLinkMediaItems'];
  loading: boolean;
  error?: ApolloError;
  loadMore: () => void;
  isListEmpty: boolean;
}

export const useGetAffiliateReferralLink = ({
  companyId,
  companyAffiliateId,
}: UseGetAffiliateReferralLinkParams): UseGetAffiliateReferralLinkResult => {
  const { data, fetchMore, loading, error } =
    useGetCompanyAffiliateReferralLinksBlock({
      variables: {
        id: companyId,
        affiliateId: companyAffiliateId,
        first: COUNT_PER_PAGE,
      },
    });

  const referralLinks = data?.company.affiliate.referralLinkMediaItems;

  const loadMore = useCallback(() => {
    if (!referralLinks?.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: referralLinks.pageInfo.endCursor,
        first: COUNT_PER_PAGE,
      },
    });
  }, [referralLinks, fetchMore]);

  const isListEmpty = !referralLinks?.edges.length;

  return {
    referralLinks,
    loading,
    error,
    loadMore,
    isListEmpty,
  };
};
