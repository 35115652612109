import { formatSumAmount } from 'src/utils/accounting';
import { formatTableHeaderCellWithCurrency } from 'src/pages/Statistic/helpers';

import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import { Currency } from 'commonTypes';
import { columnsIds, ColumnId, mapColumnIdToName } from '../const';
import { RevSharePartnerReportData } from '../../../queries/generated/RevSharePartnerReportData';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { useGetCurrency } from '../../../../../hooks/useGetCurrency';

const columnHelper = createColumnHelper<RevSharePartnerReportData>();

const buildColumnsSource = (
  currency: Currency
): ColumnsSource<RevSharePartnerReportData> => [
  columnHelper.accessor(ColumnId.ReferralCode, {
    id: ColumnId.ReferralCode,
    header: mapColumnIdToName[ColumnId.ReferralCode],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, code } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={code}
          route={Route.STATISTIC_REV_SHARE_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Hits, {
    id: ColumnId.Hits,
    header: mapColumnIdToName[ColumnId.Hits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(ColumnId.Hosts, {
    id: ColumnId.Hosts,
    header: mapColumnIdToName[ColumnId.Hosts],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(ColumnId.Registrations, {
    id: ColumnId.Registrations,
    header: mapColumnIdToName[ColumnId.Registrations],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(ColumnId.FirstDeposits, {
    id: ColumnId.FirstDeposits,
    header: mapColumnIdToName[ColumnId.FirstDeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(ColumnId.HostToRegConversion, {
    id: ColumnId.HostToRegConversion,
    header: mapColumnIdToName[ColumnId.HostToRegConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(ColumnId.RegToDepConversion, {
    id: ColumnId.RegToDepConversion,
    header: mapColumnIdToName[ColumnId.RegToDepConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AverageDeposit, {
    id: ColumnId.AverageDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AverageDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.averageDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllRedeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allRedeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(ColumnId.NewDeposit, {
    id: ColumnId.NewDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.IncomingMoneyTransfer, {
    id: ColumnId.IncomingMoneyTransfer,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.IncomingMoneyTransfer],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Withdrawal],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewWithdrawal, {
    id: ColumnId.NewWithdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewWithdrawal],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewProfit, {
    id: ColumnId.NewProfit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewProfit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newProfit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.DepositFee, {
    id: ColumnId.DepositFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.DepositFee],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.depositFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.WithdrawalFee, {
    id: ColumnId.WithdrawalFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.WithdrawalFee],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawalFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AdminFee, {
    id: ColumnId.AdminFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AdminFee],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.adminFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Bonus, {
    id: ColumnId.Bonus,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Bonus],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.bonus;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.TournamentPrize, {
    id: ColumnId.TournamentPrize,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.TournamentPrize],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.tournamentPrize;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Jackpot, {
    id: ColumnId.Jackpot,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Jackpot],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.jackpot;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NGR],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.financialIndicators.ngr;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Profit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Upcoming],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateUpcomingReward;

      return formatSumAmount(amount);
    },
  }),
];

export const useGetRevSharePartnerReportColumns = () => {
  const { currency } = useGetCurrency();
  const columnsSource = buildColumnsSource(currency);
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
