import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import { Confirmation } from 'ui/Confirmation';
import Error from 'ui/Error';
import { ApolloError } from '@apollo/client';
import styles from './ResetClientTwoFactor.module.scss';
import { validate } from './validation';

interface Props {
  onResetClientTwoFactor: () => void;
  twoFactorEnabled?: boolean;
  error?: ApolloError;
  isLoading: boolean;
}

const ResetClientTwoFactor: FC<Props> = ({
  onResetClientTwoFactor,
  twoFactorEnabled,
  error,
  isLoading,
}) => {
  const [initialValues] = useState({
    confirmation: '',
  });

  const flag = twoFactorEnabled ? 'включена' : 'отключена';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onResetClientTwoFactor}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <h2
            className={styles.title}
          >{`Двухфакторная аутентификация ${flag}.`}</h2>
          {twoFactorEnabled && (
            <Confirmation
              label="Чтобы отключить 2FA напишите «ОТКЛЮЧИТЬ»"
              loading={isLoading}
              buttonText="Отключить"
              isValid={isValid}
              dirty={dirty}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ResetClientTwoFactor;
