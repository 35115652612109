import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useResetPartnerName as useResetPartnerNameMutation } from '../queries/generated/ResetPartnerName';

interface ResetPartnerNameArgs {
  partnerId: string;
}

interface ResetPartnerName {
  loading: boolean;
  onResetPartnerName: () => void;
  error?: ApolloError;
}

export const useResetPartnerName = ({
  partnerId,
}: ResetPartnerNameArgs): ResetPartnerName => {
  const { dispatch } = useContext(ModalContext);

  const [
    ResetPartnerName,
    { data: updateResult, loading: resetPartnerNameLoading, error },
  ] = useResetPartnerNameMutation();

  const onResetPartnerName = async () => {
    await ResetPartnerName({
      variables: { input: { partnerId } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Партнер изменён');
    }
  }, [updateResult, dispatch]);

  return {
    loading: resetPartnerNameLoading,
    onResetPartnerName,
    error,
  };
};
