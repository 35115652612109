import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import { FC } from 'react';
import styles from './NoFiltersSelected.module.scss';

export const NoFiltersSelected: FC = () => (
  <div className={styles.inputContainer}>
    <CardIcon
      config={{
        icon: faSlidersH,
        iconColor: CardIconColor.GREY,
      }}
      className={styles.filterIcon}
    />
    <div className={styles.placeholder}>Выберите фильтр...</div>
  </div>
);
