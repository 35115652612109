import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { PartnerApplicationStatus } from 'generatedGraphql';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { usePartnerApplicationUpdate } from './hooks/usePartnerApplicationUpdate';
import styles from './ButtonsUpdatePartnerApplication.module.scss';
import { PartnerApplicationModalSteps } from '../../../../const';
import { nbsp } from '../../../../../../const';

interface Props {
  partnerApplicationStatus: PartnerApplicationStatus;
  partnerApplicationId: string;
  referralLinkDate?: ReferralLinkData | null;
}

export const ButtonsUpdatePartnerApplication: FC<Props> = ({
  partnerApplicationStatus,
  partnerApplicationId,
  referralLinkDate,
}) => {
  const {
    hasSuspendButton,
    hasAcceptButton,
    hasDeclineButton,
    hasAssignButton,
    assignPartnerApplication,
    suspendPartnerApplication,
    updateModalStep,
    loading,
    acceptPartnerApplicationHandler,
  } = usePartnerApplicationUpdate({
    partnerApplicationStatus,
    partnerApplicationId,
    referralLinkData: referralLinkDate,
  });

  return (
    <div className={styles.btnContainer}>
      {hasAssignButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            assignPartnerApplication({
              variables: {
                input: {
                  id: partnerApplicationId,
                },
              },
            })
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Success}
        >
          {`Взять${nbsp}в${nbsp}работу`}
        </Button>
      )}
      {hasAcceptButton && (
        <Button
          className={styles.btn}
          onClick={acceptPartnerApplicationHandler}
          type="submit"
          isLoading={loading}
          disabled={!referralLinkDate}
          theme={ButtonTheme.Success}
        >
          Активировать
        </Button>
      )}
      {hasSuspendButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            suspendPartnerApplication({
              variables: {
                input: {
                  id: partnerApplicationId,
                },
              },
            })
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Warning}
        >
          {`В${nbsp}ожидание`}
        </Button>
      )}
      {hasDeclineButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            updateModalStep(PartnerApplicationModalSteps.DeclineStep)
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Important}
        >
          Отклонить
        </Button>
      )}
    </div>
  );
};
