import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';

export const useUpdateModalStep = <T extends number>() => {
  const { dispatch } = useContext(ModalContext);

  const updateModalStep = useCallback(
    (step: T, initiatorId?: string) => {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step,
          initiatorId,
        },
      });
    },
    [dispatch]
  );

  return {
    updateModalStep,
  };
};
