import React, { FC } from 'react';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import classnames from 'classnames';
import { Sum } from 'generatedGraphql';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { twoEmDash } from 'src/const';
import styles from './BoldSumCellByCompare.module.scss';

interface Props {
  comparedSum: Sum;
  renderedSum?: Sum | null;
}

export const BoldSumCellByCompare: FC<Props> = ({
  renderedSum,
  comparedSum,
}) => {
  const isEqual = renderedSum?.amount === comparedSum.amount;

  if (renderedSum === undefined) {
    return null;
  }

  return MayBeNullCell(
    renderedSum && (
      <div
        data-testid="boldSumByCompare"
        className={classnames(!isEqual && styles.bold)}
      >
        {formatSumWithCurrency(renderedSum.amount, renderedSum.currency)}
      </div>
    ),
    twoEmDash
  );
};
