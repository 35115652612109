import React, { FC, useContext } from 'react';
import Block from 'ui/Block';
import { BlockContext } from 'ui/Block/store';
import { ClientData } from './types';
import { ClientDataBlockContent } from './components/ClientBlockContent/ClientDataBlockContent';
import { ClientHeaderContent } from './components/ClientHeaderContent/ClientHeaderContent';

interface Props {
  blockId: string;
  title: string;
  onUpdate(): void;
  data: ClientData | null;
  titleColumnMinWidth?: number;
}

export const ClientDataBlock: FC<Props> = ({
  blockId,
  title,
  onUpdate,
  data,
  titleColumnMinWidth,
}) => {
  const { state } = useContext(BlockContext);

  const isRenderClientBlockContent =
    !state.isLoading && !state.error.data && data;

  return (
    <Block
      title={title}
      id={blockId}
      headerContent={<ClientHeaderContent refetchClientData={onUpdate} />}
      extendedHeader={
        isRenderClientBlockContent && (
          <ClientDataBlockContent
            clientData={data}
            titleColumnMinWidth={titleColumnMinWidth}
          />
        )
      }
      shrinkLoaderWrapper={false}
    />
  );
};
