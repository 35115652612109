import { FC } from 'react';
import { CardIcon, CardIconConfig } from 'ui/Card/CardIcon';
import styles from './MoneyTransferType.module.scss';

interface Props {
  iconConfig: CardIconConfig;
  status: string;
}

export const MoneyTransferType: FC<Props> = ({ iconConfig, status }) => (
  <div className={styles.container}>
    <CardIcon className={styles.icon} config={iconConfig} />
    <div className={styles.status}>{status}</div>
  </div>
);
