import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetSignInRestrictionVariables = SchemaTypes.Exact<{
  signInRestrictionId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetSignInRestriction = { __typename: 'Query', signInRestriction: { __typename: 'SignInRestriction', expiredAt?: string | null, id: string, lockedAt: string, reason: string, status: SchemaTypes.SignInRestrictionStatus } };


export const GetSignInRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetSignInRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"signInRestrictionId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signInRestriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"signInRestrictionId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expiredAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lockedAt"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetSignInRestriction__
 *
 * To run a query within a React component, call `useGetSignInRestriction` and pass it any options that fit your needs.
 * When your component renders, `useGetSignInRestriction` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignInRestriction({
 *   variables: {
 *      signInRestrictionId: // value for 'signInRestrictionId'
 *   },
 * });
 */
export function useGetSignInRestriction(baseOptions: ApolloReactHooks.QueryHookOptions<GetSignInRestriction, GetSignInRestrictionVariables> & ({ variables: GetSignInRestrictionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSignInRestriction, GetSignInRestrictionVariables>(GetSignInRestrictionDocument, options);
      }
export function useGetSignInRestrictionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSignInRestriction, GetSignInRestrictionVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSignInRestriction, GetSignInRestrictionVariables>(GetSignInRestrictionDocument, options);
        }
export function useGetSignInRestrictionSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetSignInRestriction, GetSignInRestrictionVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetSignInRestriction, GetSignInRestrictionVariables>(GetSignInRestrictionDocument, options);
        }
export type GetSignInRestrictionHookResult = ReturnType<typeof useGetSignInRestriction>;
export type GetSignInRestrictionLazyQueryHookResult = ReturnType<typeof useGetSignInRestrictionLazyQuery>;
export type GetSignInRestrictionSuspenseQueryHookResult = ReturnType<typeof useGetSignInRestrictionSuspenseQuery>;
export type GetSignInRestrictionQueryResult = Apollo.QueryResult<GetSignInRestriction, GetSignInRestrictionVariables>;