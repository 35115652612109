import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';
import { useGetCompanyApplicationsLazyQuery } from '../queries/generated/GetCompanyApplications';
import { CompanyApplicationData } from '../queries/generated/CompanyApplicationData';

const cellHeight = Heights.defaultCellHeight;

export const useCompanyApplications = () => {
  const [
    loadCompanyApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCompanyApplicationsLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
    },
  });

  const companyApplications: Array<CompanyApplicationData> | undefined =
    useMemo(
      () => data?.companyApplications?.edges.map(({ node }) => node),
      [data?.companyApplications?.edges]
    );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.companyApplications?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.companyApplications?.pageInfo.endCursor, fetchMore]
  );

  return {
    companyApplications,
    loadCompanyApplications,
    data,
    loading,
    fetchMoreCallback,
    error,
    refetch,
  };
};
