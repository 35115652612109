import { FC, PropsWithChildren, ReactElement } from 'react';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { withBlockProvider } from 'ui/Block/store';
import Error from 'ui/Error';
import { PlayersSearchInput } from '../PlayersSearchInput';
import { useGetPlayerData } from '../../queries/generated/GetPlayerData';
import { PlayerHeader } from '../PlayerHeader';

interface Props {
  children: ReactElement;
  playerId: string;
  shouldProvideBlock?: boolean;
}

export const PlayerPageContainer: FC<Props> = ({
  children,
  playerId,
  shouldProvideBlock = true,
}) => {
  let ChildrenWithHOCs: FC<PropsWithChildren<object>> | undefined;

  if (shouldProvideBlock) {
    ChildrenWithHOCs = withBlockProvider(() => children);
  }

  const { data, loading, error } = useGetPlayerData({
    variables: {
      id: playerId,
    },
    fetchPolicy: 'cache-first',
  });

  const playerMainContent = () => {
    const playerData = data?.player;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <Error error={error} />;
    }

    if (!playerData) {
      return null;
    }

    return (
      <>
        <PlayerHeader player={playerData} />
        {ChildrenWithHOCs ? <ChildrenWithHOCs /> : children}
      </>
    );
  };

  return (
    <SearchContextProvider
      shouldUpdateUrl={false}
      pageType={PageTypeStorage.PlayerPage}
    >
      <>
        <PlayersSearchInput />
        {playerMainContent()}
      </>
    </SearchContextProvider>
  );
};
