import { Dispatch, SetStateAction, useCallback, MouseEvent } from 'react';
import { AvailableFilter, Filter } from 'ui/TableFilter/types';
import ClickOutside from 'ui/ClickOutside';
import { useSelectedFilter } from 'ui/TableFilter/components/SelectedFilter/useSelectedFilter';
import { FilterType } from 'ui/TableFilter/const';
import { SelectedFilterMarker } from 'ui/TableFilter/components/SelectedFilter/components/SelectedFilterMarker/SelectedFilterMarker';
import classnames from 'classnames';
import { SelectedFilterList } from 'ui/TableFilter/components/SelectedFilter/components/SelectedFilterList/SelectedFilterList';
import styles from './SelectedFilter.module.scss';

interface Props<T> {
  selectedFilter: Filter;
  allFilters: Array<AvailableFilter<T>>;
  setIsOpenedFiltersList: Dispatch<SetStateAction<boolean>>;
  selectedFilters: Array<Filter>;
  deleteFilter: (id: string) => void;
  setFilterValue: (id: string, value: string) => void;
  toggleFilter: (isOpened: boolean, id: string) => void;
}

export const SelectedFilter = <T extends string>({
  selectedFilter,
  setIsOpenedFiltersList,
  allFilters,
  selectedFilters,
  setFilterValue,
  deleteFilter,
  toggleFilter,
}: Props<T>) => {
  const { selectedFilterId, selectedFilterValue, isOpened } = selectedFilter;

  const filter = allFilters.find((item) => item.filterId === selectedFilterId);

  const {
    handleDeleteFilter,
    handleClickOutside,
    handleClick,
    submitInput,
    inputValue,
    setInputValue,
    optionsFilter,
  } = useSelectedFilter({
    selectedFilterId,
    filter,
    setIsOpenedFiltersList,
    selectedFilters,
    setFilterValue,
    deleteFilter,
    toggleFilter,
  });

  const chosenOptionFilter =
    filter?.type === FilterType.List
      ? filter?.list?.find((item) => item.id === selectedFilterValue)?.label
      : selectedFilterValue;

  const chosenValue =
    filter?.type === FilterType.List
      ? filter?.list?.find((item) => item.id === selectedFilterValue)
          ?.textForSearch
      : selectedFilterValue;

  const updateFilterValue = useCallback(() => {
    if (chosenValue) {
      toggleFilter(true, selectedFilterId);
      setInputValue(chosenValue);
    }
  }, [chosenValue, toggleFilter, selectedFilterId, setInputValue]);

  const changeFilterValue = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    updateFilterValue();
  };

  if (
    !filter ||
    (!optionsFilter?.length &&
      !inputValue.length &&
      filter?.type === FilterType.List)
  ) {
    return null;
  }

  return (
    <ClickOutside callback={handleClickOutside}>
      <div
        onClick={changeFilterValue}
        className={classnames(
          styles.selectedFilter,
          isOpened && styles.isOpened
        )}
      >
        <SelectedFilterMarker
          filterTitle={filter.title}
          filterValue={chosenOptionFilter}
          isOpened={isOpened}
          handleDeleteFilter={handleDeleteFilter}
          inputValue={inputValue}
          submitInput={(e) => {
            submitInput(e, filter?.type);
          }}
          setInputValue={setInputValue}
        />
        <SelectedFilterList
          isOpened={isOpened}
          inputValue={inputValue}
          filterType={filter.type}
          handleClick={handleClick}
          optionsFilter={optionsFilter}
        />
      </div>
    </ClickOutside>
  );
};
