import { FC } from 'react';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Sum } from 'generatedGraphql';
import { formatSumWithCurrency } from 'src/utils/accounting';
import styles from './BoldSumWithWarningIconRowByCompare.module.scss';

interface Props {
  comparedSum: Sum;
  renderedSum?: Sum | null;
}

export const BoldSumWithWarningIconRowByCompare: FC<Props> = ({
  comparedSum,
  renderedSum,
}) => {
  if (!renderedSum) {
    return null;
  }

  const isEqual = renderedSum?.amount === comparedSum.amount;

  return !isEqual ? (
    <div className={styles.bold}>
      <CardIcon
        config={{
          icon: faTriangleExclamation,
          iconColor: CardIconColor.ORANGE,
        }}
        className={styles.iconWarning}
      />
      {formatSumWithCurrency(renderedSum.amount, renderedSum.currency)}
    </div>
  ) : (
    <>{formatSumWithCurrency(renderedSum.amount, renderedSum.currency)}</>
  );
};
