import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { WithdrawalItemData } from './WithdrawalItemData';
import { WithdrawalListData } from '../../../../queries/generated/WithdrawalListData';
import { SumData } from '../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetWithdrawalItemVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  viewPaymentMethodAccount: SchemaTypes.Scalars['Boolean']['input'];
}>;


export type GetWithdrawalItem = { __typename: 'Query', withdrawal: { __typename: 'Withdrawal', hasUnfinalizedPayments: boolean, createdAt: string, id: string, status: SchemaTypes.WithdrawalStatus, updatedAt: string, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string, email: string } | null, account?: { __typename: 'Account', id: string, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null, actor: { __typename: 'Company', id: string, name: string } | { __typename: 'Partner', id: string, name: string } | { __typename: 'Player', id: string, name: string }, paidSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, paymentMethod: { __typename: 'PaymentMethod', account?: string, bank?: any | null, id: string, maskedAccount?: string, name: CommonType.PaymentMethodName }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } };


export const GetWithdrawalItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetWithdrawalItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viewPaymentMethodAccount"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"withdrawal"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"WithdrawalItemData"}}]}}]}},...WithdrawalItemData.definitions,...WithdrawalListData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetWithdrawalItem__
 *
 * To run a query within a React component, call `useGetWithdrawalItem` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawalItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawalItem({
 *   variables: {
 *      id: // value for 'id'
 *      viewPaymentMethodAccount: // value for 'viewPaymentMethodAccount'
 *   },
 * });
 */
export function useGetWithdrawalItem(baseOptions: ApolloReactHooks.QueryHookOptions<GetWithdrawalItem, GetWithdrawalItemVariables> & ({ variables: GetWithdrawalItemVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetWithdrawalItem, GetWithdrawalItemVariables>(GetWithdrawalItemDocument, options);
      }
export function useGetWithdrawalItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWithdrawalItem, GetWithdrawalItemVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetWithdrawalItem, GetWithdrawalItemVariables>(GetWithdrawalItemDocument, options);
        }
export function useGetWithdrawalItemSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetWithdrawalItem, GetWithdrawalItemVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetWithdrawalItem, GetWithdrawalItemVariables>(GetWithdrawalItemDocument, options);
        }
export type GetWithdrawalItemHookResult = ReturnType<typeof useGetWithdrawalItem>;
export type GetWithdrawalItemLazyQueryHookResult = ReturnType<typeof useGetWithdrawalItemLazyQuery>;
export type GetWithdrawalItemSuspenseQueryHookResult = ReturnType<typeof useGetWithdrawalItemSuspenseQuery>;
export type GetWithdrawalItemQueryResult = Apollo.QueryResult<GetWithdrawalItem, GetWithdrawalItemVariables>;