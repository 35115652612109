import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import styles from './CompanyApplications.module.scss';
import { CompanyApplicationsTable } from './components/CompanyApplicationsTable';
import { useCompanyApplications } from './hooks/useCompanyApplications';

export const CompanyApplications: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    data,
    error,
    fetchMoreCallback,
    loadCompanyApplications,
    loading,
    refetch,
    companyApplications,
  } = useCompanyApplications();

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.companyApplications?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  useBlockComponentState({
    loadData: loadCompanyApplications,
    loading,
    error,
  });

  return (
    <Block
      title="Заявки компаний"
      id="companyApplications"
      headerContent={
        <div className={styles.headerContent}>
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetch()}
          />
        </div>
      }
      shrinkLoaderWrapper={false}
      emptyText="Заявки не найдены"
      isEmpty={isListEmpty(companyApplications)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(companyApplications) && (
        <CompanyApplicationsTable companyApplications={companyApplications} />
      )}
    </Block>
  );
});
