import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useAuth } from 'src/components/Auth';
import classnames from 'classnames';
import { nbsp } from 'src/const';
import { WithdrawalModalSteps } from '../../../../types';
import styles from './CreatePaymentInfoBlock.module.scss';

interface Props {
  isListEmpty: boolean;
  remainingAmount?: number;
  withdrawalAdminId?: string;
}

export const CreatePaymentInfoBlock: FC<Props> = ({
  isListEmpty,
  remainingAmount,
  withdrawalAdminId,
}) => {
  const auth = useAuth();

  const isAllowedToCreatePayment = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const userId = auth.user.id;
  const isCreatorWithdrawal = userId === withdrawalAdminId;

  const isCreatePaymentBtnVisible =
    isCreatorWithdrawal &&
    isAllowedToCreatePayment([UserPrivilege.CreatePayment]) &&
    remainingAmount;

  const { updateModalStep } = useUpdateModalStep<WithdrawalModalSteps>();

  if (!isListEmpty && !isCreatePaymentBtnVisible) {
    return null;
  }

  return (
    <div
      className={classnames(styles.container, isListEmpty && styles.withBorder)}
    >
      {isListEmpty && (
        <div className={styles.emptyText}>{`Нет платежей на${nbsp}вывод`}</div>
      )}
      {isCreatePaymentBtnVisible && (
        <Button
          theme={ButtonTheme.Primary}
          type="submit"
          onClick={() => updateModalStep(WithdrawalModalSteps.CreatePayment)}
        >
          Создать платеж
        </Button>
      )}
    </div>
  );
};
