import { FC, useContext } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import Button, { ButtonTheme } from 'ui/Button';
import { Textarea } from 'ui/Input/Textarea/Textarea';
import {
  EditPartnerCompanyAffiliatesApplicationValues,
  validate,
} from './validate';
import { useEditCompanyAffiliate } from './hooks/useEditCompanyAffiliate';
import { CompanyAffiliateApplicationContext } from '../../store';

interface Props {
  companyAffiliateApplicationId: string;
}

export const EditPartnerCompanyAffiliatesApplication: FC<Props> = ({
  companyAffiliateApplicationId,
}) => {
  const { state } = useContext(CompanyAffiliateApplicationContext);

  const initialValues: EditPartnerCompanyAffiliatesApplicationValues = {
    name: state.editableAffiliate?.name || '',
    note: state.editableAffiliate?.note || '',
  };

  const { handleSubmit, loading, error } = useEditCompanyAffiliate(
    companyAffiliateApplicationId,
    state.editableAffiliate?.affiliateId
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      <Form>
        {error && <Error error={error} />}

        <FormRowsWrapper>
          <FormRow isWide>
            <Input
              maxLength={50}
              type="text"
              name="name"
              label="Имя пользователя"
              autoFocus
            />
          </FormRow>

          <FormRow isWide>
            <Textarea placeholder="Заметка" name="note" label="Заметка" />
          </FormRow>
        </FormRowsWrapper>

        <Button
          theme={ButtonTheme.Success}
          type="submit"
          isLoading={loading}
          disabled={loading}
        >
          Сохранить
        </Button>
      </Form>
    </Formik>
  );
};
