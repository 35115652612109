import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import styles from './CreateReferralLinkBlock.module.scss';

interface Props {
  onCreateButtonClick: () => void;
}

export const CreateReferralLinkBlock: FC<Props> = ({ onCreateButtonClick }) => (
  <div className={classnames(styles.content)}>
    <div className={styles.emptyText}>Нет медиа элемента</div>
    <div className={styles.buttonsContainer}>
      <Button
        theme={ButtonTheme.Primary}
        type="submit"
        onClick={onCreateButtonClick}
      >
        Создать
      </Button>
    </div>
  </div>
);
