import { TooltipOptions } from 'ui/Tooltip/types';
import { useTooltip } from 'ui/Tooltip/hooks/useTooltip';
import { TooltipContext } from 'ui/Tooltip/hooks/useTooltipState';
import { FC, PropsWithChildren } from 'react';

export const TooltipContextProvider: FC<PropsWithChildren<TooltipOptions>> = ({
  children,
  ...options
}) => {
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};
