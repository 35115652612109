export enum AboutReferralLinkSteps {
  BaseStep = 1,
  EditReferralLinkStep = 2,
}

export enum FieldName {
  Title = 'title',
}

export const mapFieldNameToTitle: {
  [key in FieldName]: string;
} = {
  [FieldName.Title]: 'Название',
};
