import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { ModalActions, ModalContext } from 'ui/Modal';
import Privilege from 'src/components/Privilege';
import SearchList from 'ui/SearchList';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'src/utils/storage';
import { SEARCH_COUNT_PER_PAGE } from 'src/const';
import { UserPrivilege } from '../../components/Auth';
import styles from './AdminsList.module.scss';
import AdminCard from './components/AdminCard';
import CreateAdminForm from './components/CreateAdminForm/CreateAdminForm';
import useGetAdmins from './hooks/useGetAdmins';
import { ADMINS_SEARCH_INPUT_NAME, ADMINS_SEARCH_PLACEHOLDER } from './const';
import {
  SearchContextProvider,
  useSearchContext,
} from '../../contexts/SearchContext';

const AdminsList: FC = () => {
  const { searchValue } = useSearchContext();
  const { dispatch } = useContext(ModalContext);

  const { endCursor, fetchMore, loadAdmins, admins, loading, error } =
    useGetAdmins();

  const adminsList = admins?.edges.map(({ node }) => node);

  const createAdmin = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: <CreateAdminForm hasAdminsList={!!adminsList} />,
        title: 'Добавить администратора',
      },
    });
  }, [dispatch, adminsList]);

  const hasItems = !!admins?.edges.length;
  const hasNextPage = admins?.pageInfo.hasNextPage;

  const loadAdminsVariables = {
    variables: {
      search: searchValue,
      first: SEARCH_COUNT_PER_PAGE,
    },
  };

  const handleSubmit = () => {
    loadAdmins(loadAdminsVariables);
  };

  const loadMore = () => {
    fetchMore({
      variables: {
        search: searchValue,
        first: SEARCH_COUNT_PER_PAGE,
        after: endCursor,
      },
    });
  };

  useEffect(() => {
    loadAdmins(loadAdminsVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const elementsToRender = useMemo(
    () =>
      adminsList?.map((admin) => <AdminCard key={admin.id} admin={admin} />),
    [adminsList]
  );

  return (
    <>
      <SearchInput
        name={ADMINS_SEARCH_INPUT_NAME}
        placeholder={ADMINS_SEARCH_PLACEHOLDER}
        onSubmit={handleSubmit}
      />
      <div className={styles.header}>
        <h1>Администраторы</h1>

        <Privilege privileges={[UserPrivilege.CreateAdmin]}>
          <Button
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
            onClick={createAdmin}
          >
            Добавить администратора
          </Button>
        </Privilege>
      </div>

      <SearchList
        hasItems={hasItems}
        hasNextPage={hasNextPage}
        loadMore={loadMore}
        loading={loading}
        error={error}
      >
        {elementsToRender}
      </SearchList>
    </>
  );
};

export default () => (
  <SearchContextProvider pageType={PageTypeStorage.AdminPage}>
    <AdminsList />
  </SearchContextProvider>
);
