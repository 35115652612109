import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { Confirmation } from 'ui/Confirmation';
import { ApolloError } from '@apollo/client';
import { validate } from './validation';
import styles from './ResetClientName.module.scss';

interface Props {
  onResetClientName: () => void;
  clientName?: string;
  clientDefaultName?: string;
  error?: ApolloError;
  isLoading: boolean;
}

const ResetClientName: FC<Props> = ({
  onResetClientName,
  clientName,
  clientDefaultName,
  error,
  isLoading,
}) => {
  const [initialValues] = useState({
    confirmation: '',
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onResetClientName}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <div className={styles.content}>
            {clientName}
            <FontAwesomeIcon className={styles.icon} icon={faArrowRight} />
            {clientDefaultName}
          </div>
          <Confirmation
            label="Чтобы сбросить имя напишите «СБРОСИТЬ»"
            loading={isLoading}
            buttonText="Сбросить"
            isValid={isValid}
            dirty={dirty}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetClientName;
