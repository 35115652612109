import { Dispatch } from 'react';
import {
  setLoadingAction,
  setLoaderVisibility,
  BlockAction,
} from 'ui/Block/store';
import { ApolloQueryResult } from '@apollo/client/core/types';

export const fetchMoreWithoutBlockLoader = <T>(
  fetchMoreFunction: () => Promise<ApolloQueryResult<T> | undefined>,
  dispatch: Dispatch<BlockAction>
) => {
  dispatch(setLoaderVisibility({ isLoaderVisible: false }));

  // eslint-disable-next-line promise/catch-or-return
  fetchMoreFunction().finally(() => {
    dispatch(
      setLoadingAction({
        loading: false,
      })
    );

    dispatch(setLoaderVisibility({ isLoaderVisible: true }));
  });
};
