import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { ListAllOperations } from 'generatedGraphql';
import { useEnablePostback as useEnablePostbackMutation } from '../queries/generated/EnablePostback';

export const useEnablePostback = (postbackId: string) => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Постбек не был включен',
      text: error.message,
      toastId: 'enable-postback-error',
    });
  };

  const handleComplete = () => {
    toast.success('Постбек включен');
  };

  const [enablePostback, { loading }] = useEnablePostbackMutation({
    variables: { input: { id: postbackId } },
    onError: handleError,
    onCompleted: handleComplete,
    refetchQueries: [ListAllOperations.Query.GetPostbackDisabledNotifications],
  });

  return {
    enablePostback,
    loading,
  };
};
