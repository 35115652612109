import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaCampaignData } from '../../../../queries/generated/MediaCampaignData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateMediaCampaignVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateMediaCampaignInput;
}>;


export type CreateMediaCampaign = { __typename: 'Mutation', createMediaCampaign: { __typename: 'CreateMediaCampaignPayload', mediaCampaign: { __typename: 'MediaCampaign', createdAt: string, id: string, name: string, url: string } } };


export const CreateMediaCampaignDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateMediaCampaign"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateMediaCampaignInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createMediaCampaign"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaCampaignData"}}]}}]}}]}},...MediaCampaignData.definitions]} as unknown as DocumentNode;
export type CreateMediaCampaignMutationFn = Apollo.MutationFunction<CreateMediaCampaign, CreateMediaCampaignVariables>;

/**
 * __useCreateMediaCampaign__
 *
 * To run a mutation, you first call `useCreateMediaCampaign` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaCampaign` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaCampaign, { data, loading, error }] = useCreateMediaCampaign({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaCampaign(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMediaCampaign, CreateMediaCampaignVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMediaCampaign, CreateMediaCampaignVariables>(CreateMediaCampaignDocument, options);
      }
export type CreateMediaCampaignHookResult = ReturnType<typeof useCreateMediaCampaign>;
export type CreateMediaCampaignMutationResult = Apollo.MutationResult<CreateMediaCampaign>;
export type CreateMediaCampaignMutationOptions = Apollo.BaseMutationOptions<CreateMediaCampaign, CreateMediaCampaignVariables>;