import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { useWithdrawalUpdateActions } from './hooks/useWithdrawalUpdateActions';
import styles from './UpdateWithdrawalButtons.module.scss';
import { WithdrawalItemData } from '../WithdrawalItem/queries/generated/WithdrawalItemData';

interface Props {
  withdrawal: WithdrawalItemData;
}

export const UpdateWithdrawalButtons: FC<Props> = ({ withdrawal }) => {
  const {
    assignWithdrawalHandler,
    declineWithdrawalHandler,
    suspendWithdrawalHandler,
    isAssignButton,
    isDeclineButton,
    isSuspendButton,
    hasUnfinalizedPayments,
    loadingSuspendWithdrawal,
    loadingAssignWithdrawal,
    loadingDeclineWithdrawal,
  } = useWithdrawalUpdateActions(withdrawal);

  return (
    <div className={styles.btnContainer}>
      {hasUnfinalizedPayments && 'Имеются платежи без\u00a0финального статуса'}
      {isAssignButton && (
        <Button
          className={styles.btn}
          onClick={() => assignWithdrawalHandler({ id: withdrawal.id })}
          type="submit"
          isLoading={loadingAssignWithdrawal}
          theme={ButtonTheme.Success}
        >
          Взять в&nbsp;работу
        </Button>
      )}
      {isSuspendButton && !hasUnfinalizedPayments && (
        <Button
          className={styles.btn}
          onClick={() => suspendWithdrawalHandler({ id: withdrawal.id })}
          type="submit"
          isLoading={loadingSuspendWithdrawal}
          theme={ButtonTheme.Success}
          disabled={loadingDeclineWithdrawal}
        >
          В&nbsp;ожидание
        </Button>
      )}
      {isDeclineButton && !hasUnfinalizedPayments && (
        <Button
          className={styles.btn}
          onClick={() => declineWithdrawalHandler({ id: withdrawal.id })}
          type="submit"
          isLoading={loadingDeclineWithdrawal}
          theme={ButtonTheme.Important}
          disabled={loadingSuspendWithdrawal}
        >
          Отклонить
        </Button>
      )}
    </div>
  );
};
