export const COUNT_PER_PAGE = 10;

export const enum CreateMediaItemLinkFields {
  typeRewardTariff = 'typeRewardTariff',
  name = 'name',
  partnerId = 'partnerId',
  companyId = 'companyId',
  companyAffiliateId = 'companyAffiliateId',
  cpaRewardTariffId = 'cpaRewardTariffId',
  fallbackTariffId = 'fallbackTariffId',
  revShareRewardTariffId = 'revShareRewardTariffId',
  mediaCampaignId = 'mediaCampaignId',
  affiliateType = 'affiliateType',
  code = 'code',
}
