import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { AcceptPaymentInput } from 'generatedGraphql';
import { ApolloError } from '@apollo/client';
import { useAcceptPayment } from './queries/generated/AcceptPayment';

interface Values {
  onAcceptPayment: (obj: AcceptPaymentInput) => void;
  error?: ApolloError;
  loading: boolean;
}

export const useOnAcceptPayment = (): Values => {
  const { state, dispatch } = useContext(ModalContext);

  const [acceptPayment, { data, error, loading }] = useAcceptPayment();

  useEffect(() => {
    if (!data) {
      return;
    }

    toast.success('Платеж изменён');

    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: 1,
      },
    });
  }, [data, dispatch, state.step]);

  const onAcceptPayment = async ({ id, amount }: AcceptPaymentInput) => {
    await acceptPayment({
      variables: { input: { id, amount } },
    });
  };

  return { onAcceptPayment, error, loading };
};
