import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetWithdrawals } from './hooks/useGetWithdrawals';
import { WithdrawalsTable } from './components/WithdrawalsTable';

export const Withdrawals: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    data,
    error,
    fetchMoreCallback,
    loadWithdrawals,
    loading,
    refetch,
    withdrawals,
  } = useGetWithdrawals();

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.withdrawals?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  useBlockComponentState({
    loadData: loadWithdrawals,
    loading,
    error,
  });

  return (
    <Block
      title="Выводы"
      id="withdrawals"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          disabled={state.isLoading}
          onClick={() => refetch()}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Выводы не найдены"
      isEmpty={isListEmpty(withdrawals)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(withdrawals) && <WithdrawalsTable withdrawals={withdrawals} />}
    </Block>
  );
});
