import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportCpaCompanyPartnerReportVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  id: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
  showAllTimeCpa: SchemaTypes.Scalars['Boolean']['input'];
}>;


export type GetExportCpaCompanyPartnerReport = { __typename: 'Query', company: { __typename: 'Company', exportCpaCompanyPartnerReport: string, id: string } };


export const GetExportCpaCompanyPartnerReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportCpaCompanyPartnerReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currency"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"showAllTimeCpa"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportCpaCompanyPartnerReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currency"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currency"}}},{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}},{"kind":"Argument","name":{"kind":"Name","value":"showAllTimeCpa"},"value":{"kind":"Variable","name":{"kind":"Name","value":"showAllTimeCpa"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportCpaCompanyPartnerReport__
 *
 * To run a query within a React component, call `useGetExportCpaCompanyPartnerReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportCpaCompanyPartnerReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportCpaCompanyPartnerReport({
 *   variables: {
 *      currency: // value for 'currency'
 *      id: // value for 'id'
 *      period: // value for 'period'
 *      showAllTimeCpa: // value for 'showAllTimeCpa'
 *   },
 * });
 */
export function useGetExportCpaCompanyPartnerReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables> & ({ variables: GetExportCpaCompanyPartnerReportVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables>(GetExportCpaCompanyPartnerReportDocument, options);
      }
export function useGetExportCpaCompanyPartnerReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables>(GetExportCpaCompanyPartnerReportDocument, options);
        }
export function useGetExportCpaCompanyPartnerReportSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables>(GetExportCpaCompanyPartnerReportDocument, options);
        }
export type GetExportCpaCompanyPartnerReportHookResult = ReturnType<typeof useGetExportCpaCompanyPartnerReport>;
export type GetExportCpaCompanyPartnerReportLazyQueryHookResult = ReturnType<typeof useGetExportCpaCompanyPartnerReportLazyQuery>;
export type GetExportCpaCompanyPartnerReportSuspenseQueryHookResult = ReturnType<typeof useGetExportCpaCompanyPartnerReportSuspenseQuery>;
export type GetExportCpaCompanyPartnerReportQueryResult = Apollo.QueryResult<GetExportCpaCompanyPartnerReport, GetExportCpaCompanyPartnerReportVariables>;