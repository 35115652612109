import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useResetPlayerTwoFactor as useResetPlayerTwoFactorMutation } from './queries/generated/ResetPlayerTwoFactor';

interface Args {
  id: string;
}

interface Return {
  loading: boolean;
  onResetPlayerTwoFactor: () => void;
  error?: ApolloError;
}

export const useResetPlayerTwoFactor = ({ id }: Args): Return => {
  const { dispatch } = useContext(ModalContext);

  const [
    resetPlayerTwoFactor,
    { data: updateResult, error, loading: updateLoading },
  ] = useResetPlayerTwoFactorMutation();
  const onResetPlayerTwoFactor = async () => {
    await resetPlayerTwoFactor({
      variables: { input: { id } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Игрок изменён');
    }
  }, [updateResult, dispatch]);

  return {
    loading: updateLoading,
    onResetPlayerTwoFactor,
    error,
  };
};
