import { useContext } from 'react';
import { useAssignCompanyAffiliateApplication } from '../queries/generated/AssignCompanyAffiliateApplication';
import { useSuspendCompanyAffiliateApplication } from '../queries/generated/SuspendCompanyAffiliateApplication';
import { useConfirmCompanyAffiliateApplication } from '../queries/generated/ConfirmCompanyAffiliateApplication';
import { getAcceptedAffiliate } from '../helpers/getAcceptedAffiliate';
import { CompanyAffiliateApplicationContext } from '../../../store';
import { useGetQueriesHandlers } from './useGetQueriesHandlers';

export const useButtonsHandlers = (companyAffiliateApplicationId: string) => {
  const { state } = useContext(CompanyAffiliateApplicationContext);

  const {
    handleErrorUpdateCompanyAffiliateApplication,
    handleCompleteConfirmCompanyAffiliateApplication,
    resetAffiliatesInProgress,
  } = useGetQueriesHandlers();

  const [
    assignCompanyAffiliateApplication,
    { loading: assignCompanyAffiliateApplicationLoading },
  ] = useAssignCompanyAffiliateApplication({
    onError: handleErrorUpdateCompanyAffiliateApplication,
    onCompleted: resetAffiliatesInProgress,
  });

  const [
    suspendCompanyAffiliateApplication,
    { loading: suspendCompanyAffiliateApplicationLoading },
  ] = useSuspendCompanyAffiliateApplication({
    onError: handleErrorUpdateCompanyAffiliateApplication,
    onCompleted: resetAffiliatesInProgress,
  });

  const [
    confirmCompanyAffiliateApplication,
    { loading: confirmCompanyAffiliateApplicationLoading },
  ] = useConfirmCompanyAffiliateApplication({
    onError: handleErrorUpdateCompanyAffiliateApplication,
    onCompleted: handleCompleteConfirmCompanyAffiliateApplication,
  });

  const handleAssignApplication = () => {
    assignCompanyAffiliateApplication({
      variables: {
        input: {
          id: companyAffiliateApplicationId,
        },
      },
    });
  };

  const handleConfirmApplication = () => {
    confirmCompanyAffiliateApplication({
      variables: {
        input: {
          id: companyAffiliateApplicationId,
          items: getAcceptedAffiliate(state.affiliatesInProgress),
        },
      },
    });
  };

  const handleSuspendApplication = () => {
    suspendCompanyAffiliateApplication({
      variables: {
        input: {
          id: companyAffiliateApplicationId,
        },
      },
    });
  };

  return {
    handleAssignApplication,
    assignCompanyAffiliateApplicationLoading,
    handleConfirmApplication,
    confirmCompanyAffiliateApplicationLoading,
    handleSuspendApplication,
    suspendCompanyAffiliateApplicationLoading,
  };
};
