export enum DateRanges {
  AllTime = 'allTime',
  LastWeek = 'lastWeek',
  TwoLastWeek = 'twoLastWeek',
  LastMonth = 'lastMonth',
  CurrentMonth = 'currentMonth',
  PrevMonth = 'prevMonth',
  Today = 'today',
  Yesterday = 'yesterday',
}
