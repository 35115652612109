import React, { FC } from 'react';
import { CardIcon, CardIconConfig } from 'ui/Card/CardIcon';
import styles from './DepositStatusItem.module.scss';

interface Props {
  iconConfig: CardIconConfig;
  status: string;
}

export const DepositStatusItem: FC<Props> = ({ iconConfig, status }) => (
  <div className={styles.container}>
    {iconConfig && <CardIcon className={styles.icon} config={iconConfig} />}
    <div className={styles.status}>{status}</div>
  </div>
);
