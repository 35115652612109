import { FC } from 'react';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.module.scss';

const ToastContainer: FC = () => (
  <ToastifyContainer
    autoClose={10000}
    pauseOnHover={false}
    draggable={false}
    closeButton
    closeOnClick={false}
    icon={false}
    position="bottom-right"
    hideProgressBar={false}
  />
);

export default ToastContainer;
