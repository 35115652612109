import { FC, useContext } from 'react';
import { Card } from 'ui/Card';
import Button from 'ui/Button';
import { AdminRole } from 'commonTypes';
import { faUserCrown } from '@fortawesome/pro-solid-svg-icons';
import { ModalActions, ModalContext } from 'ui/Modal';
import { AdminData } from 'src/queries/generated/AdminData';
import { UserPrivilege, useAuth } from '../../../../components/Auth';
import AdminEditForm from './AdminEditForm';
import { adminRoleMap } from '../../const';

interface Props {
  admin: AdminData;
}

const renderCardHeader = (admin: AdminData) => ({
  leftHeader: `${admin.firstName} ${admin.lastName}`,
  leftSubHeader: admin.email,
});

const getTags = (roles: Array<AdminRole>) =>
  roles.map((role) => ({ id: role, content: adminRoleMap[role] }));

const AdminCard: FC<Props> = ({ admin }) => {
  const { dispatch } = useContext(ModalContext);
  const auth = useAuth();

  const editAdmin = (): void => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: <AdminEditForm admin={admin} />,
        title: 'Изменение информации',
      },
    });
  };

  const renderCardActions = () => {
    const isAllowedToUpdateAdmin = auth.privileges.areEveryGranted({
      privileges: [UserPrivilege.UpdateAdmin],
    });

    if (!isAllowedToUpdateAdmin) {
      return null;
    }

    return <Button onClick={editAdmin}>Редактировать</Button>;
  };

  return (
    <Card
      key={admin.id}
      tags={getTags(admin.roles)}
      headerContent={renderCardHeader(admin)}
      actions={renderCardActions()}
      iconConfig={{
        icon: faUserCrown,
      }}
      info={[`#${admin.id}`]}
    />
  );
};

export default AdminCard;
