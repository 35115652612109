export interface TokenResponseBody {
  token?: string;
  detail?: string;
  status?: string | number;
  title?: string;
}

export interface TwoFactorResponseBody {
  qr_content: string;
  secret: string;
}

const post = async (url: string, body?: string, token?: string) => {
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Link ${token}` : '',
    },
    body,
  });

  let json;

  try {
    json = await response.json();
  } catch (e) {
    return response.ok;
  }

  if (response.ok) {
    return json;
  }

  throw json;
};

export const getToken = (
  identifier: string,
  password: string
): Promise<TokenResponseBody> =>
  post(
    `${process.env.AUTH_URI}/token`,
    JSON.stringify({ identifier, password })
  );

export const getRefreshToken = (): Promise<TokenResponseBody> =>
  post(`${process.env.AUTH_URI}/token/refresh`);

export const generateTwoFactor = (): Promise<TwoFactorResponseBody> =>
  post(`${process.env.AUTH_URI}/2fa/generate`);

export const checkTwoFactor = (authCode: string): Promise<TokenResponseBody> =>
  post(`${process.env.AUTH_URI}/2fa/check`, JSON.stringify({ authCode }));

export const logout = (): Promise<boolean> =>
  post(`${process.env.AUTH_URI}/logout`);

export const passwordSet = (
  password: string,
  token: string
): Promise<boolean> =>
  post(
    `${process.env.AUTH_URI}/password/set`,
    JSON.stringify({ password }),
    token
  );
