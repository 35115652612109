import { FormikErrors } from 'formik';
import { AdminRole } from 'commonTypes';
import { checkEmailValidity } from '../../../../utils/validation';

export interface CreteAdminValues {
  firstName: string;
  lastName: string;
  email: string;
  roles: Array<AdminRole>;
}

interface CreteAdminErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: string;
}

export const validate = (
  values: CreteAdminValues
): FormikErrors<CreteAdminErrors> => {
  const errors: FormikErrors<CreteAdminErrors> = {};

  if (!values.firstName) {
    errors.firstName = 'Необходимо ввести имя';
  }

  if (!values.lastName) {
    errors.lastName = 'Необходимо ввести фамилию';
  }

  if (!values.email) {
    errors.email = 'Необходимо ввести почту';
  } else if (!checkEmailValidity(values.email)) {
    errors.email = 'Невалидный email';
  }

  if (!values.roles || (values.roles && !values.roles.length)) {
    errors.roles = 'Необходимо выбрать роли';
  }

  return errors;
};
