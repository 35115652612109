import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';

interface CompanyPrivileges {
  canUpdateCompanyNote: boolean;
  canViewCompanyNote: boolean;
  canUpdateCompanyName: boolean;
}

export const useCompanyPrivileges = (): CompanyPrivileges => {
  const auth = useAuth();

  const canUpdateCompanyName = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdateCompanyName],
  });

  const canUpdateCompanyNote = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdateCompanyNote],
  });

  const canViewCompanyNote = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.ViewCompanyNote],
  });

  return {
    canUpdateCompanyNote,
    canViewCompanyNote,
    canUpdateCompanyName,
  };
};
