import { FC } from 'react';
import classNames from 'classnames';
import { mapSwitchSizeToClass, SwitchSize } from 'ui/Switch/const';
import styles from './Switch.module.scss';

interface Props {
  id: string;
  name: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  size?: SwitchSize;
}

export const Switch: FC<Props> = ({
  id,
  name,
  isChecked,
  onChange,
  label,
  size = SwitchSize.Medium,
}) => (
  <label className={styles.label} htmlFor={id}>
    <input
      type="checkbox"
      className={styles.checkbox}
      name={name}
      id={id}
      checked={isChecked}
      onChange={(event) => {
        onChange(event.target.checked);
      }}
    />
    <div
      className={classNames(styles.innerWrapper, mapSwitchSizeToClass[size])}
      data-testid="switch-inner-wrapper"
    >
      <span className={styles.inner} />
      <span className={styles.switcher} />
    </div>
    {label && (
      <span
        className={classNames(styles.labelText, mapSwitchSizeToClass[size])}
      >
        {label}
      </span>
    )}
  </label>
);
