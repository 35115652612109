import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UnsuspectQualifiedReferralVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UnsuspectQualifiedReferralInput;
}>;


export type UnsuspectQualifiedReferral = { __typename: 'Mutation', unsuspectQualifiedReferral: { __typename: 'UnsuspectQualifiedReferralPayload', referral: { __typename: 'QualifiedReferral', id: string, suspectState: SchemaTypes.SuspectState } } };


export const UnsuspectQualifiedReferralDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnsuspectQualifiedReferral"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UnsuspectQualifiedReferralInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unsuspectQualifiedReferral"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referral"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"suspectState"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UnsuspectQualifiedReferralMutationFn = Apollo.MutationFunction<UnsuspectQualifiedReferral, UnsuspectQualifiedReferralVariables>;

/**
 * __useUnsuspectQualifiedReferral__
 *
 * To run a mutation, you first call `useUnsuspectQualifiedReferral` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsuspectQualifiedReferral` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsuspectQualifiedReferral, { data, loading, error }] = useUnsuspectQualifiedReferral({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsuspectQualifiedReferral(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsuspectQualifiedReferral, UnsuspectQualifiedReferralVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnsuspectQualifiedReferral, UnsuspectQualifiedReferralVariables>(UnsuspectQualifiedReferralDocument, options);
      }
export type UnsuspectQualifiedReferralHookResult = ReturnType<typeof useUnsuspectQualifiedReferral>;
export type UnsuspectQualifiedReferralMutationResult = Apollo.MutationResult<UnsuspectQualifiedReferral>;
export type UnsuspectQualifiedReferralMutationOptions = Apollo.BaseMutationOptions<UnsuspectQualifiedReferral, UnsuspectQualifiedReferralVariables>;