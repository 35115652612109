import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { RewardModalSteps } from '../../../const';

interface OpenModalParams {
  rewardId: string;
}

interface UseOpenAcceptRewardModalResult {
  openModal: (args: OpenModalParams) => void;
}

export const useOpenAcceptRewardModal = (): UseOpenAcceptRewardModalResult => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    ({ rewardId }: OpenModalParams) => {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `Подтверждение вознаграждения`,
          subTitle: `#${rewardId}`,
          step: RewardModalSteps.ConfirmationStep,
          previousStep: RewardModalSteps.BaseStep,
        },
      });
    },
    [dispatch]
  );

  return {
    openModal,
  };
};
