import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeclineCompanyApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeclineCompanyApplicationInput;
}>;


export type DeclineCompanyApplication = { __typename: 'Mutation', declineCompanyApplication: { __typename: 'DeclineCompanyApplicationPayload', companyApplication: { __typename: 'CompanyApplication', declineReason?: string | null, id: string, status: SchemaTypes.CompanyApplicationStatus } } };


export const DeclineCompanyApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineCompanyApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclineCompanyApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineCompanyApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineReason"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type DeclineCompanyApplicationMutationFn = Apollo.MutationFunction<DeclineCompanyApplication, DeclineCompanyApplicationVariables>;

/**
 * __useDeclineCompanyApplication__
 *
 * To run a mutation, you first call `useDeclineCompanyApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineCompanyApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineCompanyApplication, { data, loading, error }] = useDeclineCompanyApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineCompanyApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineCompanyApplication, DeclineCompanyApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeclineCompanyApplication, DeclineCompanyApplicationVariables>(DeclineCompanyApplicationDocument, options);
      }
export type DeclineCompanyApplicationHookResult = ReturnType<typeof useDeclineCompanyApplication>;
export type DeclineCompanyApplicationMutationResult = Apollo.MutationResult<DeclineCompanyApplication>;
export type DeclineCompanyApplicationMutationOptions = Apollo.BaseMutationOptions<DeclineCompanyApplication, DeclineCompanyApplicationVariables>;