import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { UpdateConfirmationSettingSteps } from '../../../const';

interface UseUpdateConfirmationSettingModalResult {
  openUpdateConfirmationSettingModal: (confirmationSettingId: string) => void;
}

export const useUpdateConfirmationSettingModal =
  (): UseUpdateConfirmationSettingModalResult => {
    const { dispatch } = useContext(ModalContext);

    const openUpdateConfirmationSettingModal = useCallback(
      (confirmationSettingId: string) => {
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            step: UpdateConfirmationSettingSteps.UpdateStep,
            title: `Редактирование настройки подтверждения`,
            subTitle: addHashTagToId(confirmationSettingId),
          },
        });
      },
      [dispatch]
    );

    return {
      openUpdateConfirmationSettingModal,
    };
  };
