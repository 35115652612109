import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import {
  GetCompanyAffiliatePromoCodesBlock,
  useGetCompanyAffiliatePromoCodesBlock,
} from '../queries/generated/GetCompanyAffiliatePromoCodesBlock';

const COUNT_PER_PAGE = 10;

interface Params {
  companyId: string;
  companyAffiliateId: string;
}

interface Result {
  promoCodes?:
    | GetCompanyAffiliatePromoCodesBlock['company']['affiliate']['promoCodeMediaItems']
    | null;
  loading: boolean;
  error?: ApolloError;
  loadMore: () => void;
  isListEmpty: boolean;
}

export const useGetAffiliatePromoCodes = ({
  companyId,
  companyAffiliateId,
}: Params): Result => {
  const { data, fetchMore, loading, error } =
    useGetCompanyAffiliatePromoCodesBlock({
      variables: {
        id: companyId,
        affiliateId: companyAffiliateId,
        first: COUNT_PER_PAGE,
      },
    });

  const promoCodes = data?.company.affiliate.promoCodeMediaItems;

  const loadMore = useCallback(() => {
    if (!promoCodes?.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: promoCodes.pageInfo.endCursor,
        first: COUNT_PER_PAGE,
      },
    });
  }, [promoCodes, fetchMore]);

  const isListEmpty = !promoCodes?.edges.length;

  return {
    promoCodes,
    loading,
    error,
    loadMore,
    isListEmpty,
  };
};
