import { FC } from 'react';

interface Props {
  firstValue?: string | null;
  secondValue?: string | null;
}

export const AddTwoLinedSubTitle: FC<Props> = ({ firstValue, secondValue }) => {
  const hasBrTag = firstValue && secondValue;

  return (
    <>
      {firstValue}
      {hasBrTag && <br />}
      {secondValue}
    </>
  );
};
