import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useUpdateCompanyName as useUpdateCompanyNameMutation } from '../queries/generated/UpdateCompanyName';

interface UpdateCompanyNameNameArgs {
  id: string;
}

interface UpdateCompanyName {
  loading: boolean;
  onUpdateCompanyName: (name: string) => void;
  error?: ApolloError;
}

export const useUpdateCompanyName = ({
  id,
}: UpdateCompanyNameNameArgs): UpdateCompanyName => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Компания изменена');
  };

  const [updateCompanyName, { loading, error }] = useUpdateCompanyNameMutation({
    onCompleted: handleCompleted,
  });

  const onUpdateCompanyName = (name: string) => {
    updateCompanyName({
      variables: {
        input: {
          id,
          name,
        },
      },
    });
  };

  return {
    onUpdateCompanyName,
    loading,
    error,
  };
};
