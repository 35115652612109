import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLock, faUnlock } from '@fortawesome/pro-solid-svg-icons';
import { SuspectState } from 'generatedGraphql';
import styles from './SuspectStateButton.module.scss';

export const mapSuspectStateToClass: {
  [key in SuspectState]: string;
} = {
  [SuspectState.Eligible]: styles.default,
  [SuspectState.NotEligible]: styles.disabled,
  [SuspectState.Suspicious]: styles.blocked,
};

export const mapSuspectStateToIcon: {
  [key in SuspectState]: IconProp;
} = {
  [SuspectState.Eligible]: faUnlock,
  [SuspectState.NotEligible]: faUnlock,
  [SuspectState.Suspicious]: faLock,
};
