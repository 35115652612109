import React, { SyntheticEvent, useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import CreateFreespinForm from '../components/CreateFreespinForm';

interface CreateFreespinType {
  onCreateFreespin: (e: SyntheticEvent) => void;
}

export const useCreateFreespin = <T extends object>(
  playerId: string,
  isFullScreenTable: boolean,
  freespinsList?: Array<T>
): CreateFreespinType => {
  const { dispatch } = useContext(ModalContext);

  const onCreateFreespin = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      dispatch({
        type: ModalActions.Open,
        payload: {
          content: (
            <CreateFreespinForm
              playerId={playerId}
              hasFreespinsList={!!freespinsList}
              isFullScreenTable={isFullScreenTable}
            />
          ),
          title: 'Начисление фриспинов',
        },
      });
    },
    [dispatch, freespinsList, playerId, isFullScreenTable]
  );

  return { onCreateFreespin };
};
