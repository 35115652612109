import { SET_LOAD_NEW } from '../actionTypes';

interface Payload {
  hasNew: boolean;
  onLoadNew?: () => void;
}

export interface SetLoadNew {
  type: typeof SET_LOAD_NEW;
  payload: Payload;
}

export const setLoadNewAction = (payload: Payload): SetLoadNew => ({
  type: SET_LOAD_NEW,
  payload,
});
