import { FC, useMemo } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import { useTournamentRebuyModal } from '../../hooks/useTournamentRebuyModal';
import { TournamentRebuysBasicData } from '../../queries/generated/TournamentRebuysBasicData';
import { TournamentRebuysData } from '../../queries/generated/TournamentRebuysData';
import { useGetTournamentRebuysColumns } from './hooks/useGetTournamentRebuysColumns';

interface Props {
  tournamentRebuys?: Array<TournamentRebuysBasicData | TournamentRebuysData>;
  isFullScreenTable: boolean;
  playerId: string;
}

export const TournamentRebuysTable: FC<Props> = ({
  tournamentRebuys,
  isFullScreenTable,
  playerId,
}) => {
  const openTournamentRebuysModal = useTournamentRebuyModal();
  const { columns } = useGetTournamentRebuysColumns(isFullScreenTable);

  const handleRowClick = (
    tournamentRebuy: TournamentRebuysBasicData | TournamentRebuysData,
    initiatorId: string
  ) => {
    openTournamentRebuysModal({
      tournamentRebuy,
      initiatorId,
    });
  };

  const routeToCustomPage = useMemo(
    () =>
      !isFullScreenTable
        ? generatePath(RouteEnum.PLAYER_TOURNAMENT_REBUY, { id: playerId })
        : undefined,
    [playerId, isFullScreenTable]
  );

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={tournamentRebuys}
      routeToCustomPage={routeToCustomPage}
      isFullScreenTable={isFullScreenTable}
    />
  );
};
