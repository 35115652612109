import { nbsp } from 'src/const';

export enum ColumnId {
  SuspectState = 'suspectState',
  Id = 'id',
  Affiliate = 'affiliate',
  Balance = '__typename',
  Status = 'status',
  Sum = 'sum',
  PaidSum = 'paidSum',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.SuspectState]: `${nbsp}`,
  [ColumnId.Id]: 'ID',
  [ColumnId.Affiliate]: 'Партнёр/Компания',
  [ColumnId.Balance]: 'Текущий баланс',
  [ColumnId.Status]: 'Статус',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.PaidSum]: 'Фактическая сумма',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  ColumnId.SuspectState,
  ColumnId.Id,
  ColumnId.Affiliate,
  ColumnId.Balance,
  ColumnId.Status,
  ColumnId.Sum,
  ColumnId.PaidSum,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
