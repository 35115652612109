import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MessageData } from '../../../MessagesTable/queries/generated/MessageData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerMessageVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerMessage = { __typename: 'Query', message: { __typename: 'Message', deletable: boolean, createdAt: string, id: string, text: string, author: { __typename: 'Admin', firstName: string, id: string, lastName: string } } };


export const GetPlayerMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletable"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageData"}}]}}]}},...MessageData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerMessage__
 *
 * To run a query within a React component, call `useGetPlayerMessage` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerMessage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerMessage({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerMessage(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerMessage, GetPlayerMessageVariables> & ({ variables: GetPlayerMessageVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerMessage, GetPlayerMessageVariables>(GetPlayerMessageDocument, options);
      }
export function useGetPlayerMessageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerMessage, GetPlayerMessageVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerMessage, GetPlayerMessageVariables>(GetPlayerMessageDocument, options);
        }
export function useGetPlayerMessageSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPlayerMessage, GetPlayerMessageVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPlayerMessage, GetPlayerMessageVariables>(GetPlayerMessageDocument, options);
        }
export type GetPlayerMessageHookResult = ReturnType<typeof useGetPlayerMessage>;
export type GetPlayerMessageLazyQueryHookResult = ReturnType<typeof useGetPlayerMessageLazyQuery>;
export type GetPlayerMessageSuspenseQueryHookResult = ReturnType<typeof useGetPlayerMessageSuspenseQuery>;
export type GetPlayerMessageQueryResult = Apollo.QueryResult<GetPlayerMessage, GetPlayerMessageVariables>;