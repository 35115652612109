import { FC, PropsWithChildren, useState } from 'react';
import { Switch } from 'ui/Switch';
import { SwitchSize } from 'ui/Switch/const';
import styles from './ToggleBlock.module.scss';

interface Props {
  label: string;
  name: string;
  onToggle?: (isChecked: boolean) => void;
  isOpen?: boolean;
}

export const ToggleBlock: FC<PropsWithChildren<Props>> = ({
  label,
  name,
  onToggle,
  isOpen = false,
  children,
}) => {
  const [isChecked, setIsChecked] = useState(isOpen);

  const onChange = (checked: boolean) => {
    setIsChecked(checked);
    onToggle?.(checked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
        <Switch
          id={name}
          name={name}
          isChecked={isChecked}
          onChange={onChange}
          size={SwitchSize.Small}
        />
      </div>
      {isChecked && <div className={styles.content}>{children}</div>}
    </div>
  );
};
