import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeclineCompanyPlayerAccountVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeclineCompanyPlayerAccountInput;
}>;


export type DeclineCompanyPlayerAccount = { __typename: 'Mutation', declineCompanyPlayerAccount: { __typename: 'DeclineCompanyPlayerAccountPayload', company: { __typename: 'Company', id: string, playerAccount?: { __typename: 'AffiliatePlayerAccount', status: SchemaTypes.AffiliatePlayerAccountStatus } | null } } };


export const DeclineCompanyPlayerAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineCompanyPlayerAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclineCompanyPlayerAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineCompanyPlayerAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"playerAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type DeclineCompanyPlayerAccountMutationFn = Apollo.MutationFunction<DeclineCompanyPlayerAccount, DeclineCompanyPlayerAccountVariables>;

/**
 * __useDeclineCompanyPlayerAccount__
 *
 * To run a mutation, you first call `useDeclineCompanyPlayerAccount` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineCompanyPlayerAccount` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineCompanyPlayerAccount, { data, loading, error }] = useDeclineCompanyPlayerAccount({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineCompanyPlayerAccount(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineCompanyPlayerAccount, DeclineCompanyPlayerAccountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeclineCompanyPlayerAccount, DeclineCompanyPlayerAccountVariables>(DeclineCompanyPlayerAccountDocument, options);
      }
export type DeclineCompanyPlayerAccountHookResult = ReturnType<typeof useDeclineCompanyPlayerAccount>;
export type DeclineCompanyPlayerAccountMutationResult = Apollo.MutationResult<DeclineCompanyPlayerAccount>;
export type DeclineCompanyPlayerAccountMutationOptions = Apollo.BaseMutationOptions<DeclineCompanyPlayerAccount, DeclineCompanyPlayerAccountVariables>;