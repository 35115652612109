import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { MediaItemReportOrderBy, OrderDirection } from 'generatedGraphql';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useSearchContext } from 'src/contexts/SearchContext';
import {
  useGetRevShareReferralLinkReportLazyQuery,
  GetRevShareReferralLinkReportVariables,
} from '../queries/generated/GetRevShareReferralLinkReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface RevShareReferralLinkReportParams {
  referralLinkId: string;
}

export const useGetRevShareReferralLinkReport = ({
  referralLinkId,
}: RevShareReferralLinkReportParams) => {
  const period = useGetPeriodOfStatistic();

  const { searchValue } = useSearchContext();

  const { currency } = useGetCurrency();

  const baseVariables: GetRevShareReferralLinkReportVariables = {
    currency,
    first: getCountByWindowHeight(cellHeight, 0),
    id: referralLinkId,
    ordering: {
      orderBy: MediaItemReportOrderBy.AllDeposit,
      direction: OrderDirection.Desc,
    },
    period,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period.to, period.from]);

  const [
    loadRevShareReferralLinkReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevShareReferralLinkReportLazyQuery();

  const loadRevShareReferralLinkReportCallback = useCallback(
    () => loadRevShareReferralLinkReport({ variables: reportVariables }),
    [loadRevShareReferralLinkReport, reportVariables]
  );

  useBlockComponentState({
    loadData: loadRevShareReferralLinkReportCallback,
    loading,
    error,
  });

  const revShareReport = data?.mediaItem.revShareReport;
  const edges = revShareReport?.edges;
  const total = revShareReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareReport?.pageInfo.endCursor;
  const hasNextPage = revShareReport?.pageInfo.hasNextPage;

  const revShareReferralLinkReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  const mediaItemInfo = data && {
    name: data.mediaItem.name,
    owner: data.mediaItem.owner,
    code: data.mediaItem.code,
  };

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    revShareReferralLinkReportList,
    refetch,
    mediaItemInfo,
    hasTotalRow,
    loadReport: () =>
      loadRevShareReferralLinkReport({
        variables: { ...reportVariables, search: searchValue },
      }),
  };
};
