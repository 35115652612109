import { CardIconColor } from 'ui/Card';
import { PaymentStatus } from 'generatedGraphql';
import {
  faCheckCircle,
  faExclamationCircle,
  faHourglassEmpty,
  faQuestion,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CardIconConfig } from 'ui/Card/CardIcon';

export const getStatusIcon = (value: PaymentStatus): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (value) {
    case PaymentStatus.Pending:
    case PaymentStatus.Awaiting:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
    case PaymentStatus.Accepted:
    case PaymentStatus.PartiallyAccepted:
    case PaymentStatus.Complete:
      icon = faCheckCircle;
      iconColor = CardIconColor.GREEN;
      break;
    case PaymentStatus.InProgress:
      icon = faSpinner;
      iconColor = CardIconColor.BLUE;
      break;
    case PaymentStatus.Failed:
    case PaymentStatus.Declined:
    case PaymentStatus.PresumablyFailed:
    case PaymentStatus.PartiallyPaid:
      icon = faExclamationCircle;
      iconColor = CardIconColor.RED;
      break;
    case PaymentStatus.Unknown:
    default:
      icon = faQuestion;
      iconColor = CardIconColor.GREY;
      break;
  }

  return { icon, iconColor };
};
