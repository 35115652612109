import { useSearchParams } from 'react-router-dom';
import { StatisticDatePeriod } from 'src/const';
import { generatePath } from 'react-router';

interface GeneratePathParams {
  originalPath: string;
  params?: { [p: string]: string | null } | undefined;
}

export const useGeneratePathWithSavePeriodQueryParams = () => {
  const [searchParams] = useSearchParams();

  const generatePathWithPeriodQueryParams = ({
    originalPath,
    params,
  }: GeneratePathParams) => {
    const newParams = new URLSearchParams(searchParams);

    return `${generatePath(originalPath, params)}?${
      StatisticDatePeriod.From
    }=${newParams.get(StatisticDatePeriod.From)}&${
      StatisticDatePeriod.To
    }=${newParams.get(StatisticDatePeriod.To)}`;
  };

  return generatePathWithPeriodQueryParams;
};
