import { useContext, useEffect, useState } from 'react';
import {
  AffiliateInApproveProcessStatus,
  CompanyAffiliateApplicationContext,
} from '../../../../../store';
import { CompanyAffiliateApplicationInitialValues } from '../types';
import { GetCompanyAffiliateApplication } from '../../../../../queries/generated/GetCompanyAffiliateApplication';

export interface GetInitialValuesParams {
  affiliates: GetCompanyAffiliateApplication['companyAffiliateApplication']['items'];
}

export const useGetInitialValues = ({ affiliates }: GetInitialValuesParams) => {
  const { state } = useContext(CompanyAffiliateApplicationContext);

  const { affiliatesInProgress } = state;
  const [values, setValues] = useState({});

  useEffect(() => {
    const initialValues: CompanyAffiliateApplicationInitialValues = {};

    affiliates.forEach((affiliate) => {
      const { id } = affiliate;

      if (
        affiliatesInProgress &&
        affiliatesInProgress[id] !== AffiliateInApproveProcessStatus.InProcess
      ) {
        initialValues[id] = null;
      } else {
        initialValues[id] = false;
      }

      setValues(initialValues);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return values;
};
