import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AcceptPartnerApplicationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AcceptPartnerApplicationInput;
}>;


export type AcceptPartnerApplication = { __typename: 'Mutation', acceptPartnerApplication: { __typename: 'AcceptPartnerApplicationPayload', partnerApplication: { __typename: 'PartnerApplication', id: string, status: SchemaTypes.PartnerApplicationStatus } } };


export const AcceptPartnerApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptPartnerApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptPartnerApplicationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptPartnerApplication"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partnerApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AcceptPartnerApplicationMutationFn = Apollo.MutationFunction<AcceptPartnerApplication, AcceptPartnerApplicationVariables>;

/**
 * __useAcceptPartnerApplication__
 *
 * To run a mutation, you first call `useAcceptPartnerApplication` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPartnerApplication` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPartnerApplication, { data, loading, error }] = useAcceptPartnerApplication({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPartnerApplication(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptPartnerApplication, AcceptPartnerApplicationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptPartnerApplication, AcceptPartnerApplicationVariables>(AcceptPartnerApplicationDocument, options);
      }
export type AcceptPartnerApplicationHookResult = ReturnType<typeof useAcceptPartnerApplication>;
export type AcceptPartnerApplicationMutationResult = Apollo.MutationResult<AcceptPartnerApplication>;
export type AcceptPartnerApplicationMutationOptions = Apollo.BaseMutationOptions<AcceptPartnerApplication, AcceptPartnerApplicationVariables>;