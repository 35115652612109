import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ApolloError } from '@apollo/client';
import { useAuth } from 'commonComponents/Auth';
import { UserPrivilege } from 'generatedUserPrivilege';
import { CreatePaymentInput } from 'generatedGraphql';
import { CreatePaymentValues } from 'commonComponents/CreatePayment/validation';
import { logError } from 'src/utils/logError';
import { useCreatePayment as useCreatePaymentMutation } from './queries/generated/CreatePayment';
import { GetWithdrawalDocument } from '../WithdrawalItem/queries/generated/GetWithdrawal';

interface UseCreatePaymentResult {
  handleCreatePayment: (arg: CreatePaymentValues) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface UseCreatePaymentParams {
  withdrawalId: string;
}

export const useCreatePayment = ({
  withdrawalId,
}: UseCreatePaymentParams): UseCreatePaymentResult => {
  const auth = useAuth();

  const [createPayment, { data: paymentResult, loading, error }] =
    useCreatePaymentMutation({
      refetchQueries: [
        {
          query: GetWithdrawalDocument,
          variables: {
            id: withdrawalId,
            viewPaymentMethodAccount: auth.privileges.areEveryGranted({
              privileges: [UserPrivilege.ViewPaymentmethodAccount],
            }),
          },
        },
      ],
    });

  const { dispatch } = useContext(ModalContext);

  useEffect(() => {
    if (paymentResult) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: 1,
        },
      });
    }
  }, [paymentResult, dispatch]);

  const handleCreatePayment = async (values: CreatePaymentValues) => {
    const { provider, bypassRestrictionIfApproved, sum, currency } = values;

    if (!provider) {
      logError('Provider is undefined');

      return;
    }

    const payment: CreatePaymentInput = {
      withdrawalId,
      paymentProcessor: provider,
      sum: {
        amount: Number(sum),
        currency,
      },
      bypassRestrictionIfApproved,
    };

    await createPayment({ variables: { input: payment } });
  };

  return { handleCreatePayment, loading, error };
};
