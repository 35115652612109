import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { DeclinePlayerAccount } from 'commonComponents/DeclinePlayerAccount';
import { useManagePlayerAccount } from './useManagePlayerAccount';
import { GetPartnerAdditionalData } from '../queries/generated/GetPartnerAdditionalData';

interface UseEditPlayerAccountParams {
  partnerId: string;
  partner?: GetPartnerAdditionalData['partner'];
}

export const useEditPlayerAccount = ({
  partnerId,
  partner,
}: UseEditPlayerAccountParams) => {
  const { dispatch } = useContext(ModalContext);

  const {
    declinePlayerAccount,
    declinePlayerAccountLoading,
    declinePlayerAccountError,
  } = useManagePlayerAccount(partnerId);

  const onPlayerAccountEdit = useCallback(() => {
    if (!partner || !partner.playerAccount) {
      return;
    }

    const { playerAccount } = partner;
    const {
      player: { name, email, phone, id },
    } = playerAccount;

    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <DeclinePlayerAccount
            onDeclinePlayerAccount={declinePlayerAccount}
            isLoading={declinePlayerAccountLoading}
            playerName={name}
            playerContactInfo={email || phone}
            playerId={id}
            error={declinePlayerAccountError}
          />
        ),
        title: 'Игровой аккаунт',
        subTitle: `${partner.name} #${partnerId}`,
      },
    });
  }, [
    partner,
    dispatch,
    declinePlayerAccount,
    declinePlayerAccountLoading,
    declinePlayerAccountError,
    partnerId,
  ]);

  return { onPlayerAccountEdit };
};
