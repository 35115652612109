import { CardIcon } from 'ui/Card/CardIcon';
import { FC } from 'react';
import { PaymentStatus } from 'generatedGraphql';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { getStatusIcon } from '../../helpers';
import styles from './ClosedDropdownLeftSubTitle.module.scss';

interface Props {
  updatedAt: string;
  status: PaymentStatus;
}

export const ClosedDropdownLeftSubTitle: FC<Props> = ({
  updatedAt,
  status,
}) => {
  const formattedUpdatedAt = formatDate(updatedAt, DateFormat.FullDate);

  return (
    <div className={styles.container}>
      <CardIcon config={getStatusIcon(status)} />
      {formattedUpdatedAt}
    </div>
  );
};
