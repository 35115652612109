import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useGetCpaCompanyPartnersReportColumns } from './hooks/useGetCpaCompanyPartnersReportColumns';
import { CpaCompanyPartnersReportRow } from '../../types';

interface Props {
  cpaCompanyPartnersReport?: Array<CpaCompanyPartnersReportRow>;
  hasTotalRow: boolean;
}

export const CpaCompanyPartnersReportTable: FC<Props> = ({
  cpaCompanyPartnersReport,
  hasTotalRow,
}) => {
  const { columns } = useGetCpaCompanyPartnersReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={cpaCompanyPartnersReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
