import { RewardTariffType } from 'commonTypes';
import { nbsp, rewardTariffMap } from 'src/const';

export const REV_SHARE_COMPANY_REPORT_SEARCH_PLACEHOLDER =
  'Поиск реферального кода';
export const REV_SHARE_COMPANY_REPORT_INPUT_NAME =
  'revShareCompanyReportSearch';
export const REV_SHARE_COMPANY_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
