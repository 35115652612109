import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useResetPartnerTwoFactor } from './queries/generated/ResetPartnerTwoFactor';

interface ResetPartner2FAParams {
  partnerId: string;
}

interface ResetPartner2FA {
  loading: boolean;
  resetPartner2FA: () => void;
  error?: ApolloError;
}

export const useResetPartner2FA = ({
  partnerId,
}: ResetPartner2FAParams): ResetPartner2FA => {
  const { dispatch } = useContext(ModalContext);

  const [
    resetPartnerTwoFactor,
    { data: updateResult, error, loading: resetPartner2FALoading },
  ] = useResetPartnerTwoFactor();

  const resetPartner2FA = async () => {
    await resetPartnerTwoFactor({
      variables: { input: { partnerId } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Партнер изменён');
    }
  }, [updateResult, dispatch]);

  return {
    loading: resetPartner2FALoading,
    resetPartner2FA,
    error,
  };
};
