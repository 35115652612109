import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ListAllOperations } from 'generatedGraphql';
import { useCreatePartnerReferralLinkMediaItem } from 'src/queries/generated/CreatePartnerReferralLinkMediaItem';
import { useCreateCompanyReferralLinkMediaItem } from 'src/queries/generated/CreateCompanyReferralLinkMediaItem';
import { useCreateCompanyAffiliateReferralLinkMediaItem } from '../queries/generated/CreateCompanyAffiliateReferralLinkMediaItem';

export const useCreateReferralLink = () => {
  const { dispatch } = useContext(ModalContext);

  const refetchQueries = [ListAllOperations.Query.GetMediaItemsReferralLink];

  const handleComplete = () => {
    toast.success('Медиа элемент создан');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleError = () => {
    toast.error('Медиа элемент не создан');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const [
    createCompanyAffiliateMediaItem,
    {
      error: createCompanyAffiliateMediaItemError,
      data: createCompanyAffiliateMediaItemData,
      loading: createCompanyAffiliateMediaItemLoading,
    },
  ] = useCreateCompanyAffiliateReferralLinkMediaItem({
    refetchQueries,
    onError: handleError,
    onCompleted: handleComplete,
  });

  const [
    createCompanyMediaItem,
    {
      error: createCompanyMediaItemError,
      data: createCompanyMediaItemData,
      loading: createCompanyMediaItemLoading,
    },
  ] = useCreateCompanyReferralLinkMediaItem({
    refetchQueries,
    onError: handleError,
    onCompleted: handleComplete,
  });

  const [
    createPartnerMediaItem,
    {
      error: createPartnerMediaItemError,
      data: createPartnerMediaItemData,
      loading: createPartnerMediaItemLoading,
    },
  ] = useCreatePartnerReferralLinkMediaItem({
    refetchQueries,
    onError: handleError,
    onCompleted: handleComplete,
  });

  return {
    createPartnerMediaItem,
    createCompanyMediaItem,
    createCompanyAffiliateMediaItem,
    createMediaItemData:
      createPartnerMediaItemData ||
      createCompanyMediaItemData ||
      createCompanyAffiliateMediaItemData,
    createMediaItemLoading:
      createPartnerMediaItemLoading ||
      createCompanyMediaItemLoading ||
      createCompanyAffiliateMediaItemLoading,
    createMediaItemError:
      createPartnerMediaItemError ||
      createCompanyMediaItemError ||
      createCompanyAffiliateMediaItemError,
  };
};
