import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import BonusItem from '../components/BonusItem/BonusItem';
import { getTypeBonus } from '../helpers';
import { BonusBasicData } from '../queries/generated/BonusBasicData';

interface PlayerData {
  openBonusItem: (
    id: string,
    initiatorId: string,
    bonus: BonusBasicData
  ) => void;
}

export const useBonusOpenModal = (): PlayerData => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openBonusItem = useCallback(
    async (id: string, initiatorId: string, bonus: BonusBasicData) => {
      const [bonusType, iconConfig] = getTypeBonus(bonus as BonusBasicData);

      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          content: <BonusItem bonusId={id} />,
          initiatorId,
          iconConfig,
          subTitle: bonusType,
        },
      });
    },
    [modalDispatch]
  );

  return {
    openBonusItem,
  };
};
