import { Form, Formik } from 'formik';
import { useContext, useRef } from 'react';
import { ModalContext } from 'ui/Modal';
import { Currency } from 'commonTypes';
import { generatePromoCodeToken } from 'src/utils/generatePromoCodeToken';
import { useSetDefaultToken } from 'src/utils/hooks/useSetDefaultToken';
import { useCreatePromoCodeModalSteps } from './hooks/useCreatePromoCodeModalSteps';
import { FieldName } from './const';
import { getCreatePromoCodeFormByStep } from './helpers';
import { validate } from './validation';
import { CreatePromoCodeValues } from './types';
import { useCreatePromoCode } from './hooks/useCreatePromoCode';

export const CreateMediaItemPromoCode = () => {
  const { state } = useContext(ModalContext);
  const token = generatePromoCodeToken();
  const tokenRef = useRef<string>(token);

  useSetDefaultToken({ tokenRef });

  const initialValues: CreatePromoCodeValues = {
    [FieldName.ActivationLimit]: undefined,
    [FieldName.Token]: tokenRef.current,
    [FieldName.InvalidToken]: undefined,
    [FieldName.ReferralLink]: '',
    [FieldName.MediaCampaignId]: '',
    [FieldName.MediaCampaignName]: '',
    [FieldName.MediaCampaignUrl]: '',
    [FieldName.Usage]: null,

    [FieldName.FreespinGameId]: '',
    [FieldName.FreespinGameName]: '',
    [FieldName.FreespinCurrency]: Currency.Rub,
    [FieldName.FreespinCount]: 0,
    [FieldName.FreespinWager]: undefined,
    [FieldName.FreespinMinBalance]: undefined,

    [FieldName.BonusAmount]: 0,
    [FieldName.BonusCurrency]: Currency.Rub,
    [FieldName.BonusWager]: undefined,
    [FieldName.BonusCashout]: undefined,
    [FieldName.BonusMinBalance]: undefined,

    [FieldName.ConnectionWithReferralLink]: false,
    [FieldName.ConnectionWithFreespins]: false,
    [FieldName.ConnectionWithBonus]: false,
  };

  useCreatePromoCodeModalSteps();

  const { onCreatePromoCodeSubmitHandler } = useCreatePromoCode();

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validate(values, state.step)}
      validateOnChange={false}
      onSubmit={onCreatePromoCodeSubmitHandler}
      enableReinitialize
    >
      <Form>{getCreatePromoCodeFormByStep(state.step)}</Form>
    </Formik>
  );
};
