import { SCROLL_LOCKED_CLASSNAME } from 'src/const';

const { body } = document;

export const bodyScrollLocker = {
  lock: (shouldAddPadding?: boolean) => {
    if (shouldAddPadding) {
      const paddingOffset = window.innerWidth - document.body.offsetWidth;

      document.body.style.paddingRight = `${paddingOffset}px`;
    }

    body.classList.add(SCROLL_LOCKED_CLASSNAME);
  },
  unLock: () => {
    body.classList.remove(SCROLL_LOCKED_CLASSNAME);
    document.body.style.paddingRight = '';
  },
};
