import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Tooltip } from 'ui/Tooltip';
import FormRow from 'ui/FormRow';
import { Sum } from 'generatedGraphql';
import Input from 'ui/Input';
import { AcceptRewardFields } from './const';
import { AcceptRewardValues } from './types';
import { useAcceptReward } from './hooks/useAcceptReward';
import { validation } from './validation';

interface Props {
  rewardId: string;
  affiliateId: string;
  affiliateName: string;
  sum: Sum;
}

export const AcceptRewardForm: FC<Props> = ({
  rewardId,
  affiliateId,
  affiliateName,
  sum,
}) => {
  const initialValues: AcceptRewardValues = {
    [AcceptRewardFields.affiliate]: `#${affiliateId} ${affiliateName}`,
    [AcceptRewardFields.amount]: sum.amount,
  };

  const { handleSubmit, loading } = useAcceptReward(rewardId, sum.currency);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validation}
      validateOnChange={false}
    >
      {({ isValid }) => (
        <Form>
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={AcceptRewardFields.affiliate}
                label="Партнёр"
                disabled
              />
            </FormRow>
            <FormRow>
              <Input.Sum
                currency={sum.currency}
                name={AcceptRewardFields.amount}
                label="Фактическая сумма"
              />
            </FormRow>
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={!isValid ? 'Заполните все поля' : undefined}
            placement="right"
          >
            <Button
              data-testid="acceptRewardButton"
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={loading}
              disabled={!isValid}
            >
              Подтвердить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
