import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useNavigate } from 'react-router-dom';
import { Route } from 'src/router/routes.const';
import { PartnerListData } from '../../queries/generated/PartnerListData';
import { useGetPartnersColumns } from './hooks/useGetPartnersColumns';

interface Props {
  partnersList?: Array<PartnerListData>;
}

export const PartnersListTable: FC<Props> = ({ partnersList }) => {
  const { columns } = useGetPartnersColumns();
  const navigate = useNavigate();

  const handleRowClick = (partner: PartnerListData) => {
    navigate(`${Route.PARTNERS}/${partner.id}`);
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={partnersList}
      isFullScreenTable
    />
  );
};
