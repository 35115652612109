export enum ColumnId {
  Id = 'id',
  Type = 'protected',
  Reason = 'reason',
  Status = 'status',
  CreatedAt = 'lockedAt',
  ExpiredAt = 'expiredAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Type]: 'Тип',
  [ColumnId.Reason]: 'Причина',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создана',
  [ColumnId.ExpiredAt]: 'Окончание',
};

export const blockColumnsIds = [
  ColumnId.Type,
  ColumnId.Reason,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.ExpiredAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Type,
  ColumnId.Reason,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.ExpiredAt,
];
