import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Error from 'ui/Error';
import { useModalManageData } from 'ui/Modal';
import Input from 'ui/Input';
import { useCreateMediaCampaign } from './hooks/useCreateMediaCampaign';
import { validate } from './validation';
import { CreateMediaCampaignValues } from '../../types';

const initialValues: CreateMediaCampaignValues = {
  name: '',
  url: '',
};

export const CreateMediaCampaign: FC = () => {
  const { createMediaCampaign, error, data, loading } =
    useCreateMediaCampaign();

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'create-media-campaign-error' },
    hasData: !!data,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createMediaCampaign}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <Input
              name="name"
              placeholder="Введите название кампании"
              label="Название"
            />
          </FormRowsWrapper>
          <FormRowsWrapper>
            <Input name="url" placeholder="Введите URL" label="URL" />
          </FormRowsWrapper>

          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={isSubmitting}
            disabled={!isValid || !dirty}
          >
            Создать
          </Button>
        </Form>
      )}
    </Formik>
  );
};
