import React, { ReactElement } from 'react';
import { CardIcon, CardIconConfig } from 'ui/Card/CardIcon';
import classnames from 'classnames';
import styles from './MultilineCellWithIcon.module.scss';

interface Params {
  title: string | ReactElement;
  iconConfig?: CardIconConfig;
  subTitle?: string | ReactElement | null;
  isAlignRight?: boolean;
}

const MultilineCellWithIcon = ({
  title,
  iconConfig,
  subTitle,
  isAlignRight = false,
}: Params): ReactElement => (
  <div
    className={classnames(
      styles.container,
      !iconConfig && isAlignRight && styles.rightAlign
    )}
  >
    {iconConfig && (
      <div className={styles.iconContainer}>
        <CardIcon className={styles.icon} config={iconConfig} />
      </div>
    )}

    <div className={styles.textContainer}>
      <div>{title && title}</div>
      {subTitle && (
        <div className={styles.subTitle}>{subTitle && subTitle}</div>
      )}
    </div>
  </div>
);

export default MultilineCellWithIcon;
