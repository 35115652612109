import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { GeneralReportOrderBy, OrderDirection } from 'generatedGraphql';
import { useSearchContext } from 'src/contexts/SearchContext';
import {
  GetCpaGeneralReportVariables,
  useGetCpaGeneralReportLazyQuery,
} from '../queries/generated/GetCpaGeneralReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetCpaGeneralReport = (isAllTimeCpa: boolean) => {
  const { searchValue } = useSearchContext();
  const period = useGetPeriodOfStatistic();

  const { currency } = useGetCurrency();

  const baseVariables: GetCpaGeneralReportVariables = {
    currency,
    first: getCountByWindowHeight(cellHeight, 0),
    period,
    ordering: {
      orderBy: GeneralReportOrderBy.AllDeposit,
      direction: OrderDirection.Desc,
    },
    showAllTimeCpa: isAllTimeCpa,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period.to, period.from, isAllTimeCpa]);

  const [loadCpaGeneralReport, { data, loading, error, fetchMore, refetch }] =
    useGetCpaGeneralReportLazyQuery();

  const loadCpaGeneralReportCallback = useCallback(
    () => loadCpaGeneralReport({ variables: reportVariables }),
    [loadCpaGeneralReport, reportVariables]
  );

  useBlockComponentState({
    loadData: loadCpaGeneralReportCallback,
    loading,
    error,
  });

  const cpaGeneralReport = data?.cpaGeneralReport;
  const edges = cpaGeneralReport?.edges;
  const total = cpaGeneralReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaGeneralReport?.pageInfo.endCursor;
  const hasNextPage = cpaGeneralReport?.pageInfo.hasNextPage;

  const cpaGeneralReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    cpaGeneralReportList,
    hasTotalRow,
    refetch,
    loadReport: () =>
      loadCpaGeneralReport({
        variables: { ...reportVariables, search: searchValue },
      }),
  };
};
