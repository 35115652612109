export enum ColumnId {
  Id = 'id',
  Name = 'name',
  Email = 'email',
  TariffType = 'rewardTariffTypesInUse',
  Note = 'note.text',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Name]: 'Имя пользователя',
  [ColumnId.Email]: 'Почта',
  [ColumnId.TariffType]: 'Модель оплаты',
  [ColumnId.Note]: 'Заметка',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Name,
  ColumnId.Email,
  ColumnId.TariffType,
  ColumnId.Note,
];
