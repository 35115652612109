import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { PartnerReferralLinkMediaItemsData } from '../queries/generated/PartnerReferralLinkMediaItemsData';
import { PartnerReferralLinkMediaItem } from '../../PartnerReferralLinkMediaItem';

interface UseOpenReferralLinkDetailsModalResult<T> {
  openReferralLinkDetailsModal: (arg: T, initiatorId: string) => void;
}

export const useOpenReferralLinkDetailsModal = <T extends object>(
  partnerId: string
): UseOpenReferralLinkDetailsModalResult<T> => {
  const { dispatch } = useContext(ModalContext);

  const openReferralLinkDetailsModal = useCallback(
    (referralLinkMediaItem: T, initiatorId: string) => {
      const { id: referralLinkMediaItemId } =
        referralLinkMediaItem as PartnerReferralLinkMediaItemsData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(referralLinkMediaItemId),
          subTitle: 'Медиа элемент',
          content: (
            <PartnerReferralLinkMediaItem
              partnerId={partnerId}
              referralLinkId={referralLinkMediaItemId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, partnerId]
  );

  return {
    openReferralLinkDetailsModal,
  };
};
