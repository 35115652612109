import { FC } from 'react';
import { useParams } from 'react-router';
import { useGetWithdrawalsColumns } from '../../components/WithdrawalsBlock/hooks/useGetWithdrawalsColumns';
import WithdrawalsTable from '../../components/WithdrawalsBlock/components/WithdrawalsTable/WithdrawalsTable';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const Withdrawals: FC = () => {
  const { columns } = useGetWithdrawalsColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <WithdrawalsTable
        playerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PlayerPageContainer>
  );
};
