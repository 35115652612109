/**
 * When using Apollo's `relayStylePagination` with `fetchPolicy: 'cache-and-network'`,
 * there's a known issue where the UI initially displays all cached items,
 * even if we're only requesting a subset of them. This can cause a "jump" in the UI,
 * as it first shows all cached items, then updates to show only the requested items
 * once the network request completes.
 *
 * This hook is a workaround for that issue. It slices the data to the desired amount
 * on the first page, preventing the UI "jump".
 *
 * Note:
 * This is a temporary solution until the issue is addressed in Apollo Client.
 * For more details on the problem, see the related issue:
 * https://github.com/apollographql/apollo-client/issues/11087
 */

import * as Apollo from '@apollo/client';
import { useState, useMemo, useCallback } from 'react';
import { OperationVariables } from '@apollo/client';
import { trimEdges } from '../helpers';
import { FetchMoreOptions } from '../types';

interface PaginationDataParams<TData, TVariables extends OperationVariables> {
  originalData: TData | undefined;
  originalFetchMore: <TFetchData, TFetchVariables>(
    fetchMoreOptions: FetchMoreOptions<TFetchVariables, TFetchData>
  ) => Promise<Apollo.ApolloQueryResult<TFetchData>>;
  options: Apollo.QueryHookOptions<TData, TVariables> | undefined;
}

export const usePaginationData = <
  TData,
  TVariables extends OperationVariables
>({
  originalData,
  originalFetchMore,
  options,
}: PaginationDataParams<TData, TVariables>) => {
  const [perPage, setPerPage] = useState(
    options?.variables?.first as number | undefined
  );
  const [isFirstPage, setIsFirstPage] = useState(true);

  const data = useMemo(() => {
    if (!originalData) {
      return undefined;
    }

    return isFirstPage && perPage
      ? trimEdges(originalData, perPage)
      : originalData;
  }, [originalData, isFirstPage, perPage]);

  const interceptedFetchMore = useCallback<
    <TFetchData, TFetchVariables>(
      fetchMoreOptions: FetchMoreOptions<TFetchVariables, TFetchData>
    ) => Promise<Apollo.ApolloQueryResult<TFetchData>>
  >(
    (fetchMoreOptions) => {
      setIsFirstPage(false);

      return originalFetchMore(fetchMoreOptions);
    },
    [originalFetchMore]
  );

  return {
    data,
    fetchMore: interceptedFetchMore,
    setPerPage,
  };
};
