import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetConvertCurrencyVariables = SchemaTypes.Exact<{
  from: SchemaTypes.SumInput;
  to: SchemaTypes.Scalars['Currency']['input'];
}>;


export type GetConvertCurrency = { __typename: 'Query', convertCurrency: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };


export const GetConvertCurrencyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConvertCurrency"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"from"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SumInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"to"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Currency"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"convertCurrency"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"from"},"value":{"kind":"Variable","name":{"kind":"Name","value":"from"}}},{"kind":"Argument","name":{"kind":"Name","value":"to"},"value":{"kind":"Variable","name":{"kind":"Name","value":"to"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetConvertCurrency__
 *
 * To run a query within a React component, call `useGetConvertCurrency` and pass it any options that fit your needs.
 * When your component renders, `useGetConvertCurrency` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConvertCurrency({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetConvertCurrency(baseOptions: ApolloReactHooks.QueryHookOptions<GetConvertCurrency, GetConvertCurrencyVariables> & ({ variables: GetConvertCurrencyVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetConvertCurrency, GetConvertCurrencyVariables>(GetConvertCurrencyDocument, options);
      }
export function useGetConvertCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConvertCurrency, GetConvertCurrencyVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetConvertCurrency, GetConvertCurrencyVariables>(GetConvertCurrencyDocument, options);
        }
export function useGetConvertCurrencySuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetConvertCurrency, GetConvertCurrencyVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetConvertCurrency, GetConvertCurrencyVariables>(GetConvertCurrencyDocument, options);
        }
export type GetConvertCurrencyHookResult = ReturnType<typeof useGetConvertCurrency>;
export type GetConvertCurrencyLazyQueryHookResult = ReturnType<typeof useGetConvertCurrencyLazyQuery>;
export type GetConvertCurrencySuspenseQueryHookResult = ReturnType<typeof useGetConvertCurrencySuspenseQuery>;
export type GetConvertCurrencyQueryResult = Apollo.QueryResult<GetConvertCurrency, GetConvertCurrencyVariables>;