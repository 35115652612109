import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCompanyReferralLinkMediaItemNameVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateReferralLinkMediaItemNameInput;
}>;


export type UpdateCompanyReferralLinkMediaItemName = { __typename: 'Mutation', updateReferralLinkMediaItemName: { __typename: 'UpdateReferralLinkMediaItemNamePayload', referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string } } };


export const UpdateCompanyReferralLinkMediaItemNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompanyReferralLinkMediaItemName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateReferralLinkMediaItemNameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateReferralLinkMediaItemName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateCompanyReferralLinkMediaItemNameMutationFn = Apollo.MutationFunction<UpdateCompanyReferralLinkMediaItemName, UpdateCompanyReferralLinkMediaItemNameVariables>;

/**
 * __useUpdateCompanyReferralLinkMediaItemName__
 *
 * To run a mutation, you first call `useUpdateCompanyReferralLinkMediaItemName` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyReferralLinkMediaItemName` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyReferralLinkMediaItemName, { data, loading, error }] = useUpdateCompanyReferralLinkMediaItemName({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyReferralLinkMediaItemName(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyReferralLinkMediaItemName, UpdateCompanyReferralLinkMediaItemNameVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCompanyReferralLinkMediaItemName, UpdateCompanyReferralLinkMediaItemNameVariables>(UpdateCompanyReferralLinkMediaItemNameDocument, options);
      }
export type UpdateCompanyReferralLinkMediaItemNameHookResult = ReturnType<typeof useUpdateCompanyReferralLinkMediaItemName>;
export type UpdateCompanyReferralLinkMediaItemNameMutationResult = Apollo.MutationResult<UpdateCompanyReferralLinkMediaItemName>;
export type UpdateCompanyReferralLinkMediaItemNameMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyReferralLinkMediaItemName, UpdateCompanyReferralLinkMediaItemNameVariables>;