import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { faArrowAltToTop } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import { WithdrawalModalSteps } from '../../../types';
import { WithdrawalItemContext } from '../../../store';

interface UseWithdrawalSidebarStepsResult {
  withdrawalId: string;
}

export const useWithdrawalSidebarSteps = ({
  withdrawalId,
}: UseWithdrawalSidebarStepsResult) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { state: withdrawalState } = useContext(WithdrawalItemContext);

  const { paymentId } = withdrawalState;
  const { paymentPaidSum } = withdrawalState;

  useEffect(() => {
    if (state.step === WithdrawalModalSteps.CreatePayment) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание платежа',
          subTitle: `Вывод #${withdrawalId}`,
          previousStep: WithdrawalModalSteps.BaseStep,
        },
      });
    } else if (
      state.step === WithdrawalModalSteps.AcceptPayment &&
      paymentId &&
      paymentPaidSum
    ) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Выполнение платежа',
          subTitle: `#${paymentId}`,
          previousStep: WithdrawalModalSteps.BaseStep,
        },
      });
    } else {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          iconConfig: {
            icon: faArrowAltToTop,
            iconColor: CardIconColor.RED,
          },
          title: `#${withdrawalId}`,
          subTitle: 'Вывод',
        },
      });
    }
  }, [modalDispatch, state.step, withdrawalId, paymentId, paymentPaidSum]);
};
