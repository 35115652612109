import { useModalManageData } from 'ui/Modal';
import { ContactForCommunicationType } from 'commonTypes';
import { useGetCompanyAdditionalData } from '../../../queries/generated/GetCompanyAdditionalData';

interface UseGetCompanyPersonalDataParams {
  id: string;
}

interface UseGetCompanyPersonalDataResult {
  website?: string | null;
  type?: ContactForCommunicationType;
  value?: string;
}

export const useGetCompanyPersonalData = ({
  id,
}: UseGetCompanyPersonalDataParams): UseGetCompanyPersonalDataResult => {
  const { data, loading, error } = useGetCompanyAdditionalData({
    variables: {
      id,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-company-additional-data',
    },
    hasData: !!data,
  });

  return {
    website: data?.company.website,
    type: data?.company.contactForCommunication.type,
    value: data?.company.contactForCommunication.value,
  };
};
