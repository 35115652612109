import { PLAYER_HEADER_HEIGHT } from '../const';

export const getCountByWindowHeight = (
  itemHeight: number,
  headerHeight?: number
) => {
  const windowHeight = window.innerHeight;
  const playerHeaderHeight =
    headerHeight !== undefined ? headerHeight : PLAYER_HEADER_HEIGHT;

  return Math.ceil((windowHeight - playerHeaderHeight) / itemHeight);
};

export enum Heights {
  defaultCellHeight = 46,
}
