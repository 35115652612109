import {
  faArrowAltToBottom,
  faArrowAltToTop,
  faBan,
  faGift,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import { RestrictionTypeName } from 'commonTypes';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { UserPrivilege } from 'generatedUserPrivilege';

interface LastRestriction {
  canceledAt?: string | null;
  bypassRestrictionIfApproved?: boolean;
}

export const restrictionTypeMap: { [key: string]: string } = {
  [RestrictionTypeName.ReceiveGift]: 'Получение бонусов',
  [RestrictionTypeName.ChargeBack]: 'Chargeback',
  [RestrictionTypeName.Withdrawal]: 'Вывод средств',
  [RestrictionTypeName.BadBank]: 'Badbank',
};

export const bypassRestrictionIfApprovedTitle: { [key: string]: string } = {
  [RestrictionTypeName.ReceiveGift]:
    'Разрешено начисление с\u00a0согласованием',
  [RestrictionTypeName.Withdrawal]: 'Разрешен вывод с\u00a0согласованием',
};

export const bypassRestrictionIfApprovedText: { [key: string]: string } = {
  [RestrictionTypeName.ReceiveGift]:
    'Администратор может начислить «Бонус от\u00a0казино» и\u00a0«Фриспины от\u00a0казино» с\u00a0подтверждением согласования.',
  [RestrictionTypeName.Withdrawal]:
    'Администратор может создать платеж на\u00a0вывод с\u00a0подтверждением согласования.',
};

export const getRestrictionIconByType = (
  restrictionTypeName?: RestrictionTypeName
): IconDefinition => {
  switch (restrictionTypeName) {
    case RestrictionTypeName.ReceiveGift:
      return faGift;
    case RestrictionTypeName.Withdrawal:
      return faArrowAltToTop;
    case RestrictionTypeName.ChargeBack:
      return faArrowAltToBottom;
    case RestrictionTypeName.BadBank:
      return faArrowAltToBottom;
    default:
      return faArrowAltToBottom;
  }
};

export const getStatusAndIconConfig = (
  lastRestriction?: LastRestriction | null
): {
  status: string;
  iconConfig: {
    icon: IconDefinition;
    iconColor: CardIconColor;
  };
} => {
  if (lastRestriction) {
    if (lastRestriction.canceledAt) {
      return {
        status: `Отменено`,
        iconConfig: { icon: faBan, iconColor: CardIconColor.GREEN },
      };
    }

    return {
      status: 'Активно',
      iconConfig: {
        icon: faSpinner,
        iconColor: lastRestriction.bypassRestrictionIfApproved
          ? CardIconColor.ORANGE
          : CardIconColor.RED,
      },
    };
  }

  return {
    status: 'Не устанавливалось',
    iconConfig: {
      icon: faSpinner,
      iconColor: CardIconColor.GREEN,
    },
  };
};

export const isCancelable = (type: RestrictionTypeName): UserPrivilege => {
  switch (type) {
    case RestrictionTypeName.ChargeBack:
      return UserPrivilege.CancelChargebackRestriction;
    case RestrictionTypeName.ReceiveGift:
      return UserPrivilege.CancelReceiveGiftRestriction;
    case RestrictionTypeName.BadBank:
      return UserPrivilege.CancelBadBankRestriction;
    case RestrictionTypeName.Withdrawal:
      return UserPrivilege.CancelWithdrawalRestriction;

    default:
      return UserPrivilege.CancelChargebackRestriction;
  }
};
