import { FC } from 'react';
import { CompanyAffiliateApplicationStatus } from 'generatedGraphql';
import { ProcessedAffiliates } from './components/ProcessedAffiliates';
import { DeclineApplication } from '../DeclineApplication';
import { AffiliatesForm } from './components/AffiliatesForm';
import { UnEditableAffiliates } from './components/UnEditableAffiliates';
import { GetCompanyAffiliateApplication } from '../../queries/generated/GetCompanyAffiliateApplication';

interface Props {
  affiliates: GetCompanyAffiliateApplication['companyAffiliateApplication']['items'];
  status: CompanyAffiliateApplicationStatus;
  isEditable: boolean;
}

export const AffiliatesTab: FC<Props> = ({
  affiliates,
  status,
  isEditable,
}) => (
  <>
    {status === CompanyAffiliateApplicationStatus.Active && (
      <ProcessedAffiliates affiliates={affiliates} />
    )}
    {status === CompanyAffiliateApplicationStatus.Declined && (
      <DeclineApplication />
    )}
    {status === CompanyAffiliateApplicationStatus.InProgress && (
      <AffiliatesForm isEditable={isEditable} affiliates={affiliates} />
    )}
    {(status === CompanyAffiliateApplicationStatus.Pending ||
      status === CompanyAffiliateApplicationStatus.Unknown) && (
      <UnEditableAffiliates affiliates={affiliates} />
    )}
  </>
);
