import errorToast from 'ui/Toast/ErrorToast';
import { SuspectState } from 'generatedGraphql';
import { useSuspectQualifiedReferral } from '../queries/generated/SuspectQualifiedReferral';
import { useUnsuspectQualifiedReferral } from '../queries/generated/UnsuspectQualifiedReferral';

interface UseSuspiciousReferralActionsParams {
  id: string;
}

export const useSuspiciousReferralActions = ({
  id,
}: UseSuspiciousReferralActionsParams) => {
  const [suspectQualifiedReferral, { loading: suspectLoading }] =
    useSuspectQualifiedReferral();

  const [unsuspectQualifiedReferral, { loading: unsuspectLoading }] =
    useUnsuspectQualifiedReferral();

  const suspectReferral = () => {
    suspectQualifiedReferral({
      variables: { input: { id } },
      optimisticResponse: {
        __typename: 'Mutation',
        suspectQualifiedReferral: {
          referral: {
            id,
            suspectState: SuspectState.Suspicious,
            __typename: 'QualifiedReferral',
          },
          __typename: 'SuspectQualifiedReferralPayload',
        },
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'suspect-qualified-referral-error',
        });
      },
    });
  };

  const unsuspectReferral = () => {
    unsuspectQualifiedReferral({
      variables: { input: { id } },
      optimisticResponse: {
        __typename: 'Mutation',
        unsuspectQualifiedReferral: {
          referral: {
            id,
            suspectState: SuspectState.Eligible,
            __typename: 'QualifiedReferral',
          },
          __typename: 'UnsuspectQualifiedReferralPayload',
        },
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'unsuspect-qualified-referral-error',
        });
      },
    });
  };

  return {
    suspectReferral,
    suspectLoading,
    unsuspectReferral,
    unsuspectLoading,
  };
};
