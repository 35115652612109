import { nbsp } from 'src/const';

interface DefineMessageByAffiliatesCount {
  isCompleted: boolean;
  affiliatesCount: number;
}

export const defineMessageByAffiliatesCount = ({
  isCompleted,
  affiliatesCount,
}: DefineMessageByAffiliatesCount) => {
  if (isCompleted) {
    return affiliatesCount > 1 ? 'Партнеры добавлены' : 'Партнер добавлен';
  }

  return affiliatesCount > 1
    ? `Партнеры не${nbsp}были добавлены`
    : `Партнер не${nbsp}был добавлен`;
};
