import NowrapCell from 'ui/Table/components/NowrapCell';
import { CopyTableRow } from 'ui/CopyTableRow';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { MediaItemReferralLinkData } from 'src/queries/generated/MediaItemReferralLinkData';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { ownerTypeNameMap } from 'src/const';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { ColumnId, mapColumnIdToName, columnsIds } from '../const';

const columnHelper = createColumnHelper<MediaItemReferralLinkData>();

const columnsSource: ColumnsSource<MediaItemReferralLinkData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Title, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
  }),
  columnHelper.accessor(ColumnId.MediaCampaign, {
    id: ColumnId.MediaCampaign,
    header: mapColumnIdToName[ColumnId.MediaCampaign],
    cell: ({ row }) => {
      const { name, url } = row.original.mediaCampaign;

      return MultilineCellWithIcon({
        title: name,
        subTitle: url,
      });
    },
  }),
  columnHelper.accessor(ColumnId.AccountType, {
    id: ColumnId.AccountType,
    header: mapColumnIdToName[ColumnId.AccountType],
    cell: ({ row }) => ownerTypeNameMap[row.original.owner.__typename],
  }),
  columnHelper.accessor(ColumnId.AffiliateName, {
    id: ColumnId.AffiliateName,
    header: mapColumnIdToName[ColumnId.AffiliateName],
  }),
  columnHelper.accessor(ColumnId.ReferralCode, {
    id: ColumnId.ReferralCode,
    header: mapColumnIdToName[ColumnId.ReferralCode],
    cell: ({ row }) => (
      <CopyTableRow
        value={row.original.code}
        toastText="Реферальный код скопирован"
      />
    ),
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetMediaItemsReferralLinksColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
