import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetCompanyOutgoingMoneyTransfersBlockLazyQuery } from '../queries/generated/GetCompanyOutgoingMoneyTransfersBlock';
import { useGetCompanyOutgoingMoneyTransfersLazyQuery } from '../queries/generated/GetCompanyOutgoingMoneyTransfers';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetOutgoingMoneyTransfersByTableSize = (
  companyId: string,
  isFullScreenTable: boolean
) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetCompanyOutgoingMoneyTransfers = isFullScreenTable
    ? useGetCompanyOutgoingMoneyTransfersLazyQuery
    : useGetCompanyOutgoingMoneyTransfersBlockLazyQuery;

  const [
    loadOutgoingMoneyTransfers,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetCompanyOutgoingMoneyTransfers({
    variables: {
      id: companyId,
      first: countPerPageByTableSize,
    },
  });

  return {
    loadOutgoingMoneyTransfers,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
