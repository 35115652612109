import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AddCompanyAffiliatesVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AddCompanyAffiliatesInput;
}>;


export type AddCompanyAffiliates = { __typename: 'Mutation', addCompanyAffiliates: { __typename: 'AddCompanyAffiliatesPayload', affiliates: Array<{ __typename: 'CompanyAffiliate', createdAt: string, id: string, mediaItemsCount: number, name: string, note?: { __typename: 'Note', text: string } | null }> } };


export const AddCompanyAffiliatesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddCompanyAffiliates"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddCompanyAffiliatesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addCompanyAffiliates"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"affiliates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mediaItemsCount"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type AddCompanyAffiliatesMutationFn = Apollo.MutationFunction<AddCompanyAffiliates, AddCompanyAffiliatesVariables>;

/**
 * __useAddCompanyAffiliates__
 *
 * To run a mutation, you first call `useAddCompanyAffiliates` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyAffiliates` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyAffiliates, { data, loading, error }] = useAddCompanyAffiliates({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyAffiliates(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCompanyAffiliates, AddCompanyAffiliatesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddCompanyAffiliates, AddCompanyAffiliatesVariables>(AddCompanyAffiliatesDocument, options);
      }
export type AddCompanyAffiliatesHookResult = ReturnType<typeof useAddCompanyAffiliates>;
export type AddCompanyAffiliatesMutationResult = Apollo.MutationResult<AddCompanyAffiliates>;
export type AddCompanyAffiliatesMutationOptions = Apollo.BaseMutationOptions<AddCompanyAffiliates, AddCompanyAffiliatesVariables>;