import FormRow from 'ui/FormRow';
import { ToggleBlock } from 'commonComponents/ToggleBlock';
import DropdownWithQuery from 'ui/DropdownWithQuery/DropdownWithQuery';
import Input from 'ui/Input';
import { useFormikContext } from 'formik';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { useRef } from 'react';
import { throttle } from 'lodash-es';
import { GameEdge } from 'generatedGraphql';
import { useGetGamesLazyQuery } from 'src/queries/generated/GetGames';
import { CurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { CreatePromoCodeValues } from '../../../../types';
import { useToggleBlockHandlers } from '../../hooks/useToggleBlockHandlers';
import { useGetFreespinGameByDefault } from '../../hooks/useGetFreespinGameByDefault';
import { useSetFreespinGameName } from '../../hooks/useSetFreespinGameName';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';

const SEARCH_MIN_LENGTH = 3;

export const FreespinToggleBlock = () => {
  const { values } = useFormikContext<CreatePromoCodeValues>();
  const { onFreespinBlockToggleHandler } = useToggleBlockHandlers();

  const [loadGames, { data: getGamesResult, loading: getGamesLoading }] =
    useGetGamesLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const loadGamesWithThrottle = useRef(
    throttle(async (inputString: string) => {
      await loadGames({
        variables: {
          search: inputString,
          first: 10,
        },
      });
    }, 1000)
  ).current;

  const gameOptions = getGamesResult?.games.edges.map(
    ({ node: { id, name } }: GameEdge) => ({
      id,
      label: name,
    })
  );

  const { firstGameByDefault } = useGetFreespinGameByDefault();
  const { onFreespinGameChangeHandler } = useSetFreespinGameName();

  return (
    <FormRow isWide>
      <ToggleBlock
        name={FieldName.ConnectionWithFreespins}
        label={
          mapCreatePromoCodeFieldToTitle[FieldName.ConnectionWithFreespins]
        }
        isOpen={values[FieldName.ConnectionWithFreespins]}
        onToggle={(isChecked) => {
          onFreespinBlockToggleHandler(isChecked);
        }}
      >
        <FormRow isWide>
          <DropdownWithQuery
            name={FieldName.FreespinGameId}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinGameId]}
            defaultValue={firstGameByDefault?.name}
            loadList={loadGamesWithThrottle}
            loading={getGamesLoading}
            searchMinLength={SEARCH_MIN_LENGTH}
            options={gameOptions}
            onChange={(gameId) => {
              onFreespinGameChangeHandler(gameOptions, String(gameId));
            }}
          />
        </FormRow>

        <FormRow>
          <CurrencyDropdown
            name={FieldName.FreespinCurrency}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinCurrency]}
          />
          <Input.Number
            name={FieldName.FreespinCount}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinCount]}
            placeholder="Введите значение"
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={3}
          />
        </FormRow>

        <FormRow>
          <Input.Number
            name={FieldName.FreespinWager}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinWager]}
            placeholder="Введите значение"
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={2}
          />
          <Input.Number
            name={FieldName.FreespinMinBalance}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinMinBalance]}
            placeholder="Введите значение"
            customMaxLength={15}
            decimalScale={1}
          />
        </FormRow>
      </ToggleBlock>
    </FormRow>
  );
};
