import { useParams } from 'react-router';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { PartnerMediaItemsBlock } from '../../components/PartnerMediaItemsBlock';

export const PartnerMediaItems = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <PartnerMediaItemsBlock
        id={id}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </PartnerPageContainer>
  );
};
