import { FC, useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import styles from './ClientHeaderContent.module.scss';

interface Props {
  refetchClientData(): void;
}

export const ClientHeaderContent: FC<Props> = ({ refetchClientData }) => {
  const { state } = useContext(BlockContext);

  return (
    <div className={styles.headerContent}>
      <ActionButton
        actionType={ButtonType.Update}
        hidden={!state.isOpened}
        disabled={state.isLoading}
        onClick={refetchClientData}
      />
    </div>
  );
};
