import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DisablePlayerInternalOutgoingMoneyTransfersVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DisablePlayerInternalOutgoingMoneyTransfersInput;
}>;


export type DisablePlayerInternalOutgoingMoneyTransfers = { __typename: 'Mutation', disablePlayerInternalOutgoingMoneyTransfers: { __typename: 'DisablePlayerInternalOutgoingMoneyTransfersPayload', player: { __typename: 'Player', id: string, internalOutgoingMoneyTransfersEnabled: boolean } } };


export const DisablePlayerInternalOutgoingMoneyTransfersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DisablePlayerInternalOutgoingMoneyTransfers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DisablePlayerInternalOutgoingMoneyTransfersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disablePlayerInternalOutgoingMoneyTransfers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"internalOutgoingMoneyTransfersEnabled"}}]}}]}}]}}]} as unknown as DocumentNode;
export type DisablePlayerInternalOutgoingMoneyTransfersMutationFn = Apollo.MutationFunction<DisablePlayerInternalOutgoingMoneyTransfers, DisablePlayerInternalOutgoingMoneyTransfersVariables>;

/**
 * __useDisablePlayerInternalOutgoingMoneyTransfers__
 *
 * To run a mutation, you first call `useDisablePlayerInternalOutgoingMoneyTransfers` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePlayerInternalOutgoingMoneyTransfers` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePlayerInternalOutgoingMoneyTransfers, { data, loading, error }] = useDisablePlayerInternalOutgoingMoneyTransfers({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisablePlayerInternalOutgoingMoneyTransfers(baseOptions?: ApolloReactHooks.MutationHookOptions<DisablePlayerInternalOutgoingMoneyTransfers, DisablePlayerInternalOutgoingMoneyTransfersVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DisablePlayerInternalOutgoingMoneyTransfers, DisablePlayerInternalOutgoingMoneyTransfersVariables>(DisablePlayerInternalOutgoingMoneyTransfersDocument, options);
      }
export type DisablePlayerInternalOutgoingMoneyTransfersHookResult = ReturnType<typeof useDisablePlayerInternalOutgoingMoneyTransfers>;
export type DisablePlayerInternalOutgoingMoneyTransfersMutationResult = Apollo.MutationResult<DisablePlayerInternalOutgoingMoneyTransfers>;
export type DisablePlayerInternalOutgoingMoneyTransfersMutationOptions = Apollo.BaseMutationOptions<DisablePlayerInternalOutgoingMoneyTransfers, DisablePlayerInternalOutgoingMoneyTransfersVariables>;