import { FC, TextareaHTMLAttributes, useEffect, useId, useRef } from 'react';
import classnames from 'classnames';
import { FormFieldWrapper } from 'ui/FormFieldWrapper';
import styles from './Textarea.module.scss';
import { useInputManagement } from '../hooks/useInputManagement';
import { CommonProps } from '../types';

type OriginalTextareaAttributes = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'onChange' | 'name'
>;

interface Props extends CommonProps, OriginalTextareaAttributes {
  isResizable?: boolean;
  autoHeight?: boolean;
  defaultHeight?: number;
}

export const Textarea: FC<Props> = ({
  name,
  label,
  wrapperClassName,
  inputClassName,
  autoFocus,
  autoHeight = true,
  disabled,
  onChange,
  isResizable,
  defaultHeight = 48,
  ...rest
}) => {
  const id = useId();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const { handleChange, handleKeyDown, handleBlur, field, touched, error } =
    useInputManagement({ name, inputRef, autoFocus, onChange });

  useEffect(() => {
    const textarea = inputRef.current;

    if (!autoHeight || isResizable || !textarea) {
      return;
    }

    /**
     * If the height is not reset to auto, the textarea starts to "jump",
     * if the number of lines in the textarea is more than one.
     * Therefore, it is necessary to reset and set these values through .style
     */
    const extraHeight = textarea.offsetHeight - textarea.clientHeight;

    textarea.style.height = 'auto';

    let newHeight = textarea.scrollHeight + extraHeight;

    if (newHeight < defaultHeight) {
      newHeight = defaultHeight;
    }

    textarea.style.height = `${newHeight}px`;
  }, [field.value, autoHeight, defaultHeight, isResizable]);

  return (
    <FormFieldWrapper
      name={name}
      idForLabel={id}
      label={label}
      wrapperClassName={wrapperClassName}
    >
      <textarea
        name={field.name}
        id={id}
        rows={1}
        ref={inputRef}
        value={field.value || ''}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={{
          minHeight: isResizable ? `${defaultHeight}px` : undefined,
        }}
        className={classnames(
          inputClassName,
          styles.textarea,
          error && touched && styles.error,
          isResizable && styles.resizable
        )}
        disabled={disabled}
        {...rest}
      />
    </FormFieldWrapper>
  );
};
