import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { ProgressBarWagerType } from 'src/components/ProgressBarWager/const';
import { DataCard } from 'commonComponents/DataCard';
import { useGetTransfer } from './queries/generated/GetTransfer';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';
import { getCounterpartyTitleByType } from '../../helpers';
import ProgressBarWager from '../../../../../../components/ProgressBarWager';

interface TransferItemType {
  transferId: string;
  playerId: string;
}

const TransferItem: FC<TransferItemType> = ({ transferId, playerId }) => {
  const { data, loading, error } = useGetTransfer({
    variables: {
      id: transferId,
      currentPlayerId: playerId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-transfer-error' },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const {
    createdAt,
    counterparty: { name, email, phone, moneyTransferAccount },
    sum: { amount, currency },
    type,
  } = data.moneyTransfer;

  const { wager } = data.moneyTransfer;

  return (
    <DataCard
      customSubHeader={
        wager && (
          <ProgressBarWager wager={wager} type={ProgressBarWagerType.Full} />
        )
      }
      rows={[
        {
          title: 'Номер счета',
          value: moneyTransferAccount,
        },
        {
          title: getCounterpartyTitleByType(type),
          value: email || phone,
        },
        {
          title: 'Имя на\u00a0сайте',
          value: name,
        },
        {
          title: 'Сумма',
          value: formatSumWithCurrency(amount, currency),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
    />
  );
};

export default TransferItem;
