import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyMediaCampaignsTable } from '../../components/CompanyMediaCampaignsBlock/components/CompanyMediaCampaignsTable';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { useGetCompanyMediaCampaignsColumns } from '../../components/CompanyMediaCampaignsBlock/components/CompanyMediaCampaignsTable/hooks/useGetCompanyMediaCampaignsColumns';

export const CompanyMediaCampaigns: FC = () => {
  const { columns } = useGetCompanyMediaCampaignsColumns();
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <CompanyMediaCampaignsTable
        companyId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </CompanyPageContainer>
  );
};
