import { FC, useState } from 'react';
import {
  DatePickerWithRange,
  DateRanges,
} from 'commonComponents/DatePickerWithRange';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { useGetPeriodOfStatistic } from '../../../../hooks/useGetPeriodOfStatistic';
import styles from './StatisticDatePeriod.module.scss';
import { DatePeriodLabel } from '../DatePeriodLabel';
import { useHandleSubmit } from './hooks/useHandleSubmit';

export const StatisticDatePeriod: FC = () => {
  const period = useGetPeriodOfStatistic();
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const handleSubmit = useHandleSubmit(setIsDatePickerOpened);

  const handleClickOutside = useClickOutside(() => {
    setIsDatePickerOpened(false);
  });

  const availableDateRanges = [
    DateRanges.CurrentMonth,
    DateRanges.PrevMonth,
    DateRanges.Today,
    DateRanges.Yesterday,
    DateRanges.TwoLastWeek,
    DateRanges.LastMonth,
    DateRanges.AllTime,
  ];

  return (
    <div className={styles.datePickerContainer}>
      <ClickOutside callback={handleClickOutside}>
        <DatePeriodLabel
          isDatePickerOpened={isDatePickerOpened}
          period={period}
          setIsDatePickerOpened={setIsDatePickerOpened}
        />
        {isDatePickerOpened && (
          <DatePickerWithRange
            availableDateRanges={availableDateRanges}
            wrapperClassName={styles.datePickerWithRange}
            startDateInitial={new Date(period.from)}
            endDateInitial={new Date(period.to)}
            onDateSubmit={handleSubmit}
            name="statisticPeriod"
          />
        )}
      </ClickOutside>
    </div>
  );
};
