import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyReferralLinksData } from '../queries/generated/CompanyReferralLinksData';
import { CompanyReferralLinkMediaItem } from '../../CompanyReferralLinkMediaItem';

interface UseOpenReferralLinkDetailsModalResult<T> {
  openReferralLinkDetailsModal: (arg: T, initiatorId: string) => void;
}

export const useOpenReferralLinkDetailsModal = <T extends object>(
  companyId: string
): UseOpenReferralLinkDetailsModalResult<T> => {
  const { dispatch } = useContext(ModalContext);

  const openReferralLinkDetailsModal = useCallback(
    (referralLinkMediaItem: T, initiatorId: string) => {
      const { id: referralLinkMediaItemId } =
        referralLinkMediaItem as CompanyReferralLinksData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(referralLinkMediaItemId),
          subTitle: 'Медиа элемент',
          content: (
            <CompanyReferralLinkMediaItem
              companyId={companyId}
              referralLinkId={referralLinkMediaItemId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, companyId]
  );

  return {
    openReferralLinkDetailsModal,
  };
};
