import styles from './RouterLink.module.scss';

export enum RouterLinkSize {
  Small = 'small',
  Large = 'large',
}

export const mapRouterLinkSizeToClass: Record<RouterLinkSize, string> = {
  [RouterLinkSize.Small]: styles.small,
  [RouterLinkSize.Large]: styles.large,
};
