import { useCallback, useMemo } from 'react';
import { useGetOutgoingMoneyTransfersByTableSize } from './useGetOutgoingMoneyTransfersByTableSize';

interface UseGetOutgoingMoneyTransfersParams {
  partnerId: string;
  isFullScreenTable: boolean;
}

const COUNT_PER_PAGE = 10;

export const useGetOutgoingMoneyTransfers = <T>({
  partnerId,
  isFullScreenTable,
}: UseGetOutgoingMoneyTransfersParams) => {
  const {
    loadOutgoingMoneyTransfers,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  } = useGetOutgoingMoneyTransfersByTableSize(partnerId, isFullScreenTable);

  const outgoingMoneyTransfersList = useMemo(
    () =>
      data?.partner.outgoingMoneyTransfers.edges.map(
        ({ node }) => node
      ) as unknown as Array<T>,
    [data]
  );

  const hasNextPage = data?.partner.outgoingMoneyTransfers.pageInfo.hasNextPage;
  const endCursor = data?.partner.outgoingMoneyTransfers.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            id: partnerId,
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [partnerId, endCursor, fetchMore]
  );

  return {
    loadOutgoingMoneyTransfers,
    outgoingMoneyTransfersList,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    data,
  };
};
