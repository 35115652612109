import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CreateMediaCampaign } from '../components/CreateMediaCampaign';

export const useOpenModal = () => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(async () => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        title: `Создание медиа кампании`,
        content: <CreateMediaCampaign />,
      },
    });
  }, [modalDispatch]);

  return {
    openModal,
  };
};
