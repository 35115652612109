import { MediaItemTypeName } from 'commonTypes';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { getTariffType } from './getTariffType';

interface GetInitialValuesParams {
  referralLinkMediaItem: ReferralLinkData | null | undefined;
  companyApplicationId: string;
  referralLinkCodeInitial: string;
  companyName: string;
}

export const getInitialValues = ({
  referralLinkMediaItem,
  companyApplicationId,
  referralLinkCodeInitial,
  companyName,
}: GetInitialValuesParams) => ({
  id: companyApplicationId,
  referralLinkCode: referralLinkCodeInitial,
  referralLinkName: referralLinkMediaItem?.referralLinkName || '',
  mediaCampaignId: referralLinkMediaItem?.mediaCampaignId || '',
  companyName,
  typeMediaItem: MediaItemTypeName.ReferralLink,
  type: getTariffType(referralLinkMediaItem),
  revShareTariffId: referralLinkMediaItem?.revShareTariff.id || '',
  cpaTariffId: referralLinkMediaItem?.cpaTariff?.id || '',
});
