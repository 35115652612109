import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { ListAllOperations } from 'generatedGraphql';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { useEnableAllPostbacks as useEnableAllPostbacksMutation } from '../queries/generated/EnableAllPostbacks';

export const useEnableAllPostbacks = () => {
  const { dispatch } = useContext(ModalContext);

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Все постбеки не были включены',
      text: error.message,
      toastId: 'enable-all-postback-error',
    });
  };

  const handleComplete = () => {
    toast.success('Все постбеки включены');
    dispatch({ type: ModalActions.Close });
  };

  const [enableAllPostback, { loading }] = useEnableAllPostbacksMutation({
    onError: handleError,
    onCompleted: handleComplete,
    refetchQueries: [ListAllOperations.Query.GetPostbackDisabledNotifications],
  });

  return {
    enableAllPostback,
    loading,
  };
};
