import { RestrictionTypeName } from 'commonTypes';
import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { ListAllOperations } from 'generatedGraphql';
import { CreateRestrictionValues } from './validation';
import { useCreateWithdrawalRestriction } from './queries/generated/CreateWithdrawalRestriction';
import { useCreateChargeBackRestriction } from './queries/generated/CreateChargeBackRestriction';
import { useCreateBadBankRestriction } from './queries/generated/CreateBadBankRestriction';
import { useCreateReceiveGiftRestriction } from './queries/generated/CreateReceiveGiftRestriction';

interface Props {
  playerId: string;
  isFullScreenTable: boolean;
}

interface Value {
  create?: (values: CreateRestrictionValues) => void;
  loading?: boolean;
  error?: ApolloError;
}

export const useCreateRestrictionForm = ({
  playerId,
  isFullScreenTable,
}: Props): Value => {
  const { dispatch } = useContext(ModalContext);

  const [
    createReceiveGiftRestriction,
    {
      data: createReceiveGiftRestrictionData,
      loading: createReceiveGiftRestrictionLoading,
      error: createReceiveGiftRestrictionError,
    },
  ] = useCreateReceiveGiftRestriction({
    refetchQueries: [
      isFullScreenTable
        ? ListAllOperations.Query.GetRestrictions
        : ListAllOperations.Query.GetRestrictionsBlock,
    ],
  });

  const [
    createBadBankRestriction,
    {
      data: createBadBankRestrictionData,
      loading: createBadBankRestrictionLoading,
      error: createBadBankRestrictionError,
    },
  ] = useCreateBadBankRestriction({
    refetchQueries: [
      isFullScreenTable
        ? ListAllOperations.Query.GetRestrictions
        : ListAllOperations.Query.GetRestrictionsBlock,
    ],
  });

  const [
    createChargeBackRestriction,
    {
      data: createChargeBackRestrictionData,
      loading: createChargeBackRestrictionLoading,
      error: createChargeBackRestrictionError,
    },
  ] = useCreateChargeBackRestriction({
    refetchQueries: [
      isFullScreenTable
        ? ListAllOperations.Query.GetRestrictions
        : ListAllOperations.Query.GetRestrictionsBlock,
    ],
  });

  const [
    createWithdrawalRestriction,
    {
      data: createWithdrawalRestrictionData,
      loading: createWithdrawalRestrictionLoading,
      error: createWithdrawalRestrictionError,
    },
  ] = useCreateWithdrawalRestriction({
    refetchQueries: [
      isFullScreenTable
        ? ListAllOperations.Query.GetRestrictions
        : ListAllOperations.Query.GetRestrictionsBlock,
    ],
  });

  const create = (values: CreateRestrictionValues) => {
    switch (values.restrictionType) {
      case RestrictionTypeName.BadBank:
        createBadBankRestriction({
          variables: {
            input: {
              playerId,
              reason: values.reason,
            },
          },
        });
        break;
      case RestrictionTypeName.ChargeBack:
        createChargeBackRestriction({
          variables: {
            input: {
              playerId,
              reason: values.reason,
            },
          },
        });
        break;
      case RestrictionTypeName.ReceiveGift:
        if (values.reason) {
          createReceiveGiftRestriction({
            variables: {
              input: {
                playerId,
                reason: values.reason,
                bypassRestrictionIfApproved:
                  values.bypassRestrictionIfApproved || false,
              },
            },
          });
        }

        break;
      case RestrictionTypeName.Withdrawal:
        if (values.reason) {
          createWithdrawalRestriction({
            variables: {
              input: {
                playerId,
                bypassRestrictionIfApproved:
                  values.bypassRestrictionIfApproved || false,
                reason: values.reason,
              },
            },
          });
        }

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      createBadBankRestrictionData ||
      createWithdrawalRestrictionData ||
      createReceiveGiftRestrictionData ||
      createChargeBackRestrictionData
    ) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Ограничение установлено');
    }
  }, [
    createBadBankRestrictionData,
    createChargeBackRestrictionData,
    createReceiveGiftRestrictionData,
    createWithdrawalRestrictionData,
    dispatch,
  ]);

  return {
    create,
    loading:
      createBadBankRestrictionLoading ||
      createWithdrawalRestrictionLoading ||
      createReceiveGiftRestrictionLoading ||
      createChargeBackRestrictionLoading,
    error:
      createBadBankRestrictionError ||
      createWithdrawalRestrictionError ||
      createReceiveGiftRestrictionError ||
      createChargeBackRestrictionError,
  };
};
