import { FC } from 'react';
import { SidebarLoader } from 'ui/SidebarLoader';
import Error from 'ui/Error';
import { ReferralLinkMediaItemDataCard } from '../ReferralLinkMediaItemDataCard';
import { useGetPartnerReferralLinkMediaItem } from '../PartnerReferralLinkMediaItem/queries/generated/GetPartnerReferralLinkMediaItem';

interface Props {
  partnerId: string;
  referralLinkMediaItemId: string;
}

export const AboutReferralLinkMediaItem: FC<Props> = ({
  partnerId,
  referralLinkMediaItemId,
}) => {
  const { data, loading, error } = useGetPartnerReferralLinkMediaItem({
    variables: {
      partnerId,
      referralLinkMediaItemId,
    },
  });

  return (
    <SidebarLoader loading={loading}>
      {error && <Error error={error} />}
      <ReferralLinkMediaItemDataCard data={data} />
    </SidebarLoader>
  );
};
