import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useFormikContext } from 'formik';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';
import { CreatePromoCodeValues } from '../../../../types';
import styles from './DataCardBonusLink.module.scss';
import { formatSumWithCurrency } from '../../../../../../../../../../utils/accounting';

export const DataCardBonusLink: FC = () => {
  const { values } = useFormikContext<CreatePromoCodeValues>();

  return (
    <DataCard
      customSubHeader={
        <span className={styles.blockTitle}>Связь с&nbsp;бонусом</span>
      }
      rows={[
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusAmount],
          value: formatSumWithCurrency(
            values[FieldName.BonusAmount],
            values[FieldName.BonusCurrency]
          ),
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusWager],
          value: values[FieldName.BonusWager],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusCashout],
          value: values[FieldName.BonusCashout],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusMinBalance],
          value: values[FieldName.BonusMinBalance]
            ? formatSumWithCurrency(
                values[FieldName.BonusMinBalance],
                values[FieldName.BonusCurrency]
              )
            : null,
        },
      ]}
    />
  );
};
