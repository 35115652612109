import { FormikErrors } from 'formik';
import { UpdateMediaCampaignInput } from 'generatedGraphql';
import { checkIsValidUrl } from '../../../../utils/validators';

export const validate = (
  values: UpdateMediaCampaignInput
): FormikErrors<UpdateMediaCampaignInput> => {
  const errors: FormikErrors<UpdateMediaCampaignInput> = {};

  if (!values.name) {
    errors.name = 'Введите название медиа кампании';
  }

  if (values.name && values.name.length > 50) {
    errors.name = 'Название медиа кампании не может быть длиннее 50 символов';
  }

  if (!values.url) {
    errors.url = 'Введите URL медиа кампании';
  }

  if (values.url && !checkIsValidUrl(values.url)) {
    errors.url = 'Неверный формат';
  }

  if (values.url && values.url.length > 50) {
    errors.url = 'URL медиа кампании не может быть длиннее 50 символов';
  }

  return errors;
};
