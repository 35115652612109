import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { AffiliateRewardStatisticData } from '../../../../queries/generated/AffiliateRewardStatisticData';
import { SumData } from '../../../../../../queries/generated/SumData';
import { MarketingStatisticData } from '../../../../queries/generated/MarketingStatisticData';
import { RegisteredPlayerStatisticData } from '../../../../queries/generated/RegisteredPlayerStatisticData';
import { CpaPlayerStatisticData } from '../../../../queries/generated/CpaPlayerStatisticData';
import { CpaPlayerStatusStatisticData } from '../../../../queries/generated/CpaPlayerStatusStatisticData';
export type CpaGeneralReportTotalData = { __typename: 'CpaGeneralReportTotal', affiliateRewardStatistic: { __typename: 'AffiliateRewardStatistic', paid: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, upcoming: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, marketingStatistic: { __typename: 'MarketingStatistic', firstDeposits: number, hits: number, hosts: number, hostToRegConversion: any, redeposits: number, registrations: number, regToDepConversion: any }, playerRegisteredStatistic: { __typename: 'RegisteredPlayerStatistic', newDeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, newProfit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, newWithdrawal: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, playerStatistic: { __typename: 'CpaPlayerStatistic', allDeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, allRedeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, averageDeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, bonus: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, financialIndicators: { __typename: 'FinancialIndicators', ngr: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, profit: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, withdrawal: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, playerStatusStatistic: { __typename: 'CpaPlayerStatusStatistic', approved: number, canceled: number, hold: number, paid: number, qualified: number, rejected: number, revShare: number, unpaid: number } };

export const CpaGeneralReportTotalData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CpaGeneralReportTotalData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CpaGeneralReportTotal"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"affiliateRewardStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AffiliateRewardStatisticData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"marketingStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MarketingStatisticData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"playerRegisteredStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RegisteredPlayerStatisticData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"playerStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CpaPlayerStatisticData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"playerStatusStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CpaPlayerStatusStatisticData"}}]}}]}}]} as unknown as DocumentNode;