export const getRangePrevMonth = () => {
  const startDate = new Date();
  const endDate = new Date();

  startDate.setDate(1);
  startDate.setMonth(startDate.getMonth() - 1);
  endDate.setDate(0);

  const startRange = startDate;
  const endRange = endDate;

  return { startRange, endRange };
};
