import { useFormikContext } from 'formik';
import { Game } from 'generatedGraphql';
import errorToast from 'ui/Toast/ErrorToast';
import { useEffect } from 'react';
import { useGetGamesLazyQuery } from 'src/queries/generated/GetGames';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

interface useGetFreespinGameByDefaultResult {
  firstGameByDefault?: Game;
}

export const useGetFreespinGameByDefault =
  (): useGetFreespinGameByDefaultResult => {
    const { setFieldValue, values } = useFormikContext<CreatePromoCodeValues>();

    const [getFirstGameByDefault, { data: getGamesResult }] =
      useGetGamesLazyQuery({
        variables: {
          search: '',
          first: 1,
        },
        onCompleted: (games) => {
          const { id, name } = games.games.edges[0].node;

          setFieldValue(FieldName.FreespinGameId, id);
          setFieldValue(FieldName.FreespinGameName, name);
        },
        onError: (error) => {
          errorToast({
            header: error.name,
            text: error.message,
            toastId: 'getFirstGameErrorToast',
          });
        },
      });

    // Get game by default if Toggle block is open at first time
    useEffect(() => {
      if (values[FieldName.ConnectionWithFreespins] && !getGamesResult) {
        getFirstGameByDefault();
      }
    }, [getFirstGameByDefault, getGamesResult, values]);

    return {
      firstGameByDefault: getGamesResult?.games.edges[0].node,
    };
  };
