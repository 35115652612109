import { FC } from 'react';
import { MenuGroup, MenuItem } from 'commonComponents/Menu/components';
import { Link } from 'react-router-dom';
import {
  Group,
  GroupWithoutIsActive,
  MenuButtonData,
  MenuGroupItemData,
  MenuItemData,
  MenuLinkData,
} from '../../../../types';
import styles from './MenuGroupItemsList.module.scss';

interface Props {
  links: Array<MenuItemData | MenuGroupItemData>;
  subMenuClassName?: string;
  menuState: Array<Group>;
  toggleMenuDropdown: (group: GroupWithoutIsActive) => void;
  openMenuDropdownItem: (id: string) => void;
  selectMenuItem: (id: string) => void;
}

export const MenuGroupItemsList: FC<Props> = ({
  links,
  openMenuDropdownItem,
  toggleMenuDropdown,
  menuState,
  selectMenuItem,
  subMenuClassName,
}) => (
  <>
    {links.map((link) => {
      if ('group' in link) {
        const {
          title: groupTitle,
          icon: groupIcon,
          group,
        } = link as MenuGroupItemData;

        const sidebarGroupState = menuState.find(
          (item) => item.id === groupTitle
        );

        return (
          <MenuGroup
            openMenuDropdownItem={openMenuDropdownItem}
            toggleMenuDropdown={toggleMenuDropdown}
            isOpen={!!sidebarGroupState?.isOpen}
            isActive={!!sidebarGroupState?.isActive}
            key={groupTitle}
            groupIcon={groupIcon}
            groupTitle={groupTitle}
            group={group}
            className={styles.item}
            subMenuClassName={subMenuClassName}
          />
        );
      }

      if ('route' in link) {
        const { title, route, icon } = link as MenuLinkData;

        const sidebarItemState = menuState.find((item) => item.id === title);

        return (
          <Link className={styles.item} key={route} to={route}>
            <MenuItem
              onClick={() => selectMenuItem(title)}
              isActive={!!sidebarItemState?.isActive}
              isOpen={!!sidebarItemState?.isOpen}
              isDropdown={false}
              title={title}
              icon={icon}
            />
          </Link>
        );
      }

      if ('onClick' in link) {
        const { title, onClick, icon } = link as MenuButtonData;

        return (
          <MenuItem
            key={title}
            onClick={onClick}
            isActive={false}
            isOpen={false}
            isDropdown={false}
            title={title}
            icon={icon}
          />
        );
      }

      return null;
    })}
  </>
);
