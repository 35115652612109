import classnames from 'classnames';
import { Collapse as ReactCollapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import ListLoader from 'ui/ListLoader';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './Block.module.scss';
import { Storage } from '../../utils/storage';
import { BlockContext, setInitialDataAction, toggleBlockAction } from './store';
import { ErrorType } from './store/types';

interface Props {
  title: string;
  id: string;
  extendedHeaderClassname?: string;
  headerContent?: ReactNode;
  extendedHeader?: ReactNode;
  subHeader?: ReactNode;
  isEmpty?: boolean;
  emptyText?: string;
  shrinkLoaderWrapper?: boolean;
  shouldReturnToPrevPage?: boolean;
  customPathToPrevPage?: string;
  isPermanentOpened?: boolean;
}

const Block: FC<PropsWithChildren<Props>> = ({
  title,
  children,
  headerContent,
  extendedHeader,
  extendedHeaderClassname,
  subHeader,
  isEmpty,
  emptyText,
  id,
  shrinkLoaderWrapper = true,
  shouldReturnToPrevPage,
  customPathToPrevPage,
  isPermanentOpened = false,
}) => {
  const { state, dispatch } = useContext(BlockContext);

  const navigate = useNavigate();
  const location = useLocation();

  const pathToPrevPage = location.pathname.slice(
    0,
    location.pathname.lastIndexOf('/')
  );
  const goBackCallback = useCallback(
    () => navigate(customPathToPrevPage || pathToPrevPage),
    [customPathToPrevPage, navigate, pathToPrevPage]
  );

  const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    if (
      e.button === 0 &&
      !window.getSelection()?.toString() &&
      !(e.target as HTMLElement).closest('button') &&
      !isPermanentOpened
    ) {
      dispatch(toggleBlockAction());
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(
        setInitialDataAction({
          blockId: id,
          isPermanentOpened: !!shouldReturnToPrevPage || isPermanentOpened,
        })
      );

      if (shouldReturnToPrevPage || isPermanentOpened) {
        dispatch(toggleBlockAction());
      }
    }

    const openedBlocks = Storage.blocksStateStorage.getOpenedBlocks();

    if (openedBlocks[id] && !shouldReturnToPrevPage) {
      dispatch(toggleBlockAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.block}>
      <div>
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <div
          className={classnames(
            styles.headerContainer,
            !shouldReturnToPrevPage && !isPermanentOpened && styles.clickable
          )}
          role="button"
          onMouseUp={!shouldReturnToPrevPage ? onMouseUp : undefined}
        >
          {shouldReturnToPrevPage && (
            <div className={styles.goBackContainer}>
              <ActionButton
                onClick={goBackCallback}
                actionType={ButtonType.GoBack}
              />
            </div>
          )}
          <div className={styles.headerContentContainer}>
            <div className={styles.headerContent}>
              <div className={styles.headerTitle}>
                {title}
                {!shouldReturnToPrevPage && !isPermanentOpened && (
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className={classnames(
                      styles.arrowIcon,
                      state.isOpened && styles.arrowIconOpened
                    )}
                  />
                )}
              </div>
              {headerContent}
            </div>
            {!!subHeader && state.isOpened && (
              <div
                onMouseUp={(e) => e.stopPropagation()}
                className={styles.subHeader}
              >
                {subHeader}
              </div>
            )}
          </div>
        </div>
        <ReactCollapse
          isOpened={
            state.isOpened &&
            (!!extendedHeader ||
              isEmpty ||
              state.isLoading ||
              Boolean(state.error.data))
          }
          theme={{
            collapse: styles.headerCollapseWrapper,
            content: styles.headerCollapseContent,
          }}
          key={uuidv4()}
        >
          {!!extendedHeader && (
            <div
              className={classnames(
                styles.extendedHeader,
                extendedHeaderClassname
              )}
            >
              {extendedHeader}
            </div>
          )}
          {state.isLoaderVisible && (state.isLoading || state.error.data) && (
            <div
              className={classnames(
                shrinkLoaderWrapper && styles.loadingWrapper
              )}
            >
              <ListLoader
                refetch={state.refetch}
                isLoading={state.isLoading}
                isError={state.error.type === ErrorType.LoadData}
                isEmpty={isEmpty}
                emptyText={emptyText}
              />
            </div>
          )}
        </ReactCollapse>
      </div>
      <ReactCollapse
        isOpened={state.isOpened}
        theme={{
          collapse: styles.childrenCollapseWrapper,
          content: styles.childrenCollapseContent,
        }}
      >
        {children}
      </ReactCollapse>
      <ReactCollapse
        isOpened={state.isOpened && !!isEmpty}
        theme={{
          collapse: styles.childrenCollapseWrapper,
          content: styles.childrenCollapseContent,
        }}
      >
        <div
          className={classnames(shrinkLoaderWrapper && styles.loadingWrapper)}
        >
          <ListLoader isEmpty={isEmpty} emptyText={emptyText} />
        </div>
      </ReactCollapse>
    </div>
  );
};

export default Block;
