import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerReferralLinkMediaItemNameVariables = SchemaTypes.Exact<{
  partnerId: SchemaTypes.Scalars['ID']['input'];
  referralLinkMediaItemId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnerReferralLinkMediaItemName = { __typename: 'Query', partner: { __typename: 'Partner', id: string, referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string } } };


export const GetPartnerReferralLinkMediaItemNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnerReferralLinkMediaItemName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"referralLinkMediaItemId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPartnerReferralLinkMediaItemName__
 *
 * To run a query within a React component, call `useGetPartnerReferralLinkMediaItemName` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerReferralLinkMediaItemName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerReferralLinkMediaItemName({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      referralLinkMediaItemId: // value for 'referralLinkMediaItemId'
 *   },
 * });
 */
export function useGetPartnerReferralLinkMediaItemName(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables> & ({ variables: GetPartnerReferralLinkMediaItemNameVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables>(GetPartnerReferralLinkMediaItemNameDocument, options);
      }
export function useGetPartnerReferralLinkMediaItemNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables>(GetPartnerReferralLinkMediaItemNameDocument, options);
        }
export function useGetPartnerReferralLinkMediaItemNameSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables>(GetPartnerReferralLinkMediaItemNameDocument, options);
        }
export type GetPartnerReferralLinkMediaItemNameHookResult = ReturnType<typeof useGetPartnerReferralLinkMediaItemName>;
export type GetPartnerReferralLinkMediaItemNameLazyQueryHookResult = ReturnType<typeof useGetPartnerReferralLinkMediaItemNameLazyQuery>;
export type GetPartnerReferralLinkMediaItemNameSuspenseQueryHookResult = ReturnType<typeof useGetPartnerReferralLinkMediaItemNameSuspenseQuery>;
export type GetPartnerReferralLinkMediaItemNameQueryResult = Apollo.QueryResult<GetPartnerReferralLinkMediaItemName, GetPartnerReferralLinkMediaItemNameVariables>;