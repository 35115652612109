import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PartnerMediaCampaignsTable } from '../../components/PartnerMediaCampaignsBlock/components/PartnerMediaCampaignsTable';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { useGetPartnerMediaCampaignsColumns } from '../../components/PartnerMediaCampaignsBlock/hooks/useGetPartnerMediaCampaignsColumns';

export const PartnerMediaCampaigns: FC = () => {
  const { columns } = useGetPartnerMediaCampaignsColumns();
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <PartnerMediaCampaignsTable
        partnerId={id}
        columns={columns}
        shouldReturnToPrevPage
        isFullScreenTable
      />
    </PartnerPageContainer>
  );
};
