import { FC } from 'react';
import { Currency } from 'commonTypes';
import { ProgressBar, ProgressBarColor, ProgressBarView } from 'ui/ProgressBar';
import classnames from 'classnames';
import { localeCurrency } from 'src/utils/localeCurrency';
import styles from './MultiChart.module.scss';

export interface MultiChartData {
  id: string;
  firstSum: {
    amount: number;
    currency: Currency;
    percent: number;
  };
  secondSum: {
    amount: number;
    currency: Currency;
    percent: number;
  };
}

interface Props {
  data?: Array<MultiChartData>;
  firstTitle: string;
  secondTitle: string;
  firstBarColor: ProgressBarColor;
  secondBarColor: ProgressBarColor;
  className?: string;
}

const MultiChart: FC<Props> = ({
  data,
  firstTitle,
  secondTitle,
  firstBarColor,
  secondBarColor,
  className,
}) => {
  if (!data) {
    return null;
  }

  return (
    <div className={classnames(styles.chartTable, className)}>
      {data.map(({ id, firstSum, secondSum }) => (
        <div className={styles.rowGroup} key={id}>
          <div className={styles.oddRow}>
            <div className={styles.currencyCell}>{firstSum.currency}</div>
            <div className={styles.titleCell}>{firstTitle}</div>
            <div className={styles.valueCell}>
              {localeCurrency({
                amount: firstSum.amount,
                currency: firstSum.currency,
              })}
            </div>
            <div className={styles.barCell}>
              <ProgressBar
                percent={firstSum.percent}
                barColor={firstBarColor}
                viewType={ProgressBarView.Chart}
                className={styles.firstBar}
              />
            </div>
          </div>
          <div className={styles.evenRow}>
            <div className={styles.currencyCell} />
            <div className={styles.titleCell}>{secondTitle}</div>
            <div className={styles.valueCell}>
              {localeCurrency({
                amount: secondSum.amount,
                currency: secondSum.currency,
              })}
            </div>
            <div className={styles.barCell}>
              <ProgressBar
                percent={secondSum.percent}
                barColor={secondBarColor}
                viewType={ProgressBarView.Chart}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiChart;
