import { CounterTabTitle } from 'commonComponents/CounterTabTitle';
import { FC } from 'react';
import styles from './TabNameWithCounter.module.scss';

interface Props {
  withPlus: boolean;
  count?: number;
  isActiveTab: boolean;
  tabName: string;
}

export const TabNameWithCounter: FC<Props> = ({
  withPlus,
  count,
  isActiveTab,
  tabName,
}) => (
  <div className={styles.tab}>
    <span>{tabName}</span>
    <CounterTabTitle
      withPlus={withPlus}
      count={count}
      isActiveTab={isActiveTab}
    />
  </div>
);
