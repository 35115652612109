import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { RewardStatus, SuspectState } from 'generatedGraphql';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { UndoActionToastType } from 'ui/Toast/const';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { useDeclineReward as useDeclineRewardMutation } from '../queries/generated/DeclineReward';

export const useDeclineRewardModal = (rewardId: string) => {
  const [declineReward, { loading }] = useDeclineRewardMutation();
  const { dispatch } = useContext(ModalContext);

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Вознаграждение не отклонено',
      text: error.message,
      toastId: 'decline-reward-error',
    });

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleDeclineReward = () => {
    declineReward({
      variables: {
        input: {
          id: rewardId,
        },
      },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        declineReward: {
          __typename: 'DeclineRewardPayload',
          reward: {
            id: rewardId,
            status: RewardStatus.Declined,
            suspectState: SuspectState.NotEligible,
            updatedAt: new Date().toISOString(),
            __typename: 'Reward',
          },
        },
      },
      onError: handleError,
    });

    undoActionToast('Вознаграждение отклонено', UndoActionToastType.Error);
    dispatch({
      type: ModalActions.Close,
    });
  };

  return {
    handleDeclineReward,
    loading,
  };
};
