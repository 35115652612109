import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { useAssignCompanyApplication } from '../queries/generated/AssignCompanyApplication';
import { useSuspendCompanyApplication } from '../queries/generated/SuspendCompanyApplication';
import { useAcceptCompanyApplication } from '../queries/generated/AcceptCompanyApplication';
import { getReferralLinkMediaItem } from '../helpers';

interface UsePartnerApplicationUpdateParams {
  companyApplicationId: string;
  referralLinkData?: ReferralLinkData | null;
}

export const useCompanyApplicationUpdate = ({
  referralLinkData,
  companyApplicationId,
}: UsePartnerApplicationUpdateParams) => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Заявка не была изменена',
      text: error.message,
      toastId: 'change-application-error',
    });
  };

  const handleComplete = () => {
    toast.success('Заявка изменена');
  };

  const [
    assignCompanyApplication,
    {
      error: errorAssignCompanyApplication,
      loading: loadingAssignCompanyApplication,
    },
  ] = useAssignCompanyApplication({
    onError: handleError,
    onCompleted: handleComplete,
  });

  const [
    suspendCompanyApplication,
    {
      error: errorSuspendCompanyApplication,
      loading: loadingSuspendCompanyApplication,
    },
  ] = useSuspendCompanyApplication({
    onError: handleError,
    onCompleted: handleComplete,
  });

  const [
    acceptCompanyApplication,
    {
      error: errorAcceptCompanyApplication,
      loading: loadingAcceptCompanyApplication,
    },
  ] = useAcceptCompanyApplication({
    onError: handleError,
    onCompleted: handleComplete,
  });

  const acceptCompanyApplicationHandler = () => {
    acceptCompanyApplication({
      variables: {
        input: {
          id: companyApplicationId,
          referralLinkMediaItem: referralLinkData
            ? getReferralLinkMediaItem(referralLinkData)
            : undefined,
        },
      },
    });
  };

  return {
    assignCompanyApplication,
    suspendCompanyApplication,
    acceptCompanyApplicationHandler,
    error:
      errorAssignCompanyApplication ||
      errorSuspendCompanyApplication ||
      errorAcceptCompanyApplication,
    loading:
      loadingAssignCompanyApplication ||
      loadingSuspendCompanyApplication ||
      loadingAcceptCompanyApplication,
  };
};
