import { FormikErrors } from 'formik';
import { MediaItemTypeName, RewardTariffType } from 'commonTypes';

export interface CreateReferralLinkInput {
  id: string;
  referralLinkCode: string;
  mediaCampaignId: string;
  referralLinkName: string;
  typeOfPaymentModel: RewardTariffType | null;
  revShareTariffId: string;
  cpaTariffId: string;
  typeMediaItem: MediaItemTypeName;
}

export const validate = (
  values: CreateReferralLinkInput
): FormikErrors<CreateReferralLinkInput> => {
  const errors: FormikErrors<CreateReferralLinkInput> = {};

  if (!values.typeOfPaymentModel) {
    errors.typeOfPaymentModel = 'Укажите тип';
  }

  if (!values.referralLinkName) {
    errors.referralLinkName = 'Поле не может быть пустым';
  }

  if (!values.mediaCampaignId) {
    errors.mediaCampaignId = 'Поле не может быть пустым';
  }

  if (values.referralLinkName.length > 50) {
    errors.referralLinkName = 'Максимальное количество символов 50';
  }

  if (
    values.typeOfPaymentModel === RewardTariffType.Cpa &&
    !values.revShareTariffId
  ) {
    errors.revShareTariffId = 'Укажите резервный тариф';
  }

  if (
    values.typeOfPaymentModel === RewardTariffType.Cpa &&
    !values.cpaTariffId
  ) {
    errors.cpaTariffId = 'Укажите CPA тариф';
  }

  if (
    values.typeOfPaymentModel === RewardTariffType.RevShare &&
    !values.revShareTariffId
  ) {
    errors.revShareTariffId = 'Укажите RevShare тариф';
  }

  if (values.referralLinkCode.length > 36) {
    errors.referralLinkCode = 'Максимальное количество символов 36';
  }

  return errors;
};
