import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { SumData } from '../../../../../../queries/generated/SumData';
import { MarketingStatisticData } from '../../../../queries/generated/MarketingStatisticData';
import { RegisteredPlayerStatisticData } from '../../../../queries/generated/RegisteredPlayerStatisticData';
import { RevSharePlayerStatisticData } from '../../../../queries/generated/RevSharePlayerStatisticData';
export type RevShareCompanyReportTotalData = { __typename: 'RevShareAffiliateReportTotal', adminFee: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, affiliateUpcomingReward: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, marketingStatistic: { __typename: 'MarketingStatistic', firstDeposits: number, hits: number, hosts: number, hostToRegConversion: any, redeposits: number, registrations: number, regToDepConversion: any }, playerRegisteredStatistic: { __typename: 'RegisteredPlayerStatistic', newDeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, newProfit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, newWithdrawal: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, playerStatistic: { __typename: 'RevSharePlayerStatistic', allDeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, allRedeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, averageDeposit: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, bonus: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, depositFee: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, financialIndicators: { __typename: 'FinancialIndicators', ngr: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, profit: { __typename: 'Sum', amount: number, currency: CommonType.Currency } }, incomingMoneyTransfer: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, jackpot: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, tournamentPrize: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, withdrawal: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, withdrawalFee: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } };

export const RevShareCompanyReportTotalData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RevShareCompanyReportTotalData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RevShareAffiliateReportTotal"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminFee"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"affiliateUpcomingReward"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"marketingStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MarketingStatisticData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"playerRegisteredStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RegisteredPlayerStatisticData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"playerStatistic"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RevSharePlayerStatisticData"}}]}}]}}]} as unknown as DocumentNode;