import { PLAYERS_SEARCH_INPUT, PLAYERS_SEARCH_PLACEHOLDER } from 'src/const';
import SearchInput, { OnSearchInputSubmit } from 'ui/SearchInput/SearchInput';
import { useNavigate } from 'react-router';
import { Route } from 'src/router/routes.const';

export const PlayersSearchInput = () => {
  const navigate = useNavigate();

  const handleSubmit: OnSearchInputSubmit<{
    [PLAYERS_SEARCH_INPUT]: string;
  }> = () => {
    navigate(Route.PLAYERS);
  };

  return (
    <SearchInput
      name={PLAYERS_SEARCH_INPUT}
      placeholder={PLAYERS_SEARCH_PLACEHOLDER}
      onSubmit={handleSubmit}
    />
  );
};
