import { useCallback, useContext, useMemo } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetCompanyAffiliatesLazyQuery } from '../../queries/generated/GetCompanyAffiliates';
import { useAddAffiliatesModal } from '../../hooks/useAddAffiliatesModal';
import styles from './AffiliatesTable.module.scss';
import { useAffiliateModal } from '../../hooks/useAffiliateModal';

const AFFILIATES_COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface Props<T extends object> {
  companyId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable?: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const AffiliatesTable = <T extends { __typename: string }>({
  companyId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: Props<T>) => {
  const { state } = useContext(BlockContext);

  const [loadAffiliatesList, { data, error, loading, fetchMore, refetch }] =
    useGetCompanyAffiliatesLazyQuery({
      variables: {
        id: companyId,
        first: isFullScreenTable
          ? getCountByWindowHeight(cellHeight)
          : AFFILIATES_COUNT_PER_PAGE,
      },
    });

  const { openAddAffiliatesModal } = useAddAffiliatesModal({
    companyId,
    companyName: data?.company.name,
  });

  useBlockComponentState({
    loadData: loadAffiliatesList,
    loading,
    error,
  });

  const affiliates = data?.company.affiliates;
  const endCursor = affiliates?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: AFFILIATES_COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: affiliates?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const affiliatesList = useMemo(
    () => affiliates?.edges.map(({ node }) => node) as unknown as Array<T>,
    [affiliates]
  );

  const { openModal } = useAffiliateModal();

  return (
    <Block
      emptyText="Партнёры компании не найдены"
      title="Партнёры"
      id="companyAffiliatesList"
      headerContent={
        <div className={styles.headerContent}>
          <Privilege privileges={[UserPrivilege.AddCompanyAffiliates]}>
            <ActionButton
              actionType={ButtonType.Add}
              disabled={state.isLoading}
              onClick={openAddAffiliatesModal}
            />
          </Privilege>
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetch()}
          />
        </div>
      }
      isEmpty={isListEmpty(affiliatesList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(affiliatesList) && (
        <BlockTable
          columns={columns}
          data={affiliatesList}
          onClickOnRow={(companyAffiliate, initiatorId) =>
            openModal(companyAffiliate, initiatorId, companyId)
          }
          isFullScreenTable={isFullScreenTable}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};

export default AffiliatesTable;
