import { ApolloError } from '@apollo/client';
import type { SetError } from './actions/setError';
import { SetInitialData } from './actions/setInitialData';
import { SetLoaderVisibility } from './actions/setLoaderVisibility';
import { SetLoading } from './actions/setLoading';
import { SetLoadNew } from './actions/setLoadNew';
import { SetLoadOld } from './actions/setLoadOld';
import { ToggleBlock } from './actions/toggleBlock';
import { SetRefetch } from './actions/setRefetch';
import { ResetError } from './actions/resetError';

export enum ErrorType {
  LoadData = 'loadDada',
  FetchMore = 'fetchMore',
}

export type Action =
  | ToggleBlock
  | SetLoadOld
  | SetLoadNew
  | SetLoading
  | SetInitialData
  | SetError
  | ResetError
  | SetLoaderVisibility
  | SetRefetch;

export interface State {
  isOpened: boolean;
  loadOld: { hasOld: boolean; onLoadOld?: () => void };
  loadNew: { hasNew: boolean; onLoadNew?: () => void };
  refetch?: () => void;
  isLoading: boolean;
  error: { data: ApolloError | null; type: ErrorType | null };
  blockId: string;
  isPermanentOpened: boolean;
  isLoaderVisible: boolean;
}
