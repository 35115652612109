import React, { FC, useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownProps, Option } from 'ui/Dropdown';

interface Props {
  loadList: (e: string) => void;
  options?: Array<Option> | null;
  loading: boolean;
}

const DropdownWithQuery: FC<DropdownProps & Props> = ({
  searchMinLength,
  loadList,
  options,
  loading,
  ...rest
}) => {
  const [dataList, setDataList] = useState<Array<Option> | undefined | null>(
    null
  );
  const [inputLength, setInputLength] = useState(0);

  useEffect(() => {
    if (
      options &&
      !loading &&
      (!searchMinLength || inputLength >= searchMinLength)
    ) {
      setDataList(options);
    }
  }, [options, inputLength, loading, searchMinLength]);

  const onInputChange = useCallback(
    (inputSting: string) => {
      setInputLength(inputSting.length);
      if (!searchMinLength || searchMinLength > inputSting.length) {
        setDataList(null);
      } else {
        loadList(inputSting);
      }
    },
    [loadList, searchMinLength]
  );

  return (
    <Dropdown
      loading={loading}
      onSearchInList={(inputString) => onInputChange(inputString)}
      options={dataList}
      searchMinLength={searchMinLength}
      {...rest}
    />
  );
};

export default DropdownWithQuery;
