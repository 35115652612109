import { FC } from 'react';
import Input from 'ui/Input';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Form, Formik } from 'formik';
import FormRow from 'ui/FormRow';
import Button, { ButtonTheme } from 'ui/Button';
import Error from 'ui/Error';
import { Tooltip } from 'ui/Tooltip';
import { validate } from './validation';
import { useDeclineCompanyApplicationData } from './hooks/useDeclineCompanyApplicationData';
import { FormValues } from './types';

interface Props {
  companyApplicationId: string;
  companyName: string;
}

export const DeclineCompanyApplicationModal: FC<Props> = ({
  companyName,
  companyApplicationId,
}) => {
  const { declineCompanyApplicationHandler, loading, error } =
    useDeclineCompanyApplicationData();

  const initialValues: FormValues = {
    id: companyApplicationId,
    companyName,
    reason: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={declineCompanyApplicationHandler}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input name="companyName" label="Название компании" disabled />
            </FormRow>
            <FormRow isWide>
              <Input.Textarea
                name="reason"
                label="Причина отклонения"
                placeholder="Укажите причину отклонения заявки"
              />
            </FormRow>
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Укажите причину отклонения' : undefined
            }
            placement="right"
            shouldSetWidthFitContent
          >
            <Button
              type="submit"
              isLoading={loading}
              disabled={!isValid || !dirty}
              theme={ButtonTheme.Important}
            >
              Отклонить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
