import { useEffect, useState } from 'react';
import { useGetAccountsLazyQuery } from '../queries/generated/GetAccounts';

interface UseAccountsParams {
  playerId: string;
}

export const useAccounts = ({ playerId }: UseAccountsParams) => {
  const [currentAccountId, setCurrentAccountId] = useState<string>();

  const [load, { data, loading, error, refetch }] = useGetAccountsLazyQuery({
    variables: {
      playerId,
    },
  });

  const accounts = data?.player.accounts;

  useEffect(() => {
    if (accounts?.length && !currentAccountId) {
      setCurrentAccountId(accounts[0].id);
    }
  }, [accounts, currentAccountId]);

  return {
    currentAccountId,
    setCurrentAccountId,
    accounts,
    accountsLoading: loading,
    accountsError: error,
    accountsLoad: load,
    accountsRefetch: refetch,
  };
};
