import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { AffiliateType } from 'generatedGraphql';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { CreateConfirmationSettingValues } from '../types';
import { GetQualifiedReferralConfirmationSettingsDocument } from '../../../queries/generated/GetQualifiedReferralConfirmationSettings';
import { useCreateQualifiedReferralConfirmationSetting as useCreateQualifiedReferralConfirmationSettingMutation } from '../queries/generated/CreateQualifiedReferralConfirmationSetting';

const cellHeight = Heights.defaultCellHeight;

export const useCreateQualifiedReferralConfirmationSetting = () => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    toast.success('Настройка подтверждения создана');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Настройка подтверждения не создана',
      text: error.message,
      toastId: 'create-qualified-referral-confirmation-setting-error',
    });
  };

  const [
    createQualifiedReferralConfirmationSettingMutation,
    { loading, error },
  ] = useCreateQualifiedReferralConfirmationSettingMutation({
    refetchQueries: [
      {
        query: GetQualifiedReferralConfirmationSettingsDocument,
        variables: {
          first: getCountByWindowHeight(cellHeight, 0),
        },
      },
    ],
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const createQualifiedReferralConfirmationSetting = ({
    companyReferralCode,
    partnerReferralCode,
    comment,
    companyId,
    partnerId,
    settingType,
    affiliateType,
  }: CreateConfirmationSettingValues) => {
    if (affiliateType && settingType) {
      const isCompany = AffiliateType.Company;
      const code =
        affiliateType === isCompany ? companyReferralCode : partnerReferralCode;

      const affiliateId = affiliateType === isCompany ? companyId : partnerId;

      createQualifiedReferralConfirmationSettingMutation({
        variables: {
          input: {
            code,
            comment,
            affiliate: {
              id: affiliateId,
              type: affiliateType,
            },
            type: settingType,
          },
        },
      });
    }
  };

  return {
    createQualifiedReferralConfirmationSetting,
    loading,
    error,
  };
};
