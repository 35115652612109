import { FC } from 'react';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { CreateMediaItemLinkFields } from '../../const';

export const CpaForm: FC = () => (
  <>
    <FormRowsWrapper>
      <CpaTariffDropdown
        name={CreateMediaItemLinkFields.cpaRewardTariffId}
        label="Партнёрский тариф"
      />
    </FormRowsWrapper>
    <FormRowsWrapper>
      <RevShareTariffDropdown
        name={CreateMediaItemLinkFields.fallbackTariffId}
        label="Резервный тариф"
      />
    </FormRowsWrapper>
  </>
);
