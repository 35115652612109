import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { Sum } from 'generatedGraphql';
import { PAYMENTS_COUNT_PER_PAGE } from '../../../const';
import {
  GetWithdrawalListPayments,
  useGetWithdrawalListPayments,
} from '../queries/generated/GetWithdrawalListPayments';

interface UseGetPaymentsParams {
  withdrawalId: string;
}

interface UseGetPaymentsResult {
  loadMore: () => void;
  error?: ApolloError;
  loading: boolean;
  isListEmpty: boolean;
  remainingSum?: Sum;
  withdrawalAdminId?: string;
  payments?: GetWithdrawalListPayments['withdrawal']['payments'];
}

export const useGetPayments = ({
  withdrawalId,
}: UseGetPaymentsParams): UseGetPaymentsResult => {
  const { data, error, loading, fetchMore } = useGetWithdrawalListPayments({
    variables: { id: withdrawalId, first: PAYMENTS_COUNT_PER_PAGE },
  });

  const payments = data?.withdrawal.payments;

  const remainingSum = data?.withdrawal.remainingSum;
  const withdrawalAdminId = data?.withdrawal.admin?.id;

  const loadMore = useCallback(() => {
    if (!payments?.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: payments.pageInfo.endCursor,
        first: PAYMENTS_COUNT_PER_PAGE,
      },
    });
  }, [payments, fetchMore]);

  const isListEmpty = !payments?.edges.length;

  return {
    loadMore,
    error,
    loading,
    remainingSum,
    withdrawalAdminId,
    isListEmpty,
    payments: data?.withdrawal.payments,
  };
};
