import { PartnerApplicationStatus } from 'generatedGraphql';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import errorToast from 'ui/Toast/ErrorToast';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useAssignPartnerApplication } from '../queries/generated/AssignPartnerApplication';
import { useSuspendPartnerApplication } from '../queries/generated/SuspendPartnerApplication';
import { PartnerApplicationModalSteps } from '../../../../../const';
import { useAcceptPartnerApplication } from '../queries/generated/AcceptPartnerApplication';

interface UsePartnerApplicationUpdateParams {
  partnerApplicationId: string;
  referralLinkData?: ReferralLinkData | null;
  partnerApplicationStatus?: PartnerApplicationStatus;
}

export const usePartnerApplicationUpdate = ({
  partnerApplicationStatus,
  referralLinkData,
  partnerApplicationId,
}: UsePartnerApplicationUpdateParams) => {
  const auth = useAuth();

  const [
    assignPartnerApplication,
    {
      data: updateAssignPartnerApplication,
      error: errorAssignPartnerApplication,
      loading: loadingAssignPartnerApplication,
    },
  ] = useAssignPartnerApplication();

  const [
    suspendPartnerApplication,
    {
      data: updateSuspendPartnerApplication,
      error: errorSuspendPartnerApplication,
      loading: loadingSuspendPartnerApplication,
    },
  ] = useSuspendPartnerApplication();

  const [
    acceptPartnerApplication,
    {
      data: updateAcceptPartnerApplication,
      error: errorAcceptPartnerApplication,
      loading: loadingAcceptPartnerApplication,
    },
  ] = useAcceptPartnerApplication();

  const updateResult =
    updateAssignPartnerApplication ||
    updateSuspendPartnerApplication ||
    updateAcceptPartnerApplication;

  const error =
    errorAssignPartnerApplication ||
    errorSuspendPartnerApplication ||
    errorAcceptPartnerApplication;

  const { updateModalStep } =
    useUpdateModalStep<PartnerApplicationModalSteps>();

  useEffect(() => {
    if (!updateResult) {
      return;
    }

    toast.success('Заявка изменена');
  }, [updateResult]);

  useEffect(() => {
    if (!error) {
      return;
    }

    errorToast({
      header: 'Заявка не была изменена',
      text: error.message,
      toastId: 'change-application-error',
    });
  }, [error]);

  const acceptPartnerApplicationHandler = () => {
    if (referralLinkData) {
      const {
        referralLinkName,
        code,
        mediaCampaignId,
        cpaTariff,
        revShareTariff,
      } = referralLinkData;

      acceptPartnerApplication({
        variables: {
          input: {
            id: partnerApplicationId,
            referralLinkMediaItem: {
              name: referralLinkName,
              code,
              mediaCampaignId,
              mediaItemRewardTariff: {
                ...(cpaTariff
                  ? {
                      cpaRewardTariff: {
                        fallbackRewardTariffId: revShareTariff.id,
                        id: cpaTariff.id,
                      },
                    }
                  : {
                      revShareRewardTariff: {
                        id: revShareTariff.id,
                      },
                    }),
              },
            },
          },
        },
      });
    }
  };
  const isAllowedToUpdateApplication = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const hasAssignButton =
    isAllowedToUpdateApplication([UserPrivilege.AssignPartnerApplication]) &&
    partnerApplicationStatus === PartnerApplicationStatus.Pending;

  const hasSuspendButton =
    isAllowedToUpdateApplication([UserPrivilege.SuspendPartnerApplication]) &&
    partnerApplicationStatus === PartnerApplicationStatus.InProgress;

  const hasAcceptButton =
    isAllowedToUpdateApplication([UserPrivilege.AssignPartnerApplication]) &&
    partnerApplicationStatus === PartnerApplicationStatus.InProgress;

  const hasDeclineButton =
    isAllowedToUpdateApplication([UserPrivilege.DeclinePartnerApplication]) &&
    partnerApplicationStatus === PartnerApplicationStatus.InProgress;

  return {
    hasSuspendButton,
    hasAcceptButton,
    hasDeclineButton,
    hasAssignButton,
    assignPartnerApplication,
    suspendPartnerApplication,
    acceptPartnerApplicationHandler,
    error,
    loading:
      loadingAssignPartnerApplication ||
      loadingSuspendPartnerApplication ||
      loadingAcceptPartnerApplication,
    updateModalStep,
  };
};
