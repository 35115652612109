import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { STATISTIC_NO_DATA } from '../../const';
import { useGetRevShareReferralLinkReport } from './hooks/useGetRevShareReferralLinkReport';
import { RevShareReferralLinkReportTable } from './components/RevShareReferralLinkReportTable';
import { ReferralLinkReportBlockSubHeader } from '../../components/ReferralLinkReportBlockSubHeader';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { getPathParamsToOwnerPreviousRevShareReport } from './helpers';
import { useGetExportReferralLinkMediaItemRevShareReport } from './hooks/useGetExportReferralLinkMediaItemRevShareReport';
import {
  REV_SHARE_REFERRAL_LINK_REPORT_BLOCK_TITLE,
  REV_SHARE_REFERRAL_LINK_REPORT_INPUT_NAME,
  REV_SHARE_REFERRAL_LINK_REPORT_SEARCH_PLACEHOLDER,
} from './const';

interface Props {
  referralLinkId: string;
}

export const Component: FC<Props> = withBlockProvider(({ referralLinkId }) => {
  const {
    revShareReferralLinkReportList,
    refetch,
    mediaItemInfo,
    hasTotalRow,
    loadReport,
  } = useGetRevShareReferralLinkReport({
    referralLinkId,
  });

  const exportReportConfig = useGetExportReferralLinkMediaItemRevShareReport({
    referralLinkId,
  });

  const generatePathWithPeriodQueryParams =
    useGeneratePathWithSavePeriodQueryParams();
  const { route, params } = getPathParamsToOwnerPreviousRevShareReport(
    mediaItemInfo?.owner
  );

  const backToRevSharePartnerReportUrl =
    mediaItemInfo?.owner &&
    generatePathWithPeriodQueryParams({
      originalPath: route,
      params,
    });

  const listExist = hasList(revShareReferralLinkReportList);

  return (
    <>
      <SearchInput
        name={REV_SHARE_REFERRAL_LINK_REPORT_INPUT_NAME}
        placeholder={REV_SHARE_REFERRAL_LINK_REPORT_SEARCH_PLACEHOLDER}
        onSubmit={loadReport}
      />
      <Block
        title={REV_SHARE_REFERRAL_LINK_REPORT_BLOCK_TITLE}
        id="revShareReferralLinkReport"
        headerContent={
          <StatisticBlockHeaderContent
            hasList={listExist}
            exportReportConfig={exportReportConfig}
            handleRefetchClick={refetch}
          />
        }
        subHeader={
          mediaItemInfo && (
            <ReferralLinkReportBlockSubHeader mediaItemInfo={mediaItemInfo} />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(revShareReferralLinkReportList)}
        isPermanentOpened
        shouldReturnToPrevPage
        customPathToPrevPage={backToRevSharePartnerReportUrl}
      >
        {listExist && (
          <RevShareReferralLinkReportTable
            report={revShareReferralLinkReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    </>
  );
});

export const RevShareReferralLinkReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <SearchContextProvider
      pageType={PageTypeStorage.RevShareReferralLinkReport}
    >
      <Component referralLinkId={id} />
    </SearchContextProvider>
  );
};
