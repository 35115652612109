export enum ColumnId {
  Id = 'id',
  Text = 'text',
  Author = 'author',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Text]: 'Текст',
  [ColumnId.Author]: 'Автор',
  [ColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [ColumnId.Text, ColumnId.CreatedAt];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Text,
  ColumnId.Author,
  ColumnId.CreatedAt,
];
