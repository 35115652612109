import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { formatSumAmount } from 'src/utils/accounting';
import { formatTableHeaderCellWithCurrency } from 'src/pages/Statistic/helpers';
import { Route } from 'src/router/routes.const';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Currency } from 'commonTypes';
import { CpaGeneralReportData } from '../../../queries/generated/CpaGeneralReportData';
import { columnsIds, ColumnId, mapColumnIdToName } from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { getAffiliateInfo } from '../../../../../helpers';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../MultilineCellWithLinkWithPeriodQueryParams';
import { useGetCurrency } from '../../../../../hooks/useGetCurrency';

const columnHelper = createColumnHelper<CpaGeneralReportData>();

const buildColumnsSource = (
  currency: Currency
): ColumnsSource<CpaGeneralReportData> => [
  columnHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      if (!row.original.affiliate) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, typeName } = getAffiliateInfo<
        CpaGeneralReportData['affiliate']
      >(row.original.affiliate);

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={email}
          route={
            typeName === 'Partner'
              ? Route.STATISTIC_CPA_PARTNER
              : Route.STATISTIC_CPA_COMPANY_PARTNERS
          }
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Hits, {
    id: ColumnId.Hits,
    header: mapColumnIdToName[ColumnId.Hits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(ColumnId.Hosts, {
    id: ColumnId.Hosts,
    header: mapColumnIdToName[ColumnId.Hosts],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(ColumnId.Registrations, {
    id: ColumnId.Registrations,
    header: mapColumnIdToName[ColumnId.Registrations],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(ColumnId.FirstDeposits, {
    id: ColumnId.FirstDeposits,
    header: mapColumnIdToName[ColumnId.FirstDeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(ColumnId.HostToRegConversion, {
    id: ColumnId.HostToRegConversion,
    header: mapColumnIdToName[ColumnId.HostToRegConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(ColumnId.RegToDepConversion, {
    id: ColumnId.RegToDepConversion,
    header: mapColumnIdToName[ColumnId.RegToDepConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AverageDeposit, {
    id: ColumnId.AverageDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AverageDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.averageDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllRedeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allRedeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(ColumnId.NewDeposit, {
    id: ColumnId.NewDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewDeposit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newDeposit;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Withdrawal],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewWithdrawal, {
    id: ColumnId.NewWithdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewWithdrawal],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewProfit, {
    id: ColumnId.NewProfit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewProfit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newProfit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Hold, {
    id: ColumnId.Hold,
    header: mapColumnIdToName[ColumnId.Hold],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.hold),
  }),
  columnHelper.accessor(ColumnId.Qualified, {
    id: ColumnId.Qualified,
    header: mapColumnIdToName[ColumnId.Qualified],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.qualified),
  }),
  columnHelper.accessor(ColumnId.NotQualified, {
    id: ColumnId.NotQualified,
    header: mapColumnIdToName[ColumnId.NotQualified],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.canceled),
  }),
  columnHelper.accessor(ColumnId.Approved, {
    id: ColumnId.Approved,
    header: mapColumnIdToName[ColumnId.Approved],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.approved),
  }),
  columnHelper.accessor(ColumnId.Rejected, {
    id: ColumnId.Rejected,
    header: mapColumnIdToName[ColumnId.Rejected],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.rejected),
  }),
  columnHelper.accessor(ColumnId.Paid, {
    id: ColumnId.Paid,
    header: mapColumnIdToName[ColumnId.Paid],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.paid),
  }),
  columnHelper.accessor(ColumnId.Unpaid, {
    id: ColumnId.Unpaid,
    header: mapColumnIdToName[ColumnId.Unpaid],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.unpaid),
  }),
  columnHelper.accessor(ColumnId.RevShare, {
    id: ColumnId.RevShare,
    header: mapColumnIdToName[ColumnId.RevShare],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.revShare),
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NGR],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.financialIndicators.ngr;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Bonus, {
    id: ColumnId.Bonus,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Bonus],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.bonus;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Profit],
      currency
    ),
    cell: ({ row }) => {
      const { amount } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Upcoming],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateRewardStatistic.upcoming;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.RewardPaid, {
    id: ColumnId.RewardPaid,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.RewardPaid],
      currency
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateRewardStatistic.paid;

      return formatSumAmount(amount);
    },
  }),
];

export const useGetCpaGeneralReportColumns = () => {
  const { currency } = useGetCurrency();
  const columnsSource = buildColumnsSource(currency);

  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
