import { FormikErrors } from 'formik';
import { RestrictionTypeName } from 'commonTypes';

export interface CreateRestrictionValues {
  restrictionType?: RestrictionTypeName;
  reason?: string;
  bypassRestrictionIfApproved?: boolean;
}

export interface CreateRestrictionErrors {
  restrictionType: RestrictionTypeName;
  reason?: string;
  bypassRestrictionIfApproved?: boolean;
}

export const validate = (
  values: CreateRestrictionValues
): FormikErrors<CreateRestrictionErrors> => {
  const errors: FormikErrors<CreateRestrictionErrors> = {};

  if (!values.restrictionType) {
    errors.restrictionType = 'Необходимо выбрать тип ограничения';
  }

  if (
    !values.reason &&
    (values.restrictionType === RestrictionTypeName.Withdrawal ||
      values.restrictionType === RestrictionTypeName.ReceiveGift)
  ) {
    errors.reason = 'Необходимо заполнить причину';
  }

  return errors;
};
