import { useGetPromoCodeMediaItemByTokenLazyQuery } from 'src/queries/generated/GetPromoCodeMediaItemByToken';
import { MutableRefObject, useEffect, useState } from 'react';
import { generatePromoCodeToken } from 'src/utils/generatePromoCodeToken';

interface UseSetDefaultTokenParams {
  tokenRef: MutableRefObject<string>;
}

export const useSetDefaultToken = ({ tokenRef }: UseSetDefaultTokenParams) => {
  const [checkIsTokenValid, { data: existingPromoCodeWithToken }] =
    useGetPromoCodeMediaItemByTokenLazyQuery();

  const [isTokenUnique, setIsTokenUnique] = useState(false);

  useEffect(() => {
    // Fix for eslint no-param-reassign rule
    const tokenRefClone = tokenRef;

    if (!isTokenUnique) {
      setTimeout(() => {
        checkIsTokenValid({
          variables: {
            token: tokenRefClone.current,
          },
          onCompleted: () => {
            tokenRefClone.current = generatePromoCodeToken();
          },
          onError: () => {
            setIsTokenUnique(true);
          },
        });
      }, 0);
    }
  }, [checkIsTokenValid, isTokenUnique, existingPromoCodeWithToken, tokenRef]);
};
