export enum ColumnId {
  Id = 'id',
  Provider = 'paymentProvider',
  PaymentMethodName = 'paymentMethodName',
  PaymentMethodAccount = 'paymentMethod.account',
  Sum = 'sum',
  Wager = 'wager',
  PaidSum = 'paidSum',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Provider]: 'Провайдер',
  [ColumnId.PaymentMethodName]: 'Метод',
  [ColumnId.PaymentMethodAccount]: 'Номер счета',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.Wager]: 'Отыгрыш',
  [ColumnId.PaidSum]: 'Фактическая сумма',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const blockColumnsIds = [
  ColumnId.PaymentMethodName,
  ColumnId.PaymentMethodAccount,
  ColumnId.Sum,
  ColumnId.Wager,
  ColumnId.Status,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Provider,
  ColumnId.PaymentMethodName,
  ColumnId.PaymentMethodAccount,
  ColumnId.Sum,
  ColumnId.Wager,
  ColumnId.PaidSum,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];

export const countPerPage = 10;
