import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { useGetMediaItemReferralLink } from '../queries/generated/GetMediaItemReferralLink';
import { MediaItemReferralLinkModalSteps } from '../const';

interface UseMediaItemReferralLinkModalDataParams {
  mediaItemReferralLinkId: string;
}

export const useMediaItemReferralLinkModal = ({
  mediaItemReferralLinkId,
}: UseMediaItemReferralLinkModalDataParams) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { data, loading, error } = useGetMediaItemReferralLink({
    variables: {
      id: mediaItemReferralLinkId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'media-item-errors' },
    hasData: !!data,
  });

  useEffect(() => {
    if (state.step === MediaItemReferralLinkModalSteps.BaseStep) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${mediaItemReferralLinkId}`,
          subTitle: 'Медиа элемент',
        },
      });

      return;
    }

    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        title: 'Редактирование медиа элемента',
        subTitle: `#${mediaItemReferralLinkId}`,
      },
    });
  }, [mediaItemReferralLinkId, modalDispatch, state.step]);

  const openEditNameModal = () => {
    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: MediaItemReferralLinkModalSteps.EditNameStep,
      },
    });
  };

  return {
    mediaItemReferralLink: data?.mediaItem,
    step: state.step,
    openEditNameModal,
  };
};
