import { useAuth } from 'commonComponents/Auth';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { nbsp } from 'src/const';
import { useState } from 'react';

export const useExportReportHandlers = () => {
  const auth = useAuth();
  const [downloadReportFileLoading, setDownloadReportFileLoading] =
    useState(false);

  const handleCompleteExportReport = (url: string) => {
    const { accessToken } = auth;

    setDownloadReportFileLoading(true);

    fetch(url, {
      headers: {
        Authorization: accessToken.current.token
          ? `Bearer ${accessToken.current.token}`
          : '',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);

        return null;
      })
      .finally(() => {
        setDownloadReportFileLoading(false);
      })
      .catch(() => {
        errorToast({
          header: 'Произошла ошибка загрузки',
          text: `Не удалось получить ссылку на${nbsp}скачивание статистики`,
          toastId: 'get-export-report-error',
        });
      });
  };

  const handleErrorExportReport = (error: ApolloError) => {
    errorToast({
      header: `Не удалось скачать статистику`,
      text: error.message,
      toastId: 'get-export-report-link-error',
    });
  };

  return {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  };
};
