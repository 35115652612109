import { ReactElement, useEffect, useState } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { Dropdown, Option } from 'ui/Dropdown';
import { Formik, Form } from 'formik';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { ApolloError } from '@apollo/client';

interface ClientRank<T> {
  rank: T;
}

interface Props<T> {
  isLoading: boolean;
  error?: ApolloError;
  options: Array<Option>;
  onUpdateRank: ({ rank }: ClientRank<T>) => void;
  rank?: T;
  defaultRank: T;
}

const UpdateClientRank = <T extends string>({
  rank,
  isLoading,
  error,
  options,
  onUpdateRank,
  defaultRank,
}: Props<T>): ReactElement => {
  const [initialValues, setInitialValues] = useState({
    rank: defaultRank,
  });

  useEffect(() => {
    if (rank) {
      setInitialValues({
        rank,
      });
    }
  }, [rank]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onUpdateRank}
      enableReinitialize
    >
      <Form>
        {error && <Error error={error} />}
        <FormRowsWrapper>
          <FormRow isWide>
            <Dropdown label="Статус" options={options} name="rank" />
          </FormRow>
        </FormRowsWrapper>
        <Button theme={ButtonTheme.Process} type="submit" isLoading={isLoading}>
          Изменить
        </Button>
      </Form>
    </Formik>
  );
};

export default UpdateClientRank;
