import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { twoEmDash } from 'src/const';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { RevShareRewardTariffData } from '../../../../../queries/generated/RevShareRewardTariffData';

const columnHelper = createColumnHelper<RevShareRewardTariffData>();

const columnsSource: ColumnsSource<RevShareRewardTariffData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Percent, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
    cell: ({ row }) => `RevShare ${row.original.percent || 0}%`,
  }),
  columnHelper.accessor(ColumnId.Percent, {
    id: ColumnId.Percent,
    header: mapColumnIdToName[ColumnId.Percent],
    cell: ({ row }) => `${row.original.percent}%`,
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => {
      const { createdAt } = row.original;

      return MayBeNullCell(
        createdAt && (
          <NowrapCell>{formatDate(createdAt, DateFormat.FullDate)}</NowrapCell>
        ),
        twoEmDash
      );
    },
  }),
];

export const useGetRevShareRewardTariffsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
