import errorToast from 'ui/Toast/ErrorToast';
import { ListAllOperations } from 'generatedGraphql';
import { toast } from 'react-toastify';
import { useApproveAllQualifiedReferrals } from '../queries/generated/ApproveAllQualifiedReferrals';
import { useRejectAllQualifiedReferrals } from '../queries/generated/RejectAllQualifiedReferrals';

export const useAllReferralsActions = () => {
  const [approveAllQualifiedReferrals, { loading: approveAllLoading }] =
    useApproveAllQualifiedReferrals();

  const [rejectAllQualifiedReferrals, { loading: rejectAllLoading }] =
    useRejectAllQualifiedReferrals();

  const approveAllReferrals = () => {
    approveAllQualifiedReferrals({
      refetchQueries: [ListAllOperations.Query.GetQualifiedReferrals],
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'approveAllQualifiedReferralsErrorToast',
        });
      },
    });

    toast.success('Игроки подтверждены');
  };

  const rejectAllReferrals = () => {
    rejectAllQualifiedReferrals({
      refetchQueries: [ListAllOperations.Query.GetQualifiedReferrals],
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'rejectAllQualifiedReferralsErrorToast',
        });
      },
    });

    toast.error('Игроки отклонены');
  };

  return {
    approveAllReferrals,
    rejectAllReferrals,
    approveAllLoading,
    rejectAllLoading,
  };
};
