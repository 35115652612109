import { RewardStatus } from 'generatedGraphql';
import { CardIconColor, CardIconConfig } from 'ui/Card/CardIcon';
import {
  faBan,
  faCircleCheck,
  faHourglassEmpty,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const getRewardStatusIcon = (status: RewardStatus): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (status) {
    case RewardStatus.Accepted:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case RewardStatus.Declined:
      icon = faBan;
      iconColor = CardIconColor.RED;
      break;
    case RewardStatus.Pending:
    default:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
  }

  return { icon, iconColor };
};
