import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AssignWithdrawalVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AssignWithdrawalInput;
}>;


export type AssignWithdrawal = { __typename: 'Mutation', assignWithdrawal: { __typename: 'AssignWithdrawalPayload', withdrawal: { __typename: 'Withdrawal', id: string, status: SchemaTypes.WithdrawalStatus, admin?: { __typename: 'Admin', firstName: string, id: string, lastName: string } | null } } };


export const AssignWithdrawalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssignWithdrawal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssignWithdrawalInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignWithdrawal"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"withdrawal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AssignWithdrawalMutationFn = Apollo.MutationFunction<AssignWithdrawal, AssignWithdrawalVariables>;

/**
 * __useAssignWithdrawal__
 *
 * To run a mutation, you first call `useAssignWithdrawal` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignWithdrawal` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignWithdrawal, { data, loading, error }] = useAssignWithdrawal({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignWithdrawal(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignWithdrawal, AssignWithdrawalVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignWithdrawal, AssignWithdrawalVariables>(AssignWithdrawalDocument, options);
      }
export type AssignWithdrawalHookResult = ReturnType<typeof useAssignWithdrawal>;
export type AssignWithdrawalMutationResult = Apollo.MutationResult<AssignWithdrawal>;
export type AssignWithdrawalMutationOptions = Apollo.BaseMutationOptions<AssignWithdrawal, AssignWithdrawalVariables>;