import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdatePartnerIdentitiesVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdatePartnerIdentitiesInput;
}>;


export type UpdatePartnerIdentities = { __typename: 'Mutation', updatePartnerIdentities: { __typename: 'UpdatePartnerIdentitiesPayload', partner: { __typename: 'Partner', email?: string | null, id: string } } };


export const UpdatePartnerIdentitiesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePartnerIdentities"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdatePartnerIdentitiesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePartnerIdentities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdatePartnerIdentitiesMutationFn = Apollo.MutationFunction<UpdatePartnerIdentities, UpdatePartnerIdentitiesVariables>;

/**
 * __useUpdatePartnerIdentities__
 *
 * To run a mutation, you first call `useUpdatePartnerIdentities` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerIdentities` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerIdentities, { data, loading, error }] = useUpdatePartnerIdentities({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerIdentities(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePartnerIdentities, UpdatePartnerIdentitiesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePartnerIdentities, UpdatePartnerIdentitiesVariables>(UpdatePartnerIdentitiesDocument, options);
      }
export type UpdatePartnerIdentitiesHookResult = ReturnType<typeof useUpdatePartnerIdentities>;
export type UpdatePartnerIdentitiesMutationResult = Apollo.MutationResult<UpdatePartnerIdentities>;
export type UpdatePartnerIdentitiesMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerIdentities, UpdatePartnerIdentitiesVariables>;