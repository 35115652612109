import { FC, ReactNode } from 'react';
import { ApolloError } from '@apollo/client';
import LoadMoreButton from 'ui/LoadMoreButton';
import Error from 'ui/Error/Error';
import styles from './SearchList.module.scss';

interface Props {
  error?: ApolloError;
  loading?: boolean;
  hasNextPage?: boolean;
  loadMore: () => void;
  hasItems: boolean;
  children: ReactNode;
}

const SearchList: FC<Props> = ({
  error,
  loading,
  hasNextPage,
  loadMore,
  hasItems,
  children,
}) => {
  if (error) {
    return <Error error={error} />;
  }

  if (hasItems) {
    return (
      <div>
        <div className={styles.wrapper}>{children}</div>
        {hasNextPage && (
          <LoadMoreButton onLoadMore={loadMore} loading={loading} />
        )}
      </div>
    );
  }

  return <div>{loading ? 'Loading...' : 'Ничего не нашлось'}</div>;
};

export default SearchList;
