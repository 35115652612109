import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeclineRewardVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeclineRewardInput;
}>;


export type DeclineReward = { __typename: 'Mutation', declineReward: { __typename: 'DeclineRewardPayload', reward: { __typename: 'Reward', id: string, status: SchemaTypes.RewardStatus, suspectState: SchemaTypes.SuspectState, updatedAt: string } } };


export const DeclineRewardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineReward"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclineRewardInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declineReward"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reward"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"suspectState"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type DeclineRewardMutationFn = Apollo.MutationFunction<DeclineReward, DeclineRewardVariables>;

/**
 * __useDeclineReward__
 *
 * To run a mutation, you first call `useDeclineReward` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineReward` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineReward, { data, loading, error }] = useDeclineReward({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineReward(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineReward, DeclineRewardVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeclineReward, DeclineRewardVariables>(DeclineRewardDocument, options);
      }
export type DeclineRewardHookResult = ReturnType<typeof useDeclineReward>;
export type DeclineRewardMutationResult = Apollo.MutationResult<DeclineReward>;
export type DeclineRewardMutationOptions = Apollo.BaseMutationOptions<DeclineReward, DeclineRewardVariables>;