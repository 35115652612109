import { DocumentEdge, ListAllOperations } from 'generatedGraphql';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { useCallback, useContext, useLayoutEffect } from 'react';
import { BlockContext, BlockState } from 'ui/Block/store';
import { fetchMoreWithoutBlockLoader } from 'ui/Block/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { throttle } from 'lodash-es';
import { PX_BEFORE_END_OF_SCREEN } from 'ui/Block/const';
import { ModalActions, ModalContext } from 'ui/Modal';
import { DocumentData } from '../queries/generated/DocumentData';
import { useDeleteDocument } from '../queries/generated/DeleteDocument';
import {
  COUNT_PER_BLOCK,
  COUNT_PER_PAGE,
  DEFAULT_IMAGE_SIZE,
} from '../components/DocumentsList/const';
import {
  GetPlayerDocumentsDocument,
  useGetPlayerDocumentsLazyQuery,
} from '../queries/generated/GetPlayerDocuments';
import { getInitialDocumentsCount } from '../helpers';
import { DocumentImage } from './useLightboxControls';
import { CreateDocumentsForm } from '../components/CreateDocumentsForm';

interface DocumentsType {
  playerId: string;
  isFullScreenBlock: boolean;
}

interface DocumentsReturnType {
  state: BlockState;
  documents?: Array<DocumentEdge>;
  deleteDocument: (id: DocumentData['id']) => void;
  refetchDocuments: () => void;
  openCreateDocumentsModal: () => void;
  isEmptyList?: boolean;
  isDeleteLoading: boolean;
  loading: boolean;
  isEndOfList: boolean;
  documentsImages: Array<DocumentImage>;
}

export const useDocuments = ({
  playerId,
  isFullScreenBlock,
}: DocumentsType): DocumentsReturnType => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const { state, dispatch } = useContext(BlockContext);

  const [
    loadDocuments,
    { loading, error: documentsError, refetch, data, fetchMore },
  ] = useGetPlayerDocumentsLazyQuery({
    variables: {
      playerId,
      thumbnailImageUrlHeight: DEFAULT_IMAGE_SIZE,
      thumbnailImageUrlWidth: DEFAULT_IMAGE_SIZE,
      first: isFullScreenBlock ? getInitialDocumentsCount() : COUNT_PER_BLOCK,
    },
  });

  const documents = data?.player.documents?.edges;
  const hasNextPage = data?.player.documents?.pageInfo.hasNextPage;

  const [deleteDocumentMutation, { loading: isDeleteLoading }] =
    useDeleteDocument({
      update: (cache, _, input) => {
        cache.modify({
          id: cache.identify({
            id: input.variables?.input.id,
            __typename: 'Document',
          }),
          fields: (cachedId, { DELETE }) => DELETE,
        });
      },
      refetchQueries: [ListAllOperations.Query.GetPlayerDocuments],
    });

  const deleteDocument = (id: DocumentData['id']) => {
    if (isDeleteLoading) {
      return;
    }

    deleteDocumentMutation({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteDocument: { id, __typename: 'DeleteDocumentPayload' },
      },
      refetchQueries: [
        {
          query: GetPlayerDocumentsDocument,
          variables: {
            playerId,
            thumbnailImageUrlHeight: DEFAULT_IMAGE_SIZE,
            thumbnailImageUrlWidth: DEFAULT_IMAGE_SIZE,
            first: documents?.length || 0,
          },
        },
      ],
    });

    undoActionToast('Документ удалён');
  };

  useBlockComponentState({
    loadData: loadDocuments,
    loading,
    error: documentsError,
  });

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore({
        variables: {
          playerId,
          thumbnailImageUrlHeight: DEFAULT_IMAGE_SIZE,
          thumbnailImageUrlWidth: DEFAULT_IMAGE_SIZE,
          first: COUNT_PER_PAGE,
          after: data?.player.documents?.pageInfo.endCursor,
        },
      }),
    [data?.player.documents?.pageInfo.endCursor, fetchMore, playerId]
  );

  const refetchDocuments = () => {
    refetch?.();
  };

  const openCreateDocumentsModal = () => {
    dispatchModal({
      type: ModalActions.Open,
      payload: {
        content: (
          <CreateDocumentsForm
            refetchDocuments={refetchDocuments}
            playerId={playerId}
          />
        ),
        title: 'Загрузка документов',
      },
    });
  };

  const isEmptyList = documents && !documents.length;

  const isEndOfList =
    isFullScreenBlock && !hasNextPage && !loading && !isEmptyList;

  const onScroll = throttle(() => {
    const { innerHeight: viewportHeight } = window;
    const { scrollHeight: documentHeight, scrollTop } =
      document.documentElement;

    const shouldLoadMoreByScroll =
      documentHeight - (scrollTop + viewportHeight) < PX_BEFORE_END_OF_SCREEN &&
      scrollTop > 0;

    if (shouldLoadMoreByScroll && !loading && !isEndOfList) {
      fetchMoreWithoutBlockLoader(fetchMoreCallback, dispatch);
    }
  }, 100);

  useLayoutEffect(() => {
    if (isFullScreenBlock) {
      document.addEventListener('scroll', onScroll);
    }

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isFullScreenBlock, onScroll]);

  const documentsImages: Array<DocumentImage> =
    documents?.map(({ node }) => ({
      imageUrl: node.imageUrl,
    })) || [];

  return {
    state,
    documents,
    deleteDocument,
    refetchDocuments,
    openCreateDocumentsModal,
    isEmptyList,
    isDeleteLoading,
    loading,
    isEndOfList,
    documentsImages,
  };
};
