import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type RejectAllQualifiedReferralsVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type RejectAllQualifiedReferrals = { __typename: 'Mutation', rejectAllQualifiedReferrals: { __typename: 'RejectAllQualifiedReferralsPayload', void?: any | null } };


export const RejectAllQualifiedReferralsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RejectAllQualifiedReferrals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rejectAllQualifiedReferrals"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type RejectAllQualifiedReferralsMutationFn = Apollo.MutationFunction<RejectAllQualifiedReferrals, RejectAllQualifiedReferralsVariables>;

/**
 * __useRejectAllQualifiedReferrals__
 *
 * To run a mutation, you first call `useRejectAllQualifiedReferrals` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAllQualifiedReferrals` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAllQualifiedReferrals, { data, loading, error }] = useRejectAllQualifiedReferrals({
 *   variables: {
 *   },
 * });
 */
export function useRejectAllQualifiedReferrals(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectAllQualifiedReferrals, RejectAllQualifiedReferralsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RejectAllQualifiedReferrals, RejectAllQualifiedReferralsVariables>(RejectAllQualifiedReferralsDocument, options);
      }
export type RejectAllQualifiedReferralsHookResult = ReturnType<typeof useRejectAllQualifiedReferrals>;
export type RejectAllQualifiedReferralsMutationResult = Apollo.MutationResult<RejectAllQualifiedReferrals>;
export type RejectAllQualifiedReferralsMutationOptions = Apollo.BaseMutationOptions<RejectAllQualifiedReferrals, RejectAllQualifiedReferralsVariables>;