import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { UpdateReferralLinkMediaItemNameInput } from 'generatedGraphql';
import Error from 'ui/Error';
import { validate } from './validation';
import { useEditMediaItemReferralLinkNameModal } from './hooks/useEditMediaItemReferralLinkNameModal';

interface Props {
  mediaItemReferralLinkId: string;
  name: string;
}

export const EditMediaItemReferralLinkNameModal: FC<Props> = ({
  mediaItemReferralLinkId,
  name,
}) => {
  const initialValues: UpdateReferralLinkMediaItemNameInput = {
    name,
    id: mediaItemReferralLinkId,
  };

  const { onEditName, loading, error } = useEditMediaItemReferralLinkNameModal({
    mediaItemReferralLinkId,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onEditName}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <div>
              <Input
                placeholder="Введите название"
                name="name"
                label="Название"
                maxLength={50}
              />
            </div>
          </FormRowsWrapper>
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
            disabled={!isValid || !dirty || loading}
          >
            Сохранить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
