export enum ColumnId {
  Id = 'id',
  Affiliate = 'affiliate',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Affiliate]: 'Отправитель',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  ColumnId.Id,
  ColumnId.Affiliate,
  ColumnId.Sum,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Affiliate,
  ColumnId.Sum,
  ColumnId.CreatedAt,
];
