import { FC } from 'react';
import classnames from 'classnames';
import { CardIcon, CardIconConfig } from 'ui/Card/CardIcon';
import styles from './Status.module.scss';

export interface StatusProps {
  status: string;
  iconConfig: CardIconConfig;
  text?: string | null;
  className?: string;
}

export const Status: FC<StatusProps> = ({
  status,
  iconConfig,
  text,
  className,
}) => {
  const colorStatus: string = iconConfig.iconColor ? iconConfig.iconColor : '';

  return (
    <div
      className={classnames(styles[colorStatus], styles.container, className)}
    >
      <CardIcon config={iconConfig} className={styles.cardIcon} />
      <div className={styles.contentContainer}>
        <div className={classnames(styles.title, text && styles.bold)}>
          {status}
        </div>
        {text && <div className={styles.text}>{text}</div>}
      </div>
    </div>
  );
};
