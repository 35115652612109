import { faBan, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';

export const getIconConfig = (isApproved: boolean) => {
  if (!isApproved) {
    return { icon: faBan, iconColor: CardIconColor.RED };
  }

  return { icon: faCircleCheck, iconColor: CardIconColor.GREEN };
};
