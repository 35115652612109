import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useApproveCompanyPlayerAccount } from '../queries/generated/ApproveCompanyPlayerAccount';
import { useDeclineCompanyPlayerAccount } from '../queries/generated/DeclineCompanyPlayerAccount';

export const useManagePlayerAccount = (companyId: string) => {
  const { state, dispatch } = useContext(ModalContext);

  const approveCompleteHandler = () => {
    toast.success('Игровой аккаунт подтверждён');
  };

  const approveErrorHandler = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}подтверждении игрового аккаунта произошла ошибка`,
      text: error.message,
      toastId: 'approve-company-player-account-error',
    });
  };

  const declineCompleteHandler = () => {
    if (state.isOpened) {
      dispatch({
        type: ModalActions.Close,
      });
    }

    toast.error('Игровой аккаунт отклонён');
  };

  const declineErrorHandler = (error: ApolloError) => {
    if (!state.isOpened) {
      errorToast({
        header: `При${nbsp}отклонении игрового аккаунта произошла ошибка`,
        text: error.message,
        toastId: 'decline-company-player-account-error',
      });
    }
  };

  const [approvePlayerAccount, { loading: approvePlayerAccountLoading }] =
    useApproveCompanyPlayerAccount({
      variables: {
        input: {
          companyId,
        },
      },
      onCompleted: approveCompleteHandler,
      onError: approveErrorHandler,
    });

  const [
    declinePlayerAccount,
    { loading: declinePlayerAccountLoading, error: declinePlayerAccountError },
  ] = useDeclineCompanyPlayerAccount({
    variables: {
      input: {
        companyId,
      },
    },
    onCompleted: declineCompleteHandler,
    onError: declineErrorHandler,
  });

  return {
    approvePlayerAccount,
    declinePlayerAccount,
    approvePlayerAccountLoading,
    declinePlayerAccountLoading,
    declinePlayerAccountError,
  };
};
