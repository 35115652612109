import { calculatePercent } from 'src/utils/calculatePercent';
import { LoadingFileStatus } from '../const';
import { LocalFile } from '../types';

interface FileToUploadData {
  uploadedPercent?: number;
  status: LoadingFileStatus;
  id: string;
  name: string;
  note?: string | null;
}

export const getDocumentToUploadData = (
  documentToUpload: LocalFile
): FileToUploadData => ({
  uploadedPercent: calculatePercent(
    documentToUpload.uploadedBites,
    documentToUpload.file.size
  ),
  status: documentToUpload.status,
  id: documentToUpload.id,
  name: documentToUpload.file.name,
  note: documentToUpload.note,
});
