import { AuthStatuses } from './const';

const TWO_FA_STATUSES = [
  AuthStatuses.TwoFactorGenerationRequired,
  AuthStatuses.TwoFactorAuthenticationRequired,
];

const isTwoFactorRequired = (
  authStatus: (typeof AuthStatuses)[keyof typeof AuthStatuses]
): boolean =>
  authStatus && TWO_FA_STATUSES.some((status) => authStatus === status);

export default isTwoFactorRequired;
