import React, { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import styles from './FormRow.module.scss';

interface Props {
  isWide?: boolean;
}

const FormRow: FC<PropsWithChildren<Props>> = ({ children, isWide }) => (
  <div className={classnames(isWide && styles.wideRow, styles.row)}>
    {children}
  </div>
);

export default FormRow;
