import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetQualifiedReferralConfirmationSettingsLazyQuery } from '../queries/generated/GetQualifiedReferralConfirmationSettings';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useGetQualifiedReferralConfirmationSettings = () => {
  const [
    loadQualifiedReferralConfirmationSettingsList,
    { data, loading, error, fetchMore, refetch },
  ] = useGetQualifiedReferralConfirmationSettingsLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
    },
  });

  const hasNextPage =
    data?.qualifiedReferralConfirmationSettings?.pageInfo.hasNextPage;
  const endCursor =
    data?.qualifiedReferralConfirmationSettings?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  const confirmationSettingsList = useMemo(
    () =>
      data?.qualifiedReferralConfirmationSettings?.edges.map(
        ({ node }) => node
      ),
    [data?.qualifiedReferralConfirmationSettings?.edges]
  );

  return {
    loadQualifiedReferralConfirmationSettingsList,
    data,
    loading,
    error,
    refetch,
    hasNextPage,
    fetchMoreCallback,
    confirmationSettingsList,
  };
};
