import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { Dropdown } from 'ui/Dropdown';
import { useEffect, useState } from 'react';
import { useGetCountries } from './queries/generated/GetCountries';
import { INVALID_INTEGER_CHARS } from '../../../../../../utils/blockInvalidChars';
import { getDisplayName } from '../../../../../../utils/getDisplayName';

export const CreateRevShareForm = () => {
  const { data, error, loading } = useGetCountries();

  const countryOptions = data?.countries.map((code: string) => ({
    id: code,
    label: getDisplayName({ value: code, type: 'region' }) || code,
  }));

  const [optionsState, setOptionsState] = useState(countryOptions);
  const [isLoadedCountries, setIsLoadedCountries] = useState(false);

  const onSearchInList = (value: string) => {
    const opt = countryOptions?.filter((option) =>
      option.label.toUpperCase().includes(value.toUpperCase())
    );

    setOptionsState(opt);
  };

  useEffect(() => {
    if (!isLoadedCountries && countryOptions) {
      setIsLoadedCountries(true);
      setOptionsState(countryOptions);
    }
  }, [countryOptions, isLoadedCountries, optionsState]);

  return (
    <>
      <FormRowsWrapper>
        <Input
          name="title"
          label="Название"
          maxLength={100}
          placeholder="Введите название тарифа"
        />
      </FormRowsWrapper>
      <FormRowsWrapper>
        <Dropdown
          loadingError={error}
          loading={loading}
          onSearchInList={onSearchInList}
          isCheckbox
          name="countries"
          label="Геолокация"
          options={optionsState}
        />
      </FormRowsWrapper>
      <FormRowsWrapper>
        <Input.Number
          invalidChars={INVALID_INTEGER_CHARS}
          placeholder="Укажите Baseline"
          name="baseline"
          label="Baseline"
        />
      </FormRowsWrapper>
      <FormRowsWrapper>
        <Input.Number
          invalidChars={INVALID_INTEGER_CHARS}
          placeholder="Укажите вознаграждение"
          name="reward"
          label="Вознаграждение"
        />
      </FormRowsWrapper>
    </>
  );
};
