import { nbsp } from 'src/const';

export enum ColumnId {
  MediaOwner = 'companyPartner',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  Hold = 'playerStatusStatistic.hold',
  Qualified = 'playerStatusStatistic.qualified',
  NotQualified = 'playerStatusStatistic.canceled',
  Approved = 'playerStatusStatistic.approved',
  Rejected = 'playerStatusStatistic.rejected',
  Paid = 'playerStatusStatistic.paid',
  Unpaid = 'playerStatusStatistic.unpaid',
  RevShare = 'playerStatusStatistic.revShare',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.MediaOwner]: 'Владелец медиа',
  [ColumnId.Hits]: 'Хиты',
  [ColumnId.Hosts]: 'Хосты',
  [ColumnId.Registrations]: 'Регистрации',
  [ColumnId.FirstDeposits]: 'Первые депозиты',
  [ColumnId.HostToRegConversion]: 'Host2reg',
  [ColumnId.RegToDepConversion]: 'Reg2dep',

  [ColumnId.AllDeposit]: 'Все депозиты',
  [ColumnId.AverageDeposit]: 'Средний депозит',
  [ColumnId.AllRedeposit]: 'Все редепозиты',
  [ColumnId.Redeposits]: 'Количество редепозитов',
  [ColumnId.NewDeposit]: 'Новые депозиты',

  [ColumnId.Withdrawal]: 'Выводы',
  [ColumnId.NewWithdrawal]: 'Новые выводы',
  [ColumnId.NewProfit]: 'Новый доход',
  [ColumnId.Hold]: `В${nbsp}ожидании`,
  [ColumnId.Qualified]: 'Квалифицированные',
  [ColumnId.NotQualified]: 'Неквалифицированные',
  [ColumnId.Approved]: 'Подтвержденные',
  [ColumnId.Rejected]: 'Отклонённые',
  [ColumnId.Paid]: 'Оплаченные',
  [ColumnId.Unpaid]: `Без${nbsp}оплаты`,
  [ColumnId.RevShare]: `В${nbsp}RevShare`,
  [ColumnId.NGR]: 'NGR',
  [ColumnId.Profit]: 'Доход',
  [ColumnId.Upcoming]: 'Вознаграждение',
  [ColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  ColumnId.MediaOwner,
  ColumnId.Hits,
  ColumnId.Hosts,
  ColumnId.Registrations,
  ColumnId.FirstDeposits,
  ColumnId.HostToRegConversion,
  ColumnId.RegToDepConversion,

  ColumnId.AllDeposit,
  ColumnId.AverageDeposit,
  ColumnId.AllRedeposit,
  ColumnId.Redeposits,
  ColumnId.NewDeposit,

  ColumnId.Withdrawal,
  ColumnId.NewWithdrawal,
  ColumnId.NewProfit,
  ColumnId.Hold,
  ColumnId.Qualified,
  ColumnId.NotQualified,
  ColumnId.Approved,
  ColumnId.Rejected,
  ColumnId.Paid,
  ColumnId.Unpaid,
  ColumnId.RevShare,
  ColumnId.NGR,
  ColumnId.Profit,
  ColumnId.Upcoming,
  ColumnId.RewardPaid,
];
