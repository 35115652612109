import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from '../../../../../components/Auth';
import { usePartnerEdit } from './usePartnerEdit';
import { PartnerBasicData } from '../../../../../queries/generated/PartnerBasicData';

interface UsePartnerMarkersParams {
  partner: PartnerBasicData;
}

export const usePartnerMarkers = ({ partner }: UsePartnerMarkersParams) => {
  const auth = useAuth();
  const { twoFactorEnabled } = partner;

  const { resetPartnerTwoFactor } = usePartnerEdit({ partner });

  const isMarkerDisabled = (privileges: Array<UserPrivilege>) =>
    !auth.privileges.isSomeGranted({
      privileges,
    });

  return [
    {
      id: 'twoFactorEnable',
      content: twoFactorEnabled && '2FA включена',
      isHidden: !twoFactorEnabled,
      onClick: resetPartnerTwoFactor,
      isDisabled: isMarkerDisabled([UserPrivilege.ResetPartnerTwoFactor]),
    },
    {
      id: 'twoFactorDisable',
      content: !twoFactorEnabled && '2FA отключена',
      isHidden: twoFactorEnabled,
      onClick: resetPartnerTwoFactor,
      isDisabled: isMarkerDisabled([UserPrivilege.ResetPartnerTwoFactor]),
    },
  ];
};
