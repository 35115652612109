import React, { FC } from 'react';
import FormWarning from 'ui/FormWarning/FormWarning';
import { CheckboxSize } from 'ui/Checkbox';

export const FORM_WARNING_RESTRICTION_CHECKBOX_NAME = 'approved';

const FormWarningRestriction: FC = () => (
  <FormWarning
    subTitle="Платёж необходимо согласовать"
    checkbox={{
      checkboxSize: CheckboxSize.Small,
      label: 'Платеж согласован',
      name: FORM_WARNING_RESTRICTION_CHECKBOX_NAME,
    }}
  >
    <p>
      У&nbsp;пользователя есть ограничение на&nbsp;создание платежей. Согласуйте
      выплату.
    </p>
  </FormWarning>
);

export default FormWarningRestriction;
