import { useCallback, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { throttle } from 'lodash-es';
import { useGetMediaCampaignsForDropdown } from './queries/generated/GetMediaCampaignsForDropdown';
import { getMediaCampaignsOptions } from './helpers';
import { nbsp } from '../../const';

const COUNT_PER_PAGE = 10;

export const useGetMediaCampaigns = () => {
  const [search, setSearch] = useState<string>('');

  const handleErrorGetMediaCampaigns = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке медиа кампаний произошла ошибка`,
      text: error.message,
      toastId: 'get-media-campaigns-error',
    });
  };

  const {
    data,
    loading,
    fetchMore: fetchMoreMediaCampaigns,
    error,
  } = useGetMediaCampaignsForDropdown({
    variables: {
      search,
      first: COUNT_PER_PAGE,
    },
    onError: (getMediaCampaignsError) =>
      handleErrorGetMediaCampaigns(getMediaCampaignsError),
  });

  const hasNextPage = data?.mediaCampaigns.pageInfo.hasNextPage;

  const endCursor = data?.mediaCampaigns?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      fetchMoreMediaCampaigns({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [fetchMoreMediaCampaigns, endCursor]
  );

  const mediaCampaignOptions = getMediaCampaignsOptions(
    data?.mediaCampaigns.edges
  );

  const handleSearchInList = useRef(
    throttle((value: string) => {
      setSearch(value);
    }, 300)
  ).current;

  return {
    handleSearchInList,
    mediaCampaignOptions,
    loading,
    hasNextPage,
    fetchMore,
    error,
  };
};
