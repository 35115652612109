import { nbsp } from 'src/const';

export enum ColumnId {
  SuspectState = 'suspectState',
  Id = 'id',
  Player = 'player',
  Affiliate = 'affiliate',
  ReferralCode = 'code',
  CPATariff = 'tariff.title',
  Status = 'status',
  ReviewResult = 'review',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Action = '__typename',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.SuspectState]: `${nbsp}`,
  [ColumnId.Id]: 'ID',
  [ColumnId.Player]: 'Игрок',
  [ColumnId.Affiliate]: 'Партнёр/Компания',
  [ColumnId.ReferralCode]: 'Реферальный код',
  [ColumnId.CPATariff]: 'CPA тариф',
  [ColumnId.Status]: 'Статус',
  [ColumnId.ReviewResult]: 'Результат проверки',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлен',
  [ColumnId.Action]: 'Действие',
};

export const columnsIds = [
  ColumnId.SuspectState,
  ColumnId.Id,
  ColumnId.Player,
  ColumnId.Affiliate,
  ColumnId.ReferralCode,
  ColumnId.CPATariff,
  ColumnId.Status,
  ColumnId.ReviewResult,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
  ColumnId.Action,
];
