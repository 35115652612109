import { useCallback, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { throttle } from 'lodash-es';
import { nbsp } from 'src/const';
import { useGetPartnersForDropdownLazyQuery } from '../queries/generated/GetPartnersForDropdown';

const COUNT_PER_PAGE = 10;

export const useGetPartnersQuery = () => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке партнеров произошла ошибка`,
      text: error.message,
      toastId: 'get-partners-for-dropdown-error',
    });
  };

  const [loadPartners, { data, loading, fetchMore: fetchMorePartners, error }] =
    useGetPartnersForDropdownLazyQuery({
      onError: handleError,
    });

  const hasNextPage = data?.partners.pageInfo.hasNextPage;
  const endCursor = data?.partners?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      fetchMorePartners({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
          search: '',
        },
      }),
    [fetchMorePartners, endCursor]
  );

  const loadPartnersWithThrottle = useRef(
    throttle(async (searchText: string) => {
      await loadPartners({
        variables: {
          search: searchText,
          first: COUNT_PER_PAGE,
        },
      });
    }, 1000)
  ).current;

  return {
    loadPartnersWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  };
};
