import { Form, Formik } from 'formik';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import Error from 'ui/Error';
import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { Tooltip } from 'ui/Tooltip';
import { EditReferralLinkValues } from './types';
import { FieldName, mapFieldNameToTitle } from './const';
import { validate } from './validation';
import { useUpdateReferralLinkName } from './hooks/useUpdateReferralLinkName';
import { useGetPartnerReferralLinkMediaItemName } from '../PartnerReferralLinkMediaItem/queries/generated/GetPartnerReferralLinkMediaItemName';

interface Props {
  partnerId: string;
  referralLinkMediaItemId: string;
}

export const EditReferralLinkMediaItem: FC<Props> = ({
  partnerId,
  referralLinkMediaItemId,
}) => {
  const {
    data: referralLinkNameData,
    loading: getReferralLinkNameLoading,
    error: getReferralLinkNameError,
  } = useGetPartnerReferralLinkMediaItemName({
    variables: {
      partnerId,
      referralLinkMediaItemId,
    },
  });

  const initialValues: EditReferralLinkValues = {
    [FieldName.Title]:
      referralLinkNameData?.partner.referralLinkMediaItem.name || '',
  };

  const {
    handleUpdateReferralLinkName,
    updateReferralLinkNameLoading,
    updateReferralLinkNameError,
  } = useUpdateReferralLinkName();

  const error = getReferralLinkNameError || updateReferralLinkNameError;

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      onSubmit={(values) =>
        handleUpdateReferralLinkName(values, referralLinkMediaItemId)
      }
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={FieldName.Title}
                label={mapFieldNameToTitle[FieldName.Title]}
                placeholder="Введите название"
                maxLength={50}
                disabled={getReferralLinkNameLoading}
              />
            </FormRow>
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty
                ? 'Необходимо корректно заполнить поле'
                : undefined
            }
            placement="right"
          >
            <Button
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={updateReferralLinkNameLoading}
              disabled={!isValid || !dirty}
            >
              Сохранить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
