import { RESET_AFFILIATES_IN_PROGRESS } from '../actionTypes';

export interface ResetAffiliatesInProgress {
  type: typeof RESET_AFFILIATES_IN_PROGRESS;
}

export const resetAffiliatesInProgressAction =
  (): ResetAffiliatesInProgress => ({
    type: RESET_AFFILIATES_IN_PROGRESS,
  });
