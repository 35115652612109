import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PlayerBasicData } from '../../../../queries/generated/PlayerBasicData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPlayerDataVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPlayerData = { __typename: 'Query', player: { __typename: 'Player', defaultName: string, deletedAt?: string | null, id: string, internalOutgoingMoneyTransfersEnabled: boolean, name: string, rank: CommonType.PlayerRank, rankInProgress: CommonType.PlayerRank, state: SchemaTypes.PlayerState, trusted: boolean, twoFactorEnabled: boolean, note?: { __typename: 'Note', text: string } | null, signInRestriction?: { __typename: 'SignInRestriction', cancellable: boolean, expiredAt?: string | null, id: string, protected: boolean, reason: string } | null } };


export const GetPlayerDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPlayerData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PlayerBasicData"}}]}}]}},...PlayerBasicData.definitions]} as unknown as DocumentNode;

/**
 * __useGetPlayerData__
 *
 * To run a query within a React component, call `useGetPlayerData` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerData` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerData({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerData(baseOptions: ApolloReactHooks.QueryHookOptions<GetPlayerData, GetPlayerDataVariables> & ({ variables: GetPlayerDataVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPlayerData, GetPlayerDataVariables>(GetPlayerDataDocument, options);
      }
export function useGetPlayerDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayerData, GetPlayerDataVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPlayerData, GetPlayerDataVariables>(GetPlayerDataDocument, options);
        }
export function useGetPlayerDataSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPlayerData, GetPlayerDataVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPlayerData, GetPlayerDataVariables>(GetPlayerDataDocument, options);
        }
export type GetPlayerDataHookResult = ReturnType<typeof useGetPlayerData>;
export type GetPlayerDataLazyQueryHookResult = ReturnType<typeof useGetPlayerDataLazyQuery>;
export type GetPlayerDataSuspenseQueryHookResult = ReturnType<typeof useGetPlayerDataSuspenseQuery>;
export type GetPlayerDataQueryResult = Apollo.QueryResult<GetPlayerData, GetPlayerDataVariables>;