import { PostbackEvent, PostbackMethod } from 'generatedGraphql';

export const postbackMethodMap: { [key in PostbackMethod]: string } = {
  [PostbackMethod.Get]: 'Get',
  [PostbackMethod.Post]: 'Post',
};

export const postbackEventMap: { [key in PostbackEvent]: string } = {
  [PostbackEvent.FirstDeposit]: 'Первый депозит',
  [PostbackEvent.QualificationCheck]: 'Проверка квалификации',
  [PostbackEvent.Reward]: 'Вознаграждение',
  [PostbackEvent.Registration]: 'Регистрация',
};
