import { FC, useState } from 'react';
import { MediaItemTypeName } from 'commonTypes';
import { MediaItemsReferralLink } from './components/MediaItemsReferralLink';
import { MediaItemsPromoCode } from './components/MediaItemsPromoCode';

export const MediaItems: FC = () => {
  const [mediaItemsType, setMediaItemsType] = useState(
    MediaItemTypeName.ReferralLink
  );

  if (mediaItemsType === MediaItemTypeName.PromoCode) {
    return <MediaItemsPromoCode setMediaItemsType={setMediaItemsType} />;
  }

  return <MediaItemsReferralLink setMediaItemsType={setMediaItemsType} />;
};
