import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { RewardModalSteps } from '../../../const';
import { getModalSubTitle } from '../../../helpers';
import { RewardData } from '../../../queries/generated/RewardData';

interface UseChangeModalHeaderParams {
  rewardId: string;
  affiliate?: RewardData['affiliate'];
}

export const useChangeModalHeader = ({
  rewardId,
  affiliate,
}: UseChangeModalHeaderParams) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);

  useEffect(() => {
    if (state.step === RewardModalSteps.ConfirmationStep) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Подтверждение вознаграждения',
          subTitle: `#${rewardId}`,
        },
      });
    } else if (state.step === RewardModalSteps.BaseStep && affiliate) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${rewardId}`,
          subTitle: getModalSubTitle(affiliate),
        },
      });
    }
  }, [rewardId, modalDispatch, state.step, affiliate]);
};
