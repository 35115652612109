import { useContext, useEffect } from 'react';
import { ClientData } from 'commonComponents/ClientDataBlock/types';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { BlockContext, setLoadingAction } from 'ui/Block/store';
import { useAuth, UserPrivilege } from 'src/components/Auth';
import { useGetPartnerAdditionalDataLazyQuery } from '../queries/generated/GetPartnerAdditionalData';
import { getPartnerDataConfig } from '../helpers';
import { useEditPartnerIdentities } from './useEditPartnerIdentities';
import { useEditPlayerAccount } from './useEditPlayerAccount';

interface UsePartnerDataParams {
  id: string;
}

interface UsePartnerDataResult {
  refetchPartnerData: () => void;
  clientData: ClientData | null;
}

export const usePartnerData = ({
  id,
}: UsePartnerDataParams): UsePartnerDataResult => {
  const { state: blockState, dispatch: blockDispatch } =
    useContext(BlockContext);

  const auth = useAuth();

  const [loadPartnerData, { data, loading, error, refetch }] =
    useGetPartnerAdditionalDataLazyQuery({
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });

  const partner = data?.partner;

  const { onPartnerIdentitiesEdit } = useEditPartnerIdentities({
    partnerId: id,
    email: partner?.email,
  });

  const { onPlayerAccountEdit } = useEditPlayerAccount({
    partnerId: id,
    partner,
  });

  const refetchPartnerData = () => {
    refetch?.();
  };

  useBlockComponentState({
    loadData: loadPartnerData,
    loading,
    error,
  });

  useEffect(() => {
    if (!blockState.isOpened) {
      blockDispatch(setLoadingAction({ loading: false }));
    }
  }, [blockDispatch, blockState.isOpened]);

  const isEditButtonVisible = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdatePartnerIdentities],
  });

  const hasPrivilegeToDeclinePlayerAccount = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.DeclinePartnerPlayerAccount],
  });

  const clientData = getPartnerDataConfig({
    data,
    onPartnerIdentitiesEdit,
    onPlayerAccountEdit,
    isEditButtonVisible,
    hasPrivilegeToDeclinePlayerAccount,
  });

  return {
    refetchPartnerData,
    clientData,
  };
};
