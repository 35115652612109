import { OwnerTypeName } from 'commonTypes';
import { RewardData } from '../../../queries/generated/RewardData';

interface GetDataCardAffiliateTitlesResult {
  name: string;
  email: string;
}

export const getDataCardAffiliateTitles = (
  affiliate: RewardData['affiliate']
): GetDataCardAffiliateTitlesResult => {
  if (affiliate.__typename === OwnerTypeName.Company) {
    return { name: 'Название компании', email: 'Почта компании' };
  }

  return { name: 'Имя партнёра', email: 'Почта партнёра' };
};
