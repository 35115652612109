import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ReactElement } from 'react';
import { Confirmation } from 'src/components/ClientDataBlockRowConfirm/types';
import styles from './ClientDataBlockRowConfirm.module.scss';

interface Props {
  confirmation: Confirmation;
}

export const ClientDataBlockRowConfirm = ({
  confirmation,
}: Props): ReactElement => {
  if (confirmation.isConfirmed) {
    return (
      <div className={styles.confirmation}>
        <FontAwesomeIcon icon={faCheck} className={styles.icon} />
        <div>{confirmation.value}</div>
      </div>
    );
  }

  return <div className={styles.notConfirmed}>{confirmation.value}</div>;
};
