import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeleteQualifiedReferralConfirmationSettingVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeleteQualifiedReferralConfirmationSettingInput;
}>;


export type DeleteQualifiedReferralConfirmationSetting = { __typename: 'Mutation', deleteQualifiedReferralConfirmationSetting: { __typename: 'DeleteQualifiedReferralConfirmationSettingPayload', void?: any | null } };


export const DeleteQualifiedReferralConfirmationSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteQualifiedReferralConfirmationSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteQualifiedReferralConfirmationSettingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteQualifiedReferralConfirmationSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type DeleteQualifiedReferralConfirmationSettingMutationFn = Apollo.MutationFunction<DeleteQualifiedReferralConfirmationSetting, DeleteQualifiedReferralConfirmationSettingVariables>;

/**
 * __useDeleteQualifiedReferralConfirmationSetting__
 *
 * To run a mutation, you first call `useDeleteQualifiedReferralConfirmationSetting` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQualifiedReferralConfirmationSetting` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQualifiedReferralConfirmationSetting, { data, loading, error }] = useDeleteQualifiedReferralConfirmationSetting({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQualifiedReferralConfirmationSetting(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQualifiedReferralConfirmationSetting, DeleteQualifiedReferralConfirmationSettingVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteQualifiedReferralConfirmationSetting, DeleteQualifiedReferralConfirmationSettingVariables>(DeleteQualifiedReferralConfirmationSettingDocument, options);
      }
export type DeleteQualifiedReferralConfirmationSettingHookResult = ReturnType<typeof useDeleteQualifiedReferralConfirmationSetting>;
export type DeleteQualifiedReferralConfirmationSettingMutationResult = Apollo.MutationResult<DeleteQualifiedReferralConfirmationSetting>;
export type DeleteQualifiedReferralConfirmationSettingMutationOptions = Apollo.BaseMutationOptions<DeleteQualifiedReferralConfirmationSetting, DeleteQualifiedReferralConfirmationSettingVariables>;