import { FC } from 'react';
import { playerRankMap } from 'src/const';
import { PlayerRank } from 'commonTypes';
import UpdateClientRank from 'commonComponents/UpdateClientRank';
import { useUpdatePlayerRank } from './useUpdatePlayerRank';

interface Props {
  id: string;
  playerRank?: PlayerRank;
}

const UpdatePlayerRank: FC<Props> = ({ id, playerRank }) => {
  const { loading, onUpdateRankPlayer, error } = useUpdatePlayerRank({
    id,
  });
  const options = Object.keys(playerRankMap).map((rank) => ({
    id: rank,
    label: playerRankMap[rank],
  }));

  return (
    <UpdateClientRank
      onUpdateRank={onUpdateRankPlayer}
      options={options}
      isLoading={loading}
      error={error}
      rank={playerRank}
      defaultRank={PlayerRank.Noob}
    />
  );
};

export default UpdatePlayerRank;
