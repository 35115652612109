import { AccountEventGroupType } from 'commonTypes';

export const mapEventGroupTypeToName: Record<AccountEventGroupType, string> = {
  [AccountEventGroupType.Bonus]: 'Бонус',
  [AccountEventGroupType.Deposit]: 'Депозит',
  [AccountEventGroupType.Game]: 'Игровая сессия',
  [AccountEventGroupType.Jackpot]: 'Джекпот',
  [AccountEventGroupType.MoneyTransfer]: 'Перевод',
  [AccountEventGroupType.Tournament]: 'Турнир',
  [AccountEventGroupType.Withdrawal]: 'Вывод',
};
