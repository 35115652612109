export enum ColumnId {
  Id = 'id',
  SettingType = 'type',
  Affiliate = 'affiliate',
  ReferralCode = 'code',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.SettingType]: 'Тип настройки',
  [ColumnId.Affiliate]: 'Партнёр/Компания',
  [ColumnId.ReferralCode]: 'Реферальный код',
  [ColumnId.Comment]: 'Комментарий',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлен',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.SettingType,
  ColumnId.Affiliate,
  ColumnId.ReferralCode,
  ColumnId.Comment,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
