import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PaymentData } from '../../../WithdrawalPayments/queries/generated/PaymentData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type AcceptPaymentVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AcceptPaymentInput;
}>;


export type AcceptPayment = { __typename: 'Mutation', acceptPayment: { __typename: 'AcceptPaymentPayload', payment: { __typename: 'Payment', createdAt: string, externalPaymentId?: string | null, id: string, processor: CommonType.PaymentProcessor, status: SchemaTypes.PaymentStatus, updatedAt: string, error?: { __typename: 'PaymentError', code: string, message: string } | null, paidSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const AcceptPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptPaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Payment"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PaymentData"}}]}}]}}]}}]}},...PaymentData.definitions,...SumData.definitions]} as unknown as DocumentNode;
export type AcceptPaymentMutationFn = Apollo.MutationFunction<AcceptPayment, AcceptPaymentVariables>;

/**
 * __useAcceptPayment__
 *
 * To run a mutation, you first call `useAcceptPayment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPayment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPayment, { data, loading, error }] = useAcceptPayment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptPayment(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptPayment, AcceptPaymentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AcceptPayment, AcceptPaymentVariables>(AcceptPaymentDocument, options);
      }
export type AcceptPaymentHookResult = ReturnType<typeof useAcceptPayment>;
export type AcceptPaymentMutationResult = Apollo.MutationResult<AcceptPayment>;
export type AcceptPaymentMutationOptions = Apollo.BaseMutationOptions<AcceptPayment, AcceptPaymentVariables>;