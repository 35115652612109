import { FileFormat } from '../const';

const getFileValidationError = (
  file: File,
  validFormats: Array<FileFormat>,
  maxSize: number
): string | null => {
  if (!validFormats.includes(file.type as FileFormat)) {
    return 'Неверный тип файла';
  }

  if (file.size > maxSize) {
    return 'Размер файла превышает допустимый';
  }

  return null;
};

export const getFileListValidationError = (
  files: FileList | null,
  validFormats: Array<FileFormat>,
  maxSize: number
): string | null => {
  if (!files) {
    return null;
  }

  for (let i = 0; i < files.length; i += 1) {
    const error = getFileValidationError(files[i], validFormats, maxSize);

    if (error) {
      return error;
    }
  }

  return null;
};
