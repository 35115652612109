import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
export type RestrictionsBasicData_BadBankRestriction = { __typename: 'BadBankRestriction', canceledAt?: string | null, id: string, reason?: string | null, updatedAt: string };

export type RestrictionsBasicData_ChargeBackRestriction = { __typename: 'ChargeBackRestriction', canceledAt?: string | null, id: string, reason?: string | null, updatedAt: string };

export type RestrictionsBasicData_ReceiveGiftRestriction = { __typename: 'ReceiveGiftRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, id: string, reason: string, updatedAt: string };

export type RestrictionsBasicData_WithdrawalRestriction = { __typename: 'WithdrawalRestriction', bypassRestrictionIfApproved: boolean, canceledAt?: string | null, id: string, reason: string, updatedAt: string };

export type RestrictionsBasicData = RestrictionsBasicData_BadBankRestriction | RestrictionsBasicData_ChargeBackRestriction | RestrictionsBasicData_ReceiveGiftRestriction | RestrictionsBasicData_WithdrawalRestriction;

export const RestrictionsBasicData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RestrictionsBasicData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RestrictionInterface"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"WithdrawalRestriction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bypassRestrictionIfApproved"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReceiveGiftRestriction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bypassRestrictionIfApproved"}}]}}]}}]} as unknown as DocumentNode;