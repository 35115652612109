import { useCallback, useContext, useEffect } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { ModalActions, ModalContext } from 'ui/Modal';
import { BlockContext, setLoadingAction } from 'ui/Block/store';
import UpdatePlayerIdentities from './components/UpdatePlayerIdentities/UpdatePlayerIdentities';
import {
  GetPlayerAdditionalData,
  useGetPlayerAdditionalDataLazyQuery,
} from './queries/generated/GetPlayerAdditionalData';

interface Args {
  id: string;
}

interface PlayerData {
  data?: GetPlayerAdditionalData;
  onEdit: () => void;
  refetchPlayerData: () => void;
}

export const usePlayerData = ({ id }: Args): PlayerData => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { state: blockState, dispatch: blockDispatch } =
    useContext(BlockContext);
  const [loadPlayerData, { data, loading, error, refetch }] =
    useGetPlayerAdditionalDataLazyQuery({
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });

  const onEdit = useCallback(async () => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        content: <UpdatePlayerIdentities id={id} />,
        title: 'Изменение данных входа',
        subTitle: data && `${data.player.name} #${data.player.id}`,
      },
    });
  }, [modalDispatch, id, data]);

  const refetchPlayerData = () => {
    refetch?.();
  };

  useBlockComponentState({
    loadData: loadPlayerData,
    loading,
    error,
  });

  useEffect(() => {
    if (!blockState.isOpened) {
      blockDispatch(setLoadingAction({ loading: false }));
    }
  }, [blockDispatch, blockState.isOpened]);

  return {
    data,
    onEdit,
    refetchPlayerData,
  };
};
