import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UploadFileVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UploadFileInput;
}>;


export type UploadFile = { __typename: 'Mutation', uploadFile: { __typename: 'UploadFilePayload', file: { __typename: 'File', id: string } } };


export const UploadFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UploadFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UploadFileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadFile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFile, UploadFileVariables>;

/**
 * __useUploadFile__
 *
 * To run a mutation, you first call `useUploadFile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFile, { data, loading, error }] = useUploadFile({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFile(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFile, UploadFileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UploadFile, UploadFileVariables>(UploadFileDocument, options);
      }
export type UploadFileHookResult = ReturnType<typeof useUploadFile>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFile>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFile, UploadFileVariables>;