import { SET_LOADING } from '../actionTypes';

interface Payload {
  loading: boolean;
}

export interface SetLoading {
  type: typeof SET_LOADING;
  payload: Payload;
}

export const setLoadingAction = (payload: Payload): SetLoading => ({
  type: SET_LOADING,
  payload,
});
