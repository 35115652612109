import type { Placement } from '@floating-ui/react';

export interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  clickable?: boolean;
  isOpen?: boolean;
  horizontalOffset?: number;
  button?: {
    shouldShow: boolean;
    onClick(): void;
    text?: string;
  };
}

export enum TooltipTheme {
  Dark = 'dark',
  Light = 'light',
}
