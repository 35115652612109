import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { TournamentsTypeName } from 'commonTypes';
import { getNameTournamentByTypeName } from '../const';
import { TournamentRebuysBasicData } from '../queries/generated/TournamentRebuysBasicData';
import { TournamentRebuysData } from '../queries/generated/TournamentRebuysData';
import { TournamentRebuyModal } from '../components/TournamentRebuysModal/TournamentRebuysModal';

interface OpenModalParams {
  tournamentRebuy: TournamentRebuysBasicData | TournamentRebuysData;
  initiatorId: string;
}

type UseTournamentRebuyModalResult = (params: OpenModalParams) => void;

export const useTournamentRebuyModal = (): UseTournamentRebuyModalResult => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    async ({ tournamentRebuy, initiatorId }: OpenModalParams) => {
      const {
        tournament: { __typename },
        id,
      } = tournamentRebuy;
      const subTitle = getNameTournamentByTypeName(
        __typename as TournamentsTypeName
      );

      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle,
          content: <TournamentRebuyModal tournamentRebuyId={id} />,
          initiatorId,
        },
      });
    },
    [modalDispatch]
  );

  return openModal;
};
