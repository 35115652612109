import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import Error from 'ui/Error';
import { Confirmation } from 'ui/Confirmation';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { ApolloError } from '@apollo/client';
import { validate } from './validation';

interface Props {
  onDeleteClient: ({ reason }: { reason: string }) => void;
  error?: ApolloError;
  isLoading: boolean;
}

const Delete: FC<Props> = ({ onDeleteClient, error, isLoading }) => {
  const [initialValues] = useState({
    reason: '',
    confirmation: '',
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onDeleteClient}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input.Textarea name="reason" label="Причина" />
            </FormRow>
            <Confirmation
              label="Чтобы удалить игрока, напишите «УДАЛИТЬ»"
              loading={isLoading}
              buttonText="Удалить"
              isValid={isValid}
              dirty={dirty}
              submitValidationText="Необходимо корректно заполнить все поля"
            />
          </FormRowsWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default Delete;
