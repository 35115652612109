import { Game } from 'generatedGraphql';
import errorToast from 'ui/Toast/ErrorToast';
import { useGetGames } from 'src/queries/generated/GetGames';

interface useGetFreespinGameByDefaultResult {
  firstGameByDefault?: Game;
}

export const useGetFreespinGameByDefault =
  (): useGetFreespinGameByDefaultResult => {
    const { data } = useGetGames({
      variables: {
        search: '',
        first: 1,
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'get-first-game-error',
        });
      },
    });

    return {
      firstGameByDefault: data?.games.edges[0].node,
    };
  };
