import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { FC } from 'react';
import { FormikHelpers } from 'formik';
import styles from './ChangeAffiliateStatusButtons.module.scss';
import { AffiliatesInProgress } from '../../../../../../store';
import { useGetProcessAffiliateHandlers } from '../../hooks/useGetProcessAffiliateHandlers';
import { checkIsAllAffiliateChecked } from './helpers/checkIsAllAffiliateChecked';
import { CompanyAffiliateApplicationInitialValues } from '../../types';

interface Props {
  values: CompanyAffiliateApplicationInitialValues;
  setFieldValue: FormikHelpers<AffiliatesInProgress>['setFieldValue'];
  isEditable: boolean;
}
export const ChangeAffiliateStatusButtons: FC<Props> = ({
  values,
  setFieldValue,
  isEditable,
}) => {
  const {
    approveAffiliate,
    declineAffiliate,
    changeAvailableAffiliateCheckboxes,
  } = useGetProcessAffiliateHandlers();

  const hasUnTouchedAffiliates = Object.values(values).includes(!null);

  if (!isEditable) {
    return null;
  }

  return (
    <div className={styles.buttonContainer}>
      <Button
        onClick={() =>
          changeAvailableAffiliateCheckboxes(values, setFieldValue)
        }
        theme={ButtonTheme.Primary}
        disabled={!isEditable}
        size={ButtonSize.Large}
      >
        {checkIsAllAffiliateChecked(values) && hasUnTouchedAffiliates
          ? 'Сбросить выделение'
          : 'Выбрать всех'}
      </Button>
      <Button
        theme={ButtonTheme.Success}
        size={ButtonSize.Large}
        disabled={!isEditable}
        onClick={() => approveAffiliate(values, setFieldValue)}
      >
        Одобрить
      </Button>
      <Button
        theme={ButtonTheme.Important}
        size={ButtonSize.Large}
        disabled={!isEditable}
        onClick={() => declineAffiliate(values, setFieldValue)}
      >
        Отклонить
      </Button>
    </div>
  );
};
