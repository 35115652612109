import Button from 'ui/Button';
import { FC } from 'react';
import { Sum } from 'generatedGraphql';
import { useModalManageData } from 'ui/Modal';
import { KeyValueRow } from 'ui/KeyValueRow';
import { useAddPlayerNameToSidebarSubTitle } from '../../../../../../utils/hooks/useAddPlayerNameToSidebarSubTitle';
import styles from './CashbackModal.module.scss';
import { getCashbackFormula } from '../../../../../../utils/getCashbackFormula';
import { copyToClipboard } from '../../../../../../utils/copyToClipboard';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';
import { useGetPlayerCashback } from './queries/generated/GetPlayerCashback';

interface Props {
  id: string;
}

export const CashbackModal: FC<Props> = ({ id }) => {
  const { loading, error, data } = useGetPlayerCashback({
    variables: { id },
  });

  useAddPlayerNameToSidebarSubTitle(id);

  const copyFormula = (index: number) => {
    if (!data?.player.accounts[index].statistic) {
      return;
    }

    const cashbackFormula = getCashbackFormula(
      data.player.accounts[index].statistic
    );

    // TODO: we need to add here toast with error and success
    if (cashbackFormula) {
      copyToClipboard(cashbackFormula);
    }
  };

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-player-cashback-error' },
    hasData: !!data,
  });

  const getAmountWithCurrency = (sum: Sum): string =>
    formatSumWithCurrency(sum.amount, sum.currency);

  return (
    <div className={styles.root}>
      {data?.player.accounts.map(
        (
          {
            id: accountId,
            statistic: {
              creditSum,
              debitSum,
              paidCashbackSum,
              upcomingCashback,
            },
          },
          index
        ) => (
          <div className={styles.container} key={accountId}>
            <span className={styles.nextCashback}>
              {upcomingCashback.sum.amount === 0
                ? `Не предусмотрен ${upcomingCashback.sum.currency}`
                : getAmountWithCurrency(upcomingCashback.sum)}
            </span>
            <div className={styles.content}>
              <KeyValueRow
                title="Все списания"
                value={getAmountWithCurrency(creditSum)}
              />
              <KeyValueRow
                title="Все зачисления"
                value={getAmountWithCurrency(debitSum)}
              />
              <KeyValueRow
                title="Ранее выплаченный кэшбэк"
                value={getAmountWithCurrency(paidCashbackSum)}
              />
            </div>
            <Button
              className={styles.button}
              onClick={() => copyFormula(index)}
            >
              Скопировать формулу
            </Button>
          </div>
        )
      )}
    </div>
  );
};
