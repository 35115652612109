import { Route } from 'src/router/routes.const';
import { OwnerTypeName } from 'commonTypes';
import { GetRevShareReferralLinkReport } from '../queries/generated/GetRevShareReferralLinkReport';

interface GetPathParamsToOwnerPreviousRevShareReportParams {
  route: Route;
  params?: Record<string, string> | undefined;
}

export const getPathParamsToOwnerPreviousRevShareReport = (
  owner?: GetRevShareReferralLinkReport['mediaItem']['owner']
): GetPathParamsToOwnerPreviousRevShareReportParams => {
  let route: Route;
  let params: Record<string, string> | undefined;

  if (!owner) {
    return {
      route: Route.STATISTIC,
      params: undefined,
    };
  }

  switch (owner.__typename) {
    case OwnerTypeName.Partner:
      route = Route.STATISTIC_REV_SHARE_PARTNER;
      params = { id: owner.id };
      break;
    case OwnerTypeName.Company:
      route = Route.STATISTIC_REV_SHARE_COMPANY;
      params = { id: owner.id };
      break;
    case OwnerTypeName.CompanyAffiliate:
      route = Route.STATISTIC_REV_SHARE_COMPANY_AFFILIATE;
      params = { companyId: owner.company.id, affiliateId: owner.id };
      break;
    default:
      route = Route.STATISTIC;
      break;
  }

  return {
    route,
    params,
  };
};
