import { useFormikContext } from 'formik';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { CreatePromoCodeValues } from '../../../types';
import { CreatePromoCodeModalSteps, FieldName } from '../../../const';

interface useGoToNextStepResult {
  goToConnectionWithFreespinsAndBonusStep: () => void | false;
}

export const useGoToNextStep = (): useGoToNextStepResult => {
  const { isValid, values, setTouched } =
    useFormikContext<CreatePromoCodeValues>();
  const { updateModalStep } = useUpdateModalStep<CreatePromoCodeModalSteps>();

  const goToConnectionWithFreespinsAndBonusStep = () => {
    // setTouched to show errors
    setTouched({
      [FieldName.Token]: true,
      [FieldName.MediaCampaignId]: true,
      [FieldName.Usage]: true,
      // Set touch only if link with referral link block is open
      ...(values[FieldName.ConnectionWithReferralLink]
        ? { [FieldName.ReferralLink]: true }
        : null),
    });

    if (isValid) {
      return updateModalStep(
        CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus
      );
    }

    return false;
  };

  return { goToConnectionWithFreespinsAndBonusStep };
};
