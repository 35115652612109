import { parseHeaders } from './helpers';
import { CustomFetchOptions } from './types';

interface OnloadOptions {
  status: number;
  statusText: string;
  headers: Headers;
  url?: string;
}

/**
 * Custom fetch function tailored for Apollo Client to handle file uploads with XMLHttpRequest.
 * This enables advanced functionalities such as tracking upload progress, aborting the request, and performing actions upon completion.
 *
 * Use this function as part of the context's `fetchOptions` when executing a mutation through Apollo Client.
 *
 * @param {URL | RequestInfo} url - The endpoint URL for the file upload.
 * @param {CustomFetchOptions} options - Configuration options for the upload request.
 * @returns {Promise<Response>} A promise that resolves with the server's response.
 *
 * @example
 * const UPLOAD_FILE_MUTATION = gql`mutation UploadFile($file: Upload!) { uploadFile(file: $file) }`;
 *
 * const [uploadFile, { loading }] = useMutation(UPLOAD_FILE_MUTATION);
 *
 * const handleFileUpload = async (file) => {
 *   try {
 *     await uploadFile({
 *       variables: { file },
 *       context: {
 *         fetchOptions: {
 *           useUpload: true,
 *           onProgress: (event) => {
 *             console.log(`Progress: ${event.loaded} bytes uploaded`);
 *           },
 *           onAbortPossible: (abortHandler) => {
 *             // Optionally bind abortHandler to an event, e.g., button click.
 *           },
 *           onComplete: () => {
 *             console.log('Upload complete');
 *           },
 *         },
 *       },
 *     });
 *   } catch (error) {
 *     console.error('Upload failed:', error);
 *   }
 * };
 */
export const uploadFetch = (
  url: URL | RequestInfo,
  options: CustomFetchOptions
): Promise<Response> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    if (!options.method || options.method.toUpperCase() !== 'POST') {
      reject(new Error('Only POST method is allowed'));
      options.onComplete?.();

      return;
    }

    xhr.onload = () => {
      const headers = parseHeaders(xhr.getAllResponseHeaders());
      const responseUrl =
        'responseURL' in xhr ? xhr.responseURL : headers.get('X-Request-URL');

      const body = xhr.response;

      const opts: OnloadOptions = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers,
        url: responseUrl || undefined,
      };

      resolve(new Response(body, opts));
      options.onComplete?.();
    };

    const handleError = () => {
      reject(new Error('Network upload file request failed'));
      options.onComplete?.();
    };

    xhr.onerror = handleError;
    xhr.ontimeout = handleError;

    xhr.open(options.method, url.toString(), true);

    if (options.headers && typeof options.headers === 'object') {
      Object.keys(options.headers).forEach((key) => {
        xhr.setRequestHeader(
          key,
          String((options.headers as Record<string, string>)[key])
        );
      });
    }

    if (xhr.upload && options.onProgress) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible?.(() => xhr.abort());

    xhr.send(
      options.body as XMLHttpRequestBodyInit | Document | null | undefined
    );
  });
