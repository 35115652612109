import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  Group,
  GroupWithoutIsActive,
  MenuGroupItemData,
  MenuItemData,
  MenuLinkData,
} from '../types';
import { Route } from '../../../router/routes.const';

interface UseMenuStateReturn {
  menuState: Array<Group>;
  toggleMenuDropdown: (group: GroupWithoutIsActive) => void;
  openMenuDropdownItem: (id: string) => void;
  selectMenuItem: (id: string) => void;
}

export const useMenuState = (
  links: Array<MenuItemData | MenuGroupItemData>
): UseMenuStateReturn => {
  const [menuState, setMenuState] = useState<Array<Group>>([]);
  const { pathname } = useLocation();

  const toggleOpen = ({ id, isOpen }: GroupWithoutIsActive) => {
    setMenuState((arr) => {
      const menuStateArr = [...arr];

      menuStateArr.forEach((item, index) => {
        if (item.id === id) {
          menuStateArr.splice(index, 1, {
            id,
            isOpen,
            isActive: item.isActive,
          });
        }
      });

      return menuStateArr;
    });
  };

  const toggleActive = ({ id, isActive }: Omit<Group, 'isOpen'>) => {
    setMenuState((arr) => {
      const menuStateArr = [...arr];

      menuStateArr.forEach((item, index) => {
        if (item.id === id) {
          menuStateArr.splice(index, 1, {
            id,
            isOpen: item.isOpen,
            isActive,
          });
        }
      });

      return menuStateArr;
    });
  };

  const closeAllItem = () => {
    setMenuState((arr) => {
      const menuStateArr = [...arr];

      menuStateArr.forEach((item, index) => {
        menuStateArr.splice(index, 1, {
          id: item.id,
          isOpen: false,
          isActive: item.isActive,
        });
      });

      return menuStateArr;
    });
  };

  const deactivatedAllItem = () => {
    setMenuState((arr) => {
      const menuStateArr = [...arr];

      menuStateArr.forEach((item, index) => {
        menuStateArr.splice(index, 1, {
          id: item.id,
          isOpen: item.isOpen,
          isActive: false,
        });
      });

      return menuStateArr;
    });
  };

  useEffect(() => {
    // todo so that when the page is first rendered, with route the login state is not changed
    if (pathname === Route.LOGIN) {
      return;
    }

    links.forEach((link) => {
      if (!menuState.find((el) => el.id === link.title)) {
        if ('route' in link) {
          setMenuState((arr) => [
            ...arr,
            {
              id: link.title,
              isOpen: !!matchPath(link.route, pathname),
              isActive: !!matchPath(link.route, pathname),
            },
          ]);
        }

        if ('group' in link) {
          setMenuState((arr) => [
            ...arr,
            {
              id: link.title,
              isOpen: !!link.group?.find((item) => {
                if ('route' in item) {
                  const { route } = item as MenuLinkData;

                  return matchPath(route, pathname);
                }

                return false;
              }),
              isActive: !!link.group?.find((item) => {
                if ('route' in item) {
                  const { route } = item as MenuLinkData;

                  return matchPath(route, pathname);
                }

                return false;
              }),
            },
          ]);
        }
      }
    });
  }, [menuState, links, pathname]);

  const toggleMenuDropdown = ({ id, isOpen }: GroupWithoutIsActive) => {
    closeAllItem();
    toggleOpen({ id, isOpen });
  };

  const openMenuDropdownItem = (id: string) => {
    deactivatedAllItem();
    toggleActive({ id, isActive: true });
  };

  const selectMenuItem = (id: string) => {
    closeAllItem();
    deactivatedAllItem();
    toggleOpen({ id, isOpen: true });
    toggleActive({ id, isActive: true });
  };

  return {
    menuState,
    toggleMenuDropdown,
    openMenuDropdownItem,
    selectMenuItem,
  };
};
