import { FormikErrors } from 'formik';

export interface UpdateNameValues {
  clientName: string;
  confirmation: string;
}

export const validate = (
  values: UpdateNameValues
): FormikErrors<UpdateNameValues> => {
  const errors: FormikErrors<UpdateNameValues> = {};

  if (!values.clientName) {
    errors.clientName = 'Поле не может быть пустым';
  }

  if (values.confirmation.toLowerCase() !== 'изменить') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
