import React, { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData, Tabs } from 'ui/Modal';
import { faArrowAltToTop } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import { Sum } from 'generatedGraphql';
import { CounterTabTitle } from 'commonComponents/CounterTabTitle';
import { ActorInfo } from 'commonComponents/CreatePayment';
import { WithdrawalModalSteps } from '../WithdrawalPayments/types';
import { WithdrawalItemContext } from '../../WithdrawalItemStore';
import { useGetWithdrawalSidebar } from './queries/generated/GetWithdrawalSidebar';
import WithdrawalItem from '../WithdrawalItem';
import styles from './WithdrawalSidebar.module.scss';
import WithdrawalPayments from '../WithdrawalPayments';

interface Props {
  withdrawalId: string;
}

interface Values {
  remainingSum?: Sum;
  paymentPaidSum?: Sum | null;
  paymentId?: string | null;
  activeTab?: number | null;
  tabs: Array<Tabs>;
  actorInfo?: ActorInfo;
}

export const paymentsCountPerPage = 10;

export const useWithdrawalSidebar = ({ withdrawalId }: Props): Values => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { state: withdrawalState } = useContext(WithdrawalItemContext);

  const { data, error, loading } = useGetWithdrawalSidebar({
    variables: { id: withdrawalId, first: paymentsCountPerPage },
  });

  const payments = data?.withdrawal.payments;

  const remainingSum = data?.withdrawal.remainingSum;

  const paymentCount = payments?.edges.length || 0;

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-withdrawal-payment-basic' },
    hasData: true,
  });

  const hasNextPage =
    !!data?.withdrawal?.payments.pageInfo.hasNextPage ||
    paymentCount > paymentsCountPerPage;

  const count =
    paymentCount && paymentCount > paymentsCountPerPage
      ? paymentsCountPerPage
      : paymentCount;

  useEffect(() => {
    if (state.step === WithdrawalModalSteps.CreatePayment) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание платежа',
          subTitle: `Вывод #${withdrawalId}`,
          previousStep: WithdrawalModalSteps.BaseStep,
        },
      });
    } else if (state.step === WithdrawalModalSteps.AcceptPayment) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Выполнение платежа',
          subTitle: `#${withdrawalState.paymentId || ''}`,
          previousStep: WithdrawalModalSteps.BaseStep,
        },
      });
    } else {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          iconConfig: {
            icon: faArrowAltToTop,
            iconColor: CardIconColor.RED,
          },
          title: `#${withdrawalId}`,
          subTitle: 'Вывод',
        },
      });
    }
  }, [withdrawalState.paymentId, modalDispatch, state.step, withdrawalId]);

  const tabs: Array<Tabs> = [
    {
      title: 'Информация',
      content: <WithdrawalItem withdrawalId={withdrawalId} />,
    },
    {
      title: (
        <div className={styles.tab}>
          <div>Платежи</div>
          <CounterTabTitle
            withPlus={hasNextPage}
            count={count}
            isActiveTab={state.activeTab === 1}
          />
        </div>
      ),
      content: <WithdrawalPayments withdrawalId={withdrawalId} />,
    },
  ];

  const { paymentPaidSum } = withdrawalState;
  const { paymentId } = withdrawalState;

  const { activeTab } = state;
  const actor = data?.withdrawal.actor;

  return {
    remainingSum,
    paymentId,
    paymentPaidSum,
    tabs,
    activeTab,
    actorInfo: actor,
  };
};
