import { Edge } from 'commonTypes';
import { DateFormat, formatDate } from '../src/utils/formatDate';

type Node<T> = T & { id: string };

export const createListOfNodes = <T, D>(
  node: T,
  count: number,
  typeName: D,
  startCursorNumber = 0
): Array<Edge<Node<T>, D>> => {
  const listOfNodes = [];

  for (let i = startCursorNumber; i < startCursorNumber + count; i += 1) {
    listOfNodes.push({
      cursor: `cursor_${i}`,
      node: { id: i.toString(), ...node },
      __typename: typeName,
    });
  }

  return listOfNodes;
};

export const getDateConfirmed = (
  confirmedDate: string | null | undefined,
  notConfirmedText: string
): {
  value: string;
  isConfirmed: boolean;
} => {
  if (confirmedDate) {
    return {
      value: formatDate(confirmedDate, DateFormat.DefaultDateOld),
      isConfirmed: true,
    };
  }

  return { value: notConfirmedText, isConfirmed: false };
};
