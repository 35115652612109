import { nbsp } from 'src/const';

export enum ColumnId {
  Player = 'player',
  FirstDeposit = 'firstDeposit',
  AllDeposit = 'playerStatistic.allDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'redeposits',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',

  Profit = 'playerStatistic.financialIndicators.profit',
  AffiliateUpcomingReward = 'affiliateUpcomingReward',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Player]: 'Игрок',
  [ColumnId.FirstDeposit]: 'Первый депозит',
  [ColumnId.AllDeposit]: 'Депозиты',
  [ColumnId.AllRedeposit]: 'Редепозиты',
  [ColumnId.Redeposits]: 'Количество редепозитов',
  [ColumnId.IncomingMoneyTransfer]: 'Входящие переводы',

  [ColumnId.Withdrawal]: 'Выводы',
  [ColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [ColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [ColumnId.AdminFee]: 'Admin fee',
  [ColumnId.Bonus]: 'Бонусы',
  [ColumnId.TournamentPrize]: 'Призовые',
  [ColumnId.Jackpot]: 'Джекпоты',
  [ColumnId.NGR]: 'NGR',

  [ColumnId.Profit]: 'Доход',
  [ColumnId.AffiliateUpcomingReward]: 'Вознаграждение',
};

export const columnsIds: Array<ColumnId> = [
  ColumnId.Player,
  ColumnId.FirstDeposit,
  ColumnId.AllDeposit,
  ColumnId.AllRedeposit,
  ColumnId.Redeposits,
  ColumnId.IncomingMoneyTransfer,

  ColumnId.Withdrawal,
  ColumnId.DepositFee,
  ColumnId.WithdrawalFee,
  ColumnId.AdminFee,
  ColumnId.Bonus,
  ColumnId.TournamentPrize,
  ColumnId.Jackpot,
  ColumnId.NGR,

  ColumnId.Profit,
  ColumnId.AffiliateUpcomingReward,
];
