import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useResetPlayerName as useResetPlayerNameMutation } from './queries/generated/ResetPlayerName';

interface Args {
  id: string;
}

interface Return {
  loading: boolean;
  onResetPlayerName: () => void;
  error?: ApolloError;
}

export const useResetPlayerName = ({ id }: Args): Return => {
  const { dispatch } = useContext(ModalContext);

  const [
    ResetPlayerName,
    { data: updateResult, loading: updateLoading, error },
  ] = useResetPlayerNameMutation();
  const onResetPlayerName = async () => {
    await ResetPlayerName({
      variables: { input: { id } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });

      toast.success('Игрок изменён');
    }
  }, [updateResult, dispatch]);

  return {
    loading: updateLoading,
    onResetPlayerName,
    error,
  };
};
