import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { RestrictionsBasicData } from './RestrictionsBasicData';
export type RestrictionsData_BadBankRestriction = { __typename: 'BadBankRestriction', createdAt: string, canceledAt?: string | null, id: string, reason?: string | null, updatedAt: string };

export type RestrictionsData_ChargeBackRestriction = { __typename: 'ChargeBackRestriction', createdAt: string, canceledAt?: string | null, id: string, reason?: string | null, updatedAt: string };

export type RestrictionsData_ReceiveGiftRestriction = { __typename: 'ReceiveGiftRestriction', createdAt: string, bypassRestrictionIfApproved: boolean, canceledAt?: string | null, id: string, reason: string, updatedAt: string };

export type RestrictionsData_WithdrawalRestriction = { __typename: 'WithdrawalRestriction', createdAt: string, bypassRestrictionIfApproved: boolean, canceledAt?: string | null, id: string, reason: string, updatedAt: string };

export type RestrictionsData = RestrictionsData_BadBankRestriction | RestrictionsData_ChargeBackRestriction | RestrictionsData_ReceiveGiftRestriction | RestrictionsData_WithdrawalRestriction;

export const RestrictionsData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RestrictionsData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RestrictionInterface"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"RestrictionsBasicData"}}]}}]} as unknown as DocumentNode;