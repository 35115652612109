import { FormikErrors } from 'formik';

export interface ResetTwoFactor {
  confirmation: string;
}

export interface ResetTwoFactorErrors {
  confirmation: string;
}
export const validate = (
  values: ResetTwoFactor
): FormikErrors<ResetTwoFactorErrors> => {
  const errors: FormikErrors<ResetTwoFactorErrors> = {};

  if (values.confirmation.toLowerCase() !== 'отключить') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
