import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { SumData } from '../../../../../../queries/generated/SumData';
export type BonusBasicData_CashbackBonus = { __typename: 'CashbackBonus', createdAt: string, id: string, status: SchemaTypes.BonusStatus, config: { __typename: 'BonusConfig', winConditions?: { __typename: 'BonusWinConditions', wager?: number | null } | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusBasicData_FirstDepositBonus = { __typename: 'FirstDepositBonus', createdAt: string, id: string, status: SchemaTypes.BonusStatus, config: { __typename: 'BonusConfig', winConditions?: { __typename: 'BonusWinConditions', wager?: number | null } | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusBasicData_FreespinBonus = { __typename: 'FreespinBonus', createdAt: string, id: string, status: SchemaTypes.BonusStatus, config: { __typename: 'BonusConfig', winConditions?: { __typename: 'BonusWinConditions', wager?: number | null } | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusBasicData_GiftBonus = { __typename: 'GiftBonus', createdAt: string, id: string, status: SchemaTypes.BonusStatus, config: { __typename: 'BonusConfig', winConditions?: { __typename: 'BonusWinConditions', wager?: number | null } | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusBasicData_PersonalBonus = { __typename: 'PersonalBonus', createdAt: string, id: string, status: SchemaTypes.BonusStatus, config: { __typename: 'BonusConfig', winConditions?: { __typename: 'BonusWinConditions', wager?: number | null } | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusBasicData_PromoCodeBonus = { __typename: 'PromoCodeBonus', createdAt: string, id: string, status: SchemaTypes.BonusStatus, config: { __typename: 'BonusConfig', winConditions?: { __typename: 'BonusWinConditions', wager?: number | null } | null }, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } };

export type BonusBasicData = BonusBasicData_CashbackBonus | BonusBasicData_FirstDepositBonus | BonusBasicData_FreespinBonus | BonusBasicData_GiftBonus | BonusBasicData_PersonalBonus | BonusBasicData_PromoCodeBonus;

export const BonusBasicData = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BonusBasicData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BonusInterface"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"config"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"winConditions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wager"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"sum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}}]}}]} as unknown as DocumentNode;