import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { AccountData } from '../fragments/generated/AccountData';
import { SumData } from '../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetAccountsVariables = SchemaTypes.Exact<{
  playerId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetAccounts = { __typename: 'Query', player: { __typename: 'Player', id: string, accounts: Array<{ __typename: 'Account', id: string, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, bonusBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null }> } };


export const GetAccountsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAccounts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"playerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accounts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Account"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AccountData"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...AccountData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetAccounts__
 *
 * To run a query within a React component, call `useGetAccounts` and pass it any options that fit your needs.
 * When your component renders, `useGetAccounts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccounts({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetAccounts(baseOptions: ApolloReactHooks.QueryHookOptions<GetAccounts, GetAccountsVariables> & ({ variables: GetAccountsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAccounts, GetAccountsVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccounts, GetAccountsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAccounts, GetAccountsVariables>(GetAccountsDocument, options);
        }
export function useGetAccountsSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetAccounts, GetAccountsVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAccounts, GetAccountsVariables>(GetAccountsDocument, options);
        }
export type GetAccountsHookResult = ReturnType<typeof useGetAccounts>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsSuspenseQueryHookResult = ReturnType<typeof useGetAccountsSuspenseQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccounts, GetAccountsVariables>;