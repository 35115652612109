import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { TwoFactorValues } from '../../validation';
import { AUTH_CODE_LENGTH } from '../../const';

const AutoSubmitAuthCode = (): null => {
  const { values, submitForm } = useFormikContext<TwoFactorValues>();

  useEffect(() => {
    if (values.authCode.length === AUTH_CODE_LENGTH) {
      submitForm();
    }
  }, [values, submitForm]);

  return null;
};

export default AutoSubmitAuthCode;
