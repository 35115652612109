import { SET_LOADER_VISIBILITY } from '../actionTypes';

interface Payload {
  isLoaderVisible: boolean;
}

export interface SetLoaderVisibility {
  type: typeof SET_LOADER_VISIBILITY;
  payload: Payload;
}

export const setLoaderVisibility = (payload: Payload): SetLoaderVisibility => ({
  type: SET_LOADER_VISIBILITY,
  payload,
});
