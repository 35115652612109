import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { PartnerListData } from '../../../queries/generated/PartnerListData';
import { getTariffTypes } from '../helpers';

const columnHelper = createColumnHelper<PartnerListData>();

const columnsSource: ColumnsSource<PartnerListData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Name, {
    id: ColumnId.Name,
    header: mapColumnIdToName[ColumnId.Name],
  }),
  columnHelper.accessor(ColumnId.Email, {
    id: ColumnId.Email,
    header: mapColumnIdToName[ColumnId.Email],
  }),
  columnHelper.accessor(ColumnId.TariffType, {
    id: ColumnId.TariffType,
    header: mapColumnIdToName[ColumnId.TariffType],
    cell: ({ row }) => getTariffTypes(row.original.rewardTariffTypesInUse),
  }),
  columnHelper.accessor(ColumnId.Note, {
    id: ColumnId.Note,
    header: mapColumnIdToName[ColumnId.Note],
  }),
];

export const useGetPartnersColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
