import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaItemPromoCodeData } from '../../../../../../../../queries/generated/MediaItemPromoCodeData';
import { MediaItemPromoCodeBasicData } from '../../../../../../../../queries/generated/MediaItemPromoCodeBasicData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyAffiliatePromoCodeItemVariables = SchemaTypes.Exact<{
  affiliateId: SchemaTypes.Scalars['ID']['input'];
  companyId: SchemaTypes.Scalars['ID']['input'];
  promoCodeId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyAffiliatePromoCodeItem = { __typename: 'Query', company: { __typename: 'Company', id: string, affiliate: { __typename: 'CompanyAffiliate', id: string, promoCodeMediaItem: { __typename: 'PromoCodeMediaItem', createdAt: string, id: string, updatedAt: string, usageType: SchemaTypes.PromoCodeUsageType, promoCode: { __typename: 'PromoCode', id: string, token: string, bonusAction?: { __typename: 'PromoCodeBonusAction', cashout?: number | null, wager?: number | null, amount: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, minAccountBalanceAmount?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } | null, freespinAction?: { __typename: 'PromoCodeFreespinAction', bonusWager?: number | null, count: number, currency: CommonType.Currency, game: { __typename: 'Game', id: string, name: string }, minAccountBalanceAmount?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null } | null, activation?: { __typename: 'PromoCodeActivation', activated: number, limit: number } | null }, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, referralLink?: { __typename: 'ReferralLinkMediaItem', code: any, id: string, referralLink: string } | null } } } };


export const GetCompanyAffiliatePromoCodeItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyAffiliatePromoCodeItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"affiliateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"promoCodeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"affiliate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"affiliateId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"promoCodeMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"promoCodeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaItemPromoCodeData"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},...MediaItemPromoCodeData.definitions,...MediaItemPromoCodeBasicData.definitions,...SumData.definitions]} as unknown as DocumentNode;

/**
 * __useGetCompanyAffiliatePromoCodeItem__
 *
 * To run a query within a React component, call `useGetCompanyAffiliatePromoCodeItem` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAffiliatePromoCodeItem` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAffiliatePromoCodeItem({
 *   variables: {
 *      affiliateId: // value for 'affiliateId'
 *      companyId: // value for 'companyId'
 *      promoCodeId: // value for 'promoCodeId'
 *   },
 * });
 */
export function useGetCompanyAffiliatePromoCodeItem(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables> & ({ variables: GetCompanyAffiliatePromoCodeItemVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables>(GetCompanyAffiliatePromoCodeItemDocument, options);
      }
export function useGetCompanyAffiliatePromoCodeItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables>(GetCompanyAffiliatePromoCodeItemDocument, options);
        }
export function useGetCompanyAffiliatePromoCodeItemSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables>(GetCompanyAffiliatePromoCodeItemDocument, options);
        }
export type GetCompanyAffiliatePromoCodeItemHookResult = ReturnType<typeof useGetCompanyAffiliatePromoCodeItem>;
export type GetCompanyAffiliatePromoCodeItemLazyQueryHookResult = ReturnType<typeof useGetCompanyAffiliatePromoCodeItemLazyQuery>;
export type GetCompanyAffiliatePromoCodeItemSuspenseQueryHookResult = ReturnType<typeof useGetCompanyAffiliatePromoCodeItemSuspenseQuery>;
export type GetCompanyAffiliatePromoCodeItemQueryResult = Apollo.QueryResult<GetCompanyAffiliatePromoCodeItem, GetCompanyAffiliatePromoCodeItemVariables>;