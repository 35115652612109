import * as SchemaTypes from '../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { AdminData } from '../../../../../../../queries/generated/AdminData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateAdminVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateAdminInput;
}>;


export type UpdateAdmin = { __typename: 'Mutation', updateAdmin: { __typename: 'UpdateAdminPayload', admin: { __typename: 'Admin', email: string, firstName: string, id: string, lastName: string, roles: Array<CommonType.AdminRole> } } };


export const UpdateAdminDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateAdmin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateAdminInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAdmin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Admin"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdminData"}}]}}]}}]}}]}},...AdminData.definitions]} as unknown as DocumentNode;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdmin, UpdateAdminVariables>;

/**
 * __useUpdateAdmin__
 *
 * To run a mutation, you first call `useUpdateAdmin` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdmin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdmin, { data, loading, error }] = useUpdateAdmin({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdmin(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdmin, UpdateAdminVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAdmin, UpdateAdminVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminHookResult = ReturnType<typeof useUpdateAdmin>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdmin>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdmin, UpdateAdminVariables>;