import { FormikHelpers } from 'formik';
import { DROPDOWN_SEARCH } from 'ui/Dropdown/components/DropdownList/const';
import { logError } from 'src/utils/logError';
import { FieldName } from '../const';
import { CreateConfirmationSettingValues } from '../types';

interface HandleChangePartnerDropdownParams {
  setFieldValue: FormikHelpers<CreateConfirmationSettingValues>['setFieldValue'];
  partnerId: string | Array<string>;
  checkHasPartnerConfirmationSettings: (partnerId: string) => void;
}

export const handleChangePartnerDropdown = ({
  setFieldValue,
  partnerId,
  checkHasPartnerConfirmationSettings,
}: HandleChangePartnerDropdownParams) => {
  if (typeof partnerId === 'string') {
    checkHasPartnerConfirmationSettings(partnerId);
    setFieldValue(FieldName.partnerReferralCode, null);

    // This is necessary to clear the search field in the dropdown list
    setFieldValue(`${FieldName.partnerReferralCode}_${DROPDOWN_SEARCH}`, '');
  } else {
    logError(`partnerId should be a string but it's an ${typeof partnerId}`);
  }
};
