import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { PostbackDisabledNotificationsListModal } from '../components/PostbackDisabledNotificationsListModal';

export const useOpenPostbackDisabledNotificationsListModal = () => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Отключённые постбэки',
        content: <PostbackDisabledNotificationsListModal />,
      },
    });
  }, [dispatch]);

  return openModal;
};
