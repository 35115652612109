import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { UpdateMediaCampaignInput } from 'generatedGraphql';
import Error from 'ui/Error';
import { validate } from './validation';
import { useEditMediaCampaignModal } from './hooks/useEditMediaCampaignModal';

interface Props {
  mediaCampaignId: string;
  name: string;
  url: string;
}

export const EditMediaCampaignModal: FC<Props> = ({
  mediaCampaignId,
  name,
  url,
}) => {
  const initialValues: UpdateMediaCampaignInput = {
    name,
    id: mediaCampaignId,
    url,
  };

  const { editMediaCampaign, loading, error } = useEditMediaCampaignModal({
    mediaCampaignId,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={editMediaCampaign}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <Input
              placeholder="Введите название кампании"
              name="name"
              label="Название"
            />
          </FormRowsWrapper>
          <FormRowsWrapper>
            <Input placeholder="Введите URL" name="url" label="URL" />
          </FormRowsWrapper>
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
            disabled={!isValid || !dirty || loading}
          >
            Сохранить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
