import { FC } from 'react';
import styles from './CardDataList.module.scss';

export interface DataList {
  name: string;
  value: string;
}

export enum CardStatusColor {
  GREY = 'grey',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}

interface Props {
  dataList?: Array<DataList>;
}

export const CardDataList: FC<Props> = ({ dataList }) => (
  <div>
    {dataList?.map(({ name, value }) => (
      <div className={styles.dataListRow} key={name}>
        <span className={styles.dataListName}>{name}</span>
        <span>{value}</span>
      </div>
    ))}
  </div>
);
