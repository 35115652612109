import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { RewardStatus, Sum } from 'generatedGraphql';
import { Tooltip } from 'ui/Tooltip';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { useAuth } from 'commonComponents/Auth';
import { useOpenAcceptRewardModal } from './hooks/useOpenAcceptRewardModal';
import styles from './UpdateRewardButtons.module.scss';
import { useDeclineRewardModal } from './hooks/useDeclineRewardModal';

interface Props {
  status: RewardStatus;
  sum: Sum;
  rewardId: string;
}

export const UpdateRewardButtons: FC<Props> = ({ sum, rewardId, status }) => {
  const auth = useAuth();

  const { openModal } = useOpenAcceptRewardModal();
  const { handleDeclineReward } = useDeclineRewardModal(rewardId);

  const hasSomeGranted = auth.privileges.isSomeGranted({
    privileges: [UserPrivilege.AcceptReward],
  });

  if (status !== RewardStatus.Pending || !hasSomeGranted) {
    return null;
  }

  const amountIsNull = sum.amount === 0;

  return (
    <div className={styles.container}>
      <Privilege privileges={[UserPrivilege.AcceptReward]}>
        <Tooltip
          tooltipContent={
            amountIsNull
              ? 'Невозможно подтвердить нулевое вознаграждение'
              : undefined
          }
          placement="right"
        >
          <Button
            data-testid="acceptButton"
            size={ButtonSize.Large}
            theme={ButtonTheme.Success}
            disabled={amountIsNull}
            onClick={() => {
              openModal({
                rewardId,
              });
            }}
          >
            Подтвердить
          </Button>
        </Tooltip>
      </Privilege>
      <Privilege privileges={[UserPrivilege.DeclineReward]}>
        <Button
          data-testid="declineRewardButton"
          size={ButtonSize.Large}
          theme={ButtonTheme.Important}
          onClick={handleDeclineReward}
        >
          Отклонить
        </Button>
      </Privilege>
    </div>
  );
};
