import { FC, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Button, { ButtonTheme, LinkButtonTheme } from 'ui/Button';
import { ModalActions, ModalContext } from 'ui/Modal';
import Privilege from 'src/components/Privilege';
import Error from 'ui/Error';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { toast } from 'react-toastify';
import { AdminData } from 'src/queries/generated/AdminData';
import { UserPrivilege } from '../../../../../components/Auth';
import styles from './AdminEditForm.module.scss';
import { EditAdminValues, validate } from './validation';
import AdminForm from '../AdminForm';
import {
  PASSWORD_CHANGE_ACTION,
  PASSWORD_SET_URI,
} from '../../../../../components/Auth/const';
import { useUpdateAdmin } from './queries/generated/UpdateAdmin';
import { useResetAdminPassword } from './queries/generated/ResetAdminPassword';
import { useDeleteAdmin } from './queries/generated/DeleteAdmin';
import { GetAdminDocument } from '../queries/generated/GetAdmin';

interface Props {
  admin: AdminData;
}

const AdminEditForm: FC<Props> = ({
  admin: { id, firstName, lastName, email, roles },
}) => {
  const initialValues = {
    firstName,
    lastName,
    email,
    roles,
  };

  const [updateAdmin, { data: updateResult, loading, error: updateError }] =
    useUpdateAdmin({
      refetchQueries: [{ query: GetAdminDocument, variables: { id } }],
    });

  const [resetAdminPassword, { loading: resetLoading, error: resetError }] =
    useResetAdminPassword();

  const [deleteAdmin, { error: deleteError }] = useDeleteAdmin({
    update: (cache) => {
      cache.modify({
        id: cache.identify({
          id,
          __typename: 'Admin',
        }),
        fields: (cachedId, { DELETE }) => DELETE,
      });
    },
    refetchQueries: [{ query: GetAdminDocument, variables: { id } }],
  });

  const { dispatch } = useContext(ModalContext);

  useEffect(() => {
    if (updateResult) {
      dispatch({ type: ModalActions.Close });

      toast.success('Администратор изменён');
    }
  }, [updateResult, dispatch]);

  const onSubmit = async (values: EditAdminValues) => {
    await updateAdmin({
      variables: {
        input: {
          id,
          firstName: values.firstName,
          lastName: values.lastName,
          roles: values.roles,
        },
      },
    });
  };

  const onDelete = () => {
    deleteAdmin({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteAdmin: { id, __typename: 'DeleteAdminPayload' },
      },
    });

    undoActionToast('Администратор удалён');
    dispatch({
      type: ModalActions.Close,
    });
  };

  const onPasswordReset = () => {
    resetAdminPassword({
      variables: {
        input: {
          id,
          passwordSetUri: `${PASSWORD_SET_URI}/${PASSWORD_CHANGE_ACTION}`,
        },
      },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        resetAdminPassword: {
          admin: { id, __typename: 'Admin' },
          __typename: 'ResetAdminPasswordPayload',
        },
      },
    });

    undoActionToast('Пароль сброшен');
    dispatch({
      type: ModalActions.Close,
    });
  };

  const apolloError = updateError || deleteError || resetError || '';

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      <Form className={styles.form}>
        {apolloError && <Error error={apolloError} />}

        <AdminForm isEdit />

        <Button theme={ButtonTheme.Success} type="submit" isLoading={loading}>
          Сохранить
        </Button>

        <div className={styles.buttonLinksWrapper}>
          <Button
            linkStyled
            className={styles.buttonLink}
            onClick={onPasswordReset}
            disabled={resetLoading}
          >
            Сбросить пароль
          </Button>

          <Privilege privileges={[UserPrivilege.DeleteAdmin]}>
            <Button
              linkStyled
              theme={LinkButtonTheme.Important}
              className={styles.buttonLink}
              onClick={onDelete}
            >
              Удалить администратора
            </Button>
          </Privilege>
        </div>
      </Form>
    </Formik>
  );
};

export default AdminEditForm;
