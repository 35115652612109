import { FormikErrors } from 'formik';
import { DeclineCompanyApplicationInput } from 'generatedGraphql';

export const validate = (
  values: DeclineCompanyApplicationInput
): FormikErrors<DeclineCompanyApplicationInput> => {
  const errors: FormikErrors<DeclineCompanyApplicationInput> = {};

  if (!values.reason) {
    errors.reason = 'Укажите причину отклонения';
  }

  if (values.reason.length > 100) {
    errors.reason = 'Максимальное количество символов 100';
  }

  return errors;
};
