import { FC } from 'react';
import Input from 'ui/Input';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useNewAffiliateFormHandlers } from './hooks/useNewAffiliateFormHandlers';
import { AddAffiliateField } from '../../const';
import styles from './NewAffiliateForm.module.scss';

interface Props {
  name: string;
  note?: string;
  affiliateIndex: number;
  count: number;
}

export const NewAffiliateForm: FC<Props> = ({
  name,
  note,
  affiliateIndex,
  count,
}) => {
  const {
    affiliateName,
    affiliateNote,
    handleInputChange,
    handleInputBlur,
    handleTextareaChange,
    handleTextareaBlur,
    handleDeleteAffiliateClick,
  } = useNewAffiliateFormHandlers({ name, note, affiliateIndex });

  return (
    <div data-testid="new-affiliate-form">
      <div className={styles.header}>
        <h3 className={styles.title}>Партнер</h3>
        {count > 1 && (
          <ActionButton
            actionType={ButtonType.Delete}
            onClick={handleDeleteAffiliateClick}
            dataTestId="delete-affiliate-form"
          />
        )}
      </div>
      <Input
        label="Имя пользователя"
        name={`${AddAffiliateField.Name}-${affiliateIndex}`}
        value={affiliateName}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        wrapperClassName={styles.fieldName}
        placeholder="Partner#"
        maxLength={50}
      />
      <Input.Textarea
        label="Заметка"
        name={`${AddAffiliateField.Note}-${affiliateIndex}`}
        value={affiliateNote}
        onChange={handleTextareaChange}
        onBlur={handleTextareaBlur}
        placeholder="Добавьте заметку"
        maxLength={1000}
      />
    </div>
  );
};
