import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetExportCompanyAffiliateCpaReportLazyQuery } from '../queries/generated/GetExportCompanyAffiliateCpaReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

interface UseGetExportCompanyAffiliateCpaReportParams {
  companyId: string;
  affiliateId: string;
  isAllTimeCpa: boolean;
}

export const useGetExportCompanyAffiliateCpaReport = ({
  companyId,
  affiliateId,
  isAllTimeCpa,
}: UseGetExportCompanyAffiliateCpaReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportCompanyAffiliateCpaReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.company.affiliate.exportCpaReport);
      },
      onError: handleErrorExportReport,
    });

  const { currency } = useGetCurrency();

  const exportReportWithVariables = useCallback(() => {
    exportReport({
      variables: {
        id: companyId,
        affiliateId,
        currency,
        period,
        showAllTimeCpa: isAllTimeCpa,
      },
    });
  }, [currency, affiliateId, companyId, exportReport, isAllTimeCpa, period]);

  return {
    exportReport: exportReportWithVariables,
    exportReportLoading:
      getExportReportLinkLoading || downloadReportFileLoading,
    exportPrivilege: UserPrivilege.ExportCompanyAffiliateCpaReport,
  };
};
