import styles from './Switch.module.scss';

export enum SwitchSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export const mapSwitchSizeToClass: { [key in SwitchSize]: string } = {
  [SwitchSize.Small]: styles.small,
  [SwitchSize.Medium]: styles.medium,
  [SwitchSize.Large]: styles.large,
};
