import { FC } from 'react';
import UpdateClientIdentities from 'commonComponents/UpdateClientIdentities';
import { useUpdatePartnerIdentities } from './hooks/useUpdatePartnerIdentities';

interface Props {
  id: string;
}

export const UpdatePartnerIdentities: FC<Props> = ({ id }) => {
  const { loading, onUpdatePartnerIdentities, error, email } =
    useUpdatePartnerIdentities({
      id,
    });

  return (
    <UpdateClientIdentities
      onUpdateClientIdentities={onUpdatePartnerIdentities}
      email={email}
      isLoading={loading}
      error={error}
    />
  );
};
