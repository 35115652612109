import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import AffiliatesTable from './components/AffiliatesTable';
import { useGetAffiliatesColumns } from './components/AffiliatesTable/hooks/useGetAffiliatesColumns';

interface Props {
  id: string;
}

export const AffiliatesBlock: FC<Props> = withBlockProvider(({ id }) => {
  const { columns } = useGetAffiliatesColumns();

  return (
    <AffiliatesTable
      companyId={id}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.COMPANY_AFFILIATES, { id })}
      isFullScreenTable={false}
    />
  );
});
