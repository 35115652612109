import { DateFormat, formatDate } from 'src/utils/formatDate';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { PartnerMediaCampaignData } from '../queries/generated/PartnerMediaCampaignData';

const columnHelper = createColumnHelper<PartnerMediaCampaignData>();

const columnsSource: ColumnsSource<PartnerMediaCampaignData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Title, {
    id: ColumnId.Title,
    header: mapColumnIdToName[ColumnId.Title],
  }),
  columnHelper.accessor(ColumnId.Url, {
    id: ColumnId.Url,
    header: mapColumnIdToName[ColumnId.Url],
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetPartnerMediaCampaignsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
