import { RewardTariffType } from 'commonTypes';
import { rewardTariffMap } from '../../../../const';

export const tariffTypeOptions = [
  {
    label: rewardTariffMap[RewardTariffType.RevShare],
    id: RewardTariffType.RevShare,
  },
  {
    label: rewardTariffMap[RewardTariffType.Cpa],
    id: RewardTariffType.Cpa,
  },
];
