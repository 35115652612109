import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { playerPaymentNameMap, withdrawalStatusMap } from 'src/const';
import { Sum } from 'generatedGraphql';
import { getWithdrawalStatusIconConfig } from 'src/helpers';
import { BoldSumCellByCompare } from 'ui/Table/components/BoldSumCellByCompare';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { WithdrawalData } from '../components/WithdrawalsTable/queries/generated/WithdrawalData';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';

interface GetSumData {
  sum: Sum;
  paidSum?: Sum | null;
}

const getSumData = ({
  sum,
  paidSum,
}: GetSumData): {
  title: string;
  iconConfig: undefined;
  subTitle?: string;
} => {
  const sumAmount = sum?.amount;
  const sumCurrency = sum?.currency;

  const paidSumAmount = paidSum?.amount;
  const paidSumCurrency = paidSum?.currency;

  const title = formatSumWithCurrency(sumAmount, sumCurrency);

  let subTitle;

  if (paidSumAmount && paidSumCurrency && sumAmount !== paidSumAmount) {
    subTitle = formatSumWithCurrency(paidSumAmount, paidSumCurrency);
  }

  return { title, iconConfig: undefined, subTitle };
};

const columnHelper = createColumnHelper<WithdrawalData>();

const getColumnsSource = (
  isFullScreenTable: boolean
): ColumnsSource<WithdrawalData> => [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status } = row.original;

      return MultilineCellWithIcon({
        title: withdrawalStatusMap[status],
        iconConfig: getWithdrawalStatusIconConfig(status),
      });
    },
  }),
  columnHelper.accessor(ColumnId.PaymentMethodName, {
    id: ColumnId.PaymentMethodName,
    header: mapColumnIdToName[ColumnId.PaymentMethodName],
    cell: ({ row }) => {
      const { name } = row.original.paymentMethod;

      return playerPaymentNameMap[name] || name;
    },
  }),
  columnHelper.accessor(ColumnId.Account, {
    id: ColumnId.Account,
    header: mapColumnIdToName[ColumnId.Account],
    cell: ({ row }) => {
      const { account, maskedAccount } = row.original.paymentMethod;

      return MayBeNullCell(account || maskedAccount);
    },
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { sum, paidSum } = row.original;

      if (paidSum && sum.amount !== paidSum.amount && !isFullScreenTable) {
        return MultilineCellWithIcon({
          ...getSumData({ sum, paidSum }),
          isAlignRight: true,
        });
      }

      return formatSumWithCurrency(sum.amount, sum.currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.PaidSum, {
    id: ColumnId.PaidSum,
    header: mapColumnIdToName[ColumnId.PaidSum],
    cell: ({ row }) => {
      const { sum, paidSum } = row.original;

      return <BoldSumCellByCompare comparedSum={sum} renderedSum={paidSum} />;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetWithdrawalsColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    getColumnsSource(isFullScreenTable)
  );

  return {
    columns,
  };
};
