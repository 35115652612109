import { FC } from 'react';
import { useParams } from 'react-router';
import { ClientPageLayout } from 'commonComponents/ClientPageLayout/ClientPageLayout';
import { UserPrivilege } from 'commonComponents/Auth';
import Privilege from 'commonComponents/Privilege';
import { CompanyDataBlock } from './components/CompanyDataBlock';
import { CompanyPageContainer } from './components/CompanyPageContainer';
import { AffiliatesBlock } from './components/AffiliatesBlock';
import { CompanyMediaItemsBlock } from './components/CompanyMediaItemsBlock';
import { CompanyMediaCampaignsBlock } from './components/CompanyMediaCampaignsBlock';
import { OutgoingMoneyTransfersBlock } from './components/OutgoingMoneyTransfersBlock';

export interface Props {
  companyId: string;
}

const Component: FC<Props> = ({ companyId }) => (
  <ClientPageLayout>
    <div>
      <CompanyDataBlock id={companyId} />
      <Privilege privileges={[UserPrivilege.ViewCompanyMediaCampaign]}>
        <CompanyMediaCampaignsBlock id={companyId} />
      </Privilege>
      <Privilege privileges={[UserPrivilege.ViewCompanyOutgoingMoneyTransfer]}>
        <OutgoingMoneyTransfersBlock id={companyId} />
      </Privilege>
    </div>
    <div>
      <Privilege privileges={[UserPrivilege.ViewCompanyAffiliate]}>
        <AffiliatesBlock id={companyId} />
      </Privilege>
      <Privilege privileges={[UserPrivilege.ViewCompanyReferralLinkMediaItem]}>
        <CompanyMediaItemsBlock id={companyId} isFullScreenTable={false} />
      </Privilege>
    </div>
  </ClientPageLayout>
);

export const Company: FC = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer shouldProvideBlock={false} companyId={id}>
      <Component companyId={id} />
    </CompanyPageContainer>
  );
};
