import {
  memo,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  HeaderConfig,
  useStickyHeader,
} from 'ui/Block/BlockTable/components/StickyHeader/useStickyHeader';
import { throttle } from 'lodash-es';
import classnames from 'classnames';
import styles from './styles/StickyHeader.module.scss';

interface Props<T> {
  headerTableRef: RefObject<HTMLTableRowElement>;
  tableContainerRef: RefObject<HTMLDivElement>;
  data: ReadonlyArray<T>;
  pinnedColumnIndexes: Array<number>;
}

const StickyHeader = <T extends object>({
  headerTableRef,
  data,
  tableContainerRef,
  pinnedColumnIndexes,
}: Props<T>) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerConfig, setHeaderConfig] = useState<Array<HeaderConfig>>([]);
  const [distanceToTop, setDistanceToTop] = useState(0);
  const tableContainer = tableContainerRef.current;
  const header = headerRef.current;
  const headerTable = headerTableRef.current;

  const { getWidth, changeHeaderStyles, getBaseLeftDistance } =
    useStickyHeader();

  useLayoutEffect(() => {
    const changeHeaderStylesThrottle = throttle(() => {
      changeHeaderStyles({ header, headerTable });
    }, 100);

    const playerHeaderHeight = document
      .querySelector('#header')
      ?.getBoundingClientRect().height;

    if (distanceToTop !== playerHeaderHeight) {
      setDistanceToTop(playerHeaderHeight || 0);
    }

    const handleScroll = () =>
      requestAnimationFrame(changeHeaderStylesThrottle);

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [header, headerTable, distanceToTop, changeHeaderStyles]);

  useEffect(() => {
    const getConfig = () => {
      setHeaderConfig([]);

      headerTable?.querySelectorAll('th').forEach((item) => {
        setHeaderConfig((oldArr) => [
          ...oldArr,
          {
            title: item.textContent || '',
            width: item.offsetWidth,
            textAlign: getComputedStyle(item).textAlign,
          },
        ]);
      });
    };

    getConfig();

    window.addEventListener('resize', throttle(getConfig, 30));

    return () => {
      window.removeEventListener('resize', throttle(getConfig, 30));
    };
  }, [headerTable, data]);

  useLayoutEffect(() => {
    const syncHeaderWithBody = () => {
      if (header && tableContainer) {
        header.style.left = `${
          getBaseLeftDistance(tableContainer) - tableContainer.scrollLeft
        }px`;
      }
    };

    syncHeaderWithBody();

    tableContainer?.addEventListener(
      'scroll',
      throttle(syncHeaderWithBody, 10)
    );

    return () => {
      tableContainer?.removeEventListener(
        'scroll',
        throttle(syncHeaderWithBody, 10)
      );
    };
  }, [getBaseLeftDistance, header, tableContainer]);

  return (
    <div
      style={{
        width: getWidth(headerConfig),
        top: `${distanceToTop}px`,
      }}
      className={styles.stickyHeader}
      ref={headerRef}
    >
      {headerConfig.map(({ title, width, textAlign }, index) => {
        const isPinned = pinnedColumnIndexes.includes(index);

        return (
          <div
            key={title}
            style={{ width: `${width}px` }}
            className={classnames(
              styles.th,
              textAlign && styles[textAlign],
              isPinned && styles.stickyColumn
            )}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};

export default memo(StickyHeader);
