export enum FieldName {
  Partner = 'partner',
  MediaCampaignId = 'mediaCampaignId',
  MediaItemName = 'mediaItemName',
  Code = 'code',
  PaymentModel = 'paymentModel',
  CPATariffId = 'CPATariffId',
  RevShareTariffId = 'revShareTariffId',
  FallbackTariffId = 'fallbackTariffId',
}

export const mapFieldNameToTitle: { [key in FieldName]: string } = {
  [FieldName.Partner]: 'Партнер',
  [FieldName.MediaCampaignId]: 'Медиа кампания',
  [FieldName.MediaItemName]: 'Название медиа элемента',
  [FieldName.Code]: 'Реферальный код',
  [FieldName.PaymentModel]: 'Модель оплаты',
  [FieldName.CPATariffId]: 'Партнёрский тариф',
  [FieldName.FallbackTariffId]: 'Резервный тариф',
  [FieldName.RevShareTariffId]: 'Партнёрский тариф',
};
