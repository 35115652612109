export enum ColumnId {
  Id = 'id',
  Name = 'name',
  Email = 'email',
  ContactForCommunication = 'contactForCommunication',
  Note = 'note.text',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Name]: 'Название',
  [ColumnId.Email]: 'Почта',
  [ColumnId.ContactForCommunication]: 'Контакт\u00A0для\u00A0связи',
  [ColumnId.Note]: 'Заметка',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Name,
  ColumnId.Email,
  ColumnId.ContactForCommunication,
  ColumnId.Note,
];
