import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useGetCpaRewardTariffsLazyQuery } from '../../../queries/generated/GetCpaRewardTariffs';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../../../utils/getCountByWindowHeight';

const cellHeight = Heights.defaultCellHeight;

export const useCpaRewardTariff = () => {
  const [loadCpaRewardTariffs, { data, loading, error, fetchMore, refetch }] =
    useGetCpaRewardTariffsLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
      },
    });

  useBlockComponentState({
    loadData: loadCpaRewardTariffs,
    loading,
    error,
  });

  const cpaRewardTariffs = useMemo(
    () => data?.cpaRewardTariffs?.edges.map(({ node }) => node),
    [data?.cpaRewardTariffs?.edges]
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.cpaRewardTariffs?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.cpaRewardTariffs?.pageInfo.endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.cpaRewardTariffs?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchCpaRewardTariffs: refetch,
    cpaRewardTariffs,
  };
};
