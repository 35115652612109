import { FC, useCallback, useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ActionButton, ButtonSize, ButtonType } from 'src/ui/ActionButton';
import { CardIcon } from 'ui/Card/CardIcon';
import classnames from 'classnames';
import { SidebarLoader } from 'ui/SidebarLoader';
import Error from 'ui/Error';
import styles from './styles/Modal.module.scss';
import { bodyScrollLocker } from '../../utils/bodyScrollLocker';

const Modal: FC = () => {
  const { state, dispatch } = useContext(ModalContext);
  const closeModal = useCallback(() => {
    if (state.isCloseDisabled) {
      return;
    }

    dispatch({ type: ModalActions.Close });
  }, [dispatch, state.isCloseDisabled]);

  const isRenderGoBack =
    state && state?.step && state.hasGoBackButtonForSteps && state.step > 1;

  const goBack = useCallback(() => {
    if (state.step) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: state.previousStep,
        },
      });
    }
  }, [dispatch, state.previousStep, state.step]);

  useEffect(() => {
    window.addEventListener('popstate', closeModal);

    return () => {
      window.removeEventListener('popstate', closeModal);
    };
  }, [closeModal]);

  return (
    <ReactModal
      isOpen={state.isOpened}
      onRequestClose={closeModal}
      closeTimeoutMS={350}
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      className={{
        base: styles.modalContent,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      onAfterOpen={() => {
        bodyScrollLocker.lock(true);
      }}
      onAfterClose={() => {
        bodyScrollLocker.unLock();
      }}
      appElement={(document.querySelector('#root') as HTMLElement) || undefined}
    >
      <div className={styles.modalHeader}>
        <div
          className={classnames(
            styles.container,
            isRenderGoBack && styles.clickable
          )}
        >
          {!isRenderGoBack && state.iconConfig && (
            <div className={styles.iconContainer}>
              <CardIcon config={state.iconConfig} />
            </div>
          )}
          {isRenderGoBack && (
            <ActionButton
              onClick={goBack}
              actionType={ButtonType.GoBack}
              className={styles.actionBtn}
              size={ButtonSize.Big}
            />
          )}
          <div>
            {state.title}
            {state.subTitle && (
              <div className={styles.subTitle}>{state.subTitle}</div>
            )}
          </div>
        </div>
        <ActionButton
          disabled={state.isCloseDisabled}
          dataTestId="closeModalButton"
          actionType={ButtonType.Close}
          size={ButtonSize.Big}
          onClick={closeModal}
        />
      </div>
      <div className={styles.content}>
        <SidebarLoader loading={state.isLoading}>
          {state.error ? <Error error={state.error} /> : state.content}
        </SidebarLoader>
      </div>
    </ReactModal>
  );
};

export default Modal;
