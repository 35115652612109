import { getDisplayName } from '../../../../utils/getDisplayName';

export const getAddress = (country?: string | null, city?: string | null) => {
  if (!country) {
    return `${(city && city) || ''}`;
  }

  const countryName = getDisplayName({ value: country, type: 'region' });

  return `${countryName}${(city && `, ${city}`) || ''}`;
};
