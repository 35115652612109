import { toast } from 'react-toastify';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { UpdateConfirmationSettingValues } from '../types';
import { useUpdateQualifiedReferralConfirmationSetting as useUpdateQualifiedReferralConfirmationSettingMutation } from '../queries/generated/UpdateQualifiedReferralConfirmationSetting';
import { UpdateConfirmationSettingSteps } from '../../../const';

export const useUpdateQualifiedReferralConfirmationSetting = (
  confirmationSettingId: string
) => {
  const { updateModalStep } =
    useUpdateModalStep<UpdateConfirmationSettingSteps>();

  const handleCompleted = () => {
    toast.success('Настройка подтверждения обновлена');
    updateModalStep(UpdateConfirmationSettingSteps.BaseStep);
  };

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Настройка подтверждения не обновлена',
      text: error.message,
      toastId: 'update-qualified-referral-confirmation-setting-error',
    });
  };

  const [
    updateQualifiedReferralConfirmationSettingMutation,
    { loading, error },
  ] = useUpdateQualifiedReferralConfirmationSettingMutation({
    onCompleted: handleCompleted,
    onError: handleError,
  });

  const updateQualifiedReferralConfirmationSetting = ({
    comment,
    settingType,
  }: UpdateConfirmationSettingValues) => {
    updateQualifiedReferralConfirmationSettingMutation({
      variables: {
        input: {
          id: confirmationSettingId,
          comment,
          type: settingType,
        },
      },
    });
  };

  return {
    updateQualifiedReferralConfirmationSetting,
    loading,
    error,
  };
};
