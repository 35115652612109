import { AvailableFilter } from 'ui/TableFilter';
import { MoneyTransferFilterMember } from 'commonTypes';
import { FilterType } from 'ui/TableFilter/const';
import { getMoneyTransferTypesList } from './helpers';

export enum ColumnId {
  Id = 'id',
  Type = 'type',
  Account = 'counterparty.moneyTransferAccount',
  Counterparty = 'counterparty',
  RecipientSender = 'recipientSender',
  NickName = 'counterparty.name',
  Wager = 'wager',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Type]: 'Тип',
  [ColumnId.Account]: 'Номер счета',
  [ColumnId.Counterparty]: 'Игрок',
  [ColumnId.RecipientSender]: 'Получатель/отправитель',
  [ColumnId.NickName]: 'Имя на\u00a0сайте',
  [ColumnId.Wager]: 'Отыгрыш',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.CreatedAt]: 'Создан',
};

export const allMoneyTransferFilters: Array<
  AvailableFilter<MoneyTransferFilterMember>
> = [
  {
    title: 'Тип',
    filterId: MoneyTransferFilterMember.Type,
    type: FilterType.List,
    list: getMoneyTransferTypesList(),
  },
];

export const blockColumnsIds = [
  ColumnId.Type,
  ColumnId.Account,
  ColumnId.Counterparty,
  ColumnId.Sum,
  ColumnId.Wager,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Type,
  ColumnId.Account,
  ColumnId.RecipientSender,
  ColumnId.NickName,
  ColumnId.Sum,
  ColumnId.Wager,
  ColumnId.CreatedAt,
];
