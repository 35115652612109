import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { AffiliatePromoCodeItemSteps } from '../../AffiliatePromoCodeItem/const';
import { GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID } from '../../CreateAffiliatePromoCode/const';

interface UseUpdateAffiliateModalHeaderParams {
  companyAffiliateId: string;
  companyAffiliateName: string;
  currentPromoCodeId?: string;
}

export const useUpdateAffiliateModalHeader = ({
  companyAffiliateId,
  companyAffiliateName,
  currentPromoCodeId,
}: UseUpdateAffiliateModalHeaderParams): void => {
  const {
    state: { step, initiatorId },
    dispatch,
  } = useContext(ModalContext);

  useEffect(() => {
    switch (step) {
      case AffiliatePromoCodeItemSteps.Details:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: `#${currentPromoCodeId}`,
            subTitle: 'Промокод',
            previousStep: AffiliatePromoCodeItemSteps.BaseStep,
          },
        });
        break;
      case AffiliatePromoCodeItemSteps.CreatePromoCodeBaseInfo:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание промокода',
            subTitle: 'Шаг 1',
            previousStep:
              initiatorId === GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID
                ? AffiliatePromoCodeItemSteps.CreatePromoCodeConnection
                : AffiliatePromoCodeItemSteps.BaseStep,
          },
        });
        break;
      case AffiliatePromoCodeItemSteps.CreatePromoCodeConnection:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание промокода',
            subTitle: 'Шаг 2',
            previousStep: AffiliatePromoCodeItemSteps.BaseStep,
          },
        });
        break;
      case AffiliatePromoCodeItemSteps.CreatePromoCodeConfirm:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание промокода',
            subTitle: 'Подтверждение',
            previousStep: AffiliatePromoCodeItemSteps.BaseStep,
          },
        });
        break;
      case AffiliatePromoCodeItemSteps.BaseStep:
      default:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: `#${companyAffiliateId}`,
            subTitle: companyAffiliateName,
          },
        });
        break;
    }
  }, [
    companyAffiliateId,
    companyAffiliateName,
    currentPromoCodeId,
    dispatch,
    initiatorId,
    step,
  ]);
};
