import { FormikErrors } from 'formik';
import { checkEmailValidity } from '../../../../../../utils/validators';

export interface UpdateCompanyIdentitiesValues {
  email: string;
  confirmation: string;
}

export const validate = (
  values: UpdateCompanyIdentitiesValues
): FormikErrors<UpdateCompanyIdentitiesValues> => {
  const errors: FormikErrors<UpdateCompanyIdentitiesValues> = {};

  if (!checkEmailValidity(values.email)) {
    errors.email = 'Неверный формат';
  }

  if (!values.email) {
    errors.email = 'Это поле обязательное';
  }

  if (values.confirmation.toLowerCase() !== 'изменить') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
