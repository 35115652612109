import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { PaymentData } from '../../../WithdrawalPayments/queries/generated/PaymentData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreatePaymentVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreatePaymentInput;
}>;


export type CreatePayment = { __typename: 'Mutation', createPayment: { __typename: 'CreatePaymentPayload', payment: { __typename: 'Payment', createdAt: string, externalPaymentId?: string | null, id: string, processor: CommonType.PaymentProcessor, status: SchemaTypes.PaymentStatus, updatedAt: string, error?: { __typename: 'PaymentError', code: string, message: string } | null, paidSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const CreatePaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreatePaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Payment"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PaymentData"}}]}}]}}]}}]}},...PaymentData.definitions,...SumData.definitions]} as unknown as DocumentNode;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePayment, CreatePaymentVariables>;

/**
 * __useCreatePayment__
 *
 * To run a mutation, you first call `useCreatePayment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayment, { data, loading, error }] = useCreatePayment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayment(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePayment, CreatePaymentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreatePayment, CreatePaymentVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentHookResult = ReturnType<typeof useCreatePayment>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePayment>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePayment, CreatePaymentVariables>;