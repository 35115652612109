import { FC, ReactElement, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import Button from 'ui/Button';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import styles from './MultilineCellWithLink.module.scss';

interface Props {
  title: string;
  route: string;
  subTitle?: string | ReactElement | null;
  isOpenInNewWindow?: boolean;
  hasIcon?: boolean;
}

export const MultilineCellWithLink: FC<Props> = ({
  title,
  route,
  subTitle,
  isOpenInNewWindow = false,
  hasIcon = true,
}) => {
  // Prevent event dispatch for case when component is used in table and causes a modal window to open
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.container}>
      <MultilineCellWithIcon
        title={
          <Link
            onClick={handleClick}
            to={route}
            target={isOpenInNewWindow ? '_blank' : '_self'}
            rel="noopener noreferrer"
          >
            {title}
          </Link>
        }
        subTitle={subTitle}
      />
      {hasIcon && (
        <Link
          onClick={handleClick}
          to={route}
          target={isOpenInNewWindow ? '_blank' : '_self'}
          rel="noopener noreferrer"
        >
          <Button
            linkStyled
            icon={faArrowUpRightFromSquare}
            className={styles.iconLinkButton}
          />
        </Link>
      )}
    </div>
  );
};
