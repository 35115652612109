import undoActionToast from 'ui/Toast/UndoActionToast';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { ListAllOperations } from 'generatedGraphql';
import { useDeleteMessage } from './queries/generated/DeleteMessage';

interface Value {
  deleteMessage: () => void;
}

const useDeletePlayerMessage = (
  id: string,
  isFullScreenTable: boolean
): Value => {
  const { dispatch } = useContext(ModalContext);
  const [deleteMessageMutation, { loading: isDeleteLoading }] =
    useDeleteMessage({
      variables: {
        input: {
          id,
        },
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            id,
            __typename: 'Message',
          }),
          fields: (cachedId, { DELETE }) => DELETE,
        });
      },
      refetchQueries: [
        isFullScreenTable
          ? ListAllOperations.Query.GetPlayerMessages
          : ListAllOperations.Query.GetPlayerMessagesBlock,
      ],
    });

  const deleteMessage = () => {
    if (isDeleteLoading) {
      return;
    }

    deleteMessageMutation({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteMessage: {
          __typename: 'DeleteMessagePayload',
          id,
        },
      },
      refetchQueries: [
        isFullScreenTable
          ? ListAllOperations.Query.GetPlayerMessages
          : ListAllOperations.Query.GetPlayerMessagesBlock,
      ],
    });
    undoActionToast('Сообщение удалено');
    dispatch({ type: ModalActions.Close });
  };

  return { deleteMessage };
};

export default useDeletePlayerMessage;
