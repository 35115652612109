import { MenuGroupItemData, MenuItemData } from 'commonComponents/Menu/types';
import { useEffect, useRef } from 'react';

type MenuItems = Array<MenuItemData | MenuGroupItemData>;

interface UseGetMenuGroupsResult {
  topLinks: MenuItems;
  bottomLinks: MenuItems;
}

export const useGetMenuGroups = (links: MenuItems): UseGetMenuGroupsResult => {
  const topLinks = useRef<MenuItems>([]);
  const bottomLinks = useRef<MenuItems>([]);

  useEffect(() => {
    if (topLinks.current.length && bottomLinks.current.length) {
      return;
    }

    links.forEach((link) => {
      if (!link.isBottom) {
        topLinks.current.push(link);
      } else {
        bottomLinks.current.push(link);
      }
    });
  }, [bottomLinks, links, topLinks]);

  return {
    topLinks: topLinks.current,
    bottomLinks: bottomLinks.current,
  };
};
