import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { BonusData } from '../../../../queries/generated/BonusData';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import { BonusBasicData } from '../../../../queries/generated/BonusBasicData';
import { BonusCashbackData } from '../../../../queries/generated/BonusCashbackData';
import { BonusFreespinData } from '../../../../queries/generated/BonusFreespinData';
import { BonusGiftData } from '../../../../queries/generated/BonusGiftData';
import { BonusPromoCodeData } from '../../../../queries/generated/BonusPromoCodeData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CancelGiftBonusVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CancelGiftBonusInput;
}>;


export type CancelGiftBonus = { __typename: 'Mutation', cancelGiftBonus: { __typename: 'CancelGiftBonusPayload', bonus: { __typename: 'GiftBonus', updatedAt: string, createdAt: string, id: string, status: SchemaTypes.BonusStatus, cancellable: boolean, balance: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, config: { __typename: 'BonusConfig', cashout?: number | null, minAccountBalance?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, winConditions?: { __typename: 'BonusWinConditions', wager?: number | null, betSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, requiredBetSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } | null }, winSum?: { __typename: 'Sum', amount: number, currency: CommonType.Currency } | null, sum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const CancelGiftBonusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelGiftBonus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CancelGiftBonusInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelGiftBonus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bonus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BonusData"}}]}}]}}]}},...BonusData.definitions,...SumData.definitions,...BonusBasicData.definitions,...BonusCashbackData.definitions,...BonusFreespinData.definitions,...BonusGiftData.definitions,...BonusPromoCodeData.definitions]} as unknown as DocumentNode;
export type CancelGiftBonusMutationFn = Apollo.MutationFunction<CancelGiftBonus, CancelGiftBonusVariables>;

/**
 * __useCancelGiftBonus__
 *
 * To run a mutation, you first call `useCancelGiftBonus` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGiftBonus` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGiftBonus, { data, loading, error }] = useCancelGiftBonus({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelGiftBonus(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelGiftBonus, CancelGiftBonusVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CancelGiftBonus, CancelGiftBonusVariables>(CancelGiftBonusDocument, options);
      }
export type CancelGiftBonusHookResult = ReturnType<typeof useCancelGiftBonus>;
export type CancelGiftBonusMutationResult = Apollo.MutationResult<CancelGiftBonus>;
export type CancelGiftBonusMutationOptions = Apollo.BaseMutationOptions<CancelGiftBonus, CancelGiftBonusVariables>;