import { Route } from 'src/router/routes.const';
import { RewardData } from '../queries/generated/RewardData';

export const getAffiliateInfo = (affiliate: RewardData['affiliate']) => {
  const isCompany = affiliate?.__typename === 'Company';
  const email = isCompany ? affiliate?.email : affiliate?.partnerEmail;
  const route = isCompany
    ? `${Route.COMPANIES}/${affiliate.id}`
    : `${Route.PARTNERS}/${affiliate?.id}`;

  return {
    email,
    route,
    name: affiliate.name,
  };
};
