import React, { FC } from 'react';
import { Dropdown } from 'ui/Dropdown';
import { useCpaTariffDropdown } from 'commonComponents/CpaTariffDropdown/useCpaTariffDropdown';

interface Props {
  name: string;
  label: string;
}

export const CpaTariffDropdown: FC<Props> = ({ label, name }) => {
  const { cpaTariffsOptions, loading, fetchMoreCallback, hasNextPage, error } =
    useCpaTariffDropdown();

  return (
    <Dropdown
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMoreCallback();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={cpaTariffsOptions}
    />
  );
};
