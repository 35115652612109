import { FC } from 'react';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { INVALID_INTEGER_CHARS } from '../../../../../../utils/blockInvalidChars';
import { nbsp } from '../../../../../../const';
import { CreateRewardTariffValues } from '../../../../types';

interface Props {
  values: CreateRewardTariffValues;
}

export const CreateCpaForm: FC<Props> = ({ values }: Props) => (
  <>
    <FormRowsWrapper>
      <Input
        name="title"
        label="Название"
        disabled
        value={`RevShare ${values.percent || 0}%`}
      />
    </FormRowsWrapper>
    <FormRowsWrapper>
      <Input.Number
        invalidChars={INVALID_INTEGER_CHARS}
        placeholder={`Укажите процент от${nbsp}0 до${nbsp}99`}
        name="percent"
        label="Процент вознаграждения"
      />
    </FormRowsWrapper>
  </>
);
