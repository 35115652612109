import { FC } from 'react';
import Input from 'ui/Input';
import { Form, Formik } from 'formik';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import styles from './EditNoteForm.module.scss';

interface Props {
  note?: string;
  goToViewMode: () => void;
  updateNote: (updatedText: string) => void;
  updateNoteLoading: boolean;
  maxNoteLength?: number;
}

interface FormValues {
  text: string;
}

const formatTextToView = (text?: string): string => (text ? `${text}\n` : '');

const formatTextToServer = (text?: string): string => {
  if (!text) return '';
  if (text.slice(-1).match(/\n/)) {
    return text.slice(0, -1);
  }

  return text;
};

const EditNoteForm: FC<Props> = ({
  note,
  goToViewMode,
  updateNote,
  updateNoteLoading,
  maxNoteLength,
}) => {
  const initialValues: FormValues = { text: formatTextToView(note) };

  const onSubmit = (values: FormValues) => {
    const updatedText = formatTextToServer(values.text);

    if (updatedText === note) {
      goToViewMode();

      return;
    }

    updateNote(updatedText);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Form className={styles.form}>
          <Input.Textarea
            disabled={updateNoteLoading}
            name="text"
            placeholder="Заметки нет"
            wrapperClassName={styles.inputWrapper}
            inputClassName={styles.input}
            autoFocus
            maxLength={maxNoteLength}
          />
          <div className={styles.buttons}>
            <ActionButton
              actionType={ButtonType.Check}
              onClick={submitForm}
              disabled={updateNoteLoading}
              dataTestId="submitEditButton"
              className={styles.actionButton}
            />

            <ActionButton
              actionType={ButtonType.Close}
              onClick={goToViewMode}
              disabled={updateNoteLoading}
              className={styles.actionButton}
              dataTestId="cancelEditButton"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditNoteForm;
