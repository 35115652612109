import { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { RadioButton } from 'ui/RadioButton';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { InternalOutgoingMoneyTransfers, FieldName } from './const';
import styles from './UpdatePlayerInternalOutgoingMoneyTransfers.module.scss';
import { useUpdateMoneyTransfersState } from './useUpdateMoneyTransfersState';

interface Props {
  id: string;
  isMoneyTransfersEnabled: boolean;
}

export const UpdatePlayerInternalOutgoingMoneyTransfers: FC<Props> = ({
  id,
  isMoneyTransfersEnabled,
}) => {
  const { loading, updateMoneyTransfersEnabledState, error } =
    useUpdateMoneyTransfersState(id);

  const initialMoneyTransfersEnabledState = isMoneyTransfersEnabled
    ? InternalOutgoingMoneyTransfers.Enabled
    : InternalOutgoingMoneyTransfers.Disabled;

  const [initialValues] = useState({
    [FieldName.moneyTransfersEnabledState]: initialMoneyTransfersEnabledState,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ moneyTransfersEnabledState }) =>
        updateMoneyTransfersEnabledState(moneyTransfersEnabledState)
      }
      validateOnChange={false}
    >
      {({ dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <div className={styles.titleCheckbox}>Переводы</div>
            <FormRow>
              <RadioButton
                name={FieldName.moneyTransfersEnabledState}
                value={InternalOutgoingMoneyTransfers.Enabled}
              >
                Доступны
              </RadioButton>
            </FormRow>
            <FormRow>
              <RadioButton
                name={FieldName.moneyTransfersEnabledState}
                value={InternalOutgoingMoneyTransfers.Disabled}
              >
                Недоступны
              </RadioButton>
            </FormRow>
          </FormRowsWrapper>
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            disabled={!dirty}
            isLoading={loading}
          >
            Изменить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
