import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetQualifiedReferrals } from './hooks/useGetQualifiedReferrals';
import { QualifiedReferralsTable } from './components/QualifiedReferralsTable';
import { QualifiedReferralsHeaderActions } from './components/QualifiedReferralsHeaderActions';

export const QualifiedReferralsList: FC = withBlockProvider(() => {
  const {
    loadQualifiedReferralsList,
    data,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    qualifiedReferralsList,
  } = useGetQualifiedReferrals();

  useBlockComponentState({
    loadData: loadQualifiedReferralsList,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <Block
      title="Квалифицированные игроки"
      id="qualifiedReferralsList"
      headerContent={<QualifiedReferralsHeaderActions refetch={refetch} />}
      shrinkLoaderWrapper={false}
      emptyText="Игроки не получали статус квалифицированные"
      isEmpty={isListEmpty(qualifiedReferralsList)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(qualifiedReferralsList) && (
        <QualifiedReferralsTable
          qualifiedReferralsList={qualifiedReferralsList}
        />
      )}
    </Block>
  );
});
