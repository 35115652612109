import { FormikErrors } from 'formik';
import { AcceptRewardValues } from './types';
import { AcceptRewardFields } from './const';

export const validation = (
  values: AcceptRewardValues
): FormikErrors<AcceptRewardValues> => {
  const errors: FormikErrors<AcceptRewardValues> = {};

  if (!values[AcceptRewardFields.amount]) {
    errors[AcceptRewardFields.amount] = 'Введите фактическую сумму';
  }

  return errors;
};
