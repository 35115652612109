import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import styles from './UpdateAllPostbackDisabledNotificationsButtons.module.scss';
import { useDeleteAllPostbackDisabledNotifications } from './hooks/useDeleteAllPostbackDisabledNotifications';
import { useEnableAllPostbacks } from './hooks/useEnableAllPostbacks';

export const UpdateAllPostbackDisabledNotificationsButtons: FC = () => {
  const { enableAllPostback, loading: enableAllPostbackLoading } =
    useEnableAllPostbacks();

  const {
    deleteAllPostbackDisabledNotification,
    loading: deleteAllPostbackDisabledNotificationLoading,
  } = useDeleteAllPostbackDisabledNotifications();

  return (
    <div className={styles.btnContainer}>
      <Button
        isLoading={enableAllPostbackLoading}
        onClick={() => enableAllPostback()}
        theme={ButtonTheme.Primary}
        size={ButtonSize.Large}
      >
        Включить все
      </Button>
      <Button
        isLoading={deleteAllPostbackDisabledNotificationLoading}
        onClick={() => deleteAllPostbackDisabledNotification()}
        theme={ButtonTheme.Important}
        size={ButtonSize.Large}
      >
        Удалить все
      </Button>
    </div>
  );
};
