import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { generatePath } from 'react-router';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { Route } from 'src/router/routes.const';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { TableCellAlign } from 'ui/Table/const';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';
import { PartnerOutgoingMoneyTransferBasicData } from '../queries/generated/PartnerOutgoingMoneyTransferBasicData';

const columnHelper =
  createColumnHelper<PartnerOutgoingMoneyTransferBasicData>();

const columnsSource: ColumnsSource<PartnerOutgoingMoneyTransferBasicData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.MoneyTransferAccount, {
    id: ColumnId.MoneyTransferAccount,
    header: mapColumnIdToName[ColumnId.MoneyTransferAccount],
  }),
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      const { name, phone, email, id } = row.original.player;
      const pathToPlayer = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          title={name}
          subTitle={email || phone}
          route={pathToPlayer}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetOutgoingMoneyTransfersColumns = (
  isFullScreenTable: boolean
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
