import { Currency } from 'commonTypes';
import { useEffect } from 'react';
import { ActorInfo } from 'commonComponents/CreatePayment';
import { useGetWithdrawalRestrictionAndLimitsLazyQuery } from '../queries/generated/GetWithdrawalRestrictionAndLimits';
import { ComparisonResult, getComparisonResult } from '../helpers';

interface RestrictionAndLimits {
  bypassRestrictionIfApproved?: boolean;
  comparisonResult?: ComparisonResult;
  restrictionAndLimitsLoading: boolean;
}

export const useGetRestrictionAndLimits = (
  actor?: ActorInfo,
  paymentAmount?: number,
  paymentCurrency?: Currency,
  isCryptoPaymentMethod?: boolean
): RestrictionAndLimits => {
  const [
    loadRestristions,
    {
      data: restrictionAndLimitsData,
      loading: restrictionAndLimitsLoading,
      previousData: restrictionAndLimitsPrevData,
    },
  ] = useGetWithdrawalRestrictionAndLimitsLazyQuery();

  useEffect(() => {
    if (actor && actor.__typename === 'Player') {
      loadRestristions({
        variables: {
          playerId: actor.id,
          currency: paymentCurrency,
        },
      });
    }
  }, [paymentCurrency, actor?.id, actor, loadRestristions]);

  const bypassRestrictionIfApproved =
    restrictionAndLimitsData?.player.lastRestrictions.withdrawalRestriction
      ?.bypassRestrictionIfApproved;

  const dailyLimit =
    restrictionAndLimitsData?.player.withdrawalLimits.dailyLimit.amount;
  const monthlyCryptoLimit =
    restrictionAndLimitsData?.player.withdrawalLimits.monthlyCryptoLimit.amount;

  const prevDailyLimit =
    restrictionAndLimitsPrevData?.player.withdrawalLimits.dailyLimit.amount;
  const prevMonthlyCryptoLimit =
    restrictionAndLimitsPrevData?.player.withdrawalLimits.monthlyCryptoLimit
      .amount;

  const comparisonResult = getComparisonResult(
    restrictionAndLimitsLoading,
    paymentAmount,
    dailyLimit,
    monthlyCryptoLimit,
    isCryptoPaymentMethod,
    prevDailyLimit,
    prevMonthlyCryptoLimit
  );

  return {
    bypassRestrictionIfApproved,
    comparisonResult,
    restrictionAndLimitsLoading,
  };
};
