import { FormikErrors } from 'formik';
import { Currency, PaymentProcessor } from 'commonTypes';

export interface CreatePaymentValues {
  currency: Currency;
  paymentMethodAccount: string;
  paymentMethodName: string;
  actor: string;
  provider?: PaymentProcessor;
  sum?: number;
  bypassRestrictionIfApproved?: boolean;
  approved?: boolean | null;
}

interface CreatePaymentErrors {
  provider?: string;
  sum?: string;
}

export const validate = (
  values: CreatePaymentValues,
  paymentRemainingAmount: number,
  paymentAmount?: number
): FormikErrors<CreatePaymentErrors> => {
  const errors: FormikErrors<CreatePaymentErrors> = {};

  if (!values.provider) {
    errors.provider = 'Необходимо выбрать провайдера';
  }

  if (!values.sum) {
    errors.sum = 'Необходимо ввести сумму';
  }

  if (values.sum && paymentAmount && paymentRemainingAmount < paymentAmount) {
    errors.sum = `Максимальная сумма ${paymentRemainingAmount}`;
  }

  return errors;
};
