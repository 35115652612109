import React, { FC } from 'react';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faPiggyBank } from '@fortawesome/pro-solid-svg-icons';
import styles from './Cashback.module.scss';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';
import useCashback from './useCashback';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { CashbackAccountData } from '../../queries/generated/CashbackAccountData';

interface Props {
  playerId: string;
  accounts?: Array<CashbackAccountData>;
}

const Cashback: FC<Props> = ({ playerId, accounts }) => {
  const iconConfig = {
    icon: faPiggyBank,
    iconColor: CardIconColor.YELLOW,
  };

  const { openCashback, getNextCashback } = useCashback();

  const cashbackCount =
    accounts?.filter((item) => !!item.statistic?.upcomingCashback.sum.amount)
      .length || 0;

  const countMore = cashbackCount > 1 ? cashbackCount - 1 : null;

  const upcomingCashback = getNextCashback({ accounts })?.statistic
    .upcomingCashback;

  const upcomingCashbackSum = upcomingCashback?.sum;
  const cashbackRewardPeriod = upcomingCashback?.rewardPeriod
    ? ` ${formatDate(upcomingCashback.rewardPeriod, DateFormat.ShortDate)}`
    : '';

  return (
    <div
      onClick={() => openCashback({ playerId })}
      className={styles.cashbackBlock}
    >
      <CardIcon className={styles.iconContainer} config={iconConfig} />
      <div className={styles.title}>{`Кэшбэк${cashbackRewardPeriod}:`}</div>
      <div className={styles.sum}>
        {!cashbackCount
          ? `Не предусмотрен`
          : upcomingCashbackSum &&
            formatSumWithCurrency(
              upcomingCashbackSum.amount,
              upcomingCashbackSum.currency
            )}
      </div>
      {countMore && <div className={styles.more}>{`и ещё ${countMore}`}</div>}
    </div>
  );
};

export default Cashback;
