import { PaymentStatus } from 'generatedGraphql';

import { UserPrivilege } from 'generatedUserPrivilege';
import { AuthContext } from 'commonComponents/Auth';

const isAllowedToUpdatePayment = (
  auth: AuthContext,
  privileges: Array<UserPrivilege>
) =>
  auth.privileges.areEveryGranted({
    privileges,
  });

export const checkIsButtonsVisible = (
  auth: AuthContext,
  paymentStatus: PaymentStatus
) => {
  const isAcceptButtonVisible =
    isAllowedToUpdatePayment(auth, [UserPrivilege.CreatePayment]) &&
    paymentStatus &&
    [PaymentStatus.Awaiting, PaymentStatus.InProgress].includes(paymentStatus);

  const isDeclineButtonVisible =
    isAllowedToUpdatePayment(auth, [UserPrivilege.CreatePayment]) &&
    paymentStatus &&
    [
      PaymentStatus.Awaiting,
      PaymentStatus.InProgress,
      PaymentStatus.Pending,
    ].includes(paymentStatus);

  return { isAcceptButtonVisible, isDeclineButtonVisible };
};
