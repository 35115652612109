import { AvailableFilter } from 'ui/TableFilter';
import { WithdrawalFilterMember } from 'commonTypes';
import { FilterType } from 'ui/TableFilter/const';
import { nbsp, playerPaymentNameMap } from '../../../../../../const';
import { getWithdrawalStatusesList } from './helpers';
import { getDropdownListByMap } from '../../../../../../utils/getDropdownListByMap';

export const allWithdrawalFilters: Array<
  AvailableFilter<WithdrawalFilterMember>
> = [
  {
    title: `Поиск${nbsp}по${nbsp}ID`,
    filterId: WithdrawalFilterMember.WithdrawalId,
    type: FilterType.Input,
  },
  {
    title: 'Статус',
    filterId: WithdrawalFilterMember.Status,
    type: FilterType.List,
    list: getWithdrawalStatusesList(),
  },
  {
    title: 'Метод',
    filterId: WithdrawalFilterMember.MethodName,
    type: FilterType.List,
    list: getDropdownListByMap(playerPaymentNameMap),
  },
];
