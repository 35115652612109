import { useContext } from 'react';
import { ModalContext, Tabs } from 'ui/Modal';
import { TabNameWithCounter } from 'commonComponents/TabNameWithCounter';
import { GetWithdrawalPaymentsStatistic } from '../queries/generated/GetWithdrawalPaymentsStatistic';
import WithdrawalItem from '../../WithdrawalItem';
import { PAYMENTS_COUNT_PER_PAGE } from '../../../const';
import { WithdrawalPayments } from '../../WithdrawalPayments';

interface UseWithdrawalSidebarTabsParams {
  withdrawalId: string;
  withdrawal?: GetWithdrawalPaymentsStatistic['withdrawal'];
}

interface UseWithdrawalSidebarTabsResult {
  tabs: Array<Tabs>;
}

export const useWithdrawalSidebarTabs = ({
  withdrawalId,
  withdrawal,
}: UseWithdrawalSidebarTabsParams): UseWithdrawalSidebarTabsResult => {
  const { state } = useContext(ModalContext);

  const payments = withdrawal?.payments;

  const paymentCount = payments?.edges.length || 0;

  const hasNextPage =
    !!withdrawal?.payments.pageInfo.hasNextPage ||
    paymentCount > PAYMENTS_COUNT_PER_PAGE;

  const count =
    paymentCount && paymentCount > PAYMENTS_COUNT_PER_PAGE
      ? PAYMENTS_COUNT_PER_PAGE
      : paymentCount;

  const tabs: Array<Tabs> = [
    {
      title: 'Информация',
      content: <WithdrawalItem withdrawalId={withdrawalId} />,
    },
    {
      title: (
        <TabNameWithCounter
          isActiveTab={state.activeTab === 1}
          tabName="Платежи"
          count={count}
          withPlus={hasNextPage}
        />
      ),
      content: <WithdrawalPayments withdrawalId={withdrawalId} />,
    },
  ];

  return {
    tabs,
  };
};
