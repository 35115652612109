import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { generatePath } from 'react-router';
import { Route as RouteEnum } from 'src/router/routes.const';
import BonusesTable from './components/BonusesTable';
import { useGetBonusesColumns } from './hooks/useGetBonusesColumns';
import { useGetPlayerReceiveGiftRestriction } from './queries/generated/GetPlayerReceiveGiftRestriction';

interface Props {
  id: string;
}

export const BonusesBlock: FC<Props> = withBlockProvider(({ id }) => {
  const { data } = useGetPlayerReceiveGiftRestriction({
    variables: {
      playerId: id,
    },
  });

  const receiveGiftRestriction =
    data?.player.lastRestrictions.receiveGiftRestriction;

  const { columns } = useGetBonusesColumns(false);

  return (
    <BonusesTable
      playerId={id}
      columns={columns}
      routeToCustomPage={generatePath(RouteEnum.PLAYER_BONUSES, { id })}
      isFullScreenTable={false}
      receiveGiftRestriction={receiveGiftRestriction}
    />
  );
});
