import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ApolloError } from '@apollo/client';
import { EditPartnerCompanyAffiliatesApplicationValues } from '../validate';
import { useUpdateCompanyAffiliateApplicationItem } from '../queries/generated/UpdateCompanyAffiliateApplicationItem';
import { CompanyAffiliateApplicationModalSteps } from '../../../const';

interface UseEditCompanyAffiliateReturn {
  handleSubmit: (values: EditPartnerCompanyAffiliatesApplicationValues) => void;
  loading: boolean;
  error?: ApolloError;
}

export const useEditCompanyAffiliate = (
  companyAffiliateApplicationId: string,
  affiliateId?: string
): UseEditCompanyAffiliateReturn => {
  const [updateCompanyAffiliateApplicationItem, { data, loading, error }] =
    useUpdateCompanyAffiliateApplicationItem();

  const { dispatch } = useContext(ModalContext);

  const handleSubmit = (
    values: EditPartnerCompanyAffiliatesApplicationValues
  ) => {
    if (!affiliateId) {
      return;
    }

    updateCompanyAffiliateApplicationItem({
      variables: {
        input: {
          id: affiliateId,
          applicationId: companyAffiliateApplicationId,
          note: values.note ? values.note : null,
          name: values.name,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step: CompanyAffiliateApplicationModalSteps.BaseStep,
        },
      });
    }
  }, [data, dispatch]);

  return { handleSubmit, loading, error };
};
