import React, { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { DepositStatus } from 'generatedGraphql';
import { useButtonsUpdateDeposit } from './useButtonsUpdateDeposit';
import styles from './ButtonsUpdateDeposit.module.scss';

interface Props {
  depositId: string;
  depositStatus: DepositStatus;
}

const ButtonsUpdateDeposit: FC<Props> = ({ depositId, depositStatus }) => {
  const {
    onRevertDepositToPresumablyDeclined,
    isAcceptButton,
    isReturnButton,
    updateModalStep,
    loading,
  } = useButtonsUpdateDeposit(depositStatus);

  return (
    <div className={styles.btnContainer}>
      {isAcceptButton && (
        <Button
          className={styles.btn}
          onClick={() => updateModalStep(2)}
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Success}
        >
          Подтвердить
        </Button>
      )}
      {isReturnButton && (
        <Button
          className={styles.btn}
          onClick={() =>
            onRevertDepositToPresumablyDeclined({
              id: depositId,
            })
          }
          type="submit"
          isLoading={loading}
          theme={ButtonTheme.Important}
        >
          Вернуть нефинальный статус
        </Button>
      )}
    </div>
  );
};

export default ButtonsUpdateDeposit;
