import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { NoteData } from '../../../../../../queries/generated/NoteData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCompanyNoteVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateCompanyNoteInput;
}>;


export type UpdateCompanyNote = { __typename: 'Mutation', updateCompanyNote: { __typename: 'UpdateCompanyNotePayload', company: { __typename: 'Company', id: string, note?: { __typename: 'Note', text: string } | null } } };


export const UpdateCompanyNoteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCompanyNote"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateCompanyNoteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCompanyNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NoteData"}}]}}]}}]}}]}},...NoteData.definitions]} as unknown as DocumentNode;
export type UpdateCompanyNoteMutationFn = Apollo.MutationFunction<UpdateCompanyNote, UpdateCompanyNoteVariables>;

/**
 * __useUpdateCompanyNote__
 *
 * To run a mutation, you first call `useUpdateCompanyNote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyNote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyNote, { data, loading, error }] = useUpdateCompanyNote({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyNote(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyNote, UpdateCompanyNoteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCompanyNote, UpdateCompanyNoteVariables>(UpdateCompanyNoteDocument, options);
      }
export type UpdateCompanyNoteHookResult = ReturnType<typeof useUpdateCompanyNote>;
export type UpdateCompanyNoteMutationResult = Apollo.MutationResult<UpdateCompanyNote>;
export type UpdateCompanyNoteMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyNote, UpdateCompanyNoteVariables>;