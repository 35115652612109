import { useMemo } from 'react';
import { AffiliateType } from '../../../const';

interface UseGetInitialAffiliateTypeByPrivilegesParams {
  canCreatePartnerReferralLink: boolean;
  canCreateCompanyReferralLink: boolean;
}

export const useGetInitialAffiliateTypeByPrivileges = ({
  canCreatePartnerReferralLink,
  canCreateCompanyReferralLink,
}: UseGetInitialAffiliateTypeByPrivilegesParams) => {
  const initialAffiliateType = useMemo(() => {
    if (canCreatePartnerReferralLink && !canCreateCompanyReferralLink) {
      return AffiliateType.Partner;
    }

    if (!canCreatePartnerReferralLink && canCreateCompanyReferralLink) {
      return AffiliateType.Company;
    }

    return null;
  }, [canCreatePartnerReferralLink, canCreateCompanyReferralLink]);

  return { initialAffiliateType };
};
