import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { AdminData } from '../../../../../../queries/generated/AdminData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateAdminVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateAdminInput;
}>;


export type CreateAdmin = { __typename: 'Mutation', createAdmin: { __typename: 'CreateAdminPayload', admin: { __typename: 'Admin', email: string, firstName: string, id: string, lastName: string, roles: Array<CommonType.AdminRole> } } };


export const CreateAdminDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateAdmin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateAdminInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAdmin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admin"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Admin"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdminData"}}]}}]}}]}}]}},...AdminData.definitions]} as unknown as DocumentNode;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdmin, CreateAdminVariables>;

/**
 * __useCreateAdmin__
 *
 * To run a mutation, you first call `useCreateAdmin` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdmin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdmin, { data, loading, error }] = useCreateAdmin({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdmin(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAdmin, CreateAdminVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateAdmin, CreateAdminVariables>(CreateAdminDocument, options);
      }
export type CreateAdminHookResult = ReturnType<typeof useCreateAdmin>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdmin>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdmin, CreateAdminVariables>;