import { useFormikContext } from 'formik';
import { SetStateAction, useState } from 'react';
import { AffiliatesValues } from '../../../types';

interface UseAffiliateFormDataParams {
  name: string;
  note?: string;
  affiliateIndex: number;
}

interface UseAffiliateFormDataResult {
  affiliateName: string;
  affiliateNote?: string;
  handleInputChange: (value: SetStateAction<string>) => void;
  handleInputBlur: () => void;
  handleTextareaChange: (value: SetStateAction<string | undefined>) => void;
  handleTextareaBlur: () => void;
  handleDeleteAffiliateClick: () => void;
}

export const useNewAffiliateFormHandlers = ({
  name,
  note,
  affiliateIndex,
}: UseAffiliateFormDataParams): UseAffiliateFormDataResult => {
  const { setFieldValue, values, setErrors } =
    useFormikContext<AffiliatesValues>();
  const [affiliateName, setAffiliateName] = useState(name);
  const [affiliateNote, setAffiliateNote] = useState(note);

  const handleInputChange = (value: SetStateAction<string>) => {
    setAffiliateName(value);
  };

  const handleInputBlur = () => {
    values.affiliates[affiliateIndex] = {
      ...values.affiliates[affiliateIndex],
      name: affiliateName,
    };

    setFieldValue('affiliates', values.affiliates);
  };

  const handleTextareaChange = (value: SetStateAction<string | undefined>) => {
    setAffiliateNote(value);
  };

  const handleTextareaBlur = () => {
    if (!affiliateNote) {
      return;
    }

    values.affiliates[affiliateIndex] = {
      ...values.affiliates[affiliateIndex],
      note: affiliateNote,
    };

    setFieldValue('affiliates', values.affiliates);
  };

  const handleDeleteAffiliateClick = () => {
    values.affiliates.splice(affiliateIndex, 1);

    setErrors({});
    setFieldValue('affiliates', values.affiliates);
  };

  return {
    affiliateName,
    affiliateNote,
    handleInputChange,
    handleInputBlur,
    handleTextareaChange,
    handleTextareaBlur,
    handleDeleteAffiliateClick,
  };
};
