import { FC } from 'react';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faBan, faCircleCheck, faUser } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, CheckboxSize } from 'ui/Checkbox';
import styles from './LeftContent.module.scss';
import {
  AffiliateInApproveProcessStatus,
  AffiliatesInProgress,
} from '../../../../../../store';

interface Props {
  isEditable: boolean;
  affiliatesInProgressState: AffiliatesInProgress;
  affiliateId: string;
}

export const LeftContent: FC<Props> = ({
  isEditable,
  affiliatesInProgressState,
  affiliateId,
}) => {
  if (!isEditable) {
    return (
      <CardIcon
        className={styles.iconContainer}
        config={{
          icon: faUser,
          iconColor: CardIconColor.GREY,
        }}
      />
    );
  }

  if (
    affiliatesInProgressState[affiliateId] ===
    AffiliateInApproveProcessStatus.InProcess
  ) {
    return (
      <Checkbox
        wrapperClassName={styles.checkbox}
        className={styles.checkbox}
        checkboxSize={CheckboxSize.Small}
        label=""
        name={affiliateId}
      />
    );
  }

  if (
    affiliatesInProgressState[affiliateId] ===
    AffiliateInApproveProcessStatus.Approved
  ) {
    return (
      <CardIcon
        className={styles.iconContainer}
        config={{
          icon: faCircleCheck,
          iconColor: CardIconColor.GREEN,
        }}
      />
    );
  }

  return (
    <CardIcon
      className={styles.iconContainer}
      config={{
        icon: faBan,
        iconColor: CardIconColor.RED,
      }}
    />
  );
};
