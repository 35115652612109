import * as SchemaTypes from '../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeleteAdminVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeleteAdminInput;
}>;


export type DeleteAdmin = { __typename: 'Mutation', deleteAdmin: { __typename: 'DeleteAdminPayload', id: string } };


export const DeleteAdminDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAdmin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteAdminInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAdmin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type DeleteAdminMutationFn = Apollo.MutationFunction<DeleteAdmin, DeleteAdminVariables>;

/**
 * __useDeleteAdmin__
 *
 * To run a mutation, you first call `useDeleteAdmin` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdmin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdmin, { data, loading, error }] = useDeleteAdmin({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAdmin(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdmin, DeleteAdminVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAdmin, DeleteAdminVariables>(DeleteAdminDocument, options);
      }
export type DeleteAdminHookResult = ReturnType<typeof useDeleteAdmin>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdmin>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<DeleteAdmin, DeleteAdminVariables>;