import errorToast from 'ui/Toast/ErrorToast';
import { SuspectState } from 'generatedGraphql';
import { useSuspectReward } from '../queries/generated/SuspectReward';
import { useUnsuspectReward } from '../queries/generated/UnsuspectReward';

interface UseSuspiciousRewardActionsParams {
  id: string;
}

export const useSuspiciousRewardActions = ({
  id,
}: UseSuspiciousRewardActionsParams) => {
  const [suspectReward, { loading: suspectLoading }] = useSuspectReward();

  const [unsuspectReward, { loading: unsuspectLoading }] = useUnsuspectReward();

  const suspectRewardAction = () => {
    suspectReward({
      variables: { input: { id } },
      optimisticResponse: {
        __typename: 'Mutation',
        suspectReward: {
          reward: {
            id,
            suspectState: SuspectState.Suspicious,
            __typename: 'Reward',
          },
          __typename: 'SuspectRewardPayload',
        },
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'suspect-reward-error',
        });
      },
    });
  };

  const unsuspectRewardAction = () => {
    unsuspectReward({
      variables: { input: { id } },
      optimisticResponse: {
        __typename: 'Mutation',
        unsuspectReward: {
          reward: {
            id,
            suspectState: SuspectState.Eligible,
            __typename: 'Reward',
          },
          __typename: 'UnsuspectRewardPayload',
        },
      },
      onError: (error) => {
        errorToast({
          header: error.name,
          text: error.message,
          toastId: 'unsuspect-reward-error',
        });
      },
    });
  };

  return {
    suspectRewardAction,
    suspectLoading,
    unsuspectRewardAction,
    unsuspectLoading,
  };
};
