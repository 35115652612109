import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useGetPartnerApplicationsLazyQuery } from '../queries/generated/GetPartnerApplications';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';

const cellHeight = Heights.defaultCellHeight;

export const usePartnerApplicationsData = () => {
  const [
    loadPartnerApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetPartnerApplicationsLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
    },
  });

  useBlockComponentState({
    loadData: loadPartnerApplications,
    loading,
    error,
  });

  const partnerApplicationsList = useMemo(
    () => data?.partnerApplications?.edges.map(({ node }) => node),
    [data?.partnerApplications?.edges]
  );

  const endCursor = data?.partnerApplications?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.partnerApplications?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchPartnerApplicationsList: refetch,
    partnerApplicationsList,
  };
};
