import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext, useEffect } from 'react';
import { CreatePromoCodeModalSteps } from '../const';

export const useCreatePromoCodeModalSteps = (): void => {
  const { state, dispatch } = useContext(ModalContext);

  useEffect(() => {
    if (state.step === CreatePromoCodeModalSteps.BaseStep) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание промокода',
          subTitle: 'Шаг 1',
        },
      });
    } else if (
      state.step === CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus
    ) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание промокода',
          subTitle: 'Шаг 2',
        },
      });
    } else if (state.step === CreatePromoCodeModalSteps.ConfirmCreation) {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: 'Создание промокода',
          subTitle: 'Подтверждение',
        },
      });
    }
  }, [dispatch, state.step]);
};
