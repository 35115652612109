import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { signInRestrictionProtectedToType } from '../helpers';
import { SignInRestrictionHistoryModal } from '../components/SignInRestrictionHistoryModal';
import { SignInRestrictionHistoryData } from '../queries/generated/SignInRestrictionHistoryData';
import { SignInRestrictionHistoryBlockData } from '../queries/generated/SignInRestrictionHistoryBlockData';

interface OpenModalParams {
  signInRestriction:
    | SignInRestrictionHistoryData
    | SignInRestrictionHistoryBlockData;
  initiatorId: string;
}

type UseSignInRestrictionModalResult = (params: OpenModalParams) => void;

export const useSignInRestrictionModal =
  (): UseSignInRestrictionModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      async ({ signInRestriction, initiatorId }: OpenModalParams) => {
        const { protected: isProtected, id } = signInRestriction;
        const subTitle = signInRestrictionProtectedToType(isProtected);

        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: `#${id}`,
            subTitle,
            content: <SignInRestrictionHistoryModal signInRestrictionId={id} />,
            initiatorId,
          },
        });
      },
      [modalDispatch]
    );

    return openModal;
  };
