export enum ColumnId {
  Id = 'id',
  TournamentType = 'tournament',
  Name = 'tournament.name',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.TournamentType]: 'Вид турнира',
  [ColumnId.Name]: 'Название турнира',
  [ColumnId.Sum]: 'Сумма',
  [ColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  ColumnId.TournamentType,
  ColumnId.Name,
  ColumnId.Sum,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.TournamentType,
  ColumnId.Name,
  ColumnId.Sum,
  ColumnId.CreatedAt,
];
