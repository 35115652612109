import { FC } from 'react';
import { TextOverflow } from 'ui/TextOverflow';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import styles from './CopyModalRow.module.scss';
import { copyToClipboard } from '../../utils/copyToClipboard';

interface Props {
  value: string;
  copyValue?: string;
  toastText: string;
  isRightIconPosition?: boolean;
}

export const CopyModalRow: FC<Props> = ({
  value,
  copyValue,
  toastText,
  isRightIconPosition,
}) => {
  const onClick = () => {
    copyToClipboard(copyValue || value);
    toast.success(toastText);
  };

  return (
    <button
      data-testid="copy-modal-row"
      type="button"
      className={classnames(
        styles.btn,
        isRightIconPosition && styles.rightIconPosition
      )}
      onClick={onClick}
    >
      <CardIcon
        className={styles.iconContainer}
        config={{ icon: faClone, iconColor: CardIconColor.TRANSPARENT }}
      />
      <div className={styles.externalPaymentId}>
        <TextOverflow text={value} />
      </div>
    </button>
  );
};
