import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useGetMediaCampaignForPromoCodeLazyQuery } from '../queries/generated/GetMediaCampaignForPromoCode';
import { FieldName } from '../../../../../const';
import { CreatePromoCodeValues } from '../../../../../types';

export const useGetMediaCampaignIfReferralLinkUndefined = () => {
  const { setFieldValue } = useFormikContext<CreatePromoCodeValues>();

  const [getMediaCampaignForPromoCode] =
    useGetMediaCampaignForPromoCodeLazyQuery();

  const getMediaCampaignIfReferralLinkUndefined = useCallback(
    (mediaCampaignId: string) => {
      getMediaCampaignForPromoCode({
        variables: {
          id: mediaCampaignId,
        },
        onCompleted: (data) => {
          setFieldValue(FieldName.MediaCampaignName, data.mediaCampaign.name);
          setFieldValue(FieldName.MediaCampaignUrl, data.mediaCampaign.url);
        },
        onError: () => {
          toast.error('Медиа кампания не найдена');
        },
      });
    },
    [getMediaCampaignForPromoCode, setFieldValue]
  );

  return { getMediaCampaignIfReferralLinkUndefined };
};
