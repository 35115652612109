import { FC } from 'react';
import Error from 'ui/Error';
import { useInfiniteScroll } from 'src/utils/hooks/useInfiniteScroll';
import { SidebarLoader } from 'ui/SidebarLoader';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { CreatePaymentInfoBlock } from './components/CreatePaymentInfoBlock';
import { WithdrawalPayment } from './components/WithdrawalPayment';
import { useGetPayments } from './hooks/useGetPayments';

interface Props {
  withdrawalId: string;
}

export const WithdrawalPayments: FC<Props> = ({ withdrawalId }) => {
  const {
    payments,
    loadMore,
    error,
    remainingSum,
    withdrawalAdminId,
    loading,
    isListEmpty,
  } = useGetPayments({ withdrawalId });

  const loadTrigger = useInfiniteScroll(loadMore);

  if (error) {
    return <Error error={error} />;
  }

  return (
    <SidebarLoader loading={loading && isListEmpty}>
      <CreatePaymentInfoBlock
        isListEmpty={isListEmpty}
        remainingAmount={remainingSum?.amount}
        withdrawalAdminId={withdrawalAdminId}
      />
      {!!payments?.edges.length && (
        <>
          {payments.edges.map(({ node }) => (
            <WithdrawalPayment payment={node} key={node.id} />
          ))}
          {loadTrigger}
          <LoadMoreStatus
            fetchMore={loadMore}
            isLoading={loading}
            isEndOfList={!payments?.pageInfo.hasNextPage}
            messages={{
              complete: 'Вы достигли конца списка',
            }}
          />
        </>
      )}
    </SidebarLoader>
  );
};
