export enum ColumnId {
  Id = 'id',
  Player = '__typename',
  Rank = 'rank',
  Country = 'country',
  Affiliate = 'affiliate',
  Note = 'note.text',
  LastVisited = 'lastVisited',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Player]: 'Пользователь',
  [ColumnId.Rank]: 'Текущий статус',
  [ColumnId.Country]: 'Страна',
  [ColumnId.Affiliate]: 'Партнёр/Компания',
  [ColumnId.Note]: 'Заметка',
  [ColumnId.LastVisited]: 'Последний вход',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Player,
  ColumnId.Rank,
  ColumnId.Country,
  ColumnId.Affiliate,
  ColumnId.Note,
  ColumnId.LastVisited,
];
