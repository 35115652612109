import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UndeletePlayerVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UndeletePlayerInput;
}>;


export type UndeletePlayer = { __typename: 'Mutation', undeletePlayer: { __typename: 'UndeletePlayerPayload', player: { __typename: 'Player', deletedAt?: string | null, id: string, state: SchemaTypes.PlayerState } } };


export const UndeletePlayerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UndeletePlayer"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UndeletePlayerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"undeletePlayer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletedAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UndeletePlayerMutationFn = Apollo.MutationFunction<UndeletePlayer, UndeletePlayerVariables>;

/**
 * __useUndeletePlayer__
 *
 * To run a mutation, you first call `useUndeletePlayer` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeletePlayer` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeletePlayer, { data, loading, error }] = useUndeletePlayer({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndeletePlayer(baseOptions?: ApolloReactHooks.MutationHookOptions<UndeletePlayer, UndeletePlayerVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UndeletePlayer, UndeletePlayerVariables>(UndeletePlayerDocument, options);
      }
export type UndeletePlayerHookResult = ReturnType<typeof useUndeletePlayer>;
export type UndeletePlayerMutationResult = Apollo.MutationResult<UndeletePlayer>;
export type UndeletePlayerMutationOptions = Apollo.BaseMutationOptions<UndeletePlayer, UndeletePlayerVariables>;