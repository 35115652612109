import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CompanyApplicationModal } from '../components/CompanyApplicationModal';
import { CompanyApplicationData } from '../queries/generated/CompanyApplicationData';

interface OpenModalParams {
  companyApplication: CompanyApplicationData;
  initiatorId: string;
}

type UseCompanyApplicationModalResult = (params: OpenModalParams) => void;

export const useCompanyApplicationModal =
  (): UseCompanyApplicationModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      async ({ companyApplication: { id }, initiatorId }: OpenModalParams) => {
        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: `#${id}`,
            subTitle: 'Заявка компании',
            content: <CompanyApplicationModal companyApplicationId={id} />,
            initiatorId,
          },
        });
      },
      [modalDispatch]
    );

    return openModal;
  };
