import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCountriesVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetCountries = { __typename: 'Query', countries: Array<any> };


export const GetCountriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCountries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countries"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCountries__
 *
 * To run a query within a React component, call `useGetCountries` and pass it any options that fit your needs.
 * When your component renders, `useGetCountries` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountries({
 *   variables: {
 *   },
 * });
 */
export function useGetCountries(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCountries, GetCountriesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCountries, GetCountriesVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCountries, GetCountriesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCountries, GetCountriesVariables>(GetCountriesDocument, options);
        }
export function useGetCountriesSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetCountries, GetCountriesVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCountries, GetCountriesVariables>(GetCountriesDocument, options);
        }
export type GetCountriesHookResult = ReturnType<typeof useGetCountries>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<typeof useGetCountriesSuspenseQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountries, GetCountriesVariables>;