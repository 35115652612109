import { ReactElement } from 'react';
import { AffiliatePromoCodeItemSteps } from '../../AffiliatePromoCodeItem/const';
import { CreateAffiliatePromoCodeFormBaseStep } from '../components/CreateAffiliatePromoCodeFormBaseStep';
import { CreateAffiliatePromoCodeFormSecondStep } from '../components/CreateAffiliatePromoCodeFormSecondStep';
import { CreateAffiliatePromoCodeFormConfirmStep } from '../components/CreateAffiliatePromoCodeFormConfirmStep';

interface GetCreateAffiliatePromoCodeFormByStepParams {
  step?: AffiliatePromoCodeItemSteps | null;
  companyId: string;
  companyAffiliateId: string;
  createCompanyAffiliatePromoCodeLoading: boolean;
}

export const getCreateAffiliatePromoCodeFormByStep = ({
  step,
  companyId,
  companyAffiliateId,
  createCompanyAffiliatePromoCodeLoading,
}: GetCreateAffiliatePromoCodeFormByStepParams): ReactElement | null => {
  switch (step) {
    case AffiliatePromoCodeItemSteps.CreatePromoCodeBaseInfo:
      return (
        <CreateAffiliatePromoCodeFormBaseStep
          companyId={companyId}
          companyAffiliateId={companyAffiliateId}
        />
      );
    case AffiliatePromoCodeItemSteps.CreatePromoCodeConnection:
      return <CreateAffiliatePromoCodeFormSecondStep />;
    case AffiliatePromoCodeItemSteps.CreatePromoCodeConfirm:
      return (
        <CreateAffiliatePromoCodeFormConfirmStep
          submitLoading={createCompanyAffiliatePromoCodeLoading}
        />
      );
    default:
      return null;
  }
};
