import SearchInput, { OnSearchInputSubmit } from 'ui/SearchInput/SearchInput';
import { useNavigate } from 'react-router';
import { Route } from 'src/router/routes.const';
import {
  PARTNERS_SEARCH_INPUT_NAME,
  PARTNERS_SEARCH_PLACEHOLDER,
} from '../../../PartnersList/const';

export const PartnersSearchInput = () => {
  const navigate = useNavigate();

  const handleSubmit: OnSearchInputSubmit<{
    [PARTNERS_SEARCH_INPUT_NAME]: string;
  }> = () => {
    navigate(Route.PARTNERS);
  };

  return (
    <SearchInput
      name={PARTNERS_SEARCH_INPUT_NAME}
      placeholder={PARTNERS_SEARCH_PLACEHOLDER}
      onSubmit={handleSubmit}
    />
  );
};
