import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Dropdown } from 'ui/Dropdown';
import { Tooltip } from 'ui/Tooltip';
import Error from 'ui/Error';
import { useModalManageData } from 'ui/Modal';
import { RewardTariffType } from 'commonTypes';
import { CreateCpaForm } from './components/CreateCpaForm';
import { CreateRevShareForm } from './components/CreateRevShareForm';
import { useCreateRewardTariff } from './hooks/useCreateRewardTariff';
import { rewardTypeOptions } from '../../../../helpers';
import { initialValues } from './const';
import { validate } from './validation';

export const CreateRewardTariff: FC = () => {
  const { onCreateTariff, error, data, loading } = useCreateRewardTariff();

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'create-tariffs-error' },
    hasData: !!data,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onCreateTariff}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isSubmitting, isValid, dirty, values }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <Dropdown
              name="type"
              label="Модель оплаты"
              options={rewardTypeOptions}
            />
          </FormRowsWrapper>
          {values.type === RewardTariffType.RevShare && (
            <CreateCpaForm values={values} />
          )}
          {values.type === RewardTariffType.Cpa && <CreateRevShareForm />}
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Заполните все поля' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={isSubmitting}
              disabled={!isValid || !dirty}
            >
              Создать
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
