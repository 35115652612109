import FormRowsWrapper from 'ui/FormRowsWrapper';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { CreateMediaItemLinkFields } from '../../const';

export const RevShareForm = () => (
  <FormRowsWrapper>
    <RevShareTariffDropdown
      name={CreateMediaItemLinkFields.revShareRewardTariffId}
      label="Партнёрский тариф"
    />
  </FormRowsWrapper>
);
