import { Currency } from 'commonTypes';
import { SumFlowDirection } from 'generatedGraphql';
import { formatSumAmount, formatSumWithCurrency } from 'src/utils/accounting';
import { addSignToString } from 'src/utils/addSignToString';

interface GetSumWithSignParams {
  amount: number;
  currency?: Currency;
  direction?: SumFlowDirection | null;
}

export const getSumWithSign = ({
  amount,
  currency,
  direction,
}: GetSumWithSignParams): string => {
  const sum = currency
    ? formatSumWithCurrency(amount, currency)
    : formatSumAmount(amount);

  return addSignToString(sum, direction);
};
