import { ButtonHTMLAttributes, FC, ReactElement } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import Button, { IconType } from '../Button';
import styles from './ListLoader.module.scss';

interface Props {
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  emptyText?: string;
  refetch?: () => void;
}

const ListLoader: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  isLoading,
  isEmpty,
  isError,
  emptyText = 'Список пуст',
  refetch,
  className,
}) => {
  if (!isLoading && !isEmpty && !isError) {
    return null;
  }

  let nodeToRender: ReactElement = (
    <div className={styles.errorText}>{emptyText}</div>
  );

  if (isLoading) {
    nodeToRender = (
      <>
        <FontAwesomeIcon className={styles.loader} icon={faSpinnerThird} spin />
        <div className={styles.loadingText}>Загружаем информацию</div>
      </>
    );
  } else if (isError) {
    nodeToRender = (
      <>
        Не удалось загрузить данные.
        {refetch && (
          <Button
            className={styles.updateButton}
            linkStyled
            underlined
            icon={IconType.Update}
            onClick={() => refetch?.()}
          >
            Обновить
          </Button>
        )}
      </>
    );
  }

  return (
    <div
      className={classnames(className, {
        [styles.container]: true,
        [styles.loadingContainer]: isLoading,
        [styles.errorContainer]: isError,
      })}
    >
      {nodeToRender}
    </div>
  );
};

export default ListLoader;
