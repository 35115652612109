export enum ColumnId {
  Id = 'id',
  Title = 'title',
  Countries = 'countries',
  Baseline = 'baselineSum',
  RewardSum = 'rewardSum',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Title]: 'Название',
  [ColumnId.Countries]: 'Геолокация',
  [ColumnId.Baseline]: 'Baseline',
  [ColumnId.RewardSum]: 'Вознаграждение',
  [ColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Title,
  ColumnId.Countries,
  ColumnId.Baseline,
  ColumnId.RewardSum,
  ColumnId.CreatedAt,
];
