import { useCallback, useContext, useEffect } from 'react';
import {
  BlockContext,
  setErrorAction,
  setLoadNewAction,
  setLoadingAction,
} from 'ui/Block/store';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { setRefetchAction } from '../store/actions/setRefetch';
import { ErrorType } from '../store/types';
import { resetErrorAction } from '../store/actions/resetError';

interface Props {
  data?: object;
  loading: boolean;
  hasNextPage?: boolean;
  isFullScreenTable?: boolean;
  refetch: () => Promise<ApolloQueryResult<unknown>>;
  fetchMoreCallback: () => Promise<ApolloQueryResult<unknown> | undefined>;
}

export const useBlockTableComponentState = ({
  data,
  loading,
  hasNextPage,
  isFullScreenTable = false,
  refetch,
  fetchMoreCallback,
}: Props): void => {
  const { state, dispatch } = useContext(BlockContext);

  const fetchMoreWithError = useCallback(async () => {
    try {
      if (state.error.type) {
        dispatch(resetErrorAction());
      }

      await fetchMoreCallback();
    } catch (error) {
      dispatch(
        setErrorAction({
          type: ErrorType.FetchMore,
          data: error as ApolloError,
        })
      );
    }
  }, [dispatch, fetchMoreCallback, state.error.type]);

  useEffect(() => {
    dispatch(setRefetchAction({ refetch }));
  }, [refetch, dispatch]);

  useEffect(() => {
    if (!state.isOpened) {
      dispatch(setLoadingAction({ loading: false }));
    } else if (data && hasNextPage && isFullScreenTable) {
      dispatch(
        setLoadNewAction({
          hasNew: hasNextPage,
          onLoadNew: fetchMoreWithError,
        })
      );
    } else if (!hasNextPage && isFullScreenTable) {
      dispatch(
        setLoadNewAction({
          hasNew: false,
        })
      );
    }
  }, [
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    dispatch,
    state.isOpened,
    fetchMoreWithError,
  ]);
};
