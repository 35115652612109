import { PromoCodeUsageType } from 'generatedGraphql';
import { promoCodeUsageTypeMap } from '../../../../../../../../const';

export const promoCodeUsageTypeOptions = [
  {
    id: PromoCodeUsageType.NewPlayersOnly,
    label: promoCodeUsageTypeMap[PromoCodeUsageType.NewPlayersOnly],
  },
  {
    id: PromoCodeUsageType.AllPlayers,
    label: promoCodeUsageTypeMap[PromoCodeUsageType.AllPlayers],
  },
  {
    id: PromoCodeUsageType.AffiliatedPlayersOnly,
    label: promoCodeUsageTypeMap[PromoCodeUsageType.AffiliatedPlayersOnly],
  },
];

export const promoCodeUsageTypeOptionsDisabledLink = [
  {
    id: PromoCodeUsageType.NewPlayersOnly,
    label: promoCodeUsageTypeMap[PromoCodeUsageType.NewPlayersOnly],
  },
  {
    id: PromoCodeUsageType.AllPlayers,
    label: promoCodeUsageTypeMap[PromoCodeUsageType.AllPlayers],
  },
];
