import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetAvailableMoneyTransferFiltersVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  usedFilters: Array<SchemaTypes.Scalars['MoneyTransferFilterMember']['input']> | SchemaTypes.Scalars['MoneyTransferFilterMember']['input'];
}>;


export type GetAvailableMoneyTransferFilters = { __typename: 'Query', player: { __typename: 'Player', availableMoneyTransferFilters: Array<CommonType.MoneyTransferFilterMember>, id: string } };


export const GetAvailableMoneyTransferFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAvailableMoneyTransferFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"usedFilters"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MoneyTransferFilterMember"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"player"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"availableMoneyTransferFilters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"usedFilters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"usedFilters"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAvailableMoneyTransferFilters__
 *
 * To run a query within a React component, call `useGetAvailableMoneyTransferFilters` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableMoneyTransferFilters` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableMoneyTransferFilters({
 *   variables: {
 *      id: // value for 'id'
 *      usedFilters: // value for 'usedFilters'
 *   },
 * });
 */
export function useGetAvailableMoneyTransferFilters(baseOptions: ApolloReactHooks.QueryHookOptions<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables> & ({ variables: GetAvailableMoneyTransferFiltersVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables>(GetAvailableMoneyTransferFiltersDocument, options);
      }
export function useGetAvailableMoneyTransferFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables>(GetAvailableMoneyTransferFiltersDocument, options);
        }
export function useGetAvailableMoneyTransferFiltersSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables>(GetAvailableMoneyTransferFiltersDocument, options);
        }
export type GetAvailableMoneyTransferFiltersHookResult = ReturnType<typeof useGetAvailableMoneyTransferFilters>;
export type GetAvailableMoneyTransferFiltersLazyQueryHookResult = ReturnType<typeof useGetAvailableMoneyTransferFiltersLazyQuery>;
export type GetAvailableMoneyTransferFiltersSuspenseQueryHookResult = ReturnType<typeof useGetAvailableMoneyTransferFiltersSuspenseQuery>;
export type GetAvailableMoneyTransferFiltersQueryResult = Apollo.QueryResult<GetAvailableMoneyTransferFilters, GetAvailableMoneyTransferFiltersVariables>;