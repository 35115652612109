/*
 * URL validation:
 * - schema: http:// | https://
 * - minimum 2-level domain (at least one dot)
 * - any combination of characters before dot
 * - at least one character after dot with any combination of characters
 * @example https://url.com
 */
export const checkIsValidUrl = (value: string) => {
  const pattern =
    /^(https?):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,;]*\.[-a-zA-Z0-9+&@#/%?=~_|!:,.;]+/;

  return pattern.test(value);
};
