/* eslint-disable @typescript-eslint/no-explicit-any */
export const trimEdges = <T extends { [key: string]: any }>(
  data: T,
  countToTrim: number
): T => {
  if (Array.isArray(data)) {
    return data.map((item) => trimEdges(item, countToTrim)) as any;
  }

  if (typeof data === 'object' && data !== null) {
    if ('edges' in data && Array.isArray(data.edges)) {
      return {
        ...data,
        edges: data.edges.slice(0, countToTrim),
      } as T;
    }

    const updatedData: { [key: string]: any } = {};

    Object.keys(data).forEach((key) => {
      updatedData[key] = trimEdges(data[key], countToTrim);
    });

    return updatedData as T;
  }

  return data;
};
