import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetQualifiedReferralConfirmationSettingsColumns } from './hooks/useGetConfirmationSettingsColumns';
import { QualifiedReferralConfirmationSettingData } from '../../queries/generated/QualifiedReferralConfirmationSettingData';
import { useOpenModal } from './hooks/useOpenModal';

interface Props {
  confirmationSettingsList?: Array<QualifiedReferralConfirmationSettingData>;
}

export const QualifiedReferralConfirmationSettingsTable: FC<Props> = ({
  confirmationSettingsList,
}) => {
  const { columns } = useGetQualifiedReferralConfirmationSettingsColumns();
  const { openModal } = useOpenModal();

  return (
    <BlockTable
      onClickOnRow={(qualifiedReferralConfirmationSetting, initiatorId) =>
        openModal({ qualifiedReferralConfirmationSetting, initiatorId })
      }
      columns={columns}
      data={confirmationSettingsList}
      isFullScreenTable
    />
  );
};
