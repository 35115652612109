import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import { useSearchContext } from 'src/contexts/SearchContext';
import styles from './SearchInput.module.scss';

export type OnSearchInputSubmit<T> = (values: T) => void;

interface Props<T extends { [key: string]: string }> {
  name: keyof T;
  placeholder: string;
  onSubmit: OnSearchInputSubmit<T>;
}

const SearchInput = <T extends { [key: string]: string }>({
  name,
  placeholder,
  onSubmit,
}: Props<T>) => {
  const { searchValue, setSearchValue, saveSearch, prevSearchValue } =
    useSearchContext();
  const handleChange = (value: string) => {
    setSearchValue(value.trim());
  };

  const handleSubmit: OnSearchInputSubmit<T> = (value) => {
    const trimmedValue = value[name].trim();

    if (prevSearchValue !== trimmedValue) {
      onSubmit(value);
      saveSearch(trimmedValue);
    }
  };

  return (
    <Formik
      initialValues={{ [name]: searchValue } as T}
      onSubmit={handleSubmit}
    >
      <Form role="search">
        <Input.Search
          name={String(name)}
          placeholder={placeholder}
          wrapperClassName={styles.search}
          onChange={handleChange}
        />
      </Form>
    </Formik>
  );
};

export default SearchInput;
