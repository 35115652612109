import React, { FC, useCallback } from 'react';
import { CardIcon } from 'ui/Card/CardIcon';
import classnames from 'classnames';
import { RestrictionTypeName } from 'commonTypes';
import styles from './LastRestrictionItem.module.scss';
import { useRestrictionOpenModal } from '../../hooks/useRestrictionOpenModal';
import {
  getRestrictionIconByType,
  getStatusAndIconConfig,
  restrictionTypeMap,
} from '../../helpers';
import { formatDate } from '../../../../../../utils/formatDate';
import { LastRestriction } from '../../types';

interface Props {
  restriction?: LastRestriction | null;
  type: RestrictionTypeName;
}

const LastRestrictionItem: FC<Props> = ({ restriction, type }) => {
  const { openRestrictionItem } = useRestrictionOpenModal<LastRestriction>();

  const title = restrictionTypeMap[type];
  const icon = getRestrictionIconByType(type);
  const {
    status,
    iconConfig: { iconColor },
  } = getStatusAndIconConfig(restriction);
  const id = restriction?.id || null;

  const openRestriction = useCallback(() => {
    if (!restriction) {
      return;
    }

    openRestrictionItem({ restriction });
  }, [restriction, openRestrictionItem]);

  return (
    <div
      onClick={openRestriction}
      className={classnames(
        styles.restriction,
        styles[iconColor],
        !id && styles.disabled
      )}
    >
      <CardIcon className={styles.icon} config={{ icon, iconColor }} />
      <div>
        <div>{title}</div>
        <div className={styles.status}>{`${status} ${
          restriction?.canceledAt ? formatDate(restriction?.canceledAt) : ''
        }`}</div>
      </div>
    </div>
  );
};

export default LastRestrictionItem;
