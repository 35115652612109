import React, { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import Error from 'ui/Error';
import { Form, Formik } from 'formik';
import { ApolloError } from '@apollo/client';
import { getUnBanTitle } from 'commonComponents/UnBan/helpers';
import styles from './UnBan.module.scss';
import { formatDate } from '../../utils/formatDate';

interface Props {
  signInRestrictionExpiredAt?: string | null;
  signInRestrictionReason?: string | null;
  onCancelSignInRestriction: () => void;
  error?: ApolloError;
  canCancel: boolean;
  isProtected: boolean;
  isLoading: boolean;
}

const UnBan: FC<Props> = ({
  signInRestrictionExpiredAt,
  signInRestrictionReason,
  onCancelSignInRestriction,
  error,
  canCancel,
  isProtected,
  isLoading,
}) => {
  const date = signInRestrictionExpiredAt
    ? formatDate(signInRestrictionExpiredAt)
    : null;

  return (
    <Formik
      initialValues={[]}
      onSubmit={onCancelSignInRestriction}
      enableReinitialize
    >
      <Form>
        {error && <Error error={error} />}
        <div className={styles.content}>
          <h2>{getUnBanTitle({ date, isProtected })}</h2>
          {signInRestrictionReason && (
            <div className={styles.reason}>{signInRestrictionReason}</div>
          )}
        </div>
        <Button
          theme={ButtonTheme.Important}
          type="submit"
          isLoading={isLoading}
          disabled={!canCancel}
        >
          Разблокировать
        </Button>
      </Form>
    </Formik>
  );
};

export default UnBan;
