import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import errorToast from 'ui/Toast/ErrorToast';
import { ListAllOperations } from 'generatedGraphql';
import { RewardTariffType } from 'commonTypes';
import { useCreatePartnerReferralLinkMediaItem } from 'src/queries/generated/CreatePartnerReferralLinkMediaItem';
import { CreatePartnerReferralLinkValues } from '../validation';
import { FieldName } from '../const';

interface UseCreatePartnerReferralLinkResult {
  onCreateReferralLinkSubmitHandler: (
    partnerId: string,
    values: CreatePartnerReferralLinkValues
  ) => void;
  createPartnerReferralLinkLoading: boolean;
}

export const useCreatePartnerReferralLink = (
  isFullScreenTable: boolean
): UseCreatePartnerReferralLinkResult => {
  const { dispatch } = useContext(ModalContext);

  const onCompleted = () => {
    toast.success('Медиа элемент создан');

    dispatch({
      type: ModalActions.Close,
    });
  };

  const onError = (error: ApolloError) => {
    errorToast({
      header: error.name,
      text: error.message,
      toastId: 'create-partner-referral-link-error',
    });
  };

  const [
    createPartnerReferralLink,
    { loading: createPartnerReferralLinkLoading },
  ] = useCreatePartnerReferralLinkMediaItem({
    onCompleted,
    onError,
    refetchQueries: [
      isFullScreenTable
        ? ListAllOperations.Query.GetPartnerReferralLinkMediaItems
        : ListAllOperations.Query.GetPartnerReferralLinkMediaItemsBlock,
    ],
  });

  const onCreateReferralLinkSubmitHandler = (
    partnerId: string,
    values: CreatePartnerReferralLinkValues
  ) => {
    createPartnerReferralLink({
      variables: {
        input: {
          partnerId,
          referralLinkMediaItem: {
            name: values[FieldName.MediaItemName],
            code: values[FieldName.Code],
            mediaCampaignId: values[FieldName.MediaCampaignId],
            mediaItemRewardTariff: {
              ...(values[FieldName.PaymentModel] === RewardTariffType.Cpa
                ? {
                    cpaRewardTariff: {
                      id: values[FieldName.CPATariffId],
                      fallbackRewardTariffId:
                        values[FieldName.FallbackTariffId],
                    },
                  }
                : {
                    revShareRewardTariff: {
                      id: values[FieldName.RevShareTariffId],
                    },
                  }),
            },
          },
        },
      },
    });
  };

  return {
    onCreateReferralLinkSubmitHandler,
    createPartnerReferralLinkLoading,
  };
};
