import { useCallback, useMemo } from 'react';
import errorToast from 'ui/Toast/ErrorToast';
import { useGetCompanyAffiliateReferralLinkMediaItems } from '../queries/generated/GetCompanyAffiliateReferralLinkMediaItems';
import { getCompanyAffiliateReferralLinksOptions } from '../helpers/getCompanyAffiliateReferralLinksOptions';
import { nbsp } from '../../../const';

/*
 ** Temporary solution for search without query in dropdown
 ** TODO: Change to 10 after complete task https://eyeconweb.atlassian.net/browse/APN-1196
 */
const COUNT_PER_PAGE = 1000;

interface UseGetCompanyAffiliateReferralLinksParams {
  companyId: string;
  companyAffiliateId: string;
  labelWidth?: number;
}

export const useGetCompanyAffiliateReferralLinks = ({
  companyId,
  companyAffiliateId,
  labelWidth,
}: UseGetCompanyAffiliateReferralLinksParams) => {
  const { data, loading, error, fetchMore } =
    useGetCompanyAffiliateReferralLinkMediaItems({
      variables: {
        companyId,
        companyAffiliateId,
        first: COUNT_PER_PAGE,
      },
      onError: (getReferralLinksError) => {
        errorToast({
          header: `При${nbsp}загрузке реферальных ссылок произошла ошибка`,
          text: getReferralLinksError.message,
          toastId: 'get-company-affiliate-referral-links-error',
        });
      },
    });

  const hasNextPage =
    data?.company.affiliate.referralLinkMediaItems.pageInfo.hasNextPage;
  const endCursor =
    data?.company.affiliate.referralLinkMediaItems.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [endCursor, fetchMore]
  );

  const referralLinksOptions = useMemo(
    () =>
      getCompanyAffiliateReferralLinksOptions(
        data?.company.affiliate.referralLinkMediaItems.edges,
        labelWidth
      ),
    [data?.company.affiliate.referralLinkMediaItems.edges, labelWidth]
  );

  return {
    referralLinksOptions,
    loading,
    error,
    fetchMoreCallback,
    hasNextPage,
  };
};
