import { FC } from 'react';
import Input from 'ui/Input';
import { Dropdown } from 'ui/Dropdown';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { rolesOptions } from '../../../const';

interface Props {
  isEdit?: boolean;
}

const AdminForm: FC<Props> = ({ isEdit }) => (
  <FormRowsWrapper>
    <FormRow isWide>
      <Input name="email" label="Почта" disabled={isEdit} />
    </FormRow>

    <FormRow>
      <Input name="firstName" label="Имя" />
      <Input name="lastName" label="Фамилия" />
    </FormRow>

    <FormRow isWide>
      <Dropdown label="Роли" options={rolesOptions} name="roles" isCheckbox />
    </FormRow>
  </FormRowsWrapper>
);

export default AdminForm;
