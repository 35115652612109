import { MediaItemTypeName } from 'commonTypes';
import { useMemo } from 'react';
import { useGetReferralLinkMediaItemsByTableSize } from '../components/ReferralLinkMediaItemTable/hooks/useGetReferralLinkMediaItemsByTableSize';
import { useGetPromoCodeMediaItemsByTableSize } from '../components/PromoCodeMediaItemTable/hooks/useGetPromoCodeMediaItemsByTableSize';
import { useGetPromoCodeMediaItemsColumns } from '../components/PromoCodeMediaItemTable/hooks/useGetPromoCodeMediaItemsColumns';
import { useGetReferralLinkMediaItemsColumns } from '../components/ReferralLinkMediaItemTable/hooks/useGetReferralLinkMediaItemsColumns';

interface UseGetMediaItemsDataParams {
  mediaItemsType: MediaItemTypeName;
  partnerId: string;
  isFullScreenTable: boolean;
}

export const useGetMediaItemsData = ({
  mediaItemsType,
  partnerId,
  isFullScreenTable,
}: UseGetMediaItemsDataParams) => {
  const {
    referralLinkMediaItemsData,
    fetchMoreCallback: fetchMoreReferralLink,
    loadPartnerReferralLinkMediaItems,
    loading: loadingReferralLink,
    error: errorReferralLink,
    refetch: refetchReferralLink,
  } = useGetReferralLinkMediaItemsByTableSize({
    isFullScreenTable,
    partnerId,
  });

  const {
    promoCodeMediaItemsData,
    fetchMoreCallback: fetchMorePromoCode,
    loadPartnerPromoCodeMediaItems,
    loading: loadingPromoCode,
    error: errorPromoCode,
    refetch: refetchPromoCode,
  } = useGetPromoCodeMediaItemsByTableSize({
    isFullScreenTable,
    partnerId,
  });

  const isPromoCode = mediaItemsType === MediaItemTypeName.PromoCode;
  const fetchMore = isPromoCode ? fetchMorePromoCode : fetchMoreReferralLink;
  const error = isPromoCode ? errorPromoCode : errorReferralLink;
  const loading = isPromoCode ? loadingPromoCode : loadingReferralLink;
  const refetch = isPromoCode ? refetchPromoCode : refetchReferralLink;

  const loadPartnerMediaItem = isPromoCode
    ? loadPartnerPromoCodeMediaItems
    : loadPartnerReferralLinkMediaItems;

  const mediaItemsData = isPromoCode
    ? promoCodeMediaItemsData
    : referralLinkMediaItemsData;

  const mediaItems = useMemo(
    () => mediaItemsData?.edges.map(({ node }) => node),
    [mediaItemsData]
  );

  const hasNextPage = mediaItemsData?.pageInfo.hasNextPage;

  const { columns: promoCodeColumns } =
    useGetPromoCodeMediaItemsColumns(isFullScreenTable);
  const { columns: referralLinkColumns } =
    useGetReferralLinkMediaItemsColumns(isFullScreenTable);

  const columns = isPromoCode ? promoCodeColumns : referralLinkColumns;

  return {
    hasNextPage,
    mediaItems,
    mediaItemsData,
    refetch,
    loadPartnerMediaItem,
    loading,
    error,
    fetchMore,
    columns,
  };
};
