import { FC } from 'react';
import ClientHeader from 'commonComponents/ClientHeader';
import usePlayerHeaderData from './hooks/usePlayerHeaderData';
import { useMarkers } from './hooks/useMarkers';
import { PlayerBasicData } from '../../../../queries/generated/PlayerBasicData';

interface Props {
  player: PlayerBasicData;
}

export const PlayerHeader: FC<Props> = ({ player }) => {
  const { id, name, defaultName, note } = player;

  const {
    canResetPlayerName,
    canUpdateNote,
    canViewPlayerNote,
    updateNoteHandler,
    resetPlayerName,
    updateNoteResult,
    updateNoteLoading,
  } = usePlayerHeaderData({ id, name, defaultName });

  const markers = useMarkers(player);

  return (
    <ClientHeader
      updateNoteHandler={updateNoteHandler}
      updateNoteResult={updateNoteResult}
      updateNoteLoading={updateNoteLoading}
      noteText={note?.text}
      canUpdateNote={canUpdateNote}
      canResetClientName={canResetPlayerName}
      canViewClientNote={canViewPlayerNote}
      clientId={id}
      clientName={player.name}
      resetClientName={resetPlayerName}
      markers={markers}
    />
  );
};
