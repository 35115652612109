import { OwnerTypeName } from 'commonTypes';
import { ownerTypeNameMap } from 'src/const';
import { GetPostbackDisabledNotificationData } from '../../../queries/generated/GetPostbackDisabledNotificationData';

export const getOwnerTypeByTypeName = (
  owner: GetPostbackDisabledNotificationData['referralLinkMediaItem']['owner']
) => {
  if (owner.__typename === OwnerTypeName.CompanyAffiliate) {
    return ownerTypeNameMap[OwnerTypeName.Company];
  }

  return ownerTypeNameMap[owner.__typename];
};
