import { useModalManageData } from 'ui/Modal';
import { useGetCompanyAdditionalData } from '../../../queries/generated/GetCompanyAdditionalData';

interface UseGetCompanyIdentitiesParams {
  id: string;
}

interface UseGetCompanyIdentitiesResult {
  email?: string;
}

export const useGetCompanyIdentities = ({
  id,
}: UseGetCompanyIdentitiesParams): UseGetCompanyIdentitiesResult => {
  const { data, loading, error } = useGetCompanyAdditionalData({
    variables: {
      id,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-company-additional-data',
    },
    hasData: !!data,
  });

  return {
    email: data?.company.email,
  };
};
