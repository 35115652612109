import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { QualifiedReferralConfirmationSettingData } from '../../../queries/generated/QualifiedReferralConfirmationSettingData';
import { QualifiedReferralConfirmationSettingItem } from '../../QualifiedReferralConfirmationSettingItem';

interface OpenModalParams {
  qualifiedReferralConfirmationSetting: QualifiedReferralConfirmationSettingData;
  initiatorId: string;
}

interface OpenModelResult {
  openModal: (args: OpenModalParams) => void;
}

export const useOpenModal = (): OpenModelResult => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    ({
      qualifiedReferralConfirmationSetting,
      initiatorId,
    }: OpenModalParams) => {
      const { id } = qualifiedReferralConfirmationSetting;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Настройки подтверждения',
          content: (
            <QualifiedReferralConfirmationSettingItem
              confirmationSettingId={id}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch]
  );

  return {
    openModal,
  };
};
