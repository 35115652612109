import { FC } from 'react';
import { useParams } from 'react-router';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { OutgoingMoneyTransfersTable } from '../../components/OutgoingMoneyTransfersBlock/components/OutgoingMoneyTransfersTable';
import { useGetOutgoingMoneyTransfersColumns } from '../../components/OutgoingMoneyTransfersBlock/components/OutgoingMoneyTransfersTable/hooks/useGetOutgoingMoneyTransfersColumns';

export const OutgoingMoneyTransfers: FC = () => {
  const { columns } = useGetOutgoingMoneyTransfersColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <OutgoingMoneyTransfersTable
        partnerId={id}
        columns={columns}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </PartnerPageContainer>
  );
};
