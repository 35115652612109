import { Sum } from 'generatedGraphql';
import { SET_DATA } from '../actionTypes';

interface Payload {
  paymentPaidSum?: Sum | null;
  paymentId?: string | null;
}

export interface SetData {
  type: typeof SET_DATA;
  payload: Payload;
}

export const setDataAction = (payload: Payload): SetData => ({
  type: SET_DATA,
  payload,
});
