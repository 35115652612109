import { ActionButton, ButtonType } from 'ui/ActionButton';
import { FC, KeyboardEvent, ReactElement } from 'react';
import styles from './SelectedFilterMarker.module.scss';

interface Props {
  filterTitle: string;
  filterValue?: string | ReactElement | null;
  isOpened: boolean;
  handleDeleteFilter: () => void;
  inputValue: string;
  setInputValue: (v: string) => void;
  submitInput: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const SelectedFilterMarker: FC<Props> = ({
  filterTitle,
  filterValue,
  isOpened,
  handleDeleteFilter,
  inputValue,
  setInputValue,
  submitInput,
}) => (
  <>
    <div className={styles.container}>
      <div>{`${filterTitle}:`}</div>
      {filterValue && !isOpened && (
        <>
          <div className={styles.selectedFilterValue}>{filterValue}</div>
          <ActionButton
            dataTestId="deleteFilter"
            onClick={handleDeleteFilter}
            actionType={ButtonType.Close}
          />
        </>
      )}
    </div>
    {isOpened && (
      <input
        value={inputValue}
        onKeyDown={submitInput}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={({ target: { value } }) => setInputValue(value)}
        className={styles.input}
        type="text"
      />
    )}
  </>
);
