import { FC } from 'react';
import { MenuGroupItemData, MenuItemData } from 'commonComponents/Menu/types';
import { MenuRoutes } from './components';
import styles from './styles/Menu.module.scss';

interface Props {
  links: Array<MenuItemData | MenuGroupItemData>;
}

export const Menu: FC<Props> = ({ links }) => (
  <nav data-testid="menu" className={styles.sidebarWrapper}>
    <div className={styles.sidebar}>
      <MenuRoutes subMenuClassName={styles.subMenu} links={links} />
    </div>
  </nav>
);
