import { NumberFormatValues } from 'react-number-format';
import { RangeZeroToFifteen } from '../types';

/*
 * Custom maxLength function for InputNumber and InputSum
 * because NumericFormat doesn't support maxLength correctly
 * if formatting is enabled. Use with 'isAllowed' prop.
 * @params {values} - NumberFormatValues
 * @params {maxLength} - number (max - 15)
 * @example
 * isAllowed: (values) => setNumericFormatMaxLength(values, maxLength),
 */
export const setNumericFormatMaxLength = (
  values: NumberFormatValues,
  maxLength: RangeZeroToFifteen
): boolean => {
  const { floatValue } = values;
  const valueLength = floatValue?.toString().length;

  return valueLength === undefined || valueLength <= maxLength;
};
