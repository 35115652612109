import { FC } from 'react';
import { paymentProcessorMap, paymentStatusMap } from 'src/const';
import { PaymentStatus } from 'generatedGraphql';
import { DataCard } from 'commonComponents/DataCard';
import { useAuth } from 'commonComponents/Auth';
import { CopyModalRow } from 'ui/CopyModalRow';
import { PaymentData } from '../../queries/generated/PaymentData';
import { formatSumWithCurrency } from '../../../../../../../../utils/accounting';
import { checkIsButtonsVisible, getStatusIcon } from './helpers';
import {
  DateFormat,
  formatDate,
} from '../../../../../../../../utils/formatDate';
import ButtonsUpdatePayment from '../ButtonsUpdatePayment/ButtonsUpdatePayment';

interface Props {
  payment: PaymentData;
}

const WithdrawalPayment: FC<Props> = ({ payment }) => {
  const {
    paidSum,
    externalPaymentId,
    createdAt,
    updatedAt,
    sum,
    processor,
    id,
    error,
    status,
  } = payment;

  const auth = useAuth();

  const getStatusText = (paymentStatus: PaymentStatus) => {
    if (paymentStatus === PaymentStatus.Failed && error) {
      return `Ошибка: ${error.code}`;
    }

    return paymentStatusMap[paymentStatus];
  };

  const isBoldPaidSum = paidSum?.amount !== sum.amount;

  const { isAcceptButtonVisible, isDeclineButtonVisible } =
    checkIsButtonsVisible(auth, payment.status);

  return (
    <DataCard
      header={{
        leftTitle: paymentProcessorMap[processor] || processor,
        rightTitle: formatSumWithCurrency(sum.amount, sum.currency),
        leftSubTitle: `#${id}`,
      }}
      withBorder
      statusConfig={{
        status: getStatusText(status),
        text: error?.message,
        iconConfig: getStatusIcon(status),
      }}
      rows={[
        paidSum && {
          title: 'Фактическая сумма',
          value: formatSumWithCurrency(paidSum.amount, paidSum.currency),
          isBoldValue: isBoldPaidSum,
        },
        externalPaymentId && {
          title: 'Внешний ID',
          value: (
            <CopyModalRow
              value={externalPaymentId}
              toastText="Внешний ID скопирован"
            />
          ),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
        {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        (isAcceptButtonVisible || isDeclineButtonVisible) && (
          <ButtonsUpdatePayment
            payment={payment}
            isAcceptButtonVisible={isAcceptButtonVisible}
            isDeclineButtonVisible={isDeclineButtonVisible}
          />
        )
      }
    />
  );
};

export default WithdrawalPayment;
