/**
 * Parses raw headers string into a Headers object.
 * Replaces occurrences of \r\n and \n followed by at least one space or horizontal tab with a space
 * according to the HTTP specification (see https://tools.ietf.org/html/rfc7230#section-3.2).
 *
 * @param {string} rawHeaders - The raw HTTP headers string.
 * @return {Headers} A Headers object with the parsed headers.
 */
export const parseHeaders = (rawHeaders: string): Headers => {
  const headers = new Headers();
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');

  preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
    const [key, ...valueParts] = line.split(':');

    if (key) {
      headers.append(key.trim(), valueParts.join(':').trim());
    }
  });

  return headers;
};
