import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Dropdown } from 'ui/Dropdown';
import FormRow from 'ui/FormRow';
import { AffiliateType } from 'generatedGraphql';
import { RadioButton } from 'ui/RadioButton';
import { PartnersDropdown } from 'commonComponents/PartnersDropdown';
import { CompaniesDropdown } from 'commonComponents/CompaniesDropdown';
import Input from 'ui/Input';
import Error from 'ui/Error';
import FormWarning from 'ui/FormWarning/FormWarning';
import { nbsp } from 'src/const';
import { CreateConfirmationSettingValues } from './types';
import { FieldName } from './const';
import styles from './CreateQualifiedReferralConfirmationSetting.module.scss';
import { ReferralLinkCodeDropdown } from './components/ReferralLinkCodeDropdown';
import { useCreateQualifiedReferralConfirmationSetting } from './hooks/useCreateQualifiedReferralConfirmationSetting';
import { validate } from './validation';
import { getConfirmationSettingTypeOptions } from '../../helpers';
import { useCheckHasAffiliateConfirmationSettings } from './hooks/useCheckHasAffiliateConfirmationSettings';
import {
  handleChangeCompanyDropdown,
  handleChangePartnerDropdown,
  partialResetForm,
} from './heplers';

export const CreateQualifiedReferralConfirmationSetting: FC = () => {
  const {
    checkHasPartnerConfirmationSettings,
    hasAffiliateConfirmationSetting,
    checkHasCompanyConfirmationSettings,
  } = useCheckHasAffiliateConfirmationSettings();

  const { createQualifiedReferralConfirmationSetting, loading, error } =
    useCreateQualifiedReferralConfirmationSetting();

  const initialValues: CreateConfirmationSettingValues = {
    [FieldName.settingType]: null,
    [FieldName.partnerId]: '',
    [FieldName.companyId]: '',
    [FieldName.affiliateType]: null,
    [FieldName.companyReferralCode]: null,
    [FieldName.partnerReferralCode]: null,
    [FieldName.comment]: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createQualifiedReferralConfirmationSetting}
      enableReinitialize
      validateOnChange={false}
      validate={validate}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Dropdown
                name={FieldName.settingType}
                label="Тип настройки"
                options={getConfirmationSettingTypeOptions()}
              />
            </FormRow>
            <div className={styles.titleCheckbox}>Тип аккаунта</div>
            <FormRow isWide>
              <RadioButton
                onChange={() =>
                  partialResetForm(setFieldValue, resetForm, values)
                }
                name={FieldName.affiliateType}
                value={AffiliateType.Partner}
              >
                Партнёр
              </RadioButton>
            </FormRow>
            <FormRow isWide>
              <RadioButton
                onChange={() =>
                  partialResetForm(setFieldValue, resetForm, values)
                }
                name={FieldName.affiliateType}
                value={AffiliateType.Company}
              >
                Компания
              </RadioButton>
            </FormRow>
            {values.affiliateType === AffiliateType.Partner && (
              <>
                <FormRow isWide>
                  <PartnersDropdown
                    label="Партнёр"
                    name={FieldName.partnerId}
                    onChange={(partnerId) =>
                      handleChangePartnerDropdown({
                        setFieldValue,
                        partnerId,
                        checkHasPartnerConfirmationSettings,
                      })
                    }
                  />
                </FormRow>
                <FormRow isWide>
                  <ReferralLinkCodeDropdown
                    isDisabled={!values.partnerId}
                    affiliateType={values.affiliateType}
                    affiliateId={values.partnerId}
                  />
                </FormRow>
              </>
            )}
            {values.affiliateType === AffiliateType.Company && (
              <>
                <FormRow isWide>
                  <CompaniesDropdown
                    label="Компания"
                    name={FieldName.companyId}
                    onChange={(companyId) =>
                      handleChangeCompanyDropdown({
                        setFieldValue,
                        companyId,
                        checkHasCompanyConfirmationSettings,
                      })
                    }
                  />
                </FormRow>
                <FormRow isWide>
                  <ReferralLinkCodeDropdown
                    isDisabled={!values.companyId}
                    affiliateType={values.affiliateType}
                    affiliateId={values.companyId}
                  />
                </FormRow>
              </>
            )}
            <FormRow isWide>
              <Input.Textarea
                name={FieldName.comment}
                label="Комментарий"
                placeholder="Введите комментарий"
                maxLength={500}
              />
            </FormRow>
          </FormRowsWrapper>
          {(values.companyId || values.partnerId) &&
            hasAffiliateConfirmationSetting && (
              <FormWarning subTitle="Имеется настройка">
                {`Для${nbsp}выбранного аккаунта уже существуют настройки.
                Пожалуйста, проверьте список настроек перед${nbsp}созданием новой.`}
              </FormWarning>
            )}
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
          >
            Создать
          </Button>
        </Form>
      )}
    </Formik>
  );
};
