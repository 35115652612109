import { FormikErrors } from 'formik';
import { UpdateCpaRewardTariffTitleInput } from 'generatedGraphql';

export const validate = (
  values: UpdateCpaRewardTariffTitleInput
): FormikErrors<UpdateCpaRewardTariffTitleInput> => {
  const errors: FormikErrors<UpdateCpaRewardTariffTitleInput> = {};

  if (!values.title) {
    errors.title = 'Введите название';
  }

  return errors;
};
