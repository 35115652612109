import { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';

export const useValidation = (name: string) => {
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const [isValidationOnSubmitCalled, setValidationOnSubmitCalled] =
    useState(false);

  const { values, status, setStatus, isSubmitting } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { touched, error } = meta;
  const { setError, setTouched } = helpers;

  const shouldValidate = error && touched;
  const isDropdownFieldEmpty = Array.isArray(value) && value.length === 0;
  const isFieldEmpty =
    value === '' || value === 0 || value === undefined || isDropdownFieldEmpty;

  // Is validation fired on submit
  useEffect(() => {
    if (isSubmitting) {
      setValidationOnSubmitCalled(true);
    }
  }, [isSubmitting]);

  // Toggle visibility of error hint
  useEffect(() => {
    setTooltipVisibility(!!shouldValidate);
  }, [shouldValidate]);

  // Hide hints when user start typing in field or click on submit
  useEffect(() => {
    setTooltipVisibility(false);
  }, [values, isSubmitting]);

  // Hide hints when field gets focus
  useEffect(() => {
    const isFocusedAnotherFieldWithError =
      status && status.focusedFieldName !== name && status.hasError;
    const isFieldNotEmpty = status && status.isEmpty === false;

    if (
      isFocusedAnotherFieldWithError &&
      (isValidationOnSubmitCalled || isFieldNotEmpty)
    ) {
      setTooltipVisibility(false);
    }
  }, [isValidationOnSubmitCalled, name, status]);

  // Show hint on focus and set state of active field
  const handleFocus = () => {
    setStatus({
      focusedFieldName: name,
      isEmpty: isFieldEmpty,
      hasError: !!error,
    });

    if (error && touched) {
      setTooltipVisibility(true);
    }
  };

  // Reset touched param of empty field for hide error (before submit validation)
  const handleBlur = () => {
    if (isFieldEmpty && !isValidationOnSubmitCalled) {
      setTouched(false);
    }
  };

  // Reset error and hide hint on change value
  const handlerChange = () => {
    if (!shouldValidate) {
      return;
    }

    setTooltipVisibility(false);

    /* Update error state is happens immediately and tooltip collapses quickly
     ** without animation. To prevent it - need to add delay before hiding tooltip.
     */
    setTimeout(() => {
      setError(undefined);
    }, 100);
  };

  return {
    isTooltipVisible,
    handleFocus,
    handlerChange,
    handleBlur,
  };
};
