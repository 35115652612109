import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type DeclineWithdrawalPaymentVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeclinePaymentInput;
}>;


export type DeclineWithdrawalPayment = { __typename: 'Mutation', declinePayment: { __typename: 'DeclinePaymentPayload', payment: { __typename: 'Payment', id: string, status: SchemaTypes.PaymentStatus, updatedAt: string } } };


export const DeclineWithdrawalPaymentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeclineWithdrawalPayment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeclinePaymentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"declinePayment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type DeclineWithdrawalPaymentMutationFn = Apollo.MutationFunction<DeclineWithdrawalPayment, DeclineWithdrawalPaymentVariables>;

/**
 * __useDeclineWithdrawalPayment__
 *
 * To run a mutation, you first call `useDeclineWithdrawalPayment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineWithdrawalPayment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineWithdrawalPayment, { data, loading, error }] = useDeclineWithdrawalPayment({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineWithdrawalPayment(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineWithdrawalPayment, DeclineWithdrawalPaymentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeclineWithdrawalPayment, DeclineWithdrawalPaymentVariables>(DeclineWithdrawalPaymentDocument, options);
      }
export type DeclineWithdrawalPaymentHookResult = ReturnType<typeof useDeclineWithdrawalPayment>;
export type DeclineWithdrawalPaymentMutationResult = Apollo.MutationResult<DeclineWithdrawalPayment>;
export type DeclineWithdrawalPaymentMutationOptions = Apollo.BaseMutationOptions<DeclineWithdrawalPayment, DeclineWithdrawalPaymentVariables>;