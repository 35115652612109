import { Option } from 'ui/Dropdown';
import { useFormikContext } from 'formik';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

interface useSetFreespinGameNameResult {
  onFreespinGameChangeHandler: (
    gamesOptions: Array<Option> | undefined,
    gameId: string
  ) => void;
}

export const useSetFreespinGameName = (): useSetFreespinGameNameResult => {
  const { setFieldValue } = useFormikContext<CreatePromoCodeValues>();

  const onFreespinGameChangeHandler = (
    gamesOptions: Array<Option> | undefined,
    gameId: string
  ) => {
    if (!gamesOptions) {
      return;
    }

    const currentGame = gamesOptions.find((game) => game.id === gameId);

    if (currentGame) {
      setFieldValue(FieldName.FreespinGameName, currentGame.label);
    }
  };

  return {
    onFreespinGameChangeHandler,
  };
};
