import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPaymentProcessorsVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetPaymentProcessors = { __typename: 'Query', paymentProcessors: Array<CommonType.PaymentProcessor> };


export const GetPaymentProcessorsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPaymentProcessors"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentProcessors"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPaymentProcessors__
 *
 * To run a query within a React component, call `useGetPaymentProcessors` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentProcessors` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentProcessors({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentProcessors(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentProcessors, GetPaymentProcessorsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPaymentProcessors, GetPaymentProcessorsVariables>(GetPaymentProcessorsDocument, options);
      }
export function useGetPaymentProcessorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentProcessors, GetPaymentProcessorsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPaymentProcessors, GetPaymentProcessorsVariables>(GetPaymentProcessorsDocument, options);
        }
export function useGetPaymentProcessorsSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPaymentProcessors, GetPaymentProcessorsVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPaymentProcessors, GetPaymentProcessorsVariables>(GetPaymentProcessorsDocument, options);
        }
export type GetPaymentProcessorsHookResult = ReturnType<typeof useGetPaymentProcessors>;
export type GetPaymentProcessorsLazyQueryHookResult = ReturnType<typeof useGetPaymentProcessorsLazyQuery>;
export type GetPaymentProcessorsSuspenseQueryHookResult = ReturnType<typeof useGetPaymentProcessorsSuspenseQuery>;
export type GetPaymentProcessorsQueryResult = Apollo.QueryResult<GetPaymentProcessors, GetPaymentProcessorsVariables>;