import { FC } from 'react';
import { Currency } from 'commonTypes';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';
import styles from './LimitHeader.module.scss';

const LIMIT_EXHAUSTED = 'Исчерпан';

interface Props {
  label: string;
  limitAmount: number;
  withdrawalsAmount: number;
  limitCurrency: Currency;
}

const LimitHeader: FC<Props> = ({
  label,
  limitAmount,
  withdrawalsAmount,
  limitCurrency,
}) => {
  const remainingLimit = limitAmount - withdrawalsAmount;

  return (
    <>
      {label}:{' '}
      <span className={styles.limitHeader}>
        {remainingLimit <= 0
          ? LIMIT_EXHAUSTED
          : formatSumWithCurrency(remainingLimit, limitCurrency)}
      </span>
    </>
  );
};

export default LimitHeader;
