import { toast } from 'react-toastify';
import { ErrorContent } from 'ui/Error';

interface Props {
  header: string;
  text: string;
  toastId: string;
}

const errorToast = ({ header, text, toastId }: Props): void => {
  toast.error(<ErrorContent header={header} text={text} />, {
    toastId,
  });
};

export default errorToast;
