import { RewardTariffType } from 'commonTypes';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';

export const getTariffType = (referralLinkDate?: ReferralLinkData | null) => {
  if (referralLinkDate?.revShareTariff.id && referralLinkDate?.cpaTariff?.id) {
    return RewardTariffType.Cpa;
  }

  if (referralLinkDate?.revShareTariff.id) {
    return RewardTariffType.RevShare;
  }

  return null;
};
