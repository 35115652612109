import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useGetRevShareRewardTariffsLazyQuery } from '../../../queries/generated/GetRevShareRewardTariffs';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../../../utils/getCountByWindowHeight';

const cellHeight = Heights.defaultCellHeight;

export const useRevShareRewardTariffs = () => {
  const [loadRevShareTariffs, { data, loading, error, fetchMore, refetch }] =
    useGetRevShareRewardTariffsLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
      },
    });

  useBlockComponentState({
    loadData: loadRevShareTariffs,
    loading,
    error,
  });

  const revShareRewardTariffs = useMemo(
    () => data?.revShareRewardTariffs?.edges.map(({ node }) => node),
    [data?.revShareRewardTariffs?.edges]
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.revShareRewardTariffs?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.revShareRewardTariffs?.pageInfo.endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.revShareRewardTariffs?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return { refetchRevShareRewardTariffs: refetch, revShareRewardTariffs };
};
