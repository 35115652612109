import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type SuspectQualifiedReferralVariables = SchemaTypes.Exact<{
  input: SchemaTypes.SuspectQualifiedReferralInput;
}>;


export type SuspectQualifiedReferral = { __typename: 'Mutation', suspectQualifiedReferral: { __typename: 'SuspectQualifiedReferralPayload', referral: { __typename: 'QualifiedReferral', id: string, suspectState: SchemaTypes.SuspectState } } };


export const SuspectQualifiedReferralDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SuspectQualifiedReferral"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuspectQualifiedReferralInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suspectQualifiedReferral"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referral"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"suspectState"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SuspectQualifiedReferralMutationFn = Apollo.MutationFunction<SuspectQualifiedReferral, SuspectQualifiedReferralVariables>;

/**
 * __useSuspectQualifiedReferral__
 *
 * To run a mutation, you first call `useSuspectQualifiedReferral` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspectQualifiedReferral` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspectQualifiedReferral, { data, loading, error }] = useSuspectQualifiedReferral({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuspectQualifiedReferral(baseOptions?: ApolloReactHooks.MutationHookOptions<SuspectQualifiedReferral, SuspectQualifiedReferralVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SuspectQualifiedReferral, SuspectQualifiedReferralVariables>(SuspectQualifiedReferralDocument, options);
      }
export type SuspectQualifiedReferralHookResult = ReturnType<typeof useSuspectQualifiedReferral>;
export type SuspectQualifiedReferralMutationResult = Apollo.MutationResult<SuspectQualifiedReferral>;
export type SuspectQualifiedReferralMutationOptions = Apollo.BaseMutationOptions<SuspectQualifiedReferral, SuspectQualifiedReferralVariables>;