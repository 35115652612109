import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { getFreespinType } from '../utils';
import FreespinItem from '../components/FreespinItem';
import { FreespinData } from '../components/FreespinsTable/queries/generated/FreespinData';

interface FreespinOpenModelType<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useFreespinOpenModal = <
  T extends object
>(): FreespinOpenModelType<T> => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    (freespin: T, initiatorId: string) => {
      const { id, createdAutomatically, promoCode } = freespin as FreespinData;
      const { title: subTitle, iconConfig } = getFreespinType(
        createdAutomatically,
        promoCode
      );

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle,
          content: <FreespinItem freespinId={id} />,
          iconConfig,
          initiatorId,
        },
      });
    },
    [dispatch]
  );

  return {
    openModal,
  };
};
