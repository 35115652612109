import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';

export const getReferralLinkMediaItem = (
  referralLinkData: ReferralLinkData
) => ({
  code: referralLinkData.code,
  name: referralLinkData.referralLinkName,
  mediaCampaignId: referralLinkData.mediaCampaignId,
  mediaItemRewardTariff: {
    ...(referralLinkData.cpaTariff
      ? {
          cpaRewardTariff: {
            fallbackRewardTariffId: referralLinkData.revShareTariff.id,
            id: referralLinkData.cpaTariff.id,
          },
        }
      : {
          revShareRewardTariff: {
            id: referralLinkData.revShareTariff.id,
          },
        }),
  },
});
