import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import { RewardTariffType } from 'commonTypes';
import { statisticTabs } from './const';

interface Props {
  currentTabId?: RewardTariffType;
  setTariffType: (type: RewardTariffType) => void;
}

export const StatisticTabs: FC<Props> = ({ currentTabId, setTariffType }) => {
  const handleTabChange = (id: RewardTariffType) => {
    setTariffType(id);
  };

  return (
    <Tabs
      tabs={statisticTabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
