import { FreespinStatus } from 'generatedGraphql';

export const DEFAULT_FREESPINS_COUNT = '50';
export const DEFAULT_WAGER_COUNT = 5;

export enum ColumnId {
  Id = 'id',
  Type = '__typename',
  Game = 'game',
  Count = 'count',
  Currency = 'currency',
  BonusWager = 'bonusWager',
  Status = 'status',
  MinAccountBalance = 'minAccountBalance',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const freespinStatusMap: {
  [key in FreespinStatus]: string;
} = {
  [FreespinStatus.Active]: 'Активированы',
  [FreespinStatus.Canceled]: 'Отменены',
  [FreespinStatus.Expired]: 'Истекли',
  [FreespinStatus.Pending]: 'В\u00a0ожидании',
  [FreespinStatus.Won]: 'Использованы',
  [FreespinStatus.Unknown]: 'Unknown',
};

export enum FreespinTypeTitle {
  ForRegistration = 'Фриспины за\u00a0регистрацию',
  FromCasino = 'Фриспины от\u00a0казино',
  ByPromoCode = 'Фриспины по\u00a0промокоду',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Type]: 'Тип',
  [ColumnId.Game]: 'Слот',
  [ColumnId.Count]: 'Кол-во',
  [ColumnId.Currency]: 'Валюта',
  [ColumnId.BonusWager]: 'Вейджер',
  [ColumnId.MinAccountBalance]: 'Минимальный баланс',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлен',
};

export const blockColumnsIds = [
  ColumnId.Type,
  ColumnId.Game,
  ColumnId.Count,
  ColumnId.Currency,
  ColumnId.BonusWager,
  ColumnId.Status,
  ColumnId.CreatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Type,
  ColumnId.Game,
  ColumnId.Count,
  ColumnId.Currency,
  ColumnId.BonusWager,
  ColumnId.MinAccountBalance,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
