import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useGetPlayerExternalMoneyTransfersBlockLazyQuery } from '../queries/generated/GetPlayerExternalMoneyTransfersBlock';
import { useGetPlayerExternalMoneyTransfersLazyQuery } from '../queries/generated/GetPlayerExternalMoneyTransfers';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetExternalMoneyTransfersByTableSize = (
  playerId: string,
  isFullScreenTable: boolean
) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPlayerExternalMoneyTransfers = isFullScreenTable
    ? useGetPlayerExternalMoneyTransfersLazyQuery
    : useGetPlayerExternalMoneyTransfersBlockLazyQuery;

  const [
    loadExternalMoneyTransfers,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetPlayerExternalMoneyTransfers({
    variables: {
      id: playerId,
      first: countPerPageByTableSize,
    },
  });

  return {
    loadExternalMoneyTransfers,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
