import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MessageData } from '../../../MessagesTable/queries/generated/MessageData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateMessageVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateMessageInput;
}>;


export type CreateMessage = { __typename: 'Mutation', createMessage: { __typename: 'CreateMessagePayload', message: { __typename: 'Message', createdAt: string, id: string, text: string, author: { __typename: 'Admin', firstName: string, id: string, lastName: string } } } };


export const CreateMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Message"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MessageData"}}]}}]}}]}}]}},...MessageData.definitions]} as unknown as DocumentNode;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessage, CreateMessageVariables>;

/**
 * __useCreateMessage__
 *
 * To run a mutation, you first call `useCreateMessage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessage, { data, loading, error }] = useCreateMessage({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessage(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMessage, CreateMessageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMessage, CreateMessageVariables>(CreateMessageDocument, options);
      }
export type CreateMessageHookResult = ReturnType<typeof useCreateMessage>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessage>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessage, CreateMessageVariables>;