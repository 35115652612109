import { FC } from 'react';
import { useParams } from 'react-router';
import { ExternalMoneyTransfersTable } from '../../components/ExternalMoneyTransfersBlock/components/ExternalMoneyTransfersTable';
import { useGetExternalMoneyTransfersColumns } from '../../components/ExternalMoneyTransfersBlock/components/ExternalMoneyTransfersTable/hooks/useGetExternalMoneyTransfersColumns';
import { PlayerPageContainer } from '../../components/PlayerPageContainer';

export const ExternalMoneyTransfers: FC = () => {
  const { columns } = useGetExternalMoneyTransfersColumns(true);
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PlayerPageContainer playerId={id}>
      <ExternalMoneyTransfersTable
        id={id}
        columns={columns}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </PlayerPageContainer>
  );
};
