import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { MultilineCellWithLink } from 'src/ui/Table/components/MultilineCellWithLink';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { twoEmDash } from 'src/const';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import { QualifiedReferralConfirmationSettingData } from '../../../queries/generated/QualifiedReferralConfirmationSettingData';
import { getAffiliateInfo } from '../helpers';
import { qualifiedReferralConfirmationSettingTypeMap } from '../../../const';

const columnHelper =
  createColumnHelper<QualifiedReferralConfirmationSettingData>();

const columnsSource: ColumnsSource<QualifiedReferralConfirmationSettingData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.SettingType, {
    id: ColumnId.SettingType,
    header: mapColumnIdToName[ColumnId.SettingType],
    cell: ({ row }) =>
      qualifiedReferralConfirmationSettingTypeMap[row.original.type],
  }),
  columnHelper.accessor(ColumnId.Affiliate, {
    id: ColumnId.Affiliate,
    header: mapColumnIdToName[ColumnId.Affiliate],
    cell: ({ row }) => {
      const { email, name, route } = getAffiliateInfo(row.original.affiliate);

      return mayBeNullCell(
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          route={route}
          subTitle={email}
        />,
        twoEmDash
      );
    },
  }),
  columnHelper.accessor(ColumnId.ReferralCode, {
    id: ColumnId.ReferralCode,
    header: mapColumnIdToName[ColumnId.ReferralCode],
    cell: ({ row }) => mayBeNullCell(row.original.code, twoEmDash),
  }),
  columnHelper.accessor(ColumnId.Comment, {
    id: ColumnId.Comment,
    header: mapColumnIdToName[ColumnId.Comment],
    cell: ({ row }) => row.original.comment,
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetQualifiedReferralConfirmationSettingsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
