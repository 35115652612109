import { useContext, useEffect } from 'react';
import { Currency, RewardTariffType } from 'commonTypes';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useCreateRevShareRewardTariff } from '../queries/generated/CreateRevShareRewardTariff';
import { useCreateCpaRewardTariff } from '../queries/generated/CreateCpaRewardTariff';
import { GetRevShareRewardTariffsDocument } from '../../../queries/generated/GetRevShareRewardTariffs';
import { GetCpaRewardTariffsDocument } from '../../../queries/generated/GetCpaRewardTariffs';
import { CreateRewardTariffValues } from '../../../types';

export const useCreateRewardTariff = () => {
  const { dispatch } = useContext(ModalContext);

  const [
    createCpaRewardTariff,
    {
      error: createCpaRewardTariffError,
      data: createCpaRewardTariffData,
      loading: createCpaRewardTariffLoading,
    },
  ] = useCreateCpaRewardTariff({
    refetchQueries: [
      {
        query: GetCpaRewardTariffsDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });
  const [
    createRevShareRewardTariff,
    {
      error: createRevShareRewardTariffError,
      data: createRevShareRewardTariffData,
      loading: createRevShareRewardTariffLoading,
    },
  ] = useCreateRevShareRewardTariff({
    refetchQueries: [
      {
        query: GetRevShareRewardTariffsDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const onCreateTariff = async (values: CreateRewardTariffValues) => {
    if (values.type === RewardTariffType.RevShare) {
      await createRevShareRewardTariff({
        variables: {
          input: {
            percent: values.percent,
          },
        },
      });
    } else if (
      values.type === RewardTariffType.Cpa &&
      values.baseline &&
      values.reward
    ) {
      await createCpaRewardTariff({
        variables: {
          input: {
            title: values.title,
            countries: values.countries,
            baselineSum: { amount: values.baseline, currency: Currency.Usd },
            rewardSum: { amount: values.reward, currency: Currency.Usd },
          },
        },
      });
    }
  };

  const error = createCpaRewardTariffError || createRevShareRewardTariffError;
  const data = createRevShareRewardTariffData || createCpaRewardTariffData;

  useEffect(() => {
    if (data) {
      toast.success('Тариф создан');

      dispatch({
        type: ModalActions.Close,
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error('Тариф не создан');

      dispatch({
        type: ModalActions.Close,
      });
    }
  }, [error, dispatch]);

  return {
    onCreateTariff,
    error,
    data,
    loading: createCpaRewardTariffLoading || createRevShareRewardTariffLoading,
  };
};
