import { FC, PropsWithChildren } from 'react';
import createStore from '../../../utils/createStore';
import { reducer } from './reducer';
import { State } from './types';

const initialState: State = {
  blockId: '',
  isOpened: false,
  loadOld: { hasOld: false },
  loadNew: { hasNew: false },
  isLoading: false,
  error: { data: null, type: null },
  isPermanentOpened: false,
  isLoaderVisible: true,
};

const [BlockContext, BlockProvider] = createStore(reducer, initialState);

export { BlockContext, BlockProvider };

export const withBlockProvider = <Props extends object>(
  OriginalComponent: FC<Props>
): FC<PropsWithChildren<Props>> => {
  const WrappedComponent: FC<PropsWithChildren<Props>> = (props) => (
    <BlockProvider>
      <OriginalComponent {...props} />
    </BlockProvider>
  );

  WrappedComponent.displayName = `withBlockProvider(${OriginalComponent.name})`;

  return WrappedComponent;
};
