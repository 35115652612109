import { ApolloError } from '@apollo/client';
import { useContext, useEffect } from 'react';
import errorToast from 'ui/Toast/ErrorToast';
import { getCodeFromGraphQLError } from 'src/utils/getCodeFromGraphQLError';
import { BlockContext, setErrorAction, setLoadingAction } from '../store';
import { ErrorType } from '../store/types';
import { resetErrorAction } from '../store/actions/resetError';

interface Props {
  loadData?: () => void;
  loading: boolean;
  error?: ApolloError;
}

export const useBlockComponentState = ({
  error,
  loadData,
  loading,
}: Props): void => {
  const { state, dispatch } = useContext(BlockContext);

  useEffect(() => {
    if (state.isOpened && loadData) {
      loadData();
    }

    return undefined;
  }, [dispatch, loadData, state.isOpened]);

  useEffect(() => {
    dispatch(setLoadingAction({ loading }));
  }, [dispatch, loading]);

  useEffect(() => {
    if (error) {
      error.graphQLErrors.map((err) => {
        errorToast({
          header: getCodeFromGraphQLError(err),
          text: err.message,
          toastId: err.path?.join() || 'errorToast',
        });

        return null;
      });

      dispatch(setErrorAction({ data: error, type: ErrorType.LoadData }));
    } else {
      dispatch(resetErrorAction());
    }
  }, [dispatch, error]);
};
