import { useCallback, useMemo, useState } from 'react';
import { MoneyTransferFilterInput } from 'generatedGraphql';
import { MoneyTransferFilterMember } from 'commonTypes';
import { GetTransfersVariables } from '../../../queries/generated/GetTransfers';
import { useGetAvailableMoneyTransferFiltersLazyQuery } from '../../../queries/generated/GetAvailableMoneyTransferFilters';
import { allMoneyTransferFilters } from '../../../const';

const COUNT_PER_PAGE = 10;

interface LoadMoneyTransfersVariablesWithFilter extends GetTransfersVariables {
  filter: MoneyTransferFilterInput;
}

interface LoadMoneyTransfersParams {
  variables: LoadMoneyTransfersVariablesWithFilter;
}

interface UseGetMoneyTransferFiltersParams {
  playerId: string;
  loadTransfers: (params: LoadMoneyTransfersParams) => void;
}

export const useGetMoneyTransferFilters = ({
  playerId,
  loadTransfers,
}: UseGetMoneyTransferFiltersParams) => {
  const [getAvailableMoneyTransferFiltersData, { data }] =
    useGetAvailableMoneyTransferFiltersLazyQuery();

  const [isFiltered, setIsFiltered] = useState(false);

  const onSubmitFilter = useCallback(
    (filters: MoneyTransferFilterInput) => {
      loadTransfers({
        variables: {
          playerId,
          first: COUNT_PER_PAGE,
          filter: filters,
        },
      });

      if (filters && !Object.keys(filters).length) {
        setIsFiltered(false);
      }

      setIsFiltered(true);
    },
    [loadTransfers, playerId]
  );

  const availableFiltersState = useMemo(
    () => data?.player.availableMoneyTransferFilters || [],
    [data?.player.availableMoneyTransferFilters]
  );

  const onChangeFilters = useCallback(
    (filters: Array<MoneyTransferFilterMember>) => {
      getAvailableMoneyTransferFiltersData({
        variables: {
          id: playerId,
          usedFilters: filters,
        },
      });
    },
    [getAvailableMoneyTransferFiltersData, playerId]
  );

  const availableMoneyTransferFilters = useMemo(
    () =>
      allMoneyTransferFilters.filter((item) =>
        availableFiltersState.includes(item.filterId)
      ),
    [availableFiltersState]
  );

  return {
    onChangeFilters,
    availableMoneyTransferFilters,
    onSubmitFilter,
    isFiltered,
  };
};
