/**
 * When using Apollo's `relayStylePagination` with `fetchPolicy: 'cache-and-network'`,
 * there's a known issue where the UI initially displays all cached items,
 * even if we're only requesting a subset of them. This can cause a "jump" in the UI,
 * as it first shows all cached items, then updates to show only the requested items
 * once the network request completes.
 *
 * This hook is a workaround for that issue. It slices the data to the desired amount
 * on the first page, preventing the UI "jump".
 *
 * Note:
 * This is a temporary solution until the issue is addressed in Apollo Client.
 * For more details on the problem, see the related issue:
 * https://github.com/apollographql/apollo-client/issues/11087
 */

import { OperationVariables, QueryHookOptions } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { useCallback } from 'react';
import {
  FetchMoreCustomParams,
  FetchMoreOptions,
  UseLazyQueryReturn,
} from '../types';
import { fetchMoreCustom } from '../helpers';
import { useLazyPaginationData } from './useLazyPaginationData';

export const useLazyQuery = <
  TData,
  TVariables extends OperationVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): UseLazyQueryReturn<TData, TVariables> => {
  const [
    originalLazyQueryExecFunction,
    { fetchMore: originalFetchMore, data: originalData, ...rest },
  ] = Apollo.useLazyQuery<TData, TVariables>(query, {
    ...options,
  });

  const { data, interceptedLazyQueryExecFunction, fetchMore } =
    useLazyPaginationData({
      originalData,
      originalFetchMore,
      originalLazyQueryExecFunction,
      options,
    });

  const fetchMoreCallback = useCallback(
    <TFetchData>(
      fetchMoreOptions: FetchMoreOptions<TVariables, TFetchData>,
      fetchMoreParams: FetchMoreCustomParams = { shouldShowErrorToast: true }
    ) => fetchMoreCustom(fetchMoreOptions, fetchMore, fetchMoreParams),
    [fetchMore]
  );

  return [
    interceptedLazyQueryExecFunction,
    {
      ...rest,
      data,
      fetchMore: fetchMoreCallback,
    },
  ];
};
