import {
  BonusInterface,
  BonusStatus,
  BonusWinConditions,
  CashbackBonus,
  Freespin,
  FreespinBonus,
  GiftBonus,
  Maybe,
  PromoCode,
  PromoCodeBonus,
  Sum,
} from 'generatedGraphql';
import { GetBonus } from './queries/generated/GetBonus';
import { formatSumWithCurrency } from '../../../../../../utils/accounting';

interface ProgressBarConfig {
  header: string;
  percent: number;
  startTitle: string;
  endTitle: string;
}

interface Value {
  status?: BonusStatus;
  createdAt?: string;
  sum?: Sum;
  winSum: Maybe<Sum>;
  updatedAt: Maybe<string>;
  promoCode?: PromoCode;
  freespin: Maybe<Freespin>;
  cancellable?: boolean;
  minAccountBalance: Maybe<Sum>;
  cashout: Maybe<number>;
  rewardPeriod: Maybe<string>;
  winConditions: Maybe<BonusWinConditions>;
  betSum: Maybe<Sum>;
  getProgressBarConfig?: () => ProgressBarConfig;
}

export const useBonusItem = (data: GetBonus | undefined): Value => {
  if (!data) {
    return {
      status: undefined,
      createdAt: undefined,
      sum: undefined,
      winSum: undefined,
      updatedAt: undefined,
      promoCode: undefined,
      freespin: undefined,
      cancellable: undefined,
      minAccountBalance: undefined,
      cashout: undefined,
      rewardPeriod: undefined,
      winConditions: undefined,
      betSum: undefined,
      getProgressBarConfig: undefined,
    };
  }

  const {
    status,
    createdAt,
    sum,
    winSum,
    config: { minAccountBalance, cashout, winConditions },
    updatedAt,
    balance,
  } = data.bonus as BonusInterface;

  const { freespin } = data.bonus as FreespinBonus;
  const { promoCode } = data.bonus as PromoCodeBonus;
  const { cancellable } = data.bonus as GiftBonus;
  const { rewardPeriod } = data.bonus as CashbackBonus;

  const betSum = data.bonus.config.winConditions?.betSum;
  const requiredBetSum = data.bonus.config.winConditions?.requiredBetSum;

  let getProgressBarConfig;

  if (requiredBetSum && betSum) {
    getProgressBarConfig = (): ProgressBarConfig => ({
      header: `Бонусный баланс: ${formatSumWithCurrency(
        balance.amount,
        balance.currency
      )}`,
      percent: Math.ceil((betSum.amount / requiredBetSum.amount) * 100),
      startTitle: `Сумма ставок: ${formatSumWithCurrency(
        betSum.amount,
        betSum.currency
      )}`,
      endTitle: formatSumWithCurrency(
        requiredBetSum.amount,
        requiredBetSum.currency
      ),
    });
  }

  return {
    status,
    createdAt,
    sum,
    winSum,
    updatedAt,
    promoCode,
    freespin,
    cancellable,
    minAccountBalance,
    cashout,
    rewardPeriod,
    winConditions,
    betSum,
    getProgressBarConfig,
  };
};
