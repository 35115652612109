import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import errorToast from 'ui/Toast/ErrorToast';
import { useContext } from 'react';
import { ApolloError } from '@apollo/client';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useCreatePromoCodeMediaItem as useCreatePromoCodeMediaItemMutation } from '../queries/generated/CreatePromoCodeMediaItem';
import { FieldName } from '../const';
import { CreatePromoCodeValues } from '../types';
import { GetMediaItemsPromoCodeDocument } from '../../../queries/generated/GetMediaItemsPromoCode';

const cellHeight = Heights.defaultCellHeight;

interface useCreatePromoCodeResult {
  onCreatePromoCodeSubmitHandler: (values: CreatePromoCodeValues) => void;
}
export const useCreatePromoCode = (): useCreatePromoCodeResult => {
  const { dispatch } = useContext(ModalContext);

  const onCompletedHandler = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Промокод создан');
  };

  const onErrorHandler = (error: ApolloError) => {
    dispatch({
      type: ModalActions.Close,
    });

    errorToast({
      header: 'Промокод не создан',
      text: error.message,
      toastId: 'create-promo-code-media-item-error',
    });
  };

  const [createPromoCodeMediaItem] = useCreatePromoCodeMediaItemMutation({
    onCompleted: onCompletedHandler,
    onError: onErrorHandler,
    refetchQueries: [
      {
        query: GetMediaItemsPromoCodeDocument,
        variables: {
          first: getCountByWindowHeight(cellHeight, 0),
        },
      },
    ],
  });

  const onCreatePromoCodeSubmitHandler = (values: CreatePromoCodeValues) => {
    if (!values[FieldName.Usage]) {
      return;
    }

    createPromoCodeMediaItem({
      variables: {
        input: {
          token: values[FieldName.Token],
          usageType: values[FieldName.Usage],
          activationLimit: values[FieldName.ActivationLimit] || null,
          ...(!values[FieldName.ReferralLinkId]
            ? {
                mediaCampaignId: values[FieldName.MediaCampaignId],
              }
            : null),
          referralLinkMediaItem: values[FieldName.ReferralLinkId]
            ? { id: values[FieldName.ReferralLinkId] }
            : null,
          ...(values[FieldName.ConnectionWithFreespins]
            ? {
                freespin: {
                  count: values[FieldName.FreespinCount],
                  currency: values[FieldName.FreespinCurrency],
                  gameId: values[FieldName.FreespinGameId],
                  ...(values[FieldName.FreespinWager]
                    ? {
                        bonusWager: values[FieldName.FreespinWager],
                      }
                    : null),
                  ...(values[FieldName.FreespinMinBalance]
                    ? {
                        minAccountBalanceAmount: {
                          amount: values[FieldName.FreespinMinBalance],
                          currency: values[FieldName.FreespinCurrency],
                        },
                      }
                    : null),
                },
              }
            : null),
          ...(values[FieldName.ConnectionWithBonus]
            ? {
                bonus: {
                  amount: {
                    amount: values[FieldName.BonusAmount],
                    currency: values[FieldName.BonusCurrency],
                  },
                  bonusConfig: {
                    cashout: values[FieldName.BonusCashout],
                    currency: values[FieldName.BonusCurrency],
                    minAccountBalanceAmount: values[FieldName.BonusMinBalance],
                    wager: values[FieldName.BonusWager],
                  },
                },
              }
            : null),
        },
      },
    });
  };

  return { onCreatePromoCodeSubmitHandler };
};
