import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdatePartnerReferralLinkMediaItemNameVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateReferralLinkMediaItemNameInput;
}>;


export type UpdatePartnerReferralLinkMediaItemName = { __typename: 'Mutation', updateReferralLinkMediaItemName: { __typename: 'UpdateReferralLinkMediaItemNamePayload', referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', id: string, name: string } } };


export const UpdatePartnerReferralLinkMediaItemNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdatePartnerReferralLinkMediaItemName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateReferralLinkMediaItemNameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateReferralLinkMediaItemName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdatePartnerReferralLinkMediaItemNameMutationFn = Apollo.MutationFunction<UpdatePartnerReferralLinkMediaItemName, UpdatePartnerReferralLinkMediaItemNameVariables>;

/**
 * __useUpdatePartnerReferralLinkMediaItemName__
 *
 * To run a mutation, you first call `useUpdatePartnerReferralLinkMediaItemName` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerReferralLinkMediaItemName` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerReferralLinkMediaItemName, { data, loading, error }] = useUpdatePartnerReferralLinkMediaItemName({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerReferralLinkMediaItemName(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePartnerReferralLinkMediaItemName, UpdatePartnerReferralLinkMediaItemNameVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdatePartnerReferralLinkMediaItemName, UpdatePartnerReferralLinkMediaItemNameVariables>(UpdatePartnerReferralLinkMediaItemNameDocument, options);
      }
export type UpdatePartnerReferralLinkMediaItemNameHookResult = ReturnType<typeof useUpdatePartnerReferralLinkMediaItemName>;
export type UpdatePartnerReferralLinkMediaItemNameMutationResult = Apollo.MutationResult<UpdatePartnerReferralLinkMediaItemName>;
export type UpdatePartnerReferralLinkMediaItemNameMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerReferralLinkMediaItemName, UpdatePartnerReferralLinkMediaItemNameVariables>;