import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CashbackModal } from '../CashbackModal';
import { DateFormat, formatDate } from '../../../../../../utils/formatDate';
import { CashbackAccountData } from '../../queries/generated/CashbackAccountData';

interface OpenCashbackParams {
  playerId: string;
}

interface GetNextCashbackParams {
  accounts?: Array<CashbackAccountData>;
}

interface Values {
  openCashback: (props: OpenCashbackParams) => void;
  getNextCashback: (props: GetNextCashbackParams) => CashbackAccountData | null;
}

const useCashback = (): Values => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const currentDate = formatDate(new Date().toString(), DateFormat.ShortDate);

  const openCashback = useCallback(
    ({ playerId }: OpenCashbackParams) => {
      modalDispatch({
        type: ModalActions.Open,
        payload: {
          content: <CashbackModal id={playerId} />,
          title: `Кэшбэк ${currentDate}`,
        },
      });
    },
    [currentDate, modalDispatch]
  );

  const getNextCashback = ({
    accounts,
  }: GetNextCashbackParams): CashbackAccountData | null => {
    const firstActiveCashback = accounts?.find(
      (account) =>
        !!(account.active && account.statistic.upcomingCashback.sum.amount)
    );

    const firstNotNullableCashback = accounts?.find(
      (account) => !!account.statistic.upcomingCashback.sum.amount
    );

    return firstActiveCashback || firstNotNullableCashback || null;
  };

  return {
    openCashback,
    getNextCashback,
  };
};

export default useCashback;
