import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { BadBankRestrictionData } from './BadBankRestrictionData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateBadBankRestrictionVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateBadBankRestrictionInput;
}>;


export type CreateBadBankRestriction = { __typename: 'Mutation', createBadBankRestriction: { __typename: 'CreateBadBankRestrictionPayload', badBankRestriction: { __typename: 'BadBankRestriction', canceledAt?: string | null, createdAt: string, id: string, reason?: string | null, updatedAt: string } } };


export const CreateBadBankRestrictionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateBadBankRestriction"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateBadBankRestrictionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createBadBankRestriction"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"badBankRestriction"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BadBankRestrictionData"}}]}}]}}]}},...BadBankRestrictionData.definitions]} as unknown as DocumentNode;
export type CreateBadBankRestrictionMutationFn = Apollo.MutationFunction<CreateBadBankRestriction, CreateBadBankRestrictionVariables>;

/**
 * __useCreateBadBankRestriction__
 *
 * To run a mutation, you first call `useCreateBadBankRestriction` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBadBankRestriction` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBadBankRestriction, { data, loading, error }] = useCreateBadBankRestriction({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBadBankRestriction(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBadBankRestriction, CreateBadBankRestrictionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBadBankRestriction, CreateBadBankRestrictionVariables>(CreateBadBankRestrictionDocument, options);
      }
export type CreateBadBankRestrictionHookResult = ReturnType<typeof useCreateBadBankRestriction>;
export type CreateBadBankRestrictionMutationResult = Apollo.MutationResult<CreateBadBankRestriction>;
export type CreateBadBankRestrictionMutationOptions = Apollo.BaseMutationOptions<CreateBadBankRestriction, CreateBadBankRestrictionVariables>;