import * as types from './actionTypes';

import { Action, State } from './types';

export const reducer = (currentState: State, action: Action): State => {
  const newState = { ...currentState };

  switch (action.type) {
    case types.SET_DATA:
      newState.paymentPaidSum = action.payload.paymentPaidSum;
      newState.paymentId = action.payload.paymentId;

      return newState;

    default:
      return currentState;
  }
};
