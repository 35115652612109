import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { DocumentData } from './DocumentData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateDocumentNoteVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateDocumentNoteInput;
  thumbnailImageUrlHeight: SchemaTypes.Scalars['Int']['input'];
  thumbnailImageUrlWidth: SchemaTypes.Scalars['Int']['input'];
}>;


export type UpdateDocumentNote = { __typename: 'Mutation', updateDocumentNote: { __typename: 'UpdateDocumentNotePayload', document: { __typename: 'Document', createdAt: string, id: string, imageUrl: string, note?: string | null, thumbnailImageUrl: string } } };


export const UpdateDocumentNoteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateDocumentNote"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateDocumentNoteInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"thumbnailImageUrlHeight"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"thumbnailImageUrlWidth"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateDocumentNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"document"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DocumentData"}}]}}]}}]}},...DocumentData.definitions]} as unknown as DocumentNode;
export type UpdateDocumentNoteMutationFn = Apollo.MutationFunction<UpdateDocumentNote, UpdateDocumentNoteVariables>;

/**
 * __useUpdateDocumentNote__
 *
 * To run a mutation, you first call `useUpdateDocumentNote` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentNote` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentNote, { data, loading, error }] = useUpdateDocumentNote({
 *   variables: {
 *      input: // value for 'input'
 *      thumbnailImageUrlHeight: // value for 'thumbnailImageUrlHeight'
 *      thumbnailImageUrlWidth: // value for 'thumbnailImageUrlWidth'
 *   },
 * });
 */
export function useUpdateDocumentNote(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentNote, UpdateDocumentNoteVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateDocumentNote, UpdateDocumentNoteVariables>(UpdateDocumentNoteDocument, options);
      }
export type UpdateDocumentNoteHookResult = ReturnType<typeof useUpdateDocumentNote>;
export type UpdateDocumentNoteMutationResult = Apollo.MutationResult<UpdateDocumentNote>;
export type UpdateDocumentNoteMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentNote, UpdateDocumentNoteVariables>;