import { useGetCpaTariffs } from 'commonComponents/CpaTariffDropdown/queries/generated/GetCpaTariffs';
import { useCallback, useEffect } from 'react';
import errorToast from 'ui/Toast/ErrorToast';
import { getCpaTariffsOptions } from './helpers';
import { nbsp } from '../../const';

const COUNT_PER_PAGE = 10;

export const useCpaTariffDropdown = () => {
  const {
    data: cpaTariffs,
    loading,
    error,
    fetchMore,
  } = useGetCpaTariffs({
    variables: {
      first: COUNT_PER_PAGE,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  const hasNextPage = cpaTariffs?.cpaRewardTariffs.pageInfo.hasNextPage;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore({
        variables: {
          first: COUNT_PER_PAGE,
          after: cpaTariffs?.cpaRewardTariffs?.pageInfo.endCursor,
        },
      }),
    [cpaTariffs?.cpaRewardTariffs?.pageInfo.endCursor, fetchMore]
  );

  const cpaTariffsOptions = getCpaTariffsOptions(
    cpaTariffs?.cpaRewardTariffs.edges
  );

  useEffect(() => {
    if (error) {
      errorToast({
        header: `Ошибка${nbsp}при${nbsp}загрузке CPA тарифов`,
        text: error.message,
        toastId: 'get-cpa-tariffs-error',
      });
    }
  }, [error]);

  return {
    cpaTariffsOptions,
    loading,
    cpaTariffs,
    fetchMoreCallback,
    hasNextPage,
    error,
  };
};
