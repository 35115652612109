import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type ResetPartnerNameVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ResetPartnerNameInput;
}>;


export type ResetPartnerName = { __typename: 'Mutation', resetPartnerName: { __typename: 'ResetPartnerNamePayload', partner: { __typename: 'Partner', defaultName: string, id: string, name: string } } };


export const ResetPartnerNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPartnerName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPartnerNameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPartnerName"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"defaultName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ResetPartnerNameMutationFn = Apollo.MutationFunction<ResetPartnerName, ResetPartnerNameVariables>;

/**
 * __useResetPartnerName__
 *
 * To run a mutation, you first call `useResetPartnerName` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPartnerName` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPartnerName, { data, loading, error }] = useResetPartnerName({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPartnerName(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPartnerName, ResetPartnerNameVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPartnerName, ResetPartnerNameVariables>(ResetPartnerNameDocument, options);
      }
export type ResetPartnerNameHookResult = ReturnType<typeof useResetPartnerName>;
export type ResetPartnerNameMutationResult = Apollo.MutationResult<ResetPartnerName>;
export type ResetPartnerNameMutationOptions = Apollo.BaseMutationOptions<ResetPartnerName, ResetPartnerNameVariables>;