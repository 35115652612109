import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useGetRevShareCompanyPartnersReportColumns } from './hooks/useGetRevShareCompanyPartnersReportColumns';
import { RevShareCompanyPartnersReportRow } from '../../types';

interface Props {
  revShareCompanyPartnersReport?: Array<RevShareCompanyPartnersReportRow>;
  hasTotalRow: boolean;
}

export const RevShareCompanyPartnersReportTable: FC<Props> = ({
  revShareCompanyPartnersReport,
  hasTotalRow,
}) => {
  const { columns } = useGetRevShareCompanyPartnersReportColumns();

  return (
    <BlockTable
      columns={columns}
      data={revShareCompanyPartnersReport}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
