import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type EnablePostbackVariables = SchemaTypes.Exact<{
  input: SchemaTypes.EnablePostbackInput;
}>;


export type EnablePostback = { __typename: 'Mutation', enablePostback: { __typename: 'EnablePostbackPayload', void?: any | null } };


export const EnablePostbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EnablePostback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EnablePostbackInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enablePostback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"void"}}]}}]}}]} as unknown as DocumentNode;
export type EnablePostbackMutationFn = Apollo.MutationFunction<EnablePostback, EnablePostbackVariables>;

/**
 * __useEnablePostback__
 *
 * To run a mutation, you first call `useEnablePostback` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePostback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePostback, { data, loading, error }] = useEnablePostback({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnablePostback(baseOptions?: ApolloReactHooks.MutationHookOptions<EnablePostback, EnablePostbackVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EnablePostback, EnablePostbackVariables>(EnablePostbackDocument, options);
      }
export type EnablePostbackHookResult = ReturnType<typeof useEnablePostback>;
export type EnablePostbackMutationResult = Apollo.MutationResult<EnablePostback>;
export type EnablePostbackMutationOptions = Apollo.BaseMutationOptions<EnablePostback, EnablePostbackVariables>;