import { WithdrawalListData } from '../queries/generated/WithdrawalListData';
import { Route } from '../../../router/routes.const';

export const getRouteByTypeName = (actor: WithdrawalListData['actor']) => {
  switch (actor.__typename) {
    case 'Company':
      return `${Route.COMPANIES}/${actor.id}`;
    case 'Partner':
      return `${Route.PARTNERS}/${actor.id}`;
    case 'Player':
      return `${Route.PLAYERS}/${actor.id}`;

    default:
      return Route.WITHDRAWAL;
  }
};
