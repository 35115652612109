import { useCallback, useContext, useEffect } from 'react';
import { ClientData } from 'commonComponents/ClientDataBlock/types';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { BlockContext, setLoadingAction } from 'ui/Block/store';
import { useAuth, UserPrivilege } from 'commonComponents/Auth';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useGetCompanyAdditionalDataLazyQuery } from '../queries/generated/GetCompanyAdditionalData';
import { getCompanyDataConfig } from '../helpers/getCompanyDataConfig';
import { UpdateCompanyIdentities } from '../components/UpdateCompanyIdentities';
import { UpdateCompanyPersonalData } from '../components/UpdateCompanyPersonalData';
import { useEditPlayerAccountModal } from './useEditPlayerAccountModal';

interface UseCompanyDataParams {
  id: string;
}

interface UseCompanyDataResult {
  refetchCompanyData: () => void;
  clientData: ClientData | null;
}

export const useCompanyData = ({
  id,
}: UseCompanyDataParams): UseCompanyDataResult => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { state: blockState, dispatch: blockDispatch } =
    useContext(BlockContext);

  const auth = useAuth();

  const [loadCompanyData, { data, loading, error, refetch }] =
    useGetCompanyAdditionalDataLazyQuery({
      variables: {
        id,
      },
    });

  const onCompanyIdentitiesEdit = useCallback(() => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        content: <UpdateCompanyIdentities id={id} />,
        title: 'Изменение данных входа',
        subTitle: data && `${data.company.name} #${data.company.id}`,
      },
    });
  }, [modalDispatch, id, data]);

  const onCompanyPersonalDataEdit = useCallback(() => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        content: <UpdateCompanyPersonalData id={id} />,
        title: 'Изменение персональных данных',
        subTitle: data && `${data.company.name} #${data.company.id}`,
      },
    });
  }, [modalDispatch, id, data]);

  const { onPlayerAccountEdit } = useEditPlayerAccountModal({
    companyId: id,
    company: data?.company,
  });

  const refetchCompanyData = () => {
    refetch?.();
  };

  useBlockComponentState({
    loadData: loadCompanyData,
    loading,
    error,
  });

  useEffect(() => {
    if (!blockState.isOpened) {
      blockDispatch(setLoadingAction({ loading: false }));
    }
  }, [blockDispatch, blockState.isOpened]);

  const isCompanyIdentitiesEditBtnVisible = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdateCompanyIdentities],
  });

  const isCompanyPersonalDataEditBtnVisible = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdateCompanyPersonalData],
  });

  const hasPrivilegeToDeclinePlayerAccount = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.DeclineCompanyPlayerAccount],
  });

  const clientData = getCompanyDataConfig({
    data,
    onCompanyIdentitiesEdit,
    onCompanyPersonalDataEdit,
    isCompanyIdentitiesEditBtnVisible,
    isCompanyPersonalDataEditBtnVisible,
    hasPrivilegeToDeclinePlayerAccount,
    onPlayerAccountEdit,
  });

  return {
    refetchCompanyData,
    clientData,
  };
};
