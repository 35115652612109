import { QualifiedReferralConfirmationSettingType } from 'generatedGraphql';
import { nbsp } from '../../const';

export const qualifiedReferralConfirmationSettingTypeMap: {
  [key in QualifiedReferralConfirmationSettingType]: string;
} = {
  [QualifiedReferralConfirmationSettingType.AutomaticConfirmationVerified]:
    'Автоапрув проверенных',
  [QualifiedReferralConfirmationSettingType.AutomaticConfirmationWithoutVerification]: `Автоапрув без${nbsp}проверки`,
};

export enum UpdateConfirmationSettingSteps {
  BaseStep = 1,
  UpdateStep,
}
