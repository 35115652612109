import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { KeyValueRowProps } from 'ui/KeyValueRow';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { DateFormat, formatDate } from '../../../../utils/formatDate';
import { MediaCampaignModalSteps } from './const';
import { EditMediaCampaignModal } from '../EditMediaCampaignModal';
import { useMediaCampaignModal } from './hooks/useMediaCampaignModal';

interface Props {
  mediaCampaignId: string;
}

export const MediaCampaignModal: FC<Props> = ({ mediaCampaignId }) => {
  const { mediaCampaign, openEditMediaCampaignModal, step } =
    useMediaCampaignModal({
      mediaCampaignId,
    });

  if (!mediaCampaign) {
    return null;
  }

  const { name, createdAt, url } = mediaCampaign;

  if (step === MediaCampaignModalSteps.EditNameStep) {
    return (
      <EditMediaCampaignModal
        mediaCampaignId={mediaCampaignId}
        name={name}
        url={url}
      />
    );
  }

  const rows: Array<KeyValueRowProps> = [
    {
      title: 'Название',
      value: name,
    },
    {
      title: 'URL',
      value: url,
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      customFooter={
        <Privilege privileges={[UserPrivilege.UpdateMediaCampaign]}>
          <Button
            size={ButtonSize.Large}
            onClick={openEditMediaCampaignModal}
            theme={ButtonTheme.Process}
          >
            Редактировать
          </Button>
        </Privilege>
      }
      rows={rows}
    />
  );
};
