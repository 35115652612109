import { ModalContext, Tabs, TabsWrapper } from 'ui/Modal';
import { FC, useContext, useState } from 'react';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { AffiliatePromoCodesList } from '../AffiliatePromoCodesList';
import { AffiliatePromoCodeItemSteps } from '../AffiliatePromoCodeItem/const';
import { AffiliatePromoCodeItemDetails } from '../AffiliatePromoCodeItemDetails';
import { useUpdateAffiliateModalHeader } from './hooks/useUpdateAffiliateModalHeader';
import { AffiliateReferralLinksList } from '../AffiliateReferralLinkList';
import { CreateAffiliatePromoCode } from '../CreateAffiliatePromoCode';
import { useGetPrivilegeToShowMediaItems } from './hooks/useGetPrivilegeToShowMediaItems';

interface Props {
  companyId: string;
  companyAffiliateId: string;
  companyAffiliateName: string;
}

export const AffiliateItem: FC<Props> = ({
  companyId,
  companyAffiliateId,
  companyAffiliateName,
}) => {
  const { state } = useContext(ModalContext);
  const [promoCodeId, setPromoCodeId] = useState<string>();
  const { updateModalStep } = useUpdateModalStep<AffiliatePromoCodeItemSteps>();
  const {
    hasPrivilegeForViewCompanyAffiliateReferralLinks,
    hasPrivilegeForViewCompanyAffiliatePromoCodes,
  } = useGetPrivilegeToShowMediaItems();

  useUpdateAffiliateModalHeader({
    currentPromoCodeId: promoCodeId,
    companyAffiliateId,
    companyAffiliateName,
  });

  if (state.step === AffiliatePromoCodeItemSteps.Details && promoCodeId) {
    return (
      <AffiliatePromoCodeItemDetails
        companyId={companyId}
        affiliateId={companyAffiliateId}
        promoCodeId={promoCodeId}
      />
    );
  }

  const isCreatePromoCodeFormSteps =
    state.step === AffiliatePromoCodeItemSteps.CreatePromoCodeBaseInfo ||
    state.step === AffiliatePromoCodeItemSteps.CreatePromoCodeConnection ||
    state.step === AffiliatePromoCodeItemSteps.CreatePromoCodeConfirm;

  if (isCreatePromoCodeFormSteps) {
    return (
      <CreateAffiliatePromoCode
        companyId={companyId}
        companyAffiliateId={companyAffiliateId}
        companyAffiliateName={companyAffiliateName}
      />
    );
  }

  const tabs: Array<Tabs> = [
    {
      title: 'Информация',
      content: <span>Not implemented yet</span>,
      isVisible: false,
    },
    {
      title: 'Ссылки',
      content: (
        <AffiliateReferralLinksList
          companyAffiliateId={companyAffiliateId}
          companyId={companyId}
        />
      ),
      isVisible: hasPrivilegeForViewCompanyAffiliateReferralLinks,
    },
    {
      title: 'Промокоды',
      content: (
        <AffiliatePromoCodesList
          companyId={companyId}
          companyAffiliateId={companyAffiliateId}
          handlePromoCodeDetailsButtonClick={(currentPromoCodeId) => {
            setPromoCodeId(currentPromoCodeId);
            updateModalStep(AffiliatePromoCodeItemSteps.Details);
          }}
        />
      ),
      isVisible: hasPrivilegeForViewCompanyAffiliatePromoCodes,
    },
  ];

  return <TabsWrapper tabs={tabs} activeTabDefault={state.activeTab || 0} />;
};
