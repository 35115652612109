import {
  ChangeEventHandler,
  DragEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Timeout } from 'react-number-format/types/types';
import { FileFormat } from '../const';
import { getFileListValidationError } from '../helpers';

interface Params {
  inputRef: React.RefObject<HTMLInputElement>;
  onChange: (files: FileList) => void;
  validFormats: Array<FileFormat>;
  maxSize: number;
}

interface Result {
  isDragActive: boolean;
  error: string | null;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  dragHandlers: {
    onDragEnter: DragEventHandler;
    onDragLeave: DragEventHandler;
    onDragOver: DragEventHandler;
    onDrop: DragEventHandler;
  };
}

export const useFileDragAndDrop = ({
  inputRef,
  onChange,
  validFormats,
  maxSize,
}: Params): Result => {
  const [error, setError] = useState<string | null>(null);
  const [isDragActive, setIsDragActive] = useState(false);

  const errorTimeoutId = useRef<Timeout | null>(null);

  const resetError = () => {
    setError(null);

    if (errorTimeoutId.current) {
      clearTimeout(errorTimeoutId.current);
      errorTimeoutId.current = null;
    }
  };

  const onDragOver: DragEventHandler = (e) => {
    e.preventDefault();
  };

  const onDragEnter: DragEventHandler = () => {
    setIsDragActive(true);
  };

  const onDragLeave: DragEventHandler = () => {
    setIsDragActive(false);
  };

  const onDrop: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragActive(false);

    const fileError = getFileListValidationError(
      e.dataTransfer.files,
      validFormats,
      maxSize
    );

    setError(fileError);

    if (!e.dataTransfer.files?.length || fileError) {
      return;
    }

    onChange(e.dataTransfer.files);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    const fileError = getFileListValidationError(
      e.target.files,
      validFormats,
      maxSize
    );

    setError(fileError);

    if (!e.target.files?.length || fileError) {
      return;
    }

    onChange(e.target.files);

    /**
     * Use setTimeout to defer clearing input value,
     * ensuring all handlers process before reset.
     */
    setTimeout(() => {
      const refCurrent = inputRef.current;

      if (refCurrent) {
        refCurrent.value = '';
      }
    }, 0);
  };

  useEffect(() => {
    if (!errorTimeoutId.current && error) {
      errorTimeoutId.current = setTimeout(resetError, 2000);
    }
  }, [error]);

  return {
    error,
    isDragActive,
    handleInputChange,
    dragHandlers: {
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
    },
  };
};
